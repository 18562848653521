import _ from "underscore";
import React from "react";

export const getDisplayText = (arr, keyName) => {
  const nolbl = { display: "no lbl for " + keyName, textToRead: "no lbl" };
  // return _.find(arr, {keyName}) || arr[keyName] || nolbl
  var tempObj = [];
  _.find(arr, el => {
    if (el.keyName === keyName) {
      if (el.numOfPopupLinkPresent > 0) {
        tempObj = splitURLsFromText(el);
      } else {
        tempObj = el;
      }
      return tempObj;
    }
  });
  return tempObj ? tempObj : nolbl;
};

export const getByValue = (arr = [], value = "") => {
  const found =
    _.findWhere(arr, { value }) ||
    _.findWhere(arr, { value: value.toString() });
  return found || { value: 0, text: "no lbl" };
};

export const makeFlexitDateObj = e => {
  let resp = null;
  let d = e;
  try {
    if (!typeof d.getMonth === "function") {
      d = new Date(Date.parse(d));
    }
    resp = {
      year: d.getUTCFullYear(),
      month: d.getUTCMonth(),
      day: d.getUTCDate()
    };
    return resp;
  } catch (e) {
    console.log(e);
  }
};

export const getErrors = errorMessage => {
  let res = {};
  const ar = errorMessage.split("|");
  ar.forEach(item => {
    const fieldValidation = item.split(":");
    if (fieldValidation.length > 1)
      res[fieldValidation[0].trim()] = fieldValidation[1]
        ? fieldValidation[1]
        : "";
  });
  return res;
};

export const checkBrowser = () => {
  var ua = window.navigator.userAgent;
  var msie = ua.indexOf("MSIE ");
  var trident = ua.indexOf("Trident/");
  var msEdge = ua.indexOf("Edge");

  if (msie > 0 || trident > 0 || msEdge > 0) {
    // If Internet Explorer
    return true;
  } // If another browser
  else {
    return false;
  }
};

export const CustomToolTipIcon = () => {
  return (
    <svg
      viewBox="0 0 24 24"
      width="1.25em"
      height="1.2em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11,22C4.9,22,0,17.1,0,11S4.9,0,11,0s11,4.9,11,11C22,17.1,17.1,22,11,22z M11,7c0.6,0,1-0.4,1-1s-0.4-1-1-1
	s-1,0.4-1,1S10.4,7,11,7z M11,17c0.6,0,1-0.4,1-1l0-6c0-0.6-0.4-1-1-1s-1,0.4-1,1l0,6C10,16.6,10.5,17,11,17z"
        fill="currentColor"
      />
    </svg>
  );
};

export const splitURLsFromText = object => {
  var index = 0;
  let newObj = { ...object };
  const targetText = newObj.display;
  const regex = /\[!(.*?)\]/gm;
  const matchedText = targetText.match(regex);
  if (matchedText !== null) {
    const replacement = matchedText.map((el, i) => {
      let temp = "";
      const res = el.split("[!URL='").map(elm => {
        temp = elm.split("'&&TEXTTODISPLAY='");
        return (
          elm.length > 0 && [
            temp[0].replace("/", "_").toLowerCase(),
            temp[1].split("']")[0]
          ]
        );
      });
      // aria-label="${res[1][1]}" 
      return `<a href="javaScript:void(0)" tid="helpURL" class="url-modifier" role="button" onclick="handleURLClick('${temp[0]}')">${res[1][1]}</a>`;
    });
    const result = targetText.replace(regex, () => {
      return replacement[index++];
    });
    newObj.display = result;
  }
  return newObj;
};
export const CustomDeletePersonIcon = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 49.8 64.4"
    >
      <path d="M46,38.9c-3.4-2.3-8.6-3.8-14.5-4.6v-3.2c4.9-1.7,8-5.8,8-10.6v-4.6c0-6.6-5.5-11.5-13.3-11.5 s-13.4,5-13.4,11.4v4.6c0,5,3.1,8.9,8,10.6v3.2C10.5,35.8,2.3,39,2.3,45.9v9.2" />
      <circle cx="36.7" cy="51.3" r="10.9" />
      <line x1="31.3" y1="45.9" x2="42" y2="56.7" />
      <line x1="42" y1="45.9" x2="31.3" y2="56.7" />
    </svg>
  );
};

export const setModalAttribute = attrValue => {
  const inputElement = document.querySelector(".modal");
  inputElement.setAttribute("aria-label", attrValue);
};

export const getControlId = strValue => {
  let controlId = "";
  if (strValue && strValue.length > 0) {
    let newstrValue = strValue.replace(/[^a-zA-Z ]/g, "").trim(); //Remove special charactor
    newstrValue =
      newstrValue.length > 25 ? newstrValue.slice(0, 25) : newstrValue;
    controlId = newstrValue.replace(/\s+/g, "-").toLowerCase();
  }
  return controlId;
};

export const skipMainContent =()=> {
  
   if (document.getElementById("headingMain") !== "undefined" 
  && document.getElementById("headingMain") !== null) {
    document.getElementById("headingMain").setAttribute("tabIndex", "-1");
    document.getElementById("headingMain").focus();
  } 
  else if (document.getElementById("login-container") !== "undefined" 
  && document.getElementById("login-container") !== null ) {
    document.getElementById("login-container").setAttribute("tabIndex", "-1");
    document.getElementById("login-container").focus();
  }
  else if (document.getElementById("maincontent") !== "undefined" 
  && document.getElementById("maincontent") !== null ) {
    document.getElementById("maincontent").setAttribute("tabIndex", "-1");
    document.getElementById("maincontent").focus();
  } 
  else if (document.getElementById("headingWelcome") !== "undefined" 
  && document.getElementById("headingWelcome") !== null) {
    document.getElementById("headingWelcome").setAttribute("tabIndex", "-1");
    document.getElementById("headingWelcome").focus();
  } 
  else if (document.getElementById("mainWelcome") !== "undefined" 
  && document.getElementById("mainWelcome") !== null ) {
    document.getElementById("mainWelcome").setAttribute("tabIndex", "-1");
    document.getElementById("mainWelcome").focus();
  } 
  else if (document.getElementById("heading2FA") !== "undefined" 
  && document.getElementById("heading2FA") !== null) {
    document.getElementById("heading2FA").setAttribute("tabIndex", "-1");
    document.getElementById("heading2FA").focus();
  } 
  else if (document.getElementById("headingenrollmentconfirmation") !== "undefined"  
  && document.getElementById("headingenrollmentconfirmation") !== null) {
    document.getElementById("headingenrollmentconfirmation").setAttribute("tabIndex", "-1");
    document.getElementById("headingenrollmentconfirmation").focus();
  } 
  else if (document.getElementById("enrollment-summary") !== "undefined" 
  && document.getElementById("enrollment-summary") !== null) {
    document.getElementById("enrollment-summary").setAttribute("tabIndex", "-1");
    document.getElementById("enrollment-summary").focus();
  } 
  else if (document.getElementById("main-content") !== "undefined" &&
    document.getElementById("main-content") !== null) {
    document.getElementById("main-content").setAttribute("tabIndex", "-1");
    document.getElementById("main-content").focus();
  }
  else if(document.getElementById("coverageReport")!=='undefined' 
  && document.getElementById("coverageReport") !==null){
    document.getElementById("coverageReport").setAttribute("tabIndex", "-1");
    document.getElementById("coverageReport").focus();
  }
  else if(document.getElementById("mainpage")!=='undefined' 
  && document.getElementById("mainpage") !==null){
    document.getElementById("mainpage").setAttribute("tabIndex", "-1");
    document.getElementById("mainpage").focus();
  }
};
