import React, { Component, Fragment } from 'react';
import { Form } from 'react-bootstrap';
import defaults from '../helpers/defaults';
import ViewEditAddress from './ViewEditAddress';
import {getControlId} from "../utils";


class  AddressComponent extends Component {

    state = {
        country: ''
    }

    render() {
        const address = this.props.address || defaults.address
        const editMode = this.props.mode === 'edit' ? true : false;
        const type = this.props.type ? this.props.type : "text";
        const labelC = <Form.Label className="edit-input-label" htmlFor={getControlId(this.props.address1.textToRead)}>{this.props.display}</Form.Label>
        const {regpostalcodecanadaerrormessage, regpostalcodeuserrormessage,regaddress1errormessage,regaddress2errormessage,regcityerrormessage}= this.props
        let addressC = null
        if (editMode) {
            addressC =  <div className="address-input">
                            <Form.Control id={getControlId(this.props.address1.textToRead)} maxLength={50}  name="address1" className="col-sm-12 login-input-field edit-input-field mb-3" placeholder={this.props.address1.display}   type={type} {...this.props} value={address.address1}/>
                            
                            { (this.props.validationAddress.test(address.address1.trim()) ?
                                '' : (<Fragment><div className={`ml-error`}><span  role='alert'  dangerouslySetInnerHTML={{ __html: regaddress1errormessage}} /></div></Fragment>))
                               
                            }

                            <Form.Control maxLength={50} name="address2" className="col-sm-12 login-input-field edit-input-field mb-3" placeholder={this.props.address2.display}  type={type} {...this.props} value={address.address2}/>
                            { (this.props.validationAddress.test(address.address2.trim()) ?
                                '' : (<Fragment><div className={`ml-error`}><span  role='alert'  dangerouslySetInnerHTML={{ __html: regaddress2errormessage}} /></div></Fragment>))
                               
                            }
                            <Form.Control maxLength={50} name="city" className="col-sm-4 login-input-field edit-input-field mb-3" placeholder={this.props.city.display}  type={type} {...this.props} value={address.city}/>
                            {  (this.props.validationAddress.test(address.city.trim()) ?
                                '' : (<Fragment><div className={`ml-error`}><span  role='alert'  dangerouslySetInnerHTML={{ __html: regcityerrormessage}} /></div></Fragment>))
                               
                            }
                            {/* <Form.Control name="provinceCode" className="col-sm-2 login-input-field edit-input-field" placeholder={lblEditProvince.display} title={lblEditProvince.textToRead} aria-label={lblEditProvince.textToRead}  type={type} {...this.props} value={address.provinceCode}/> */}
                            <ViewEditAddress
                                type="select"
                                aria-label={this.props.provincecode.textToRead}
                                className="mb-3"
                                mode={'edit'}
                                name="provinceCode"
                                value={address.provinceCode}
                                onChange={this.props.onChange}
                                options={this.props.provinceslist}
                            />
                            <Form.Control name="postalCode" className="col-sm-4 login-input-field edit-input-field" placeholder={this.props.postalcode.display}  type={type} {...this.props} value={address.postalCode}/>
                            <ViewEditAddress
                                type="select"
                                aria-label={this.props.countrycode.textToRead}
                                mode={'edit'}
                                name="countryCode"
                                text={address.countryCode}
                                value={address.countryCode}
                                options={this.props.countrieslist}
                                onChange={this.props.onChange}
                                // errormessage={this.state.errorMessage.gender}
                            />
                            {address.countryCode === '' && ''}
                            {address.countryCode === 'CA' ? (this.props.validationPostalCodeCanada.test(address.postalCode) ?
                                '' : (<Fragment><div className={`ml-error`}><span  role='alert'  dangerouslySetInnerHTML={{ __html: regpostalcodecanadaerrormessage}} /></div></Fragment>))
                                : ''
                            }
                            {address.countryCode === 'US' ? (this.props.validationPostalCodeUS.test(address.postalCode) ?
                                '' : (<Fragment><div className={`ml-error`}><span  role='alert'  dangerouslySetInnerHTML={{ __html: regpostalcodeuserrormessage}} /></div></Fragment>))
                                : ''
                            }
                        </div>
        } else {
            addressC = 
                <label>
                    {`${address.address1 ? address.address1+'-' : ''}${address.address2 ? address.address2+'-' : ''}${address.city ? address.city+'-' : ''}${address.provinceCode ? address.provinceCode+'-' : ''}${address.postalCode ? address.postalCode+'-' : ''}${address.countryCode ? address.countryCode : ''}`} 
                </label> }
        return (<div className="edit-address">{labelC} {addressC}</div>);
    }
}

export default AddressComponent;
