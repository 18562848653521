import axios from "axios";
import { USER_TOKEN, DEFAULT_HEADER } from "../helpers/storage";
import { history } from "../components/App";
import { idleTimer } from "../actions/userActions";

/*
const production_server = "https://flexit.websincdev.ca/restapiinternal/api/";//"https://flexit.websincdev.ca/restapi/api";
const production_server = "https://flexit.websincdev.ca/restapi/api";
const stage_server = "https://api-flexit360.mobilelive.ca/mock/api";
const mock_server = "http://localhost:1024/mock/api";
*/
const production_server = window.REST_API_ENDPOINTS.api_server;

/**
 *
 * Change server_address value if you want change the service endpoint:
 * * production_server: Flexit360 Production endpoint,
 * * stage_server: ML staging server,
 * * mock_server:localhost, you need to run the mock api project on your machine on port 1024.
 *
 * */
const server_address = production_server;

console.log(`* * * Rest API Endpoint:${production_server} * * *`);
const instance = axios.create({
  baseURL: server_address,
  xsrfCookieName: "XSRF-TOKEN",
  xsrfHeaderName: "X-XSRF-TOKEN"
});

const getLoginHistory = () => {
  let nVer = navigator.appVersion;
  let userAgent = navigator.userAgent;

  var browserVersion = "" + parseFloat(navigator.appVersion);
  var majorVersion = parseInt(navigator.appVersion, 10);
  let nameOffset, verOffset, ix;

  let browserCodeName = (function (agent) {
    switch (true) {
      case agent.indexOf("edge") > -1:
        verOffset = agent.indexOf("edge");
        browserVersion = agent.substring(verOffset + 4);
        return "Edge";
      case agent.indexOf("edg/") > -1:
        verOffset = agent.indexOf("edg/");
        browserVersion = agent.substring(verOffset + 4);
        return "Edge";
      case agent.indexOf("opr") > -1 && !!window.opr:
        verOffset = agent.indexOf("opr");
        return "Opera";
      case agent.indexOf("chrome") > -1 && !!window.chrome:
        verOffset = agent.indexOf("chrome");
        browserVersion = agent.substring(verOffset + 7);
        return "Google Chrome";
      case agent.indexOf("trident") > -1:
        verOffset = agent.indexOf("trident");
        browserVersion = agent.substring(verOffset + 5);
        return "MS IE";
      case agent.indexOf("firefox") > -1:
        verOffset = agent.indexOf("firefox");
        return "Mozilla Firefox";
      case agent.indexOf("safari") > -1:
        verOffset = agent.indexOf("safari");
        browserVersion = agent.substring(verOffset + 7);
        if ((verOffset = agent.indexOf("Version")) != -1) browserVersion = agent.substring(verOffset + 8);
        return "Safari";
      default:
        if ((nameOffset = agent.lastIndexOf(" ") + 1) < (verOffset = agent.lastIndexOf("/"))) {
          browserVersion = agent.substring(verOffset + 1);
        }
        return agent.substring(nameOffset, verOffset);
      // return "Other";
    }
  })(userAgent.toLowerCase());

  // trim the browserVersion string at semicolon/space if present
  if ((ix = browserVersion.indexOf(";")) != -1) browserVersion = browserVersion.substring(0, ix);
  if ((ix = browserVersion.indexOf(" ")) != -1) browserVersion = browserVersion.substring(0, ix);
  // console.log("browser_version = ", browserVersion);

  majorVersion = parseInt("" + browserVersion, 10);
  if (isNaN(majorVersion)) {
    browserVersion = "" + parseFloat(navigator.appVersion);
    majorVersion = parseInt(navigator.appVersion, 10);
  }

  let OSName = "Unknown OS";
  // console.log("userAgent: ", userAgent);
  if (navigator.appVersion.indexOf("Win") != -1) OSName = "Microsoft Windows";
  if (navigator.appVersion.indexOf("Mac") != -1) OSName = "MacOS";
  if (navigator.appVersion.indexOf("X11") != -1) OSName = "UNIX";
  if (navigator.appVersion.indexOf("Linux") != -1) OSName = "Linux";
  if (/android/i.test(userAgent)) OSName = "Android";
  if (/iPad|iPhone|iPod/.test(userAgent) || (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)) OSName = "iOS";

  let viewportOrientation = "";
  if (window.matchMedia("(orientation: portrait)").matches) viewportOrientation = "portrait";
  if (window.matchMedia("(orientation: landscape)").matches) viewportOrientation = "landscape";

  return JSON.stringify({
    browserCodeName,
    browserName: navigator.appName,
    browserVersion,
    majorVersion,
    browserCookieEnabled: navigator.cookieEnabled,
    browserLanguage: navigator.language,
    isOnline: navigator.onLine,
    Platform: navigator.platform,
    OSName,
    screenResolution: window.innerWidth + "x" + window.innerHeight,
    viewportOrientation
  });
};

/** IMPORTANT */
instance.interceptors.request.use(
  config => {
    const isSignedIn = JSON.parse(sessionStorage.getItem("isSignedIn")) || false;
    config.url !== "/Account/refreshtoken" && isSignedIn && idleTimer(history);
    //console.log("----> HEADER BEFORE: ", config.headers)
    if (config.headers.DeviceType) {
      let deviceType = config.headers.DeviceType;
      config.headers = DEFAULT_HEADER.get();
      config.headers.DeviceType = deviceType;
    } else {
      config.headers = DEFAULT_HEADER.get();
      let loginHistory = sessionStorage.getItem("loginHistory");
      if (config?.url === "/Home/Welcome" && !loginHistory) {
        // console.log("Login History: ", JSON.parse(getLoginHistory()));
        sessionStorage.setItem("loginHistory", true);
        config.headers.LoginHistory = getLoginHistory();
      }
    }

    //console.log("<----> HEADER AFTER: ", config.headers)
    return config;
  },
  error => Promise.reject(error)
);

instance.interceptors.response.use(
  async response => {
    return response;
  },
  async error => {
    if (USER_TOKEN.get().isSignedIn) {
      const { response } = error;

      if (response && (response.status === 403 || response.status === 401)) {
        USER_TOKEN.remove();
        history.push("/logoff");
      }
      return Promise.reject(error);
    }
  }
);

// Handle token expiry
export default instance;
