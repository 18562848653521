import React, { Component } from "react";
import { connect } from "react-redux";
import { logoff, validateTFACode, setPageSetting } from "../../actions/userActions";
import {
  getTFARegSelection,
  sendVerificationCode,
  verificationContent,
  showError,
  showSuccess,
  dissmiss
} from "../../actions/contentActions";
import { Redirect, withRouter } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import { USER_TOKEN } from "../../helpers/storage.js";
import VerificationForm from "./VerificationForm";

class Verification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resendCount: 0,
      TypeID: "",
      IsRegistration: false
    };
  }

  componentDidMount() {
    const { needTwoFARegistration, needTwoFAValidation, twoFATypeID } = this.props;

    const options = {
      PhoneOrEmail: "",
      TypeID: twoFATypeID,
      IsRegistration: !needTwoFARegistration && needTwoFAValidation ? false : true,
      resendCount: this.state.resendCount
    };
    this.setState({
      IsRegistration: options.IsRegistration,
      TypeID: twoFATypeID
    });
    localStorage.setItem("TypeID", twoFATypeID);
    localStorage.setItem("isRegistration", options.IsRegistration);

    /* if (this.props.pageSetting === "undefined" || (this.props.pageSetting && this.props.pageSetting.name !== "MFA")) {
      this.props.setPageSetting("MFA");
    } */
    this.verificationContent(options);
  }

  failureCallback = (msg) => {
    this.props.showError(msg ? msg : "Please enter a valid contact number.");
  };
  handleResend = () => {
    const totalCount = this.state.resendCount + 1;
    this.setState({ resendCount: totalCount });
    const options = {
      PhoneOrEmail: "",
      TypeID: this.state.TypeID,
      IsRegistration: this.state.IsRegistration,
      resendCount: totalCount
    };
    this.verificationContent(options);
  };

  verificationContent = (options) => {
    const params = "isRegistration=" + this.state.IsRegistration + "&selectedTwoFAType=" + options.TypeID;
    if (options.TypeID === 4) {
      trackPromise(this.props.verificationContent(params, this.props.history));
    } else {
      trackPromise(
        this.props.sendVerificationCode(options, this.failureCallback).then((data) => {
          if (!data && data === undefined) {
          } else {
            this.props.dissmiss();
            if (options.resendCount > 0) {
              this.props.showSuccess("Verification code resent successfully.");
            }
            trackPromise(this.props.verificationContent(params, this.props.history));
          }
        })
      );
    }
  };

  renderVerficationForm() {
    const hasVerifiedContent = Object.keys(this.props.verifiedContent).length > 0 ? true : false;
    const option = parseInt(this.state.TypeID);
    return (
      hasVerifiedContent && (
        <VerificationForm
          verifiedContent={this.props.verifiedContent}
          resendCodeInterval={this.props.resendCodeInterval}
          twoFATypeId={option}
          handleResend={this.handleResend}
          resendCount={this.state.resendCount}
          validateTFACode={this.props.validateTFACode}
          history={this.props.history}
          failureCallback={this.props.failureCallback}
          showError={this.props.showError}
          showSuccess={this.props.showSuccess}
        />
      )
    );
  }

  render() {
    const { needTwoFARegistration, needTwoFAValidation } = this.props;
    if (needTwoFARegistration === "false" && needTwoFAValidation === "false") {
      return <Redirect to="/" />;
    } else {
      return this.renderVerficationForm();
    }
  }
}

const mapStateToProps = (state) => {
  return {
    client: state.contentReducer.client,
    needTwoFARegistration: USER_TOKEN.get().needTwoFARegistration,
    needTwoFAValidation: USER_TOKEN.get().needTwoFAValidation,
    twoFATypeID: USER_TOKEN.get().twoFATypeID,
    verifiedContent: state.contentReducer.verificationContent ? state.contentReducer.verificationContent : [],
    resendCodeInterval: state.contentReducer.resendCodeInterval,
    pageSetting: state.userReducer.pageSetting
  };
};

export default connect(mapStateToProps, {
  getTFARegSelection,
  sendVerificationCode,
  verificationContent,
  validateTFACode,
  logoff,
  showError,
  showSuccess,
  dissmiss,
  setPageSetting
})(withRouter(Verification));
