import React, { Component } from "react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { CssVarsPonyfill } from "css-vars-ponyfill-react";
import Header from "./Header";
import Content from "./Content";
import Footer from "./Footer";
import store from "../helpers/store.js";
import { createBrowserHistory, createMemoryHistory } from "history";
import { toggleSideBar } from "../actions/userActions";
import ErrorComponent from "./ErrorComponent";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";
import _ from "underscore";
import withClearCache from "../ClearCache";

export const history = (!document.isJest && createBrowserHistory()) || createMemoryHistory();
const IS_SIGNED_IN = "isSignedIn";
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      clientName: "",
      isExternal: false,
      clientID: -1,
      iconList: []
    };
  }

  componentDidMount() {
    store.subscribe(() => {
      if (
        this.state.clientName !== store.getState().contentReducer.clientStyle.WebsiteType &&
        !(
          Object.keys(store.getState().contentReducer.clientStyle).length === 0 &&
          store.getState().contentReducer.clientStyle === Object
        )
      ) {
        this.setState(
          {
            isLoading: true,
            clientName: store.getState().contentReducer.clientStyle.WebsiteType,
            isExternal: store.getState().contentReducer.clientStyle.ClientOverrideRequired,
            clientID: store.getState().contentReducer.clientStyle.ClientID
          },
          () => this.setManifestURL()
        );
      }
    });
  }

  setManifestURL() {
    if (this.state.clientID && this.state.clientID !== -1) {
      const manifestURL = `/manifests/${this.state.clientName.toLocaleLowerCase()}/manifest.json`;
      const manifestEl = document.getElementById("manifest-pwa");

      fetch(`${manifestURL}`)
        .then((response) => {
          if (!response.ok) throw Error("JSON Not Found");
          return response.json();
        })
        .then((data) => {
          this.setState({ iconList: data.icons });
          manifestEl.setAttribute("href", manifestURL);
        })
        .catch((error) => {
          console.log("ERROR Loading Manifest: ", error);
          this.setState({ iconList: [] });
          manifestEl && manifestEl.removeAttribute("href");
        });
    }
  }

  componentWillUnmount() {
    try {
      withClearCache();
      store.unsubscribe();
    } catch (e) {
      console.log(e.message);
    }
  }

  onSwipe(value) {
    if (sessionStorage.getItem(IS_SIGNED_IN) && sessionStorage.getItem(IS_SIGNED_IN) === "true") {
      if (!/\/changepassword$/g.test(window.location.pathname)) {
        store.dispatch(toggleSideBar(value));
      }
    }
  }

  render() {
    //TODO : TEMPORARY TO TEST STUFF
    let clientName = this.state.clientName
      ? this.state.clientName === "GWL"
        ? "canadalife"
        : this.state.clientName === "Standard" && this.state.isExternal
        ? "telus"
        : this.state.clientName
      : "";
    document.body.className = `ml-layout${clientName.toLowerCase()}`;
    if (clientName !== "") {
      CssVarsPonyfill({
        externalStyleSheets: true,
        onError: (err) => {
          console.log("Error processing stylesheet : ", err);
        }
      });
    }
    const appleTouchIcons = [];
    _.forEach(this.state.iconList, (icon) => {
      appleTouchIcons.push(
        <link key={`apple-icon-${icon.sizes}-ID`} rel="apple-touch-icon" sizes={icon.sizes} href={icon.src} />
      );
    });
    const faviconURL = this.state.clientName
      ? `/assets/favicons/${this.state.clientName.toLowerCase()}/favicon.ico`
      : "/favicon.ico";
    return (
      <Provider store={store}>
        <div className={`App`}>
          <Helmet>
            <link rel="shortcut icon" href={faviconURL} />
            {appleTouchIcons}
          </Helmet>
          <Router history={history}>
            <div className="page-container">
              <div className="content-wrap">
                {isMobile ? (
                  <>
                    <ErrorComponent>
                      {this.state.isLoading && <Header isMenuOpen={this.state.showMenu} />}
                      <Content />
                    </ErrorComponent>
                  </>
                ) : (
                  <ErrorComponent>
                    {this.state.isLoading && <Header />}
                    <Content />
                  </ErrorComponent>
                )}
              </div>
              <Footer />
            </div>
          </Router>
        </div>
      </Provider>
    );
  }
}
export default App;
