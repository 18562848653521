import React, { Component }  from 'react';
import { connect } from "react-redux";
import { reviewPastCoverage, pastCoverageList, pdfStatement, setPageSetting } from "../../actions/userActions"
import "./coverage.css"
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { isMobile } from "react-device-detect";
import { trackPromise } from 'react-promise-tracker';
import { getDisplayText } from '../../utils';
import DisplayButton from '../button';
import _ from 'underscore';

class Coverage extends Component {
    state={
        pastCoverage: "",
        saveBtn: this.props.pastCoverage.buttonSavePDF.display
    }
    componentDidMount(){
      trackPromise(this.props.reviewPastCoverage(localStorage.getItem('dateString'), () => {
            this.setState({
                pastCoverage: this.props.pastCoverage.html,
                saveBtn: this.props.pastCoverage.buttonSavePDF.display
            })
        })
        )
    }
    static getDerivedStateFromProps(props, state) {
		if (props.pastCoverage.buttonSavePDF.display !== state.saveBtn) {
			return {
			saveBtn: props.pastCoverage.buttonSavePDF.display,
			pastCoverage: props.pastCoverage.html
		  };
		}
		return null;
      }


    handleClick = (e) => {
    e.preventDefault()
    this.props.pdfStatement(this.props.pastCoverage.buttonSavePDF.btnNavigateTo, localStorage.getItem('dateString'))
    
    }

    previousPage = () => {
      if(localStorage.getItem('pageName')){
        const menuItem = _.find(this.props.screens, item => item.name === localStorage.getItem('pageName'))
        trackPromise(this.props.setPageSetting(localStorage.getItem('pageName')))
        localStorage.removeItem('pageName')
        this.props.history.push(`/${menuItem ? menuItem.link : ""}`)
      } else 
        this.props.history.push(`/${this.props.pageSetting.link ? this.props.pageSetting.link : ''}`)
    }

    render(){    
    const backButtonText = getDisplayText((this.props.contentFooter.contentResource && this.props.contentFooter.contentResource.contents) || {}, 'btnBack')
			return (
      <div className={`coveragewrapper`}>
				<div className={`coveragebox clearfix`}>
        <div className="pt-4 pb-4 mb-4 btnbox d-flex justify-content-between align-items-center">
          <div className={`d-flex titlewithicon`}>
            {isMobile ?
              <DisplayButton className="icon-action" displayName={backButtonText.display} icon={faChevronLeft} onClick={() => this.previousPage()} /> 
              :''
            }
          </div>
          <DisplayButton controlId='navigateclick' displayName={this.state.saveBtn} onClick={(e) => {this.handleClick(e)}} className={`btn btn-outline-secondary float-right`} />
				</div>
					<div dangerouslySetInnerHTML={ { __html: this.state.pastCoverage } } className={`reviewcoverage clearfix`}></div>
					<div className={`clearfix`}></div>
					<DisplayButton controlId='saveclick' displayName={this.state.saveBtn} onClick={(e) => {this.handleClick(e)}} className={`btn btn-outline-secondary float-right`} />
				</div>
			</div>)
		}
}
const mapStateToProps = (state) => {
    return {
      pastCoverage: state.userReducer.pastCoverage,
      contentFooter: state.userReducer.contentFooter,
      screens: state.contentReducer.screens,
      pageSetting: state.userReducer.pageSetting,
    }
};

export default connect(
mapStateToProps,
{ reviewPastCoverage, pastCoverageList, pdfStatement, setPageSetting }
)(Coverage);