import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import React, { Component, Fragment } from "react";
import { Card, Container, Form } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "underscore";
import { dissmiss } from "../actions/contentActions";
import {
  getBenefitSummary,
  getSummaryBeneficiariesESignature,
  setFlexBeneficiaryID,
  setPageSetting,
  summarySaveESignature
} from "../actions/userActions";
import { getDisplayText } from "../utils";
import DisplayButton from './button';

class SummaryESignBeneficiaries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSelected: false,
      verificationText: "",
      isAgreed: false,
      verifyTypeToSign: ""
    };
  }

  setValue = e => {
    this.setState({ isAgree: true });
  };

  handleValue = event => {
    this.setState({ isSelected: !this.state.isSelected });
  };

  componentDidMount() {
    const { pathname, search } = this.props.location;
    const actionLink = pathname + search;
    trackPromise(this.props.getSummaryBeneficiariesESignature(actionLink));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.summaryBeneficiaries) {
      const eSignatureConfirmation =
        nextProps.summaryBeneficiaries.eSignatureConfirmation || {};
      this.setState({
        verifyTypeToSign: eSignatureConfirmation.verifyTypeToSign
      });
    }
  }

  componentWillUnmount() {
    this.props.dissmiss();
  }

  handleEditAction = (link, linkID) => {
    const benefitName = link.split("/")[0];
    const actionLink = `${benefitName}/${linkID}`;
    let menuItem;
    if (this.props.deviceType === "Desktop") {
      _.each(this.props.sideBarMenu, (item, index) => {
        if (item.link instanceof Array) {
          let obj = _.where(item.link, { actinLink: actionLink.toUpperCase() });
          if (obj && obj.length !== 0) {
            menuItem = item;
            return;
          }
        }
      });
      this.props.setPageSetting(
        menuItem ? menuItem.name : "WELCOME",
        true,
        true
      );
      this.props.setFlexBeneficiaryID(linkID, () => {
        this.props.history.push("/Beneficiaries");
      });
    } else {
      menuItem = _.find(
        this.props.sideBarMenu,
        item =>
          (item.link || "").toUpperCase() === (actionLink || "").toUpperCase()
      );
      this.props.setPageSetting(
        menuItem ? menuItem.name : "WELCOME",
        true,
        false,
        () => {
          this.props.history.push("/Beneficiaries");
        }
      );
    }
  };

  onDownKeyPressed = (event, editButtonLink, editButtonLinkID) => {
    switch (event.which) {
      case 13: {
        this.handleEditAction(editButtonLink, editButtonLinkID);
        break;
      }
      default:
        break;
    }
  };

  renderBeneficiarieList(
    editButtonText,
    title,
    note,
    editButton,
    thcPercent,
    list,
    customClass
  ) {
    if (list && list.length > 0) {
      const items = [];
      _.forEach(list, item => {
        items.push(
          <div
            key={`div-${title}-${editButton.linkID}-ID-${
              item.benefitName
            }-${Math.random()}`}
            className="d-flex justify-content-between bgrow"
          >
            <div>
              <span>
                {item.beneficiaryName}{/*  - {item.relationShip} {item.dateOfBirth} */}
              </span>
            </div>
            {(item.removedMsg==="" || item.removedMsg===null)?<div>{item.percentage}%</div>:
             <div><span className="ml-mandatory-sign">({item.removedMsg})</span> {item.percentage}%</div>}
          </div>
        );
      });
      return (
        <Card className={`ccard ${customClass}`}>
          <Card.Header>
            <div className="d-flex justify-content-between">
              <div><h2>{title}</h2></div>
              <div>
                <h2>{thcPercent.display}</h2>
              </div>
            </div>
          </Card.Header>
          <Card.Body>{items}</Card.Body>
          {note && (
            <Card.Footer>
              <div className="alert alert-primary" role='alert'>
                {title} <strong>{note}</strong>
              </div>
            </Card.Footer>
          )}
        </Card>
      );
    }

    return null;
  }

  renderNoBeneficiariesMessage(title, message, thcPercent) {
    return (
      <Card className="ccard onfilebeneficiaries">
        <Card.Header>
          <div className="d-flex justify-content-between">
            <div><h2>{title}</h2></div>
            <div>
              <h2>{thcPercent.display}</h2>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <Fragment>
            <div
              className="bgrow"
              dangerouslySetInnerHTML={{ __html: message }}
            />
          </Fragment>
        </Card.Body>
      </Card>
    );
  }

  handleVerificationText = event => {
    const verificationText = event.target.value;
    const isAgreed =
      this.state.verifyTypeToSign === event.target.value ? true : false;
    this.setState({ isAgreed, verificationText });
  };

  onSave = event => {
    const beneficiaryBackActionLink =
      this.props.summaryBeneficiaries.beneficiaryBackActionLink || "";
    const search = new URLSearchParams(this.props.location.search);
    const beneficiaryall = search.get("beneficiaryall");
    trackPromise(
      this.props.summarySaveESignature(
        this.state.isAgreed || this.state.isSelected ? 1 : 0,
        this.state.verificationText,
        beneficiaryall,
        () => {
          if (this.props.onSaveESign.statusCode) {
            this.props.history.push(
              beneficiaryBackActionLink
                ? beneficiaryBackActionLink.backActionlink
                : "/Summary"
            );
          }
        }
      )
    );
  };
  render() {
    const pageTitle = this.props.summaryBeneficiaries.tabName;
    const beneficiaryBenefitsInfo = this.props.summaryBeneficiaries
      .beneficiaryBenefitsInfo
      ? this.props.summaryBeneficiaries.beneficiaryBenefitsInfo
      : [];
    const content = this.props.summaryBeneficiaries.contentResource
      ? this.props.summaryBeneficiaries.contentResource
      : {};
    const controlResource = content.controlResource
      ? content.controlResource.Beneficiaries
      : {};
    const contents = content.contents ? content.contents : {};

    const eSignatureConfirmation = this.props.summaryBeneficiaries
      .eSignatureConfirmation
      ? this.props.summaryBeneficiaries.eSignatureConfirmation
      : {};
    const beneficiaryBackActionLink =
      this.props.summaryBeneficiaries.beneficiaryBackActionLink || "";

    const btnSave = getDisplayText(contents, "btnSave");
    const lblCancel = getDisplayText(contents, "btnCancel");
    const thcPercent = getDisplayText(controlResource, "thcPercent");
    const beneficiaryMessage = this.props.summaryBeneficiaries
      .beneficiariesMessage;
    const noBeneficiaryBenefits = this.props.summaryBeneficiaries
      .noBeneficiaryBenefits
      ? this.props.summaryBeneficiaries.noBeneficiaryBenefits
      : "";
    const noCoverage = this.props.summaryBeneficiaries.noCoverage
      ? this.props.summaryBeneficiaries.noCoverage
      : "";
    const editIcon = getDisplayText(
      (this.props.contentFooter.contentResource &&
        this.props.contentFooter.contentResource.controlResource) ||
        {},
      "EditIcon"
    );
  
    const backButtonText = getDisplayText(
      (this.props.contentFooter.contentResource &&
        this.props.contentFooter.contentResource.contents) ||
        {},
      "btnBack"
    );
    document.title = pageTitle;
    const beneficiaryList = [];
    _.forEach(beneficiaryBenefitsInfo, benefit => {
      const onFile = this.renderBeneficiarieList(
        editIcon.textToRead,
        benefit.lblOnFile,
        null,
        benefit.editButton,
        thcPercent,
        benefit.beneficiaryCurrentInfo,
        benefit.noBeneficiariesNote,
        "onfilebeneficiaries"
      );
      const contingentOnFile = this.renderBeneficiarieList(
        editIcon.textToRead,
        benefit.lblContingentOnFile,
        null,
        benefit.editButton,
        thcPercent,
        benefit.contingentBeneficiaryCurrentInfo,
        benefit.noBeneficiariesNote,
        "onfilebeneficiaries"
      );
      const pending = this.renderBeneficiarieList(
        editIcon.textToRead,
        benefit.pending,
        benefit.pendingNote,
        benefit.editButton,
        thcPercent,
        benefit.beneficiaryPendingInfo,
        benefit.noBeneficiariesNote,
        "pendingbeneficiaries"
      );
      const contingentPending = this.renderBeneficiarieList(
        editIcon.textToRead,
        benefit.lblContingentPending,
        benefit.pendingNote,
        benefit.editButton,
        thcPercent,
        benefit.contingentBeneficiaryPendingInfo,
        benefit.noBeneficiariesNote,
        "pendingbeneficiaries"
      );
      const noBeneficiariesMessage = this.renderNoBeneficiariesMessage(
        benefit.lblOnFile,
        benefit.noBeneficiariesNote,
        thcPercent
      );
      beneficiaryList.push(
        <Card
          key={`div-${benefit.benefitID}-ID-${benefit.benefitName}`}
          className="mb-4 insurancetype pcard"
        >
          <Card.Header><h2 className="heading-white">{benefit.benefitName}</h2></Card.Header>
          <Card.Body>
            {benefit.noBeneficiariesNote ? (
              <div className="clearfix">{noBeneficiariesMessage}</div>
            ) : (
              <>
                {onFile}
                {contingentOnFile}
                {pending}
                {contingentPending}
              </>
            )}
          </Card.Body>
        </Card>
      );
    });
    /* E-Signature Start */
    let disableSaveBtn = !this.state.isSelected;
    if(eSignatureConfirmation.enableTypeToSign) {
      disableSaveBtn = !this.state.isAgreed || !this.state.isSelected;
    }
    /* E-Signature End */
    const cardFooterBtns = {
      handleCancel: (
        <DisplayButton
          className="outline-secondary btn-cancel"
          onClick={() => {
            this.props.history.push(beneficiaryBackActionLink ? beneficiaryBackActionLink.backActionlink : "/Summary");
          }}
          displayName={lblCancel.display}
        />
      ),
      handleSave: (
        <DisplayButton
          className="outline-secondary btn-save"
          onClick={this.onSave}
          disabled={disableSaveBtn}
          displayName= {btnSave.display}
       / >
      )
    };

    return (
      <div className="summary summarybeneficiaries beneficiariesall baneficiarylist clearfix">
        <div className={`d-flex titlewithicon mb-3`}>
        <DisplayButton className="icon-action" displayName={backButtonText.display} icon={faChevronLeft} onClick={() => {
              this.props.history.push(
                beneficiaryBackActionLink
                  ? beneficiaryBackActionLink.backActionlink
                  : "/Summary"
              );
            }} /> 
        </div>
        {noBeneficiaryBenefits && (
          <div className="alert alert-dark" role='alert'>{noBeneficiaryBenefits}</div>
        )}
        {noCoverage && (
          <div className="alert alert-secondary" role='alert'>{noCoverage}</div>
        )}
        {!noCoverage && !noBeneficiaryBenefits && (
          <Card className="mb-4 insurancetype pcard">
            <Card.Header>
              <h2 className="heading-white">{pageTitle}</h2>
            </Card.Header>
            <Card.Body className="pcard">{beneficiaryList}</Card.Body>
          </Card>
        )}
        {beneficiaryMessage && (
          <div className="alert alert-info" role='alert'>
            {beneficiaryMessage}
          </div>
        )}
        {eSignatureConfirmation && (
          <div className="esignable-container">
            <Container className="mb-3 pb-3 d-flex flex-wrap">
              <div className="scroll-container" tabIndex="0">
                <p
                  className="mb-3 form-check-label warning"
                  htmlFor="lblEsignableMessage"
                  dangerouslySetInnerHTML={{
                    __html: eSignatureConfirmation.declaration
                  }}
                />
              </div>
            </Container>
            <div className="clearfix form-check">
              <input
                type="checkbox"
                key="confirmSelection"
                className=""
                id="confirmSelection"
                checked={this.state.isSelected}
                onChange={() => {
                  this.handleValue();
                }}
              />
              <label
                className="mb-3 pb-3 form-check-label warning"
                htmlFor="confirmSelection"
              >
                {eSignatureConfirmation.agreement}
              </label>
            </div>
            {eSignatureConfirmation.enableTypeToSign && (
              <Fragment>
                <label
                  className="mb-3 form-check-label warning"
                  htmlFor="enableTypeToSignText"
                  dangerouslySetInnerHTML={{
                    __html: eSignatureConfirmation.enableTypeToSignText
                  }}
                />
                <Form.Control
                  type="text"
                  aria-label={eSignatureConfirmation.enableTypeToSignText}
                  className="form-control login-input-field"
                  id="verificationText"
                  aria-labelledby="verificationText"
                  aria-required="true"
                  required
                  autoComplete="on"
                  value={this.state.verificationText}
                  onChange={this.handleVerificationText}
                />
              </Fragment>
            )}
            <div className="d-flex justify-content-between py-3">
              {cardFooterBtns.handleCancel}
              {cardFooterBtns.handleSave}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    summaryBeneficiaries: state.userReducer.summaryBeneficiaries
      ? state.userReducer.summaryBeneficiaries
      : {},
    sideBarMenu: state.contentReducer.screens,
    contentFooter: state.userReducer.contentFooter,
    deviceType: state.userReducer.deviceType || "",
    onSaveESign: state.userReducer.onSaveESign
      ? state.userReducer.onSaveESign
      : {}
  };
};

export default connect(mapStateToProps, {
  getSummaryBeneficiariesESignature,
  setPageSetting,
  dissmiss,
  setFlexBeneficiaryID,
  summarySaveESignature,
  getBenefitSummary
})(withRouter(SummaryESignBeneficiaries));
