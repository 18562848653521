import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component, Fragment } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Sticky from "react-stickynode";
import { Modal, ModalBody } from "reactstrap";
import _ from "underscore";
import { getMenu2 } from "../actions/contentActions";
import { getSummaryBenefits, setPageSetting } from "../actions/userActions";
import { CustomToolTipIcon, getDisplayText, setModalAttribute } from "../utils";
import DisplayButton from "./button";

class SummaryActiveSubscriptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNotes: false,
      description: "",
      header: "",
      tooltipId: "",
      tooltipNum: ""
    };
  }

  handleEditAction = (link, linkID) => {
    let notMulti = true;
    const benefitName = link.split("/")[0];
    const actionLink = `${benefitName}/${linkID}`;
    let menuItem;
    if (this.props.deviceType === "Desktop") {
      _.each(this.props.sideBarMenu, (item, index) => {
        if (item.link instanceof Array) {
          let obj = _.where(item.link, { actinLink: actionLink.toUpperCase() });
          if (obj && obj.length !== 0) {
            menuItem = { ...item };
            menuItem.link = "Benefits";
            notMulti = false;
            return;
          }
        }
      });
    } else {
      menuItem = _.find(this.props.sideBarMenu, (item) => (item.link || "").toUpperCase() === (actionLink || "").toUpperCase());
    }
    trackPromise(this.props.setPageSetting(menuItem ? menuItem.name : "WELCOME", true, notMulti));
    this.props.history.push(`/${menuItem ? menuItem.link : ""}`);
  };

  handleDownKeyPressed = (event, link, linkID) => {
    switch (event.which) {
      case 13: {
        event.preventDefault();
        this.handleEditAction(link, linkID);
        break;
      }
      default:
        break;
    }
  };

  toggleDescription = (opt, tooltipNum) => {
    const tooltipNumList = tooltipNum ? tooltipNum.split(",") : [];
    this.setState({
      showNotes: !this.state.showNotes,
      description: opt.toolTipDescription,
      header: opt.benefit,
      tooltipId: "showTooltip-noteNumber-" + tooltipNum,
      tooltipNum: tooltipNumList
    });
  };

  handleClose = () => {
    this.setState({ showNotes: false });
    if (document.getElementById(this.state.tooltipId) !== null) {
      document.getElementById(this.state.tooltipId).focus();
    }
  };

  handleInfoDetailThroughTab = (event, opt, tooltipIdValue) => {
    switch (event.which) {
      case 13: {
        this.toggleDescription(opt, tooltipIdValue);
        break;
      }
      default:
        break;
    }
  };

  render() {
    const enrolmentSummary = this.props.enrolmentSummary ? this.props.enrolmentSummary : {};
    const pageTitle = enrolmentSummary.tabName;
    document.title = pageTitle;
    // const benefitLabel = enrolmentSummary.activeSubscriptionsHeader.benefit
    // const optionLabel = enrolmentSummary.activeSubscriptionsHeader.option
    // const descLabel = enrolmentSummary.activeSubscriptionsHeader.description
    const costLabel = enrolmentSummary && enrolmentSummary.activeSubscriptionsHeader ? enrolmentSummary.activeSubscriptionsHeader.cost : "";
    const flexLabel = enrolmentSummary && enrolmentSummary.activeSubscriptionsHeader ? enrolmentSummary.activeSubscriptionsHeader.flex : "";
    const employerCostLabel = enrolmentSummary && enrolmentSummary.activeSubscriptionsHeader ? enrolmentSummary.activeSubscriptionsHeader.employerCost : "";
    const creditsAppliedLabel = enrolmentSummary && enrolmentSummary.activeSubscriptionsHeader ? enrolmentSummary.activeSubscriptionsHeader.creditsApplied : "";
    const deductionLabel = enrolmentSummary && enrolmentSummary.activeSubscriptionsHeader ? enrolmentSummary.activeSubscriptionsHeader.deduction : "";

    const activeFooter = enrolmentSummary && enrolmentSummary.activeSubscriptionsFooter ? enrolmentSummary.activeSubscriptionsFooter : {};
    const contentFooter = this.props.contentFooter;
    const toolTipIcon = getDisplayText((contentFooter && contentFooter.contentResource && contentFooter.contentResource.contents) || {}, "ToolTipIcon");
    const closeButtonText = getDisplayText((contentFooter && contentFooter.contentResource && contentFooter.contentResource.contents) || {}, "btnClose");

    const editIcon = getDisplayText(contentFooter, "EditIcon");
    const activeSubscriptions = [];
    const list = ((enrolmentSummary || {}).benefits || {}).activeSubscriptions || [];
    _.each(list, (benefit) => {
      const notesNumberList = benefit.noteNumber ? benefit.noteNumber.split("|") : [];
      let noteNumberString = "";
      _.each(notesNumberList, (note, i) => {
        noteNumberString += note - 1 + (i < notesNumberList.length - 1 ? "," : "");
      });
      activeSubscriptions.push(
        <div key={`div-${benefit.benefit}-ID`} className="mb-md-3 enrollmentitem">
          <Card className="m-0">
            <Card.Header>
              <div className="titleandicon d-flex position-relative pr-0">
                <div className="benefit-title flex-column">
                  <h2>
                    {benefit.benefit}
                    {noteNumberString && (
                      <span
                        tid="showTooltip"
                        role="button"
                        aria-label={benefit.benefit}
                        tabIndex="0"
                        className="tooltip-icon icon-action float-right"
                        onClick={() => this.toggleDescription(benefit, noteNumberString)}
                        onKeyDown={(event) => {
                          this.handleInfoDetailThroughTab(event, benefit, noteNumberString);
                        }}>
                        <CustomToolTipIcon />
                      </span>
                    )}
                  </h2>
                </div>
                <div className="benifit-option flex-column">
                  <h2 dangerouslySetInnerHTML={{ __html: benefit.description }} />
                </div>
                {/* <sup>{noteNumberString}</sup> */}
                {/* <span tid="showTooltip" id={"showTooltip-benefitID-"+benefit.benefitID+"-"+i} onKeyDown={} role='button' aria-label={toolTipIcon.textToRead} className="tooltip-icon icon-action float-right" onClick={() => this.toggleDescription(opt,"showTooltip-benefitID-"+benefit.benefitID+"-"+i)}></span> */}
                {/* <strong className="m-0 pr-3">{benefit.benefit} {benefit.option} ({benefit.description}) <sup>{noteNumberString}</sup></strong> */}
                <time className="pr-3" aria-hidden={!benefit.benefitEffectiveDate} aria-label={benefit.benefitEffectiveDate} dateTime={benefit.benefitEffectiveDate}>
                  {benefit.benefitEffectiveDate}
                </time>
                {benefit.editButton.enabled && (
                  <span
                    className="editicon icon-action"
                    role="button"
                    tid="click"
                    tabIndex="0"
                    onClick={() => {
                      benefit.editButton.enabled && this.handleEditAction(benefit.editButton.link, benefit.editButton.linkID);
                    }}
                    aria-label={editIcon.textToRead + "-" + benefit.benefit + " " + benefit.option + " " + benefit.description}
                    aria-disabled={!benefit.editButton.enabled}
                    onKeyDown={(event) => benefit.editButton.enabled && this.handleDownKeyPressed(event, benefit.editButton.link, benefit.editButton.linkID)}>
                    <FontAwesomeIcon style={!benefit.editButton.enabled && { opacity: 0.5, pointerEvents: "none" }} icon={faPencilAlt} />
                  </span>
                )}
              </div>
            </Card.Header>
            <Card.Body className="p-md-0">
              <Row className="justify-content-end activesubscription-rows">
                <Col className="col-12 col-sm-8">
                  <Row className="valueboxes">
                    <Col>
                      <span className="visuallyhidden">{costLabel}</span>
                      {benefit.annualCost}
                    </Col>
                    {flexLabel && (
                      <Col>
                        <span className="visuallyhidden">{flexLabel}</span>
                        {benefit.flexDollars}
                      </Col>
                    )}
                    {employerCostLabel && (
                      <Col>
                        <span className="visuallyhidden">{employerCostLabel}</span>
                        {benefit.employerCost}
                      </Col>
                    )}
                    {creditsAppliedLabel && (
                      <Col>
                        <span className="visuallyhidden">{creditsAppliedLabel}</span>
                        {benefit.creditsApplied}
                      </Col>
                    )}
                    <Col className="last-column">
                      <span className="visuallyhidden">{deductionLabel}</span>
                      {benefit.payrollDeducations}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>
      );
    });

    const notesList = [];
    _.each(this.props.enrolmentSummary.notes, (note, i) => {
      const { tooltipNum } = this.state;
      _.each(tooltipNum, (tipNum) => {
        parseInt(tipNum) === i && notesList.push(<p key={`notes-${i + 1}-ID`} className="m-0" dangerouslySetInnerHTML={{ __html: note.text }} />);
      });
    });
    return (
      <div className="enrollmentsummary tabone clearfix">
        {/* <h1 className='clearfix' aria-label={pageTitle} >{pageTitle}</h1> */}
        <Sticky enabled={true} top={63} innerZ={100}>
          <Row className="enrollmenthead justify-content-end m-0">
            {" "}
            {/*  class name: enrollmenthead */}
            <Col className="col-12 col-sm-8">
              <Row className="valueboxes">
                <Col>{costLabel}</Col>
                {flexLabel && <Col>{flexLabel}</Col>}
                {employerCostLabel && <Col>{employerCostLabel}</Col>}
                {creditsAppliedLabel && <Col>{creditsAppliedLabel}</Col>}
                <Col className="last-column">{deductionLabel}</Col>
              </Row>
            </Col>
          </Row>
        </Sticky>
        <div className="enrollmentlist">{activeSubscriptions}</div>
        <div className="mb-3 enrollmentitem foottotal">
          {activeFooter.subTotal && (
            <>
              <Row className="m-0">
                <Col className="col-12 col-sm-4 title">{activeFooter.subTotal.subTotalLabel}</Col>
                <Col className="col-12 col-sm-8 columnone">
                  <Row className="justify-content-between valueboxes">
                    <Col>
                      <span className="visuallyhidden">{costLabel}</span>
                      {activeFooter.subTotal.cost}
                    </Col>
                    {flexLabel && (
                      <Col>
                        <span className="visuallyhidden">
                          {activeFooter.subTotal.subTotalLabel}
                          {flexLabel}
                        </span>
                        {activeFooter.subTotal.flex}
                      </Col>
                    )}
                    {employerCostLabel && (
                      <Col>
                        <span className="visuallyhidden">
                          {activeFooter.subTotal.subTotalLabel}
                          {employerCostLabel}
                        </span>
                        {activeFooter.subTotal.employerCost}
                      </Col>
                    )}
                    {creditsAppliedLabel && (
                      <Col>
                        <span className="visuallyhidden">{creditsAppliedLabel}</span>
                        {activeFooter.subTotal.creditsApplied}
                      </Col>
                    )}
                    <Col className="last-column">
                      <span className="visuallyhidden">{deductionLabel}</span>
                      {activeFooter.subTotal.deductions}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          )}
          {activeFooter.salesTax && (
            <>
              <Row className="m-0">
                <Col className="col-12 col-sm-4 title">{activeFooter.salesTax.salesTaxLabel}</Col>
                <Col className="col-12 col-sm-8 columntwo">
                  <Row className="justify-content-between valueboxes">
                    <Col>&nbsp;</Col>
                    <Col>&nbsp;</Col>
                    {employerCostLabel && creditsAppliedLabel && <Col>&nbsp;</Col>}
                    <Col className="last-column">
                      <span className="visuallyhidden">{deductionLabel}</span>
                      {activeFooter.salesTax.salesTaxDeductions}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          )}
          {activeFooter.total && (
            <>
              <Row className="m-0">
                <Col className="col-12 col-sm-4 title">{activeFooter.total.totalLabel}</Col>
                <Col className="col-12 col-sm-8 columnthree">
                  <Row className="justify-content-between valueboxes">
                    <Col>&nbsp;</Col>
                    <Col>&nbsp;</Col>
                    {employerCostLabel && creditsAppliedLabel && <Col>&nbsp;</Col>}
                    <Col className="last-column">
                      <span className="visuallyhidden">{deductionLabel}</span>
                      {activeFooter.total.deductions}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          )}
        </div>
        <Modal onOpened={() => setModalAttribute("modalHeading")} aria-labelledby="modalHeading" isOpen={this.state.showNotes} toggle={this.handleClose} centered>
          <div className="modal-header">
            <h1 className="modal-title" id="modalHeading">
              {this.state.header ? this.state.header : "\u00A0"}
            </h1>
            <DisplayButton type="button" className="close" arialabel={closeButtonText.display} displayName="×" onClick={this.handleClose} />
          </div>
          <ModalBody>
            <>{notesList}</>
          </ModalBody>
        </Modal>
        {/* {notesList.length > 0 && (
          <div className="alert alert-warning" role="alert">
            {notesList}
          </div>
        )} */}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    enrolmentSummary: state.userReducer.enrollmentSummaryBenefits,
    sideBarMenu: state.contentReducer.screens,
    contentFooter: state.userReducer.contentFooter.contentResource ? state.userReducer.contentFooter.contentResource.contents : {},
    deviceType: state.userReducer.deviceType || ""
  };
};

export default connect(mapStateToProps, { getSummaryBenefits, setPageSetting, getMenu2 })(withRouter(SummaryActiveSubscriptions));
