import React, { useState, useEffect } from 'react'
import ConfirmedItem from './confirmedItem';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
    downloadFinishedEnrollmentFile
} from "../../actions/userActions";

const EnrollmentBenefit = props => {
    const [noCarrier, setNoCarrier] = useState(false);

    useEffect(() => {
        setNoCarrier(props.noCarrier);
    }, [props.noCarrier]);

    const coverLetterButtonClicked = () => {
        props.downloadFinishedEnrollmentFile(props.coverLetterFile,"CoverLetter");
    }

    const questionaireButtonClicked = (filePath) => {
        window.open(filePath);
    }
    
    return (
        <div className="clearfix itemsection" >
            <p className="mb-3">{props.benefitTitle }</p>
            {props.coverLetterFile && <ConfirmedItem
                title={props.coverLetterTitle}
                buttonTitle={props.coverLetterButton}
                backColor={props.backColor}
                navigateClicked={() => coverLetterButtonClicked()} />}
            {props.questionaireFile && <ConfirmedItem
                title={props.questionaireTitle}
                buttonTitle={props.questionaireButton}
                backColor={props.backColor}
                navigateClicked={() => questionaireButtonClicked(props.questionaireFile)}/>}
            {noCarrier &&
                <p className='m-0' tabIndex='0'  >{props.noCarrierMessage}</p>
            }
        </div>
    )
}

export default connect(null, {
    downloadFinishedEnrollmentFile
})(withRouter(EnrollmentBenefit));
