import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {connect} from "react-redux";
import {getLoginContent, showError, showSuccess, dissmiss, setClientName,setFocusWelcomeFlag} from "../actions/contentActions";
import {authenticate} from "../actions/userActions";
import {getDisplayText,getControlId} from "../utils";
import {Link, withRouter, Redirect} from 'react-router-dom';
import {Row,  Form, Col} from 'react-bootstrap';
import { trackPromise } from 'react-promise-tracker';
import DisplayButton from './button';

class Login extends Component {
    constructor(props) {
        super(props);

        const clientName = props.match.params.client
        let didSetClientName = false
        
        this.props.setClientName(clientName, (data) => { //Handle Success
            didSetClientName = true
            }, (data) => { // Handle Failure
                didSetClientName = false
            }).then(() => {
                if (!didSetClientName) 
                    props.history.replace("/403")
        })

        this.state = {username: '', password: '', formValidated: false};
        document.title = window.location.href
    }

    UNSAFE_componentWillMount () {
        this.props.getLoginContent()
        this.props.setFocusWelcomeFlag(false);
       
    }

    onUsernameChange = (event) => {
        this.setState({username: event.target.value})
    }

    onPasswordChange = (event) => {
        this.setState({password: event.target.value})
    }

    failureCallback = (msg) => {
        const invalidCredentialsError = getDisplayText(this.props.content, 'ErrorNoAccess')

        this.props.showError( msg ? msg : invalidCredentialsError.display)
    }

    handleKeyPress = (event) => {
        if(event.key === 'Enter' || event.charCode === 13){
            this.handleSubmit(event)
        }
    }
    
    handleSubmit = (event) => {
        event.preventDefault();
        let tfaDate = JSON.parse(localStorage.getItem('tfaDate'));
        
        if(tfaDate === null || Object.keys(tfaDate).length === 0) {
            const cdt = new Date();
            tfaDate = {
                day: cdt.getDate(),
                month: cdt.getMonth() + 1,
                year: cdt.getFullYear()
            }
        }
        const me = this
        me.props.dissmiss()
        
        if (!this.state.username) {
            this.setState({formValidated: true})
            ReactDOM.findDOMNode(this.refs.username).focus(); 
        }
        else if (!this.state.password){
            this.setState({formValidated: true})
            ReactDOM.findDOMNode(this.refs.password).focus(); 
        }
        else {
            trackPromise(this.props.authenticate(this.state.username, this.state.password, tfaDate, me.failureCallback, this.props.history))
        }
    }
    
    dismiss = () => {
        this.props.dissmiss()
    }
  

    render() {
        const headerPage = getDisplayText(this.props.content, 'LoginPanelTitle')
        const title = getDisplayText(this.props.content, 'LoginContentText')
        const lblPageTitle = getDisplayText(this.props.content, 'lblLoginPageTitle')
        const userIdLabel = getDisplayText(this.props.content, 'LoinLabelText')
        const passwordLabel = getDisplayText(this.props.content, 'PasswordText')
        const forgotPasswordLabel = getDisplayText(this.props.content, 'lnkForgotPassword')
        const loginButtonLabel = getDisplayText(this.props.content, 'btnLogin')
        const userNameEmptyErrorMessage = getDisplayText(this.props.content, 'rfvtxtLoginErrorMessage')
        const passwordEmptyErrorMessage = getDisplayText(this.props.content, 'rfvtxtPasswordErrorMessage')
        const { needTwoFARegistration, needTwoFAValidation, passwordChangeRequired } = this.props
        
        document.title = lblPageTitle.display && !lblPageTitle.display.includes("no lbl") ? lblPageTitle.display : headerPage.display

        if ( this.props.isSignedIn ) {
            if(passwordChangeRequired) {
                return <Redirect to='/changepassword' />
            } else if(needTwoFARegistration && needTwoFAValidation) {
                return <Redirect to='/Registration' />
            } else if(!needTwoFARegistration && needTwoFAValidation) {
                // return <Redirect to={{pathname: '/Verification', options: {TypeID: twoFATypeID, IsRegistration: false } }} />
                return <Redirect to='/Verification' />
            }
        
            return <Redirect to='/' />
        }

        if (this.props.redirectURL !== null && this.props.redirectURL !== undefined && this.props.redirectURL) { 
            return <Redirect to='/outside' />
        }

        return (<div className="conatiner" role="main" id="maincontent" >
            <div className="login-holder">
                <Row className="h-100">
                    {/*image holder*/}
                    <div className="col-12 login-image-holder">
                        {/*image sholud be placed here*/}
                    </div>
                    <div className="logincontainer d-md-flex align-items-center align-content-center justify-content-between">
                        <div className="holder login-form description panel d-none d-md-block">
                            {/* <h1  className="login-heading d-sm-block d-md-none">{headerPage.display}</h1> */}
                            <span dangerouslySetInnerHTML={{__html: title.display}} />
                        </div>
                        <div className="form-group login-form mobile-login-form pb-md-0" xs={{order: 1}} md={{order: 2}} lv={{order: 2}}>
                            <div className="holder">
                                <Form id="login-container" className="loginform" aria-label={headerPage.textToRead}  role="form" noValidate validated={this.state.formValidated} onSubmit={this.handleSubmit}>
                                    <h1 className="login-heading d-md-block">{headerPage.display}</h1>
                                    <div className="login-form description panel d-md-none">
                                        <span dangerouslySetInnerHTML={{__html: title.display}} />
                                    </div>
                                    <Row>
                                        <Col className="col-12 paddingbottom">
                                            <label htmlFor={getControlId(userIdLabel.textToRead)}  className="d-none d-md-block d-xl-block"  >
                                            {userIdLabel.display}
                                            </label>
                                            <Form.Control   
                                                id={getControlId(userIdLabel.textToRead)}
                                                type="text"
                                                autoComplete="username"
                                                placeholder={userIdLabel.display}
                                                className="form-control login-input-field"
                                                required
                                                ref="username"
                                                value={this.state.username}                              
                                                /* isInvalid={this.state.username === '' ? true : false} */
                                                onChange={this.onUsernameChange}/>
                                            <Form.Control.Feedback type="invalid"  role='alert'  className="mt-2"  >
                                                {userNameEmptyErrorMessage.display}
                                            </Form.Control.Feedback>
                                        </Col>
                                        <Col className="col-12 paddingbottom">
                                            <label htmlFor={getControlId(passwordLabel.textToRead)}    className="d-none d-md-block d-xl-block" >
                                                {passwordLabel.display}
                                            </label>
                                                <Form.Control
                                                    id={getControlId(passwordLabel.textToRead)}
                                                    type="password"
                                                    autoComplete="current-password"
                                                    placeholder={passwordLabel.display}
                                                    className="form-control login-input-field"
                                                    ref="password"
                                                    value={this.state.password}
                                                    required onChange={this.onPasswordChange}
                                                    onKeyPress={this.handleKeyPress}/>
                                            <Form.Control.Feedback type="invalid" role='alert'  className="mt-2"  >
                                                {passwordEmptyErrorMessage.display}
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Row>
                                    {/* <div className="forgotpassword d-none d-md-block">
                                        {this.props.configs.showForgetPassword &&
                                            <Link
                                                tid="dismissLink"
                                                to={`/${this.props.match.params.client}/forgotpassword`}
                                                title={forgotPasswordLabel.textToRead}
                                                aria-label={forgotPasswordLabel.textToRead}
                                                onClick={() => this.dismiss()}
                                                className={this.props.configs['showForgetPassword'] ? '' : 'hidden'}>
                                                {forgotPasswordLabel.display}
                                            </Link>
                                        }
                                    </div> */}
                                    <DisplayButton className="secondary btn-login btn-block" type="submit" displayName={loginButtonLabel.display} onClick={this.handleSubmit} />
                                    <div className="forgotpassword pt-1 pt-md-4">
                                        {this.props.configs.showForgetPassword &&
                                            <Link
                                                to={`/${this.props.match.params.client}/forgotpassword`}
                                                aria-label={forgotPasswordLabel.textToRead}
                                                onClick={() => this.dismiss()}
                                                className={this.props.configs['showForgetPassword'] ? 'btn-link btn-forgetpassword' : 'hidden'}>
                                               {forgotPasswordLabel && forgotPasswordLabel.display?forgotPasswordLabel.display.slice(0,1).toUpperCase() + forgotPasswordLabel.display.slice(1, forgotPasswordLabel.display.length).toLowerCase():''}
                                            </Link>
                                        }
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </Row>
            </div>
        </div>);
    }
}

const mapStateToProps = (state) => {
    return {
        content: state.contentReducer.loginContent.contentResource ? state.contentReducer.loginContent.contentResource.contents : [],
        configs: state.contentReducer.loginContent.result ? state.contentReducer.loginContent.result : [],
        user: {username: '', password: ''},
        isSignedIn: state.userReducer.isSignedIn,
        passwordChangeRequired: state.userReducer.authResponse.passwordChangeRequired || false,
        needTwoFARegistration: state.userReducer.needTwoFARegistration,
        needTwoFAValidation: state.userReducer.needTwoFAValidation,
        twoFATypeID: state.userReducer.twoFATypeID,
        locale: state.contentReducer.locale,
        client: state.contentReducer.client ? state.contentReducer.client : "",
        redirectURL: state.userReducer.redirectURL
    }
};

export default connect(
    mapStateToProps,
    {getLoginContent, authenticate, showError, showSuccess, dissmiss, setClientName,setFocusWelcomeFlag}
)(withRouter(Login));
