import React, { Component } from 'react';
import { connect } from "react-redux";
import { Row } from 'react-bootstrap';
import _ from 'underscore';
import { progressBarIndicator } from '../actions/userActions';
import { trackPromise } from 'react-promise-tracker';


class PagingComponent extends Component {
    componentDidMount(){
        trackPromise(this.props.progressBarIndicator())
    }
    render() {
        const  progressBar  = this.props.progressBar
        let paging = []
        _.each(progressBar, (i) => {
            paging=[]
            for(let j =0; j< progressBar.currentSubStep; j++) {
                paging.push(<span className="fill-circle active" key={`active-${j+10}-ID`}></span>)
            } 
            for(let j =0; j< progressBar.numberofSubSteps - progressBar.currentSubStep; j++) {
                paging.push(<span className="fill-circle " key={`inactive-${j+2}-ID`} ></span>)
            } 
        })
        return (<>
            {progressBar.labelText && <Row className={`justify-content-center mt-2 mb-3 mb-md-4`}>
              { progressBar.labelText && <span className={`paginationtext`} >({progressBar.labelText})</span>}
              { this.props.deviceType !== "Desktop" && <div className={'pagination'} >{paging}</div> }
            </Row>}
        </>);
    }
}
const mapStateToProps = (state) => {
    return {
        indicators: state.contentReducer.indicators,
        pageSetting: state.userReducer.pageSetting,
        progressBar: state.userReducer.progressBar,
        deviceType: state.userReducer.deviceType
    }
};

export default connect(
mapStateToProps,
{progressBarIndicator}
)(PagingComponent);
