import React, { Component } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import { getControlId, getDisplayText } from "../../utils";
import DisplayButton from "../button";

class VerificationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      lbldisabled: false,
      time: {},
      seconds: this.props.resendCodeInterval,
      otpCode: "",
      errorMessage: "",
      inActive: true
    };
    this.timer = 0;

    this.startTimer = this.startTimer.bind(this);
    this.resetTimer = this.resetTimer.bind(this);
    this.countDown = this.countDown.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.resendCodeInterval) {
      let timeLeftVar = this.secondsToTime(nextProps.resendCodeInterval);
      this.setState({
        time: timeLeftVar,
        seconds: nextProps.resendCodeInterval
      });
    }
  }

  failureCallback = msg => {
    this.props.showError(msg ? msg : "Please enter a valid verification code.");
  };

  secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds
    };
    return obj;
  }

  resetTimer() {
    if (this.state.seconds !== this.timer) {
      this.timer = 0;
      this.setState(
        {
          seconds: this.props.resendCodeInterval,
          disabled: true,
          lbldisabled: true,
          errorMessage: ""
        },
        this.startTimer
      );
    }
  }

  startTimer() {
    if (this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds
    });

    // Check if we're at zero.
    if (seconds === 0) {
      this.setState({ disabled: false });
      clearInterval(this.timer);
    }
  }

  setOtpCode = e => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      this.setState({ otpCode: e.target.value });
      if (e.target.value.length === 6) {
        this.setState({ inActive: false });
      } else {
        this.setState({ inActive: true });
      }
    }
  };

  handleKeyPress = event => {
    if (event.key === "Enter" || event.charCode === 13) {
      this.handleSubmit(event);
    }
  };

  handleSubmit = event => {
    event.preventDefault();
    const options = {
      Code: this.state.otpCode,
      IsRegistration: true,
      TypeID: parseInt(this.props.verifiedContent.twoFATypeID)
    };
    trackPromise(this.props.validateTFACode(options, this.failureCallback, this.props.history, this.props.showSuccess));
  };

  render() {
    const verifiedContent = this.props.verifiedContent;
    const content = verifiedContent.contentResource ? verifiedContent.contentResource.contents : [];
    const btnVerifyCode = getDisplayText(content, "btnVerifyCode");
    const btnResend = getDisplayText(content, "btnResend");
    const lblVerificationMsgReceived = getDisplayText(content, "lblVerificationMsgReceived");
    const { isAllowedToResendCode, maxResendPerSession } = verifiedContent;

    const lblDetail2 = getDisplayText(content, "lblDetail2");
    const lblResendVerificationMsg = getDisplayText(content, "lblResendVerificationMsg");
    const lblTitle = getDisplayText(content, "lblTitle");
    const lblverificationCode = getDisplayText(content, "lblverificationCode");

    return (
      <div className="conatiner" role="main" id="maincontent">
        <div className="login-holder">
          <Row className="h-100">
            <div className="col-12 login-image-holder"></div>
            <div className="logincontainer d-md-flex align-items-center align-content-center justify-content-between">
              <div className="holder login-form description panel d-none d-md-block">
                {/* <h1 className="login-heading d-sm-block d-md-none">{lblTitle.display}</h1> */}
                <span dangerouslySetInnerHTML={{ __html: lblDetail2.display }} />
              </div>
              <div className="form-group login-form mobile-login-form pb-5 pb-md-0" xs={{ order: 1 }} md={{ order: 2 }} lv={{ order: 2 }}>
                <h1 className="login-heading d-md-block">{lblTitle.display}</h1>
                <div className="login-form description panel d-md-none">
                  <span dangerouslySetInnerHTML={{ __html: lblDetail2.display }} />
                </div>
                <div className="holder">
                  <div id="login-container" className="loginform" noValidate>
                    <Row>
                      <Col className="col-12 paddingbottom">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: lblVerificationMsgReceived.display
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: lblResendVerificationMsg.display
                          }}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col className="col-12 paddingtop">
                        <label htmlFor={getControlId(lblverificationCode.textToRead)} className="label-heading">
                          {lblverificationCode.display}
                        </label>
                        <Form.Control
                          id={getControlId(lblverificationCode.textToRead)}
                          type="text"
                          autoComplete="off"
                          placeholder={lblverificationCode.display}
                          className="form-control login-input-field"
                          ref="otpCode"
                          value={this.state.otpCode}
                          minLength="6"
                          maxLength="6"
                          required
                          onChange={this.setOtpCode}
                        />
                      </Col>

                      <Col className="col-12 paddingtop">
                        <div className={`btns-forgot d-flex justify-content-between`}>
                          <DisplayButton type="submit" className="secondary" displayName={btnVerifyCode.display} onClick={this.handleSubmit} disabled={this.state.inActive} />
                          {isAllowedToResendCode && maxResendPerSession > this.props.resendCount && (
                            <DisplayButton
                              className="outline-secondary"
                              onClick={() => {
                                this.props.handleResend();
                                this.resetTimer();
                              }}
                              controlId="resendBtn"
                              disabled={this.state.disabled}
                              displayName={
                                <>
                                  {" "}
                                  {btnResend.display}
                                  {this.state.time.s > 0 && this.state.time.s < this.props.resendCodeInterval && ` (${this.state.time.s.toString().padStart(2, "0")} )`}
                                </>
                              }
                            />
                          )}
                        </div>
                      </Col>

                      {/* {this.state.lbldisabled && (
                                                <Col>
                                                    <div
                                                        className='alert alert-info mt-4'
                                                        role='alert'
                                                    >
                                                        <p
                                                            className='m-0'
                                                            aria-label={
                                                                lblResentCodeSuccess.textToRead
                                                            }
                                                        >
                                                            {
                                                                lblResentCodeSuccess.display
                                                            }
                                                        </p>
                                                    </div>
                                                </Col>
                                            )} */}
                      {this.state.errorMessage && (
                        <Col>
                          <div className="alert alert-danger mt-4" role="alert">
                            <p className="m-0">{this.state.errorMessage}</p>
                          </div>
                        </Col>
                      )}
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </div>
      </div>
    );
  }
}

export default VerificationForm;
