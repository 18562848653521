import React, { Component } from 'react';
import {Modal, ModalBody} from 'reactstrap';
import { goNext, goPrevious, cancelClick, updateWellness, getEnrollmentFooter, saveFlex, getContentFooter, setPageSetting } from '../actions/userActions' 
import { setMiniCartInitialState, dissmiss, focusInitializing } from '../actions/contentActions' 
import DisplayButton from './button';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { getDisplayText,setModalAttribute,skipMainContent} from '../utils';
import { trackPromise } from 'react-promise-tracker';
import WarningMessageModal from './WarningMessageModal';

class NextPrevious extends Component {
  state = {
    showModal: false,showWarningModal: false
  }

  goHome = () => {
    this.props.setPageSetting("WELCOME")
    this.props.history.replace("/")
  }

  setMiniCartInitialState = () => {
    this.props.setMiniCartInitialState(false);
  }

  UNSAFE_shouldComponentUpdate(nextProps){
    const { nextEnrolStep } = this.props
    if (nextEnrolStep.navigationError === undefined || nextEnrolStep.navigationError === null)  {
      if(nextProps.pageSetting !== this.props.pageSetting){
        skipMainContent() 
      const InputElement = document.getElementsByClassName("error-alert");
      if(InputElement && InputElement.length>0){
        for(var i=0; i<InputElement.length; i++){
          InputElement[i].removeAttribute("role")
        }
      }
      }
    }
    return true
  }

  handleSelectClickByWarningModal = (event) => { //Handle Click of the  warning modal yest button
    this.setState({showWarningModal: false})
    this.handelGoPrevBtn(event)

  }

  handelGoPrevBtn=(event)=>{
    this.props.dissmiss()
    if (this.props.previousClick) this.props.previousClick()
    else {
      trackPromise(this.props.goPrevious(this.props.history))
    this.props.onClick()
    }
    event.currentTarget.blur();
    this.setMiniCartInitialState();
    this.props.focusInitializing(); 
  }
  
  handleClose = () => {
		this.setState({showWarningModal: false})
  }
  
  render () {
    const props = this.props
    const gonext = (event) => { 
      this.props.dissmiss()
      if(this.props.isSmoker){
        this.props.isSmokerCallBack()
      }else{
        if (this.props.nextClick) this.props.nextClick()
        else{ 
          trackPromise(this.props.goNext(this.props.history))
          this.props.onClick()
        }
        event.currentTarget.blur()
        this.setMiniCartInitialState();
        this.props.focusInitializing();
    }
    }
    const gopre = (event) => {
   
     if(this.props.showWarningMessage) 
     {
      let index=this.props.screens.findIndex(item => item.name===this.props.pageSetting.name);
      const prevPageName=this.props.screens[index-1].link;
      const currPageName=this.props.screens[index].link;
      if(currPageName !=='Wellness' && (prevPageName.includes("Dependent") || prevPageName.includes("Wellness"))) 
      this.setState({showWarningModal:true});
      else this.handelGoPrevBtn(event)
     }
     else{
        this.handelGoPrevBtn(event)
    }
    }
    const cancel = (event) => {
      if (this.props.cancelClicked) this.props.cancelClicked()
      else {
        trackPromise(this.props.getEnrollmentFooter(() => {
        this.setState({
          showModal: true
        })
      }))
      }
      event.currentTarget.blur()
    }
    const handleClose = () => {
      this.setState({
          showModal: false
      })   
    }
    const handleCancel = () => {
      trackPromise(this.props.cancelClick(this.props.history))
      this.setMiniCartInitialState(); 
      skipMainContent()      
    }
  const lblCancelTextSummary = getDisplayText(this.props.enrolmentFooter, 'lblCancelTextSummary').display
  const lblCancelText = getDisplayText(this.props.enrolmentFooter, 'lblCancelText').display

  const contents = this.props.contentFooter;
  const nextButtonLabel = getDisplayText(contents, 'btnNext');
  const previousButtonLabel = getDisplayText(contents, 'btnPrevious');
  const cancelButtonLabel = getDisplayText(contents, 'btnCancel');
  const yesButtonLabel = getDisplayText(contents, 'lblYes');
  const noButtonLabel = getDisplayText(contents, 'lblNo'); 
  const homeButtonLabel = getDisplayText(contents, 'lblHome')
  const closeButtonText = getDisplayText(contents,'btnClose')
    return (
      <>{ localStorage.getItem('hideButtons') || props.showButtons ?
      ( <div className="review-card-btns ml-btns border-top">
          {!props.hideCancel && <DisplayButton controlId="cancelbtn" displayName={this.props.lblCancel ? this.props.lblCancel.display : nextButtonLabel.display} className="outline-secondary btn-cancel float-left"  onClick={cancel} />}
	        {(!props.firstPage && props.pageSetting.link !== 'Profile') && <DisplayButton controlId="qpreBtn" displayName={this.props.lblPrevious ? this.props.lblPrevious.display : previousButtonLabel.display} className="outline-secondary btn-previous"  onClick={gopre} />}
	        {!props.lastPage && <DisplayButton  controlId="btnGoNext" displayName={this.props.lblNext ? this.props.lblNext.display : cancelButtonLabel.display} className="outline-secondary btn-next"   disabled={this.props.nextDisabled || this.props.disableNext} onClick={gonext} />}
        </div>)
        : (<DisplayButton controlId="goHomeBtn" displayName={homeButtonLabel.display}  className={this.props.client.toLowerCase() ==="richardson" ? "outline-secondary btn-home" : "outline-secondary btn-home"} onClick={()=> this.goHome()} />)
        }
        <Modal onOpened={() => setModalAttribute(lblCancelText)} aria-labelledby="modalHeading" aria-describedby="modalDescription" className="ml-cancelenrollment" isOpen={this.state.showModal} toggle={handleClose} centered>
          <div className="modal-header">
            <h1 className="modal-title" id='modalHeading'>
              {lblCancelText}
            </h1>
            <DisplayButton type="button"  className="close" displayName="×" arialabel={closeButtonText.display} onClick={handleClose}/> 
          </div>
          <ModalBody>
            <div className="ml-popupmessage" id='modalDescription'>
                <p className="ml-popupmessagetext" dangerouslySetInnerHTML={{ __html: lblCancelTextSummary}}></p>
            </div>
            <div className={'ml-buttons d-flex justify-content-between'}>
                <DisplayButton controlId="handleClose" displayName={noButtonLabel.display} className="outline-secondary btn-sm btn-cancel"  onClick={handleClose} />
                <DisplayButton controlId="handleCancel" displayName={yesButtonLabel.display} className="outline-secondary btn-sm btn-close"  onClick={handleCancel} />
            </div>
          </ModalBody>
        </Modal>
        <WarningMessageModal showWarningModal={this.state.showWarningModal} clickAction={(event)=>this.handleSelectClickByWarningModal(event)} handleClose={this.handleClose} />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {pageSetting: state.userReducer.pageSetting,
          wellnessQuestionAnswers: state.userReducer.wellness.result,
          enrolmentFooter: state.userReducer.enrolmentFooter.contentResource.contents,
          disableNext: state.userReducer.disableNext,
          contentFooter: state.userReducer.contentFooter.contentResource ? state.userReducer.contentFooter.contentResource.contents : {},
          locale: state.contentReducer.locale,
          client: state.contentReducer.client,
          nextEnrolStep: state.userReducer.nextEnrolStep,
          screens : state.contentReducer.screens,
          showWarningMessage:state.contentReducer.showWarningMessage
        }
};

export default connect(
mapStateToProps,
{ goNext, goPrevious, cancelClick, updateWellness, getEnrollmentFooter, saveFlex, getContentFooter, setPageSetting, setMiniCartInitialState, dissmiss, focusInitializing }
)(withRouter(NextPrevious));
