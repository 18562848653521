import React, { Component } from 'react';
import { connect } from "react-redux";
import { Row, Card, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { getSummaryBenefits, setPageSetting } from '../actions/userActions';
import { getDisplayText } from '../utils/index';
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'; 
import { trackPromise } from 'react-promise-tracker';

import _ from 'underscore';

class SummaryEoi extends Component {
  
  handleEditAction = (link, linkID) => {
    const benefitName = link.split("/")[0];
    const actionLink = `${benefitName}/${linkID}`;
    let menuItem
    if (this.props.deviceType === "Desktop") {
      _.each(this.props.sideBarMenu, (item, index) => {
        if (item.link instanceof Array) {
          let obj = _.where(item.link, {"actinLink": actionLink})
          if (obj && obj.length !== 0) {
            menuItem = item
            return
          }
        }
      })
      menuItem.link = "Benefits"
    } else {
      menuItem = _.find(this.props.sideBarMenu, item => item.link.toUpperCase() === actionLink.toUpperCase())
    }
    trackPromise(this.props.setPageSetting(menuItem ? menuItem.name : "WELCOME", true));
    this.props.history.push(`/${menuItem ? menuItem.link : ""}`);
  }

  handleDownKeyPressed = (event, link,linkID) => {
    switch(event.which){
        case 13: {
          this.handleEditAction(link,linkID);
          break;
        }
        default:
				  break;
    }
 }
  
  render () {
    const enrolmentSummary = this.props.enrolmentSummary ? this.props.enrolmentSummary : {}
    const pageTitle = enrolmentSummary.otherTabName
    document.title = pageTitle
    const contentEOI = enrolmentSummary.contentResource.controlResource.PendingEOIs
    const contentPackages = enrolmentSummary.contentResource.controlResource.Packages
    const contentSubscriptions = enrolmentSummary.contentResource.controlResource.Subscriptions

    const captionNoteEoi = getDisplayText(contentEOI, "lblEOIPendingCaption")
    const formNoteEoi = getDisplayText(contentEOI, "lblEOIFormsNote")
    const lblTerminatedCaption = getDisplayText(contentSubscriptions, "lblTerminatedCaption")
    const lblCoverageWaivedCaption = getDisplayText(contentSubscriptions, "lblCoverageWaivedCaption")
    const lblPremiumWaivedCaption = getDisplayText(contentSubscriptions, "lblPremiumWaivedCaption")
    const lblPackageCaption = getDisplayText(contentPackages, "lblPackageCaption")
    const editIcon = getDisplayText((this.props.contentFooter.contentResource && this.props.contentFooter.contentResource.contents) || {}, 'EditIcon')

    const benefitLabel = enrolmentSummary.eoiPendingSubHeaderTemplate ? enrolmentSummary.eoiPendingSubHeaderTemplate.benefit : ""
    const optionLabel = enrolmentSummary.eoiPendingSubHeaderTemplate ? enrolmentSummary.eoiPendingSubHeaderTemplate.option : ""
    const descLabel = enrolmentSummary.eoiPendingSubHeaderTemplate ? enrolmentSummary.eoiPendingSubHeaderTemplate.description : ""
    const costLabel = enrolmentSummary.eoiPendingSubHeaderTemplate ? enrolmentSummary.eoiPendingSubHeaderTemplate.cost : ""

    const lblPackageName = enrolmentSummary.packageHeader ? enrolmentSummary.packageHeader.package : ""
    const lblPackageDescription = enrolmentSummary.packageHeader ? enrolmentSummary.packageHeader.description : ""
    const lblPackageCost = enrolmentSummary.packageHeader ? enrolmentSummary.packageHeader.cost : ""
    const lblPackageFlex = enrolmentSummary.packageHeader ? enrolmentSummary.packageHeader.flex : ""
    const lblPackageDeductions = enrolmentSummary.packageHeader ? enrolmentSummary.packageHeader.deduction : ""

    const lblPremiumWaivedName = enrolmentSummary.premiumWaivedSubscriptionHeader ? enrolmentSummary.premiumWaivedSubscriptionHeader.benefit : ""
    const lblPremiumWaivedOption = enrolmentSummary.premiumWaivedSubscriptionHeader ? enrolmentSummary.premiumWaivedSubscriptionHeader.option : ""
    const lblPremiumWaivedDescription = enrolmentSummary.premiumWaivedSubscriptionHeader ? enrolmentSummary.premiumWaivedSubscriptionHeader.description : ""
    const lblPremiumWaived = enrolmentSummary.premiumWaivedSubscriptionHeader ? enrolmentSummary.premiumWaivedSubscriptionHeader.premiumWaived : ""

    const lblCoverageWaivedBenefit = enrolmentSummary.coverageWaivedSubscriptionsHeader ? enrolmentSummary.coverageWaivedSubscriptionsHeader.benefit : ""
    const lblCoveregaWaived = enrolmentSummary.coverageWaivedSubscriptionsHeader ? enrolmentSummary.coverageWaivedSubscriptionsHeader.option : ""

    const lblTerminatedBenefit = enrolmentSummary.terminatedSubscriptionsHeader ? enrolmentSummary.terminatedSubscriptionsHeader.benefit : ""
    const lblTerminatedOption = enrolmentSummary.terminatedSubscriptionsHeader ? enrolmentSummary.terminatedSubscriptionsHeader.option : ""

    const pendingEOI = []
    _.each(enrolmentSummary.benefits.pendingEOISInfo, (benefit) =>{
        pendingEOI.push(
        <div key={`div-${benefit.benefit}-ID`} className="pendingeoi singleitem pt-3">
          <Row className="" >
            <Col className="" ><span className="visuallyhidden">{benefitLabel}</span>{benefit.benefit}</Col>
            <Col className="" ><span className="visuallyhidden">{optionLabel}</span>{benefit.option}</Col>
            <Col className="" ><span className="visuallyhidden">{descLabel}</span>{benefit.description}</Col>
            <Col className="" ><span className="visuallyhidden" dangerouslySetInnerHTML={{__html: costLabel}}/>{benefit.costs}</Col>
          </Row>
        </div>
      );
    });

    const packageList = []
    _.each(enrolmentSummary.benefits.packagesListInfo, (benefit) =>{
        packageList.push(
        <div key={`div-${benefit.packageName}-ID`} className="singleitem pt-3">
          <Row className="">
            <Col className=""><span className="visuallyhidden">{lblPackageName}</span>{benefit.packageName} 
              {benefit.editButton != null &&
                <span
                  tid="packageEditAction"
                  className="editicon icon-action" 
                  role='button' 
                  aria-disabled = {!benefit.editButton.enabled}
                  onClick={() => {benefit.editButton.enabled && this.handleEditAction(benefit.editButton.link, benefit.editButton.linkID)}}
                  aria-label={editIcon.textToRead+" "+lblPackageName}
                  onKeyDown={(event) => benefit.editButton.enabled && this.handleDownKeyPressed(event, benefit.editButton.link, benefit.editButton.linkID)}>
                  <FontAwesomeIcon 
                    style={ !benefit.editButton.enabled && {opacity: 0.5, pointerEvents: "none"}}
                    icon={faPencilAlt}
                  />
                </span>}
            </Col>
            <Col className=""><span className="visuallyhidden">{lblPackageDescription}</span>{benefit.description}</Col>
            <Col className=""><span className="visuallyhidden" dangerouslySetInnerHTML={{ __html: lblPackageCost }}/>{benefit.cost}</Col>
            <Col className=""><span className="visuallyhidden">{lblPackageFlex}</span>{benefit.flex}</Col>
            <Col className=""><span className="visuallyhidden">{lblPackageDeductions}</span>{benefit.deductions}</Col>
          </Row>
        </div>
      );
    });

    const premiumWaived = []
    _.each(enrolmentSummary.benefits.premiumWaivedSubscriptionList, (benefit) =>{
        premiumWaived.push(
        <div key={`div-${benefit.benefit}-ID`} className="singleitem pt-3">
          <Row className="">
            <Col className=""><span className="visuallyhidden">{lblPremiumWaivedName}</span>{benefit.benefit}</Col>
            <Col className=""><span className="visuallyhidden">{lblPremiumWaivedOption}</span>{benefit.option}</Col>
            <Col className=""><span className="visuallyhidden">{lblPremiumWaivedDescription}</span>{benefit.description}</Col>
            <Col className=""><span className="visuallyhidden">{lblPremiumWaived}</span>{benefit.premiumWaived}</Col>
          </Row>
        </div>
      );
    });

    const coverageWaived = []
    _.each(enrolmentSummary.benefits.coverageWaivedSubscriptionList, (benefit) =>{
        coverageWaived.push(
        <div key={`div-${benefit.benefit}-ID`} className="singleitem pt-3">
          <Row className="">
            <Col className=""><span className="visuallyhidden">{lblCoverageWaivedBenefit}</span>{benefit.benefit} 
              {benefit.editButton != null &&
                <span
                  tid="coverageEditAction"
                  className="editicon icon-action" 
                  role='button'
                  aria-disabled={!benefit.editButton.enabled}
                  onClick={() => {benefit.editButton.enabled && this.handleEditAction(benefit.editButton.link, benefit.editButton.linkID)}}
                  aria-label={editIcon.textToRead+" "+benefit.benefit}
                  onKeyDown={(event) => benefit.editButton.enabled && this.handleDownKeyPressed(event, benefit.editButton.link, benefit.editButton.linkID)}>
                  <FontAwesomeIcon 
                    style={ !benefit.editButton.enabled && {opacity: 0.5, pointerEvents: "none"}}
                    icon={faPencilAlt}
                  />
                </span>}
            </Col>
            <Col className=""><span className="visuallyhidden">{lblCoveregaWaived}</span>{benefit.coverageWaived}</Col>
          </Row>
        </div>
      );
    });

    const terminatedList = []
    _.each(enrolmentSummary.benefits.terminatedSubscriptionsList, (benefit) =>{
        terminatedList.push(
        <div key={`div-${benefit.benefit}-ID`} className="singleitem pt-3">
          <Row className="">
            <Col className=""><span className="visuallyhidden">{lblTerminatedBenefit}</span>{benefit.benefit}</Col>
            <Col className=""><span className="visuallyhidden">{lblTerminatedOption}</span>{benefit.option}</Col>
          </Row>
        </div>
      );
    });

    return (
      <div className="enrollmentsummary tabtwo clearfix">
        {/* <h1 className='caption' aria-label={pageTitle} >{pageTitle}</h1> */}
        {pendingEOI.length > 0 && <>
          <h1 className='caption'>{captionNoteEoi.display}</h1>
        <Card>
            <Card.Header className="p-md-3">
              <Row>
                <Col className=""><h2>{benefitLabel}</h2></Col>
                <Col className=""><h2>{optionLabel}</h2></Col>
                <Col className=""><h2>{descLabel}</h2></Col>
                <Col className=""><h2  dangerouslySetInnerHTML={{__html: costLabel}}/></Col>
              </Row>
            </Card.Header>
            <Card.Body className="p-md-3">
              {pendingEOI}
            </Card.Body>
          <Card.Footer className="p-md-3">
            <Row>
              <Col className="col-12" >{formNoteEoi.display}</Col>
            </Row>
          </Card.Footer>
        </Card> </>}

        {packageList.length > 0 && <>
          <h3>{lblPackageCaption.display}</h3>
          <Card>
          <Card.Header>
              <Row>
                <Col className=""><h2>{lblPackageName}</h2></Col>
                <Col className=""><h2>{lblPackageDescription}</h2></Col>
                <Col className=""><h2  dangerouslySetInnerHTML={{ __html: lblPackageCost }} /></Col>
                <Col className=""><h2>{lblPackageFlex}</h2></Col>
                <Col className=""><h2>{lblPackageDeductions}</h2></Col>
              </Row>
          </Card.Header>
            <Card.Body className="">
              {packageList}
            </Card.Body>
        </Card>  </>}

        {premiumWaived.length > 0 && <>
          <h3>{lblPremiumWaivedCaption.display}</h3>
          <Card>
          <Card.Header>
            <Row>
              <Col className=""><h2>{lblPremiumWaivedName}</h2></Col>
              <Col className=""><h2>{lblPremiumWaivedOption}</h2></Col>
              <Col className=""><h2>{lblPremiumWaivedDescription}</h2></Col>
              <Col className=""><h2>{lblPremiumWaived}</h2></Col>
            </Row>
          </Card.Header>
            <Card.Body className="">
              {premiumWaived}
            </Card.Body>
        </Card>  </>}

        {coverageWaived.length > 0 && <>
          <h3>{lblCoverageWaivedCaption.display}</h3>
          <Card>
          <Card.Header>
              <Row>
                <Col className=""><h2>{lblCoverageWaivedBenefit}</h2></Col>
                <Col className=""><h2>{lblCoveregaWaived}</h2></Col>
              </Row>
            </Card.Header>
            <Card.Body className="">
              {coverageWaived}
            </Card.Body>
        </Card>  </>}

        {terminatedList.length > 0 && <>
          <h3>{lblTerminatedCaption.display}</h3>
          <Card>
          <Card.Header>
              <Row className="">
                <Col className=""><h2>{lblTerminatedBenefit}</h2></Col>
                <Col className=""><h2>{lblTerminatedOption}</h2></Col>
              </Row>
          </Card.Header>
            <Card.Body className="">
              {terminatedList}
            </Card.Body>
        </Card></>}      
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return { enrolmentSummary: state.userReducer.enrollmentSummaryBenefits, 
    contentFooter: state.userReducer.contentFooter,
    sideBarMenu: state.contentReducer.screens,
    deviceType: ( state.userReducer.deviceType || "")
  }
};

export default connect(
mapStateToProps,
{ getSummaryBenefits, setPageSetting }
)(withRouter(SummaryEoi));