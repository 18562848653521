import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { isMobile } from 'react-device-detect';
import { addYears } from 'date-fns';


class  DateComponent extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isreadonly: this.props.isreadonly === "true" ? true : false
    }
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isreadonly !== this.state.isreadonly) {
      this.setState({isreadonly: true});
    }
  }
  onIconClick = () => {
    const datePickerNode = ReactDOM.findDOMNode(this.refs.datepicker)
    datePickerNode && datePickerNode.querySelector('input').click();
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  /**
   * Check if clicked on outside of element
   */
  handleClickOutside(event) {
    const domNode = ReactDOM.findDOMNode(this);
    if(!domNode || !domNode.contains(event.target)) {
      this.setState({isreadonly: true});
    }
  }

  render() {
    const {placeholder, islifeevent} = this.props
    const editMode = this.props.mode === 'edit' ? true : false;
    const labelC = this.props.name==='dateOfBirth'?
                    <Form.Label   {...this.props}><span dangerouslySetInnerHTML={{__html: this.props.display}}/>&nbsp;</Form.Label>:
                    <Form.Label   {...this.props}>{this.props.display}&nbsp;</Form.Label>
    let dateco = {};
    if (editMode) {
      let date;
      if(islifeevent === 'true'){
        date = (this.props.date && this.validate(this.props.date)) ? this.props.date :  new Date()
        if(date.year) {
          const {year, month, day} = date
          date = new Date(year, month, day)
        } else {
          date = new Date(Date.parse(date))
        }
    }
      else {
       date = (this.props.date && this.validate(this.props.date)) ? this.props.date :  null
       if(date !== null) {
        const {year, month, day} = date
        date = new Date(year, month, day)
      } 
      }
      if(this.props.format){
        dateco = (<DatePicker selected={islifeevent === 'true' ? date.display ? date.display : date : date}
                              tid="datepickerFormat"
                              ref="datepicker"
                              autoComplete="off"
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              yearDropdownItemNumber={3}
                              scrollableYearDropdown
                              dropdownMode="select"
                              withPortal={isMobile}
                              onFocus={(e) => e.target.readOnly = isMobile}
                              tabIndex="0"
                              placeholderText={placeholder ? placeholder : 'Date: '}
                              useShortMonthInDropdown
                              dateFormat={this.props.format}
                              minDate={this.props.min_date}
                              maxDate={this.props.max_date ? this.props.max_date : addYears(new Date(), 2)}
                              readOnly={this.state.isreadonly}
                              onChange={this.props.changeDate} {...this.props}/>)
      } else{
        dateco = (<DatePicker selected={islifeevent === 'true' ? date.display ? date.display : date : date}
                              tid="datepicker"
                              ref="datepicker"
                              autoComplete="off"
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              placeholderText={placeholder ? placeholder : 'Date: '}
                              yearDropdownItemNumber={3}
                              scrollableYearDropdown
                              useShortMonthInDropdown
                              dropdownMode="select"
                              withPortal={isMobile}
                              onFocus={(e) => e.target.readOnly = isMobile}
                              tabIndex='0'
                              minDate={this.props.min_date}
                              maxDate={this.props.max_date ? this.props.max_date : addYears(new Date(), 2)}
                              readOnly={this.state.isreadonly}
                              onChange={this.props.changeDate} {...this.props}/>)
      }
    } else {
      const d = `${this.props.date.year ? this.props.date.year+'/' : ''}${this.props.date.month ? this.props.date.month+'/' : ''}${this.props.date.day ? this.props.date.day : ''}`
      const date = this.props.date.display
      if(this.props.date.display)
      dateco = (<Form.Label tid="displayDateLabel"  {...this.props}>{date} </Form.Label>);
      if(!this.props.date.display)
      dateco = (<Form.Label  {...this.props}>{d} </Form.Label>);
    }
    
  return (<div className={`clearfix`}>{labelC}{isMobile ? <div className="react-datepicker-wrapper">{dateco}</div> : dateco} {editMode && <FontAwesomeIcon onClick={() => this.setState({isreadonly:false}, this.onIconClick)}  className="icon-action" icon={faCalendarAlt} />}</div>)
  }
  
  validate = (date) => {
    const res = (date && date.year) || Date.parse(date) ? true: false
    return res
  }
}

export default DateComponent;
