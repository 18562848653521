import React, { Component }  from 'react';
import { connect } from "react-redux";
import { reviewPastCoverage, pastCoverageList } from "../../actions/userActions"
import { dissmiss } from '../../actions/contentActions'
import { getDisplayText } from '../../utils';
import PastCoverageList from './PastCoverageList';
import { trackPromise } from 'react-promise-tracker';

class ReviewCoverage extends Component {
	UNSAFE_componentWillMount(){
		trackPromise(this.props.pastCoverageList());
	}
	componentWillUnmount(){
		this.props.dissmiss()
	}

	render () {
        const lblPageTitle = getDisplayText(this.props.coverageList.contentResource.contents, 'lblPageTitle')
        const btnDownload = getDisplayText(this.props.coverageList.contentResource.contents, 'btnDownload')
		let coverage = this.props.coverageList.previousCoverage ? this.props.coverageList.previousCoverage.map((item, i) => 
				<PastCoverageList key={i} item={item} reviewPastCoverage={this.props.reviewPastCoverage} btnDownload={btnDownload} />
		) : ''
		return (<div className={`pastcoveragelist clearfix pt-md-4 pb-md-4`}>
			<div className="alert alert-info" role="alert">
				<p className="m-0" ><strong>{this.props.coverageList.firsName} {this.props.coverageList.lastName}</strong></p>
				<p className="m-0" >{this.props.coverageList.currentDate}</p>
			</div>		
			
            <h2>{lblPageTitle.display}</h2>
			{coverage}
		</div>);
	}
}

const mapStateToProps = (state) => {
	return {
        coverageList: state.userReducer.coverageList
	}
};

export default connect(
mapStateToProps,
{ reviewPastCoverage, pastCoverageList, dissmiss }
)(ReviewCoverage);