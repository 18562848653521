import React, { Component } from 'react';
import { Table, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Defaults from "../helpers/defaults";
import DisplayButton from './button';

class FlexModal extends Component {
  
  tabbedEnterKeyPressed = (event) => {
    switch(event.which) {
      case 13:{
        this.props.onHide();
        break;
      } 
      default:
        break;
    }
  }


  render() {
    const flex = this.props.userShowFlex || Defaults.showFlex

    const showFlex = flex.showFlex
    // const awardedFlexVisble = flex.awardedFlexVisble
    // const remainingFlexVisible = flex.remainingFlexVisible
    // const deductionsVisible = flex.deductionsVisible

    const flexDetail = flex.flexDetail ? flex.flexDetail : {}
    const breakDownList = flexDetail.breakDownList ? flexDetail.breakDownList : []
    
    const deductionsDetail = flex.deductionsDetail ? flex.deductionsDetail : {}
    const breakDownListDeduction = deductionsDetail.breakDownList ? deductionsDetail.breakDownList : []
    
    const lblPerPayDeductionsTaxNote = deductionsDetail.taxNote || ""


    return (
      <div>
        <Card.Body className="ml-healthpopup">

          { showFlex && this.props.modalType && this.props.modalType === "awarded" &&
            <Table className="ml-popuptable">
              <tbody>
                {breakDownList.map((value, i) => {
                return (
                  <tr key={value.description+i} aria-label={value.description+" "+value.value}>
                    <td key={Math.random()}>{value.description}</td>
                    <td style={{textAlign: "right"}} key={Math.random()}>{value.value}</td>
                  </tr>
                )
                })}
              </tbody>
            </Table>
          }

          { showFlex && this.props.modalType && this.props.modalType === "deduction" &&
            <Table className="ml-popuptable">
              <tbody>
                {breakDownListDeduction.map((value, i) => {
                return (
                  <tr key={value.description+i} aria-label={value.description+" "+value.value}>
                    <td key={Math.random()}>{value.description}</td>
                    <td  style={{textAlign: "right"}} key={Math.random()}>{value.value}</td>
                  </tr>
                )
                })}
                <tr aria-label={lblPerPayDeductionsTaxNote}>
                  <td key={Math.random()}></td>
                  <td key={Math.random()}><strong>{lblPerPayDeductionsTaxNote}</strong></td>
                </tr>
              </tbody>
            </Table>
          }
          <DisplayButton onKeyDown={this.tabbedEnterKeyPressed}
           displayName={<FontAwesomeIcon className="icon-action close" icon={faTimes}><span aria-hidden="true"></span></FontAwesomeIcon>}
          className="link ml-btnpopupclose close" data-dismiss="modal"  onClick={this.props.onHide} arialabel={this.props.arialabel}>
          </DisplayButton>
        </Card.Body>
      </div>
    );
  }
}

export default FlexModal;
