import React from 'react';
import { Form } from 'react-bootstrap';
import { CustomToolTipIcon } from '../../utils';

const CheckBoxWithToolTipComponent = props => {
    return (
        <div className="mb-3 w-50 float-left">
            <Form.Check
                inline
                id={props.id}
                type="checkbox" >
                <Form.Check.Input
                    type="checkbox"
                    aria-checked={props.checked}
                    checked={props.checked}
                    value={props.value}
                    onChange={props.onChange} />
                <Form.Check.Label>
                    {props.displayLabel}
                </Form.Check.Label>
                {props.isToolTip &&
                    <span 
                        role="button"
                        tabIndex="0"
                        className="tooltip-icon icon-action float-right"
                        aria-label={props.toolTipAriaLabel}
                        onClick={props.toolTipOnClick}
                    > <CustomToolTipIcon />
                    </span>
                }
            </Form.Check>
        </div>
    )
}

export default CheckBoxWithToolTipComponent;