import React, { Component } from "react";
import { getDisplayText } from "../../utils";
import { Row, Form, Col } from "react-bootstrap";
import DisplayButton from "../button";

class EmailForm extends Component {
  render() {
    const regContent = this.props.regContent;
    const content = regContent.contentResource ? regContent.contentResource.contents : [];
    const btnSendCode = getDisplayText(content, "btnSendCode");
    const lblDetail1 = getDisplayText(content, "lblDetail1");
    const lblDetail2 = getDisplayText(content, "lblDetail2");

    const lblNoEmailOnFileErrorMsg = getDisplayText(content, "lblNoEmailOnFileErrorMsg");
    const lblTitle = getDisplayText(content, "lblTitle");
    const lblCancel = getDisplayText(content, "btnCancel");
    const btnBack = getDisplayText(content, "btnBack");

    const handleSubmit = event => {
      event.preventDefault();
      const options = {
        PhoneOrEmail: "",
        TypeID: this.props.regContent.twoFATypeId,
        IsRegistration: true,
        resendCount: 0
      };
      // trackPromise(this.props.sendVerificationCode(options));
      return this.props.verificationContent(options);
    };
    return (
      <div className="conatiner" role="main" id="maincontent">
        <div className="login-holder">
          <Row className="h-100">
            <div className="col-12 login-image-holder"></div>
            <div className="logincontainer d-md-flex align-items-center align-content-center justify-content-between">
              <div className="holder login-form description panel d-none d-md-block">
                {/* <h1 className="login-heading d-sm-block d-md-none">{lblTitle.display}</h1> */}
                <span dangerouslySetInnerHTML={{ __html: lblDetail2.display }} />
              </div>
              <div className="form-group login-form mobile-login-form pb-5 pb-md-0" xs={{ order: 1 }} md={{ order: 2 }} lv={{ order: 2 }}>
                <h1 className="login-heading d-md-block">{lblTitle.display}</h1>
                <div className="login-form description panel d-md-none">
                  <span dangerouslySetInnerHTML={{ __html: lblDetail2.display }} />
                </div>
                <div className="holder">
                  <Form id="login-container" className="loginform" noValidate onSubmit={handleSubmit}>
                    <Row>
                      <Col className="col-12 paddingbottom">
                        {regContent.isEmailExists ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: lblDetail1.display
                            }}
                          />
                        ) : (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: lblNoEmailOnFileErrorMsg.display
                            }}
                          />
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className={`btns-forgot d-flex justify-content-between`}>
                          {regContent.allowCancel && <DisplayButton className="outline-secondary" onClick={this.handleCancel} controlId="cancelBtn" displayName={lblCancel.display} />}
                          {!regContent.isEmailExists ? (
                            <DisplayButton className="secondary" onClick={() => window.location.reload(false)} controlId="backBtn" displayName={btnBack.display} />
                          ) : (
                            <DisplayButton className="secondary" type="submit" displayName={btnSendCode.display} />
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </div>
          </Row>
        </div>
      </div>
    );
  }
}

export default EmailForm;
