import React, { Component, Fragment}  from 'react';
import { connect } from "react-redux";
import { Row } from 'react-bootstrap';
import { reviewPastCoverage, pastCoverageList, pdfStatement } from "../../actions/userActions"
import { isMobile } from "react-device-detect";
import { Link} from 'react-router-dom';
import { getDisplayText } from '../../utils';

class PastCoverageList extends Component {
	handleClick = (e) => {
	localStorage.setItem('dateString', this.props.item.dateString)
	}

	render(){
		const footerContent = this.props.contentFooter
		const lblNewTab =getDisplayText(footerContent, 'lblNewTab')
		return (<>	
			<Row className={`justify-content-between align-items-center border-top py-2 py-md-3 m-0 coverageitem`}>
				<Fragment><div className={`col-8 col-sm-auto p-0 pr-sm-3`} dangerouslySetInnerHTML={{__html: this.props.item.display}} /></Fragment>
				<div className={`col-4 col-sm-auto p-0 pl-sm-3`}>
					<Link tid='navigateclick' tabIndex='0' role='button' to={{ pathname:"/coverage", state:{ test: "testt"}}} target={isMobile ? "" : "__blank"} aria-label={!isMobile?this.props.btnDownload.display+' '+lblNewTab.textToRead:''} onClick={(e) => this.handleClick(e)} className={`btn btn-secondary`}>{this.props.btnDownload.display}</Link>
				</div>
			</Row>
			
		</>)
	}
}

const mapStateToProps = (state) => {
	return {
		pastCoverage: state.userReducer.pastCoverage,
		contentFooter: state.userReducer.contentFooter.contentResource ? state.userReducer.contentFooter.contentResource.contents : {}
	}
};

export default connect(
mapStateToProps,
{ reviewPastCoverage, pastCoverageList, pdfStatement }
)(PastCoverageList);