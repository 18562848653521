import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { reallocateMultiBenefit, setPageSetting,getBeneficiaries,setBaseBeneficiaryPageTitle } from '../actions/userActions';
import { dissmiss } from '../actions/contentActions';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Table, Card,Accordion } from 'react-bootstrap';
import { getDisplayText } from '../utils';
import Beneficiaries from './Beneficiaries';
import { trackPromise } from 'react-promise-tracker';
import _ from 'underscore';
import DisplayButton from './button';

class BeneficiariesReallocate extends Component {

  constructor (props) {
    super(props);
    this.state = { showEdit: true, benefitID:this.props.flexbenefitID?this.props.flexbenefitID:-1, expandedAccordion: 0 }
    this.hideEdit = this.hideEdit.bind(this)
  }
  componentDidMount() {
   //trackPromise(this.props.reallocateMultiBenefit(this.props.pageSetting.name==="BENEFICIARIES"?-1:this.state.benefitID)); 
  }
  componentWillUnmount(){
    this.props.dissmiss()
  }
  handleEditAction = (benefitID) => {
    this.setState({showEdit: true, benefitID: benefitID});
  }
  handleBack = (event) => {
    if(!this.isTotalValid()){
    if(this.props.pageSetting.name==="BENEFICIARIES"){
    const url=this.props.pageSetting.link?this.props.pageSetting.link:'/Beneficiary/All'
    this.props.history.push(url)
    }
    else
    this.previousPage() 
    }
  }

  previousPage = () => {
    trackPromise(this.props.setPageSetting(this.props.pageSetting.name))
    if (typeof this.props.pageSetting.link === "string")
    { console.log('previousPage if',this.props.pageSetting.link);
      this.props.history.push(this.props.pageSetting.link)
    }
    else{
      console.log('previousPage else',this.props.pageSetting.link);
      this.props.history.push("/Benefits")
    }
  }

  hideEdit = (event) => {
   // trackPromise(this.props.reallocateMultiBenefit(this.props.pageSetting.name==="BENEFICIARIES"?-1:this.state.benefitID)); 
    this.setState({showEdit: false, benefitID: -1})
  }

  getTotalPer = (beneficiary) => {
    let newTotal = 0
    if((beneficiary || {}).length > 0){
        beneficiary.map(item => {
            return newTotal = item.percentage + newTotal
        })
    }
    return isNaN(newTotal) ? "" : newTotal
  }

  renderBeneficiarieList(title, note, editButton, contents, list, customClass) {
    if (list && list.length > 0) {
      const thcName = getDisplayText(contents, 'thcName');
      const thcRelationship = getDisplayText(contents, 'thcFormRelationship');
      const thcTrustee = getDisplayText(contents, 'thcTrustee');
      const thcPercent = getDisplayText(contents, 'thcPercent');
      const thcTotal = getDisplayText(contents, 'thcTotal');
      //const noteMessage = `${title} <strong>${note ? note : ""}</strong>`
      const items = []
      _.forEach(list, (item, index) => {
        items.push(
          <tr key={`div-${title}-${editButton.linkID}-ID-${item.beneficiaryName}`}>
              <td dangerouslySetInnerHTML={{ __html: `<strong>${item.beneficiaryName}</strong><br />${item.dateOfBirth ? item.dateOfBirth : "n/a"}`}} />
              <td dangerouslySetInnerHTML={{ __html: item.relationShip ? item.relationShip : "n/a"}} />
              <td dangerouslySetInnerHTML={{ __html: item.trustee ? item.trustee : "n/a"}} />{(item.removedMsg==="" || item.removedMsg==null)?<td className="text-right">{item.percentage}%</td>:<td><span className="ml-mandatory-sign">({item.removedMsg})</span> {item.percentage}%</td>}
          </tr>
        )
    })
      /* _.forEach(list, (item, index) => {
          items.push(
            <Row key={`div-${title}-${editButton.linkID}-ID-${item.beneficiaryName}`} className="bgrow justify-content-between mb-2">
              <Col className="align-self-center">{item.beneficiaryName}</Col>
              {(item.removedMsg==="" || item.removedMsg==null)?<Col className="col-auto align-self-center">{item.percent}</Col>:
              <Col className="col-auto align-self-center"><span className="ml-mandatory-sign">({item.removedMsg})</span> {item.percent}</Col>}
            </Row>
          )
      }) */
      return (
        <Fragment>
          <div className="tbl-beneficiaries">
              <h3 className="title">{title}</h3>
              <Table>
              <thead>
                  <tr>
                  <th scope="col">{thcName.display}</th>
                  <th scope="col">{thcRelationship.display}</th>
                  <th scope="col">{thcTrustee.display}</th>
                  <th scope="col">{thcPercent.display}</th>
                  </tr>
              </thead>
              <tbody>{items}</tbody>
              <tfoot>
                  <tr>
                  <td colSpan="3" className="text-right no-right-border">
                      <strong>{thcTotal.display}</strong>
                  </td>
                  <td>
                      <strong className="m-0" >
                      {this.getTotalPer( (list || []).length > 0 ? list : 0)}%
                      </strong>
                  </td>
                  </tr>
              </tfoot>
              </Table>
          </div>
          {/* <Card className={`ccard ${customClass}`}>
          <Card.Header>
            <div className="d-flex justify-content-between">
              <div><h2>{title}</h2> </div>
              <div><h2>{thcPercent.display}</h2></div>
            </div>
          </Card.Header>
              <Card.Body>{items}</Card.Body>
              {note && <Card.Footer><Fragment><div className="alert alert-primary" role='alert'  dangerouslySetInnerHTML={{__html: noteMessage}}/></Fragment>
             </Card.Footer>}
          </Card> */}
        </Fragment>
      )
    }

    return null;
  }

  renderNoBeneficiariesMessage(title, message, thcPercent) {
    return (
      <Card className={`ccard onfilebeneficiaries`}>
        <Card.Header>
          <div className="d-flex justify-content-between">
            <div><h2>{title}</h2> </div>
            <div><h2>{thcPercent.display}</h2></div>
          </div>
        </Card.Header>
          {message && <Card.Footer><Fragment><div className="alert alert-secondary" role='alert' dangerouslySetInnerHTML={{__html: message}}/></Fragment></Card.Footer>}
      </Card>
    );
  }

  isTotalValid = () => {
   const errorMessage=this.props.multiBenefitErrorMessage.errorMessage.length>0 && this.props.beneficiariesAll.beneficiaryBenefitsInfo.length>0?true:false
   return errorMessage 
 
  }

  render() {
    console.log('this.props.beneficiariesAll result',this.props.beneficiariesAll.result?[10,16,18].length:10)
    
    const beneficiaryMessage = this.props.beneficiariesAll.beneficiariesMessage
    const noBeneficiaryBenefits = this.props.beneficiariesAll.noBeneficiaryBenefits ? this.props.beneficiariesAll.noBeneficiaryBenefits : ""
    const noCoverage = this.props.beneficiariesAll.noCoverage ? this.props.beneficiariesAll.noCoverage : ""
    const beneficiaryBenefitsInfo = this.props.beneficiariesAll.beneficiaryBenefitsInfo ? this.props.beneficiariesAll.beneficiaryBenefitsInfo : []
    const benefitID = this.state.benefitID !==-1?this.state.benefitID:(this.props.beneficiariesAll.result ? this.props.beneficiariesAll.result[0] : -1)
    const isCancelVisible = this.props.beneficiariesAll.result ? (this.props.beneficiariesAll.result.length>1?true:false) : false
    
    const content = this.props.beneficiariesAll.contentResource ? this.props.beneficiariesAll.contentResource.controlResource : {}
    const contents = content.Beneficiaries ? content.Beneficiaries : {}
    const pageTitle = getDisplayText(contents, "lblBeneficiaries")
    const thcPercent = getDisplayText(contents, "thcPercent")
    const lblEdit = getDisplayText(contents,'lblChoose')
    const backButtonText = getDisplayText((this.props.contentFooter && this.props.contentFooter) || {},'btnBack')
    const nextButtonLabel = getDisplayText((this.props.contentFooter && this.props.contentFooter) || {},'btnNext')
    
    document.title = pageTitle.display
    const beneficiaryList = [];
    _.forEach(beneficiaryBenefitsInfo, (benefit, index) => {
      
      const onFile = this.renderBeneficiarieList(benefit.lblOnFile, null, benefit.editButton, contents, benefit.beneficiaryCurrentInfo, "onfilebeneficiaries")
      const pending = this.renderBeneficiarieList(benefit.pending, benefit.pendingNote, benefit.editButton, contents, benefit.beneficiaryPendingInfo, "pendingbeneficiaries")
      const contingentOnFile = this.renderBeneficiarieList(benefit.lblContingentOnFile, null, benefit.editButton, contents, benefit.contingentBeneficiaryCurrentInfo, "onfilecontingentbeneficiaries")
      const contingentPending = this.renderBeneficiarieList(benefit.lblContingentPending, benefit.pendingNote, benefit.editButton, contents, benefit.contingentBeneficiaryPendingInfo, "contingentpendingbeneficiaries")
      
      const noBeneficiariesMessage = this.renderNoBeneficiariesMessage(benefit.lblOnFile, benefit.noBeneficiariesNote, thcPercent)
      const noContBeneficiariesMessage = this.renderNoBeneficiariesMessage(benefit.lblContingentOnFile, benefit.noBeneficiariesNote, thcPercent)
      const removedBeneficiaryError=benefit.removedBeneficiaryError;
      beneficiaryList.push(
      <section key={`div-${benefit.benefitID}-ID-${benefit.benefitName}-${index}`} className="mb-md-4">
        { ( this.state.showEdit && (benefit.benefitID === (this.state.benefitID !==-1 ? this.state.benefitID : benefitID) ) ) ? 
          <div className="clearfix">
            <Beneficiaries beneficiariesAll={false} benefitID={(this.state.benefitID!==-1?this.state.benefitID:benefitID)} benefitTitle={benefit.benefitName} hideEdit={this.hideEdit} isCancelVisible={isCancelVisible} handleCancelEdit={this.hideEdit}/>
          </div>
          : 
            <Card className="pcard">
              <Card.Header>
              <h2 className="heading-white">{benefit.benefitName}</h2>
              </Card.Header>
              <Card.Body>
                  { benefit.noBeneficiariesNote ? <>{noBeneficiariesMessage}{noContBeneficiariesMessage}</>
                    : <>
                      {onFile}
                      {contingentOnFile}
                      {pending}
                      {contingentPending}
                    </>
                  }
                 {removedBeneficiaryError && <Fragment><div className="alert alert-danger mb-2" role='alert' dangerouslySetInnerHTML={{ __html: removedBeneficiaryError}} />
              </Fragment>}
              <br/>
                <div className={this.state.expandedAccordion === index ? "clearfix btnbox" : "clearfix btnbox"}>
                  <DisplayButton className="outline-secondary btn-choosebeneficiary" displayName={lblEdit.display} tid="editBtn"  disabled={benefit.noEditableNote} onClick={() => this.handleEditAction(benefit.benefitID)}/>
                </div>
               
              </Card.Body>
            </Card>
        }
      </section>
      )
    })

    return (
      <div className="beneficiariesall baneficiarylist clearfix">
        <div className={`d-flex titlewithicon mb-md-3`}> 
          <DisplayButton className="icon-action" displayName={backButtonText.display} icon={faChevronLeft} onClick={(e) => { this.handleBack(e) }} /> 
        </div>
        <div className="beneficiarylist clearfix mb-md-4">
          { noBeneficiaryBenefits && <Fragment><div className="alert alert-dark" role='alert' dangerouslySetInnerHTML={{ __html: noBeneficiaryBenefits}} /></Fragment> }
          { noCoverage && <Fragment><div className="alert alert-secondary" role='alert'  dangerouslySetInnerHTML={{ __html: noCoverage}} /></Fragment> }
          { !noCoverage && !noBeneficiaryBenefits && beneficiaryList && 
            <Accordion defaultActiveKey="0">
              {beneficiaryList}
            </Accordion>
          }
        </div>
        {beneficiaryMessage && <Fragment><div className="alert alert-info m-0 mb-md-4" role='alert' dangerouslySetInnerHTML={{ __html: beneficiaryMessage}} />
        </Fragment>}
        <div className="d-flex justify-content-between py-3 btnsfoot">
        <DisplayButton className="outline-secondary btn-save" displayName={nextButtonLabel.display}  onClick={this.handleBack} disabled={this.isTotalValid()}/>
       </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
    return { 
      beneficiariesAll: state.userReducer.beneficiariesMultiBenefit ? state.userReducer.beneficiariesMultiBenefit : {},
      contentFooter: (state.userReducer.contentFooter && state.userReducer.contentFooter.contentResource) ? state.userReducer.contentFooter.contentResource.contents : {},
      pageSetting: state.userReducer.pageSetting,
      flexbenefitID:state.userReducer.flexBeneficiaryID,
      multiBenefitErrorMessage: state.userReducer.multiBenefitErrorMessage,
      removeBeneficiaryResponse:state.userReducer.removeBeneficiaryResponse
    }
  };
  
  export default connect(
  mapStateToProps,
  { reallocateMultiBenefit, setPageSetting, dissmiss,getBeneficiaries,setBaseBeneficiaryPageTitle }
  )(withRouter(BeneficiariesReallocate));