import React, { Component } from "react";
import { Container, Row } from "react-bootstrap";
import { getTwoFASettings, getMenu, disableTFA } from "../../actions/contentActions";
import { getDisplayText, skipMainContent } from "../../utils";
import { trackPromise } from "react-promise-tracker";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import SideMenu from "../SideMenu";
import DisplayButton from "../button";

class TwoFASettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showModal: false,
      combinedHeight: 0
    };
  }

  componentDidMount() {
    const headerHeight = document.querySelector("#mainHeader") && document.querySelector("#mainHeader").clientHeight;
    const footerHeight = document.querySelector("#mainFooter") && document.querySelector("#mainFooter").clientHeight;
    const screenHeight = window.innerHeight;
    if (this.state.combinedHeight === 0) {
      this.setState({
        combinedHeight: screenHeight - (headerHeight + footerHeight + 0),
        loading: true
      });
    }
    window.addEventListener("resize", this.resize.bind(this));
    // if (this.props.pageSetting === "undefined" || (this.props.pageSetting && this.props.pageSetting.name !== "MFA")) this.props.setPageSetting("MFA");
  }

  resize() {
    this.setState({
      combinedHeight: window.innerHeight - (document.querySelector("#mainHeader").clientHeight + document.querySelector("#mainFooter").clientHeight + (document.querySelector("#bannerID") ? document.querySelector("#bannerID").clientHeight : 0) + 0)
    });
  }

  onButtonClick = e => {
    trackPromise(this.props.disableTFA(this.props.history));
  };
  UNSAFE_componentWillUpdate() {
    skipMainContent();
  }

  render() {
    const contents = this.props.contents;
    const isDisableAllowed = this.props.isDisableAllowed;
    const isTwoFAEnabled = this.props.isTwoFAEnabled;
    const pageTitle = getDisplayText(contents, "lblTitle");
    const DisableTwoFA = getDisplayText(contents, "DisableTwoFA");
    const EnableTwoFA = getDisplayText(contents, "EnableTwoFA");
    const EnableTwoFAMsg = getDisplayText(contents, "EnableTwoFAMsg");
    const DisableTwoFAMsg = getDisplayText(contents, "DisableTwoFAMsg");
    return (
      <div role="main">
        <Container className="welcome-page-container">
          <Row className="welcome-page-holder">
            <div className="col-lg-3 side-nav-holder-desktop">
              <SideMenu content={this.props.sideBarMenu} isOpen={this.props.isOpen} />
            </div>
            <div
              className="col-md-12 col-lg-9 welcome-content-holder"
              style={{
                minHeight: this.state.combinedHeight + "px"
              }}
            >
              <Row className="welcome-btns">
                {this.state.loading && (
                  <h1 id="heading2FA" className="caption personal-info-caption">
                    {pageTitle.display}
                  </h1>
                )}
              </Row>
              <Row>
                {isTwoFAEnabled && isDisableAllowed ? (
                  <div className="mb-3 mb-lg-4">
                    <p className="bold-text">
                      <span>{EnableTwoFAMsg.display}</span>
                    </p>
                  </div>
                ) : (
                  <div className="mb-3 mb-lg-4">
                    <p className="bold-text">
                      <span>{DisableTwoFAMsg.display}</span>
                    </p>
                  </div>
                )}
              </Row>
              <Row className="col-xs-12 col-md-12 col-lg-12  welcome-btn">
                {isTwoFAEnabled && isDisableAllowed && <DisplayButton name="showStartNewHireEnrollment" className="secondary btn btn-large btn-block col-xs-12 col-md-6 " displayName={DisableTwoFA.display} onClick={this.onButtonClick} />}
                {!isTwoFAEnabled && (
                  <Link
                    style={{
                      outline: "none",
                      boxShadow: "none"
                    }}
                    to="/Registration"
                    name="showEnableTwoFA"
                    aria-label={EnableTwoFA.textToRead}
                    onClick={e => {
                      e.currentTarget.blur();
                    }}
                    className="btn-large btn-block btn btn-secondary col-xs-12 col-md-6"
                  >
                    {EnableTwoFA.display}
                  </Link>
                )}
                <div className="space-height-20" />
              </Row>
            </div>
          </Row>
        </Container>
      </div>
    );
  }
  UNSAFE_componentWillMount = () => {
    trackPromise(this.props.getTwoFASettings());
    trackPromise(this.props.getMenu());
  };
}
const mapStateToProps = state => {
  return {
    sideBarMenu: state.contentReducer.menu2.mainMenu,
    isOpen: state.userReducer.menu.isOpen,
    contents: state.contentReducer.tfaSettings.contentResource ? state.contentReducer.tfaSettings.contentResource.contents : [],
    isDisableAllowed: state.contentReducer.tfaSettings.isDisableAllowed,
    isTwoFAEnabled: state.contentReducer.tfaSettings.isTwoFAEnabled
  };
};

export default connect(mapStateToProps, {
  getMenu,
  getTwoFASettings,
  disableTFA
})(withRouter(TwoFASettings));
