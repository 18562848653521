import React, { Component, Fragment } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getCustomPopUpURL } from '../actions/userActions';
import { setModalAttribute,getDisplayText } from "../utils";
import DisplayButton from './button';

class CustomPopUpURL extends Component {
    constructor(props) {
        super(props);
        this.state = { showPopUpContent: false };
        window.handleURLClick = this.handleURLClick.bind(this);
        this.handlePopUpClose = this.handlePopUpClose.bind(this);
    }
    handleURLClick = link => {
        console.log('--> POPUP LINK: ' + link);
        if (link) {
            this.props.getCustomPopUpURL(link, () => {
                this.setState({ showPopUpContent: true });
            });
        }
    };
    handlePopUpClose = () => {
        this.setState({ showPopUpContent: false });
    };
    render() {
        const closeButtonText = getDisplayText((this.props.contentFooter.contentResource && this.props.contentFooter.contentResource.contents) || {}, 'btnClose')
        return (
            <Modal
                onOpened={() => setModalAttribute('popupcontent')}
                aria-labelledby='modalHeading'
                centered
                isOpen={this.state.showPopUpContent}
                toggle={this.handlePopUpClose}
                className='popupcontent'
            >
                <div className="modal-header">
                <div id="modalHeading">&nbsp;</div>
                    <DisplayButton type="button" arialabel={closeButtonText.display}  className="close" displayName="×" onClick={this.handlePopUpClose}/> 
                </div>
                <ModalBody>
                    {this.state.showPopUpContent && (
                        <Fragment>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: this.props.helpContent
                                }}
                            />
                        </Fragment>
                    )}
                </ModalBody>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        helpContent: state.userReducer.helpContentResponse
            ? state.userReducer.helpContentResponse.result.content
            : '',
        contentFooter: state.userReducer.contentFooter
    };
};

export default connect(mapStateToProps, { getCustomPopUpURL })(withRouter(CustomPopUpURL));
