import React, { Component } from 'react';
import { connect } from "react-redux";
import { getForgotPasswordContent, showError, showSuccess, dissmiss } from "../actions/contentActions";
import { resetPassword } from "../actions/userActions";
import { getDisplayText,getControlId } from "../utils";
import { withRouter, Redirect } from 'react-router-dom';
import { Row, Form, Col } from 'react-bootstrap';
import { trackPromise } from 'react-promise-tracker';
import DisplayButton from './button';

class  ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {username: '', password: '', showModal: false, formValidated: false};
    console.log(props.match)
  }
  
  UNSAFE_componentWillMount () {
    trackPromise(this.props.getForgotPasswordContent())
  } 

  onUsernameChange = (event) => {
    this.setState({username: event.target.value})
  }

  onPasswordChange = (event) => {
    this.setState({password: event.target.value})
  }

  handleCloseModal = () => {
    this.setState({showModal: false})
  }

  handleShowModal = () => {
    this.setState({showModal: true})
  }

  onSubmitConfirmation = () => {
    const rfvLoginInErrorMessage = getDisplayText(this.props.content, 'rfvLoginInErrorMessage')
    if (!this.state.username) this.props.showError(rfvLoginInErrorMessage.display)
    else {
      this.handleShowModal()
    }
  }

  failureCallback = msg => {
    //const invalidCredentialsError = getDisplayText(this.props.content, 'PasswordEmptyErrorMessage')

    this.props.showError('Invalid/Empty username or password!\nPlease try again...')
  }

  displayError = () => {
    //this.props.showError('This feature is not implemented yet, Sorry!\nWill be back soon...')
  }
  handleCancel = (event) => {
    this.props.dissmiss()
    this.props.history.push(`/${this.props.match.params.client}`)
    console.log(this.props.match)
  }
  handleKeyPress = (event) => {
    if(event.key === 'Enter' || event.charCode === 13){
        this.handleSubmit(event)
    }
  }
  handleSubmit = (event) => {
    const me = this
    event.preventDefault();

      if (!this.state.username) this.setState({formValidated: true})
      //else if (!this.state.password) me.props.showError(lblTempPasswordExpired.display)
      else {
        this.props.resetPassword(this.state.username, () => {
          if(me.props.resetPasswordResponse.statusCode === 200) {
              me.props.showSuccess(me.props.resetPasswordResponse.result.Message)
              me.props.history.push(`/${this.props.match.params.client}`)
          } else {
              me.props.showError(me.props.resetPasswordResponse.errorMessage)
          }
        }, me.failureCallback)
      }
      me.handleCloseModal()
  }


  render() {
    const headerPage = getDisplayText(this.props.content, 'lblPageTitleForgot')
    const title = getDisplayText(this.props.content, 'lblForgotPassword')
    const title2 = getDisplayText(this.props.content, 'lblForgotPassword1')
    const lblUserName = getDisplayText(this.props.content, 'lblLogin')
    const lblSubmit = getDisplayText(this.props.content, 'lblSubmit')
    const lblCancel = getDisplayText(this.props.content, 'lblCancel') || {textToRead: 'Cancel', display: 'Cancel'}
    const rfvLoginInErrorMessage = getDisplayText(this.props.content, 'rfvLoginInErrorMessage')
    document.title = headerPage.display
    if ( this.props.isSignedIn) {
      return <Redirect to='/' />
    }
    return (<div className="conatiner" role="main" id="maincontent" >
        <div className="login-holder">
          <Row className="h-100">
            <div className="logincontainer d-md-flex align-items-center align-content-center justify-content-between">
              <div className="holder login-form description panel right-border d-none d-md-block">
                {/* <h1 className="login-heading d-sm-block d-md-none" >{headerPage.display}</h1> */}
                <div dangerouslySetInnerHTML={{__html: title.display}} />
                <div className="pt-3"  dangerouslySetInnerHTML={{__html: title2.display}} />
              </div>
              <div className="form-group login-form mobile-login-form" xs={{ order: 1 }} md={{ order: 2 }} lv={{ order: 2 }}>
                <div>
                  <h1 className="login-heading d-md-block" >{headerPage.display}</h1>
                  <div className="login-form description panel right-border d-md-none">
                    <div dangerouslySetInnerHTML={{__html: title.display}} />
                    <div className="pt-3"  dangerouslySetInnerHTML={{__html: title2.display}} />
                  </div>
                  <Form id="login-container" className="loginform" noValidate validated={this.state.formValidated} onSubmit={this.handleSubmit}>
                    <Row>
                      <Col className="col-12 pb-md-3">
                        <label  htmlFor={getControlId(lblUserName.textToRead)} className="d-none d-md-block d-xl-block">{lblUserName.display}</label>
                        <Form.Control type="text"
                         id={getControlId(lblUserName.textToRead)}
                          placeholder={lblUserName.display}
                          className="form-control login-input-field"
                          required
                          autoComplete="username"
                          value={this.state.username}
                          onChange={this.onUsernameChange}
                          onKeyPress={this.handleKeyPress}/>
                        <Form.Control.Feedback type="invalid" role='alert'    className="mt-2">
                          {rfvLoginInErrorMessage.display}
                        </Form.Control.Feedback>
                      </Col>
                    </Row>
                    <div className={`btns-forgot d-flex justify-content-between`}>
                      {/*<div className={`col-sm-6`}>*/}
                        <DisplayButton
                          className="outline-secondary btn-cancel"
                          onClick={this.handleCancel}
                          controlId="cancelBtn"
                          displayName={lblCancel.display} />
                            
                        
                      {/*</div>
                      <div className={`col-sm-6`}>*/}
                        <DisplayButton
                          className="secondary btn-submit"
                          type="submit"
                          displayName={lblSubmit.display}
                          onClick={this.handleSubmit}
                        />
                      {/*</div>*/}
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    content: state.contentReducer.forgotPasswordContent.contentResource? state.contentReducer.forgotPasswordContent.contentResource.contents : [],
    configs: state.contentReducer.forgotPasswordContent.result,
    user: {username: '', password: ''},
    isSignedIn: state.userReducer.isSignedIn,
    locale: state.contentReducer.locale,
    resetPasswordResponse: state.userReducer.resetPasswordResponse
  }
};
  
export default connect(
mapStateToProps,
{ getForgotPasswordContent, resetPassword, showError, showSuccess, dissmiss}
)(withRouter(ForgotPassword));
