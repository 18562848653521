import React, { Component } from "react";
import ReactDOM from "react-dom";
import {
  getLifeChangeEvent,
  getLoginContent,
  getForgotPasswordContent,
  getTemporaryPasswordContent,
  getHeaderImages,
  getClientStylesContent,
  changeLocale,
  getWelcomeContent,
  getMenu,
  getMenu2,
  dissmiss
} from "../actions/contentActions";
import {
  getContentFooterPage,
  getContentFooter,
  getProfile,
  getDependents,
  toggleSideBar,
  getLifeBenefit,
  getWellness,
  getBeneficiaries,
  changeLocaleForBenefit,
  languageChange
} from "../actions/userActions";
import { logoff } from "../actions/userActions";
import { connect } from "react-redux";
import defaultFlexitLogo from "../assets/png/flexit-logo.png";
import defaultClientLogo from "../assets/png/2ndLogo.png";
import { Button } from "reactstrap";
import { Navbar, Nav } from "react-bootstrap";
import SideMenu from "./SideMenu";
import { getDisplayText, skipMainContent } from "../utils";
import { trackPromise } from "react-promise-tracker";
import { createBrowserHistory, createMemoryHistory } from "history";
import CustomPopUpURL from "./CustomPopUpURL";
import { isMobile } from "react-device-detect";
import { USER_TOKEN } from "../helpers/storage";

export const history = (!document.isJest && createBrowserHistory()) || createMemoryHistory();

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false, isLoading: true, isMenuOpen: props.isOpen };
  }
  UNSAFE_componentWillMount() {
    trackPromise(this.props.getHeaderImages());
    trackPromise(this.props.getClientStylesContent());
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.client !== this.props.client) {
      this.props.getClientStylesContent(true);
    }
  }

  toggleSidebar = (e) => {
    this.props.toggleSideBar(this.props.isOpen ? false : true);
  };

  UNSAFE_shouldComponentUpdate(nextProps, nextState) {
    if (this.props.isMenuOpen !== nextProps.isMenuOpen) {
      this.props.toggleSideBar(nextProps.isMenuOpen ? false : true);
    }
    if (nextProps.focusInitializing !== this.props.focusInitializing) {
      const titleHeading = ReactDOM.findDOMNode(this.refs.refreshFocus);
      if (titleHeading) {
        titleHeading.focus();
      }
    }
    if (nextProps.isSignedIn !== this.props.isSignedIn && document.getElementById("mainHeader") !== null) {
      document.getElementById("mainHeader").setAttribute("tabIndex", "-1");
      document.getElementById("mainHeader").focus();
    }
    return true;
  }

  skipMainContentKeyDown = (e) => {
    /* if (e.shiftKey && e.which === 9 && this.props.isOpen) {
      document.querySelector("[tid='logoffMenuItem']").focus();
    } */
    if (e.which === 13) {
      skipMainContent();
    }
  };

  goToLastMenuItem = (e) => {
    if (e.shiftKey && e.which === 9 && this.props.isOpen) {
      document.querySelector("[tid='logoffMenuItem']").focus();
    }
  };

  resetTabOrder = (isReset = false) => {
    if (isReset) {
      this.props.toggleSideBar(this.props.isOpen && false);
      document.getElementById("mainpage").setAttribute("tabIndex", "-1");
      document.getElementById("mainpage").focus();
    } else {
      document.getElementById("mainpage").removeAttribute("tabIndex");
    }
  };

  goToMainContent = (e) => {
    if (!this.props.isOpen && ((!e.shiftKey && e.which === 9 && window.innerWidth <= 1000) || isMobile))
      this.resetTabOrder(true);
  };

  escapeHandler = (e) => {
    if (e.keyCode === 27 || e.which === 27) this.resetTabOrder(true);
  };

  render() {
    const style = JSON.parse(localStorage.getItem("style"));
    const labelEN = getDisplayText(this.props.language, "labelEN");
    const labelFR = getDisplayText(this.props.language, "labelFR");
    const toolTipEn = getDisplayText(this.props.language, "toolTipEn");
    const toolTipFr = getDisplayText(this.props.language, "toolTipFr");
    const altText = getDisplayText(this.props.language, "logoAltText");
    const clientLogoAltText = getDisplayText(this.props.language, "clientLogoAltText");
    const skipContent = getDisplayText(this.props.language, "skipContent");
    const AllowFrench = !style ? "True" : style["AllowFrench"] !== null ? style["AllowFrench"] : "True";

    return (
      <div className="nav-holder" id="mainHeader" onKeyDown={this.escapeHandler}>
        <Navbar
          bg=""
          aria-label="nav-header"
          className="container custom-navbar-styling hide-desktop-menu show-side-nav"
          expanded={this.props.isOpen}
          expand="lg"
        >
          <span
            className={`main-content-skip-nav-link btn-link ${this.props.isOpen ? "hide" : "show"}`}
            tabIndex="0"
            role="link"
            onKeyDown={this.skipMainContentKeyDown}
            onClick={skipMainContent}
          >
            {skipContent.display}
          </span>
          {this.props.isSignedIn &&
            !this.props.needTwoFAValidation &&
            !/\/changepassword$/g.test(window.location.pathname) && (
              <Navbar.Toggle
                aria-controls="basic-navbar-nav"
                tabIndex="0"
                onClick={this.toggleSidebar}
                onKeyDown={this.goToLastMenuItem}
              />
            )}
          <div className="logos-holder">
            <span className="img--holder">
              {/* <Navbar.Brand  style={{backgroundImage: `url(${this.props.headerImages.headerLogo})`}}/> */}
              <Navbar.Brand className="text-center" tabIndex="0" role="link">
                <img
                  src={this.props.headerImages.headerLogo}
                  className="d-inline-block align-top"
                  alt={altText.textToRead}
                />
              </Navbar.Brand>
            </span>
            <span className="img--holder">
              <Navbar.Brand
                tabIndex="0"
                className="text-center"
                href={this.props.headerNavURL}
                role="link"
                onKeyDown={(e) => this.goToMainContent(e)}
              >
                <img
                  src={this.props.headerImages.clientLogo}
                  className="d-inline-block align-top"
                  alt={clientLogoAltText.textToRead}
                />
              </Navbar.Brand>
            </span>
          </div>

          <Navbar.Collapse id="basic-navbar-nav" className="nav-links-holder justify-content-end">
            {this.props.isSignedIn && !/\/changepassword$/g.test(window.location.pathname) && (
              <Nav className="side-nav-holder-mobile">
                <SideMenu content={this.props.sideBarMenu} isOpen={this.props.isOpen} isMobile={true} />
              </Nav>
            )}
            {AllowFrench === "True" && (
              <Nav className="navbar-text">
                <ul className="language-tab-holder float-right">
                  <li className="divider">
                    <Button
                      tid="changeLocale"
                      tabIndex="0"
                      aria-label={toolTipEn.textToRead}
                      data-toggle="tooltip"
                      // disabled={/en/.test(this.props.locale)}
                      className={/en/.test(this.props.locale) ? "lang-btn active bold-text" : "lang-btn"}
                      variant="link"
                      onClick={() => this.changeLocale("en")}
                    >
                      {labelEN.display}
                    </Button>
                  </li>
                  <li>
                    <Button
                      data-toggle="tooltip"
                      tabIndex="0"
                      aria-label={toolTipFr.textToRead}
                      // disabled={/fr/.test(this.props.locale)}
                      className={/fr/.test(this.props.locale) ? "lang-btn active bold-text" : "lang-btn"}
                      variant="link"
                      onClick={() => this.changeLocale("fr")}
                    >
                      {labelFR.display}
                    </Button>
                  </li>
                </ul>
                {/* {this.props.isSignedIn && <Nav.Link onClick={()=>{this.props.logoff()}} title={logoffText.textToRead} aria-label={logoffText.textToRead}>{logoffText.display}</Nav.Link>} */}
              </Nav>
            )}
          </Navbar.Collapse>
        </Navbar>
        <CustomPopUpURL />
      </div>
    );
  }
  changeLocale = (locale) => {
    this.props.dissmiss();
    const pathname = window.location.search
      ? window.location.pathname.toLowerCase() + window.location.search
      : window.location.pathname.toLowerCase();
    USER_TOKEN.get().languageSwitchRequired && USER_TOKEN.removeQCUser();
    trackPromise(this.props.languageChange(locale, this.props.client.toLowerCase(), pathname, this.props.needTwoFAValidation));
  };
}
const mapStateToProps = (state) => {
  return {
    headerImages: state.contentReducer.images
      ? state.contentReducer.images
      : { headerLogo: defaultFlexitLogo, clientLogo: defaultClientLogo },
    locale: state.contentReducer.locale,
    isSignedIn: state.userReducer.isSignedIn ? state.userReducer.isSignedIn : false,
    needTwoFAValidation: state.userReducer.needTwoFAValidation ? state.userReducer.isSignedIn : false,
    isOpen: state.userReducer.menu.isOpen ? state.userReducer.menu.isOpen : false,
    sideBarMenu: state.contentReducer.menu2.mainMenu ? state.contentReducer.menu2.mainMenu : [],
    client: state.contentReducer.client,
    language: state.contentReducer.language,
    headerNavURL: state.userReducer.headerNavURL,
    focusInitializing: state.contentReducer.focusInitializing
  };
};

export default connect(mapStateToProps, {
  getContentFooterPage,
  getLifeChangeEvent,
  getContentFooter,
  getWellness,
  getLifeBenefit,
  toggleSideBar,
  getLoginContent,
  getForgotPasswordContent,
  getTemporaryPasswordContent,
  getHeaderImages,
  getClientStylesContent,
  changeLocale,
  getWelcomeContent,
  getMenu,
  getMenu2,
  logoff,
  getProfile,
  getDependents,
  getBeneficiaries,
  changeLocaleForBenefit,
  languageChange,
  dissmiss
})(Header);
