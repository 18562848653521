import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "../reducers";
import thunk from "redux-thunk";
import { createLogger } from 'redux-logger';
import { cacheEnhancer } from 'redux-cache';
// import { loadState } from "./storage.js";
// import Defaults from "../helpers/defaults";

//const persistedState =  loadState() || Defaults.state; //{userReducer:{authResponse: loadState() || Defaults.authResponse}, contentReducer: {}};
//TODO : FIX
const loggerMiddleware = createLogger();

const middleware = [thunk];
if(!document.isJest) {
  middleware.push(loggerMiddleware);
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  //persistedState,
  composeEnhancers(applyMiddleware(...middleware), cacheEnhancer({ log: true }))
);

export default store;
