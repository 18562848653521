import React, { Component } from 'react';
import { connect } from "react-redux";
import ReactDOM from 'react-dom';
import { getTemporaryPasswordContent, showError, showSuccess, dissmiss} from "../actions/contentActions";
import { changePassword, logoff, onChangePassword, setPageSetting } from "../actions/userActions";
import { getDisplayText,getControlId } from "../utils";
import { withRouter, Redirect } from 'react-router-dom';
import { Row, Form, Col } from 'react-bootstrap';
import DisplayButton from './button';
import { trackPromise } from 'react-promise-tracker';

class  ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {password1: '', password2: '', formValidated: false};
    }

    UNSAFE_componentWillMount () {
        trackPromise(this.props.getTemporaryPasswordContent())
    }
      
    componentWillUnmount() {
        if (this.props.messageType === "danger")
            this.props.dissmiss()

           
    }

    onInputChange = (event) => {
        let state = {...this.state}
        state[event.target.name] = event.target.value;
        this.setState(state);
    }

    failureCallback = msg => {
        //const invalidCredentialsError = getDisplayText(this.props.content, 'PasswordEmptyErrorMessage')
        this.props.showError('Invalid/Empty username or password!\nPlease tye again...')
    }

    handleCancel = (event) => {
        if (this.props.passwordChangeRequired) {
            this.props.logoff() 
        } else {
            this.props.setPageSetting("WELCOME")
            this.props.history.replace("/")
        }
    }
    handleKeyPress = (event) => {
        if(event.key === 'Enter' || event.charCode === 13){
            this.handleSubmit(event)
        }
    }
    handleSubmit = (event) => {
        const me = this
        const ComparePswdErrorMessage = getDisplayText(this.props.content, 'ComparePswdErrorMessage')
        const PasswordValidationMsg = getDisplayText(this.props.content, 'PasswordValidationMsg')
        
        event.preventDefault();
        if (!this.state.password1){
            this.setState({formValidated: true})
            ReactDOM.findDOMNode(this.refs.password1).focus(); 
        }
        else if (!this.state.password2){
            this.setState({formValidated: true})
            ReactDOM.findDOMNode(this.refs.password2).focus(); 
        }
        else if (this.state.password1 !== this.state.password2) me.props.showError(ComparePswdErrorMessage ? ComparePswdErrorMessage.display : 'error')
        else if (this.state.password1.length < parseInt(this.props.configs.minPassWordLength) 
                || this.state.password1.length < parseInt(this.props.configs.minPassWordLength))
                me.props.showError(PasswordValidationMsg ? PasswordValidationMsg.display : 'error')
        else {
            let tfaDate = JSON.parse(localStorage.getItem('tfaDate'));
            trackPromise(this.props.changePassword(this.state.password1, tfaDate, () => {
                if(me.props.resetPasswordResponse.statusCode === 200 && !me.props.resetPasswordResponse.errorMessage) {
                    me.props.showSuccess(me.props.resetPasswordResponse.result.Message)
                    const { needTwoFARegistration, needTwoFAValidation } = me.props
                    needTwoFARegistration || needTwoFAValidation ? me.props.onChangePassword(false, true):me.props.onChangePassword(false, false);
                    if(needTwoFARegistration && needTwoFAValidation) {
                        console.log("Here..1");
                        me.props.history.push("/Registration")
                    } else if(!needTwoFARegistration && needTwoFAValidation) {
                        console.log("Here..2");
                        me.props.history.push("/Verification")
                    } else {
                        me.props.history.push("/")
                    }
                } else {
                    me.props.showError(me.props.resetPasswordResponse.errorMessage)
                }
            }, me.failureCallback))
        }
    }
    
    render() {
        const headerPage = getDisplayText(this.props.content, 'lblChangePasswordTitle')
        const title = getDisplayText(this.props.content, 'lblChangePasswordTitle')
        document.title = title.display
        const title2 = getDisplayText(this.props.content, 'lblChangePasswordData')
        const lblNewPassword = getDisplayText(this.props.content, 'lblNewPassword')
        const lblReEnterNewPassword = getDisplayText(this.props.content, 'lblReEnterNewPassword')
        const lblSubmit = getDisplayText(this.props.content, 'lblSubmit')
        const lblCancel = getDisplayText(this.props.content, 'btnCancel') || {textToRead: 'Cancel', display: 'Cancel'}
        const rfvReenterNewPasswordErrorMessage = getDisplayText(this.props.content, 'rfvReenterNewPasswordErrorMessage')
        const rfvNewPasswordErrorMessage = getDisplayText(this.props.content, 'rfvNewPasswordErrorMessage')
        const lblHome = getDisplayText(this.props.contentFooter, "lblHome")
        const PasswordGuideLineMsg = getDisplayText(this.props.content, 'PasswordGuideLineMsg')

        if (!this.props.isSignedIn) {
            console.log("--> REDIRECT ON CHANGE PW: ", this.props.client)
            return <Redirect to={`/${this.props.client}/login`} />
        }

        return (<div className="conatiner" role="main" id="maincontent">
            <div className="login-holder">
                <Row className="h-100">
                    <div className="logincontainer d-md-flex align-items-center align-content-center justify-content-between">
                        <div className="description panel holder login-form">
                            <h1 className="description panel change-password-heading login-heading d-sm-block d-md-none">{headerPage.display}</h1>
                            <span >{title.display}</span>
                            <br/>
                            <span  role="term" dangerouslySetInnerHTML={{__html: title2.display}} />
                        </div>
                        <div className="form-group login-form mobile-login-form" xs={{ order: 1 }} md={{ order: 2 }} lv={{ order: 2 }}>
                            <div className="holder">
                                <Form id="login-container" className="loginform" aria-label={headerPage.textToRead} role='form' noValidate validated={this.state.formValidated} onSubmit={this.handleSubmit}>
                                    <h1 className="description panel change-password-heading login-heading d-none d-md-block">{headerPage.display}</h1>
                                    <Row>
                                        <Col className="col-12 pb-md-3">
                                            <label htmlFor={getControlId(lblNewPassword.textToRead)}  className="d-none d-md-block d-xl-block" >{lblNewPassword.display}</label>
                                            <Form.Control
                                                type="password" 
                                                ref="password1" 
                                                name="password1" 
                                                minLength={this.props.configs.minPassWordLength}
                                                maxLength={this.props.configs.maxPassWordLength}
                                                id={getControlId(lblNewPassword.textToRead)}
                                                placeholder={lblNewPassword.display} 
                                                className="form-control login-input-field"
                                                required
                                                autoComplete="new-password"
                                                value={this.state.password1} 
                                                onChange={this.onInputChange}/>
                                            <Form.Control.Feedback type="invalid"  role='alert'  className="mt-2">{rfvNewPasswordErrorMessage.display}</Form.Control.Feedback>
                                        </Col>
                                        <Col className="col-12 pb-md-4">
                                            <label htmlFor={getControlId(lblReEnterNewPassword.textToRead)}  className="d-none d-md-block d-xl-block"  >{lblReEnterNewPassword.display}</label>
                                            <Form.Control  type="password" 
                                                ref="password2" 
                                                name="password2" 
                                                id={getControlId(lblReEnterNewPassword.textToRead)}
                                                minLength={this.props.configs.minPassWordLength}
                                                maxLength={this.props.configs.maxPassWordLength}
                                                placeholder={lblReEnterNewPassword.display} 
                                                className="form-control login-input-field" 
                                                required
                                                autoComplete="new-password"
                                                value={this.state.password2} 
                                                onChange={this.onInputChange} 
                                                onKeyPress={this.handleKeyPress}/>
                                            <Form.Control.Feedback type="invalid" role='alert'  className="mt-2">{rfvReenterNewPasswordErrorMessage.display}</Form.Control.Feedback>
                                        </Col>
                                    </Row>
                                    <div className="password-guideline" dangerouslySetInnerHTML={{__html: PasswordGuideLineMsg.display}} />
                                    <div className={`btns-forgot d-flex justify-content-between`}>
                                        <DisplayButton
                                            className="outline-secondary"
                                            onClick={this.handleCancel}
                                            controlId="cancelBtn"
                                            displayName={lblCancel.display} />
                                                
                                        
                                        <DisplayButton
                                            className="secondary"
                                            type="submit"
                                            displayName={lblSubmit.display} />
                                    </div>
                                    {/* <div className="btncontainer overflow-hidden d-flex mt-3 pt-4 border-top justify-content-end">
                                        <DisplayButton 
                                            className="btn btn-secondary btn-home" 
                                            onClick={()=> this.handleCancel()} 
                                            displayName={lblHome.display} />
                                    </div> */}
                                </Form>
                            </div>
                        </div>
                    </div>
                </Row>
            </div>
        </div>);
    }
}

const mapStateToProps = (state) => {
    return {
        content: state.contentReducer.temporaryPasswordContent.contentResource? state.contentReducer.temporaryPasswordContent.contentResource.contents : [], 
        configs: state.contentReducer.temporaryPasswordContent.result || {},
        user: {username: '', password: ''},
        isSignedIn: state.userReducer.isSignedIn,
        resetPasswordResponse: state.userReducer.resetPasswordResponse,
        passwordChangeRequired: state.userReducer.authResponse.passwordChangeRequired,
        needTwoFARegistration: state.userReducer.needTwoFARegistration,
        needTwoFAValidation: state.userReducer.needTwoFAValidation,
        client: state.contentReducer.client,
        contentFooter: (state.userReducer.contentFooter && state.userReducer.contentFooter.contentResource) ? state.userReducer.contentFooter.contentResource.contents : {},
        messageType: state.contentReducer.messageType
    }
};
  
export default connect(
mapStateToProps,
{ getTemporaryPasswordContent, changePassword, showError, showSuccess, logoff, onChangePassword, setPageSetting, dissmiss }
)(withRouter(ChangePassword));
