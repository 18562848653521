import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import { Card, Row, Form } from "react-bootstrap";
import {
  getDisplayText,
  makeFlexitDateObj,
  getByValue,
  getControlId,
  setModalAttribute,
  CustomToolTipIcon
} from "../utils";
import DateComponent from "./Calender";
import ViewEditText from "./ViewEditText";
import _ from "underscore";
import DisplayButton from "./button";
import NewMultiRadioComponent from "./NewMultiRadioComponent";
import { Modal, ModalBody } from 'reactstrap';

export const FormErrors = ({ formErrors }) => (
  <div className="formErrors">
    {Object.keys(formErrors).map((fieldName, i) => {
      if (formErrors[fieldName].length > 0) {
        return (
          <p className="formErrorPStyle" key={i} role="alert">
            {" "}
            {formErrors[fieldName]}
          </p>
        );
      } else {
        return "";
      }
    })}
  </div>
);

class NewBeneficiariesForm extends Component {
  constructor(props) {
    super(props);
    const editMode =
      this.props.isEditMode && this.props.benefiaryType === "person"
        ? true
        : false;
    this.state = {
      beneficiary: {
        relationshipID: editMode ? this.props.beneficiary.relationshipID : null,
        beneficiaryType: this.props.benefiaryType
          ? this.props.benefiaryType
          : "person",
        revocable: editMode
          ? this.props.beneficiary.isRevocable === 1
            ? true
            : false
          : true,
        firstName: editMode ? this.props.beneficiary.name.split(" ")[0] : "",
        initial: editMode ? this.props.beneficiary.initial : "",
        lastName: editMode ? this.props.beneficiary.name.split(" ")[1] : "",
        dateOfBirth: {
          year: editMode
            ? this.props.beneficiary.birthDate &&
              this.props.beneficiary.birthDate.year
              ? this.props.beneficiary.birthDate.year
              : null
            : null,
          month: editMode
            ? this.props.beneficiary.birthDate &&
              this.props.beneficiary.birthDate.month
              ? this.props.beneficiary.birthDate.month
              : null
            : null,
          day: editMode
            ? this.props.beneficiary.birthDate &&
              this.props.beneficiary.birthDate.day
              ? this.props.beneficiary.birthDate.day
              : null
            : null
        },
        trustee: editMode ? this.props.beneficiary.trustee : null,
        fullName: "",
        charity:
          this.props.isEditMode && this.props.benefiaryType === "charity"
            ? this.props.beneficiary.name
            : "",
        estate:
          this.props.isEditMode && this.props.benefiaryType === "estate"
            ? this.props.beneficiary.name
            : "",
        beneficiaryId: !this.props.isEditMode
          ? -1
          : this.props.beneficiary.beneficiaryId
      },
      formErrors: {
        firstName: "",
        lastName: "",
        initial: "",
        trustee: "",
        charity: "",
        estate: "",
        dateOfBirth: ""
      },
      firstNameValid: false,
      lastNameValid: false,
      initialValid: true,
      birthDateValid: true,
      formValid: false,
      charity: false,
      estate: false,
      errorMessage: { relationshipID: "" },
      error: [],
      formValidated: false,
      isEnabled: this.props.isEditMode ? true : false,
      showDesc: false,
      description: "",
      descTitle: ""
    };
  }

  setValue = e => {
    const { name, value } = e.target;
    let beneficiary = { ...this.state.beneficiary };
    beneficiary[name] = value;
    this.setState({ beneficiary, isEnabled: false }, () => {
      this.validateField(name, value);
      this.validateSaveButton(beneficiary);
    });
  };
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let validationforFirstName = this.state.firstNameValid;
    let validationforInitial = this.state.initialValid;
    let validationforLastName = this.state.lastNameValid;
    let validationforCharity = this.state.charityValid;
    let validationforEstate = this.state.estateValid;
    const {
      BenefitBeneficiaries
    } = this.props.beneficiaries.contentResource.controlResource;
    const regCharityNameErrorMessage = getDisplayText(
      BenefitBeneficiaries,
      "regCharityNameErrorMessage"
    ).display;
    const regFirstNameErrorMessage = getDisplayText(
      BenefitBeneficiaries,
      "regFirstNameErrorMessage"
    ).display;
    // const regInitialErrorMessage = getDisplayText(BenefitBeneficiaries, 'regInitialErrorMessage').display
    const regLastNameErrorMessage = getDisplayText(
      BenefitBeneficiaries,
      "regLastNameErrorMessage"
    ).display;
    const regTrusteeEstateErrorMessage = getDisplayText(
      BenefitBeneficiaries,
      "regTrusteeEstateErrorMessage"
    ).display;

    switch (fieldName) {
      case "firstName":
        validationforFirstName = value.match(
          this.props.beneficiaries.validationforFirstName
        );
        if (validationforFirstName === null) {
          validationforFirstName = false;
        }
        fieldValidationErrors.firstName = validationforFirstName
          ? ""
          : regFirstNameErrorMessage;
        break;
      case "initial":
        validationforInitial = value.match(
          this.props.beneficiaries.validationforInitial
        );
        if (validationforInitial === null) {
          validationforInitial = false;
        }
        fieldValidationErrors.initial = validationforInitial ? "" : "";
        break;
      case "lastName":
        validationforLastName = value.match(
          this.props.beneficiaries.validationforLastName
        );
        if (validationforLastName === null) {
          validationforLastName = false;
        }
        fieldValidationErrors.lastName = validationforLastName
          ? ""
          : regLastNameErrorMessage;
        break;
      case "charity":
        validationforCharity = value.match(
          this.props.beneficiaries.validationforCharity
        );
        fieldValidationErrors.charity = validationforCharity
          ? ""
          : regCharityNameErrorMessage;
        break;
      case "estate":
        validationforEstate = value.match(
          this.props.beneficiaries.validationforEstate
        );
        fieldValidationErrors.estate = validationforEstate
          ? ""
          : regTrusteeEstateErrorMessage;
        break;
      default:
        break;
    }
    this.setState({
      formErrors: fieldValidationErrors,
      firstNameValid: validationforFirstName,
      initialValid: validationforInitial,
      lastNameValid: validationforLastName,
      charityValid: validationforCharity,
      estateValid: validationforEstate,
      formValid:
        (validationforFirstName &&
          validationforInitial &&
          validationforLastName) ||
        validationforCharity ||
        validationforEstate
    });
  }

  changeDate = e => {
    let beneficiary = { ...this.state.beneficiary };
    const name = "dateOfBirth";
    beneficiary[name] = makeFlexitDateObj(e);

    this.setState({ beneficiary }, () => {
      this.validateField(name, beneficiary[name]);
      this.validateSaveButton(beneficiary);
    });
  };

  handleDateChange = (dob) => {
    let beneficiary = {...this.state.beneficiary}
    const name = "dateOfBirth";
    let dateOfBirth = { ...dob };
    if(dateOfBirth.year === "NaN" || dateOfBirth.year === ""){
      dateOfBirth.day = null;
      dateOfBirth.month = null;
      dateOfBirth.year = null;
    }
    // console.log(dateOfBirth);
    beneficiary[name] = dateOfBirth
    this.setState({ beneficiary }, () => {
      this.validateField(name, beneficiary[name]);
      this.validateSaveButton(beneficiary);
    });
  };

  changeInput = e => {
    let beneficiary = { ...this.state.beneficiary };
    beneficiary[e.target.name] = e.target.value;
    this.setState({ beneficiary, isEnabled: false, error: [] });
  };

  changeInputNumber = e => {
    let beneficiary = { ...this.state.beneficiary };
    beneficiary[e.target.name] = e.target.value === "false" ? false : true;
    this.setState({ beneficiary });
    this.validateSaveButton(beneficiary);
  };

  changeRelationship = e => {
    let beneficiary = { ...this.state.beneficiary };
    beneficiary["relationshipID"] = e.target.value;
    this.setState({ beneficiary, isEnabled: false });
    this.validateSaveButton(beneficiary);
  };

  createBeneficiary = (e) => {
    e.preventDefault()
    this.setState({ isEnabled: true });
    let beneficiary = { ...this.state.beneficiary };
    // beneficiary.percentage = 0
    // beneficiary.deletEnabled = true
    if (
      (beneficiary.firstName === "" ||
        beneficiary.lastName === "" ||
        beneficiary.lastName === "" ||
        beneficiary.relationshipID === null ||
        beneficiary.relationshipID === "0") &&
      beneficiary.charity === "" &&
      beneficiary.beneficiaryType !== "estate"
    ) {
      if (beneficiary.firstName === "") {
        ReactDOM.findDOMNode(this.refs.firstName) &&
          ReactDOM.findDOMNode(this.refs.firstName).focus();
      } else if (beneficiary.lastName === "") {
        ReactDOM.findDOMNode(this.refs.lastName) &&
          ReactDOM.findDOMNode(this.refs.lastName).focus();
      } else if (
        beneficiary.relationshipID === null ||
        beneficiary.relationshipID === "0"
      ) {
        ReactDOM.findDOMNode(this.refs.relationshipID) &&
          ReactDOM.findDOMNode(this.refs.relationshipID).focus();
      }
      this.setState({ formValidated: true });
    } else {
      if (beneficiary.beneficiaryType === "person")
        beneficiary.relationshipID = parseInt(
          getByValue(
            this.props.beneficiaries.relationshipList,
            beneficiary.relationshipID
          ).value
        );
      beneficiary.fullName = `${beneficiary.firstName} ${beneficiary.lastName}`;
      if (
        beneficiary.dateOfBirth /*&& beneficiary.dateOfBirth.month*/ &&
        beneficiary.dateOfBirth.year &&
        beneficiary.dateOfBirth.day
      )
        beneficiary.dateOfBirth.month = beneficiary.dateOfBirth.month;
      else beneficiary.dateOfBirth = null;
      this.props.createBeneficiary(
        beneficiary,
        err => {
          if (beneficiary.dateOfBirth !== null)
            beneficiary.dateOfBirth.month = beneficiary.dateOfBirth.month;
          this.setState({
            error: err
          });
          if (this.state.error && this.refs.formErrorMessage) {
            ReactDOM.findDOMNode(this.refs.formErrorMessage).focus()
            this.setState({ isEnabled: false });
          }
        },
        () => {
          this.props.getBeneficiaries();
          !this.props.isEditMode
            ? this.props.onOpenBeneificiary("")
            : this.props.onOpenBeneificiary(beneficiary.beneficiaryId);
          this.props.close(true);
        }
      );
    }
  };

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }

  validateSaveButton(beneficiary) {
    if (this.props.isEditMode) {
      var isValid = true;
      if (this.props.benefiaryType === "person") {
        const revocable = beneficiary.revocable === true ? 1 : 0;
        const dateOfBirth = beneficiary.dateOfBirth
          ? beneficiary.dateOfBirth
          : null;
        if (
          beneficiary.firstName !== this.props.beneficiary.name.split(" ")[0]
        ) {
          isValid = false;
        } else if (beneficiary.initial !== this.props.beneficiary.initial) {
          isValid = false;
        } else if (
          beneficiary.lastName !== this.props.beneficiary.name.split(" ")[1]
        ) {
          isValid = false;
        } else if (beneficiary.trustee !== this.props.beneficiary.trustee) {
          isValid = false;
        } else if (
          beneficiary.relationshipID.toString() !==
          this.props.beneficiary.relationshipID.toString()
        ) {
          isValid = false;
        } else if (revocable !== this.props.beneficiary.isRevocable) {
          isValid = false;
        } else if (
          dateOfBirth &&
          this.props.beneficiary.birthDate &&
          (beneficiary.dateOfBirth.day !==
            this.props.beneficiary.birthDate.day ||
            dateOfBirth.month !== this.props.beneficiary.birthDate.month ||
            dateOfBirth.year !== this.props.beneficiary.birthDate.year)
        ) {
          isValid = false;
        } else if (
          this.props.beneficiary.birthDate === null &&
          dateOfBirth !== null &&
          dateOfBirth !== this.props.beneficiary.birthDate
        ) {
          isValid = false;
        } else if (
          this.props.beneficiary.birthDate !== null &&
          dateOfBirth === null &&
          dateOfBirth !== this.props.beneficiary.birthDate
        ) {
          isValid = false;
        }
      } else if (this.props.benefiaryType === "charity") {
        if (beneficiary.charity !== this.props.beneficiary.name) {
          isValid = false;
        }
      } else if (this.props.benefiaryType === "estate") {
        if (beneficiary.estate !== this.props.beneficiary.name) {
          isValid = false;
        }
      }
      this.setState({ isEnabled: isValid });
    }
  }

  toggleDescription = (e, title, desc) => {
    e.preventDefault();
    this.setState({
      showDesc: !this.state.showDesc,
      description: desc,
      descTitle: title
    })
  }

  handleClose = () => {
    this.setState({ showDesc: false });
  }

  render() {
    const contents1 =
      this.props.beneficiaries &&
      this.props.beneficiaries.contentResource &&
      this.props.beneficiaries.contentResource.contents
        ? this.props.beneficiaries.contentResource.contents
        : [];
    const contents2 =
      this.props.beneficiaries &&
      this.props.beneficiaries.contentResource &&
      this.props.beneficiaries.contentResource.controlResource
        ? this.props.beneficiaries.contentResource.controlResource
            .BenefitBeneficiaries
        : [];
    const contents = _.union(contents1, contents2);
    const { formValidated, beneficiary } = this.state;
    const { locale } = this.props;
    let relationshipList = this.props.beneficiaries.relationshipList;
    if (relationshipList[0].value !== "0")
      relationshipList.unshift({
        value: "0",
        text: locale === "en-CA" || locale === "en" ? "Select" : "Sélectionner"
      });
    const lblRevocableText = getDisplayText(contents, "lblTooltipRevocable");
    const lblTooltipTrusteeTitle = getDisplayText(contents, "lblTooltipTrusteeTitle");
    const lblTooltipTrustee = getDisplayText(contents, "lblTooltipTrustee");
    const lblIrrevocableText = getDisplayText(
      contents,
      "lblTooltipIrrevocable"
    );
    const lblInstructionsAdd = getDisplayText(contents, "lblInstructionsAdd");
    const lblFirstName = getDisplayText(contents, "lblFirstName");
    const lblInitial = getDisplayText(contents, "lblInitial");
    const lblLastName = getDisplayText(contents, "lblLastName");
    const lblRelationship = getDisplayText(contents, "lblRelationship");
    const lblRevocableLabel = getDisplayText(contents, "lblRevocableLabel");
    const lblRevocable = getDisplayText(contents, "lblRevocable");
    const lblIrrevocable = getDisplayText(contents, "lblIrrevocable");
    const lblTrustee = getDisplayText(contents, "lblTrustee");
    const lblBirthDate = getDisplayText(contents, "lblBirthDate");
    const lblBeneficiaryType = getDisplayText(contents, "lblBeneficiaryType");
    const lblCharity = getDisplayText(contents, "lblCharity");
    const lblEstate = getDisplayText(contents, "lblEstate");
    const lblBeneficiaryCharityNamePlaceHolder = getDisplayText(
      contents,
      "lblBeneficiaryCharityNamePlaceHolder"
    );
    const lblBeneficiaryFirstNamePlaceHolder = getDisplayText(
      contents,
      "lblBeneficiaryFirstNamePlaceHolder"
    );
    const lblBeneficiaryInitialPlaceHolder = getDisplayText(
      contents,
      "lblBeneficiaryInitialPlaceHolder"
    );
    const lblBeneficiaryLastNamePlaceHolder = getDisplayText(
      contents,
      "lblBeneficiaryLastNamePlaceHolder"
    );
    const lblBeneficiaryTrusteePlaceHolder = getDisplayText(
      contents,
      "lblBeneficiaryTrusteePlaceHolder"
    );
    const lblDatePlaceHolder = getDisplayText(contents, "lblDatePlaceHolder");
    const rfvFirstNameErrorMessage = getDisplayText(
      contents,
      "rfvFirstNameErrorMessage"
    );
    const rfvLastNameErrorMessage = getDisplayText(
      contents,
      "rfvLastNameErrorMessage"
    );
    const rfvCharityNameErrorMessage = getDisplayText(
      contents,
      "rfvCharityNameErrorMessage"
    );
    const cmpRelationshipErrorMessage = getDisplayText(
      contents,
      "cmpRelationshipErrorMessage"
    );
    const lblInstructionsAllEdit = getDisplayText(
      contents,
      "lblInstructionsAllEdit"
    );

    const btnSave = getDisplayText(contents, "btnSave");
    const beneficiaryTypesEstate = [
      { text: this.props.beneficiaries.typeList[0], value: "person" },
      { text: this.props.beneficiaries.typeList[1], value: "charity" },
      { text: this.props.beneficiaries.typeList[2], value: "estate" }
    ];
    const beneficiaryTypes = [
      { text: this.props.beneficiaries.typeList[0], value: "person" },
      { text: this.props.beneficiaries.typeList[1], value: "charity" }
    ];
    const revocableList = [
      { text: lblRevocable.display, value: true },
      { text: lblIrrevocable.display, value: false }
    ];
    let benfitRevocable = this.state.beneficiary.revocable ? lblRevocableText : lblIrrevocableText;
    let benfitRevocableTitle = this.state.beneficiary.revocable ? lblRevocable.display : lblIrrevocable.display;
    const localestring = locale && locale.substring(0,2);

    return (
      <>
        {/* <div className={"mb-4"}>
          <Fragment>
            {!this.props.isEditMode ? (
              <div
                dangerouslySetInnerHTML={{ __html: lblInstructionsAdd.display }}
              />
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: lblInstructionsAllEdit.display
                }}
              />
            )}
          </Fragment>
        </div> */}

        <Card className={"ml-card"}>
          {/* Beneficiary status: is beneficiary a person, charity, or estate? */}
          <div className="ml-radiolist clearfix">
            {this.state.error &&
              this.state.error.length > 0 &&
              (typeof this.state.error === "string" ? (
                <p
                  className="formErrors has-error formErrorPStyle"
                  role="alert"
                  tabIndex="-1" ref="formErrorMessage"
                >
                  {this.state.error}
                </p>
              ) : (
                this.state.error.map((e, i) => (
                  <p
                    key={i}
                    className="has-error formErrors formErrorPStyle"
                    role="alert"
                    tabIndex="-1" ref="formErrorMessage"
                  >
                    {e}
                  </p>
                ))
              ))}
            <div className="ml-errors">
              <FormErrors formErrors={this.state.formErrors} />
            </div>
            {/* <label className={`mb-md-2 d-block`} aria-label={lblBeneficiaryType.textToRead}>{lblBeneficiaryType.display}</label> */}
            {!this.props.isEditMode ? (
              <NewMultiRadioComponent
                component="newBeneficiary"
                display={lblBeneficiaryType.display}
                name="beneficiaryType"
                selected={
                  this.state.beneficiary.beneficiaryType
                    ? this.state.beneficiary.beneficiaryType.toString()
                    : 0
                }
                type="radio"
                options={
                  this.props.beneficiaries.canAddEstate
                    ? beneficiaryTypesEstate
                    : beneficiaryTypes
                }
                keyy={`${this.props.keyy}-types`}
                onChange={this.changeInput}
              />
            ) : (
              ""
            )}
          </div>
          {/* Beneficiary status: is beneficiary a person, charity, or estate? */}
          {this.state.beneficiary.beneficiaryType === "charity" ? (
            <div>
              <Row className="mt-4">
                <div
                  className={`ml-inputfields ml-charity form-group ${this.errorClass(
                    this.state.formErrors.charity
                  )}`}
                >
                  <Form noValidate validated={this.state.formValidated}>
                    <label
                      htmlFor={getControlId(
                        lblBeneficiaryCharityNamePlaceHolder.textToRead
                      )}
                    >
                      <span className="ml-mandatory-sign">*</span>{" "}
                      {lblCharity.display}
                    </label>
                    <Form.Control
                      id={getControlId(
                        lblBeneficiaryCharityNamePlaceHolder.textToRead
                      )}
                      type="text"
                      value={this.state.beneficiary.charity}
                      required
                      placeholder={lblBeneficiaryCharityNamePlaceHolder.display}
                      name="charity"
                      onChange={this.setValue}
                      onKeyPress={e => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                    />
                    <Form.Control.Feedback
                      className="mt-0"
                      role="alert"
                      type="invalid"
                    >
                      {rfvCharityNameErrorMessage.display}
                    </Form.Control.Feedback>
                  </Form>
                </div>
              </Row>
            </div>
          ) : this.state.beneficiary.beneficiaryType === "estate" ? (
            <div>
              <Row className="mt-4">
                <div
                  className={`ml-inputfields ml-estate form-group ${this.errorClass(
                    this.state.formErrors.estate
                  )}`}
                >
                  <label htmlFor={getControlId(lblEstate.textToRead)}>
                    {lblEstate.display}
                  </label>
                  <Form.Control
                    id={getControlId(lblEstate.textToRead)}
                    type="text"
                    name="estate"
                    placeholder={lblEstate.display}
                    value={this.state.beneficiary.estate}
                    onChange={this.setValue}
                    onKeyPress={e => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  />
                </div>
              </Row>
            </div>
          ) : (
            <>
              {/* Name input fields */}
              <Row className="mt-4">
                <div
                  className={`ml-inputfields ml-firstname form-group ${this.errorClass(
                    this.state.formErrors.firstName
                  )}`}
                >
                  <Form noValidate validated={this.state.formValidated}>
                    <label
                      htmlFor={getControlId(
                        lblBeneficiaryFirstNamePlaceHolder.textToRead
                      )}
                    >
                      <span className="ml-mandatory-sign">*</span>{" "}
                      {lblFirstName.display}
                    </label>
                    <Form.Control
                      maxLength={50}
                      type="text"
                      id={getControlId(
                        lblBeneficiaryFirstNamePlaceHolder.textToRead
                      )}
                      value={this.state.beneficiary.firstName}
                      ref="firstName"
                      required
                      placeholder={lblBeneficiaryFirstNamePlaceHolder.display}
                      name="firstName"
                      onChange={this.setValue}
                      onKeyPress={e => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                    />
                    <Form.Control.Feedback
                      className="mt-0"
                      type="invalid"
                      role="alert"
                    >
                      {rfvFirstNameErrorMessage.display}
                    </Form.Control.Feedback>
                  </Form>
                </div>
                <div
                  className={`ml-inputfields ml-initial form-group ${this.errorClass(
                    this.state.formErrors.initial
                  )}`}
                >
                  <label
                    htmlFor={getControlId(
                      lblBeneficiaryInitialPlaceHolder.textToRead
                    )}
                  >
                    {lblInitial.textToRead}
                  </label>
                  <Form.Control
                    maxLength={1}
                    type="text"
                    id={getControlId(
                      lblBeneficiaryInitialPlaceHolder.textToRead
                    )}
                    value={this.state.beneficiary.initial}
                    placeholder={lblBeneficiaryInitialPlaceHolder.display}
                    name="initial"
                    onChange={this.setValue}
                    onKeyPress={e => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  />
                </div>
                <div
                  className={`ml-inputfields ml-lastname form-group ${this.errorClass(
                    this.state.formErrors.lastName
                  )}`}
                >
                  <Form noValidate validated={this.state.formValidated}>
                    <label
                      htmlFor={getControlId(
                        lblBeneficiaryLastNamePlaceHolder.textToRead
                      )}
                    >
                      <span className="ml-mandatory-sign">*</span>{" "}
                      {lblLastName.textToRead}
                    </label>
                    <Form.Control
                      maxLength={50}
                      type="text"
                      id={getControlId(
                        lblBeneficiaryLastNamePlaceHolder.textToRead
                      )}
                      value={this.state.beneficiary.lastName}
                      ref="lastName"
                      required
                      placeholder={lblBeneficiaryLastNamePlaceHolder.display}
                      name="lastName"
                      onChange={this.setValue}
                      onKeyPress={e => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                    />
                    <Form.Control.Feedback
                      className="mt-0"
                      role="alert"
                      type="invalid"
                    >
                      {rfvLastNameErrorMessage.display}
                    </Form.Control.Feedback>
                  </Form>
                </div>
              </Row>
              {/* Relationship Dropdown */}
              <Row className="justify-content-between">
                <div className="ml-inputfields ml-relationship form-group">
                  <div className="peronal-info-details">
                    <ViewEditText
                      type="select"
                      ref="relationship"
                      aria-label={lblRelationship.textToRead}
                      display={`<span className="ml-mandatory-sign">*</span> ${lblRelationship.display}`}
                      mode="edit"
                      name="relationshipID"
                      text={lblRelationship.display}
                      value={
                        this.state.beneficiary.relationshipID === null
                          ? 0
                          : this.state.beneficiary.relationshipID
                      }
                      options={relationshipList}
                      onChange={this.changeRelationship}
                      errormessage={this.state.errorMessage.relationshipID}
                    />
                    <div className="clearfix"></div>
                    {formValidated &&
                      (beneficiary.relationshipID === null ||
                        beneficiary.relationshipID === "0") && (
                        <div
                          className="invalid-feedback d-block mt-0"
                          role="alert"
                        >
                          {cmpRelationshipErrorMessage.display}
                        </div>
                      )}
                  </div>
                </div>
                {/* Date of Birth Selection */}
                <div className="ml-inputfields ml-dateofbirth form-group">
                  <div className={`position-relative`}>
                    <DateComponent
                      name="dateOfBirth"
                      type="text"
                      display={lblBirthDate.display}
                      aria-label={lblBirthDate.textToRead}
                      mode="edit"
                      date={
                        this.state.beneficiary.dateOfBirth === null &&
                        this.state.beneficiary.dateOfBirth.day === null
                          ? null
                          : this.state.beneficiary.dateOfBirth
                      }
                      placeholder={lblDatePlaceHolder.display}
                      // onChange={this.changeDate}
                      onChange={this.handleDateChange}
                      localestring={localestring}
                      isreadonly="true"
                    />
                  </div>
                </div>
              </Row>
              <Fragment>
                {!this.props.isEditMode ? (
                  <div
                    className="alert alert-secondary"
                    role="alert"
                    dangerouslySetInnerHTML={{
                      __html: lblInstructionsAdd.display
                    }}
                  />
                ) : (
                  <div
                    className="alert alert-secondary"
                    role="alert"
                    dangerouslySetInnerHTML={{
                      __html: lblInstructionsAllEdit.display
                    }}
                  />
                )}
              </Fragment>
              {/* Trustee */}
              <Row className="justify-content-between">
                <div
                  className={`ml-inputfields ml-trustee form-group ${this.errorClass(
                    this.state.formErrors.trustee
                  )}`}
                >
                  <label
                    htmlFor={getControlId(
                      lblBeneficiaryTrusteePlaceHolder.textToRead
                    )}
                  >
                    {lblTrustee.textToRead}
                    {<span role="button" tabIndex="0" className="tooltip-icon icon-action pl-2 tool" aria-label={"Sample Trustee Texttoread"} onClick={(e) => this.toggleDescription(e, lblTooltipTrusteeTitle.textToRead, lblTooltipTrustee.textToRead)}><CustomToolTipIcon /></span>}
                  </label>
                  <Form.Control
                    id={getControlId(
                      lblBeneficiaryTrusteePlaceHolder.textToRead
                    )}
                    maxLength={50}
                    type="text"
                    value={this.state.beneficiary.trustee ?  this.state.beneficiary.trustee : ""}
                    placeholder={lblBeneficiaryTrusteePlaceHolder.display}
                    name="trustee"
                    onChange={this.setValue}
                    onKeyPress={e => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  />
                </div>
                {/* Shows an Alert if the Beneficiary's birthDate is chosen in the future. */}
              </Row>
              {/* is it Revocable (1) or Irrevocable (0)? */}
              {this.props.beneficiaries.showIsRevocable === true ? (
                <div>
                  {/* <span className={`mb-2 d-block`}>{lblBeneficiaryType.display}</span> */}
                  <div className="ml-radiolist clearfix">
                    <NewMultiRadioComponent
                      component="newBeneficiary"
                      name="revocable"
                      display={lblRevocableLabel.display}
                      //selected = {this.state.beneficiary.revocable ? this.state.beneficiary.revocable : -1}
                      selected={
                        this.state.beneficiary.revocable.toString() === "true"
                          ? true
                          : false
                      }
                      istooltip={benfitRevocable.display !== "" ? 1 : 0}
                      tooltiparialabel={benfitRevocable.textToRead}
                      tooltiponclick={(e) => this.toggleDescription(e, benfitRevocableTitle, benfitRevocable.display)}
                      type="radio"
                      options={revocableList}
                      keyy={`${this.props.keyy}-revocability`}
                      onChange={this.changeInputNumber}
                    />
                  </div>
                  {/* <Fragment>
                    <div
                      className={`btextbox`}
                      dangerouslySetInnerHTML={{
                        __html: this.state.beneficiary.revocable
                          ? lblRevocableText.display
                          : lblIrrevocableText.display
                      }}
                    ></div>
                  </Fragment> */}
                </div>
              ) : (
                ""
              )}
            </>
          )}
        </Card>
        <Modal onOpened={() => setModalAttribute(this.state.descTitle ? this.state.descTitle.textToRead : "infopopup")} className="infopopup" isOpen={this.state.showDesc} toggle={this.handleClose} aria-labelledby="modalHeading" centered>
          <div className="modal-header">
              <h1 className="modal-title" id="modalHeading">
              {this.state.descTitle ? this.state.descTitle : '\u00A0'}
              </h1>
              <DisplayButton type="button"  className="close" displayName="×" arialabel={this.props.closeButtonText.display} onClick={this.handleClose}/> 
            </div>
          <ModalBody>
            <Fragment><div dangerouslySetInnerHTML={{__html: this.state.description}}/></Fragment>
          </ModalBody>
        </Modal>
        {/* Saves appears only if form is Valid (all the */}
        <Row className="ml-popupbuttons m-0 pt-4 pb-md-3 pt-md-2 justify-content-between">
          <DisplayButton
            controlId="btnCloseModal"
            displayName={this.props.lblCancel.display}
            className="outline-secondary btn-cancel"
            onClick={this.props.close}
          />
          <DisplayButton
            displayName={btnSave.display}
            className="outline-secondary btn-save"
            onClick={this.createBeneficiary}
            disabled={this.state.isEnabled}
          />
        </Row>
      </>
    );
  }
}

export default NewBeneficiariesForm;
