import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Row, Card, Col, Form, Table, Container } from "react-bootstrap";
import { Modal, ModalBody } from "reactstrap";
import { withRouter } from "react-router-dom";
import { showError, dissmiss } from "../actions/contentActions";
import { getFlex, updateFlex, flexReallocate, enableNext, disableNext, getContentHelp, setFlexBeneficiaryID } from "../actions/userActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { getDisplayText, CustomToolTipIcon, setModalAttribute } from "../utils";
import { trackPromise } from "react-promise-tracker";
import DisplayButton from "./button";
import _ from "underscore";

class FlexDollars extends Component {
  constructor(props) {
    super(props);
    let flexBenefitList = [];
    let flexGroupID = -1;
    let beneficiaryID = -1;
    let showBeneficiarie = false;

    if (this.props.flexDollars) {
      _.each(this.props.flexDollars.benefitFlexes, (benefit) => {
        flexBenefitList[benefit.benefitID] = benefit.flexAmount ? benefit.flexAmount : 0;
        if (benefit.needBeneficiary) {
          beneficiaryID = benefit.benefitID;
          showBeneficiarie = true;
        }
      });
      this.props.flexDollars.canProceedToNextStep ? this.props.enableNext() : this.props.disableNext();
    }
    if (props.location.search) {
      let search = new URLSearchParams(props.location.search);
      flexGroupID = parseInt(search.get("GroupID")) ? parseInt(search.get("GroupID")) : 0;
    }
    this.state = {
      flexBenefitList,
      flexGroupID,
      showHelpContent: false,
      showTooltip: false,
      toolTipMessage: "",
      showBeneficiarie,
      beneficiaryID,
      inputValue: -1,
      flag: false,
      allowCreditsToReallocate: false,
      reallocateFlexInfo: {}
    };
    // console.log('Flex Group ID: ', flexGroupID);

    this.handleFocusInput = this.handleFocusInput.bind(this);
    this.handleBlurInput = this.handleBlurInput.bind(this);
  }

  componentDidMount() {
    trackPromise(
      this.props.getFlex(this.state.flexGroupID, true, (msg) => {
        if (msg) {
          this.props.showError(msg);
        }
      })
    );
    const { allowCreditsToReallocate, reallocateFlexInfo } = this.props.flexDollars;
    this.setState({ allowCreditsToReallocate, reallocateFlexInfo });
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.location.search) {
      let search = new URLSearchParams(this.props.location.search);
      let flexGroupID = parseInt(search.get("GroupID")) ? parseInt(search.get("GroupID")) : 0;
      if (this.state.flexGroupID !== flexGroupID) {
        this.setState({ flexGroupID }, () => {
          trackPromise(
            this.props.getFlex(this.state.flexGroupID, true, (msg) => {
              if (msg) {
                this.props.showError(msg);
              }
            })
          );
        });
      }
    }
    const { allowCreditsToReallocate, reallocateFlexInfo } = this.props.flexDollars;
    if (prevProps.flexDollars.allowCreditsToReallocate !== allowCreditsToReallocate) {
      this.setState({ allowCreditsToReallocate, reallocateFlexInfo });
    }
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.flexDollars.benefitFlexes !== this.state.flexBenefitList) {
      let flexBenefitList = [];
      let beneficiaryID = this.state.beneficiaryID;
      let showBeneficiarie = this.state.showBeneficiarie;
      let didUpdateShowBeneficiarie = false;

      _.each(nextProps.flexDollars.benefitFlexes, (benefit) => {
        flexBenefitList[benefit.benefitID] = benefit.flexAmount ? benefit.flexAmount : 0;
        if (benefit.needBeneficiary) {
          beneficiaryID = benefit.benefitID;
          showBeneficiarie = true;
          didUpdateShowBeneficiarie = true;
        }
      });
      if (!didUpdateShowBeneficiarie && showBeneficiarie) showBeneficiarie = false;
      nextProps.flexDollars.canProceedToNextStep ? this.props.enableNext() : this.props.disableNext();
      this.setState({ flexBenefitList, beneficiaryID, showBeneficiarie }, () => {
        if (document.getElementById("errorMsg") !== null && this.state.flag) document.getElementById("errorMsg").focus();
      });
    }
  }

  componentWillUnmount() {
    this.props.dissmiss();
    this.props.enableNext();
  }

  getFormattedValue = (value) => {
    const precision = this.props.flexDollars.precision || "2";
    const localestring = this.props.locale.substring(0, 2);
    let formattedValue = 0;
    if (precision === "2") {
      if (localestring === "en") {
        formattedValue = value
          .replace(/[^\d.]/g, "")
          .replace(/(^(0|1-9))[\d]/g, "$1")
          .replace(/(\..*)\./g, "$1")
          .replace(/(\.[\d]{2})./g, "$1");
      } else {
        formattedValue = value
          .replace(/[^\d,]/g, "")
          .replace(/(^(0|1-9))[\d]/g, "$1")
          // eslint-disable-next-line
          .replace(/(\,.*)\,/g, "$1")
          // eslint-disable-next-line
          .replace(/(\,[\d]{2})./g, "$1");
      }
    } else {
      formattedValue = value.replace(/[^\d]/g, "").replace(/(^(0|1-9))[\d]/g, "$1");
    }

    return formattedValue;
  };

  handleFlexValueUpdate = (event) => {
    let flexBenefitList = this.state.flexBenefitList;

    const requestBody = {
      flexGroupID: this.state.flexGroupID,
      flexItem: {
        flexBenefitID: event.target.id,
        flexAmount: flexBenefitList[event.target.id] ? flexBenefitList[event.target.id] : "0"
      }
    };
    trackPromise(this.props.updateFlex(requestBody));
  };

  onInputChnage = (event) => {
    const value = event.target.value;
    const validatedValue = this.getFormattedValue(value);

    let flexBenefitList = this.state.flexBenefitList;
    flexBenefitList[event.target.id] = validatedValue;
    this.setState({ flexBenefitList });
    this.setState({ flag: true });
  };

  handleKeyPress = (event) => {
    if (event.key === "Enter" || event.charCode === 13) {
      this.handleFlexValueUpdate(event);
    }
  };

  handleShowToolTip = (msg) => {
    this.setState({ showTooltip: true, toolTipMessage: msg });
  };

  handleShowHelp = (lnkHelp) => {
    if (lnkHelp) {
      this.props.getContentHelp(lnkHelp);
      this.setState({ showHelpContent: true });
    }
  };

  handleClose = () => {
    this.setState({ showTooltip: false, showHelpContent: false });
  };

  handleKeyDown = (event, functionType, parameter) => {
    switch (event.which) {
      case 13: {
        if (functionType === 0) {
          this.handleShowToolTip(parameter);
        } else {
          this.handleShowHelp(parameter);
        }
        break;
      }
      default:
        break;
    }
  };

  withPadding = (benefit) => {
    if (benefit.tax != null || benefit.deduction != null || benefit.isEarnedFlexAmountVisible || benefit.isFlexBenefitAdditionalDetailsVisible || benefit.cantContributeVisible) {
      return false;
    }

    return true;
  };

  handleEditAction = (benefitID) => {
    this.props.setFlexBeneficiaryID(benefitID, () => {
      this.props.history.push("/Beneficiaries");
    });
  };

  handleFocusInput = (event) => {
    const inputID = event.target.id;
    const inputValue = this.state.flexBenefitList[inputID];
    this.setState({ inputValue });
  };

  handleBlurInput = (event) => {
    if (this.state.inputValue !== this.state.flexBenefitList[event.target.id]) this.handleFlexValueUpdate(event);
  };

  handleChange = () => {
    this.setState({ allowCreditsToReallocate: !this.state.allowCreditsToReallocate });
    console.log("reallocateFlexInfo: ", this.state.reallocateFlexInfo);
    trackPromise(this.props.flexReallocate({ allowCreditsToReallocate: !this.state.allowCreditsToReallocate }));
  };

  render() {
    const flexDollars = this.props.flexDollars ? this.props.flexDollars : {};
    const contents = flexDollars.contentResource ? flexDollars.contentResource.contents : {};

    const pageTitle = flexDollars.pageTitle;
    const flexAssignmentMessage = flexDollars.flexAssignmentMessage;
    const labelFlexDollarsRemaining = flexDollars.labelFlexDollarsRemaining;
    const flexDollarsRemaining = flexDollars.flexDollarsRemaining;
    const labelErrorFlexRemaining = flexDollars.labelErrorFlexRemaining;
    const labelErrorFlexOverLimit = flexDollars.labelErrorFlexOverLimit;
    const proceedToNextStepErrorMessage = flexDollars.proceedToNextStepErrorMessage;

    const lblViewBeneficiaries = getDisplayText(contents, "lblViewBeneficiaries");
    const toolTipIcon = getDisplayText((this.props.contentFooter.contentResource && this.props.contentFooter.contentResource.contents) || {}, "ToolTipIcon");
    const questionMarkIcon = getDisplayText((this.props.contentFooter.contentResource && this.props.contentFooter.contentResource.contents) || {}, "QuestionMarkIcon");
    const closeButtonText = getDisplayText((this.props.contentFooter.contentResource && this.props.contentFooter.contentResource.contents) || {}, "btnClose");
    const flexInputWatermark = getDisplayText(contents, "FlexInputWatermark");
    const commonFlexMessage = getDisplayText(contents, "commonFlexMessage");
    const isCommonFlexMessage = _.findIndex(contents, { keyName: "commonFlexMessage" }) === -1 ? false : true;
    const flexBenefitRows = [];
    _.each(flexDollars.benefitFlexes, (flexBenefit) => {
      flexBenefitRows.push(
        <div key={`div-${flexBenefit.benefitID}`} className={`py-3 py-md-4 border-top ${this.withPadding(flexBenefit) ? "customcol" : "customcol-no-padding"}`}>
          <Row className={`align-items-center`}>
            <div className="col-8 col-sm-9">
              {flexBenefit.name}
              <span className={`infoicon pl-2`}>
                {flexBenefit.flexMessage && (
                  <span
                    className="tooltip-icon icon-action"
                    role="button"
                    tabIndex="0"
                    aria-label={toolTipIcon.textToRead}
                    onKeyDown={(event) => this.handleKeyDown(event, 0, flexBenefit.flexMessage)}
                    onClick={() => {
                      this.handleShowToolTip(flexBenefit.flexMessage);
                    }}>
                    <CustomToolTipIcon />
                  </span>
                )}
                {flexBenefit.isHelpVisible && (
                  <span
                    tid="helpBtn"
                    className="tooltip-icon icon-action"
                    role="button"
                    tabIndex="0"
                    aria-label={questionMarkIcon.textToRead}
                    onKeyDown={(event) => this.handleShowHelp(event, 1, flexBenefit.helpLink)}
                    onClick={() => {
                      this.handleShowHelp(flexBenefit.helpLink);
                    }}>
                    <FontAwesomeIcon icon={faQuestionCircle} />
                  </span>
                )}
              </span>
            </div>
            <div className="col-4 col-sm-3">
              <Form.Control
                name="benefitAmount"
                id={flexBenefit.benefitID}
                className="login-input-field edit-input-field"
                value={this.state.flexBenefitList[flexBenefit.benefitID] ? this.state.flexBenefitList[flexBenefit.benefitID] : ""}
                onChange={this.onInputChnage}
                onKeyPress={this.handleKeyPress}
                disabled={flexBenefit.flexAmountReadOnly}
                aria-label={flexBenefit.name}
                aria-disabled={flexBenefit.flexAmountReadOnly}
                placeholder={flexInputWatermark.display}
                onFocus={this.handleFocusInput}
                onBlur={this.handleBlurInput}
              />
              {/* <Form.Control
                name="benefitAmount"
                id={flexBenefit.benefitID}
                className="login-input-field edit-input-field"
                type="number"
                aria-label={flexInputWatermark.textToRead}
                value={this.state.flexBenefitList[flexBenefit.benefitID] ? this.state.flexBenefitList[flexBenefit.benefitID] : ""}
                onChange={this.onInputChnage}
                onKeyPress={this.handleKeyPress}
                disabled={flexBenefit.flexAmountReadOnly}
                aria-disabled={flexBenefit.flexAmountReadOnly}/> */}
            </div>
          </Row>
          {flexBenefit.flexBenefitErrorMessage !== null && (
            <>
              <span
                className={`ml-error float-none mb-0 pt-3 d-block`}
                role="alert"
                dangerouslySetInnerHTML={{
                  __html: flexBenefit.flexBenefitErrorMessage
                }}
              />
            </>
          )}
          {flexBenefit.minimumAllocationNotValidMessage !== null && (
            <>
              <span
                tid="errMinAllocation"
                className={`ml-error float-none mb-0 pt-3 d-block`}
                role="alert"
                dangerouslySetInnerHTML={{
                  __html: flexBenefit.minimumAllocationNotValidMessage
                }}
              />
            </>
          )}
          {flexBenefit.maximumAllocationNotValidMessage !== null && (
            <>
              <span
                className={`ml-error float-none mb-0 pt-3 d-block`}
                role="alert"
                dangerouslySetInnerHTML={{
                  __html: flexBenefit.maximumAllocationNotValidMessage
                }}
              />
            </>
          )}
          {flexBenefit.decreaseNotAllowedMessage !== null && (
            <>
              <span
                className={`ml-error float-none mb-0 pt-3 d-block`}
                role="alert"
                dangerouslySetInnerHTML={{
                  __html: flexBenefit.decreaseNotAllowedMessage
                }}
              />
            </>
          )}
          {flexBenefit.increaseNotAllowedMessage !== null && (
            <>
              <span
                className={`ml-error float-none mb-0 pt-3 d-block`}
                role="alert"
                dangerouslySetInnerHTML={{
                  __html: flexBenefit.increaseNotAllowedMessage
                }}
              />
            </>
          )}
          {flexBenefit.unitsValidatorErrorMessage !== null && (
            <>
              <span
                className={`ml-error float-none mb-0 pt-3 d-block`}
                role="alert"
                dangerouslySetInnerHTML={{
                  __html: flexBenefit.unitsValidatorErrorMessage
                }}
              />
            </>
          )}
          {!this.withPadding(flexBenefit) && (
            <div className="mt-3 mt-md-4">
              {flexBenefit.tax != null && (
                <div className={`child-flex pb-3 pb-md-4 pl-md-5 border-top`}>
                  <Row className={`justify-content-between pt-4 m-0`}>
                    <Col className="col-8 col-sm-9 p-0">
                      <p
                        className={`m-0`}
                        dangerouslySetInnerHTML={{
                          __html: flexBenefit.tax.flexBenefitTaxNote
                        }}
                      />
                    </Col>
                    <Col className="col-4 col-sm-3 p-md-0 text-right">
                      <strong>{flexBenefit.tax.taxes}</strong>
                    </Col>
                  </Row>
                </div>
              )}
              {flexBenefit.deduction != null && (
                <div className={`child-flex pb-3 pb-md-4 pl-md-5 border-top`}>
                  <Row className={`py-3 py-md-4 m-md-0`}>
                    <Col className="col-12 pl-md-0">
                      <p
                        className={`m-0`}
                        dangerouslySetInnerHTML={{
                          __html: flexBenefit.deduction.flexDeductionTitle
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className={`justify-content-between py-3 py-md-4 border-top m-0`}>
                    <Col className="col-8 col-sm-9 p-0">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: flexBenefit.deduction.labelAnnualDeduction
                        }}></div>
                    </Col>
                    <Col className="col-4 col-sm-3 text-right">
                      <strong>{flexBenefit.deduction.annualDeduction}</strong>
                    </Col>
                  </Row>
                  <Row className={`justify-content-between py-3 py-md-4 border-top m-0`}>
                    <Col className="col-8 col-sm-9 p-0">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: flexBenefit.deduction.labelPerPayDeduction
                        }}></div>
                    </Col>
                    <Col className="col-4 col-sm-3 text-right">
                      <strong>{flexBenefit.deduction.perPayDeduction}</strong>
                    </Col>
                  </Row>
                  <Row className={`justify-content-between pt-3 pt-md-4 border-top m-0`}>
                    <Col className="col-8 col-sm-9 p-0">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: flexBenefit.deduction.labelBalance
                        }}></div>
                    </Col>
                    <Col className="col-4 col-sm-3 text-right">
                      <strong>{flexBenefit.deduction.accountBalance}</strong>
                    </Col>
                  </Row>
                </div>
              )}
              {flexBenefit.isEarnedFlexAmountVisible && (
                <div className={`child-flex pb-3 pb-md-4 pl-md-5 border-top`}>
                  <Row className={`justify-content-between pt-4 m-0`}>
                    <Col className="col-2 offset-10 text-right p-0">
                      <strong>{flexBenefit.earnedFlexAmount}</strong>
                    </Col>
                  </Row>
                </div>
              )}
              {flexBenefit.isFlexBenefitAdditionalDetailsVisible && (
                <div className="child-flex pb-3 pb-md-4 pl-md-5 border-top">
                  <Row className={`justify-content-between pt-4 m-0`}>
                    <Col className="col-12 p-0">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: flexBenefit.flexBenefitAdditionalDetailsMessaging
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              )}
              {flexBenefit.cantContributeVisible && (
                <div className="child-flex pb-3 pb-md-4 pl-md-5 border-top">
                  <Row className={`justify-content-between pt-4 m-0`}>
                    <Col className="col-12 p-0">{flexBenefit.cantContributeText}</Col>
                  </Row>
                </div>
              )}
            </div>
          )}

          {flexBenefit.needBeneficiary && (
            <div className="mt-md-2 mb-md-2 d-flex justify-content-end">
              <DisplayButton className="outline-secondary" displayName={lblViewBeneficiaries.display} onClick={() => this.handleEditAction(flexBenefit.benefitID)} />
            </div>
          )}
        </div>
      );
    });
    /* Flex re-allocation */
    const confirmToReallocate = flexDollars.confirmToReallocate;
    const reallocateFlexHeaders = flexDollars.reallocateFlexInfo ? flexDollars.reallocateFlexInfo.header : {};
    const payrollDeductionBenefits = flexDollars.reallocateFlexInfo ? flexDollars.reallocateFlexInfo.payrollDeductionBenefits : {};
    const reallocateFlexTotal = flexDollars.reallocateFlexInfo ? flexDollars.reallocateFlexInfo.total : {};
    const title = flexDollars.reallocateFlexInfo?.title;
    const payrollDeductionMessage = flexDollars.reallocateFlexInfo?.payrollDeductionMessage;

    const flexColumnCount = ((reallocateFlexHeaders.benefitName && 1) || 0) + ((reallocateFlexHeaders.before && 1) || 0) + ((reallocateFlexHeaders.after && 1) || 0);

    const reallocateFlexRows = [];
    /*  _.each(payrollDeductionBenefits, (reallocateFlex, index) => {
      reallocateFlexRows.push(
        <tr key={`d-div-onFileBeneficiary-${index}`}>
          <td>{reallocateFlex.benefitName}</td>
          <td>{reallocateFlex.before}</td>
          <td>{reallocateFlex.after}</td>
        </tr>
      );
    }); */
    _.each(payrollDeductionBenefits, (reallocateFlex, index) => {
      reallocateFlexRows.push(
        <Row className="singleitem justify-content-md-center" key={`div-flexRow-${reallocateFlex.benefitName}-${index}-ID`}>
          {reallocateFlex.benefitName && (
            <Col xs lg="3" data-title={reallocateFlex.benefitName}>
              <span className="visuallyhidden first">{reallocateFlexHeaders.benefitName}</span>
              <span>{reallocateFlex.benefitName || "\u00A0"}</span>
            </Col>
          )}
          {reallocateFlex.before && (
            <Col xs lg="2" data-title={reallocateFlex.before} style={{ disply: "flex", justifyContent: "right" }}>
              <span className="visuallyhidden">{reallocateFlexHeaders.before}</span>
              <span className="float-right">{reallocateFlex.before || "\u00A0"}</span>
            </Col>
          )}
          {reallocateFlex.after && (
            <Col xs lg="2" data-title={reallocateFlex.after} style={{ disply: "flex", justifyContent: "right" }}>
              <span className="visuallyhidden">{reallocateFlexHeaders.after}</span>
              <span className="float-right">{reallocateFlex.after || "\u00A0"}</span>
            </Col>
          )}
        </Row>
      );
    });
    /* Flex re-allocation */

    return (
      <div className="flexdollars">
        <div className="ml-titledescription pb-3">
          <h2 className={`m-0`}>{pageTitle}</h2>
        </div>
        <div className="ml-description pb-3">
          <Fragment>
            <p className={`mb-3`} dangerouslySetInnerHTML={{ __html: flexAssignmentMessage }} />
          </Fragment>
          {labelErrorFlexRemaining && (
            <div className={`alert alert-info`}>
              <Fragment>
                <p className={`m-0`} role="alert" dangerouslySetInnerHTML={{ __html: labelErrorFlexRemaining }} />
              </Fragment>
            </div>
          )}
          {labelErrorFlexOverLimit && (
            <div className={`alert alert-danger`}>
              <Fragment>
                <p className={`m-0`} role="alert" dangerouslySetInnerHTML={{ __html: labelErrorFlexOverLimit }} />
              </Fragment>
            </div>
          )}
        </div>
        {confirmToReallocate && (
          <div className="clearfix form-check confirmationcheck mb-3 mb-md-4">
            <input type="checkbox" key="confirmSelection" className="" id="confirmSelection" checked={this.state.allowCreditsToReallocate ?? false} onChange={this.handleChange} />
            <label className="form-check-label warning" htmlFor="confirmSelection">
              {confirmToReallocate}
            </label>
          </div>
        )}
        <Card className={`flexdollarscard`}>
          <Card.Header>
            <Row>
              <Col className="col-xs-9">
                <h2>{labelFlexDollarsRemaining}</h2>
              </Col>
              <Col className="col-xs-3 text-right">
                <h2>{flexDollarsRemaining}</h2>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>{flexBenefitRows}</Card.Body>
          {proceedToNextStepErrorMessage && (
            <Card.Footer>
              {proceedToNextStepErrorMessage !== null && (
                <Fragment>
                  <p
                    role="alert"
                    id="errorMsg"
                    tabIndex="-1"
                    className={`m-0 alert alert-danger`}
                    dangerouslySetInnerHTML={{
                      __html: proceedToNextStepErrorMessage
                    }}
                  />
                </Fragment>
              )}
            </Card.Footer>
          )}
        </Card>
        {/* <Table className="responsive table-borderless">
          <thead>
            <tr>
              <th scope="col">{reallocateFlexHeaders.benefitName}</th>
              <th scope="col">{reallocateFlexHeaders.before}</th>
              <th scope="col">{reallocateFlexHeaders.after}</th>
            </tr>
          </thead>
          <tbody>{reallocateFlexRows}</tbody>
          <tfoot style={{ borderTop: '1px solid #00000020' }}>
            <tr>
              <td>{reallocateFlexTotal.benefitName}</td>
              <td className="text-right font-weight-bold">{reallocateFlexTotal.before}</td>
              <td className="text-right font-weight-bold">{reallocateFlexTotal.after}</td>
            </tr>
          </tfoot>
        </Table> */}
        {this.state.allowCreditsToReallocate && flexDollars.reallocateFlexInfo && (
          <Card className="flexdollarscard">
            <Card.Header>
              <Row>
                <Col className="col-xs-9">
                  <h2>{title}</h2>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <p className={`mb-3`} dangerouslySetInnerHTML={{ __html: payrollDeductionMessage }} />

              <Row className="justify-content-md-center font-weight-bold">
                <Col xs lg="3" data-title={reallocateFlexHeaders.benefitName}>
                  <span>{reallocateFlexHeaders.benefitName || "\u00A0"}</span>
                </Col>
                <Col xs lg="2" data-title={reallocateFlexHeaders.before}>
                  <span className="float-right">{reallocateFlexHeaders.before || "\u00A0"}</span>
                </Col>
                <Col xs lg="2" data-title={reallocateFlexHeaders.after}>
                  <span className="float-right">{reallocateFlexHeaders.after || "\u00A0"}</span>
                </Col>
              </Row>
              {reallocateFlexRows}
            </Card.Body>
            <Card.Footer>
              <Row className="justify-content-md-center">
                {reallocateFlexTotal.benefitName && (
                  <Col xs lg="3" className="col-xs-9">
                    <span className="visuallyhidden first">{reallocateFlexHeaders.benefitName}</span>
                    <span>{reallocateFlexTotal.benefitName}</span>
                  </Col>
                )}
                {reallocateFlexTotal.before && (
                  <Col xs lg="2" className="col-xs-9">
                    <span className="visuallyhidden">{reallocateFlexHeaders.before}</span>
                    <span className="font-weight-bold float-right">{reallocateFlexTotal.before}</span>
                  </Col>
                )}
                {reallocateFlexTotal.after && (
                  <Col xs lg="2" className="col-xs-9">
                    <span className="visuallyhidden">{reallocateFlexHeaders.after}</span>
                    <span className="font-weight-bold float-right">{reallocateFlexTotal.after}</span>
                  </Col>
                )}
              </Row>
            </Card.Footer>
          </Card>
        )}

        {/* { this.state.showBeneficiarie && <div className="ml-popupbuttons mt-md-2 mb-md-5 d-flex justify-content-end">
          <Button variant="outline-secondary" title={lblViewBeneficiaries.textToRead} aria-label={lblViewBeneficiaries.textToRead} onClick={() => {this.props.history.push("/Beneficiaries")}}>
            {lblViewBeneficiaries.display}
          </Button>
        </div> } */}
        {commonFlexMessage && commonFlexMessage.display && isCommonFlexMessage && (
          <div className={`alert alert-warning`}>
            <span className={`m-0`} role="alert" dangerouslySetInnerHTML={{ __html: commonFlexMessage.display }} />
          </div>
        )}
        <Modal
          onOpened={() => setModalAttribute("modalHeadingFlex")}
          aria-labelledby="modalHeading"
          isOpen={this.state.showHelpContent || this.state.showTooltip}
          toggle={this.handleClose}
          className="ml-addnewdependent"
          centered>
          <div className="modal-header">
            <div id="modalHeading">&nbsp;</div>
            <DisplayButton type="button" className="close" arialabel={closeButtonText.display} displayName="×" onClick={this.handleClose} />
          </div>
          <ModalBody>
            {this.state.showHelpContent && <div tid="helpContent" dangerouslySetInnerHTML={{ __html: this.props.helpContent }} />}
            {this.state.showTooltip && <div tid="toolTip" className="mb-4" dangerouslySetInnerHTML={{ __html: this.state.toolTipMessage }} />}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    flexDollars: state.userReducer.flexDollars ? state.userReducer.flexDollars : {},
    helpContent: state.userReducer.helpContentResponse ? state.userReducer.helpContentResponse.result.content : "",
    contentFooter: state.userReducer.contentFooter,
    locale: state.contentReducer.locale,
    sideBarMenu: state.contentReducer.screens
  };
};

export default connect(mapStateToProps, {
  getFlex,
  updateFlex,
  enableNext,
  disableNext,
  getContentHelp,
  showError,
  dissmiss,
  setFlexBeneficiaryID,
  flexReallocate
})(withRouter(FlexDollars));
