import React, { Component } from "react";
import { FormCheck } from "react-bootstrap";
import { FormGroup } from "reactstrap";
import { CustomToolTipIcon } from '../utils';

import _ from "underscore";

class NewMultiRadioComponent extends Component {
	constructor(props) {
    super(props);
    /* this.state = {
      istooltip: false,
    }; */
  }
  /* componentDidMount() {
    // const istooltip = this.state.istooltip;
    if(this.props.istooltip) {
      this.setState({istooltip: this.props.istooltip})
    }
  } */
  render() {
    const props = this.props;
    const {tooltiparialabel, tooltiponclick, ...others} = props
    const options = [];
    const propsOptions = _.reject(
      props.options,
      op =>
        op.text === undefined ||
        op.text === "undefined" ||
        op.text === "Undefined" ||
        op.text === "indéfinie" ||
        op.text === "Indéfinie" ||
        op.text === "Non défini"
    );
    _.each(propsOptions, (opt, i) => {
      const uniqID = `${others.component}-${others.name}-${opt.text}-${opt.value}-${i}`;
      options.push(
        <div key={uniqID} className={`mt-2 mb-4 w-${others.width || 50} float-left`}>
          <FormCheck
            inline
            type={others.type || "radio"}
            id={uniqID}
            key={uniqID}
            value={opt.value}
            label={opt.text}
            aria-checked={
              others.selected === opt.value ||
              parseInt(others.selected) === opt.value ||
              others.selected === parseInt(opt.value)
            }
            checked={
              others.selected === opt.value ||
              parseInt(others.selected) === opt.value ||
              others.selected === parseInt(opt.value)
            }
            { ...others }
          />
        </div>
      );
    });
    return (
      <div className="form-holder">
        {
          <FormGroup
            tag="fieldset"
            className="clearfix mb-0 w-100"
            controlid={`${props.component}-${props.name}`}
          >
            {props.istooltip?<legend>
							{this.props.display}
							<span role="button" tabIndex="0" className="tooltip-icon icon-action pl-2 tool" aria-label={props.tooltiparialabel} onClick={props.tooltiponclick}><CustomToolTipIcon /></span>
						  </legend>:<legend  dangerouslySetInnerHTML={{__html: this.props.display}}></legend>}
            {options}
          </FormGroup>
        }
      </div>
    );
  }
}
export default NewMultiRadioComponent;
