import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Row } from "react-bootstrap";
import { Modal, ModalBody } from "reactstrap";
import { getDisplayText, makeFlexitDateObj, skipMainContent } from "../utils";
import DateComponent from "./Calender";
import { getLifeChangeEvent, getWelcomeContent, dissmiss } from "../actions/contentActions";
import { goNext, goPrevious, cancelClick, putWelcome } from "../actions/userActions";
import NextPrevious from "./NextPrevious";
import defaults from "../helpers/defaults";
import { withRouter } from "react-router-dom";
import LifeEventChangeClaim from "./LifeChangeEventClaim";
import { trackPromise } from "react-promise-tracker";
import _ from "underscore";
import NewMultiRadioComponent from "./NewMultiRadioComponent";
export const FormErrors = ({ formErrors }) => (
  <div className="formErrors">
    {Object.keys(formErrors).map((fieldName, i) => {
      if (formErrors[fieldName].length > 0) {
        return (
          <p className="formErrorPStyle" key={i} role="alert">
            {formErrors[fieldName]}
          </p>
        );
      } else {
        return "";
      }
    })}
  </div>
);

class LifeChangeEvent extends Component {
  constructor(props) {
    super(props);
    const lifeEventEffectiveDateSelectedDate = this.props.lifeChangeEvent.result.lifeEventEffectiveDateSelectedDate
      ? new Date(this.props.lifeChangeEvent.result.lifeEventEffectiveDateSelectedDate)
      : new Date();
    this.state = {
      lifeEventEffectiveDateSelectedDate: lifeEventEffectiveDateSelectedDate,
      lifeEventEffectiveDateMinDate: new Date(this.props.lifeChangeEvent.result.lifeEventEffectiveDateMinDate),
      lifeEventEffectiveDateMaxDate: new Date(this.props.lifeChangeEvent.result.lifeEventEffectiveDateMaxDate),
      lifeEventOptionSelected: 0,
      formErrors: { options: "", date: "" },
      optionsValid: false,
      dateValid: false,
      isDisclaimerModalVisible: false,
      isShowLifeEventContinueButton: false
    };
  }

  UNSAFE_componentWillMount = () => {
    trackPromise(this.props.getWelcomeContent());
    trackPromise(this.props.getLifeChangeEvent());
    this.initializeTheStartDate();
  };

  componentWillUnmount = () => {
    this.props.dissmiss();
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const lifeEventEffectiveDateSelectedDate = nextProps.lifeChangeEvent.result.lifeEventEffectiveDateSelectedDate
      ? new Date(nextProps.lifeChangeEvent.result.lifeEventEffectiveDateSelectedDate)
      : new Date();
    const comingState = {
      lifeEventEffectiveDateMinDate: new Date(nextProps.lifeChangeEvent.result.lifeEventEffectiveDateMinDate),
      lifeEventEffectiveDateMaxDate: new Date(nextProps.lifeChangeEvent.result.lifeEventEffectiveDateMaxDate),
      formErrors: { options: "", date: "" }
    };
    if (JSON.stringify(this.props.lifeChangeEvent) !== JSON.stringify(nextProps.lifeChangeEvent)) {
      this.initializeTheStartDate();
      if (
        lifeEventEffectiveDateSelectedDate.toDateString() === comingState.lifeEventEffectiveDateMaxDate.toDateString()
      ) {
        this.setState({ dateValid: true });
      } else {
        this.setState({ dateValid: false });
      }
      // console.log("comming props",nextProps.lifeChangeEvent);
      this.setState(comingState);
      this.validateForm();
    }

    !this.state.dateValid && this.setState({ lifeEventOptionSelected: 0 });
    this.setState({
      isShowLifeEventContinueButton: nextProps.lifeChangeEvent.result.showLifeEventContinueButton
    });
  };

  setValue = (e) => {
    const { name, value } = e.target;
    this.setState({ lifeEventOptionSelected: value }, () => {
      this.validateField(name, value);
    });
  };

  validateField = (fieldName, value) => {
    let fieldValidationErrors = this.state.formErrors;
    let optionsValid = this.state.optionsValid;
    let dateValid = this.state.dateValid;
    switch (fieldName) {
      case "lifeEventOptionSelected":
        optionsValid = true; //value && parseInt(value) > 0 && parseInt(value) <= this.props.lifeChangeEvent.result.lifeEventList.length;
        fieldValidationErrors.options = optionsValid
          ? ""
          : getDisplayText(this.props.lifeChangeEvent.contentResource.contents, "ErrorMustSelectLifeEvent").display;
        break;
      case "lifeEventEffectiveDateSelectedDate":
        dateValid =
          value >= this.state.lifeEventEffectiveDateMinDate && value <= this.state.lifeEventEffectiveDateMaxDate;
        fieldValidationErrors.date = dateValid
          ? ""
          : getDisplayText(this.props.lifeChangeEvent.contentResource.contents, "lblLifeEventEffectiveDateLabel")
              .display;
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        optionsValid: optionsValid,
        dateValid: dateValid
      },
      this.validateForm
    );
  };

  validateForm = () => {
    this.setState({ formValid: this.state.optionsValid && this.state.dateValid });
  };

  handleDateChange = (eventDate) => {
    // const eventDate = new Date(event.year  + "-" + event.month + "-" + event.day);
    // const eventDate = new Date(event.year  + "-" + event.month + "-" + event.day +' '+now.getHours()+':'+now.getMinutes()+':'+ now.getSeconds());
    // console.log("eventDate: ", eventDate);
    this.setState({ lifeEventEffectiveDateSelectedDate: eventDate }, () => {
      this.validateField("lifeEventEffectiveDateSelectedDate", eventDate);
    });
  };

  errorClass = (error) => {
    return error.length === 0 ? "" : "has-error";
  };

  initializeTheStartDate = () => {
    if (
      this.state.lifeEventEffectiveDateSelectedDate.toDateString() ===
      this.state.lifeEventEffectiveDateMaxDate.toDateString()
    ) {
      this.setState({ dateValid: true });
    } else {
      this.setState({ dateValid: false });
    }
    this.validateForm();
  };

  agreeDisclaimerPresses = () => {
    this.validateField("lifeEventOptionSelected", this.state.lifeEventOptionSelected);
    this.validateField("lifeEventEffectiveDateSelectedDate", this.state.lifeEventEffectiveDateSelectedDate);
    if (this.state.formValid) {
      console.log(this.state.lifeEventEffectiveDateSelectedDate);
      let obj = {
        selectedLink: this.props.lifeChangeEventLinkContent,
        lifeEventDate: makeFlexitDateObj(this.state.lifeEventEffectiveDateSelectedDate),
        lifeEventType: this.state.lifeEventOptionSelected
      };
      if (obj.lifeEventDate) {
        obj.lifeEventDate.month++;
      }
      this.props.putWelcome(this.props.history, null, null, null, obj);
      localStorage.setItem("lifeEvent", true);
    }
  };

  nextClick = () => {
    this.setState({ isDisclaimerModalVisible: true });
    // const inputElement = document.querySelector(".fade");
    // inputElement.setAttribute('aria-label', 'header');
  };
  cancelClicked = () => {
    trackPromise(this.props.cancelClick(this.props.history));
  };

  setModalAttribute = (attrValue) => {
    const inputElement = document.querySelector(".modal");
    inputElement.setAttribute("aria-label", attrValue);
  };
  componentDidMount() {
    skipMainContent();
  }

  /* componentDidUpdate(prevProps) {
    if (this.props.locale !== prevProps.locale) {
      trackPromise(this.props.getLifeChangeEvent());
      this.initializeTheStartDate();
    }
  } */

  render = () => {
    const contents1 = this.props.lifeChangeEvent.contentResource
      ? this.props.lifeChangeEvent.contentResource.contents
      : [];
    const contents2 =
      this.props.lifeChangeEvent.contentResource && this.props.lifeChangeEvent.contentResource.controlResource
        ? this.props.lifeChangeEvent.contentResource.controlResource
        : [];
    const contents = _.union(contents1, contents2);

    const lblLifeEventEffectiveDateLabel = getDisplayText(contents, "lblLifeEventEffectiveDateLabel");
    const lifeEventIntroTextVersion = this.props.lifeChangeEvent.result
      ? this.props.lifeChangeEvent.result.lifeEventIntroTextVersion
      : "";

    const lifeEventListUnsorted = this.props.lifeChangeEvent.result
      ? this.props.lifeChangeEvent.result.lifeEventList
      : defaults.lifeEventList;
    const lifeEventArrayUnUsed = lifeEventListUnsorted.sort((a, b) => (a.sequence > b.sequence ? 1 : -1));
    const lifeEventArray = lifeEventArrayUnUsed.filter(function (item) {
      return item.isUsed;
    });
    const btnCancel = this.props.lblCancel || getDisplayText(contents, "btnCancel");
    const btnNext = this.props.lblNext || getDisplayText(contents, "btnNext");
    const lnkStartLifeEvent = getDisplayText(contents, "lnkStartLifeEvent");
    const localestring = this.props.locale.substring(0, 2);
    const lblDatePlaceHolder = getDisplayText(contents, "lblDatePlaceHolder");
    const lifeEventDateError = getDisplayText(contents, "lifeEventDateError");
    const lblLifeEventChangeDateToProceed = getDisplayText(contents, "lblLifeEventChangeDateToProceed");
    const hideLifeEventEffectiveDate = this.props.lifeChangeEvent.result.hideLifeEventEffectiveDate;
    const showLifeEventIntroTextBottom = this.props.lifeChangeEvent.result.showLifeEventIntroTextBottom;

    const lifeEventList = lifeEventArray.map((item) => {
      const mapping = {
        text: item.text,
        value: item.value
      };
      return mapping;
    });
    // console.log("UnOrderList response",this.props.lifeChangeEvent);
    // console.log("final response",lifeEventList);
    return (
      <Row className="justify-content-around">
        <div className="col-xs-12 col-md-10 col-lg-8">
          {/* <FormErrors formErrors={this.state.formErrors} /> */}
          {!showLifeEventIntroTextBottom && (
            <div className="mb-4 mb-lg-5" dangerouslySetInnerHTML={{ __html: lifeEventIntroTextVersion }} />
          )}
          {this.state.isShowLifeEventContinueButton && (
            <>
              <div className="mb-4">
                <div className="ml-inputfields ml-dateofbirth form-group w-100">
                  <div className={`position-relative life-event`}>
                    <DateComponent
                      className="ml-datecomponent"
                      name="lifeEventEffectiveDateSelectedDate"
                      type="text"
                      placeholder={lblDatePlaceHolder.display}
                      display={lblLifeEventEffectiveDateLabel.display}
                      aria-label={lblLifeEventEffectiveDateLabel.textToRead}
                      mode="edit"
                      min_date={this.state.lifeEventEffectiveDateMinDate}
                      max_date={this.state.lifeEventEffectiveDateMaxDate}
                      date={this.state.lifeEventEffectiveDateSelectedDate}
                      onChange={this.handleDateChange}
                      localestring={localestring}
                      isreadonly="true"
                      islifeevent="true"
                      datevalid={this.state.dateValid.toString()}
                      hidelifeeventeffectivedate={hideLifeEventEffectiveDate.toString()}
                    />
                  </div>
                  {this.state.dateValid === false ? (
                    <div className="lifeevent-error invalid-feedback d-block w-100" role="alert">
                      {lifeEventDateError.display}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {hideLifeEventEffectiveDate && lblLifeEventChangeDateToProceed && (
                  <label
                    role="alert"
                    className="alert alert-primary"
                    dangerouslySetInnerHTML={{ __html: lblLifeEventChangeDateToProceed.display }}
                  />
                )}
              </div>
              <div className="ml-lifeevents clearfix">
                <NewMultiRadioComponent
                  component="lifeEventChange"
                  name="lifeEventOptionSelected"
                  selected={this.state.lifeEventOptionSelected ? this.state.lifeEventOptionSelected.toString() : 0}
                  type="radio"
                  options={lifeEventList}
                  keyy={`${this.props.keyy}-lifeEventList`}
                  onChange={this.setValue}
                />
              </div>
            </>
          )}
          {showLifeEventIntroTextBottom && (
            <div className="mb-4" dangerouslySetInnerHTML={{ __html: lifeEventIntroTextVersion }} />
          )}

          <div className="ml-btnslifechangeevent clearfix">
            <NextPrevious
              lastPage={!this.state.isShowLifeEventContinueButton}
              firstPage={true}
              nextClick={this.nextClick}
              cancelClicked={this.cancelClicked}
              previousClick={this.cancelClicked}
              nextDisabled={!this.state.formValid}
              lblNext={btnNext}
              lblCancel={btnCancel}
              lblPrevious={btnCancel}
              showButtons={true}
            />
          </div>

          <Modal
            onOpened={() => this.setModalAttribute(lnkStartLifeEvent.textToRead)}
            aria-labelledby="modalHeading"
            className="ml-lifechangeeventpopup"
            isOpen={this.state.isDisclaimerModalVisible}
            toggle={this.cancelClicked}
            centered
          >
            <div className="modal-header">
              <h1 className="modal-title" id="modalHeading">
                {lnkStartLifeEvent.display}
              </h1>
            </div>
            <ModalBody>
              <LifeEventChangeClaim
                agreeDisclaimerPresses={this.agreeDisclaimerPresses}
                cancelClicked={this.cancelClicked}
              />
            </ModalBody>
          </Modal>
        </div>
      </Row>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    locale: state.contentReducer.locale,
    lifeChangeEvent: state.contentReducer.lifeEventChangeContent,
    pageSetting: state.userReducer.pageSetting,
    lifeChangeEventLinkContent: state.contentReducer.welcomeContent.result.showLifeEvent.btnLabel,
    putWelcomeResponse: state.userReducer.putWelcomeResponse.navigateTo
  };
};

export default connect(mapStateToProps, {
  getLifeChangeEvent,
  getWelcomeContent,
  putWelcome,
  goNext,
  goPrevious,
  cancelClick,
  dissmiss
})(withRouter(LifeChangeEvent));
