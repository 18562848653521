import React, { Component } from "react";
import { connect } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { getSummaryBenefits, setPageSetting, getSummaryFlex } from "../actions/userActions";
import { dissmiss } from "../actions/contentActions";
import SummaryActiveSubscriptions from "./SummaryActiveSubscriptions";
import SummaryFlex from "./SummaryFlex";
import SummaryEoi from "./SummaryEoi";
import { trackPromise } from "react-promise-tracker";

class EnrollmentSummary extends Component {
  constructor(props) {
    super(props);
    this.state = { selected: this.props.selected ? this.props.selected : "summaryTab" };
  }

  componentDidMount() {
    trackPromise(this.props.getSummaryBenefits(true));
    trackPromise(this.props.getSummaryFlex());
  }

  componentWillUnmount() {
    this.props.dissmiss();
  }

  render() {
    return (
      <div>
        <span id="enrollment-summary" />
        <Tabs
          className="tabsenrollment"
          activeKey={this.state.selected}
          onSelect={evKey => {
            this.setState({ selected: evKey });
          }}
        >
          <Tab eventKey="summaryTab" title={this.props.enrollmentSummary.tabName ? this.props.enrollmentSummary.tabName : "noLblForTabName"}>
            <SummaryActiveSubscriptions title={this.props.enrollmentSummary.tabName ? this.props.enrollmentSummary.tabName : "noLblForTabName"} />
          </Tab>
          {this.props.flexSummary.tabName && (
            <Tab eventKey="summaryflex" title={this.props.flexSummary.tabName ? this.props.flexSummary.tabName : "noLblForSummaryFlexTabName"}>
              <SummaryFlex title={this.props.flexSummary.tabName ? this.props.flexSummary.tabName : "noLblForSummaryFlexTabName"} />
            </Tab>
          )}
          {this.props.enrollmentSummary.otherTabName && (
            <Tab eventKey="summaryOtherTab" title={this.props.enrollmentSummary.otherTabName ? this.props.enrollmentSummary.otherTabName : "noLblForOtherTabName"}>
              <SummaryEoi title={this.props.enrollmentSummary.otherTabName ? this.props.enrollmentSummary.otherTabName : "noLblForOtherTabName"} />
            </Tab>
          )}
        </Tabs>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return { enrollmentSummary: state.userReducer.enrollmentSummaryBenefits, flexSummary: state.userReducer.enrollmentSummaryFlex };
};

export default connect(mapStateToProps, { getSummaryBenefits, setPageSetting, getSummaryFlex, dissmiss })(withRouter(EnrollmentSummary));
