import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { Link, withRouter } from 'react-router-dom';
import { getBeneficiariesAll, setPageSetting, downloadFinishedEnrollmentFile, finishEnrollmentForms, getBeneficiaryDeclaration } from '../actions/userActions';
import { dissmiss } from '../actions/contentActions';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Table, Card,Accordion } from 'react-bootstrap';
import { getDisplayText,setModalAttribute } from '../utils';
import Beneficiaries from './Beneficiaries';
import { trackPromise } from 'react-promise-tracker';
import ConfirmedItem from './enrollmentConfirmation/confirmedItem';
import { Modal, ModalBody } from 'reactstrap';
import DisplayButton from './button';

import _ from 'underscore';

class BeneficiariesAll extends Component {

  constructor (props) {
    super(props);

    this.state = { showEdit: false, benefitID: -1, expandedAccordion: 0, viewDeclaration: false }
    this.hideEdit = this.hideEdit.bind(this)
  }
  
  componentDidMount() {
    trackPromise(this.props.getBeneficiariesAll())
    trackPromise(this.props.finishEnrollmentForms()) 
  }

  componentWillUnmount(){
    this.props.dissmiss()
  }

  handleEditAction = (benefitID) => {
    this.setState({showEdit: true, benefitID: benefitID});
  }

  handleBack = (event) => {
    this.props.setPageSetting("WELCOME")
    this.props.history.replace("/")
  }

  hideEdit = (event) => {
    trackPromise(this.props.getBeneficiariesAll())
    trackPromise(this.props.finishEnrollmentForms())
    this.setState({showEdit: false, benefitID: -1})
  }

  getTotalPer = (beneficiary) => {
    let newTotal = 0
    if((beneficiary || {}).length > 0) {
      beneficiary.map(item => {
        return newTotal = item.percentage + newTotal
      })
    }
    return isNaN(newTotal) ? "" : newTotal
  }

  renderBeneficiarieList(title, note, editButton, contents, list, customClass) {
    if (list && list.length > 0) {
      const thcName = getDisplayText(contents, 'thcName');
      const thcRelationship = getDisplayText(contents, 'thcFormRelationship');
      const thcTrustee = getDisplayText(contents, 'thcTrustee');
      const thcPercent = getDisplayText(contents, 'thcPercent');
      const thcTotal = getDisplayText(contents, 'thcTotal');

      //const noteMessage = `${title} <strong>${note ? note : ""}</strong>`
      const items = []
      _.forEach(list, (item, index) => {
          items.push(
            <tr key={`div-${title}-${editButton.linkID}-ID-${item.beneficiaryName}`}>
              <td dangerouslySetInnerHTML={{ __html: `<strong>${item.beneficiaryName}</strong><br />${item.dateOfBirth ? item.dateOfBirth : "n/a"}`}} />
              <td dangerouslySetInnerHTML={{ __html: item.relationShip ? item.relationShip : "n/a"}} />
              <td dangerouslySetInnerHTML={{ __html: item.trustee ? item.trustee : "n/a"}} />
              {(item.removedMsg==="" || item.removedMsg==null)?<td className="text-right">{item.percentage}%</td>:<td><span className="ml-mandatory-sign">({item.removedMsg})</span> {item.percentage}%</td>}
            </tr>
          )
      })
      
      return (
        <Fragment>
          <div className="tbl-beneficiaries">
            <h3 className="title">{title}</h3>
            <Table>
              <thead>
                <tr>
                  <th scope="col">{thcName.display}</th>
                  <th scope="col">{thcRelationship.display}</th>
                  <th scope="col">{thcTrustee.display}</th>
                  <th scope="col">{thcPercent.display}</th>
                </tr>
              </thead>
              <tbody>{items}</tbody>
              <tfoot>
                <tr>
                  <td colSpan="3" className="text-right no-right-border">
                    <strong>{thcTotal.display}</strong>
                  </td>
                  <td>
                    <strong className="m-0" >
                      {this.getTotalPer( (list || []).length > 0 ? list : 0)}%
                    </strong>
                  </td>
                </tr>
              </tfoot>
            </Table>
          </div>
          {/* <Card className={`ccard ${customClass}`}>
              <Card.Header>
                  <div className="d-flex justify-content-between">
                      <div><h2>{title}</h2> </div>
                      <div><h2>{thcPercent.display}</h2></div>
                  </div>
              </Card.Header>
              <Card.Body>{items}</Card.Body>
              {note && <Card.Footer><Fragment><div className="alert alert-primary" role='alert'  dangerouslySetInnerHTML={{__html: noteMessage}}/></Fragment></Card.Footer>}
          </Card> */}
        </Fragment>
      )
    }

    return null;
  }

  renderNoBeneficiariesMessage(title, message, thcPercent) {
    return (
      <Card className={`ccard onfilebeneficiaries`}>
          <Card.Header>
          <div><h2>{title}</h2> </div>
          <div><h2>{thcPercent.display}</h2></div>
          </Card.Header>
          {message && <Card.Footer><Fragment><div className="alert alert-secondary" role='alert' dangerouslySetInnerHTML={{__html: message}}/></Fragment></Card.Footer>}
      </Card>
    );
  }

  beneficiaryNavigationClicked = (beneficiaryFormNavigateTo, fileName) => {
    this.props.downloadFinishedEnrollmentFile(beneficiaryFormNavigateTo, fileName);
  }

  toggleAccordion = (openElementIndex) => {
    this.setState(state => ({expandedAccordion: openElementIndex === state.expandedAccordion ? -1: openElementIndex}))
  }

  /* E-Signature Start */
  handleShowHelp = (btnNavigateTo) => {
    console.log("--> HELP LINK: ", btnNavigateTo)
    if (btnNavigateTo) {
      this.props.getBeneficiaryDeclaration(btnNavigateTo , () => {
        this.showModal()
      })
    }
  }

  showModal = () => {
    this.setState({viewDeclaration: true}) 
  }

  handleClose = () => {
    this.setState(state => ({viewDeclaration: false}))
  }
  /* E-Signature End */

  render() {

    const beneficiaryForms = this.props.beneficiariyForm.beneficiaryForms
    const isBeneficiaryFormVisible = this.props.beneficiariyForm.beneficiaryFormsFormVisible
    let beneficiaryFilename = "";
    if(isBeneficiaryFormVisible && (beneficiaryForms && beneficiaryForms !== null)){
        beneficiaryFilename = beneficiaryForms.fileName ? beneficiaryForms.fileName : "BeneficiaryForm";
    }
    /* E-Signature Start */
    const isEsignable = this.props.beneficiariyForm.isEsignable;
    const beneficiaryESign = this.props.beneficiariyForm.beneficiaryESign;
    let beneficiaryESignFilename = "";
    if(isEsignable && (beneficiaryESign && beneficiaryESign !== null)){
        beneficiaryESignFilename = beneficiaryESign.fileName ? beneficiaryESign.fileName : "BeneficiaryESignForm";
    }
    /* E-Signature End */
    //Beneficiary Form Configuration
    const dangerType = 'DANGER';
    const simpleButtonColor = '#f4f9f2';
    const downloadButtonLabel = this.props.beneficiariyForm.downloadButtonLabel

    const beneficiaryMessage = this.props.beneficiariesAll.beneficiariesMessage
    const noBeneficiaryBenefits = this.props.beneficiariesAll.noBeneficiaryBenefits ? this.props.beneficiariesAll.noBeneficiaryBenefits : ""
    const noCoverage = this.props.beneficiariesAll.noCoverage ? this.props.beneficiariesAll.noCoverage : ""
    const beneficiaryBenefitsInfo = this.props.beneficiariesAll.beneficiaryBenefitsInfo ? this.props.beneficiariesAll.beneficiaryBenefitsInfo : []
    const content = this.props.beneficiariesAll.contentResource ? this.props.beneficiariesAll.contentResource.controlResource : {}
    const contentres = this.props.beneficiariesAll.contentResource ? this.props.beneficiariesAll.contentResource.contents : {}
    const contents = content.Beneficiaries ? content.Beneficiaries : {}
    const pageTitle = getDisplayText(contents, "lblBeneficiaries")
    const thcPercent = getDisplayText(contents, "thcPercent")
    const lblEdit = getDisplayText(contents,'lblChoose')
    const closeButtonText = getDisplayText(contentres,"btnClose")
   // const backButtonIcon = getDisplayText((this.props.contentFooter && this.props.contentFooter) || {},'BackButtonIcon')
    const backButtonText = getDisplayText((this.props.contentFooter && this.props.contentFooter) || {},'btnBack')
    //const expandCollapseIcon = getDisplayText((this.props.contentFooter && this.props.contentFooter) || {}, 'ExpandCollapseIcon')
    document.title = pageTitle.display
    const beneficiaryList = [];
    _.forEach(beneficiaryBenefitsInfo, (benefit, index) => {
      const onFile = this.renderBeneficiarieList(benefit.lblOnFile, null, benefit.editButton, contents, benefit.beneficiaryCurrentInfo, "onfilebeneficiaries")
      const pending = this.renderBeneficiarieList(benefit.pending, benefit.pendingNote, benefit.editButton, contents, benefit.beneficiaryPendingInfo, "pendingbeneficiaries")
      const contingentOnFile = this.renderBeneficiarieList(benefit.lblContingentOnFile, null, benefit.editButton, contents, benefit.contingentBeneficiaryCurrentInfo, "onfilecontingentbeneficiaries")
      const contingentPending = this.renderBeneficiarieList(benefit.lblContingentPending, benefit.pendingNote, benefit.editButton, contents, benefit.contingentBeneficiaryPendingInfo, "contingentpendingbeneficiaries")

      const noBeneficiariesMessage = this.renderNoBeneficiariesMessage(benefit.lblOnFile, benefit.noBeneficiariesNote, thcPercent)
      const noContBeneficiariesMessage = benefit.isContingentBeneficiaryOn ? this.renderNoBeneficiariesMessage(benefit.lblContingentOnFile, benefit.noBeneficiariesNote, thcPercent):null
      /* E-Signature Start */
      const viewBeneficiaryDeclaration = benefit.viewBeneficiaryDeclaration
      /* E-Signature End */
      const removedBeneficiaryError=benefit.removedBeneficiaryError
      // const beneficiaryDeclaration = this.renderNoBeneficiariesMessage(viewBeneficiaryDeclaration.btnNavigateTo, viewBeneficiaryDeclaration.name)
      beneficiaryList.push(
      <section key={`div-${benefit.benefitID}-ID-${benefit.benefitName}-${index}`} className="mb-md-4">
        { ( this.state.showEdit && benefit.benefitID === this.state.benefitID ) ? 
          <div className="clearfix">
            <Beneficiaries beneficiariesAll={true} benefitID={this.state.benefitID} benefitTitle={benefit.benefitName} hideEdit={this.hideEdit} isCancelVisible={true} handleCancelEdit={this.hideEdit}/>
          </div>
          : 
            <Card className="pcard">
              <Card.Header>
              <h2 className="heading-white">{benefit.benefitName}</h2>
              </Card.Header>
              <Card.Body>
                  { benefit.noBeneficiariesNote ? <>{noBeneficiariesMessage}{noContBeneficiariesMessage}</>
                    : <>
                      {onFile}
                      {contingentOnFile}
                      {pending}
                      {contingentPending}
                    </>
                  }
                {removedBeneficiaryError && <Fragment><div className="alert alert-danger mb-2" role='alert' dangerouslySetInnerHTML={{ __html: removedBeneficiaryError}} />
                </Fragment>}
               <br/>
                <div className={this.state.expandedAccordion === index ? "clearfix btnbox" : "clearfix btnbox"}>
                  <DisplayButton className="outline-secondary btn-choosebeneficiary"controlId="editBtn1"  disabled={benefit.noEditableNote} displayName={lblEdit.display} onClick={() => this.handleEditAction(benefit.benefitID)}/>
                  {/* E-Signature Start */}
                  {benefit.isEsignable && benefit.noEditableNote && 
                    <Fragment><div role='alert' className='clearfix alert alert-info m-0 mb-3' dangerouslySetInnerHTML={{ __html: benefit.noEditableNote }}/></Fragment>
                  }
                  {benefit.isEsignable && !benefit.noEditableNote && viewBeneficiaryDeclaration.name && 
                    <Link
                    to="#"
                    onClick={() => {this.handleShowHelp(viewBeneficiaryDeclaration.btnNavigateTo)}}
                    aria-label={viewBeneficiaryDeclaration.name} 
                    className='float-right icon-action mt-3'
                    dangerouslySetInnerHTML={{ __html: viewBeneficiaryDeclaration.name }}
                    />
                  }
                  {/* <span role='button' tabIndex='0' onClick={() => {this.handleShowHelp(viewBeneficiaryDeclaration.btnNavigateTo)}} aria-label={viewBeneficiaryDeclaration.name} className='float-right icon-action mt-3' dangerouslySetInnerHTML={{ __html: viewBeneficiaryDeclaration.name }}/> */}
                  
                  {/* E-Signature End */}
  
                </div>
              </Card.Body>
            </Card>
        }
      </section>
      )
    })

    return (
      <div className="beneficiariesall baneficiarylist clearfix">
       
        <div className={`d-flex titlewithicon mb-md-3`}> 
        <DisplayButton className="icon-action" displayName={backButtonText.display} icon={faChevronLeft} onClick={(e) => { this.handleBack(e) }} /> 
        </div>
        <div className="clearfix">
          {isBeneficiaryFormVisible && beneficiaryForms && <>
            <Fragment><div role='alert' className={beneficiaryForms.messageType === dangerType ? 'clearfix alert alert-danger m-0 mb-3' : 'clearfix pb-4'} dangerouslySetInnerHTML={{ __html: beneficiaryForms.message }}/></Fragment>
            <ConfirmedItem
              title={beneficiaryForms.name}
              buttonTitle={downloadButtonLabel}
              navigationTo={beneficiaryForms.btnNavigateTo}
              backColor={simpleButtonColor}
              navigateClicked={() => this.beneficiaryNavigationClicked(beneficiaryForms.btnNavigateTo, beneficiaryFilename)} />
            </>
          }
        </div>
        {/* E-Signature Start */}
        <div className="clearfix">
          {isEsignable && beneficiaryESign && <>
            <Fragment><div role='alert' className={beneficiaryESign.messageType === dangerType ? 'clearfix alert alert-danger m-0 mb-3' : 'clearfix pb-4'} dangerouslySetInnerHTML={{ __html: beneficiaryESign.message }}/></Fragment>
            <ConfirmedItem
              title={beneficiaryESign.name}
              buttonTitle={downloadButtonLabel}
              navigationTo={beneficiaryESign.btnNavigateTo}
              backColor={simpleButtonColor}
              navigateClicked={() => this.beneficiaryNavigationClicked(beneficiaryESign.btnNavigateTo, beneficiaryESignFilename)} />
            </>
          }
        </div>
        {/* E-Signature End */}
        <div className="beneficiarylist clearfix mb-md-4">
          { noBeneficiaryBenefits && <Fragment><div className="alert alert-dark" role='alert' dangerouslySetInnerHTML={{ __html: noBeneficiaryBenefits}} /></Fragment> }
          { noCoverage && <Fragment><div className="alert alert-secondary" role='alert' dangerouslySetInnerHTML={{ __html: noCoverage}} /></Fragment> }
          { !noCoverage && !noBeneficiaryBenefits && beneficiaryList && 
            <Accordion defaultActiveKey="0">
              {beneficiaryList}
            </Accordion>
          }
        </div>
        {beneficiaryMessage && <Fragment><div className="alert alert-info m-0 mb-md-4" role='alert' dangerouslySetInnerHTML={{ __html: beneficiaryMessage}} />
        </Fragment>}
        
          {/* E-Signature Start */}
          <Modal onOpened={() => setModalAttribute(this.props.helpContent.contentHeader)} aria-labelledby="modalHeading" centered isOpen={this.state.viewDeclaration} toggle={this.handleClose} className="ml-addnewdependent coveragewaiverpopup">
          <div className="modal-header">
            <h1 className="modal-title" id='modalHeading'>
              {this.props.helpContent.contentHeader}
            </h1>
            <DisplayButton type="button"  className="close" displayName="×" arialabel={closeButtonText.display} onClick={this.handleClose}/> 
          </div>
            <ModalBody className={this.state.viewDeclaration ? "scroll-container" : ""}>
              {this.state.viewDeclaration && 
                <Fragment> <div dangerouslySetInnerHTML={{__html: this.props.helpContent.content}}/> </Fragment>
              }
            </ModalBody>
        </Modal>
        {/* E-Signature End */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
    return { 
      beneficiariesAll: state.userReducer.beneficiariesAll ? state.userReducer.beneficiariesAll : {},
      contentFooter: (state.userReducer.contentFooter && state.userReducer.contentFooter.contentResource) ? state.userReducer.contentFooter.contentResource.contents : {},
      pageSetting: state.userReducer.pageSetting,
      beneficiariyForm: state.userReducer.beneficiariyForm ? state.userReducer.beneficiariyForm : {},
      helpContent: state.userReducer.helpContentResponse ? state.userReducer.helpContentResponse.result : "",
    }
  };
  
  export default connect(
  mapStateToProps,
  { getBeneficiariesAll, setPageSetting, dissmiss, downloadFinishedEnrollmentFile, finishEnrollmentForms, getBeneficiaryDeclaration }
  )(withRouter(BeneficiariesAll));
