import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { isMobile } from "react-device-detect";
import ReactDOM from "react-dom";

class Calender extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isreadonly: this.props.isreadonly === "true" ? true : false,
      isfocused: props.date ? true : false,
      dataformat: [],
      isEmpty: false,
      caldate: {
        month: props.date && props.date.month ? this.setDateZero(props.date.month) : "",
        day: props.date && props.date.day ? this.setDateZero(props.date.day) : "",
        year: props.date && props.date.year ? props.date.year : ""
      }
    };
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isreadonly !== this.state.isreadonly) {
      this.setState({ isreadonly: true });
    }
  }

  onIconClick = () => {
    const datePickerNode = ReactDOM.findDOMNode(this.refs.datepicker);
    datePickerNode && datePickerNode.querySelector("input").click();
  };

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside, true);
    const { placeholder, localestring } = this.props;
    let dataformat = localestring === "fr" ? placeholder.split("-") : placeholder.split("/");
    this.setState({ dataformat });
    if (this.props.islifeevent) {
      const eventDate = this.props.max_date;
      const caldate = {
        month: this.setDateZero(eventDate.getMonth() + 1),
        day: this.setDateZero(eventDate.getDate()),
        year: eventDate.getFullYear().toString()
      };

      const isEmpty = Object.values(caldate).every((x) => x === null || x === "");
      if (!isEmpty && this.props.hidelifeeventeffectivedate === "false") {
        this.setState({ caldate, isfocused: true, isEmpty });
      } else {
        this.setState({
          caldate: {
            month: "",
            day: "",
            year: ""
          }
        });
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
  }

  setDateZero = (date) => {
    return date < 10 ? "0" + date.toString() : date.toString();
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.placeholder !== nextProps.placeholder && nextProps.islifeevent) {
      document.addEventListener("click", this.handleClickOutside, true);
      const { placeholder, localestring } = nextProps;
      let dataformat = localestring === "fr" ? placeholder.split("-") : placeholder.split("/");

      this.setState({ dataformat });
      const eventDate = nextProps.max_date;
      const caldate = {
        month: this.setDateZero(eventDate.getMonth() + 1),
        day: this.setDateZero(eventDate.getDate()),
        year: eventDate.getFullYear().toString()
      };
      const isEmpty = Object.values(caldate).every((x) => x === null || x === "");
      if (!isEmpty && this.props.hidelifeeventeffectivedate === "false") {
        this.setState({ caldate, isfocused: true });
      }
    }
    if (this.state.caldate.year === "NaN") {
      this.setState({
        caldate: {
          month: "",
          day: "",
          year: ""
        }
      });
    }
  }

  /**
   * Check if clicked on outside of element
   */
  handleClickOutside(event) {
    const domNode = ReactDOM.findDOMNode(this);
    if (!domNode || !domNode.contains(event.target)) {
      if (this.state.caldate.month === "") {
        this.setState({ isfocused: false });
      }
      this.setState({ isreadonly: true });
      this.outSideClickHandler();
    }
  }

  outSideClickHandler() {
    const { day, month, year } = this.state.caldate;

    const now = new Date();
    let eventDate = new Date(
      year + "/" + month + "/" + day + " " + now.getHours() + ":" + now.getMinutes() + ":" + now.getSeconds()
    );

    if (this.props.islifeevent) {
      const { min_date, max_date } = this.props;
      if (eventDate <= min_date) {
        eventDate = min_date;
      } else if (eventDate >= max_date) {
        eventDate = max_date;
      }
    } else {
      const currentDate = new Date();
      eventDate = eventDate > currentDate ? currentDate : eventDate;
    }

    const caldate = {
      month: this.setDateZero(eventDate.getMonth() + 1),
      day: this.setDateZero(eventDate.getDate()),
      year: eventDate.getFullYear().toString()
    };

    if (this.state.caldate.year === "NaN") {
      this.setState({
        caldate: {
          month: "",
          day: "",
          year: ""
        },
        isEmpty: true
      });
    } else {
      this.setState({ caldate }, () => {
        if (this.props.islifeevent) {
          this.props.onChange(eventDate);
        } else {
          this.props.onChange(caldate);
        }
        document.removeEventListener("click", this.handleClickOutside, true);
      });
    }
  }

  handleCalender = (e) => {
    e.preventDefault();
    let focusObj = this.props.localestring === "fr" ? this.refs.dobDay : this.refs.dobMonth;
    if (!this.state.isfocused) {
      this.setState(
        () => ({
          isfocused: true
        }),
        () => {
          ReactDOM.findDOMNode(focusObj).focus();
        }
      );
    }
    if (this.state.caldate.year === "NaN") {
      this.setState({
        caldate: {
          month: "",
          day: "",
          year: ""
        }
      });
    }
    // this.props.onChange(this.state.caldate);
    /* else if (this.state.isfocused && this.state.caldate.month > 0) {
      ReactDOM.findDOMNode(this.refs.dobMonth).select();
    } */
  };

  setDOBValue = (e) => {
    document.addEventListener("click", this.handleClickOutside, true);
    let caldate = { ...this.state.caldate };
    const { name, value } = e.target;
    if (caldate.year === "") {
      this.setState({ isEmpty: true });
    } else {
      this.setState({ isEmpty: false });
    }

    if (!isNaN(value)) {
      caldate[name] = value;
      this.setState({ caldate }, () => {
        this.props.onChange(caldate);
      });
    } else {
      this.setState({
        caldate: {
          month: "",
          day: "",
          year: ""
        }
      });
    }

    if (this.props.localestring === "fr") {
      if (name === "month" && value.length === 2) {
        ReactDOM.findDOMNode(this.refs.dobYear).focus();
      } else if (name === "day" && value.length === 2) {
        ReactDOM.findDOMNode(this.refs.dobMonth).focus();
      } else if (name === "year" && value.length === 4) {
        this.setState({ caldate }, () => {
          this.outSideClickHandler();
        });
      }
    } else {
      if (name === "month" && value.length === 2) {
        ReactDOM.findDOMNode(this.refs.dobDay).focus();
      } else if (name === "day" && value.length === 2) {
        ReactDOM.findDOMNode(this.refs.dobYear).focus();
      } else if (name === "year" && value.length === 4) {
        this.setState({ caldate }, () => {
          this.outSideClickHandler();
        });
      }
    }
  };

  onKeyDown = (e) => {
    var name = e.target.name,
      value = e.target.value;
    if (this.props.localestring === "fr") {
      if (e.keyCode === 8 && value.length === 0 && name === "year") {
        ReactDOM.findDOMNode(this.refs.dobMonth).focus();
      } else if (e.keyCode === 8 && value.length === 0 && name === "month") {
        ReactDOM.findDOMNode(this.refs.dobDay).focus();
      }
    } else {
      if (e.keyCode === 8 && value.length === 0 && name === "year") {
        ReactDOM.findDOMNode(this.refs.dobDay).focus();
      } else if (e.keyCode === 8 && value.length === 0 && name === "day") {
        ReactDOM.findDOMNode(this.refs.dobMonth).focus();
      }
    }
    if (
      ((e.keyCode > 47 && e.keyCode < 58) || (e.keyCode > 96 && e.keyCode < 105)) &&
      value.length > 3 &&
      name === "year"
    ) {
      ReactDOM.findDOMNode(e.target).select();
    } else if (
      ((e.keyCode > 47 && e.keyCode < 58) || (e.keyCode > 96 && e.keyCode < 105)) &&
      value.length > 1 &&
      name !== "year"
    ) {
      ReactDOM.findDOMNode(e.target).select();
    }
    if (e.keyCode === 9) {
      this.outSideClickHandler();
    }
  };

  render() {
    const { islifeevent, localestring } = this.props;
    const editMode = this.props.mode === "edit" ? true : false;
    const labelC =
      this.props.name === "dateOfBirth" ? (
        <Form.Label {...this.props}>
          <span dangerouslySetInnerHTML={{ __html: this.props.display }} />
          &nbsp;
        </Form.Label>
      ) : (
        <Form.Label {...this.props}>{this.props.display}&nbsp;</Form.Label>
      );

    let dateco = {};
    const seperator = localestring === "fr" ? "-" : "/";

    if (editMode) {
      let date;
      if (islifeevent === "true") {
        date = this.props.date && this.validate(this.props.date) ? this.props.date : new Date();
        if (date.year) {
          const { year, month, day } = date;
          date = new Date(year, month, day);
        } else {
          date = new Date(Date.parse(date));
        }
      } else {
        date = this.props.date && this.validate(this.props.date) ? this.props.date : null;
        if (date !== null) {
          const { year, month, day } = date;
          date = new Date(year, month, day);
        }
      }
      if (this.props.format) {
        dateco = (
          <Fragment>
            <div
              className={`w-50 custInput dob-blk-main ${
                this.props.checkdob === "true" && this.props.formvalidated === "true" ? "invalid" : ""
              }`}
              ref="custInput"
              onClick={this.handleCalender}
              // onKeyDown={this.outSideClickHandler}
            >
              <div id="dob-div" className="d-flex align-items-center dob-blk dob-blk-none">
                {localestring === "fr" ? (
                  <Form.Control
                    autoComplete="off"
                    type="text"
                    placeholder={this.state.dataformat[0]}
                    name="day"
                    value={this.state.caldate.day || ""}
                    className={`ddfield ${this.state.isfocused ? "show" : "hide"}`}
                    maxLength="2"
                    ref="dobDay"
                    onChange={this.setDOBValue}
                    onKeyDown={this.onKeyDown}
                  />
                ) : (
                  <Form.Control
                    autoComplete="off"
                    type="text"
                    placeholder={this.state.dataformat[0]}
                    name="month"
                    value={this.state.caldate.month || ""}
                    className={`mmfield ${this.state.isfocused ? "show" : "hide"}`}
                    maxLength="2"
                    ref="dobMonth"
                    onChange={this.setDOBValue}
                    onKeyDown={this.onKeyDown}
                  />
                )}
                <span className={`input-sep align-items-center ${this.state.isfocused ? "show" : "hide"}`}>
                  {seperator}
                </span>
                {localestring === "fr" ? (
                  <Form.Control
                    autoComplete="off"
                    type="text"
                    placeholder={this.state.dataformat[1]}
                    name="month"
                    value={this.state.caldate.month || ""}
                    className={`mmfield ${this.state.isfocused ? "show" : "hide"}`}
                    maxLength="2"
                    ref="dobMonth"
                    onChange={this.setDOBValue}
                    onKeyDown={this.onKeyDown}
                  />
                ) : (
                  <Form.Control
                    type="text"
                    placeholder={this.state.dataformat[1]}
                    name="day"
                    value={this.state.caldate.day || ""}
                    className={`ddfield ${this.state.isfocused ? "show" : "hide"}`}
                    maxLength="2"
                    ref="dobDay"
                    onChange={this.setDOBValue}
                    onKeyDown={this.onKeyDown}
                  />
                )}
                <span className={`input-sep align-items-center ${this.state.isfocused ? "show" : "hide"}`}>
                  {seperator}
                </span>
                <Form.Control
                  type="text"
                  placeholder={this.state.dataformat[2]}
                  name="year"
                  value={this.state.caldate.year || ""}
                  className={`yyfield ${this.state.isfocused ? "show" : "hide"}`}
                  maxLength="4"
                  ref="dobYear"
                  onChange={this.setDOBValue}
                  onKeyDown={this.onKeyDown}
                />
              </div>
              <label
                htmlFor="dob-div"
                tabIndex="0"
                onFocus={this.handleCalender}
                className={`dob-blk label-placeholder ${!this.state.isfocused ? "show" : "hide"}`}
              >
                {this.state.dataformat.map((format, i, arr) => {
                  return (
                    <Fragment key={format + i + arr.length}>
                      {format}
                      {arr.length - 1 !== i && <span className="input-sep align-items-center">{seperator}</span>}
                    </Fragment>
                  );
                })}
              </label>
            </div>
          </Fragment>
        );
      } else {
        dateco = (
          <Fragment>
            <div
              className={`custInput dob-blk-main ${
                this.props.islifeevent && this.props.datevalid === "false" ? "invalid" : ""
              }`}
              ref="custInput"
              onClick={this.handleCalender}
            >
              <div id="dob-div" className="d-flex align-items-center dob-blk dob-blk-none">
                {localestring === "fr" ? (
                  <Form.Control
                    autoComplete="off"
                    type="text"
                    placeholder={this.state.dataformat[0]}
                    name="day"
                    value={this.state.caldate.day || ""}
                    className={`ddfield ${this.state.isfocused ? "show" : "hide"}`}
                    maxLength="2"
                    ref="dobDay"
                    onChange={this.setDOBValue}
                    onKeyDown={this.onKeyDown}
                  />
                ) : (
                  <Form.Control
                    autoComplete="off"
                    type="text"
                    placeholder={this.state.dataformat[0]}
                    name="month"
                    value={this.state.caldate.month || ""}
                    className={`mmfield ${this.state.isfocused ? "show" : "hide"}`}
                    maxLength="2"
                    ref="dobMonth"
                    onChange={this.setDOBValue}
                    onKeyDown={this.onKeyDown}
                  />
                )}
                <span className={`input-sep align-items-center ${this.state.isfocused ? "show" : "hide"}`}>
                  {seperator}
                </span>
                {localestring === "fr" ? (
                  <Form.Control
                    autoComplete="off"
                    type="text"
                    placeholder={this.state.dataformat[1]}
                    name="month"
                    value={this.state.caldate.month || ""}
                    className={`mmfield ${this.state.isfocused ? "show" : "hide"}`}
                    maxLength="2"
                    ref="dobMonth"
                    onChange={this.setDOBValue}
                    onKeyDown={this.onKeyDown}
                  />
                ) : (
                  <Form.Control
                    type="text"
                    placeholder={this.state.dataformat[1]}
                    name="day"
                    value={this.state.caldate.day || ""}
                    className={`ddfield ${this.state.isfocused ? "show" : "hide"}`}
                    maxLength="2"
                    ref="dobDay"
                    onChange={this.setDOBValue}
                    onKeyDown={this.onKeyDown}
                  />
                )}
                <span className={`input-sep align-items-center ${this.state.isfocused ? "show" : "hide"}`}>
                  {seperator}
                </span>
                <Form.Control
                  type="text"
                  placeholder={this.state.dataformat[2]}
                  name="year"
                  value={this.state.caldate.year || ""}
                  className={`yyfield ${this.state.isfocused ? "show" : "hide"}`}
                  maxLength="4"
                  ref="dobYear"
                  onChange={this.setDOBValue}
                  onKeyDown={this.onKeyDown}
                />
              </div>
              <label
                htmlFor="dob-div"
                tabIndex="0"
                onFocus={this.handleCalender}
                className={`dob-blk label-placeholder ${!this.state.isfocused ? "show" : "hide"}`}
              >
                {this.state.dataformat.map((format, i, arr) => {
                  return (
                    <Fragment key={format + i + arr.length}>
                      {format}
                      {arr.length - 1 !== i && <span className="input-sep align-items-center">{seperator}</span>}
                    </Fragment>
                  );
                })}
              </label>
            </div>
          </Fragment>
        );
      }
    } else {
      const d = `${this.props.date.year ? this.props.date.year + "/" : ""}${
        this.props.date.month ? this.props.date.month + "/" : ""
      }${this.props.date.day ? this.props.date.day : ""}`;
      const date = this.props.date.display;
      if (this.props.date.display)
        dateco = (
          <Form.Label tid="displayDateLabel" {...this.props}>
            {date}{" "}
          </Form.Label>
        );
      if (!this.props.date.display) dateco = <Form.Label {...this.props}>{d} </Form.Label>;
    }

    return (
      <div className={`clearfix`}>
        {labelC}
        {isMobile ? <div className="react-datepicker-wrapper">{dateco}</div> : dateco}{" "}
        {!editMode && (
          <FontAwesomeIcon
            onClick={() => this.setState({ isreadonly: false }, this.onIconClick)}
            className="icon-action"
            icon={faCalendarAlt}
          />
        )}
      </div>
    );
  }

  validate = (date) => {
    const res = (date && date.year) || Date.parse(date) ? true : false;
    return res;
  };
}

export default Calender;
