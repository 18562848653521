import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { Row, Card, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { navigatesTo, getSummaryFlex, setPageSetting } from '../actions/userActions';
import DisplayButton from './button';
import _ from 'underscore';
import { getDisplayText } from '../utils';
import { trackPromise } from 'react-promise-tracker';

class SummaryFlex extends Component {

  handleEditAction = (link) => {
    // const menuItem = _.find(this.props.sideBarMenu, item => item.link === link)
    // this.props.history.push(`/${menuItem ? menuItem.link: ""}`);
    trackPromise(this.props.navigatesTo(this.props.history, link))
  }

  render () {
    const flexSummary = this.props.flexSummary ? this.props.flexSummary.displayFlex : {}
    const flexHeader = flexSummary.flexHeader ? flexSummary.flexHeader : {}

    const flexColumnCount = ( (flexHeader.assignedFlex && 1) || 0) 
                            + ((flexHeader.deductions && 1) || 0) 
                            + ((flexHeader.earnedFlex && 1) || 0) 
                            + ((flexHeader.flexBenefit && 1) || 0) 
                            + ((flexHeader.salesTax && 1) || 0) 

    const contents = this.props.flexSummary.contentResource ? this.props.flexSummary.contentResource.contents : {}
    const lblBtnEdit = getDisplayText(contents, "btnChange")

    const pageTitle = flexSummary.flexTitle
    document.title = pageTitle

    const flexDescription = flexSummary.flexDescription
    const flexDescriptionProrating = flexSummary.flexDescriptionProrating
    const flexDescriptionNoRemaining = flexSummary.flexDescriptionNoRemaining
    const excessFlex = flexSummary.excessFlex
    const proratedFlex = flexSummary.proratedFlex

    const flexAdjustmentText = flexSummary.flexAdjustmentText
    const wellness = flexSummary.wellness
    const proratedNote = flexSummary.proratedNote
    const disclaimerText = flexSummary.disclaimerText
    const endOfYearNote = flexSummary.endOfYearNote
    const endOfYearNoteList = flexSummary.endOfYearNoteList
    const endOfYearNoteEnd = flexSummary.endOfYearNoteEnd

    const isEditButtonVisible = this.props.flexSummary.isbtnEditFlexVisible
    const actionLink = this.props.flexSummary.actionlink

    const benefitTitle = flexHeader.flexBenefit
    const assignedFlexTitle = flexHeader.assignedFlex
    const deductionsTitle = flexHeader.deductions
    const salesTaxTitle = flexHeader.salesTax
    const earnedFlexTitle = flexHeader.earnedFlex

    const flexFooterList = ( flexSummary.flexFooter || [] )
    const flexRows = []
    const flexRowsList = flexSummary.flexRows ? flexSummary.flexRows : []

    const flexRowTotalIndexList = []
    _.each(flexFooterList, (totalItem, index) => {
      let totalIndex = _.findLastIndex(flexRowsList, {"flexDisplayGroupId": totalItem.flexDisplayGroupId})
      if (totalIndex !== -1) {
        flexRowTotalIndexList.push({
          flexRowIndex: totalIndex,
          flexFooterIndex: index
        })
      }
    })


    _.each(flexRowsList, (benefit, index) => {
      flexRows.push(
        <Row className="singleitem" key={`div-flexRow-${benefit.flexName}-${index}-ID`} >
          { benefitTitle && <Col data-title="Allocations To"><span className="visuallyhidden">{benefitTitle}</span><span>{benefit.flexName || '\u00A0'}</span></Col>}
          { assignedFlexTitle && <Col data-title="Amount Applied"><span className="visuallyhidden">{assignedFlexTitle}</span><span>{benefit.flexAmount || '\u00A0'}</span></Col>}
          { deductionsTitle && <Col data-title="Payroll Deductions Per Pay"><span className="visuallyhidden">{deductionsTitle}</span><span>{benefit.deductionsAmount || '\u00A0'}</span></Col>}
          { salesTaxTitle && <Col data-title="Sales Tax"><span className="visuallyhidden">{salesTaxTitle}</span><span>{benefit.salesTaxAmount || '\u00A0'}</span></Col>}
          { earnedFlexTitle && <Col data-title="Earned Flex"><span className="visuallyhidden">{earnedFlexTitle}</span><span>{benefit.earnedFlexAmount || '\u00A0'}</span></Col>}
        </Row>);

      if ( flexRowTotalIndexList.length > 0 ) {
        const matchedTotalIndex = _.findWhere(flexRowTotalIndexList, {"flexRowIndex": index})
        if ( typeof matchedTotalIndex !== 'undefined' ) {
          const totalValue = flexFooterList[matchedTotalIndex.flexFooterIndex]
          flexRows.push(
            <Row className="total-row" key={`div-flexRow-${benefit.flexName}-total-${totalValue.totalLabel}-${index}-ID`}  >
              { totalValue.totalLabel && <Col>{totalValue.totalLabel || '\u00A0'}</Col> }
              { totalValue.totalAmount && <Col className="total-col-sum">{totalValue.totalAmount|| '\u00A0'}</Col> }
              { deductionsTitle && <Col className="col-empty">&nbsp;</Col>}
              { salesTaxTitle && <Col className="col-empty">&nbsp;</Col>}
              { earnedFlexTitle && <Col className="col-empty">&nbsp;</Col>}
            </Row>
          );
        }
      }
    });

    const matchRows = []
    const matchRowsList = flexSummary.matchRows ? flexSummary.matchRows : []
    _.each(matchRowsList, (benefit) =>{
      matchRows.push(
        <Row className="singleitem" key={`div-matchRow-${benefit.flexName || '\u00A0'}-ID`}>
          <Col data-title="Allocations To"><span className="visuallyhidden">{benefitTitle}</span><span>{benefit.flexName || '\u00A0'}</span></Col>
          <Col data-title="Amount Applied"><span className="visuallyhidden">{assignedFlexTitle}</span><span>{benefit.flexAmount || '\u00A0'}</span></Col>
          <Col data-title="Payroll Deductions Per Pay"><span className="visuallyhidden">{deductionsTitle}</span><span>{benefit.deductionsAmount || '\u00A0'}</span></Col>
          <Col data-title="Sales Tax"><span className="visuallyhidden">{salesTaxTitle}</span><span>{benefit.salesTaxAmount || '\u00A0'}</span></Col>
          <Col data-title="Earned Flex"><span className="visuallyhidden">{earnedFlexTitle}</span><span>{benefit.earnedFlexAmount || '\u00A0'}</span></Col>
        </Row>);
    });

    const vacationFlexRow = !flexSummary.vacationFlexRow ? null : (
      <Row className="singleitem" key={`div-matchRow-${flexSummary.vacationFlexRow.flexBenefit}-ID`}>
        { flexSummary.vacationFlexRow.flexBenefit && <Col data-title="Allocations To"><span className="visuallyhidden">{benefitTitle}</span><span>{flexSummary.vacationFlexRow.flexBenefit || '\u00A0'}</span></Col>}
        { flexSummary.vacationFlexRow.assignedFlex && <Col data-title="Amount Applied"><span className="visuallyhidden">{assignedFlexTitle}</span><span>{flexSummary.vacationFlexRow.assignedFlex || '\u00A0'}</span></Col>}
        { flexSummary.vacationFlexRow.deductions && <Col data-title="Payroll Deductions Per Pay"><span className="visuallyhidden">{deductionsTitle}</span><span>{flexSummary.vacationFlexRow.deductions || '\u00A0'}</span></Col>}
        { flexSummary.vacationFlexRow.salesTax && <Col data-title="Sales Tax"><span className="visuallyhidden">{salesTaxTitle}</span><span>{flexSummary.vacationFlexRow.salesTax || '\u00A0'}</span></Col>}
        { flexSummary.vacationFlexRow.earnedFlex && <Col data-title="Earned Flex"><span className="visuallyhidden">{earnedFlexTitle}</span><span>{flexSummary.vacationFlexRow.earnedFlex || '\u00A0'}</span></Col>}
      </Row>
    );

    return (
      <div className="enrollmentsummary tabtwo clearfix">
        { pageTitle && 
          <h1 className='caption clearfix' >{pageTitle}</h1>
        }
        { flexDescription && <div className="pb-4">
        {/* <span className="d-block" role='alert' aria-label={flexDescription} dangerouslySetInnerHTML={{__html: flexDescription}} /> */}
            <Fragment><span className="d-block" dangerouslySetInnerHTML={{__html: flexDescription}} /></Fragment>
        </div>}
        { flexDescriptionProrating && <div className="alertnote pb-4">
          <Fragment> <span className="d-block" role='alert'  dangerouslySetInnerHTML={{__html: flexDescriptionProrating}} /></Fragment>
        </div>}
        { flexDescriptionNoRemaining && <div className="alertnote pb-4">
            <Fragment><span className="d-block" role='alert'  dangerouslySetInnerHTML={{__html: flexDescriptionNoRemaining}} /></Fragment>
        </div>}
        { excessFlex && <div className="alertnote pb-4">
            <Fragment><span className="d-block" role='alert'  dangerouslySetInnerHTML={{__html: excessFlex}} /></Fragment>
        </div>}
        { proratedFlex && <div className="alertnote pb-4">
            <Fragment><span className="d-block" role='alert'  dangerouslySetInnerHTML={{__html: proratedFlex}} /></Fragment>
        </div>}

        <Card className={`ml-summaryflex column-${flexColumnCount}`}>
          {flexRows.length > 0 && <>
            <Card.Header className="p-3">
              <Row className="singleitem">
                { benefitTitle && <Col data-title="Allocations To">{benefitTitle}</Col>}
                { assignedFlexTitle && <Col data-title="Amount Applied">{assignedFlexTitle}</Col>}
                { deductionsTitle && <Col data-title="Payroll Deductions Per Pay">{deductionsTitle}</Col>}
                { salesTaxTitle && <Col data-title="Sales Tax">{salesTaxTitle}</Col>}
                { earnedFlexTitle && <Col data-title="Earned Flex">{earnedFlexTitle}</Col>}
              </Row>
            </Card.Header>
            <Card.Body className="p-3">
              {flexRows} 
              {matchRows.length > 0 && matchRows}
              { (vacationFlexRow && flexSummary.vacationFlexRow.flexBenefit) && vacationFlexRow}
            </Card.Body>
          </>}
          { (flexAdjustmentText || wellness || proratedNote || disclaimerText || endOfYearNote || endOfYearNoteList || endOfYearNoteEnd || isEditButtonVisible) &&
            <Card.Footer className="p-3">
            {flexAdjustmentText && <Row><Col className="col-12"><div className="alert alert-warning">{flexAdjustmentText}</div></Col> </Row>}
            {wellness && <Row><Col className="col-12"><div className="alert alert-warning" >{wellness}</div></Col> </Row>}
            {proratedNote && <Row><Col className="col-12"><div className="alert alert-danger" >{proratedNote}</div></Col> </Row>}
            {disclaimerText && <Row><Col className="col-12"><div className="alert alert-warning"  dangerouslySetInnerHTML={{__html: disclaimerText}}/></Col> </Row>}
            {endOfYearNote && <Row><Col className="col-12"><div className="alert alert-warning">{endOfYearNote}</div></Col> </Row>}
            {endOfYearNoteList && <Row><Col className="col-12"><div className="alert alert-warning">{endOfYearNoteList}</div></Col> </Row>}
            {endOfYearNoteEnd && <Row><Col className="col-12"><div className="alert alert-warning">{endOfYearNoteEnd}</div></Col> </Row>}
            {isEditButtonVisible && <Row>
              <Col className="">
                <div className="d-flex justify-content-end">
                  <DisplayButton controlId="editAction" key={actionLink} className="outline-secondary" displayName={lblBtnEdit.display}  onClick={() => this.handleEditAction(actionLink)} />
                </div>
              </Col>
            </Row>}
          </Card.Footer>}
        </Card>
      </div>
    )
  }

}
const mapStateToProps = (state) => {
  return {flexSummary: state.userReducer.enrollmentSummaryFlex ? state.userReducer.enrollmentSummaryFlex : {},
          sideBarMenu: state.contentReducer.screens}
};

export default connect(
mapStateToProps,
{ navigatesTo, getSummaryFlex, setPageSetting }
)(withRouter(SummaryFlex));