import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import MultiRadioComponent from "../MultiRadioComponent";
import { Row, Form } from "react-bootstrap";
import { Modal, ModalBody } from "reactstrap";
import {
  getDisplayText,
  makeFlexitDateObj,
  setModalAttribute,
  getControlId,
} from "../../utils";
import DateComponent from "../Calender";
import { trackPromise } from "react-promise-tracker";
import { Col } from "reactstrap";
import CheckBoxWithToolTipComponent from "./CheckBoxWithToolTipComponent";
import DisplayButton from "../button";
import NewMultiRadioComponent from "../NewMultiRadioComponent";
import _ from "underscore";

export const FormErrors = ({ formErrors }) => (
  <div className="formErrors">
    {Object.keys(formErrors).map((fieldName, i) => {
      if (formErrors[fieldName].length > 0) {
        return (
          <p className="formErrorPStyle" key={i} role="alert">
            {formErrors[fieldName]}
          </p>
        );
      } else {
        return "";
      }
    })}
  </div>
);

class NewDependentFrom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dependent: {
        firstName: "",
        lastName: "",
        initial: "",
        dateOfBirth: {
          day: null,
          month: null,
          year: null,
        },
        gender: "",
        smokerStatus: 2,
        status: 0,
        relationshipID: parseInt(this.props.relationshipList[0].value),
        dependentStatus: 0,
        isCoveredThisPlan: true,
        isCoveredOtherPlan: false,
        hasDeclaredSmokerStatus: true,
        isStudentStatusConfirmed: false,
        isOverageFullTimeStudent: false,
      },
      formErrors: { firstName: "", lastName: "", initial: "" },
      firstNameValid: false,
      lastNameValid: false,
      initialValid: false,
      studentValid: false,
      showDesc: false,
      descriptiuon: "",
      studentDeclarationState: 0,
      errorMessage: [],
      formValidated: false,
      descTitle: "",
      isDisabled: false,
    };

    this.toggleDescription = this.toggleDescription.bind(this);
    this.changeCoverUnder = this.changeCoverUnder.bind(this);
  }

  setValue = (e) => {
    const { name, value } = e.target;
    let dependent = { ...this.state.dependent };
    console.log(name);
    console.log(value);
    if (name === "smokerStatus") {
      dependent[name] = parseInt(value);
    } else {
      dependent[name] = value;
    }
    this.setState({ dependent, isDisabled: false }, () => {
      this.validateField(name, value);
    });
  };

  invalidateStudentDeclaration = () => {
    this.setState(
      {
        studentValid: false,
      },
      this.validateForm
    );
  };
  infoClick = (info) => {
    return <span>{info}</span>;
  };

  toggleDescription = (info, title) => {
    this.setState({
      showDesc: !this.state.showDesc,
      description: info,
      descTitle: title,
    });
  };

  handleClose = () => {
    this.setState({ showDesc: false });
  };

  validateStudentDeclaration = () => {
    this.setState(
      {
        studentValid: true,
      },
      this.validateForm
    );
  };
  studentCheckbox = (e) => {
    let dependent = this.state.dependent;
    dependent.isOverageFullTimeStudent =
      parseInt(e.target.value) === 1 ? true : false;

    this.setState({ studentDeclarationState: e.target.value, dependent });

    if (parseInt(e.target.value) === 1) this.validateStudentDeclaration();
    if (parseInt(e.target.value) === 2) this.invalidateStudentDeclaration();
  };
  changeStudentStatus = (e) => {
    let dependent = { ...this.state.dependent };
    const { name } = e.target;
    dependent[name] = !dependent[name];
    this.setState({ dependent });
  };
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let validationforFirstName = this.state.firstNameValid;
    let validationforInitial = this.state.initialValid;
    let validationforLastName = this.state.lastNameValid;
    const regInitialErrorMessage = getDisplayText(
      this.props.contents,
      "regInitialErrorMessage"
    );
    const regFirstNameErrorMessage = getDisplayText(
      this.props.contents,
      "regFirstNameErrorMessage"
    );
    const regLastNameErrorMessage = getDisplayText(
      this.props.contents,
      "regLastNameErrorMessage"
    );

    switch (fieldName) {
      case "firstName":
        validationforFirstName = value.match(
          this.props.dependents.validationforFirstName
        );
        fieldValidationErrors.firstName = validationforFirstName
          ? ""
          : regFirstNameErrorMessage.display;
        break;
      case "initial":
        validationforInitial = value.match(
          this.props.dependents.validationforInitial
        );
        fieldValidationErrors.initial = validationforInitial
          ? ""
          : regInitialErrorMessage.display;
        break;
      case "lastName":
        validationforLastName = value.match(
          this.props.dependents.validationforLastName
        );
        fieldValidationErrors.lastName = validationforLastName
          ? ""
          : regLastNameErrorMessage.display;
        break;

      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        firstNameValid: validationforFirstName,
        initialValid: validationforInitial,
        lastNameValid: validationforLastName,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.firstNameValid &&
        this.state.initialValid &&
        this.state.lastNameValid &&
        this.state.studentValid,
    });
  }

  changeCoverUnder = (coverName) => {
    let dependent = { ...this.state.dependent };
    dependent[coverName] = !dependent[coverName];
    this.setState({ dependent });
  };

  changeRelationID = (e) => {
    const studentIDs = this.props.relationshipList.filter(
      (val) => val.type === "Student"
    );

    let dependent = { ...this.state.dependent };
    dependent["relationshipID"] = parseInt(e.target.value);
    this.setState({ dependent });

    if (studentIDs.filter((x) => x.value.includes(e.target.value)).length > 0) {
      this.invalidateStudentDeclaration();
    } else {
      this.validateStudentDeclaration();
    }
  };

  changeDate = (e) => {
    let dependent = { ...this.state.dependent };
    dependent["dateOfBirth"] = makeFlexitDateObj(e);
    this.setState({ dependent });
  };

  createDependent = (e) => {
    e.preventDefault();
    this.setState({ isDisabled: true });
    const { relationshipList, navigationError } = this.props;
    let dependent = { ...this.state.dependent };
    // console.log(dependent.dateOfBirth);
    const relationshipID = dependent.relationshipID.toString();
    let spouseList = [];
    relationshipList.map((item) => {
      if (item.type === "Spouse") {
        spouseList.push(item.value);
      }
      return null;
    });
    const checkDOB =
      dependent.dateOfBirth.year === null ||
      dependent.dateOfBirth.year === "" ||
      dependent.dateOfBirth.year === "NaN" ||
      dependent.dateOfBirth.year.length < 4;
    if (dependent.firstName === "") {
      ReactDOM.findDOMNode(this.refs.firstName) &&
        ReactDOM.findDOMNode(this.refs.firstName).focus();
    } else if (dependent.lastName === "") {
      ReactDOM.findDOMNode(this.refs.lastName) &&
        ReactDOM.findDOMNode(this.refs.lastName).focus();
    } else if (checkDOB) {
      console.log(dependent.dateOfBirth.year);
      ReactDOM.findDOMNode(this.refs.dob) &&
        ReactDOM.findDOMNode(this.refs.dob).focus();
    } else if (dependent.gender === "") {
      ReactDOM.findDOMNode(this.refs.gender) &&
        ReactDOM.findDOMNode(this.refs.gender).focus();
    } else if (
      spouseList.includes(relationshipID) &&
      dependent.smokerStatus === 2 &&
      this.props.dependents.spousalSmokerDeclarationOptional === false
    ) {
      ReactDOM.findDOMNode(this.refs.smokerStatus) &&
        ReactDOM.findDOMNode(this.refs.smokerStatus).focus();
    }
    if (
      dependent.firstName === "" ||
      dependent.lastName === "" ||
      checkDOB ||
      dependent.gender === ""
    )
      this.setState({ formValidated: true });
    else if (
      spouseList.includes(relationshipID) &&
      dependent.smokerStatus === 2 &&
      this.props.dependents.spousalSmokerDeclarationOptional === false
    ) {
      this.setState({ formValidated: true });
    } else {
      if (!spouseList.includes(relationshipID)) {
        dependent.smokerStatus = 2;
      }
      dependent["fullName"] = `${dependent.firstName} ${dependent.lastName}`;
      dependent["dateOfBirth"].month = dependent["dateOfBirth"].month;
      //this.props.validate(this)
      trackPromise(
        this.props.createDependent(
          dependent,
          (err) => {
            dependent["dateOfBirth"].month = dependent["dateOfBirth"].month;
            this.setState({ errorMessage: err });
            if (this.state.errorMessage && this.refs.formErrorMessage) {
              ReactDOM.findDOMNode(this.refs.formErrorMessage).focus();
              this.setState({ isDisabled: false });
            }
          },
          () => {
            trackPromise(this.props.getDependents());
            this.props.onClose();
            if (!(navigationError === undefined || navigationError === null)) {
              this.props.setNavigationError(null);
            }
          }
        )
      );
    }
  };

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }

  handleDateChange = (dob) => {
    let dependent = { ...this.state.dependent };
    let dateOfBirth = { ...dob };
    if (dateOfBirth.year === "NaN" || dateOfBirth.year === "") {
      dateOfBirth.day = null;
      dateOfBirth.month = null;
      dateOfBirth.year = null;
    }
    console.log(dateOfBirth);
    dependent["dateOfBirth"] = dateOfBirth;
    // dependent['dateOfBirth'] = dob

    /* if(dob.year === null || dob.year === "" || dob.year === "NaN") {
      this.setState({formValidated: false});
    } else {
      this.setState({formValidated: true});
    } */
    this.setState({ dependent, isDisabled: false });
  };

  render() {
    const { dependent, formValidated, studentDeclarationState } = this.state;
    const { smokerDeclarationLabel, selectedDateDisplay } =
      this.props.dependents;
    const { relationshipID } = this.state.dependent;
    const { contents, relationshipList, localestring } = this.props;
    const lblDepSmokerText = getDisplayText(
      this.props.contents,
      "lblDepSmokerText"
    );
    const lblRelationshipText = getDisplayText(
      this.props.contents,
      "lblRelationshipText"
    );
    const lblGenderText = getDisplayText(this.props.contents, "lblGenderText");

    const effectiveDate = getDisplayText(this.props.contents, "effectiveDate");
    const isEffectiveDate =
      _.findIndex(this.props.contents, { keyName: "effectiveDate" }) === -1
        ? false
        : true;

    const lblEffectiveDateText = getDisplayText(
      this.props.contents,
      "lblEffectiveDateText"
    );
    const lblCoveredThisPlanCaptionText = getDisplayText(
      this.props.contents,
      "lblCoveredThisPlanCaptionText"
    );
    const lblCoveredOtherPlanCaptionText = getDisplayText(
      this.props.contents,
      "lblCoveredOtherPlanCaptionText"
    );
    // const coverUnderThisPlan= [{text:lblCoveredThisPlanCaptionText.display, value:'isCoveredThisPlan'}]
    // const coverUnderOtherPlan = [{text:lblCoveredOtherPlanCaptionText.display, value:'isCoveredOtherPlan'}]
    const lblBirthDateText = getDisplayText(
      this.props.contents,
      "lblBirthDateText"
    );
    const lblFirstNameText = getDisplayText(
      this.props.contents,
      "lblFirstNameText"
    );
    const lblInitialText = getDisplayText(
      this.props.contents,
      "lblInitialText"
    );
    const lblLastNameText = getDisplayText(
      this.props.contents,
      "lblLastNameText"
    );
    const lblStudentDeclarationText = getDisplayText(
      this.props.contents,
      "lblStudentDeclarationText"
    );
    const btnSave = getDisplayText(this.props.contents, "btnSave");
    const btnCancel = getDisplayText(
      (this.props.contentFooter.contentResource &&
        this.props.contentFooter.contentResource.contents) ||
        {},
      "btnCancel"
    );
    const lblYes = getDisplayText(
      (this.props.contentFooter.contentResource &&
        this.props.contentFooter.contentResource.contents) ||
        {},
      "lblYes"
    );
    const lblNo = getDisplayText(
      (this.props.contentFooter.contentResource &&
        this.props.contentFooter.contentResource.contents) ||
        {},
      "lblNo"
    );
    const toolTipIcon = getDisplayText(
      (this.props.contentFooter.contentResource &&
        this.props.contentFooter.contentResource.contents) ||
        {},
      "ToolTipIcon"
    );
    const closeButtonText = getDisplayText(
      (this.props.contentFooter.contentResource &&
        this.props.contentFooter.contentResource.contents) ||
        {},
      "btnClose"
    );
    const lblDepedendentFirstNamePlaceHolder = getDisplayText(
      contents,
      "lblDepedendentFirstNamePlaceHolder"
    );
    const lblDependentLastNamePlaceHolder = getDisplayText(
      contents,
      "lblDependentLastNamePlaceHolder"
    );
    const lblDependentInitialPlaceHolder = getDisplayText(
      contents,
      "lblDependentInitialPlaceHolder"
    );
    const lblDatePlaceHolder = getDisplayText(contents, "lblDatePlaceHolder");
    const rfvFirstNameErrorMessage = getDisplayText(
      contents,
      "rfvFirstNameErrorMessage"
    );
    const rfvLastNameErrorMessage = getDisplayText(
      contents,
      "rfvLastNameErrorMessage"
    );
    const lblBirthDateRequiredText = getDisplayText(
      contents,
      "lblBirthDateRequiredText"
    );
    const cmpGenderErrorMessage = getDisplayText(
      contents,
      "cmpGenderErrorMessage"
    );
    const custDepSmokerErrorMessage = getDisplayText(
      contents,
      "custDepSmokerErrorMessage"
    );
    const custStudentErrorMessage = getDisplayText(
      contents,
      "custStudentErrorMessage"
    );
    const lblYouCantAddSpouse = getDisplayText(contents, "lblYouCantAddSpouse");
    const isCoveredThisPlanEditable =
      this.props.dependents.isCoveredThisPlanEditable;
    const isCoveredOtherPlanEditable =
      this.props.dependents.isCoveredOtherPlanEditable;
    const tooltipCoveredThisPlan = this.props.dependents.tooltipCoveredThisPlan;
    const tooltipCoveredOtherPlan =
      this.props.dependents.tooltipCoveredOtherPlan;
    const isAllowUpdateSudentStatus = this.props.isAllowUpdateSudentStatus;
    const canSpouseAdded = this.props.dependents.canSpouseAdded;
    const isValidStudent = this.props.relationshipList.filter(
      (val) =>
        val.type === "Student" &&
        val.value === this.state.dependent.relationshipID.toString()
    );
    const studentID = (isValidStudent && isValidStudent.value) || -1;
    // const isValidSpouse = this.props.relationshipList.find( val =>  val.type === 'Spouse')
    // const spouseID = (isValidSpouse && isValidSpouse.value) || -1;
    // const isValid = this.props.relationshipList.find( val =>  val.type === 'Spouse' && val.value !== spouseID)
    // const commonlawID = (isValid && isValid.value) || -1;
    const lblStudentNotAllowedText = getDisplayText(
      contents,
      "lblStudentNotAllowedText"
    );
    const studentDeclarationYes = [
      { text: lblYes.display, value: 1 },
      { text: lblNo.display, value: 2 },
    ];
    const childCanTurnStudent = [
      { text: lblStudentDeclarationText.display, value: "isChildTurnStudent" },
    ];
    let spouseList = [];
    relationshipList.map((item) => {
      if (item.type === "Spouse") {
        spouseList.push(item.value);
      }
      return null;
    });
    const checkDOB =
      dependent.dateOfBirth.year === null ||
      dependent.dateOfBirth.year === "" ||
      dependent.dateOfBirth.year === "NaN" ||
      dependent.dateOfBirth.year.length < 4;

    return (
      <>
        <Row>
          <div className="ml-errors px-3">
            <FormErrors role="alert" formErrors={this.state.formErrors} />
          </div>
        </Row>
        <Row className="firstinfobox">
          {this.state.errorMessage.length > 0 ? (
            typeof this.state.errorMessage === "string" ? (
              <div className="col-12">
                <p
                  className="formErrorPStyle"
                  tabIndex="-1"
                  ref="formErrorMessage"
                  role="alert"
                >
                  {this.state.errorMessage}
                </p>
              </div>
            ) : (
              this.state.errorMessage.map((error, i) => (
                <div key={i} className="col-12">
                  <p
                    className="formErrorPStyle"
                    tabIndex="-1"
                    ref="formErrorMessage"
                    role="alert"
                  >
                    {error}
                  </p>
                </div>
              ))
            )
          ) : (
            ""
          )}
          <div
            className={`ml-inputfields ml-firstname ${this.errorClass(
              this.state.formErrors.firstName
            )}`}
          >
            <Form noValidate validated={this.state.formValidated}>
              <label
                htmlFor={getControlId(
                  lblDepedendentFirstNamePlaceHolder.textToRead
                )}
              >
                <span className="ml-mandatory-sign">*</span>{" "}
                {lblFirstNameText.display}
              </label>
              <Form.Control
                maxLength={50}
                mode="edit"
                required
                type="text"
                id={getControlId(lblDepedendentFirstNamePlaceHolder.textToRead)}
                ref="firstName"
                placeholder={lblDepedendentFirstNamePlaceHolder.display}
                name="firstName"
                onChange={this.setValue}
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              />
              <Form.Control.Feedback type="invalid" role="alert">
                {rfvFirstNameErrorMessage.display}
              </Form.Control.Feedback>
            </Form>
          </div>
          <div
            className={`ml-inputfields ml-initial ${this.errorClass(
              this.state.formErrors.initial
            )}`}
          >
            <label
              htmlFor={getControlId(lblDependentInitialPlaceHolder.textToRead)}
            >
              {lblInitialText.display}
            </label>
            <Form.Control
              maxLength={1}
              mode="edit"
              type="text"
              id={getControlId(lblDependentInitialPlaceHolder.textToRead)}
              placeholder={lblDependentInitialPlaceHolder.display}
              name="initial"
              onChange={this.setValue}
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
            />
          </div>
          <div
            className={`ml-inputfields ml-lastname ${this.errorClass(
              this.state.formErrors.lastName
            )}`}
          >
            <Form noValidate validated={this.state.formValidated}>
              <label
                htmlFor={getControlId(
                  lblDependentLastNamePlaceHolder.textToRead
                )}
              >
                <span className="ml-mandatory-sign">*</span>{" "}
                {lblLastNameText.display}
              </label>
              <Form.Control
                maxLength={50}
                mode="edit"
                required
                type="text"
                id={getControlId(lblDependentLastNamePlaceHolder.textToRead)}
                ref="lastName"
                placeholder={lblDependentLastNamePlaceHolder.display}
                name="lastName"
                onChange={this.setValue}
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              />
              <Form.Control.Feedback role="alert" type="invalid">
                {rfvLastNameErrorMessage.display}
              </Form.Control.Feedback>
            </Form>
          </div>
        </Row>
        <Row>
          <div className={`ml-popupsection ml-newdependent`}>
            <div className="clearfix dependenttypes">
              <NewMultiRadioComponent
                component="newDependent"
                name="newDependentRelationshipID"
                vertical="true"
                selected={this.state.dependent.relationshipID.toString() || 2}
                display={lblRelationshipText.display}
                options={this.props.relationshipList}
                onChange={this.changeRelationID}
              />
            </div>
            <div className={`clearfix`}></div>

            {isValidStudent?.length > 0 ? (
              <>
                {!isAllowUpdateSudentStatus ? (
                  <>
                    <NewMultiRadioComponent
                      component="newDependent"
                      type="radio"
                      display={`<span className="ml-mandatory-sign">*</span> ${lblStudentDeclarationText.display}`}
                      selected={this.state.studentDeclarationState}
                      options={studentDeclarationYes}
                      keyy={`${this.props.keyy}-student`}
                      tid="studentCheckbox"
                      onChange={this.studentCheckbox}
                    />
                  </>
                ) : (
                  <MultiRadioComponent
                    component={"isChildCanTurnStudent"}
                    width="100"
                    name={"isStudentStatusConfirmed"}
                    checked={this.state.dependent.isStudentStatusConfirmed}
                    type="checkbox"
                    options={childCanTurnStudent}
                    keyy={`${this.props.keyy}-status`}
                    tid="changeStudentStatus"
                    onChange={this.changeStudentStatus}
                  />
                )}
                {parseInt(this.state.studentDeclarationState) === 2 && (
                  <span className={`clearfix d-block pb-3 pl-3`}>
                    {lblStudentNotAllowedText.display}
                  </span>
                )}
                {this.state.formValidated === true &&
                studentDeclarationState === 0 ? (
                  <div className="invalid-feedback d-block pl-3" role="alert">
                    {custStudentErrorMessage.display}
                  </div>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </div>
          {canSpouseAdded && (
            <div className={`alert alert-info`}>
              <Fragment>
                <p
                  className={`m-0`}
                  role="alert"
                  dangerouslySetInnerHTML={{
                    __html: lblYouCantAddSpouse.display,
                  }}
                />
              </Fragment>
            </div>
          )}
        </Row>
        <Row>
          <div className={`ml-popupsection`}>
            <div className={`ml-inputfields ml-dateofbirth form-group`}>
              <div className={`position-relative clearfix`}>
                <DateComponent
                  ref="dob"
                  name="dateOfBirth"
                  type="text"
                  placeholder={lblDatePlaceHolder.display}
                  display={`<span className="ml-mandatory-sign">*</span> ${lblBirthDateText.display}`}
                  mode="edit"
                  date={this.state.dependent.dateOfBirth}
                  // onChange={this.changeDate}
                  onChange={this.handleDateChange}
                  checkdob={checkDOB.toString()}
                  formvalidated={formValidated.toString()}
                  localestring={localestring}
                  isreadonly="true"
                  format={
                    selectedDateDisplay ? selectedDateDisplay : "MM/dd/yyyy"
                  }
                />
              </div>
              {checkDOB && formValidated === true ? (
                <div className="invalid-feedback d-block w-100" role="alert">
                  {lblBirthDateRequiredText.display}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className={`ml-popupsection`}>
            <div className={`ml-popupgender form-group`}>
              <NewMultiRadioComponent
                ref="gender"
                component="newDependent"
                name="gender"
                display={`<span className="ml-mandatory-sign">*</span> ${lblGenderText.display}`}
                selected={this.state.dependent.gender.toString()}
                options={this.props.genderList}
                keyy={`${this.props.keyy}-gender`}
                onChange={this.setValue}
              />
              <div className="ml-genderfeedback">
                <div className="clearfix"></div>
                {dependent.gender === "" && formValidated === true ? (
                  <div className="invalid-feedback d-block" role="alert">
                    {cmpGenderErrorMessage.display}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          {spouseList.includes(relationshipID.toString()) &&
          this.props.dependents.spousalSmokerDeclarationOptional === false ? (
            <div className={`ml-popupsection`}>
              <div className={`ml-popupsmokerstatus form-group`}>
                <NewMultiRadioComponent
                  ref="smokerStatus"
                  component="newDependent"
                  name="smokerStatus"
                  selected={this.state.dependent.smokerStatus.toString()}
                  display={`<span className="ml-mandatory-sign">*</span> ${lblDepSmokerText.display}`}
                  options={this.props.smokerStatusList}
                  keyy={`${this.props.keyy}-smoker-status`}
                  onChange={this.setValue}
                />
                <div className="clearfix"></div>
                {dependent.smokerStatus === 2 && formValidated === true ? (
                  <div
                    className="invalid-feedback d-block mt-0 ml-3"
                    role="alert"
                  >
                    {custDepSmokerErrorMessage.display}
                  </div>
                ) : (
                  ""
                )}
                {dependent.smokerStatus === 0 && (
                  <div className="col">
                    <label className="alert alert-primary" role="alert">
                      {smokerDeclarationLabel}
                    </label>
                  </div>
                )}
              </div>
            </div>
          ) : (
            ""
          )}
          <div className={`ml-popupsection clearfix`}>
            <div className={`ml-popupplan form-group paddingonmobile`}>
              <div className="row">
                {isCoveredThisPlanEditable && (
                  <div className={`col-6 position-relative withinfoicon p-0`}>
                    <CheckBoxWithToolTipComponent
                      id="newDependent-isCoveredThisPlan"
                      value="isCoveredThisPlan"
                      ariaLabel={lblCoveredThisPlanCaptionText.textToRead}
                      displayLabel={lblCoveredThisPlanCaptionText.display}
                      checked={this.state.dependent.isCoveredThisPlan}
                      onChange={() =>
                        this.changeCoverUnder("isCoveredThisPlan")
                      }
                      isToolTip={tooltipCoveredThisPlan !== ""}
                      toolTipAriaLabel={toolTipIcon.textToRead}
                      toolTipOnClick={() =>
                        this.toggleDescription(
                          tooltipCoveredThisPlan,
                          lblCoveredThisPlanCaptionText
                        )
                      }
                    />
                  </div>
                )}
                {isCoveredOtherPlanEditable === true ? (
                  <div className={`col-6 position-relative withinfoicon p-0`}>
                    <CheckBoxWithToolTipComponent
                      id="newDependent-isCoveredOtherPlan"
                      value="isCoveredOtherPlan"
                      ariaLabel={lblCoveredOtherPlanCaptionText.textToRead}
                      displayLabel={lblCoveredOtherPlanCaptionText.display}
                      checked={this.state.dependent.isCoveredOtherPlan}
                      onChange={() =>
                        this.changeCoverUnder("isCoveredOtherPlan")
                      }
                      isToolTip={tooltipCoveredOtherPlan !== ""}
                      toolTipAriaLabel={toolTipIcon.textToRead}
                      toolTipOnClick={() =>
                        this.toggleDescription(
                          tooltipCoveredOtherPlan,
                          lblCoveredOtherPlanCaptionText
                        )
                      }
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </Row>
        {isEffectiveDate && (
          <div className="clearfix">
            <Col className="col-12 p-0">
              <div>
                {lblEffectiveDateText.display}
                {effectiveDate.display}
              </div>
            </Col>
            {/* <div className="ml-popupbuttons d-flex justify-content-between pb-3 pt-2">
        </div> */}
          </div>
        )}
        <Row className="ml-popupbuttons justify-content-between pt-3 border-top">
          <DisplayButton
            displayName={btnCancel.display}
            onClick={this.props.onClose}
            className="outline-secondary btn-cancel"
          />
          <DisplayButton
            controlId="createDependentBtn"
            displayName={btnSave.display}
            onClick={this.createDependent}
            disabled={this.state.isDisabled}
            className="outline-secondary btn-save"
          />
        </Row>

        <Modal
          onOpened={() =>
            setModalAttribute(
              this.state.descTitle
                ? this.state.descTitle.textToRead
                : "infopopup"
            )
          }
          className="infopopup"
          isOpen={this.state.showDesc}
          toggle={this.handleClose}
          aria-labelledby="modalHeading"
          centered
        >
          <div className="modal-header">
            <h1 className="modal-title" id="modalHeading">
              {this.state.descTitle ? this.state.descTitle.display : "\u00A0"}
            </h1>
            <DisplayButton
              type="button"
              className="close"
              displayName="×"
              arialabel={closeButtonText.display}
              onClick={this.handleClose}
            />
          </div>
          <ModalBody>
            <Fragment>
              <div
                dangerouslySetInnerHTML={{ __html: this.state.description }}
              />
            </Fragment>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default NewDependentFrom;
