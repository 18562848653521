import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getSummaryBeneficiaries, setPageSetting, setFlexBeneficiaryID } from '../actions/userActions';
import { dissmiss } from '../actions/contentActions'
import { faChevronLeft} from '@fortawesome/free-solid-svg-icons';
import { Table, Card } from 'react-bootstrap';
import { getDisplayText } from '../utils';
import { trackPromise } from 'react-promise-tracker';
import DisplayButton from './button';

import _ from 'underscore';

class SummaryBeneficiaries extends Component {
    componentDidMount() {
        trackPromise(this.props.getSummaryBeneficiaries())
    }

    componentWillUnmount(){
        this.props.dissmiss()
    }
    
    handleEditAction = (link, linkID) => {
        const benefitName = link.split("/")[0];
        let actionLink = `${benefitName}/${linkID}`.toUpperCase();
        if(link.includes("Flex?")) {
            actionLink = link
        }
        let menuItem
        if (this.props.deviceType === "Desktop") {
            _.each(this.props.sideBarMenu, (item, index) => {
                if (item.link instanceof Array) {
                    if(link.includes("Flex?") && item.link === actionLink) {
                        menuItem = item
                        return
                    } else
                    {
                        let obj = _.where(item.link, {"actinLink": actionLink})
                        if (obj && obj.length !== 0) {
                            menuItem = item
                            return
                        }
                    }    
                } else if(link.includes("Flex?") && item.link === actionLink) {
                    menuItem = item
                    return
                }
            })
            this.props.setPageSetting(menuItem ? menuItem.name : "WELCOME", true, true);
            this.props.setFlexBeneficiaryID(linkID, () => {
                this.props.history.push("/Beneficiaries")
            })
        } else {
            menuItem = _.find(this.props.sideBarMenu, item => (item.link || "").toUpperCase() === (actionLink || "").toUpperCase())
            this.props.setPageSetting(menuItem ? menuItem.name : "WELCOME", true, false, () => {
                this.props.history.push("/Beneficiaries");
            });   
        }
    }

    onDownKeyPressed = (event, editButtonLink, editButtonLinkID) => {
        switch(event.which){
            case 13: {
                this.handleEditAction(editButtonLink,editButtonLinkID)
                break;
            }
            default:
                break;
        }
    }

    getTotalPer = (beneficiary) => {
        let newTotal = 0
        if((beneficiary || {}).length > 0){
            beneficiary.map(item => {
                return newTotal = item.percentage + newTotal
            })
        }
        return isNaN(newTotal) ? "" : newTotal
    }

    renderBeneficiarieList(editButtonText,title, note, editButton, contents, list, customClass) {
        if (list && list.length > 0) {
            const thcName = getDisplayText(contents, 'thcName');
            const thcRelationship = getDisplayText(contents, 'thcFormRelationship');
            const thcTrustee = getDisplayText(contents, 'thcTrustee');
            const thcPercent = getDisplayText(contents, 'thcPercent');
            const thcTotal = getDisplayText(contents, 'thcTotal');
            const items = []
            _.forEach(list, (item, index) => {
                items.push(
                    <tr key={`div-${title}-${editButton.linkID}-ID-${item.beneficiaryName}`}>
                        <td dangerouslySetInnerHTML={{ __html: `<strong>${item.beneficiaryName}</strong><br />${item.dateOfBirth ? item.dateOfBirth : "n/a"}`}} />
                        <td dangerouslySetInnerHTML={{ __html: item.relationShip ? item.relationShip : "n/a"}} />
                        <td dangerouslySetInnerHTML={{ __html: item.trustee ? item.trustee : "n/a"}} />{(item.removedMsg==="" || item.removedMsg==null)?<td className="text-right">{item.percentage}%</td>:<td><span className="ml-mandatory-sign">({item.removedMsg})</span> {item.percentage}%</td>}
                    </tr>
                )
            })
            /* _.forEach(list, (item) => {
                items.push(
                    <div key={`div-${title}-${editButton.linkID}-ID-${item.benefitName}-${Math.random()}`} className="d-flex justify-content-between bgrow">
                        
                            <div> */
                                {/* <span 
                                    tid="click"
                                    onClick={() => {this.handleEditAction(editButton.link, editButton.linkID)}}
                                    tabIndex='0'
                                    role='button'
                                    aria-label={editButtonText}
                                    onKeyDown={(event) => {this.onDownKeyPressed(event,editButton.link,editButton.linkID)}}>
                                    <FontAwesomeIcon className="icon-action" 
                                        style={ !editButton.enabled && {opacity: 0.5, pointerEvents: "none"}}
                                        icon={faPlusCircle}/>
                                </span> */}
                                {/* <span>{item.beneficiaryName}</span>
                            </div>
                            {(item.removedMsg==="" || item.removedMsg==null)?<div>{item.percent}</div>:
                            <div><span className="ml-mandatory-sign">({item.removedMsg})</span> {item.percent}</div>}
                        
                    </div>
                )
            }) */}
            return (
                <Fragment>
                    <div className="tbl-beneficiaries">
                        <h3 className="title">{title}</h3>
                        <Table>
                        <thead>
                            <tr>
                            <th scope="col">{thcName.display}</th>
                            <th scope="col">{thcRelationship.display}</th>
                            <th scope="col">{thcTrustee.display}</th>
                            <th scope="col">{thcPercent.display}</th>
                            </tr>
                        </thead>
                        <tbody>{items}</tbody>
                        <tfoot>
                            <tr>
                            <td colSpan="3" className="text-right no-right-border">
                                <strong>{thcTotal.display}</strong>
                            </td>
                            <td>
                                <strong className="m-0" >
                                {this.getTotalPer( (list || []).length > 0 ? list : 0)}%
                                </strong>
                            </td>
                            </tr>
                        </tfoot>
                        </Table>
                    </div>
                {/* <Card className={`ccard ${customClass}`}>
                    <Card.Header>
                        <div className="d-flex justify-content-between">
                            <div><h2>{title}</h2></div>
                            <div><h2>{thcPercent.display}</h2></div>
                        </div>
                    </Card.Header>
                    <Card.Body>{items}</Card.Body>
                    {note && <Card.Footer><div className="alert alert-primary" role='alert'>{title} <strong>{note}</strong></div></Card.Footer>}
                </Card> */}
                </Fragment>
            )
        }

        return null;
    }

    renderNoBeneficiariesMessage(title, message, thcPercent) {

        return (
            <Card className="ccard onfilebeneficiaries">
                <Card.Header>
                    <div className="d-flex justify-content-between">
                        <div><h2>{title}</h2></div>
                        <div><h2>{thcPercent.display}</h2></div>
                    </div>
                </Card.Header>
                <Card.Body><Fragment><div className="bgrow"  dangerouslySetInnerHTML={{__html: message}} /></Fragment></Card.Body>
            </Card> 
        );
    }

    render() {
        const pageTitle = this.props.summaryBeneficiaries.tabName
        const beneficiaryBenefitsInfo = this.props.summaryBeneficiaries.beneficiaryBenefitsInfo ? this.props.summaryBeneficiaries.beneficiaryBenefitsInfo : []
        const content = this.props.summaryBeneficiaries.contentResource ? this.props.summaryBeneficiaries.contentResource : {}
        const contents = (content.controlResource ? content.controlResource.Beneficiaries : {})
        const thcPercent = getDisplayText(contents, "thcPercent")
        const beneficiaryMessage = this.props.summaryBeneficiaries.beneficiariesMessage
        const noBeneficiaryBenefits = this.props.summaryBeneficiaries.noBeneficiaryBenefits ? this.props.summaryBeneficiaries.noBeneficiaryBenefits : ""
        const noCoverage = this.props.summaryBeneficiaries.noCoverage ? this.props.summaryBeneficiaries.noCoverage : ""

        const editIcon = getDisplayText((this.props.contentFooter.contentResource && this.props.contentFooter.contentResource.contents) || {}, 'EditIcon')
        const lblEdit = getDisplayText(((content.contents && content.contents) || {}), "btnChange")
        const backButtonText = getDisplayText((this.props.contentFooter.contentResource && this.props.contentFooter.contentResource.contents) || {}, 'btnBack')
        document.title = pageTitle
        const beneficiaryList = [];
        _.forEach(beneficiaryBenefitsInfo, (benefit) => {
            const onFile = this.renderBeneficiarieList(editIcon.textToRead,benefit.lblOnFile, null, benefit.editButton, contents, benefit.beneficiaryCurrentInfo, benefit.noBeneficiariesNote, "onfilebeneficiaries")
            const pending = this.renderBeneficiarieList( editIcon.textToRead,benefit.pending, benefit.pendingNote, benefit.editButton, contents, benefit.beneficiaryPendingInfo, benefit.noBeneficiariesNote, "pendingbeneficiaries")
            // Contingent Beneficiary Summary
            const contingentOnFile = this.renderBeneficiarieList(editIcon.textToRead,benefit.lblContingentOnFile, null, benefit.editButton, contents, benefit.contingentBeneficiaryCurrentInfo, benefit.noBeneficiariesNote, "onfilebeneficiaries")
            const contingentPending = this.renderBeneficiarieList(editIcon.textToRead, benefit.lblContingentPending, benefit.pendingNote, benefit.editButton, contents, benefit.contingentBeneficiaryPendingInfo, benefit.noBeneficiariesNote, "pendingbeneficiaries")
            const noBeneficiariesMessage = this.renderNoBeneficiariesMessage(benefit.lblOnFile, benefit.noBeneficiariesNote, thcPercent)
            beneficiaryList.push(
                <Card key={`div-${benefit.benefitID}-ID-${benefit.benefitName}`} className="mb-4 insurancetype pcard">
                    <Card.Header>
                    <h2 className="heading-white">{benefit.benefitName}</h2>
                    </Card.Header>
                    <Card.Body>
                        { benefit.noBeneficiariesNote ? <div className="clearfix">{noBeneficiariesMessage}</div>
                        : <>
                            {onFile}
                            {contingentOnFile}
                            {pending}
                            {contingentPending}
                        </>
                        }
                        {benefit.editButton.enabled && <DisplayButton 
                            controlId="click" 
                            className="outline-secondary btn-change"
                            displayName={lblEdit.display} 
                            onClick={() => this.handleEditAction(benefit.editButton.link, benefit.editButton.linkID)} />
                                
                        }
                    </Card.Body>
                </Card>
            )
        })
        return (
            <div className="summary summarybeneficiaries beneficiariesall baneficiarylist clearfix">
                <div className={`d-flex titlewithicon mb-3`}> 
                    <DisplayButton className="icon-action" displayName={backButtonText.display} icon={faChevronLeft} onClick={() => { this.props.history.push("/Summary")}} /> 
                </div>
                { noBeneficiaryBenefits && <div className="alert alert-dark" role='alert'>{noBeneficiaryBenefits}</div> }
                { noCoverage && <div className="alert alert-secondary" role='alert'>{noCoverage}</div> }
                { !noCoverage && !noBeneficiaryBenefits && beneficiaryList}
                {beneficiaryMessage && <div className="alert alert-info" role='alert'>
                    {beneficiaryMessage}
                </div>}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {summaryBeneficiaries: state.userReducer.summaryBeneficiaries ? state.userReducer.summaryBeneficiaries : {},
            sideBarMenu: state.contentReducer.screens,
            contentFooter: state.userReducer.contentFooter,
            deviceType: state.userReducer.deviceType || ""
        }
};

export default connect(
    mapStateToProps, { getSummaryBeneficiaries, setPageSetting, dissmiss, setFlexBeneficiaryID }
)(withRouter(SummaryBeneficiaries));

