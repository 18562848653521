import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { connect } from "react-redux";
import { Row, Card, Col, Form } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { getWellness, clearWellnessError } from '../actions/userActions';
import { dissmiss } from '../actions/contentActions';
import { getDisplayText } from '../utils';
import { trackPromise } from 'react-promise-tracker';


import _ from 'underscore';

class BenefitWellness extends Component {
  constructor(props) {
    super(props)
    let answers = []
    _.each(this.props.options, (option, index) => {
      answers[index] = option;
    })
    this.state = { answers }
  }

  UNSAFE_componentWillMount = async () => {
   trackPromise(this.props.getWellness())
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.options !== this.state.answers) {
      const answer = nextProps.options;
      this.setState({answers: answer});
    }
    if(nextProps.errorMessage){
      const node = ReactDOM.findDOMNode(this.refs.errorMessage);
      if(node){
        node.focus();
      }
    }
  }

  componentWillUnmount() {
    this.props.dissmiss()
    this.props.clearWellnessError()
  }

  checkIsTrue = (value) => { //Handle API returning "1" and "0" as Bool
    switch(value.trim().toLowerCase()) {
      case '1':
        return true;
      default:
        return false;
    }
  }

  changeOptionAnswer = (index) => { //Handle Switch Toggle
    let answers = [...this.state.answers]
    answers[index].selectionValue = answers[index].yesSelected ? "0" : "1";
    answers[index].yesSelected = !answers[index].yesSelected

    answers.map(answer => {
      if(answer.questionID !== answers[index].questionID && answer.viewingOrder === answers[index].viewingOrder && answer.linked === answers[index].linked) {
        answer.yesSelected = false
        answer.selectionValue = 0
      }
      return answer;
    })

    if (!answers[index].yesSelected) {
      answers[index].supplementalAnswer = ""
      answers[index].supplementalAnswerEnabled = false
    } else {
      answers[index].supplementalAnswerEnabled = true
    }
    this.setState({ answers });
  }

  changeInput = (e) => { //Handle Supplimental Answer
    let answers = [...this.state.answers]
    answers[e.target.id-1].supplementalAnswer = e.target.value
    this.setState({ answers })
  }

  render () {
    const wellness = this.props.wellness
    const contents = this.props.contents
    // UI Display Lables
    const wellnessQuestionsTitle = wellness.wellnessQuestionsTitle //getDisplayText(wellness, "wellnessQuestionsTitle");
    const noWellnessQuestionstionsNote = wellness.noWellnessQuestionstionsNote //getDisplayText(wellness, "noWellnessQuestionstionsNote");
    const wellnessQuestionsNote = wellness.wellnessQuestionsNote //getDisplayText(wellness, "wellnessQuestionsNote");
    const wellnessConfigMessage = wellness.wellnessConfigMessage
    const wellnessMessages = `${wellnessQuestionsNote ? wellnessQuestionsNote : ""} ${wellnessConfigMessage ? wellnessConfigMessage : ""}`
    const wellnessQuestionCardTitleValueLabel = getDisplayText(contents, "lblValue");
    const lblNoYesHeader = getDisplayText(contents, "lblNoYesHeader")

    const isNoWellnessQuestionsMsg = wellness.isNoWellnessQuestionsMsg //Should display Wellness Question Card

    const props = this.props
    const optionRows = []
    _.each(props.options, (opt, index)=>{
      optionRows.push(
        <div key={`div-${opt.questionID}`} className="card-row">
          <Row>
            <Col className="col-6">{opt.question}</Col>
            <Col className="col-3">{opt.showlblWellnessAmount ? `$${opt.calculatedCredits}` : '\u00A0'}</Col>
            {this.state.answers[index].yesEnabled && <Col className="col-3">
              <div className="custom-control custom-switch">
                <input type="checkbox" key={`opt-${opt.questionID}`}
                      tid="changeOptAns"
                      className="custom-control-input" id={`opt-id-${opt.questionID}`}
                      checked={this.state.answers[index].yesSelected}
                      disabled={!this.state.answers[index].yesEnabled}
                      onChange={()=>{this.changeOptionAnswer(index)}}/>
                <label className="custom-control-label" htmlFor={`opt-id-${opt.questionID}`}>
                  <span className="sr-only">{opt.question}</span>
                </label>
              </div>
            </Col> }
          </Row>
          { ( opt.supplementalQuestionRequired && (opt.isSupplemental || opt.showSupplemental) ) && 
            <div className={`supplemental`}>
              <Row>
                <Form.Label htmlFor={index+1} column xs={{span: "6"}}>{opt.supplementalQuestion}</Form.Label>
                <Col xs={{span: "6"}} sm={{span: "3", offset: "3"}}>
                  <Form.Control
                    name="supplimentalAnswer"
                    id={index+1}
                    className="login-input-field edit-input-field"
                    type="text"
                    aria-label={opt.question+" "+wellnessQuestionCardTitleValueLabel.textToRead+" $"+opt.calculatedCredits}
                    onChange={this.changeInput}
                    value={this.state.answers[index].supplementalAnswer ? this.state.answers[index].supplementalAnswer : ""}
                    disabled={!this.state.answers[index].supplementalAnswerEnabled}
                    aria-disabled={!this.state.answers[index].supplementalAnswerEnabled}/>
                </Col>
              </Row>
            </div>}
        </div>
      );
    });
    return (
      <div className={`ml-wellness clearfix`}>
        <div className={`ml-description mb-4 clearfix`}>
          <p className={`m-0`}  dangerouslySetInnerHTML={{__html: wellnessQuestionsTitle}} />
        </div>
        <Card className={`wellnesscard`}>
            <Card.Header>
              <Row>
                <Col xs={{span: "3", offset: "6"}} className="col-height"><h2>{wellnessQuestionCardTitleValueLabel.display}</h2><sup className="ml-errorColorOnly sup-heading">1</sup></Col>
                <Col xs={3} className="col-height"><h2>{lblNoYesHeader.display}</h2></Col>
              </Row>
            </Card.Header>
            <Card.Body>
              {optionRows}
            </Card.Body>
            { ((wellnessMessages && wellnessMessages !== " " && !isNoWellnessQuestionsMsg)
              || (noWellnessQuestionstionsNote && isNoWellnessQuestionsMsg)) 
              &&
              <Card.Footer>
                <Fragment><div  dangerouslySetInnerHTML={{__html: isNoWellnessQuestionsMsg ? noWellnessQuestionstionsNote : wellnessMessages }} /> </Fragment>
              </Card.Footer>
            }
        </Card>
        {this.props.errorMessage && <Fragment><div className="alert alert-danger" tabIndex="-1" ref="errorMessage"  role='alert'  dangerouslySetInnerHTML={{__html: this.props.errorMessage}} /></Fragment> }
      </div>
    )
  }

}
const mapStateToProps = (state) => {
  return {wellness: state.userReducer.wellness,
          options: state.userReducer.wellness.result,
          contents: state.userReducer.wellness.contentResource.contents,
          errorMessage: state.userReducer.wellnessError ? state.userReducer.wellnessError.errorMessage : ""
         }
};

export default connect(
mapStateToProps,
{ getWellness, clearWellnessError, dissmiss }
)(withRouter(BenefitWellness));
