import React, { Component } from 'react';
import { FormCheck, Form , Col } from 'react-bootstrap';
import _ from 'underscore';
import ViewEditText from './ViewEditText';
import { FormGroup } from 'reactstrap';

class MultiRadioCompEmail extends Component {
	render () {
    const props = this.props
    const { personalEmailText, workEmailText } = this.props
    const labelC = <Form.Label >{props.display}</Form.Label>
		const options = []
    const propsOptions = _.reject(props.options, (op)=>(op.text === undefined || op.text === 'undefined' || op.text === 'Undefined'))
		_.each(propsOptions, (opt,i)=>{
      const uniqID = `${props.component}-${props.name}-${opt.text}-${opt.value}-${i}`
      options.push(
        <div key={uniqID} className="clearfix">
          { ((opt.name === 'personalEmail' ) ||
            (opt.name === 'workEmail')) === true ?
            (<div className="peronal-info-details form-group row">
              <Col>
                <FormCheck  
                className="edit-input-label"
                type='radio'
                id={`${uniqID}-radio`}
                key={`${uniqID}-radio`}
                value={opt.name}
                label={opt.text}
                aria-checked={props.selected === opt.select}
                checked={props.selected === opt.select}
                onChange={this.props.changeoption}
                name={opt.select}
                />
              </Col>
              <Col className="personalemailholder">
                { ( (this.props.isHomeEmailEditable && opt.name === 'personalEmail' ) ||
                    (this.props.isWorkEmailEditable && opt.name === 'workEmail')) === true ? <>
                      <Form.Control id={`${uniqID}-text`} maxLength={100} type='text' className="login-input-field edit-input-field" placeholder={opt.placeHolder.display} name={opt.name} value={opt.default}  aria-label={opt.placeHolder.text} onChange={this.props.changeinput}/>
                      {/* {opt.name === 'workEmail' ? (props.emailErrorMsg ? (opt.default.match(this.props.validationWorkEmail)[0] ? '' : (<span className={`ml-error`} tabIndex='-1' role='alert' aria-label={props.emailErrorMsg}>{props.emailErrorMsg}</span>)) : '' ): '' }
                      {opt.name === 'personalEmail' ? (props.homeError ? (opt.default.match(this.props.validationPersonalEmail)[0] ? '' : (<span className={`ml-error`} tabIndex='-1' role='alert' aria-label={props.homeError}>{props.homeError}</span>)) : '') : '' } */}
                      {/* {opt.name === 'workEmail' ? (props.emailErrorMsg ? ((opt.default.match(this.props.validationWorkEmail) !== null && opt.default.match(this.props.validationWorkEmail)[0]) ? ''  : opt.default !== '' && (<span className={`ml-error`} tabIndex='-1' role='alert' aria-label={props.emailErrorMsg}>{props.emailErrorMsg}</span>)) : '' ): ''}
                      {opt.name === 'personalEmail' ? (props.homeError ? (opt.default.match(this.props.validationPersonalEmail) !== null && opt.default.match(this.props.validationPersonalEmail)[0] ? '': opt.default !== '' &&   (<span className={`ml-error`} tabIndex='-1' role='alert' aria-label={props.homeError}>{props.homeError}</span>)) : '') : '' } */}
                      {opt.name === 'workEmail' && props.emailErrorMsg && props.showWorkEmailError && 
                        <span className={`ml-error`}  role='alert' >{props.emailErrorMsg}</span>
                      }
                      {opt.name === 'personalEmail' && props.homeError && props.showPersonalEmailError && 
                        <span className={`ml-error`}  role='alert' >{props.homeError}</span>
                      }
                    </> : ((personalEmailText !== null && personalEmailText !== "" ) && (workEmailText !== null && workEmailText !== "")) ?
                      <ViewEditText type="text" name={opt.name}  textToRead={opt.text} text={opt.default}/> : ''
                }
              </Col>
          </div>) : ((personalEmailText !== null && personalEmailText !== "" ) && (workEmailText !== null && workEmailText !== "")) ?
            <ViewEditText type="text" name={opt.name} display={opt.text}  textToRead={opt.text} text={opt.default}/> : ''
          }
        </div>
			);
		});
		return (
      <div className="form-holder clearfix">
        {labelC}
        {/* <Form.Label>{props.title}</Form.Label> */}
        <FormGroup tag="fieldset" className="clearfix mb-0 w-100" >
        {<legend className="mb-3" dangerouslySetInnerHTML={{__html: props.legendText}} ></legend>}
          {options}
        </FormGroup>
      </div>
		)
	}
}
export default MultiRadioCompEmail;
