import React, { Component } from "react";
import { connect } from "react-redux";
import { logoff, validateTFACode } from "../../actions/userActions";
import { getTFARegContent, getTFARegSelection, sendVerificationCode, verificationContent, showError, showSuccess, dissmiss } from "../../actions/contentActions";
import { getDisplayText } from "../../utils";
import { Redirect, withRouter } from "react-router-dom";
import { Row, Form, Col } from "react-bootstrap";
import NewMultiRadioComponent from "../NewMultiRadioComponent";
import { trackPromise } from "react-promise-tracker";
import SMSForm from "./SMSForm";
import EmailForm from "./EmailForm";
import VerificationForm from "./VerificationForm";
import QRForm from "./QRForm";
import DisplayButton from "../button";

class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      multiFAList: {
        twoFATypeId: "",
        PhoneOrEmail: ""
      },
      formValidated: false,
      registrationStep: 1,
      resendCount: 0,
      nextScreen: true
    };
  }

  componentWillUnmount() {
    this.props.dissmiss();
  }

  componentDidMount() {
    localStorage.setItem("isRegistration", true);
    trackPromise(this.props.getTFARegContent(this.props.history));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.multiFAList.length === 1 && nextProps.multiFAList[0].value !== this.state.multiFAList.twoFATypeId) {
      this.setState({
        multiFAList: { twoFATypeId: nextProps.multiFAList[0].value },
        registrationStep: 2
      });
      trackPromise(this.props.getTFARegSelection(nextProps.multiFAList[0].value, true, this.props.history));
    }
  }

  setValue = e => {
    localStorage.setItem("TypeID", e.target.value);
    this.setState({ multiFAList: { twoFATypeId: e.target.value } });
  };

  failureCallback = msg => {
    this.props.showError(msg ? msg : "Please enter a valid contact number.");
    /* this.setState({
            nextScreen: false
        }); */
  };

  handleCancel = () => {
    this.props.logoff();
  };

  handleSubmit = e => {
    const me = this;
    e.preventDefault();

    if (!me.state.multiFAList.twoFATypeId) {
      me.setState({ formValidated: true });
    } else {
      me.setState({ registrationStep: 2 });
      trackPromise(me.props.getTFARegSelection(me.state.multiFAList.twoFATypeId, true, this.props.history));
    }
  };

  handleResend = () => {
    const totalCount = this.state.resendCount + 1;
    this.setState({ resendCount: totalCount });
    const { twoFATypeId } = this.state.multiFAList;

    const options = {
      PhoneOrEmail: parseInt(twoFATypeId) === 2 ? this.state.PhoneOrEmail : "",
      TypeID: parseInt(twoFATypeId),
      IsRegistration: true,
      resendCount: totalCount
    };
    this.verificationContent(options);
  };

  verificationContent = options => {
    this.setState({
      registrationStep: 3,
      PhoneOrEmail: options.PhoneOrEmail ? options.PhoneOrEmail : ""
    });
    const twoFATypeId = options.TypeID;
    const params = "isRegistration=true&selectedTwoFAType=" + twoFATypeId;
    trackPromise(
      this.props.sendVerificationCode(options, this.failureCallback).then(data => {
        if (!data && data === undefined) {
          this.setState({ registrationStep: 2 });
          return this.handleSubmit;
        } else {
          this.props.dissmiss();
          if (options.resendCount > 0) {
            this.props.showSuccess("Verification code resent successfully.");
          }
          trackPromise(this.props.verificationContent(params, this.props.history));
        }
      })
    );
  };

  renderStep1() {
    const lblDetail2 = getDisplayText(this.props.content, "lblDetail2");
    const lblTitle = getDisplayText(this.props.content, "lblTitle");
    const lblDetail1 = getDisplayText(this.props.content, "lblDetail1");
    const lblSubmit = getDisplayText(this.props.content, "btnSubmit");
    const lblCancel = getDisplayText(this.props.content, "btnCancel");
    // let twoFATypeId = [];
    /* this.props.multiFAList.map(item => {
            if (item.selected) {
                // twoFATypeId['twoFATypeId'] = item.value;
                this.ç({ multiFAList: {"twoFATypeId": item.value} });
            }
        }); */

    return (
      <div className="conatiner" role="main" id="maincontent">
        <div className="login-holder">
          <Row className="h-100">
            {/*image holder*/}
            <div className="col-12 login-image-holder">{/*image sholud be placed here*/}</div>
            <div className="logincontainer d-md-flex align-items-center align-content-center justify-content-between">
              <div className="holder login-form description panel d-none d-md-block">
                {/* <h1 className="login-heading d-sm-block d-md-none">{lblTitle.display}</h1> */}
                <span dangerouslySetInnerHTML={{ __html: lblDetail2.display }} />
              </div>

              <div className="form-group login-form mobile-login-form pb-5 pb-md-0" xs={{ order: 1 }} md={{ order: 2 }} lv={{ order: 2 }}>
                <h1 className="login-heading d-md-block">{lblTitle.display}</h1>
                <div className="login-form description panel d-md-none">
                  <span dangerouslySetInnerHTML={{ __html: lblDetail2.display }} />
                </div>
                <div className="holder">
                  <Form className="loginform" noValidate validated={this.state.formValidated} onSubmit={e => this.handleSubmit(e)}>
                    <Row>
                      <Col className="col-12 paddingbottom">
                        <NewMultiRadioComponent
                          ref="twoFATypeId"
                          component="newDependent"
                          name="twoFATypeId"
                          width="100"
                          display={`${lblDetail1.display}`}
                          selected={this.state.multiFAList.twoFATypeId}
                          options={this.props.multiFAList}
                          keyy={`${this.props.keyy}-gender`}
                          onChange={this.setValue}
                        />
                      </Col>
                      <Col>
                        <div className={`btns-forgot d-flex justify-content-between`}>
                          {this.props.allowCancel && <DisplayButton className="outline-secondary" displayName={lblCancel.display} onClick={this.handleCancel} controlId="cancelBtn" />}
                          <DisplayButton className="secondary" type="submit" disabled={this.state.multiFAList.twoFATypeId ? false : true} displayName={lblSubmit.display} />
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </div>
          </Row>
        </div>
      </div>
    );
  }

  renderStep2() {
    const hasRegContent = Object.keys(this.props.regContent).length > 0 ? true : false;
    const option = parseInt(this.state.multiFAList.twoFATypeId);
    if (option === 2) {
      return hasRegContent && <SMSForm regContent={this.props.regContent} sendVerificationCode={this.props.sendVerificationCode} verificationContent={this.verificationContent} showError={this.props.showError} dissmiss={this.props.dissmiss} />;
    } else if (option === 3) {
      return hasRegContent && <EmailForm regContent={this.props.regContent} sendVerificationCode={this.props.sendVerificationCode} verificationContent={this.verificationContent} history={this.props.history} />;
    } else {
      return (
        hasRegContent && (
          <QRForm
            regContent={this.props.regContent}
            sendVerificationCode={this.props.sendVerificationCode}
            verificationContent={this.verificationContent}
            validateTFACode={this.props.validateTFACode}
            history={this.props.history}
            failureCallback={this.props.failureCallback}
            showError={this.props.showError}
            showSuccess={this.props.showSuccess}
          />
        )
      );
    }
  }

  renderStep3() {
    const hasVerifiedContent = Object.keys(this.props.verifiedContent).length > 0 ? true : false;
    const option = parseInt(this.state.multiFAList.twoFATypeId);
    if (option === 2 || option === 3) {
      return (
        hasVerifiedContent && (
          <VerificationForm
            verifiedContent={this.props.verifiedContent}
            resendCodeInterval={this.props.resendCodeInterval}
            twoFATypeId={option}
            handleResend={this.handleResend}
            resendCount={this.state.resendCount}
            validateTFACode={this.props.validateTFACode}
            history={this.props.history}
            failureCallback={this.props.failureCallback}
            showError={this.props.showError}
            showSuccess={this.props.showSuccess}
          />
        )
      );
    } else {
      return (
        hasVerifiedContent && (
          <VerificationForm
            verifiedContent={this.props.verifiedContent}
            resendCodeInterval={this.props.resendCodeInterval}
            twoFATypeId={option}
            validateTFACode={this.props.validateTFACode}
            history={this.props.history}
            failureCallback={this.props.failureCallback}
            showError={this.props.showError}
            showSuccess={this.props.showSuccess}
          />
        )
      );
    }
  }

  render() {
    const { needTwoFARegistration, needTwoFAValidation } = this.props;
    if (needTwoFARegistration === "false" && needTwoFAValidation === "false") {
      return <Redirect to="/" />;
    }
    switch (this.state.registrationStep) {
      case 1:
        return this.renderStep1();
      case 2:
        return this.renderStep2();
      case 3:
        return this.renderStep3();
      default:
        return this.renderStep1();
    }
  }
}

const mapStateToProps = state => {
  return {
    content: state.contentReducer.twoFARegContent.contentResource ? state.contentReducer.twoFARegContent.contentResource.contents : [],
    multiFAList: state.contentReducer.twoFARegContent.twoFATypes ? state.contentReducer.twoFARegContent.twoFATypes : [],
    allowCancel: state.contentReducer.twoFARegContent.allowCancel,
    isSignedIn: state.userReducer.isSignedIn,
    needTwoFARegistration: state.userReducer.needTwoFARegistration,
    needTwoFAValidation: state.userReducer.needTwoFAValidation,
    client: state.contentReducer.client,
    regContent: state.contentReducer.regContent ? state.contentReducer.regContent : [],
    verifiedContent: state.contentReducer.verificationContent ? state.contentReducer.verificationContent : [],
    resendCodeInterval: state.contentReducer.resendCodeInterval
  };
};

export default connect(mapStateToProps, {
  getTFARegContent,
  getTFARegSelection,
  sendVerificationCode,
  verificationContent,
  validateTFACode,
  showError,
  showSuccess,
  dissmiss,
  logoff
})(withRouter(Registration));
