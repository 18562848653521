import React, { Component } from 'react';
import {Row, Form } from 'react-bootstrap';
import defaults from '../helpers/defaults';
import {getControlId} from "../utils";


class  PhoneComponent extends Component {

    render() {
        const phone = this.props.phone || defaults.phone
        const editMode = this.props.mode === 'edit' ? true : false;
        const type = this.props.type ? this.props.type : "text";
        const labelC =editMode?'':<Form.Label htmlFor={getControlId(this.props.display)}  >{this.props.display}</Form.Label>
        const regHomePhoneErrorMessage = this.props.reghomephoneerrormessage
        const regWorkPhoneErrorMessage = this.props.regworkphoneerrormessage
        const regHomePhoneAreaErrorMessage = this.props.reghomephoneareaerrormessage
        const regWorkPhoneAreaErrorMessage = this.props.regworkphoneareaerrormessage
        const regWorkPhoneExtensionErrorMessage = this.props.regworkphoneextensionerrormessage

        let phoneC = null
        if (editMode) {
             phoneC =   <div className="phone-details-form">
               <Row>
               <div className={`col-sm-12 col-md-3`}>
               <Form.Label htmlFor={getControlId(this.props.display)}  >{this.props.display}</Form.Label>
               </div>
                 <div className={`col-sm-12 col-md-3`}>
                   <Form.Control maxLength={3} id={getControlId(this.props.display)} name="areaCode" className="login-input-field edit-input-field phone mb-3" placeholder={this.props.areacode.display}  type={type} {...this.props} value={phone.areaCode}/>
                   {regHomePhoneAreaErrorMessage ? (this.props.validationHomePhoneAreaCode.test(phone.areaCode) ? '' : (<div className={`ml-error`} role='alert'><span>{regHomePhoneAreaErrorMessage}</span></div>)) : ''}
                   {regWorkPhoneAreaErrorMessage ? (this.props.validationWorkPhoneAreaCode.test(phone.areaCode) ? '' : (<div className={`ml-error`} role='alert'><span>{regWorkPhoneAreaErrorMessage}</span></div>)) : ''}
                 </div>
                 <div className={`col-sm-12 col-md-3`}>
                   <Form.Control maxLength={7}  name="phoneNumber" className="login-input-field edit-input-field phone mb-3" placeholder={this.props.phonenumber.display}  type={type} {...this.props} value={phone.phoneNumber}/>
                   {regHomePhoneErrorMessage ? (this.props.validationHomePhoneNumber.test(phone.phoneNumber) ? '' : (<div className={`ml-error`} role='alert'><span>{regHomePhoneErrorMessage}</span></div>)) : ''}
                   {regWorkPhoneErrorMessage ? (this.props.validationWorkPhoneNumber.test(phone.phoneNumber) ? '' : (<div className={`ml-error`} role='alert'><span>{regWorkPhoneErrorMessage}</span></div>)) : ''}
                 </div>
                 <div className={`col-sm-12 col-md-3`}>
                   {regWorkPhoneExtensionErrorMessage && <Form.Control maxLength={5} name="extension" className="login-input-field edit-input-field phone" placeholder={this.props.extension.display} type={type} {...this.props} value={phone.extension}/>}
                   {regWorkPhoneExtensionErrorMessage ? (this.props.validationWorkPhoneExtension.test(phone.extension) ? '' : (<div className={`ml-error`} role='alert' ><span>{regWorkPhoneExtensionErrorMessage}</span></div>)) : '' }
                 </div>
               </Row>
             </div>
        } else {
            phoneC = (<Form.Label>{`${phone.areaCode ? phone.areaCode+'-' : ''}${phone.phoneNumber ? phone.phoneNumber : ''}${phone.extension ? '-' + phone.extension : ''}`}</Form.Label>);
        }
        return (<div className="phone-edit">{labelC} {phoneC}</div>)
    }
}

export default PhoneComponent;
