import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { getProfile, updatePersonalInfo, disableNext, enableNext} from '../../actions/userActions'
import { getDisplayText, makeFlexitDateObj } from "../../utils";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { withRouter } from 'react-router-dom';
import { Row, Col, Container, Card, Alert } from 'react-bootstrap';
import NewMultiRadioComponent from '../NewMultiRadioComponent';
import ViewEditText from '../ViewEditText'
import PhoneComponent from '../PhoneComponent';
import AddressComponent from '../AddressComponent';
import MultiRadioCompEmail from '../MultiRadioCompEmail';
import { trackPromise } from 'react-promise-tracker';
import _ from 'underscore';
import DisplayButton from '../button';


class ReviewPersonalInfo extends Component {
  constructor(props) {
    super(props)
    this.state = { infoMode: 'view'
      , smokerMode: 'view'
      , phoneMode: 'view'
      , emailMode: 'view'
      , errorMessage: {WorkEmail: '', PersonalEmail: '', PostalCode: '', HomePhoneAreaCode: '', dateOfBirth: 'fill dateOfBirth', salary: ''}
      , error: []
      , currentUser: this.props.currentUser
      , disableNextCounter: 0
      , showWorkEmailError: false
      , showPersonalEmailError: false
      , validationforFirstName: new RegExp()
      , validationforLastName: new RegExp()
      , validationforInitial: new RegExp()
      , validationWorkEmail: new RegExp()
      , validationPersonalEmail: new RegExp()
      , validationPostalCodeCanada: new RegExp()
      , validationPostalCodeUS: new RegExp()
      , validationHomePhoneAreaCode: new RegExp()
      , validationHomePhoneNumber: new RegExp()
      , validationWorkPhoneAreaCode: new RegExp()
      , validationWorkPhoneNumber: new RegExp()
      , validationWorkPhoneExtension: new RegExp()
       ,validationAddress:new RegExp()
       ,smokerDeclarationChanged: false

    }

    this.betaState = Object.assign({}, this.state)
    this.oldAddress = Object.assign({}, this.props.currentUser.address)
    this.oldHomePhone = Object.assign({}, this.props.currentUser.homePhone)
    this.oldWorkPhone = Object.assign({}, this.props.currentUser.workPhone)
  }
  
  componentDidMount = () => {
    let isReview;
    if(localStorage.getItem('isReview') === "false"){
      isReview = false
    }
    if(localStorage.getItem('isReview') === null) {
      isReview = true
    }
    trackPromise(this.props.getProfile(isReview))
  }

  componentDidUpdate(prevProps){
    
    if(prevProps.currentUser !== this.props.currentUser){
      this.setState({currentUser: this.props.currentUser,
        validationforFirstName:new RegExp(this.props.personalInfo.validationforFirstName),
        validationforLastName:new RegExp(this.props.personalInfo.validationforLastName),
        validationforInitial:new RegExp(this.props.personalInfo.validationforInitial),
        validationAddress:new RegExp(this.props.personalInfo.validationForAddress),
        validationWorkEmail: new RegExp(this.props.personalInfo.validationWorkEmail),
        validationPersonalEmail: new RegExp(this.props.personalInfo.validationPersonalEmail),
        validationPostalCodeCanada: new RegExp(this.props.personalInfo.validationPostalCodeCanada), 
        validationPostalCodeUS:new RegExp(this.props.personalInfo.validationPostalCodeUS),
        validationHomePhoneAreaCode:new RegExp(this.props.personalInfo.validationHomePhoneAreaCode),
        validationHomePhoneNumber:new RegExp(this.props.personalInfo.validationHomePhoneNumber),
        validationWorkPhoneAreaCode:new RegExp(this.props.personalInfo.validationWorkPhoneAreaCode),
        validationWorkPhoneNumber:new RegExp(this.props.personalInfo.validationWorkPhoneNumber),
        validationWorkPhoneExtension: new RegExp(this.props.personalInfo.validationWorkPhoneExtension)
      })

    }
  }
  componentWillUnmount(){
    this.props.enableNext()
  }

  reverseInfoMode = () => {
    if (this.state.emailMode === 'edit') {
      this.cancelEmail()
    }
    if (this.state.phoneMode === 'edit') {
      this.cancelPhone()
    }
    const isReview = false
    trackPromise(this.props.getProfile(isReview))
    this.setState({ infoMode: this.state.infoMode === 'edit' ? 'view' : 'edit', error: "" }, () => {
      if(this.state.infoMode === 'edit'){
        this.setState({
          disableNextCounter: 1
        })
        this.props.disableNext();
      }else{
        this.setState({
          disableNextCounter: this.state.disableNextCounter - 1
        }, () => {
          if(this.state.disableNextCounter === 0) this.props.enableNext();
        })
      }
      
    })
  }

  reversePhoneMode = () => {
    if (this.state.emailMode === 'edit') {
      this.cancelEmail()
    }
    if (this.state.infoMode === 'edit') {
      this.cancelInfo()
    }
    const isReview = false
    trackPromise(this.props.getProfile(isReview))
    this.setState({ phoneMode: this.state.phoneMode === 'edit' ? 'view' : 'edit' }, () => {
      if(this.state.phoneMode === 'edit'){
        this.setState({
          disableNextCounter: 1
        })
        this.props.disableNext();
      }else{
        this.setState({
          disableNextCounter: this.state.disableNextCounter - 1
        }, () => {
          if(this.state.disableNextCounter === 0) this.props.enableNext();
        })
      }
    })
  }

  reverseEmailMode = () => {
    if (this.state.phoneMode === 'edit') {
      this.cancelPhone()
    }
    if (this.state.infoMode === 'edit') {
      this.cancelInfo()
    }
    const isReview = false
    trackPromise(this.props.getProfile(isReview))
    this.setState({ emailMode: this.state.emailMode === 'edit' ? 'view' : 'edit' }, () => {
      if(this.state.emailMode === 'edit'){
        this.setState({
          disableNextCounter: 1
        })
        this.props.disableNext();
      }else{
        this.setState({
          disableNextCounter: this.state.disableNextCounter - 1
        }, () => {
          if(this.state.disableNextCounter === 0) this.props.enableNext();
        })
        
      }
    })
  }

  cancelPhone = () => {
    this.betaState.currentUser.homePhone = Object.assign({}, this.oldHomePhone)
    this.betaState.currentUser.workPhone = Object.assign({}, this.oldWorkPhone)
    this.setState({ currentUser:this.betaState.currentUser, phoneMode: 'view' }, () => {
      if(this.state.phoneMode !== 'edit'){
        this.setState({
          disableNextCounter: this.state.disableNextCounter - 1
        }, () => {
          if(this.state.disableNextCounter === 0) this.props.enableNext();
        })
      }
    })
  }

  cancelInfo = () => {
    this.betaState.currentUser.address = Object.assign({}, this.oldAddress)
    this.setState({ currentUser:this.betaState.currentUser, infoMode: 'view' }, () => {
      if(this.state.infoMode !== 'edit'){
        this.setState({
          disableNextCounter: this.state.disableNextCounter - 1
        }, () => {
          if(this.state.disableNextCounter === 0) this.props.enableNext();
        })
      }
    })
  }

  cancelEmail = () => {
    this.setState({ currentUser:this.betaState.currentUser, emailMode: 'view', showPersonalEmailError: false, showWorkEmailError: false }, () => {
      if(this.state.emailMode !== 'edit'){
        this.setState({
          disableNextCounter: this.state.disableNextCounter - 1
        }, () => {
          if(this.state.disableNextCounter === 0) this.props.enableNext();
        })
      }
    })
  }

  changeSmokerStatus = (e) => {
    let currentUser = {...this.state.currentUser}
    currentUser['smokerStatus'] = e.target.value;
    this.setState({ smokerDeclarationChanged: this.state.smokerDeclarationChanged =true });
    this.setState({ currentUser });
    trackPromise(this.props.updatePersonalInfo(currentUser, null, () => {trackPromise(this.props.getProfile(false))}))
    
  }

  changePreferredEmailStatus = (e) => {
    let currentUser = {...this.state.currentUser}
    currentUser['selectedEmail'] = e.target.name;
    this.setState({ currentUser });
    trackPromise(this.props.updatePersonalInfo(currentUser, null, () => {trackPromise(this.props.getProfile(false))}))
  }

  changeDate = (e) => {
    let currentUser = {...this.state.currentUser}
    currentUser['dateOfBirth'] = makeFlexitDateObj(e)
    this.setState({ currentUser })
  }

  changeAddress = (e) => {
    let currentUser = {...this.state.currentUser}
    const {name, value} = e.target
    currentUser.address[name] = value
    this.setState({ currentUser })
  }

  changeHomePhone = (e) => {
    let currentUser = {...this.state.currentUser}
    const {name, value} = e.target
    currentUser.homePhone[name] = value
    this.setState({ currentUser })
  }

  changeWorkPhone = (e) => {
    let currentUser = {...this.state.currentUser}
    const {name, value} = e.target
    currentUser.workPhone[name] = value
    this.setState({ currentUser })
  }

  changeInput = (e) => {
    let currentUser = {...this.state.currentUser}
    const {name, value} = e.target
    currentUser[name] = value;
    this.setState({ currentUser });
  }

  saveClickValid = (divName) => {
    const {infoMode, phoneMode, emailMode} = this.state
    trackPromise(this.props.updatePersonalInfo(this.state.currentUser, err => {
      // const errorMessage = getErrors(err.errorMessage)
      this.setState({error: err, infoMode, phoneMode, emailMode})
    }, () => {
      //SUCCESS UPDATE info
      
      this.betaState = Object.assign({}, this.state)
      this.oldAddress = Object.assign({}, this.state.currentUser.address)
      this.oldHomePhone = Object.assign({}, this.state.currentUser.homePhone)
      this.oldWorkPhone = Object.assign({}, this.state.currentUser.workPhone)
      if (divName === 'info') this.reverseInfoMode()
      if (divName === 'phone') this.reversePhoneMode()
      if (divName === 'email') this.reverseEmailMode() 
    }))
  }
  
  saveClick = (divName) => {
    if (divName === 'email') {
      const isValid = this.isValidEmailInfo()
      if (isValid) {
        this.saveClickValid(divName) 
      }
    } else {
      this.saveClickValid(divName) 
    }
    // const {infoMode, phoneMode, emailMode} = this.state
    // trackPromise(this.props.updatePersonalInfo(this.state.currentUser, err => {
    //   // const errorMessage = getErrors(err.errorMessage)
    //   this.setState({error: err, infoMode, phoneMode, emailMode})
    // }, () => {
    //   //SUCCESS UPDATE info
      
    //   this.betaState = Object.assign({}, this.state)
    //   this.oldAddress = Object.assign({}, this.state.currentUser.address)
    //   this.oldHomePhone = Object.assign({}, this.state.currentUser.homePhone)
    //   this.oldWorkPhone = Object.assign({}, this.state.currentUser.workPhone)
    //   if (divName === 'info') this.reverseInfoMode()
    //   if (divName === 'phone') this.reversePhoneMode()
    //   if (divName === 'email') this.reverseEmailMode() 
    // }))
  }

   isValidBasicInfo= ()=>{



    if(this.props.personalInfo.isAddressEditable){

      if( this.state.validationforFirstName.test(this.state.currentUser.firstName) 
      && this.state.validationforLastName.test(this.state.currentUser.lastName) && this.state.validationforInitial.test(this.state.currentUser.initial) 
     && this.state.validationAddress.test(this.state.currentUser.address.address1) 
     && this.state.validationAddress.test(this.state.currentUser.address.address2) 
     && this.state.validationAddress.test(this.state.currentUser.address.city) 
    
      ){
      if(this.state.currentUser.address.countryCode === 'OT'){

        return true;

      }

      if(this.state.currentUser.address.countryCode === 'CA'){

        if(this.state.validationPostalCodeCanada.test(this.state.currentUser.address.postalCode)){
          return true;
        }else{
          return false;
        }
      }


      if(this.state.currentUser.address.countryCode === 'US'){

        if(this.state.validationPostalCodeUS.test(this.state.currentUser.address.postalCode)){
          return true;
        }else{
          return false;
        }
      }


     }else {
       return false;
     }


    }else{ // did have address flage 

      if( this.state.validationforFirstName.test(this.state.currentUser.firstName) 
      && this.state.validationforLastName.test(this.state.currentUser.lastName) && this.state.validationforInitial.test(this.state.currentUser.initial)
      && this.state.validationAddress.test(this.state.currentUser.address.address1) 
      && this.state.validationAddress.test(this.state.currentUser.address.address2) 
      && this.state.validationAddress.test(this.state.currentUser.address.city) 
      ){
       return true;
     }else {
       return false;
     }

    }


   } 

   isValidPhoneInfo = () => {
     const {isHomePhoneEditable, isWorkPhoneEditable} = this.props.personalInfo
     const { homePhone, workPhone } = this.state.currentUser
    if( isHomePhoneEditable && true){
      if(this.state.validationWorkPhoneAreaCode.test(workPhone.areaCode) && this.state.validationWorkPhoneNumber.test(workPhone.phoneNumber) && this.state.validationWorkPhoneExtension.test(workPhone.extension) && this.state.validationHomePhoneAreaCode.test(homePhone.areaCode) && this.state.validationHomePhoneNumber.test(homePhone.phoneNumber)){
        return true
      } else {
        return false
      }
    }  else {
      if (isHomePhoneEditable ){
        if(this.state.validationHomePhoneAreaCode.test(homePhone.areaCode) && this.state.validationHomePhoneNumber.test(homePhone.phoneNumber)){
          return true
        } else {
          return false
        }
      }
      if (isWorkPhoneEditable){
        if(this.state.validationWorkPhoneAreaCode.test(workPhone.areaCode) && this.state.validationWorkPhoneNumber.test(workPhone.phoneNumber) && this.state.validationWorkPhoneExtension.test(workPhone.extension)){
          return true
        } else {
          return false
        }
      }
    } 
   }

   isValidEmailInfo = () => {
     const {isHomeEmailEditable, isWorkEmailEditable} = this.props.personalInfo
     const {personalEmail, workEmail} = this.state.currentUser
     
    
     if(isHomeEmailEditable && isWorkEmailEditable){

      //  if(this.state.currentUser.workEmail.match(this.state.validationWorkEmail)[0]){

      //  }
      //  else {
      //  }
      // if(this.currentUser.workEmail.match(this.state.validationWorkEmail) !== null && this.state.currentUser.workEmail.match(this.state.validationWorkEmail)[0] && this.currentUser.personalEmail.match(this.state.validationPersonalEmail) !== null && this.state.currentUser.personalEmail.match(this.state.validationPersonalEmail)[0])
      // {
      //    return true
      // }
      // else {
         
    //      return false
    //    }
    //  } else{
      // && workEmail.match(this.state.validationWorkEmail)[0]
      // && personalEmail.match(this.state.validationPersonalEmail)[0]
      const showWorkError = (workEmail.match(this.state.validationWorkEmail) !== null) ? false : true
      const showHomeError = (personalEmail.match(this.state.validationPersonalEmail) !== null) ? false : true
      if( !showWorkError && !showHomeError)
      {
        this.setState({showPersonalEmailError: showHomeError, showWorkEmailError: showWorkError})
        return true
      } else {
        this.setState({showPersonalEmailError: showHomeError, showWorkEmailError: showWorkError})
        return false
      }
      }else{
       if(isHomeEmailEditable){
         if(this.state.validationPersonalEmail.test(personalEmail)){
          this.setState({showPersonalEmailError: false})
           return true
         } else {
           this.setState({showPersonalEmailError: true})
           return false
         }
       }
       if(isWorkEmailEditable){
         if(this.state.validationWorkEmail.test(workEmail)){
           this.setState({showWorkEmailError: false})
           return true
         }else {
           this.setState({showWorkEmailError: true})
           return false
         }
       }
     }
     
   }

   handleDownKeyPressed = (event, method) => {
      switch(event.which){
          case 13: {
            method();
            break;
          }
          default:
				    break;
      }
   }

   getErrorFromResponse = (errorCat) => {
     const errorParam = this.state.error;
     if(!errorParam || !errorParam.length) {
       return false;
     }
     const removeRegex = /[/!@#$%&*()]+/gi
     let errors = ((typeof errorParam === "string") && [errorParam]) || errorParam
     const type = (typeof errorCat === "string" && errorCat.toLowerCase().replace(removeRegex, '')) 
                  || errorCat.join("|").toLowerCase().replace(removeRegex, '')
     
     let filterErrors = ((typeof errorCat === "string") && errors.filter(
      error => new RegExp((type)).test(error.toLowerCase())
     )) || errors.filter(
      error => !(new RegExp(type)).test(error.toLowerCase())
     );
     return (errors.length && filterErrors.length && filterErrors) || false;
   }

  render () {
    const contents = this.props.contents ? this.props.contents : {}
    const { footer } = this.props
    const nonSmokerMessage = getDisplayText(contents, 'lblIsNonSmoker')
    const smokerMessage = getDisplayText(contents, 'lblIsSmoker')
    const smokerStatusTitle = getDisplayText(contents, 'pnlSmokerGroupingText')
    const btnCancel = getDisplayText((footer && footer.contents) || {}, 'btnCancel')
    const btnSave = getDisplayText( (footer && footer.contents) ||  {}, 'btnSave')
    const editIcon = getDisplayText((footer && footer.contents) || {}, 'EditIcon')
    let smokerStatusMessage = ""
    if (["0", 0].indexOf(this.props.currentUser.smokerStatus) > -1) smokerStatusMessage = nonSmokerMessage
    if (["1", 1].indexOf(this.props.currentUser.smokerStatus) > -1) smokerStatusMessage = smokerMessage

    const { isHomeEmailEditable } = this.props.personalInfo && this.props.personalInfo;
    const { isWorkEmailEditable}  = this.props.personalInfo && this.props.personalInfo;
    const showEmailEditable = isHomeEmailEditable || isWorkEmailEditable

    const { isHomePhoneEditable } = this.props.personalInfo && this.props.personalInfo;
    const { isWorkPhoneEditable } = this.props.personalInfo && this.props.personalInfo;
    const { showWorkPhone } = this.props.personalInfo && this.props.personalInfo;
    const showPhoneEditable = isHomePhoneEditable || isWorkPhoneEditable

    const { isAddressEditable } = this.props.personalInfo && this.props.personalInfo;
    const { isNameEditable } = this.props.personalInfo && this.props.personalInfo;
    const { isMaritalStatusUsed } = this.props.personalInfo && this.props.personalInfo;
    const showInfoEditable = isAddressEditable || isNameEditable || isMaritalStatusUsed

    const lblBirthDate = getDisplayText(contents, 'lblBirthDate')
    const lblGender = getDisplayText(contents, 'lblGender')
    const lblLanguage = getDisplayText(contents, 'lblLanguage')
    const lblMaritalStatus = getDisplayText(contents, 'lblMaritalStatus')
    const lblHomePhone = getDisplayText(contents, 'lblHomePhone')
    const lblEditWorkEmail = getDisplayText(contents, 'lblEditWorkEmail')

    const lblEditAddress = getDisplayText(contents, 'lblEditAddress')
    const lblEditPersonalEmail = getDisplayText(contents, 'lblEditPersonalEmail')
    const lblCurrentFrozenSalary = getDisplayText(contents, 'lblCurrentFrozenSalary')
    // const lblOtherChangeNote = getDisplayText(contents, 'lblOtherChangeNote')
    const lblEditWorkPhone = getDisplayText(contents, 'lblEditWorkPhone')
    const lblPreferredEmail = getDisplayText(contents, 'lblPreferredEmail')
    const lblEditLastName = getDisplayText(contents, 'lblEditLastName')
    const lblEditFirstName = getDisplayText(contents, 'lblEditFirstName')
    const lblEditInitial = getDisplayText(contents, 'lblEditInitial')

    const pnlEmailGroupingText = getDisplayText(contents, 'pnlEmailGroupingText')
    const pnlPhoneGroupingText = getDisplayText(contents, 'pnlPhoneGroupingText')
    // const userMaritalStatus = getByValue(this.props.maritalStatusList || defaults.maritalStatusList, this.state.currentUser.maritalStatus||0).text
    // const userLanguage = getByValue(this.props.languageList || defaults.languageList, this.state.currentUser.languageCode||'E').text

    const regHomePhoneErrorMessage = getDisplayText(contents, 'regHomePhoneErrorMessage').display
    const regWorkPhoneErrorMessage = getDisplayText(contents, 'regWorkPhoneErrorMessage').display
    const regWorkPhoneAreaErrorMessage = getDisplayText(contents, 'regWorkPhoneAreaErrorMessage').display
    const regHomePhoneAreaErrorMessage = getDisplayText(contents, 'regHomePhoneAreaErrorMessage').display
    const regExtensionErrorMessage = getDisplayText(contents, 'regExtensionErrorMessage').display
    const regWorkEmailErrorMessage = getDisplayText(contents, 'regWorkEmailErrorMessage').display
    const regPersonalEmailErrorMessage = getDisplayText(contents, 'regPersonalEmailErrorMessage').display
    const regPostalCodeCanadaErrorMessage = getDisplayText(contents, 'regPostalCodeCanadaErrorMessage').display
    const regPostalCodeUSErrorMessage = getDisplayText(contents, 'regPostalCodeUSErrorMessage').display
    const regLastNameErrorMessage = getDisplayText(contents, 'regLastNameErrorMessage').display
    const regFirstNameErrorMessage = getDisplayText(contents, 'regFirstNameErrorMessage').display
    const regMiddleInitialErrorMessage = getDisplayText(contents, 'regMiddleInitialErrorMessage').display
    const lblSmoker = getDisplayText(contents, 'lblSmoker').display
    const lblNonSmoker = getDisplayText(contents, 'lblNonSmoker').display
    const lblWorkPhone = getDisplayText(contents, 'lblWorkPhone')
    const lblEditHomePhone = getDisplayText(contents, 'lblEditHomePhone')
    const lblWorkEmail = getDisplayText(contents, 'lblWorkEmail')
    const lblPersonalEmail = getDisplayText(contents, 'lblPersonalEmail')


    const lblProfileFirstNamePlaceHolder = getDisplayText(contents, 'lblProfileFirstNamePlaceHolder')
    const lblProfileLastNamePlaceHolder = getDisplayText(contents, 'lblProfileLastNamePlaceHolder')
    const lblProfileInitialPlaceHolder = getDisplayText(contents, 'lblProfileInitialPlaceHolder')
    const lblProfileAddress1PlaceHolder = getDisplayText(contents, 'lblProfileAddress1PlaceHolder')
    const lblProfileAddress2PalceHolder = getDisplayText(contents, 'lblProfileAddress2PalceHolder')
    const lblProfileCityPlaceHolder = getDisplayText(contents, 'lblProfileCityPlaceHolder')
    const lblProfileProvinceCodePlaceHolder = getDisplayText(contents, 'lblProfileProvinceCodePlaceHolder')
    const lblProfilePostalCodePlaceHolder = getDisplayText(contents, 'lblProfilePostalCodePlaceHolder')
    const lblProfileCountryCodePlaceHolder = getDisplayText(contents, 'lblProfileCountryCodePlaceHolder')
    const lblProfileWorkEmailPlaceHolder = getDisplayText(contents, 'lblProfileWorkEmailPlaceHolder')
    const lblProfilePersonalEmailPlaceHolder = getDisplayText(contents, 'lblProfilePersonalEmailPlaceHolder')
    const lblProfileWorkPhoneAreaCodePlaceHolder = getDisplayText(contents, 'lblProfileWorkPhoneAreaCodePlaceHolder')
    const lblProfileWorkPhonePhoneNumberPlaceHolder = getDisplayText(contents, 'lblProfileWorkPhonePhoneNumberPlaceHolder')
    const lblProfileWorkPhoneExtensionPlaceHolder = getDisplayText(contents, 'lblProfileWorkPhoneExtensionPlaceHolder')
    const lblProfileHomePhoneAreaCodePlaceHolder = getDisplayText(contents, 'lblProfileHomePhoneAreaCodePlaceHolder')
    const lblProfileHomePhonePhoneNumberPlaceHolder = getDisplayText(contents, 'lblProfileHomePhonePhoneNumberPlaceHolder')
    const regAddress1errormessage = getDisplayText(contents, 'regAddress1ErrorMessage').display
    const regAddress2errormessage = getDisplayText(contents, 'regAddress2ErrorMessage').display
    const regCityerrormessage = getDisplayText(contents, 'regCityErrorMessage').display
    
    const errorsList = {
      home: this.getErrorFromResponse([pnlPhoneGroupingText.display, pnlEmailGroupingText.display]),
      phone: this.getErrorFromResponse(pnlPhoneGroupingText.display),
      email: this.getErrorFromResponse(pnlEmailGroupingText.display)
    }

    const preferredEmailList = [
      {
          text: lblEditWorkEmail.display,
          name: "workEmail",
          value: "workEmail",
          default: this.state.currentUser.workEmail,
          select: "work",
          placeHolder: lblProfileWorkEmailPlaceHolder
      },
      {
          text: lblEditPersonalEmail.display,
          name: "personalEmail",
          value: "personalEmail",
          default: this.state.currentUser.personalEmail,
          select: "personal",
          placeHolder:  lblProfilePersonalEmailPlaceHolder
      }
  ]
    const smokerList = [
      {
        text: lblNonSmoker,
        value: 0
      },
      {
        text: lblSmoker,
        value: 1
      }
    ]

    return (
        <div className="row">
          <Container>
            <Alert  variant='light' className='none-padding'> <span dangerouslySetInnerHTML={{__html: this.props.personalInfo.changeNote}} /></Alert>
            <Col>
              <Row>
                <div className="w-100">
                  <Card>
                    <Card.Header> 
                       <h2 className="float-left">{ this.props.personalInfo.employeeNameText }</h2>
                      {showInfoEditable && <span tabIndex='0' tid="editInfoBtn" role='button' aria-label={editIcon.textToRead +" "+ this.props.personalInfo.employeeNameText} className="icon-action edit-icon float-right" onKeyDown={(event) => this.handleDownKeyPressed(event, this.reverseInfoMode)} onClick={this.reverseInfoMode} > <FontAwesomeIcon icon={faPencilAlt} /> </span>}       
                    </Card.Header>
                    <Card.Body className="personal-info-holder">
                      <div className="row">
                        <div className="col-12">
                          {this.state.infoMode === 'edit' && errorsList.home && errorsList.home.map( 
                            (e,i) => 
                            <p key={i} className="ml-inputfields has-error formErrorPStyle" role='alert'>
                              {e}
                            </p>
                            )
                          }
                        </div>
                        {isNameEditable &&
                        <div className={ this.state.infoMode === 'edit' ? "col-sm-12 fullname" : "d-none"}>
                          {this.state.infoMode === 'edit' ? (<div className="editmode row">
                          <div className="col-sm-12 col-md-4">
                            <ViewEditText 
                                          type="text"
                                          placeholder={lblProfileFirstNamePlaceHolder.display}
                                          display={lblEditFirstName.display}
                                          textToRead={lblEditFirstName.textToRead}
                                          mode={!isNameEditable ? 'edit' : 'view' }
                                          name="firstName"
                                          text={this.state.currentUser.firstName}
                                          value={this.state.currentUser.firstName}
                                          onChange={this.changeInput}
                                          errormessage={this.state.errorMessage.firstName}
                                          maxLength={50}
                            />
                            {this.state.validationforFirstName.test(this.state.currentUser.firstName) ? '' : (<span   role='alert'   className={`ml-error`}>{regFirstNameErrorMessage}</span>)}
                          </div>
                          <div className="col-sm-12 col-md-4">
                            <ViewEditText type="text"
                                        placeholder={lblProfileLastNamePlaceHolder.display}
                                        display={lblEditLastName.display}
                                        textToRead={lblEditLastName.textToRead}
                                        mode={isNameEditable ? 'edit' : 'view' }
                                        name="lastName"
                                        text={this.state.currentUser.lastName}
                                        value={this.state.currentUser.lastName}
                                        onChange={this.changeInput}
                                        errormessage={this.state.errorMessage.lastName}
                                        maxLength={50}
                            />
                            { this.state.validationforLastName.test(this.state.currentUser.lastName) ? '' : (<span  role='alert'  className={`ml-error`}>{regLastNameErrorMessage}</span>) }
                          </div>
                          {
                          <div className="col-sm-12 col-md-4">
                            <ViewEditText type="text"
                                          display={lblEditInitial.display}
                                          placeholder={lblProfileInitialPlaceHolder.display}
                                          textToRead={lblEditInitial.textToRead}
                                          mode={isNameEditable ? 'edit' : 'view' }
                                          name="initial"
                                          text={this.state.currentUser.initial}
                                          value={this.state.currentUser.initial}
                                          onChange={this.changeInput}
                                          errormessage={this.state.errorMessage.initial}
                                          maxLength={1}
                            />
                            {this.state.validationforInitial.test(this.state.currentUser.initial) ? '' : (<span  role='alert'  className={`ml-error`}>{regMiddleInitialErrorMessage}</span>)}
                          </div>
                          }
                          </div>) : ''}
                        </div>}
                        <div className="col-sm-12 col-md-6">
                          <div className="peronal-info-details select-gender">
                          <label className="form-label"> {lblBirthDate ? lblBirthDate.display : ""}</label>
                          {this.state.currentUser.dateOfBirth && (this.state.currentUser.dateOfBirth.display === "" ? '' :
                          <time 
                          // aria-label={lblBirthDate.textToRead+" "+this.state.currentUser.dateOfBirth.display} 
                          dateTime={this.state.currentUser.dateOfBirth.display}> {this.state.currentUser.dateOfBirth.display}</time>
                          )}
                            {/* <DateComponent
                                display={lblBirthDate.display}
                                title={lblBirthDate.display}
                                aria-label={lblBirthDate.textToRead}
                                // mode={this.state.infoMode}
                                mode={'view'}
                                date={this.state.currentUser.dateOfBirth}
                                onChange={this.changeDate}
                                errormessage={this.state.errorMessage.dateOfBirth}
                            /> */}
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <div className="peronal-info-details">
                            <ViewEditText type="select"
                                          display={lblGender.display}
                                          aria-label={lblGender.textToRead+" "+this.props.personalInfo.genderText}
                                          mode={'view'}
                                          name="gender"
                                          text={this.props.personalInfo.genderText}
                                          value={this.state.currentUser.gender}
                                          options={this.props.genderList}
                                          onChange={this.changeInput}
                                          errormessage={this.state.errorMessage.gender}
                            />
                          </div>
                        </div>
                        {  (this.props.personalInfo.maritalStatusText !== null && this.props.personalInfo.maritalStatusText !== "") &&
                        <div className="col-sm-12 col-md-6">
                          <div className="peronal-info-details">
                            <ViewEditText type="select"
                                          display={lblMaritalStatus.display}
                                          aria-label={lblMaritalStatus.textToRead+" "+this.props.personalInfo.maritalStatusText}
                                          mode={isMaritalStatusUsed ? this.state.infoMode : 'view'}
                                          text={this.props.personalInfo.maritalStatusText}
                                          value={this.state.currentUser.maritalStatus}
                                          options={this.props.maritalStatusList}
                                          name="maritalStatus"
                                          onChange={this.changeInput}
                                          errormessage={this.state.errorMessage.maritalStatus}/>
                          </div>
                        </div>}
                        <div className="col-sm-12 col-md-6">
                          <div className="peronal-info-details">
                            <ViewEditText
                                display={lblLanguage.display}
                                aria-label={lblLanguage.textToRead+" "+this.props.personalInfo.languageText}
                                type="select"
                                mode={'view'}
                                text={this.props.personalInfo.languageText}
                                value={this.state.currentUser.languageCode}
                                options={this.props.languageList}
                                name="languageCode"
                                onChange={this.changeInput}
                                errormessage={this.state.errorMessage.language}
                            />
                          </div>
                        </div>
                        
                        
                        { this.props.personalInfo.showEarningsInProfile &&
                        <div className="col-sm-12 col-md-6">
                            <div className="peronal-info-details">
                              <ViewEditText type="text"
                                            display={this.props.personalInfo.salaryLabel}
                                            textToRead={this.props.personalInfo.salaryLabel+" "+this.props.personalInfo.salaryText}
                                            mode={'view'}
                                            text={this.props.personalInfo.salaryText}
                                            name="salary"
                                            onChange={this.changeInput}
                                            errormessage={this.state.errorMessage.salary}
                              />
                            </div>
                          </div>
                        }
                        { 
                        <div className="col-sm-12 col-md-6">
                          <div className="peronal-info-details address">
                            {this.state.infoMode === 'view' ? <>
                            <label className="float-left pr-1">{lblEditAddress.display}</label>
                            <address className="float-left overflow-hidden">
                                {this.props.personalInfo.address1Text}<br/>{this.props.personalInfo.address2Text}
                            </address>
                            </> :
                            <AddressComponent
                              tid="addressComponentChange"
                              contents={contents}
                              address1={lblProfileAddress1PlaceHolder}
                              address2={lblProfileAddress2PalceHolder}
                              city={lblProfileCityPlaceHolder}
                              provincecode={lblProfileProvinceCodePlaceHolder}
                              postalcode={lblProfilePostalCodePlaceHolder}
                              countrycode   ={lblProfileCountryCodePlaceHolder}
                              display={lblEditAddress.display}
                              type="text"
                              mode={isAddressEditable ? this.state.infoMode : 'view'}
                              address={this.state.currentUser.address}
                              onChange={this.changeAddress}
                              errormessage={this.state.errorMessage.address}
                              regpostalcodecanadaerrormessage={regPostalCodeCanadaErrorMessage}
                              regpostalcodeuserrormessage={regPostalCodeUSErrorMessage}
                              provinceslist={this.props.provincesList}
                              countrieslist={this.props.countriesList}
                              validationPostalCodeCanada={this.state.validationPostalCodeCanada}
                              validationPostalCodeUS={this.state.validationPostalCodeUS}
                              regaddress1errormessage={regAddress1errormessage}
                              regaddress2errormessage={regAddress2errormessage}
                              regcityerrormessage={regCityerrormessage}
                              validationAddress={this.state.validationAddress}
                             
                            />}
                          </div>
                        </div>}
                        {/* <div className="col-sm-12 col-md-6">
                          <div className="peronal-info-details">
                            <ViewEditText
                                display={lblLanguage.display}
                                title={lblLanguage.textToRead}
                                aria-label={lblLanguage.textToRead}
                                type="select"
                                mode={this.state.infoMode}
                                text={userLanguage}
                                value={this.state.currentUser.languageCode}
                                options={this.props.languageList}
                                name="languageCode"
                                onChange={this.changeInput}
                                errormessage={this.state.errorMessage.language}
                            />
                          </div>
                        </div> */}
                        {false && <div className="col-sm-12 col-md-6">
                          <div className="peronal-info-details">
                            <ViewEditText type="text" name="currentFrozenSalary"
                                          display={lblCurrentFrozenSalary.display}
                                          textToRead={lblCurrentFrozenSalary.textToRead+" "+this.state.currentUser.currentFrozenSalary}
                                          mode={'view'}
                                          text={this.state.currentUser.currentFrozenSalary}
                                          onChange={this.changeInput}
                                          errormessage={this.state.errorMessage.currentFrozenSalary}
                            />
                          </div>
                        </div>}
                      </div>
                    </Card.Body>
                    {this.state.infoMode === 'edit' &&
                    <Card.Footer><SaveCancel  aria-disabled={this.isValidBasicInfo()} disabled={this.isValidBasicInfo()} cancel={this.cancelInfo} save={() => { this.saveClick('info') }} btnCancel={btnCancel} btnSave={btnSave} /></Card.Footer>
                    }
                  </Card>
                </div>
              </Row>
              {
                <Row>
                    <div className="w-100">
                      <Card>
                        <Card.Header>
                          <h2 className="float-left">{pnlPhoneGroupingText.display}</h2>
                          {showPhoneEditable && <span tid="phoneEditableBtn" tabIndex='0' role='button' aria-label={editIcon.textToRead +" "+ pnlPhoneGroupingText.textToRead} className="icon-action edit-icon edit-icon float-right" onKeyDown={(event) => this.handleDownKeyPressed(event, this.reversePhoneMode)} onClick={this.reversePhoneMode}> <FontAwesomeIcon icon={faPencilAlt} /> </span>}
                        </Card.Header>
                        <Card.Body className="personal-info-holder">
                        <div className="col-12">
                          {this.state.phoneMode === 'edit' && errorsList.phone && errorsList.phone.map( 
                            (e,i) => 
                            <p key={i} className="ml-inputfields has-error formErrorPStyle" role='alert'>
                              {e}
                            </p>
                            )
                          }
                        </div>
                        { (this.props.personalInfo.isHomePhoneEditable === true || (this.props.personalInfo.homePhoneText !== null && this.props.personalInfo.homePhoneText !== "")) &&
                        <div className="peronal-info-details edit-details block">
                            {this.state.phoneMode === "view" ? 
                            <>
                            <label className="form-label"> {lblHomePhone.display} </label>&nbsp;
                            <label className="form-label" >{this.props.personalInfo.homePhoneText} </label> 
                            </>
                            :
                            <PhoneComponent
                                tid="changePhoneComp"
                                type="text"
                                contents={contents}
                                display={lblEditHomePhone.display}
                                areacode={lblProfileHomePhoneAreaCodePlaceHolder}
                                phonenumber={lblProfileHomePhonePhoneNumberPlaceHolder}
                                mode={isHomePhoneEditable ? this.state.phoneMode : 'view'}
                                phone={this.state.currentUser.homePhone}
                                onChange={this.changeHomePhone}
                                errormessage={this.state.errorMessage.homePhone}
                                reghomephoneerrormessage = {regHomePhoneErrorMessage}
                                reghomephoneareaerrormessage = {regHomePhoneAreaErrorMessage}
                                validationHomePhoneAreaCode= {this.state.validationHomePhoneAreaCode}
                                validationWorkPhoneAreaCode= {this.state.validationWorkPhoneAreaCode}
                                validationHomePhoneNumber= {this.state.validationHomePhoneNumber}
                                validationWorkPhoneNumber= {this.state.validationWorkPhoneNumber}
                                validationWorkPhoneExtension= {this.state.validationWorkPhoneExtension}
                            />}
                          </div>}
                          {showWorkPhone &&
                          <div className="peronal-info-details edit-details">
                            {this.state.phoneMode === "view" ? 
                            <>
                            <label className="form-label">{lblWorkPhone.display}</label>
                            &nbsp;<label className="form-label">{this.props.personalInfo.workPhoneText}</label> 
                            </>
                            :
                            <PhoneComponent
                                tid="changeWorkPhoneComp"
                                type="text"
                                contents={contents}
                                areacode={lblProfileWorkPhoneAreaCodePlaceHolder}
                                phonenumber={lblProfileWorkPhonePhoneNumberPlaceHolder}
                                extension={lblProfileWorkPhoneExtensionPlaceHolder}
                                display={lblEditWorkPhone.display}
                                mode={isWorkPhoneEditable ? this.state.phoneMode : 'view'}
                                phone={this.state.currentUser.workPhone}
                                onChange={this.changeWorkPhone}
                                errormessage={this.state.errorMessage.workPhone}
                                regworkphoneerrormessage = {regWorkPhoneErrorMessage}
                                regworkphoneareaerrormessage = {regWorkPhoneAreaErrorMessage}
                                regworkphoneextensionerrormessage = {regExtensionErrorMessage}
                                validationHomePhoneAreaCode= {this.state.validationHomePhoneAreaCode}
                                validationWorkPhoneAreaCode= {this.state.validationWorkPhoneAreaCode}
                                validationHomePhoneNumber= {this.state.validationHomePhoneNumber}
                                validationWorkPhoneNumber= {this.state.validationWorkPhoneNumber}
                                validationWorkPhoneExtension= {this.state.validationWorkPhoneExtension}
                            />}
                          </div>
                          }
                        </Card.Body>
                        {this.state.phoneMode === 'edit' &&
                        <Card.Footer><SaveCancel aria-disabled={this.isValidPhoneInfo()} disabled={this.isValidPhoneInfo()} save={() => { this.saveClick('phone') }} cancel={this.cancelPhone} btnCancel={btnCancel} btnSave={btnSave} /></Card.Footer>
                        }
                      </Card>
                    </div>
                </Row>
              }
              {
                <Row>
                  <div className="w-100">
                    <Card>
                      <Card.Header>
                        <h2 className="float-left">{ pnlEmailGroupingText.display }</h2>
                        {showEmailEditable && <span tid="emailEditable" tabIndex='0' role='button' className="icon-action edit-icon float-right" aria-label={editIcon.textToRead +" "+ pnlEmailGroupingText.textToRead} onKeyDown={(event) => this.handleDownKeyPressed(event, this.reverseEmailMode)} onClick={this.reverseEmailMode}><FontAwesomeIcon  icon={faPencilAlt}  /> </span> }
                      </Card.Header>
                      <Card.Body className="personal-info-holder d-block">
                      <div className="col-12">
                        {this.state.emailMode === 'edit' && errorsList.email && errorsList.email.map( 
                          (e,i) => 
                          <p key={i} className="ml-inputfields has-error formErrorPStyle" role='alert'>
                            {e}
                          </p>
                          )
                        }
                      </div>
                        <div className="row">
                          {this.state.emailMode === 'edit' &&
                            <div className="col-sm-12 col-md-12 col-lg-11">
                              <div className="peronal-info-details personal-info-email mb-3 mb-md-0">
                                <MultiRadioCompEmail
                                  component='reviewPersonalInformation'
                                  name='reviewProfilePreferredEmail'
                                  fname='preferredEmail'
                                  selected={this.props.currentUser.selectedEmail ? this.props.currentUser.selectedEmail : 'personal'}
                                  options={preferredEmailList}
                                  changeinput={this.changeInput}
                                  changeoption={this.changePreferredEmailStatus}
                                  errormessage={this.state.errorMessage.preferredEmail}
                                  emailErrorMsg = {regWorkEmailErrorMessage}
                                  homeError = {regPersonalEmailErrorMessage}
                                  isHomeEmailEditable = {isHomeEmailEditable}
                                  isWorkEmailEditable = {isWorkEmailEditable}
                                  workEmailText = {this.props.personalInfo.workEmailText}
                                  personalEmailText = {this.props.personalInfo.personalEmailText}
                                  validationWorkEmail={this.state.validationWorkEmail}
                                  validationPersonalEmail={this.state.validationPersonalEmail}
                                  showPersonalEmailError={this.state.showPersonalEmailError}
                                  showWorkEmailError={this.state.showWorkEmailError}
                                  legendText={lblPreferredEmail.display}
                                />
                              </div>
                            </div>
                          }
                          {this.state.emailMode === 'view' && 
                            <div className="col-sm-12 col-md-9">
                              <div className="wrapper">
                                { ( isWorkEmailEditable || (this.props.personalInfo.workEmailText !== null && this.props.personalInfo.workEmailText !== "")) &&
                                <div className="peronal-info-details">
                                  <ViewEditText
                                      type="text" name="workEmail"
                                      display={lblWorkEmail.display}
                                      textToRead={lblWorkEmail.textToRead+" "+this.props.personalInfo.workEmailText}
                                      text={this.props.personalInfo.workEmailText}
                                      onChange={this.changeInput}
                                      errormessage={this.state.errorMessage.workEmail}
                                      mode={isWorkEmailEditable ? this.state.emailMode : 'view'}
                                  />
                                </div>}
                                { (isHomeEmailEditable || (this.props.personalInfo.personalEmailText !== null && this.props.personalInfo.personalEmailText !== "" ))&& 
                                <div className="peronal-info-details">
                                  <ViewEditText
                                      display={lblPersonalEmail.display}
                                      textToRead={lblPersonalEmail.textToRead+" "+this.props.personalInfo.personalEmailText}
                                      type="text" name="personalEmail"
                                      text={this.props.personalInfo.personalEmailText}
                                      onChange={this.changeInput}
                                      errormessage={this.state.errorMessage.personalEmail}
                                      mode={isHomeEmailEditable ? this.state.emailMode : 'view'}                                  
                                  />
                                </div>}
                              </div>
                            </div>
                          }
                        </div>
                      </Card.Body>
                      {this.state.emailMode === 'edit' &&
                      <Card.Footer><SaveCancel aria-disabled={true} disabled={true} cancel={this.cancelEmail} save={() => { this.saveClick('email') }} btnCancel={btnCancel} btnSave={btnSave} /></Card.Footer>
                      }
                    </Card>
                  </div>
                </Row>
              }
              { ((this.props.currentUser.smokerStatus === 0 || this.props.currentUser.smokerStatus === 1) || !this.props.personalInfo.readOnly) &&
              this.props.currentUser.smokerDeclarationOptional === false &&
                <Row>
                  <div className="w-100">
                    <Card>
                      <Card.Header>
                      <h2 className="float-left">{ smokerStatusTitle.display }</h2>
                      </Card.Header>
                      <Card.Body>
                      { this.props.personalInfo.readOnly === false ?
                      ( <NewMultiRadioComponent
                            component='reviewPersonalInformation'
                            name='reviewProfileSmokerStatus'
                            fname='smokerStatus'
                            display={smokerStatusMessage.display}
                            selected={this.props.currentUser.smokerStatus ? this.props.currentUser.smokerStatus.toString() : this.props.currentUser.smokerStatus.toString()}
                            options={smokerList}
                            onChange={this.changeSmokerStatus}
                        />) : (<span>{smokerStatusMessage.display}</span>)}
                        {
                          this.props.personalInfo.readOnly === false && this.props.currentUser.smokerStatus === 0 && this.props.personalInfo.showSmokerDeclaration === true && this.state.smokerDeclarationChanged === true? (
                            <Fragment><label role="alert" className="alert alert-primary"   dangerouslySetInnerHTML={{ __html: this.props.personalInfo.smokerDeclarationLabel}} /></Fragment>
                          ) 
                          : ''
                        }
                      </Card.Body>
                    </Card>
                  </div>
                </Row>
              }
            </Col>
          </Container>
        </div>
    );
  }
}

class SaveCancel extends Component {

  render() {
    const {disabled} = this.props;
    const cancelBtn = "cancel"
    const saveBtn = "save"

    return (
        <Row className="edit-btn infoeditbtns justify-content-between">
          <DisplayButton controlId="cancelBtn" className="outline-secondary btn-sm btn-cancel" displayName={(this.props.btnCancel && this.props.btnCancel.display) || cancelBtn}  onClick={this.props.cancel} />
          <DisplayButton controlId="saveBtn" className="outline-secondary btn-sm btn-save" displayName={(this.props.btnSave && this.props.btnSave.display) || saveBtn}   disabled={!disabled} onClick={this.props.save} />
        </Row>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const contents1 = state.userReducer.personalInfo.contentResource ? state.userReducer.personalInfo.contentResource.contents : []
  const contents2 = (state.userReducer.personalInfo&&state.userReducer.personalInfo.contentResource&&state.userReducer.personalInfo.contentResource.controlResource.PersonalInfo) ? state.userReducer.personalInfo.contentResource.controlResource.PersonalInfo : []
  const contents = _.union(contents1, contents2)

  return {isSignedIn: state.userReducer.isSignedIn,
    personalInfo: state.userReducer.personalInfo,
    contents : contents ? contents : {},
    currentUser: state.userReducer.personalInfo.result || {firstName: '', lastName: ''},
    genderList: state.userReducer.personalInfo.genderList,
    languageList : state.userReducer.personalInfo.languageList,
    maritalStatusList : state.userReducer.personalInfo.maritalStatusList,
    smokerStatusList : state.userReducer.personalInfo.smokerStatusList,
    provincesList : state.userReducer.personalInfo.provincesList,
    countriesList : state.userReducer.personalInfo.countriesList,
    footer : state.userReducer.contentFooter.contentResource,
    dependentContentResource: state.userReducer.dependents.contentResource ? state.userReducer.dependents.contentResource : {}
  }
};

export default connect(
    mapStateToProps,
    { getProfile, updatePersonalInfo, disableNext, enableNext }
)(withRouter(ReviewPersonalInfo));
