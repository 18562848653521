import React, { Component } from 'react';
import { connect } from "react-redux";
import { Row} from 'react-bootstrap';
import { getContentFooter, getContentFooterPage } from '../actions/userActions';
import { Link } from 'react-router-dom'
import _ from 'underscore';
import { trackPromise } from 'react-promise-tracker';
import {getDisplayText} from "../utils";

class Footer extends Component {
  
  componentDidMount() {
    trackPromise(this.props.getContentFooter())
  }
  handleClick = (value) => {
    localStorage.setItem('section', value.section)
    localStorage.setItem('display', value.display)
  }

  handleClose = () => {
    this.setState({
      showModal: false
    })
  }

  render() {
    const { copyright, links } = this.props.contentFooter.result
    const lblNewTab =getDisplayText((this.props.contentFooter.contentResource && this.props.contentFooter.contentResource.contents) || {}, 'lblNewTab' )
    let allLinks = []
    _.each(links, (value,key) => {
      // aria-label={value.display+" "+lblNewTab.textToRead}
      allLinks.push(
      <li key={key} className="top-footer-link">{
      value.isExternalLink === true  ?
      <a target="_blank" href={value.actinLink} rel="noopener noreferrer">{value.display}</a>
      : 
       <Link  tid="linkClick" to={{
         pathname:`/Content/FooterContent`,
         state: {
          value: value
            }
          }} target="blank"
         onClick={() => this.handleClick(value)}>{value.display}
      </Link>
      }</li>
      )
    })

    return (
      <div role="contentinfo" className={this.props.isSignedIn ? "footer fixed-bottom" : "footer fixed-bottom showfooter"} id='mainFooter'>
        {/* <div className="navbar-nav">
            {this.props.isSignedIn && <Link className="nav-item nav-link" to="/help">Need help?</Link>}
        </div> */}
        <div className="container">
          <Row>
            <div className={`col-12 flex-column d-sm-flex justify-content-sm-between flex-sm-row-reverse p-0`}>
              <ul>{allLinks}</ul>
              <span  aria-hidden={false} dangerouslySetInnerHTML={{__html: copyright}}></span>
            </div>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    locale: state.contentReducer.locale,
    isSignedIn: state.userReducer.isSignedIn,
    contentFooter: state.userReducer.contentFooter,
    contentFooterPage: state.userReducer.contentFooterPage
  }
};

export default connect(
  mapStateToProps,
  {getContentFooter, getContentFooterPage}
)(Footer);
