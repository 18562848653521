import React, { useState, useEffect } from "react";
import ViewEditText from "../ViewEditText";
import {faFile} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const SelectUploadFile = props => {
    let inputRef;
    const [selectedFileCategoryName, setSelectedFileCategoryName] = useState("");
    const { setSelectedFile } = props;
    const { setErrorMsg } = props;
    const { setSelectedCategory } = props;
    const { maxFileSizeLimit } = props;
    const categoriesListData = props.categpriesData ? props.categpriesData : [];
    const categoriesTypeListData = props.categpriesTypeData? props.categpriesTypeData : [];
    const [filteredExtentions, setFilteredExtentions] = useState([]);
    const [selectedFileName,setSelectedFileName] = useState("")

    const categoriesArray = categoriesListData.map((item) => {
        const mapping = {
            text: item.categoryName,
            value: item.categoryID
        };
        return mapping
    });

    useEffect(() => {
        setSelectedFileName(props.browseFileLabel);
    },[props.browseFileLabel]);

    useEffect(() => {
        if(inputRef)
            inputRef.value = ''
    }, [selectedFileCategoryName,inputRef])

    const onSelectedFileChangeHandler = event => {
        if (event.target.files[0].size <= (parseInt(maxFileSizeLimit)*1e+6)) {
            const isValidExt = filteredExtentions.filter(ext => `.${event.target.files[0].type.split("/")[1]}` === ext).length > 0
            if(isValidExt){
                setSelectedFile(event.target.files[0]);
                setSelectedFileName(event.target.files[0].name);
                setErrorMsg("");
            }else{
                resetBroweWithError(event,props.validationNotSupportMessage)
            }
        } else {
            resetBroweWithError(event,props.sizeLimitError)
        }
    };

    const resetBroweWithError = (event, error) => {
        setSelectedFile(null);
        setErrorMsg(error);
        event.target.value = null;
        setSelectedFileName(props.browseFileLabel);
    }

    const changeTheCatgoryName = event => {
        setSelectedFileCategoryName(event.target.value);
        makeAcceptanceList(parseInt(event.target.value));
        setSelectedFile(null);
        setErrorMsg("");
        setSelectedFileName(props.browseFileLabel);
    };

    const makeAcceptanceList = selectedCategoryID => {
        const extentions = categoriesTypeListData
          .filter(cat => cat.categoryID === selectedCategoryID)
          .map(cat => "." + cat.fileExtension.toLowerCase());
        setFilteredExtentions(extentions);
        setSelectedCategory(selectedCategoryID);
    };

    const [categoryData] = categoriesListData && categoriesListData;
    if (categoryData && !filteredExtentions.length)
        makeAcceptanceList(categoryData.categoryID);

    const onSelectedFileThroughTab = (event) => {
        switch(event.which){
            case 13: {
                inputRef.click();
                break;
            }
            default:
                break;
        }
    }    
  return (<>
      <div className={`fieldgroup clearfix`} >
          <p dangerouslySetInnerHTML={{__html: props.subTitle}} />
        
        <ViewEditText
          tid='categoryOptions'
          type="select"
          aria-label={props.selectCategoryTitle}
          display={props.selectCategoryTitle}
          mode={"edit"}
          text={selectedFileCategoryName}
          value={selectedFileCategoryName}
          options={categoriesArray}
          onChange={changeTheCatgoryName}/>
          
      </div>
      <div className={`fieldgroup clearfix`}>
        <div>
          <label className={`form-label`}>{props.selectFileTitle}</label>
          <div className={`uploadcustome`} tid='uploadLabelButton'>
            <input 
                tid='uploadFileInput' 
                id={`cupload`}  
                style={{display: 'none'}}  
                ref={ref => inputRef = ref} 
                className={`invisible position-absolute`} 
                type="file" 
                name="file" 
                accept={filteredExtentions.join()} 
                onChange={onSelectedFileChangeHandler}/>
            <label htmlFor="cupload" tid="updatedFileName">
              <FontAwesomeIcon style={{ marginRight: "10px" }} className="icon-action" icon={faFile} />
              <span tid="fileNameSpan" style={{cursor:'pointer'}} tabIndex='0' role='button' aria-controls="cupload" onKeyDown={event => onSelectedFileThroughTab(event)} onClick={event => onSelectedFileThroughTab(event)} aria-label={selectedFileName}>{selectedFileName}</span>
            </label>
          </div>
        </div>
      </div>
  </>);
};

export default SelectUploadFile;
