import React, { Component } from "react";
import { getDisplayText, getControlId } from "../../utils";
import { Row, Form, Col } from "react-bootstrap";
import DisplayButton from "../button";

class SMSForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionlPhone: "",
      inActive: false
    };
  }

  setOptionalPhone = e => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      this.setState({ optionlPhone: e.target.value });
      if (e.target.value.length > 0 && e.target.value.length < 10) {
        this.setState({ inActive: true });
      } else {
        this.setState({ inActive: false });
        this.props.dissmiss();
      }
    }
  };

  render() {
    const regContent = this.props.regContent;
    const content = regContent.contentResource ? regContent.contentResource.contents : [];
    const btnSendCode = getDisplayText(content, "btnSendCode");
    const lblDetail1 = getDisplayText(content, "lblDetail1");
    const lblDetail2 = getDisplayText(content, "lblDetail2");
    const lblDetailOptionlPhone = getDisplayText(content, "lblDetailOptionlPhone");
    const lblNoPhoneNumberOnFileMsg = getDisplayText(content, "lblNoPhoneNumberOnFileMsg");
    const lblOptionlPhone = getDisplayText(content, "lblOptionlPhone");
    const lblTitle = getDisplayText(content, "lblTitle");
    const lblCancel = getDisplayText(content, "btnCancel");

    const handleSubmit = event => {
      event.preventDefault();
      const options = {
        PhoneOrEmail: this.state.optionlPhone ? this.state.optionlPhone : "",
        TypeID: this.props.regContent.twoFATypeId,
        IsRegistration: true,
        resendCount: 0
      };
      return this.props.verificationContent(options);
    };

    return (
      <div className="conatiner" role="main" id="maincontent">
        <div className="login-holder">
          <Row className="h-100">
            <div className="col-12 login-image-holder"></div>
            <div className="logincontainer d-md-flex align-items-center align-content-center justify-content-between">
              <div className="holder login-form description panel d-none d-md-block">
                {/* <h1 className="login-heading d-sm-block d-md-none">{lblTitle.display}</h1> */}
                <span dangerouslySetInnerHTML={{ __html: lblDetail2.display }} />
              </div>
              <div className="form-group login-form mobile-login-form pb-5 pb-md-0" xs={{ order: 1 }} md={{ order: 2 }} lv={{ order: 2 }}>
                <h1 className="login-heading d-md-block">{lblTitle.display}</h1>
                <div className="login-form description panel d-md-none">
                  <span dangerouslySetInnerHTML={{ __html: lblDetail2.display }} />
                </div>
                <div className="holder">
                  <Form id="login-container" className="loginform" noValidate onSubmit={handleSubmit}>
                    {/* <h1
                                            className='register-heading d-none d-md-block'
                                            aria-label={lblTitle.textToRead}
                                        >
                                            {lblTitle.display}
                                        </h1> */}
                    <Row>
                      <Col className="col-12 paddingbottom">
                        {regContent.isPhoneNoExists ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: lblDetail1.display
                            }}
                          />
                        ) : (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: lblNoPhoneNumberOnFileMsg.display
                            }}
                          />
                        )}
                        {regContent.showOptionalPhone && regContent.isPhoneNoExists && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: lblDetailOptionlPhone.display
                            }}
                          />
                        )}
                      </Col>
                    </Row>
                    <Row>
                      {regContent.showOptionalPhone && (
                        <Col className="col-12 paddingbottom">
                          <label htmlFor={getControlId(lblOptionlPhone.textToRead)} className="label-heading">
                            {lblOptionlPhone.display}
                          </label>
                          <Form.Control
                            type="text"
                            autoComplete="off"
                            placeholder={lblOptionlPhone.display}
                            className="form-control login-input-field"
                            id={getControlId(lblOptionlPhone.textToRead)}
                            ref="optionlphone"
                            value={this.state.optionlPhone}
                            minLength="10"
                            maxLength="10"
                            onChange={this.setOptionalPhone}
                          />
                        </Col>
                      )}
                      <Col>
                        <div className={`btns-forgot d-flex justify-content-between`}>
                          {regContent.allowCancel && <DisplayButton className="outline-secondary" onClick={this.handleCancel} controlId="cancelBtn" displayName={lblCancel.display} />}
                          <DisplayButton className="secondary" type="submit" disabled={this.state.inActive} displayName={btnSendCode.display} />
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>

              {/* <div className='holder login-form description panel'>
                                <span
                                    title={lblDetail2.textToRead}
                                    role='heading'
                                    aria-label={lblDetail2.textToRead}
                                    dangerouslySetInnerHTML={{
                                        __html: lblDetail2.display
                                    }}
                                />
                            </div> */}
            </div>
          </Row>
        </div>
      </div>
    );
  }
}

export default SMSForm;
