import React, { Component, Fragment }  from 'react';
import { Form } from 'react-bootstrap';
import { getDisplayText } from '../utils';
import { FormGroup } from 'reactstrap';
import { saveChangeDependentCoverage } from '../actions/userActions';
import { trackPromise } from 'react-promise-tracker';
import { connect } from 'react-redux';
import DisplayButton from './button';
import _ from 'underscore';

class ChangeDependedCoverage extends Component {

  constructor (props) {
    super(props);

    this.state = {
      dependentsThisPlan : props.dependentsThisPlan ?  props.dependentsThisPlan : [],
      dependentsOtherPlan: props.dependentsOtherPlan ? props.dependentsOtherPlan : [],
      
      selectedValue: {
        dependentsThisPlan: props.dependentsThisPlan ? props.dependentsThisPlan.findIndex(item => item.selected === true) : '-1',
        dependentsOtherPlan: props.dependentsOtherPlan ? props.dependentsOtherPlan.findIndex(item => item.selected === true) : '-1'
      }
    }
  }

  handleSelect = event => {
    const list = this.state[event.target.name]
    const selectedValue = this.state.selectedValue

    if (selectedValue[event.target.name] !== '-1' && !this.props.isMultiSelectDependents) {
      const prevInd = parseInt(selectedValue[event.target.name])
      list[prevInd].selected = !list[prevInd].selected
    }
    
    list[parseInt(event.target.value)].selected = !list[parseInt(event.target.value)].selected
    selectedValue[event.target.name] = event.target.value

    this.setState({[event.target.name]: list, selectedValue})
  }

  handleSave = () => {
    if (this.props.benefitURL)
      trackPromise(this.props.saveChangeDependentCoverage(this.state.dependentsThisPlan, this.state.dependentsOtherPlan, this.props.benefitURL));
    else
      trackPromise(this.props.saveChangeDependentCoverage(this.state.dependentsThisPlan, this.state.dependentsOtherPlan));
    this.props.handleClose()
  }

  checkBoxList = (listName, isMultiSelectDependents) => {
    const options = []
    _.forEach(this.state[listName], (opt, i) => {
      const uniqID = `${listName}-${opt.text}-${opt.value}-${i}`
      options.push(
        <div tid="listOp" key={uniqID} className={`mb-3 w-100 float-left`}>
          <Form.Check 
            tid="optionChkBox"
					  type={isMultiSelectDependents ? 'checkbox' : 'radio'}
					  id={uniqID}
            key={uniqID}
            name={listName}
            disabled={!opt.enabled}
            aria-disabled={!opt.enabled}
            value={i}
            aria-checked={isMultiSelectDependents ? opt.selected : (
              i === parseInt(this.state.selectedValue[listName]) ? true : false
            )}
					  label={opt.text}
            checked={isMultiSelectDependents ? opt.selected : (
              i === parseInt(this.state.selectedValue[listName]) ? true : false
            )}
            onChange={this.handleSelect}
            onSelect={this.handleSelect}/>
				</div>
      )
    })

  return (options)
}
 
  render() {
    const isMultiSelectDependents = this.props.isMultiSelectDependents
    const thisPlan = this.checkBoxList("dependentsThisPlan", isMultiSelectDependents)
    const otherPlan = this.checkBoxList("dependentsOtherPlan", isMultiSelectDependents)
    
    const lblNoDependents = getDisplayText(this.props.contents, 'lblNoDependents')
    const lblAddDependentsNote = getDisplayText(this.props.contents, 'lblAddDependentsNote')
    const lblDependentCoveredUnderAnotherPlan = getDisplayText(this.props.contents, 'lblDependentCoveredUnderAnotherPlan')
    const btnLblSave = getDisplayText(this.props.contents, 'btnSave')
    const btnLblCancel = getDisplayText(this.props.contents, 'btnCancel')
    const btnAddDependent = getDisplayText(this.props.contents, 'btnAddDependent')

    return (<div className={`changedependentcoverage clearfix`}>
      { this.state.dependentsThisPlan.length > 0 ?
        <>
          <FormGroup tag="fieldset" className="clearfix mb-0" controlId={`thisPlan`}>{thisPlan}</FormGroup>
          { this.state.dependentsOtherPlan.length > 0 &&<><div className={`titlebox clearfix`}>
            <h1 className="modal-title">{lblDependentCoveredUnderAnotherPlan.display}</h1>
          </div>
          <FormGroup tag="fieldset" className="clearfix" controlId={`otherPlan`}>{otherPlan}</FormGroup></>}
        </> :
        <div>
          <div>{lblNoDependents.display}</div>
          <DisplayButton className="outline-secondary btn-addDependent mt-1 mb-2" displayName={btnAddDependent.display}  onClick={this.props.handleAddDependent} />
        </div>
      }
      <div className={`message mb-3`}>
        <Fragment> <div className={`m-0`}  dangerouslySetInnerHTML={{__html: lblAddDependentsNote.display}} /> </Fragment>
      </div>
      <div className="ml-popupbuttons d-flex justify-content-between border-top">
        <DisplayButton className="outline-secondary btn-sm btn-cancel" displayName={btnLblCancel.display}  onClick={this.props.handleClose} />
        {this.state.dependentsThisPlan.length > 0 && <DisplayButton controlId="btnSave" className="outline-secondary btn-sm btn-save" onClick={this.handleSave} displayName={btnLblSave.display}  disabled={ !(this.state.dependentsThisPlan.length > 0) }/>}
      </div>
    </div>)
  }
}

const mapStateToProps = (state) => {
  return {benefit: state.userReducer.benefit
         }
};

export default connect(mapStateToProps, 
  { saveChangeDependentCoverage}
)(ChangeDependedCoverage);
