import React from 'react';

const Contact = () => {
    return (
        <div className="">
          Contact
        </div>
    );
}

export default Contact;