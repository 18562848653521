import {
  SUCCESS,
  FAILURE,
  GET_MENU,
  CHANGE_LOCALE, 
  GET_SECOND_MENU,
  DISSMISS_MESSAGE,
  GET_LOGIN_CONTENT,
  GET_HEADER_IMAGES,
  GET_WELCOME_CONTENT,
  TEMPORARY_PASSWORD_CONTENT,
  GET_FORGOT_PASSWORD_CONTENT,
  GET_LIFE_EVENT_CHANGE,
  SET_CLIENT_NAME,
  IS_DISABLED,
  SET_CLIENT_TYPE,
  SET_HEADER_INFO,
  SET_MINI_CART_INITIAL_STATE,
  FOCUS_INITIALIZING,
  GET_TWOFA_REG_CONTENT,
  GET_REGISTRATION_FORM,
  GET_VERIFICATION_CONTENT,
  GET_TFA_SETTINGS,
  GET_CLIENT_STYLES_CONTENT,
  FOCUS_IS_WELCOME,
  BENEFIT_ERROR_ID,
  SHOW_WARNING_MESSAGE
  } from "../actions/types.js";
  import Defaults from "../helpers/defaults";
  import _ from 'underscore';
import { USER_TOKEN } from "../helpers/storage.js";

import { registerLocale, setDefaultLocale } from 'react-datepicker';
import en from "date-fns/locale/en-CA";
import fr from "date-fns/locale/fr-CA";

registerLocale("en", en);
registerLocale("fr", fr);

  const initialState = {
    loginContent: Defaults.loginContent,
    twoFARegContent: Defaults.twoFARegContent,
    images: Defaults.headerImages,
    menu: Defaults.menu,
    menu2: '',
    welcomeContent: Defaults.welcomeContent,
    forgotPasswordContent: Defaults.forgotPasswordContent,
    temporaryPasswordContent: Defaults.temporaryPasswordContent,
    lifeEventChangeContent: Defaults.lifeEventChangeContent,
    messageType: '',
    message: '',
    screens: [],
    locale: USER_TOKEN.get().lang || Defaults.lang,
    language: Defaults.language,
    client: USER_TOKEN.get().client,
    clientStyle: JSON.parse(localStorage.getItem("style")) ? JSON.parse(localStorage.getItem("style")) : {},
    headerInfo: USER_TOKEN.get().employeeInfo ? USER_TOKEN.get().employeeInfo : {},
    miniCartInitialState: false,
    focusInitializing: false,
    regContent:[],
    verificationContent:[],
    tfaSettings: [],
    stylesContent: [],
    isWelcomeFocus: false,
    benefitErrorID:0,
    showWarningMessage:false
  };

  setDefaultLocale(initialState.locale.substring(0,2));

  export default function(state = initialState, action) {
    switch (action.type) {
      case GET_LOGIN_CONTENT: {
        return {
          ...state,
          loginContent: action.payload
        };
      }
      case GET_CLIENT_STYLES_CONTENT: {
        return {
          ...state,
          stylesContent: action.payload
        };
      }
      case GET_FORGOT_PASSWORD_CONTENT: {
        return {
          ...state,
          forgotPasswordContent: action.payload
        };
      }
      case TEMPORARY_PASSWORD_CONTENT: {
        return {
          ...state,
          temporaryPasswordContent: action.payload
        };
      }
      case GET_HEADER_IMAGES: {
        return {
          ...state,
          images: action.payload.result,
          language: action.payload.contentResource.contents

        };
      }
      case GET_WELCOME_CONTENT: {
        return {
          ...state,
          welcomeContent: action.payload
        };
      }
      case GET_MENU: {
        return {
          ...state,
          menu: action.payload,
        };
      }
      case FOCUS_INITIALIZING: {
        return {
          ...state,
          focusInitializing: action.payload,
        }
      }
      case IS_DISABLED: {
        let tempMenu = state.menu2.mainMenu
        for (let item in tempMenu){
          if (action.payload instanceof Array) { 
            if (tempMenu[item].actionLinks instanceof Array) {
              for (let index in tempMenu[item].actionLinks) {
                if (action.payload[0].actinLink === tempMenu[item].actionLinks[index].actinLink) {
                  tempMenu[item].isDisabled = false
                  return {
                    ...state,
                    menu2: {mainMenu: tempMenu}
                  }
                }
              }

            }
  
          } else if(tempMenu[item].actinLink === ""){
            for (let index in tempMenu[item].subMenus){
              if(action.payload === tempMenu[item].subMenus[index].actinLink){
                tempMenu[item].subMenus[index].isDisabled = false
                return {
                  ...state,
                  menu2: {mainMenu: tempMenu}
                }
              }
              }
          } else {
            if(action.payload === tempMenu[item].actinLink){
              tempMenu[item].isDisabled = false
              return {
                ...state,
                menu2: {mainMenu: tempMenu}
              }
            }
          }
          

        }
        return {
          ...state,
          
        }
      }
      case GET_SECOND_MENU: {
        const po = makePageOrder(action.payload)
        const screens = po.screens
        const indicators = po.indicators
        return {
          ...state,
          menu2 : action.payload,
          screens,
          indicators
        };
      }
      case GET_LIFE_EVENT_CHANGE: {
        return {
          ...state,
          lifeEventChangeContent : action.payload
        };
      }
      case FAILURE: {
        return {
          ...state,
          message: action.payload,
          messageType: 'danger'
        }
      }
      case SUCCESS: {
        return {
          ...state,
          message: action.payload,
          messageType: 'success'
        }
      }
      case SET_MINI_CART_INITIAL_STATE: {
        return {
          ...state,
          miniCartInitialState: action.payload,
        }
      }
      case DISSMISS_MESSAGE: {
        return {
          ...state,
          message: {}, 
          messageType: null
        }
      }
      case CHANGE_LOCALE: {
        USER_TOKEN.setLang(`${action.payload}-CA`)
        setDefaultLocale(action.payload)
        return {
          ...state,
          locale: action.payload
        }
      }
      case SET_CLIENT_NAME: {
        USER_TOKEN.setClientName(action.payload)
        return {
          ...state,
          client: action.payload
        }
      }
      case SET_CLIENT_TYPE: {
        localStorage.setItem("style", JSON.stringify(action.payload))
        return {
          ...state,
          clientStyle: action.payload,
        }
      }
      case SET_HEADER_INFO: {
        USER_TOKEN.setEmployeeInfo(action.payload)
        return {
          ...state,
          headerInfo: action.payload
        }
      }
      case GET_TWOFA_REG_CONTENT: {
        return {
          ...state,
          twoFARegContent: action.payload
        };
      }
      case GET_REGISTRATION_FORM: {
        return {
          ...state,
          regContent: action.payload
        }
      }
      case GET_VERIFICATION_CONTENT: {
        return {
          ...state,
          verificationContent: action.payload,
          resendCodeInterval: action.payload.resendCodeInterval
        }
      }
      case GET_TFA_SETTINGS: {
        return {
          ...state,
          tfaSettings: action.payload,
        }
      }
      case FOCUS_IS_WELCOME: {
        return {
          ...state,
          isWelcomeFocus: action.payload,
        }
      }
      case BENEFIT_ERROR_ID: {
        return {
          ...state,
          benefitErrorID: action.payload,
        }
      }
      case SHOW_WARNING_MESSAGE: {
        return {
          ...state,
          showWarningMessage: action.payload,
        }
      }
      
      default:
        return state;
    }
  }
  
  const makePageOrder = (data) => {
    let menu = [];
    const keys  = _.keys(data.mainMenu)
    let actionLinks = []
    let screens = []
    let ii = 0
    let j = 0
    let indicators = [];
    _.each(keys, (k) => {
      let menuItem = data.mainMenu[k]
        let subMenus = menuItem.subMenus
        menu.push(menuItem)
        let link
        if (menuItem.actinLink && !menuItem.actionLinks) {
          actionLinks.push(menuItem.actinLink)
          link = menuItem.actinLink
        } else if (menuItem.actionLinks) {
          actionLinks.push(menuItem.actionLinks)
          link = menuItem.actionLinks
        }
        screens.push({link:link, name:k , order:ii+j, display:menuItem.display});
        ii++
        _.each(subMenus, (submenuItem, key) => {
          menu.push(submenuItem)
          actionLinks.push(submenuItem.actinLink)
          if(submenuItem.actinLink.indexOf('/')>-1) indicators.push(submenuItem);
          screens.push({link:submenuItem.actinLink, name:key , order:ii+j, display:submenuItem.display})
          j++
        })
    })
    return {screens, indicators}
  }