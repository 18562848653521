import React, { Component } from 'react';
import { FormCheck, Form } from 'react-bootstrap';
import _ from 'underscore';

class MultiRadioComponent extends Component {
	render () {
		const props = this.props
		const options = []
		const propsOptions = _.reject(props.options, (op)=>(op.text === undefined || op.text === 'undefined' || op.text === 'Undefined' || op.text === 'indéfinie' || op.text === 'Indéfinie'||op.text === "Non défini"))
		_.each(propsOptions, (opt,i)=>{
			const uniqID = `${props.component}-${props.name}-${opt.text}-${opt.value}-${i}`
			options.push(
				<div key={uniqID} className={`mb-3 w-${props.width || 50} float-left`}>
					<FormCheck  inline
								type={props.type || 'radio'}
					            id={uniqID}
					            key={uniqID}
					            value={opt.value}
								label={opt.text}
								aria-checked={props.selected === opt.value || parseInt(props.selected) === opt.value || props.selected === parseInt(opt.value)}
					            checked={props.selected === opt.value || parseInt(props.selected) === opt.value || props.selected === parseInt(opt.value)}
					            {...props}/>
				</div>
			);
		});
		return (

				<div className="form-holder">
					{this.props.display && <Form.Label {...this.props}><span dangerouslySetInnerHTML={{__html: this.props.display}}/></Form.Label>}
					<Form.Group className="clearfix mb-0" controlId={`${props.component}-${props.name}`}>
						{options}
					</Form.Group>
				</div>

		)
	}
}
export default MultiRadioComponent;
