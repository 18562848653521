import React from 'react';
import { Container, Col, Card, Row } from 'react-bootstrap';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import ViewEditText from '../ViewEditText';
import { getDisplayText, getByValue, makeFlexitDateObj, CustomDeletePersonIcon,setModalAttribute} from "../../utils";
import MultiRadioComponent from '../MultiRadioComponent';
import DateComponent from '../DateComponent';
import { trackPromise } from 'react-promise-tracker';
import DisplayButton from '../button';
import NewMultiRadioComponent from '../NewMultiRadioComponent';
 
class DependentDetail extends React.Component {
    constructor(props) {
       super(props);
        this.state = {
            showRemoveConfirmModal: false,
            dependent: this.props.dependent,
            editMode: true,
            smokerDeclarationChanged: false,
            errorMessage: [],
            studentValid: true,
            isRelationshipVisible: true
        };
    }
 
    reverseMode = e => {
        this.setState({ editMode: !this.state.editMode }, () => {
            if (this.state.editMode === true) {
                this.props.disableNext();
                let dependent = { ...this.props.dependent };
                let isRelationshipVisible = this.state.isRelationshipVisible;
                if (
                    this.state.editMode === true &&
                    dependent.isChildRelationshipEditable === true
                ) {
                    isRelationshipVisible = false;
                }
                this.setState({ dependent, isRelationshipVisible });
            } else {
                this.setState({
                    errorMessage: [],
                    isRelationshipVisible: true
                });
                this.props.enableNext();
            }
        });
    };
 
    showDeleteConfirmModal = event => {
        this.setState({ ...this.state, showRemoveConfirmModal: true });
    };
 
    handleClose = () => {
        this.setState({ ...this.state, showRemoveConfirmModal: false });
    };
    deleteDependent = () => {
        const { navigationError } = this.props;
        trackPromise(
            this.props.deleteDependent(this.props.dependentId, () => {
                trackPromise(
                    this.props.getDependents(this.props.dependentType)
                );
                if (
                    !(navigationError === undefined || navigationError === null)
                ) {
                    this.props.setNavigationError(null);
                }
            })
        );
 
        this.handleClose();
    };
 
    changeSmokerStatus = e => {
        let dependent = { ...this.state.dependent };
        dependent['smokerStatus'] = parseInt(e.target.value);
        this.setState({ dependent });
        this.setState({ smokerDeclarationChanged: this.state.smokerDeclarationChanged =true });
        trackPromise(
            this.props.updateDependent(
                dependent,
                err => {
                    this.setState({ errorMessage: err });
                },
                () => {
                    trackPromise(
                        this.props.getDependents(this.props.dependentType)
                    );
                }
            )
        );
    };
 
    changeStudentDeclaration = e => {
        let dependent = { ...this.state.dependent };
        dependent['studentDeclaration'] = e.target.value;
        this.setState({ dependent });
    };
 
    changeInput = e => {
        let dependent = { ...this.state.dependent };
        const { name, value } = e.target;
        dependent[name] = value;
        this.setState({ dependent });
    };
 
    changeDate = e => {
        let dependent = { ...this.state.dependent };
        dependent['dateOfBirth'] = makeFlexitDateObj(e);
        this.setState({ dependent });
    };
 
    changeCoverUnder = e => {
        let dependent = { ...this.state.dependent };
        const { name } = e.target;
        dependent[name] = !dependent[name];
        this.setState({ dependent });
        trackPromise(
            this.props.updateDependent(dependent, err => {
                this.setState({ errorMessage: err });
            },() => trackPromise(this.props.getDependents(this.props.dependentType))
            )
        );
    };
 
    changeStudentStatus = e => {
        this.props.enableNext();
        let dependent = { ...this.state.dependent };
        const { name } = e.target;
        dependent[name] = !dependent[name];
        this.setState({ dependent });
        /*
        trackPromise(
            this.props.updateStudentStatus(this.props.dependentType, this.state.dependent, err => {
                this.setState({ errorMessage: err });
            })
        );
        trackPromise(this.props.getDependents(this.props.dependentType));
        */
       trackPromise(
            // this.props.enableNext(),
            this.props.updateStudentStatus(this.props.dependentType, dependent, err => {
                this.setState({ errorMessage: err });
            },() => trackPromise(this.props.getDependents(this.props.dependentType))
            )
        );
    };
 
    updateStudentStatus = () => {
        trackPromise(
            this.props.updateStudentStatus(this.props.dependentType, this.state.dependent, err => {
                this.setState({ errorMessage: err });
            })
        );
        trackPromise(this.props.getDependents(this.props.dependentType));
    };
 
    validateStudentDeclaration = () => {
        this.setState({ studentValid: true });
        this.props.disableNext();
    };
    invalidateStudentDeclaration = () => {
        this.setState({ studentValid: false });
        this.props.enableNext();
    };
 
    changeRelationID = e => {
        const studentID = this.props.relationshipList.find(
           val => val.type === 'Student'
        ).value;
        const studentType = this.props.relationshipList.find(
           val => val.type === 'Student'
        ).type;
        let dependent = { ...this.state.dependent };
        dependent['relationshipID'] = parseInt(e.target.value);
        dependent['type'] = studentType;
        this.setState({ dependent });
        if (dependent['relationshipID'] === parseInt(studentID)) {
            this.validateStudentDeclaration();
        }
        if (dependent['relationshipID'] !== parseInt(studentID)) {
            this.invalidateStudentDeclaration();
        }
    };
 
    handleDownKeyPressed = (event, action = '') => {
        switch (event.which) {
            case 13: {
                if (action === 'edit') {
                    this.reverseMode();
                } else {
                    this.showDeleteConfirmModal();
                }
                break;
            }
            default:
                break;
        }
    };
 
    render = () => {
        /**
   * props.dependent has this fields :
  { dateOfBirth: {day: 24, month: 8, year: 1964},
    dependentID: 841,
    dependentStatus: 0, dependentType: null, description: null,
    descriptionFrench: null, firstName: "Kimberley", fullName: "Kimberley Testlastname",
    gender: "Female",
    initial: null, isCoveredOtherPlan: false,
    isCoveredThisPlan: true,lastName: "Testlastname",
    lifeEventEffectiveDate: null,lifeEventID: 0,
    relationshipID: 2,result: null,smokerStatus: 1,status: 1}
  */
        const props = this.props;
        const smokerDeclarationLabel = props.smokerDeclarationLabel;
        const spousalSmokerDeclarationOptional = props.spousalSmokerDeclarationOptional
        const { relationshipID } = this.props.dependent;
        // const canBeModified = props.canBeModified
 
        const canBeRemoved = props.canBeRemoved;
        const isAllowUpdateSudentStatus = props.isAllowUpdateSudentStatus
        const readOnly = props.readOnly;
 
        const lblBirthDateText = getDisplayText(
            props.contents,
            'lblBirthDateText'
        );
        const lblGenderText = getDisplayText(props.contents, 'lblGenderText');
        const lblRelationshipText = getDisplayText(
            props.contents,
            'lblRelationshipText'
        );
        const userRelationText = getByValue(
            props.relationshipListDisplay,
            props.dependent.relationshipID
        ).text;
        // const userRelationType = getByValue(props.relationshipListDisplay, props.dependent.relationshipID).type
        // const userRelationID = getByValue(props.relationshipListDisplay, props.dependent.relationshipID).value
 
        const nonSmokerMessage = getDisplayText(
            props.contents,
            'lblIsNonSmokerText'
        );
        const smokerMessage = getDisplayText(props.contents, 'lblIsSmokerText');
        let smokerStatusMessage = '';
        if (["0", 0].indexOf(props.dependent.smokerStatus) > -1 && !spousalSmokerDeclarationOptional && !this.state.smokerDeclarationChanged) smokerStatusMessage = nonSmokerMessage
        if (["1", 1].indexOf(props.dependent.smokerStatus) > -1 && !spousalSmokerDeclarationOptional && !this.state.smokerDeclarationChanged) smokerStatusMessage = smokerMessage
        let spouseList = [];
        let studentList = [];
 
        props.relationshipListDisplay.map(item => {
            if (item.type === 'Spouse') {
                spouseList.push(item.value);
            }
            return null;
        });

        props.relationshipList.map(item => {
            if (item.type === 'Student') {
                studentList.push(item);
            }
            return null;
        });
        /**
         *
         * lblDeleteDependentConfirmBtn
         * lblDeleteDependentHeader
         * lblDeleteDependentConfirmation
         * lblDeleteDependentConfirmation2
         */
        const lblDeleteDependentHeader = getDisplayText(
            props.contents,
            'lblDeleteDependentHeader'
        );
        const lblDeleteDependentConfirmBtn = getDisplayText(
            props.contents,
            'lblDeleteDependentConfirmBtn'
        );
        const lblDeleteDependentConfirmation = getDisplayText(
            props.contents,
            'lblDeleteDependentConfirmation'
        );
        const lblDeleteDependentConfirmation2 = getDisplayText(
            props.contents,
            'lblDeleteDependentConfirmation2'
        );
 
        const lblCoveredThisPlanCaptionText = getDisplayText(
            this.props.contents,
            'lblCoveredThisPlanCaptionText'
        );
        const lblCoveredOtherPlanCaptionText = getDisplayText(
            this.props.contents,
            'lblCoveredOtherPlanCaptionText'
        );
        const lblStudentDeclarationText = getDisplayText(
            props.contents,
            'lblStudentDeclarationText'
        );
        const lblStudentNeedToRemoveDuringLEorAE = getDisplayText(
            props.contents,
            'lblStudentNeedToRemoveDuringLEorAE'
        );
 
        const coverUnderThisPlan = [
            {
                text: lblCoveredThisPlanCaptionText.display,
                value: 'isCoveredThisPlan'
            }
        ];
        const coverUnderOtherPlan = [
            {
                text: lblCoveredOtherPlanCaptionText.display,
                value: 'isCoveredOtherPlan'
            }
        ];
        const childCanTurnStudent = [
            {
                text: lblStudentDeclarationText.display,
                value: 'isChildTurnStudent'
            }
        ];
        const {
            isEditableIsCoveredOtherPlan,
            isEditableIsCoveredThisPlan,
       //     isEditable,
            isEditableSmokerStatus,
            isChildRelationshipEditable,
            isChildCanTurnIntoStudent,
            isStudentStatusConfirmed
        } = this.props.dependent;
        const deleteIcon = getDisplayText(
            (this.props.contentFooter.contentResource &&
                this.props.contentFooter.contentResource.contents) ||
                {},
            'DeleteIcon'
        );
        /* const editIcon = getDisplayText(
           (this.props.contentFooter.contentResource &&
                this.props.contentFooter.contentResource.contents) ||
                {},
            'EditIcon'
        ); */
        //const btnSave = getDisplayText(this.props.contents, 'btnSave');
        const btnCancel = getDisplayText(
            (this.props.contentFooter.contentResource &&
                this.props.contentFooter.contentResource.contents) ||
                {},
            'btnCancel'
        );
        const closeButtonText = getDisplayText(
            (this.props.contentFooter.contentResource &&
                this.props.contentFooter.contentResource.contents) ||
                {},
            'btnClose'
        );
        return (
            <Container key={props.keyy}>
                <Row>
                    <Col>
                        <div className="w-100">
                            <Card>
                                <Card.Header>
                                    <h2 className="float-left ml-title">{props.dependent.fullName}</h2>
                                    {readOnly === true ? (
                                        ''
                                    ) : (
                                        <div className="float-right icon-holder">
                                            {/*{isEditable &&
                                                (isEditableIsCoveredOtherPlan ||
                                                    isEditableIsCoveredThisPlan ||
                                                    isEditableSmokerStatus ||
                                                    isChildCanTurnIntoStudent) && (
                                                    <span
                                                        className="icon-action edit-icon"
                                                        tabIndex="0"
                                                        aria-label={
                                                            editIcon.textToRead
                                                        }
                                                        role="button"
                                                        onClick={event =>
                                                            this.reverseMode(
                                                                event
                                                            )
                                                        }
                                                        onKeyDown={event =>
                                                            this.handleDownKeyPressed(
                                                                event,
                                                                'edit'
                                                            )
                                                        }
                                                    >
                                                        {' '}
                                                        <FontAwesomeIcon
                                                            icon={faPencilAlt}
                                                        />{' '}
                                                    </span>
                                                )*/}
                                            {canBeRemoved && (
                                                <span
                                                    tid="removeBtn"
                                                    tabIndex="0"
                                                    aria-label={
                                                        deleteIcon.textToRead+" "+props.dependent.fullName
                                                    }
                                                    role="button"
                                                    onClick={event =>
                                                        this.showDeleteConfirmModal(
                                                            event
                                                        )
                                                    }
                                                    onKeyDown={event =>
                                                        this.handleDownKeyPressed(
                                                            event,
                                                            'delete'
                                                        )
                                                    }
                                                    className="icon-action edit-icon close-icon"> <CustomDeletePersonIcon /> </span>
                                            )}
                                        </div>
                                    )}
                                </Card.Header>
                                <Card.Body className="personal-info-holder">
                                    {this.state.errorMessage.length > 0 ? (
                                        typeof this.state.errorMessage ===
                                        'string' ? (
                                            <div className="col-12">
                                                <p
                                                    className="formErrorPStyle"
                                                    role="alert"
                                                >
                                                    {this.state.errorMessage}
                                                </p>
                                            </div>
                                        ) : (
                                            this.state.errorMessage.map(
                                                error => (
                                                    <div className="col-12">
                                                        <p
                                                            className="formErrorPStyle"
                                                            role="alert"
                                                        >
                                                            {error}
                                                        </p>
                                                    </div>
                                                )
                                            )
                                        )
                                    ) : (
                                        ''
                                    )}
                                    <Row>
                                        {this.state.isRelationshipVisible && (
                                            <div className="col-sm-12 col-md-6">
                                                <div className="peronal-info-details">
                                                    <ViewEditText
                                                        type="select"
                                                        display={
                                                            lblRelationshipText.display
                                                        }
                                                        aria-label={
                                                            lblRelationshipText.textToRead +
                                                            ' ' +
                                                            userRelationText
                                                        }
                                                        mode={props.mode}
                                                        text={userRelationText}
                                                        value={
                                                            props.dependent
                                                                .relationshipID
                                                        }
                                                        options={
                                                            props.relationshipListDisplay
                                                        }
                                                        name="maritalStatus"
                                                        onChange={
                                                            this.changeInput
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}
 
                                        <div className="col-sm-12 col-md-6">
                                            <div className="peronal-info-details">
                                                <DateComponent
                                                    name="dateOfBirth"
                                                    display={
                                                        lblBirthDateText.display
                                                    }
                                                    aria-label={
                                                        lblBirthDateText.textToRead +
                                                        ' ' +
                                                        props.dependent
                                                            .dateOfBirth.display
                                                    }
                                                    mode={props.mode}
                                                    date={
                                                        props.dependent
                                                            .dateOfBirth
                                                    }
                                                    onChange={this.changeDate}
                                                    isreadonly="true"
                                                    format={
                                                        props.dependent
                                                            .dateOfBirth
                                                            .dateFormat
                                                    }
                                                />
                                           </div>
                                        </div>
 
                                        <div className="col-sm-12 col-md-6">
                                            <div className="peronal-info-details">
                                                <ViewEditText
                                                    type="select"
                                                    display={
                                                        lblGenderText.display
                                                    }
                                                    aria-label={
                                                        lblGenderText.textToRead +
                                                        ' ' +
                                                        props.dependent.gender
                                                    }
                                                    mode={props.mode}
                                                    name="gender"
                                                    text={
                                                        props.dependent.gender
                                                    }
                                                    value={
                                                        props.dependent.gender
                                                    }
                                                    options={props.genderList}
                                                    onChange={this.changeInput}
                                                />
                                            </div>
                                        </div>
                                        {/* spouse smoker status */}
                                        {spouseList.includes(
                                            relationshipID.toString()
                                        ) && (
                                            <>
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="peronal-info-details">
                                                        {this.state.editMode === true && spousalSmokerDeclarationOptional === false ? (
                                                            readOnly === true ||
                                                            isEditableSmokerStatus ===
                                                                false ? (
                                                                <span>
                                                                    {
                                                                        smokerStatusMessage.display
                                                                    }
                                                                </span>
                                                            ) : (
                                                                <NewMultiRadioComponent
                                                                    name={`smokerStatus-${props.index}`}
                                                                    tid="smokerStatusRadio"
                                                                    component={`dependentDetailSmokerStatus-${props.index}`}
                                                                    display={
                                                                        smokerStatusMessage.display
                                                                    }
                                                                    selected={this.state.dependent.smokerStatus.toString()}
                                                                    options={
                                                                        this
                                                                            .props
                                                                            .smokerStatusList
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .changeSmokerStatus
                                                                    }
                                                                />
                                                            )
                                                        ) : (
                                                            <span>
                                                                {
                                                                    smokerStatusMessage.display
                                                                }
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                                {this.state.dependent
                                                    .smokerStatus === 0 &&
                                                    this.state.editMode ===
                                                        true && spousalSmokerDeclarationOptional === false &&
                                                    !(
                                                        readOnly === true ||
                                                        isEditableSmokerStatus ===
                                                            false
                                                    ) && (
                                                        <div className="col-sm-12">
                                                            <div className="peronal-info-details">
                                                            { this.state.smokerDeclarationChanged === true &&
                                                                <label
                                                                    className="alert alert-primary"
                                                                    role="alert"
                                                                >
                                                                    {
                                                                        smokerDeclarationLabel
                                                                    }
                                                                </label>
                                                            }
                                                           </div>
                                                        </div>
                                                    )}
                                            </>
                                        )}
                                        {this.state.editMode && (
                                            <div className="col">
                                                <div className="row">
                                                    {isEditableIsCoveredThisPlan && (
                                                        <div className="col-sm-12 ">
                                                            <div className="peronal-info-details ml-plan">
                                                                <MultiRadioComponent
                                                                    component={`dependentDetailisCoveredThisPlan-${props.index}`}
                                                                    name={`isCoveredThisPlan`}
                                                                    checked={
                                                                        this
                                                                            .state
                                                                            .dependent
                                                                            .isCoveredThisPlan
                                                                    }
                                                                    type="checkbox"
                                                                    options={
                                                                        coverUnderThisPlan
                                                                    }
                                                                    keyy={`${this.props.keyy}-status`}
                                                                    tid="changeCoverRadio"
                                                                    onChange={
                                                                        this
                                                                            .changeCoverUnder
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                    {isEditableIsCoveredOtherPlan && (
                                                        <div className="col-sm-12 ">
                                                            <div className="peronal-info-details ml-plan">
                                                                <MultiRadioComponent
                                                                    component={`dependentDetailisCoveredOtherPlan-${props.index}`}
                                                                    name={`isCoveredOtherPlan`}
                                                                    checked={
                                                                        this
                                                                            .state
                                                                            .dependent
                                                                            .isCoveredOtherPlan
                                                                    }
                                                                    type="checkbox"
                                                                    options={
                                                                        coverUnderOtherPlan
                                                                    }
                                                                    keyy={`${this.props.keyy}-status`}
                                                                    onChange={
                                                                        this
                                                                            .changeCoverUnder
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
 
                                        {/* update student status */}
                                        <div className="col-sm-12 col-md-12">
                                            <div className="clearfix dependenttypes">
                                                {!readOnly && this.state.editMode &&
                                                    isAllowUpdateSudentStatus &&
                                                    isChildCanTurnIntoStudent &&
                                                    isChildRelationshipEditable  && (
                                                        <NewMultiRadioComponent
                                                            component={`updateDependent-${props.index}`}
                                                            name={`updateDependentRelationshipID-${props.index}`}
                                                            vertical="true"
                                                            selected={
                                                                this.state
                                                                    .dependent
                                                                    .relationshipID
                                                            }
                                                            display={
                                                                lblRelationshipText.display
                                                            }
                                                            options={studentList}
                                                            keyy={`${this.props.index}-status`}
                                                            tid="changeStudentStatus"
                                                            onChange={
                                                                this
                                                                    .changeRelationID
                                                            }
                                                        />
                                                    )}
                                            </div>
                                            {!readOnly && this.state.editMode && (
                                                <div className="w-100">
                                                    {isAllowUpdateSudentStatus &&
                                                        isChildCanTurnIntoStudent &&
                                                        this.state
                                                            .studentValid &&
                                                            this.state.dependent.type === 'Student'  && (
                                                            <div className="peronal-info-details ml-plan bdr-container">
                                                                <MultiRadioComponent
                                                                    component={`isChildCanTurnStudent-${props.index}`}
                                                                    name={`isStudentStatusConfirmed`}
                                                                    checked={isStudentStatusConfirmed}
                                                                    type="checkbox"
                                                                    options={
                                                                        childCanTurnStudent
                                                                    }
                                                                    keyy={`${this.props.keyy}-status`}
                                                                    tid="changeStudentStatus"
                                                                    onChange={
                                                                        this
                                                                            .changeStudentStatus
                                                                    }
                                                                />
                                                                {!isStudentStatusConfirmed && (
                                                                    <div className="col">
                                                                        <label
                                                                            className="alert alert-primary"
                                                                            role="alert"
                                                                        >
                                                                            {
                                                                                lblStudentNeedToRemoveDuringLEorAE.display
                                                                            }
                                                                        </label>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                                    {/*<Row className="edit-btn infoeditbtns justify-content-between">
                                                        <Button
                                                            variant="outline-secondary"
                                                            className=""
                                                            onClick={event =>
                                                                this.reverseMode(
                                                                    event
                                                                )
                                                            }
                                                            onKeyDown={event =>
                                                                this.handleDownKeyPressed(
                                                                    event,
                                                                    'edit'
                                                                )
                                                            }
                                                        >
                                                            {btnCancel.display}
                                                        </Button>
                                                        <Button
                                                            tid="updateDependentBtn"
                                                            variant="outline-secondary"
                                                            className=""
                                                            onClick={
                                                                this
                                                                    .updateStudentStatus
                                                            }
                                                            disabled={
                                                                !this.state
                                                                    .dependent
                                                                    .isStudentStatusConfirmed &&
                                                                props.dependentType !==
                                                                    'updatestudent'
                                                            }
                                                        >
                                                            {btnSave.display}
                                                        </Button>
                                                    </Row>*/}
                                                </div>
                                            )}
                                        </div>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                </Row>
                <Modal
                    size="lg"
                    centered
                    isOpen={this.state.showRemoveConfirmModal}
                    aria-labelledby="modalHeader"
                    toggle={this.handleClose}
                    onOpened={() => setModalAttribute(lblDeleteDependentHeader.textToRead)}
                >
                    <div className="modal-header">
                        <h1 className="modal-title" id="modalHeading">
                                {lblDeleteDependentHeader.display}  
                        </h1>
                        <DisplayButton type="button"  className="close" displayName="×" arialabel={closeButtonText.display} onClick={this.handleClose}/> 
                    </div>
                    <ModalBody>
                        <p
                            id="confirmationOne"
                        >
                            <b>{lblDeleteDependentConfirmation.display}</b>
                        </p>
                        <p
                            id="confirmationTwo"
                            className="ml-popupmessagetext align-left"
                        >
                           {lblDeleteDependentConfirmation2.display}
                        </p>
                    </ModalBody>
                    <ModalFooter className="ml-popupbuttons justify-content-between pt-3 border-top">
                    <DisplayButton className="outline-secondary btn-cancel" displayName={btnCancel.display} onClick={this.handleClose} />
                        <DisplayButton
                            controlId="deleteDependentBtn"
                            className="outline-secondary"
                            onClick={this.deleteDependent}
                            displayName= {lblDeleteDependentConfirmBtn.display}
                        />  
                    </ModalFooter>
                </Modal>
            </Container>
        );
    };
}
 
export default DependentDetail;
