import React, { Component, Fragment }  from 'react';
import { connect } from "react-redux";
import { getDisplayText } from '../utils';
import { withRouter } from 'react-router-dom';
import NextPrevious from './NextPrevious';
import { getLifeChangeEvent, dissmiss } from "../actions/contentActions";
import { trackPromise } from 'react-promise-tracker';

import _ from 'underscore';

class LifeChangeEventClaim extends Component {

  nextClick = () => {}

  UNSAFE_componentWillMount = () => {
    trackPromise(this.props.getLifeChangeEvent());
  }

  componentWillUnmount = () => {
    this.props.dissmiss()
  }

  render = () => {
    const contents1 = this.props.contents.contentResource ? this.props.contents.contentResource.contents : []
    const contents2 = (this.props.contents.contentResource && this.props.contents.contentResource.controlResource) ? this.props.contents.contentResource.controlResource : []
    const contents = _.union(contents1, contents2)

    const lblDisclaimerTitle = getDisplayText(contents, 'lblDisclaimerTitle')
    const LifeEventDisclaimer = getDisplayText(contents, 'LifeEventDisclaimer')

    const iAgree = this.props.lblNext || getDisplayText(contents, 'lblIAgree')
    const idontAgree = this.props.lblCancel || getDisplayText(contents, 'lblIDisagree')

    return (
        <div className="ml-lifechangeeventcontent">
          {/* <div className="col-xs-12 col-md-10 col-lg-8"> */}
            <Fragment><div dangerouslySetInnerHTML={{__html: lblDisclaimerTitle.textToRead}}/></Fragment>
            <hr/>
            <Fragment><div className="textcontent" dangerouslySetInnerHTML={{__html: LifeEventDisclaimer.textToRead}}/></Fragment>
            <div className="ml-btnslifechangeevent">
                <NextPrevious 
                          lastPage={false}
                          firstPage={true}
                          nextClick={this.props.agreeDisclaimerPresses}
                          cancelClicked={this.props.cancelClicked}
                          previousClick={this.props.cancelClicked}
                          lblNext={iAgree}
                          lblCancel={idontAgree}
                          lblPrevious={idontAgree}
                          showButtons={true}/>
            </div>
          {/* </div> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { contents : state.contentReducer.lifeEventChangeContent
         , pageSetting: state.userReducer.pageSetting
         }
};

export default connect(
mapStateToProps,
{ getLifeChangeEvent, dissmiss }
)(withRouter(LifeChangeEventClaim));
