import React from 'react';
import { Link } from "react-router-dom";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isMobile } from "react-device-detect";

const ConfirmedItem = props => {

    const tabIndexValue = 0;
    const itemPressedThroughTab = (event) => {
        switch(event.which){
            case 13:{
                props.navigateClicked();
                break;
            }
            default:
				break;
        }
    }
    const period = localStorage.getItem("parameters") ? "?" + localStorage.getItem("parameters") : "";
    return (
        <div className="mb-3 item" style={{ backgroundColor: props.backColor }} 
        // aria-label={props.title}
        >
            <div className="d-flex justify-content-between p-3 confirmationitem">
                <p className="m-0">{props.title}</p>
                {props.confirmationStatment ? 
                (<div className="d-flex justify-content-between align-items-center clickbtn" tid="itemPressedThroughTab" tabIndex={tabIndexValue} onClick={props.navigateClicked} onKeyDown={(event) => {itemPressedThroughTab(event)}}>
                    <FontAwesomeIcon icon={faFileDownload} />
                    {isMobile ?
                        <Link variant="link" to={`/ReviewCoverage/CurrentCoverage${period}`} 
                        // aria-label={props.buttonTitle} 
                        className="m-0 pl-2">{props.buttonTitle}</Link>
                        : 
                        <Link variant="link" target = "blank" to={`/ReviewCoverage/CurrentCoverage${period}`} aria-label={props.buttonTitle+" "+props.lblNewTabText} className="m-0 pl-2">{props.buttonTitle}</Link>
                    }
                </div>
                )
                :
                (<div tid='navigateclick' className="d-flex justify-content-between align-items-center clickbtn" 
                // aria-label={props.buttonTitle} 
                tabIndex={tabIndexValue} onKeyDown={(event) => {itemPressedThroughTab(event)}} onClick={props.navigateClicked}>
                    <FontAwesomeIcon icon={faFileDownload}/>
                    <p className="m-0 pl-2">{props.buttonTitle}</p>
                </div>
                )
                }
            </div>
        </div>
    )
}

export default ConfirmedItem;
