import React, { Component } from "react";
import {Helmet} from "react-helmet";

import Login from './Login';
import Welcome from './Welcome';
import Registration from './registration';
import Verification from './registration/Verification';
import TwoFASettings from './registration/TwoFASettings';
import Contact from './Contact';
import CustomAlert from './CustomAlert';

import ForgotPassword from './ForgotPassword';
import ChangePassword from './ChangePassword';

// import PersonalInfoTab from './personalInfo/PersonalInfoTab';
import ReviewPersonalInfo from './personalInfo/ReviewPersonalInfo';
import Dependents from './personalInfo/Dependents';

import ReviewCoverage from './coverages/ReviewCoverage';
import BenefitWellness from './BenefitWellness';

import BenefitShell from "./BenefitShell";
import PackageGroup from './PackageGroup';
import BaseContentPageWithMenu from "./BaseContentPageWithMenu";

import { logoff, putWelcome } from "../actions/userActions";
import { setClientName } from "../actions/contentActions";

import { Route, Redirect, Switch, matchPath, withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import LifeChangeEvent from "./LifeChangeEvent";
import LifeChangeEventClaim from "./LifeChangeEventClaim";
// import Beneficiaries from "./personalInfo/BeneficiariesModal";
import Beneficiaries from './Beneficiaries';
import ExternalLinks from "./ExternalLinks";

import UploadDocs from './uploadFiles/UploadFile';
import EnrollmentConfirmation from './enrollmentConfirmation/EnrollmentConfirmation';

import FlexDollars from './FlexDollars';
import Coverage from "./coverages/Coverage";
import Current from "./coverages/Current";
import EnrollmentSummary from "./EnrollmentSummary";
import BenefitSummary from "./BenefitSummary";
import SummaryBeneficiaries from "./SummaryBeneficiaries";
import SummaryESignBeneficiaries from "./SummaryESignBeneficiaries";
import SummaryDependents from "./SummaryDependents";
import SummaryPersonal from "./SummaryPersonal";
import BeneficiariesAll from "./BeneficiariesAll";
import PackageBeneficiaries from "./PackageBeneficiaries";
import MultiBenefitShell from "./MultiBenefitShell";
import PackageMultiBeneficiaries from "./PackageMultiBeneficiaries";
import BeneficiariesReallocate from "./BeneficiariesReallocate";


class Content extends Component {
  constructor(props) {
    super(props)
    this.state = { 
      isLoading: true,
    }

    this.pathName = null
    this.searchParams = null
  }
  componentDidMount() {
    const { history } = this.props;

    history.listen((nextProps, action) => {
      if (action === "PUSH") {
        if (nextProps.pathname !== this.pathName || nextProps.search !== this.searchParams) {
          this.pathName = nextProps.pathname;
          this.searchParams = nextProps.search;
          history.push({
            pathname: nextProps.pathname,
            search: nextProps.search
          });
        }
      } else {
        history.go(1);
      }
    });

    this.setState({ 
      isLoading: false,
    })
  }

  setSpinnerTransparencyClass = () => {
    const location = window.location.pathname
    const path = location.replace(/^(.+?)\/*?$/, "$1")
    switch (path) {
      case "/":
      case "/Logoff":
      case (path.match("/*/login") || {}).input:
      case (path.match("/*/sso") || {}).input:
        document.body.classList.add("spinner-white-bg");
        break;
      case (path.match("/*/SSO") || {}).input:
          document.body.classList.add("spinner-white-bg");
          break;
      default: 
        document.body.classList.remove("spinner-white-bg");
        break;
    }
  }

  getValidURL(pathname) {
    console.log("PATH: ", pathname)
    let path = pathname.replace(/^(.+?)\/*?$/, "$1")
    switch (path) {
      case "/Verification":
      case "/Registration":
      case "/changepassword": 
      case "/": 
        //path = this.props.client !== undefined && this.props.client !== null ? `/${this.props.client}/login` : "/ceva/login";
        path = this.props.client !== undefined && this.props.client !== null ? `/${this.props.client}/login` : "no_client_redirect";
        break;
      case path.match("/*/login"):
        break;
      default: path = path + '/login'; break;
    }
   if(path === "no_client_redirect") {
    const no_client_redirect = window.REST_API_ENDPOINTS.no_client_redirect;
    const pattern = /^((http|https):\/\/)/;
    window.location.href = !pattern.test(no_client_redirect) ? "https://" + no_client_redirect : no_client_redirect;
    } else {
    let isValid = matchPath(path, {path: '/:client/login', exact: true, strict: false})
    return isValid ? isValid.url : "/403"
    }
  }

  PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
      this.props.isSignedIn && this.props.client !== undefined && this.props.client !== null ? <Component {...rest} {...props} /> : (
        props.location.pathname !== "/403" ? (
          props.location.pathname === "/outside" ? <Component {...rest} {...props} /> :
          <Redirect to={this.getValidURL(props.location.pathname)}/>) : <Redirect to="/403"/>
      )
    )
    } />)

  render() {
    this.setSpinnerTransparencyClass();
    return (
      <div id="mainpage">
        <Helmet>
          <html lang={this.props.locale.substring(0,2)} />
        </Helmet>
        <div className="overlay"></div>
        <CustomAlert />
        <Route path="/403" component = {() => <div>403: Access Denied. Invalid Client or URL</div>} exact/>
        
        <Switch>
          <Route path="/:client/login" exact component={Login} />
          <Route path="/:client/forgotpassword" exact component={ForgotPassword} />
          <Route path="/logoff" render={() => { this.props.logoff(); return <Redirect to={`/${this.props.client}/login`} /> }} exact />
          <Route path="/Account/RevokeToken" render={() => { this.props.logoff(); return <Redirect to={`/${this.props.client}/login`} /> }} exact />
          <Route path="/outside" render={() => {
          const { redirectURL } = this.props;
          redirectURL && (redirectURL.indexOf('http://') || redirectURL.indexOf('https://')) !== -1 ? (window.location = 'https://' + redirectURL) : (window.location = redirectURL); return null }} exact />

          <Route path="/:client/sso" exact component={Welcome} />
          <Route path="/Content/FooterContent" component={ExternalLinks} />
          <Route path="/coverage" component={Coverage} />
          <Route path="/ReviewCoverage/CurrentCoverage" component={Current} />

          <this.PrivateRoute path="/" exact component={Welcome} />
          <this.PrivateRoute path="/registration" exact component={Registration} />
          <this.PrivateRoute path="/verification" exact component={Verification} />
          <this.PrivateRoute path="/Account/TwoFASettings" component={TwoFASettings} />
          <this.PrivateRoute path="/contact" exact component={Contact} />
          <this.PrivateRoute path="/changepassword" exact component={ChangePassword} />

          {/* <this.PrivateRoute component={BaseContentPageWithMenu} path="/Content/HelpMenu" benefit={Help} hideFlex="true" hidePaging="true"/> */}
          <this.PrivateRoute component={BaseContentPageWithMenu} path="/ReviewCoverage/PreviousCoverageList" exact benefit={ReviewCoverage} hideFlex="true" hidePaging="true"/>
          <this.PrivateRoute component={BaseContentPageWithMenu} path="/plandetails" benefit={ReviewCoverage} />
          
          <this.PrivateRoute component={BaseContentPageWithMenu} path="/reviewpersonalprofile" benefit={ReviewPersonalInfo} hideFlex="true" hidePaging="true" selected="reviewpersonalprofile" />
          <this.PrivateRoute component={BaseContentPageWithMenu} path="/personalinformation" benefit={ReviewPersonalInfo} hideFlex="true" hidePaging="true" selected="reviewpersonalprofile" />
          <this.PrivateRoute component={BaseContentPageWithMenu} path="/profile" benefit={ReviewPersonalInfo} selected="reviewpersonalprofile" />
          <this.PrivateRoute component={BaseContentPageWithMenu} path="/dependent" benefit={Dependents} selected="dependent" />
          <this.PrivateRoute component={BaseContentPageWithMenu} path="/wellness" benefit={BenefitWellness} hidePaging="true" />
          <this.PrivateRoute component={BaseContentPageWithMenu} path="/LifeChangeEvent" benefit={LifeChangeEvent} hideFlex="true" hidePaging="true" menu="main" hideNavigation="true"/>
          <this.PrivateRoute component={BaseContentPageWithMenu} path="/LifeChangeEventClaim" benefit={LifeChangeEventClaim} hideFlex="true" hidePaging="true" menu="main" hideNavigation="true"/>
          <this.PrivateRoute component={BaseContentPageWithMenu} path="/Beneficiaries" benefit={Beneficiaries} hideFlex="true" hidePaging="true" hideNavigation="true"/>
          <this.PrivateRoute component={BaseContentPageWithMenu} path="/Flex" benefit={FlexDollars} />
          <this.PrivateRoute component={BaseContentPageWithMenu} path="/EnrollmentSummary" benefit={EnrollmentSummary} firstPage="true" hideFlex="true" selected="summaryTab"/>

          <this.PrivateRoute component={BaseContentPageWithMenu} path="/Benefits" exact benefit={MultiBenefitShell}/>
          <this.PrivateRoute component={BaseContentPageWithMenu} path="/add/*" benefit={BenefitShell} optionKey='options'/>
          <this.PrivateRoute component={BaseContentPageWithMenu} path="/life/*" benefit={BenefitShell}  optionKey='options'/>
          <this.PrivateRoute component={BaseContentPageWithMenu} path="/addwc/*" benefit={BenefitShell}  optionKey='options'/>
          <this.PrivateRoute component={BaseContentPageWithMenu} path="/health/*" benefit={BenefitShell}  optionKey='options'/>
          <this.PrivateRoute component={BaseContentPageWithMenu} path="/disability/*" benefit={BenefitShell}  optionKey='options'/>
          <this.PrivateRoute component={BaseContentPageWithMenu} path="/vacation/*" benefit={BenefitShell}  optionKey='options'/>

          <this.PrivateRoute component={BaseContentPageWithMenu} path="/Summary" exact benefit={BenefitSummary} hideNavigation="true" />
          <this.PrivateRoute component={BaseContentPageWithMenu} path="/Summary/Beneficiaries" benefit={SummaryBeneficiaries} hideNavigation="true" hidePaging="true" hideFlex="Flex" />
          <this.PrivateRoute component={BaseContentPageWithMenu} path="/Summary/Dependents" benefit={SummaryDependents} hideNavigation="true" hidePaging="true" hideFlex="Flex" />
          <this.PrivateRoute component={BaseContentPageWithMenu} path="/Summary/Personal" benefit={SummaryPersonal} hideNavigation="true" hidePaging="true" hideFlex="Flex" />
          
          {/* E-Signature Route */}
          <this.PrivateRoute component={BaseContentPageWithMenu} path="/Summary/BeneficiariesESignature" benefit={SummaryESignBeneficiaries} hideNavigation="true" hidePaging="true" hideFlex="Flex" />

          <this.PrivateRoute component={BaseContentPageWithMenu} path="/FileUpload" benefit={UploadDocs}  hideFlex="true" hideNavigation="true" hidePaging="true"/>
          <this.PrivateRoute component={BaseContentPageWithMenu} path="/EnrollmentConfirmation" benefit={EnrollmentConfirmation}  hideFlex="true" hideNavigation="true" hidePaging="true"/>
          <this.PrivateRoute component={BaseContentPageWithMenu} path="/FinishedEnrollment/Forms" benefit={EnrollmentConfirmation}  hideFlex="true" hideNavigation="true" hidePaging="true"/>
         
          <this.PrivateRoute component={BaseContentPageWithMenu} path="/Beneficiary/All" exact benefit = {BeneficiariesAll} hideFlex="true" hidePaging="true" />
          <this.PrivateRoute component={BaseContentPageWithMenu} path="/Package/*" benefit={PackageGroup} optionKey='options' />
          <this.PrivateRoute component={BaseContentPageWithMenu} path="/PackageBeneficiaries" exact benefit={PackageBeneficiaries} hideFlex="true" hidePaging="true" hideNavigation="true"/>
          <this.PrivateRoute component={BaseContentPageWithMenu} path="/MultiPackageBeneficiaries" exact benefit={PackageMultiBeneficiaries} hideFlex="true" hidePaging="true" hideNavigation="true"/>
          <this.PrivateRoute component={BaseContentPageWithMenu} path="/BeneficiaryReallocate" exact benefit = {BeneficiariesReallocate} hideFlex="true" hidePaging="true" hideNavigation="true" />
          <this.PrivateRoute component={BaseContentPageWithMenu} path="*" hideFlex="true" hidePaging="true" hideNavigation="true" benefit={() => (<div>404 Not found!</div>)} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.contentReducer.errorMessage,
    successMessage: state.contentReducer.successMessage,
    warningMessage: state.contentReducer.warningMessage,
    isSignedIn: state.userReducer.isSignedIn,
    client: state.contentReducer.client,
    redirectURL: state.userReducer.redirectURL,
    locale: state.contentReducer.locale
  }
};

export default withRouter(connect(
  mapStateToProps,
  { logoff, putWelcome, setClientName }
)(Content));
