import React, { Component, Fragment } from "react";
import ReactDOM from 'react-dom';
import { connect } from "react-redux";
import {  Card } from "react-bootstrap";
import { Modal, ModalBody } from 'reactstrap';

import { withRouter } from "react-router-dom";
import DisplayButton from './button';
import { getDisplayText,setModalAttribute } from "../utils";
import BenefitOptionComponent from "./BenefitOptionComponent";
import ChangeDependedCoverage from "./ChangeDependedCoverage";
import MedicalWaiver from "./PackageMedicalWaiver";
import {
  setMultiBenefitOption,
  removeWaiver,
  getContentHelp,
  setMultiBeneficiariesList,
  setPageSetting,
  setFocusOnBenefitError,
  setBaseBeneficiaryPageTitle
} from "../actions/userActions";

import _ from "underscore";
import { trackPromise } from "react-promise-tracker";
import _deep from "underscore.deepclone";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faQuestionCircle,
  faChevronUp,
  faChevronDown
} from '@fortawesome/free-solid-svg-icons';

_.mixin(_deep);
class PackageGroupMulti extends Component {
  constructor(props) {
    super(props);
    this.state = {
      benefit: {},
      options: [],
      header: {},
      selected: "",
      showHelpContent: false,
      showChangeDependentCoverage: false,
      showMedicalWaiver: false,
      showMedicalWaiverDependent: false,
      showAlternateCoverageDetails: false,
      selectedOption: {},
      isExpanded: false,
      collapsedOptionList: []
    };
  }

  makeCollapsedOptions = (options, selected) => {
    const selectedIndex = _.findIndex(options, opt => opt === selected)
    const eoiOptionIndex = _.findIndex(options, opt => opt.requireEOI === true)
    let collapsedOptionList = []
    if (selectedIndex === 0) {
      collapsedOptionList = options.slice(selectedIndex, selectedIndex+3)
    } else if (selectedIndex === options.length-1) {
      collapsedOptionList = options.slice(selectedIndex-2)
    } else {
      collapsedOptionList = options.slice(selectedIndex-1, selectedIndex+2)
    }

    if ( eoiOptionIndex !== -1) {
        const collapsedEOIIndex = _.findIndex(collapsedOptionList, opt => opt.requireEOI === true)
        if (collapsedEOIIndex === -1)
          collapsedOptionList.push(options[eoiOptionIndex])
    }

    this.setState({collapsedOptionList})
  }
  
  componentDidMount() {
    const {
      state,
      props: { benefit, benefitURL, setMultiBenefitOption }
    } = this;
    if (benefit !== state.benefit) {
      const options = benefit.packageList;
      const selectedOption = _.where(options, { isSelected: true });
      const selected =
        (selectedOption.length && selectedOption[0].optionID) || "";
      const selectedOptionProp =
        (options && options.find(opt => opt.optionID === selected)) || {};
      this.setState({
        options,
        selected,
        selectedOption: selectedOptionProp,
        benefit,
        header: benefit.header
      },
        () => setTimeout(() => this.handleSetFocusOnError(), 500)
      );
      trackPromise(setMultiBenefitOption(benefitURL, selected, false));
      if ( (options || []).length > 10) 
        this.makeCollapsedOptions(
          options, 
          selectedOptionProp
        )
    }
  }

  componentDidUpdate() {
    const { 
        props: { benefit, benefitURL, setMultiBenefitOption, benefitErrorOnNext }
    } = this;
    if (benefit !== this.state.benefit) {
        const options = benefit.packageList;
        const selectedOption = _.where(options, { isSelected: true });
        const selected = selectedOption.length ? selectedOption[0].optionID : "";
        const selectedOptionProp = options
          ? options.find(opt => opt.optionID === selected)
          : {};
        this.setState({
          options,
          selected,
          selectedOption: selectedOptionProp,
          benefit,
          header: benefit.header
        },
          () => setTimeout(() => this.handleSetFocusOnError(), 500)
        );
        trackPromise(setMultiBenefitOption(benefitURL, selected, false));
        if ( (options || []).length > 10) 
          this.makeCollapsedOptions(
            options, 
            selectedOptionProp
          )
    }

    if (benefitErrorOnNext) {
      setTimeout(() => {
        this.handleSetFocusOnError();
        this.props.setFocusOnBenefitError(false);
      }, 200)
    }
  }

//   UNSAFE_componentWillReceiveProps({ benefit }) {
//     if (benefit !== this.state.benefit) {
//       const options = benefit.packageList;
//       const selectedOption = _.where(options, { isSelected: true });
//       const selected = selectedOption.length ? selectedOption[0].optionID : "";
//       const selectedOptionProp = options
//         ? options.find(opt => opt.optionID === selected)
//         : {};
//       this.setState({
//         options,
//         selected,
//         selectedOption: selectedOptionProp,
//         benefit,
//         header: benefit.header
//       });
//       console.log(this.state);
//       trackPromise(this.props.setBenefitOption(selected, false));
//       if ( (options || []).length > 10) 
//         this.makeCollapsedOptions(
//           options, 
//           selectedOptionProp
//         )
//     }
//   }

UNSAFE_shouldComponentUpdate(nextProps, nextState){
    if(nextState.benefit){
      if(nextState.benefit.errorMessage){
        const errorMessageNode = ReactDOM.findDOMNode(this.refs.errorMessageField); 
        if(errorMessageNode){
          errorMessageNode.focus();
        }
      }
    }
}

  setValue = option => {
    let options = { ...this.state.options };
    _.each(options, opt => {
      opt.isSelected = opt.optionID === option.optionID;
    });
    this.setState({ options, selected: option.optionID });
    trackPromise(this.props.setMultiBenefitOption(this.props.benefitURL, option.optionID, true));
    this.setState({
      isExpanded: false
    })
  };

  showModal = modalName => {
    if (modalName === "showChangeDependentCoverage") {
      this.setState({
        prevDependentCoverage: _.deepClone(this.state.benefit)
      });
    }
    this.setState({ [modalName]: true });
  };

  handleClose = () => {
    this.setState(state => ({
      showHelpContent: false,
      showChangeDependentCoverage: false,
      showMedicalWaiver: false,
      showMedicalWaiverDependent: false,
      showAlternateCoverageDetails:false,
      benefit:
        (state.prevDependentCoverage &&
          _.deepClone(this.state.prevDependentCoverage)) ||
        state.benefit
    }));
  };

  getSelectedProps = () => {
    const { options } = this.state;
    return (
      (options && options.find(opt => opt.optionID === this.state.selected)) ||
      {}
    );
  };

  handleRemoveWaiver = () => trackPromise(this.props.removeWaiver());

  handleShowHelp = () => {
    const { helpLink } = this.props.benefit;
    const { getContentHelp } = this.props; 
    helpLink &&
      getContentHelp(helpLink, () => {
        this.showModal("showHelpContent");
      });
  };

  setAreaLabel = (
    currentState,
    lblDependentsCoveredUnderThisPlan,
    lblWaiveOptionDialogTitle
  ) => {
    if (currentState.showChangeDependentCoverage) {
      return lblDependentsCoveredUnderThisPlan.display;
    } else if (currentState.showHelpContent) {
      return "Help";
    } else if (
      currentState.showMedicalWaiver ||
      currentState.showMedicalWaiverDependent
    ) {
      return lblWaiveOptionDialogTitle.display;
    }
  };

  handleSetFocusOnError = () => {
    if(this.props.benefit.errorMessage){
      if (this.props.benefitWithError && this.props.benefitURL === this.props.benefitWithError) {
        const errorMessageNode = ReactDOM.findDOMNode(this.refs.errorMessageField); 
        if(errorMessageNode){
          errorMessageNode.focus();
        }
      }
    }
  }

  toggleExpandCollapse = () => {
    this.setState(
      (state) => (
        {isExpanded: !state.isExpanded}
      )
    )
  }

  handleBeneficiaries = () => {
    this.props.setBaseBeneficiaryPageTitle(this.state.benefit.benefitName)
    this.props.setMultiBeneficiariesList(this.state.benefit, () => {
        this.props.history.push("/MultiPackageBeneficiaries")
    })
  }

  handleAddDependent = () => {
    const menuItem = _.find(this.props.sideBarMenu, item => (item.link || "").toUpperCase() === "DEPENDENT")
    this.props.setPageSetting(menuItem ? menuItem.name : "WELCOME", true, true)
    this.props.history.push(`/${menuItem ? menuItem.link : ""}`);
  }

  render() {
    const contentFooter = this.props.contentFooter;
    const {
      benefit,
      options,
      selected,
      header,
      selectedOption,
      showHelpContent,
      showChangeDependentCoverage,
      showMedicalWaiver,
      showMedicalWaiverDependent,
      showAlternateCoverageDetails,
      isExpanded,
      collapsedOptionList
    } = this.state;
    const contents = ((benefit.contentResource || {}).contents || []);

    const benefitURL = this.props.benefitURL;

    const showWaiverNotes = (selectedOption && selectedOption.showWaiverNotes) || false;
    const lblViewBeneficiaries = getDisplayText(
      contents,
      "lblViewBeneficiaries"
    );
    const showAlternateCoverageFlag=this.state.benefit.showAlternateCoverageFlag ? this.state.benefit.showAlternateCoverageFlag : false;
    const lblChangeDependents = getDisplayText(contents, "lblChangeDependents");
    const lblWaiveCoverage = getDisplayText(contents, "lblWaiveCoverage");
    const lblDependentsCoveredUnderThisPlan = getDisplayText(
      contents,
      "lblDependentsCoveredUnderThisPlan"
    );
    const lblWaiveOptionDialogTitle = getDisplayText(
      contents,
      "lblWaiveCoverageDialogTitle"
    );
    
    const lblWaiverNoteOptOut = getDisplayText(contents, "lblWaiverNoteOptOut");

    const lblDependentCoverageWaiverTitle = getDisplayText(contents, "lblDependentCoverageWaiverTitle");
    const lblAlternateCoverageDetails = getDisplayText(contents, "lblAlternateCoverageDetails");
    const lnkMore = getDisplayText(contentFooter, 'lnkMore')
    const lnkLess = getDisplayText(contentFooter, 'lnkLess')
    const closeButtonText = getDisplayText(contentFooter, 'btnClose')

    const optionList = (options && options.length > 10) 
      ? ( isExpanded 
          ? options 
          : collapsedOptionList 
        ) 
      : options

    return (<Card className={`healthcarecontent clearfix`}>
      <Card.Header>
        <div className="ml-titledescription d-flex justify-content-between">
          <div className="titlehelpicon">
            <h2>
              {benefit.benefitName}
            </h2>
            {benefit.showHelp && (
              <span 
                tid="showHelpBtn"
                className="icon-action" 
                role='button' 
                tabIndex="0" 
                onClick={() => {
                  this.handleShowHelp()
                }}
              >
                <FontAwesomeIcon 
                  icon={faQuestionCircle}
                />
              </span>
            )}
          </div>
          {/* benefit effective date */}
          {benefit.lblBenefitNotes && <span className={`float-right m-0`} role='alert' dangerouslySetInnerHTML={{__html: benefit.lblBenefitNotes}} />}
        </div>
      </Card.Header>
      <Card.Body>
        {/* {benefit.lblBenefitNotes && (
          <Fragment>
            <div className={`clearfix alert alert-info mt-3`}>
              <p
                className="m-0"
                aria-label={benefit.lblBenefitNotes}
                dangerouslySetInnerHTML={{ __html: benefit.lblBenefitNotes }}
              />
            </div>
          </Fragment>
        )} */}
        {benefit.waivedPremuimRequiredNote && <label className={`warning note`} role='alert' dangerouslySetInnerHTML={{ __html: benefit.waivedPremuimRequiredNote }} />}
        {benefit.showLinkedNote && <label className={`warning note`} dangerouslySetInnerHTML={{ __html: benefit.linkedNote }} />}
        <div className="ml-options mt-4 clearfix">
          {((benefit.isWaived || benefit.isTerminated) && (
            <p
              className="m-0"
              tabIndex="0"
            >
              {benefit.terminatedORWaived}
            </p>
          ))
          }
          <BenefitOptionComponent
            benefitURL={benefitURL}
            options={optionList}
            benefit={benefit}
            header={header}
            contents={contents}
            selected={selected}
            setValue={this.setValue}
            footer={this.props.contentFooter}
          />
        </div>

        { options && options.length > 10 && 
          (<div className="ml-showmore d-flex justify-content-end">
          <DisplayButton className="link"
            onClick={this.toggleExpandCollapse}
            displayName={this.state.isExpanded
              ? lnkLess.display
              : lnkMore.display}
            btnIcon={this.state.isExpanded
              ? faChevronUp
              : faChevronDown} />
          </div>
        )}
        
         {benefit.errorMessage && (
          <div className="alert alert-danger" tabIndex="-1" role='alert' ref='errorMessageField'  dangerouslySetInnerHTML={{__html: benefit.errorMessage}} />
        )}
        <div className="clearfix"></div>
         <div className={`ml-popupbuttons mt-md-2 d-flex justify-content-between`}>
           {showWaiverNotes && (
            <DisplayButton
              controlId="showMedicalWaiverDependent"
              className="outline-secondary"
              onClick={() => {
                this.showModal("showMedicalWaiverDependent");
              }}
              displayName= {lblWaiveCoverage.display}
           / >
          )}
          {benefit.showWaiveCoverage && (
            <DisplayButton
              controlId="showWaiveCoverage"
              className="outline-secondary"
              onClick={() => {
                this.showModal("showMedicalWaiver");
              }}
              displayName= {lblWaiveCoverage.display}
           / >
          )}
          {benefit.showDependents && (
            <DisplayButton
              controlId="showDependents"
              className="outline-secondary ml-3"
              onClick={() => {
                this.showModal("showChangeDependentCoverage");
              }}
              displayName={lblChangeDependents.display}
            />  
          )}
          
          {showAlternateCoverageFlag &&  (
            <DisplayButton
            controlId="showAlternateCoverageDetails"
            className="outline-secondary btn-waivecoverage"
              onClick={() => {
                this.showModal("showAlternateCoverageDetails");
              }}
              displayName={lblAlternateCoverageDetails.display}
           / >
          )}

          {benefit.showBeneficiariesAssignment && (
            <DisplayButton 
              className="outline-secondary ml-3"
              onClick={() => {this.handleBeneficiaries()}}
              displayName={lblViewBeneficiaries.display}
            />
          )}
        </div>
       
        <Modal
            onOpened={() => setModalAttribute(this.setAreaLabel(
              this.state,
              lblDependentsCoveredUnderThisPlan,
              lblWaiveOptionDialogTitle
            ))}
          centered
          aria-labelledBy="modalHeading"
          isOpen={
            showHelpContent ||
            showChangeDependentCoverage ||
            showMedicalWaiver ||
            showMedicalWaiverDependent ||
            showAlternateCoverageDetails
          }
          toggle={this.handleClose}
          className={`${(showChangeDependentCoverage ? "" : "ml-addnewdependent")} 
            coveragewaiverpopup`}
        >
          <div className="modal-header">
            <h1 className="modal-title" id="modalHeading">
                {showChangeDependentCoverage &&
                  lblDependentsCoveredUnderThisPlan.display}
                {showHelpContent && benefit.benefitName}
                {(showMedicalWaiver || showMedicalWaiverDependent) &&
                  lblWaiveOptionDialogTitle.display}
                {showAlternateCoverageDetails && lblDependentCoverageWaiverTitle.display }
                
              
            </h1>
            <DisplayButton type="button"  className="close" displayName="×" arialabel={closeButtonText.display} onClick={this.handleClose}/> 
            
          </div>
          <ModalBody className={this.state.showHelpContent ? "scroll-container" : ""}>
            {showHelpContent && (
              <Fragment>
                {" "}
                <div
                  dangerouslySetInnerHTML={{ __html: this.props.helpContent }}
                />{" "}
              </Fragment>
            )}
            {showChangeDependentCoverage && (
              <ChangeDependedCoverage
                benefitURL={benefitURL}
                isMultiSelectDependents={benefit.isMultiSelectDependents}
                dependentsThisPlan={benefit.dependentsThisPlan}
                dependentsOtherPlan={benefit.dependentsOtherPlan}
                contents={benefit.contentResource.contents}
                handleClose={this.handleClose}
                handleAddDependent={this.handleAddDependent}
              />
            )}
            {(showMedicalWaiver || showMedicalWaiverDependent || showAlternateCoverageDetails) && (
              <MedicalWaiver
                benefitURL={benefitURL}
                isOptOut={showMedicalWaiverDependent}
                benefit={benefit}
                selectedOption={selectedOption}
                showAlternateCoverageDetails={showAlternateCoverageDetails?true:false} 
                handleClose={this.handleClose}
              />
            )}
          </ModalBody>
        </Modal>
        </Card.Body>
      </Card>
    );
  }
}

const mapStateToProps = ({
  userReducer: { pageSetting, helpContentResponse, contentFooter, benefitErrorOnNext },
  contentReducer: { screens },
}) => ({
  pageSetting,
  sideBarMenu: screens ? screens : [],
  helpContent: (helpContentResponse && helpContentResponse.result.content) || "",
  contentFooter: ((contentFooter || {}).contentResource || {}).contents || {},
  benefitErrorOnNext: benefitErrorOnNext || false
});

export default connect(mapStateToProps, {
  setMultiBenefitOption,
  removeWaiver,
  getContentHelp,
  setMultiBeneficiariesList,
  setPageSetting,
  setFocusOnBenefitError,
  setBaseBeneficiaryPageTitle
})(withRouter(PackageGroupMulti));
