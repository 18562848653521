import React, { Component } from 'react';
import { getEnrollmentHeader, getShowFlex } from "../actions/userActions";
import { setMiniCartInitialState } from '../actions/contentActions';
import { connect } from "react-redux";
import { trackPromise } from 'react-promise-tracker';

import { Col } from 'react-bootstrap';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import DisplayButton from './button';
import FlexModal from './EnrollmentHeaderShowFlex';
import FocusLock from 'react-focus-lock';
import ReactDOM from 'react-dom';
import { getDisplayText } from '../utils';

class EnrollmentHeader extends Component {
    constructor(props) {
        super(props)
        this.state = { 
            showNewModal : this.props.miniCartInitialState,
            showFlexAwardedModal: false,
            showDeductionModal: false,
            modalType: "deduction"
        };
    }

    componentDidMount() {
      trackPromise(this.props.getEnrollmentHeader());
      trackPromise(this.props.getShowFlex());
    }

    componentWillReceiveProps(nextProps) {
      if(nextProps.miniCartInitialState !== this.state.showNewModal) {
        return this.setState({
          showNewModal: nextProps.miniCartInitialState
        })
      }
    }

    handleModalType = (modalType) => {
      if (modalType && modalType === "awarded") 
        this.setState({showFlexAwardedModal: true, showDeductionModal: false, modalType: "awarded"})
      else 
        this.setState({showFlexAwardedModal: false, showDeductionModal: true, modalType: "deduction"})
    }

    handleClose = () => {
      if(this.state.modalType==="awarded" && this.refs.flexAwarded){
        ReactDOM.findDOMNode(this.refs.flexAwarded).focus()
        }
      this.props.setMiniCartInitialState(false);
      this.setState({showFlexAwardedModal: false, showDeductionModal: false})
    }

    handleShow = (modalType) => {
      this.props.setMiniCartInitialState(true);
      this.handleModalType(modalType)
    }

    handleDownKeyPressed = (event, method) => {
      switch(event.which){
          case 13: {
            method();
            break;
          }
          default:
				    break;
      }
   }

    render() {
        const awardedFlexLabel = this.props.userShowFlex.headerTotalFlexAwarded
        const remainingFlexLabel = this.props.userShowFlex.headerRemainingFlex
        const deductionsLabel = this.props.userShowFlex.headerPerPayDeductions

        const showFlex = this.props.userShowFlex.showFlex
        const awardedFlexVisble = this.props.userShowFlex.awardedFlexVisible
        const remainingFlexVisible = this.props.userShowFlex.remainingFlexVisible
        const deductionsVisible = this.props.userShowFlex.deductionsVisible
        const totalFlexAwarded = this.props.userShowFlex.totalFlexAwarded
        const remainingFlex = this.props.userShowFlex.remainingFlex
        const deductionAmout = this.props.userShowFlex.deductions
        const flexZeroValues = ["$0.00", "$0.0", "$0"]
        const closeButtonText =getDisplayText((this.props.footer.contentResource && this.props.footer.contentResource.contents) || {}, 'btnClose' )
        return (<>
        {showFlex && <div  className="ml-healthheader d-flex justify-content-end enrollment-header mb-md-4">
          {/* <div className="d-flex w-100"> */}
            {awardedFlexVisble && <Col className={`ml-col col-4 col-md-4 ml-colawarded ${this.state.showFlexAwardedModal ? "active bgsecondary" : ''}`}  >
              <span className="ml-enrollmenttitle">{awardedFlexLabel}</span>
              {flexZeroValues.indexOf(totalFlexAwarded) === -1 ? 
               <DisplayButton className="link" 
                displayName={<span className="ml-enrollmentvalue">{totalFlexAwarded}</span>}
                onClick={() => this.handleShow("awarded")}
                btnIcon={!this.state.showFlexAwardedModal?faChevronDown:''}
                ref="flexAwarded"
                arialabel={awardedFlexLabel+" "+totalFlexAwarded}
               /> 
              :<span className="ml-enrollmentvalue"><span className="visuallyhidden">{awardedFlexLabel}</span>{totalFlexAwarded}</span>
              }
            </Col>}
            
            {remainingFlexVisible && <Col className="ml-col col-4 col-md-4"  >
              <span className="ml-enrollmenttitle">{remainingFlexLabel}</span>
              <span className="ml-enrollmentvalue"><span className="visuallyhidden">{remainingFlexLabel}</span>{remainingFlex}</span> 
            </Col>}
            {deductionsVisible && <Col className={`ml-col col-4 col-md-4 ml-coldeduction ${this.state.showDeductionModal ? "active bgprimary" : ''}`}>
              <span className="ml-enrollmenttitle">{deductionsLabel}</span>
              {flexZeroValues.indexOf(deductionAmout) === -1 ?
                <DisplayButton className="link" arialabel={deductionsLabel+" "+deductionAmout} displayName={<span className="ml-enrollmentvalue">{deductionAmout}</span>}  onClick={() => this.handleShow("deduction")}
                btnIcon={!this.state.showDeductionModal?faChevronDown:''}
                /> 
              :<span className="ml-enrollmentvalue"><span className="visuallyhidden">{deductionsLabel}</span>{deductionAmout}</span>}
            </Col>}
          {/* </div> */}

          {this.state.showNewModal && 
            <FocusLock autoFocus={true} noFocusGuards={true} >
              <div 
                className={`ml-dropdowncontent ${this.state.showFlexAwardedModal ? "alignleft bgsecondary open": ''} ${this.state.showDeductionModal ? "alignright bgprimary open" : ''}`}
              >
                <div className="scroll-container">
                  <FlexModal modalType={this.state.modalType} userShowFlex={this.props.userShowFlex} onHide={this.handleClose} footer={this.props.footer} arialabel={closeButtonText.textToRead} />
                </div>
              </div>
            </FocusLock>}
        </div>}
      </>);
    }
}
const mapStateToProps = (state) => {
    return {
        headerImages: state.contentReducer.images,
        locale: state.contentReducer.locale,
        isSignedIn: state.userReducer.isSignedIn,
        userShowFlex: state.userReducer.showFlex ? state.userReducer.showFlex : {},
        content: state.userReducer.enrolmentHeader.contentResource ? state.userReducer.enrolmentHeader.contentResource.contents : [],
        enrolmentHeader: state.userReducer.enrolmentHeader ? state.userReducer.enrolmentHeader : [],
        miniCartInitialState: state.contentReducer.miniCartInitialState,
        footer: state.userReducer.contentFooter
    }
};

export default connect(
mapStateToProps,
{ getEnrollmentHeader, getShowFlex, setMiniCartInitialState }
)(EnrollmentHeader);
