import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import "./Spinner.css";

export const Spinner = (props) => {
  const { promiseInProgress } = usePromiseTracker({delay: 250})

  return (
    promiseInProgress && (
      <div className="loaderwrapper">
        <div className="loader">
          <div className='dot primarycolor'></div>
          <div className='dot primarycolor'></div>
          <div className='dot primarycolor'></div>
          <div className='dot primarycolor'></div>
          <div className='dot primarycolor'></div>
          <div className='dot primarycolor'></div>
          <div className='dot primarycolor'></div>
          <div className='dot secondarycolor'></div>
          <div className='dot secondarycolor'></div>
          <div className='dot secondarycolor'></div>
          <div className='dot secondarycolor'></div>
          <div className='dot secondarycolor'></div>
          <div className='dot secondarycolor'></div>
          <div className='dot secondarycolor'></div>
        </div>
      </div>
    )
  );
};