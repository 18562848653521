import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons'

const UploadedFileItem = props => {
  return (
    <tr>
      <td  aria-label={props.fileNameHeadingValue.display+" "+props.title} data-title={props.fileNameHeadingValue.display}>{props.title}</td>
      <td aria-label={props.categoryNameHeadingValue.display+" "+  props.categoryName} data-title={props.categoryNameHeadingValue.display}>{props.categoryName}</td>
      <td aria-label={props.dateHeadingValue.display+" "+props.uploadedDate} data-title={props.dateHeadingValue.display}>{props.uploadedDate}</td>
      <td aria-label={props.statusHeadingValue.display+" "+props.status} data-title={props.statusHeadingValue.display}>{props.status}</td>
      <td data-title={props.downloadFileHeadingValue.display}>
        <span tabIndex='0' role='button' aria-label={props.downloadFileHeadingValue.display}  className="btn btn-secondary btn-uploadfile" onClick={props.downloadFile}>
          <FontAwesomeIcon icon={faCloudDownloadAlt}/>
        </span>
      </td>
    </tr>
  );
};

export default UploadedFileItem;
