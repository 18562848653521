import {
  FAILURE,
  SUCCESS,
  CHANGE_LOCALE, 
  GET_SECOND_MENU,
  DISSMISS_MESSAGE,
  GET_LOGIN_CONTENT,
  GET_HEADER_IMAGES,
  GET_WELCOME_CONTENT,
  CHANGE_PASSWORD_CONTENT,
  TEMPORARY_PASSWORD_CONTENT,
  GET_FORGOT_PASSWORD_CONTENT,
  GET_LIFE_EVENT_CHANGE,
  SET_CLIENT_NAME,
  SET_CLIENT_TYPE,
  SET_HEADER_INFO,
  SET_MINI_CART_INITIAL_STATE,
  FOCUS_INITIALIZING,
  GET_TWOFA_REG_CONTENT,
  GET_REGISTRATION_FORM,
  SEND_VERIFICATION_CODE,
  GET_VERIFICATION_CONTENT,
  GET_TFA_SETTINGS,
  TFA_DISABLE,
  FOCUS_IS_WELCOME,
  BENEFIT_ERROR_ID,
  NEXT_ENROLLMENT_STEP,
  SHOW_WARNING_MESSAGE
  } from "./types.js";

import { USER_TOKEN } from '../helpers/storage';
import flexit from '../apis/flexit'
import {setBasePageTitle} from './userActions';

let intervalID = 0;

export const getHeaderImages = () => async (dispatch, getState) => {
  
  try {
    const locale = getState().contentReducer.locale
    const headers = { "Lang": `${locale}-CA` }
    const response = await flexit.get('/Content/headerImages', { headers });
    dispatch({
      type: GET_HEADER_IMAGES,
      payload: response.data
    }
  )} catch (e) {
    const message = e.message
    dispatch({
      type: FAILURE, 
      payload: { message }
    })
  }
}

export const getLoginContent = () => async (dispatch, getState) => {
  try {
    if (!USER_TOKEN.get().isSignedIn) {
      const locale = getState().contentReducer.locale
      const headers = { "Lang": `${locale}-CA` }
      const response = await flexit.get('/Content/login', { headers });
      dispatch({
        type: GET_LOGIN_CONTENT,
        payload: response.data
      })
    }
  } catch (e) {
    const message = e.message
    dispatch({
      type: FAILURE, 
      payload: { message }
    })
  }
}

/* Client Dynamic Stylesheet */
export const getClientStylesContent = (flag) => async (dispatch, getState) => {
  try {
    const locale = getState().contentReducer.locale
    const headers = { "Lang": `${locale}-CA` }
    
    /* dispatch({
      type: GET_CLIENT_STYLES_CONTENT,
      payload: response.data
    }) */

    let clientStyles = JSON.parse(localStorage.getItem('clientStyles'));

    if(flag || !clientStyles){
      const response = await flexit.get('/Content/ClientStyles', { headers });
      if (response && response.data && response.status === 200) {
        clientStyles = response.data.result;
        if (clientStyles) localStorage.setItem('clientStyles', JSON.stringify(clientStyles));
      }
    }
    modifyJsonObject(clientStyles);
  } catch (e) {
    const message = e.message
    dispatch({
      type: FAILURE, 
      payload: { message }
    })
  }
}

export const modifyJsonObject = (clientStyles) => {
  const keys = Object.keys(clientStyles);
  keys.forEach(key => {
    document.body.style.setProperty(key, clientStyles[key])
  });
}

export const getForgotPasswordContent = () => async (dispatch, getState) => {
  try {
    const locale = getState().contentReducer.locale
    const headers = { "Lang": `${locale}-CA` }
    const response = await flexit.get('/Account/ForgotPassword', { headers });
    dispatch({
      type: GET_FORGOT_PASSWORD_CONTENT,
      payload: response.data
    }
  )} catch (e) {
    const message = e.message
    dispatch({
      type: FAILURE, 
      payload: { message }
    })
  }
}

export const getChangePasswordContent = () => async (dispatch, getState) => {
  try {
    const locale = getState().contentReducer.locale
    const headers = { "Lang": `${locale}-CA` }
    const response = await flexit.get('/Account/TemporaryPassword', { headers });
    dispatch({
      type: CHANGE_PASSWORD_CONTENT,
      payload: response.data
    }
  )} catch (e) {
    const message = e.message
    dispatch({
      type: FAILURE, 
      payload: { message }
    })
  }
}

export const getTemporaryPasswordContent = () => async (dispatch, getState) => {
  try {
    const locale = getState().contentReducer.locale
    const headers = { "Lang": `${locale}-CA` }
    const response = await flexit.get('/Account/TemporaryPassword', { headers });
    dispatch({
      type: TEMPORARY_PASSWORD_CONTENT,
      payload: response.data
    }
  )} catch (e) {
    const message = e.message
    dispatch({
      type: FAILURE, 
      payload: { message }
    })
  }
}

export const getWelcomeContent = () => async (dispatch, getState) => {
  try {
    const locale = getState().contentReducer.locale
    const headers = { "Lang": `${locale}-CA` }
    const response = await flexit.get('/Home/Welcome', { headers });
    if(response && response.data && response.data.statusCode === 200 ){
      dispatch({
        type: GET_WELCOME_CONTENT,
        payload: response.data
      })
      if (response.data.result) {
        const headerInfo = {
          firsName: response.data.result.firsName,
          lastName: response.data.result.lastName,
          divisionName: response.data.result.divisionName
        }

        dispatch({
          type: SET_HEADER_INFO,
          payload: headerInfo
        })
        dispatch({
          type: NEXT_ENROLLMENT_STEP,
          payload: {}
        }) 
      }
    }
  } catch (e) {
    const message = e.message
    dispatch({
      type: FAILURE, 
      payload: { message }
    })
  } finally{
    if(localStorage.getItem('isReview') === "false"){
      localStorage.removeItem('isReview')
    }
    if(localStorage.getItem('hideButtons') === "true"){
      localStorage.removeItem('hideButtons')
    }
    if(localStorage.getItem('lifeEvent') === "true"){
      localStorage.removeItem('lifeEvent')
    }
  }
}


export const getSmokerContent = () => async (dispatch, getState) => {
  try {
    const locale = getState().contentReducer.locale
    const headers = { "Lang": `${locale}-CA` }
        const resp = await flexit.get('/Content/GetSmokerContent', { headers });
        if (resp && resp.data && resp.status === 200) {
          const {employeeSmoker, dependentSmoker} = resp.data;
          if (employeeSmoker) sessionStorage.setItem('employeeSmoker', JSON.stringify(employeeSmoker));
          if (dependentSmoker) sessionStorage.setItem('dependentSmoker', JSON.stringify(dependentSmoker));
      }
    
  } catch (e) {
    const message = e.message
    dispatch({
      type: FAILURE, 
      payload: { message }
    })
  } 
}

export const getMenu = () => async (dispatch, getState) => {
  try {
    const locale = getState().contentReducer.locale
    const headers = { "Lang": `${locale}-CA` }
    const response = await flexit.get('/Menu/main', { headers });
    if(response && response.data && response.data.statusCode === 200 ){
    dispatch({
      type: GET_SECOND_MENU,
      payload: response.data
    })
    dispatch(setBasePageTitle(getState().userReducer.basePageTitleItem ? getState().userReducer.basePageTitleItem : (getState().userReducer.pageSetting ? getState().userReducer.pageSetting : {})))
  }
  } catch (e) {
    const message = e.message
    dispatch({
      type: FAILURE, 
      payload: { message }
    })
  }
}

export const getMenu2 = () => async (dispatch, getState) => {
  try {
    const locale = getState().contentReducer.locale
    const headers = { "Lang": `${locale}-CA` }
    const response = await flexit.get('/Menu/mainstep2', { headers });
    if(response && response.data && response.data.statusCode === 200 ){
    dispatch({
      type: GET_SECOND_MENU,
      payload: response.data
    })
    dispatch(setBasePageTitle(getState().userReducer.basePageTitleItem ? getState().userReducer.basePageTitleItem : (getState().userReducer.pageSetting ? getState().userReducer.pageSetting : {})))
    }
} catch (e) {
    const message = e.message
    dispatch({
      type: FAILURE, 
      payload: { message }
    })
  }
}

export const getLifeChangeEvent = () => async (dispatch, getState) => {
  try {
    const locale = getState().contentReducer.locale
    const headers = { "Lang": `${locale}-CA` }
    const response = await flexit.get('/Home/LifeChangeEvent', { headers });
    dispatch({
      type: GET_LIFE_EVENT_CHANGE,
      payload: response.data
    })

  } catch (e) {
    const message = e.message
    dispatch({
      type: FAILURE, 
      payload: { message }
    })
  }
}

export const showError = (message, header) => dispatch => {
  dispatch({
    type: FAILURE, 
    payload: {message, header}
  })
}

export const showSuccess = (message, header) => dispatch => {
  dispatch({
    type: SUCCESS, 
    payload: {message, header}
  })
}

export const dissmiss = () => dispatch => {
  dispatch({
    type: DISSMISS_MESSAGE,
    payload: ''
  })
}

export const changeLocale = (locale, success) => dispatch => {
  dispatch({
    type: CHANGE_LOCALE,
    payload: locale
  })
}

export const setMiniCartInitialState = (value) => async (dispatch) => {
  try{
    dispatch({ 
      type: SET_MINI_CART_INITIAL_STATE, 
      payload: value })
  }catch(exception){
    console.log("exception found",exception);
  } 
}

export const focusInitializing = () => async(dispatch, getState) => {
  try{
    dispatch({ 
      type: FOCUS_INITIALIZING, 
      payload: !getState().contentReducer.focusInitializing })
  }catch(exception){
    console.log("exception found",exception);
  } 
}

export const setClientName = (clientName, success, failure) => async dispatch => {
  try {
    USER_TOKEN.setClientName(clientName);
    const response = await flexit.get('/Account/ClientID');
    console.log(`Trying to verify client name: ${clientName}`);
    // console.log(response)
    if (response.data.statusCode !== 204) {
      dispatch({
        type: SET_CLIENT_NAME,
        payload: clientName
      })
      dispatch({
        type: SET_CLIENT_TYPE,
        payload: response.data.result
      })
      dispatch({
        type: SHOW_WARNING_MESSAGE,
        payload:response.data.result.ShowWarningMessage
      })
      if (success)
        success(response.data)
    } else {
      if (failure) 
        failure(response.data)

      dispatch({
        type: FAILURE,
        payload: response.data.errorMessage
      })
      USER_TOKEN.removeClientName()
    }
  } catch (e) {
    const message = e.message
    dispatch({
      type: FAILURE, 
      payload: { message }
    })
  }
}

export const getTFARegContent = (history) => async (dispatch, getState) => {
  const locale = getState().contentReducer.locale
  const headers = { "Lang": `${locale}-CA` }
  try {
    const response = await flexit.get('/Account/TwoFARegistration', { headers });
    if (response.data.statusCode < 204) {
      dispatch({
        type: GET_TWOFA_REG_CONTENT,
        payload: response.data
      });
    } else {
      dispatch({
        type: FAILURE,
        payload: response.data.errorMessage
      })
      clearInterval(intervalID)
      history.push("/logoff")
    }
  } catch (e) {
    dispatch({ type: FAILURE, payload: e.message });
  }
}

export const getTFARegSelection = (option, isRegistration, history) => async (dispatch, getState) => {
  const locale = getState().contentReducer.locale
  const headers = { "Lang": `${locale}-CA` }
  try {
    const response = await flexit.get('/Account/TwoFARegistrationSelection/?isRegistration=' + isRegistration + '&selected=' + option, { headers });
    if (response.data.statusCode < 204) {
      dispatch({
        type: GET_REGISTRATION_FORM,
        payload: response.data
      });
    } else {
      dispatch({
        type: FAILURE,
        payload: response.data.errorMessage
      })
      clearInterval(intervalID)
      history.push("/logoff")
    }
  } catch (e) {
    dispatch({ type: FAILURE, payload: e.message });
  }
}

export const sendVerificationCode = (options, failure) => async (dispatch, getState) => {
  const locale = getState().contentReducer.locale
  const headers = { "Lang": `${locale}-CA` }
  try {
    const response = await flexit.post('/Account/TwoFASendCode', options, { headers });
    if (response.data.statusCode !== 200) {
      dispatch({ type: FAILURE, payload: response.data.errorMessage })
      if (failure)
        failure(response.data.errorMessage)
    } else {
      dispatch({
        type: SEND_VERIFICATION_CODE,
        payload: response.data
      })
      return response.data;
    }
  } catch (e) {
    dispatch({ type: FAILURE, payload: e.message })
  }
};

export const verificationContent = (params, history) => async (dispatch, getState) => {
  const locale = getState().contentReducer.locale
  const headers = { "Lang": `${locale}-CA` }
  try {
    // isRegistration=true&selectedTwoFAType=' + option
    const response = await flexit.get('/Account/GetVerificationCode/?' + params, { headers });
    if (response.data.statusCode < 204) {
      dispatch({
        type: GET_VERIFICATION_CONTENT,
        payload: response.data
      });
    } else {
      dispatch({
        type: FAILURE,
        payload: response.data.errorMessage
      })
      clearInterval(intervalID)
      history.push("/logoff")
    }
  } catch (e) {
    dispatch({ type: FAILURE, payload: e.message });
  }
}

export const getTwoFASettings = () => async (dispatch, getState) => {
  const locale = getState().contentReducer.locale
  const headers = { "Lang": `${locale}-CA` }
  try {
    const response = await flexit.get('Account/TwoFASettings', { headers });
    dispatch({
      type: GET_TFA_SETTINGS,
      payload: response.data
    })
  } catch (e) {
    dispatch({ type: FAILURE, payload: e.message })
  }
}

export const disableTFA = (history) => async (dispatch, getState) => {
  const locale = getState().contentReducer.locale
  const headers = { "Lang": `${locale}-CA` }
  try {
    const response = await flexit.put('/Account/DisableTwoFA', { headers });
    dispatch({
      type: TFA_DISABLE,
      payload: response.data
    })
    history.push("/logoff")
  } catch (e) {
    dispatch({ type: FAILURE, payload: e.message })
  }
}

export const setFocusWelcomeFlag = (payloaddata) => async(dispatch, getState) => {
  try{
    dispatch({ 
      type: FOCUS_IS_WELCOME, 
      payload: payloaddata })
  }catch(exception){
    console.log("exception found",exception);
  } 
}

export const setBenefitErrorID = (payloaddata) => async(dispatch, getState) => {
  try{
    console.log('setBenefitErrorID',payloaddata)
    dispatch({ 
      type: BENEFIT_ERROR_ID, 
      payload: payloaddata })
  }catch(exception){
    console.log("exception found",exception);
  } 
}