import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { getContentFooter, getContentFooterPage, setPageSetting } from '../actions/userActions';
import { isMobile } from "react-device-detect";
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { trackPromise } from 'react-promise-tracker';
import { getDisplayText } from '../utils';
import DisplayButton from './button';
import _ from 'underscore';

class ExternalLinks extends Component{
    state = {
        content: ''
    }

    static getDerivedStateFromProps(props, state) {
      if (props.contentFooterPage.result && props.contentFooterPage.result.content !== state.content) {
        return {
          content: props.contentFooterPage.result.content,
        };
      }

      return null;
    }

    componentDidMount(){
        if(localStorage.getItem('section')){
          trackPromise(this.props.getContentFooterPage(localStorage.getItem('section'), () => {
            this.setState({
                content: this.props.contentFooterPage.result.content
            })
        })
        )
        ;}
        if(localStorage.getItem('menuName')){
          this.props.setPageSetting(localStorage.getItem('menuName'))
          localStorage.removeItem('menuName')
        }
    }

    handleBack = () => {
      if(localStorage.getItem('policyName')){
        const menuItem = _.find(this.props.screens, item => item.name === localStorage.getItem('policyName'))
        trackPromise(this.props.setPageSetting(localStorage.getItem('policyName')))
        localStorage.removeItem('policyName')
        this.props.history.push(`/${menuItem ? menuItem.link : ""}`)
      } else
        this.props.history.push(`/${this.props.pageSetting.link ? this.props.pageSetting.link : ''}`)
    }

    render(){
      const backButtonText = getDisplayText((this.props.contentFooter.contentResource && this.props.contentFooter.contentResource.contents) || {}, 'btnBack')
      return ( 
        <div className="clearfix position-relative bg-white">
            {isMobile ? 
              <div className={`backicon titlewithicon`}>
                <DisplayButton className="icon-action" displayName={backButtonText.display} icon={faChevronLeft} onClick={() => {this.handleBack()}} /> 
              </div>
              :
              ''
            }
            <h1 className="text-center" tabIndex='0' >{localStorage.getItem('display') || ''}</h1>
            <Fragment><div className="clearfix" dangerouslySetInnerHTML={{__html: this.state.content}}></div></Fragment>
        </div>
    )
}
  
  }

  const mapStateToProps = (state) => {
    return {
      locale: state.contentReducer.locale,
      isSignedIn: state.userReducer.isSignedIn,
      contentFooter: state.userReducer.contentFooter,
      contentFooterPage: state.userReducer.contentFooterPage,
      screens: state.contentReducer.screens,
      pageSetting: state.userReducer.pageSetting,
    }
  };

  export default connect(
    mapStateToProps,
    {getContentFooter, getContentFooterPage, setPageSetting}
  )(ExternalLinks);