import {
  LOGOFF,
  SIGN_IN,
  FAILURE,
  GET_ADD,
  GET_ADDWC,
  GET_HEALTH,
  GET_PROFILE,
  CHANGE_EVENT,
  GET_WELLNESS,
  UPDATE_WELLNESS,
  CHANGE_OPTION,
  GET_SHOW_FLEX,
  GET_DISABILITY,
  RESET_PASSWORD,
  TOGGLE_SIDEBAR,
  GET_DEPENDENTS,
  GET_BASIC_LIFE,
  CHANGE_PASSWORD,
  UPDATE_DEPENDENT,
  CREATE_DEPENDENT,
  DELETE_DEPENDENT,
  SET_PAGE_SETTING,
  GET_CHANGE_EVENT,
  SET_BENEFIT_OPTION,
  GET_ENROLMENT_HEADER,
  // GET_PENSION_CONTRIBUTION,
  GET_BENEFICIARIES,
  UPDATE_BENEFICIARY,
  CREATE_BENEFICIARY,
  DELETE_BENEFICIARY,
  GET_ENROLMENT_FOOTER,
  UPDATE_PERSONAL_INFO,
  PUT_WELCOME,
  PUT_WELCOME_MESSAGES,
  PUT_WELCOME_REQUESTED_OBJECT,
  PASSWORD_CHANGED,
  REMOVE_EOI,
  SAVE_WAIVER_FORM,
  PUT_DEPENDED_COVERAGE,
  SAVE_DEPENDENT_WAIVER_FORM,
  DISABLE_NEXT,
  GET_CONTENT_FOOTER,
  GET_CONTENT_FOOTER_PAGE,
  UPDATE_INDIVIDUAL_BENEFICIARY,
  GET_PROGRESS_INDICATOR,
  FILE_UPLOAD_INFO,
  UPLOADED_FILES_INFO,
  UPLOAD_FILE,
  PROGRESS_PERCENTAGE,
  REMOVE_WAIVER,
  GET_CONTENT_HELP,
  GET_FLEX,
  UPDATE_FLEX,
  FINISH_ENROLLMENT,
  REVIEW_CURRENT_COVERAGE,
  REVIEW_PAST_COVERAGE,
  REVIEW_PAST_COVERAGE_LIST,
  SAVE_FLEX,
  GET_SUMMARY_BENEFITS,
  GET_SUMMARY_FLEX,
  IS_DISABLED,
  GET_BENEFIT_SUMMARY,
  GET_SUMMARY_BENEFICIARIES,
  GET_SUMARY_DEPENDENTS,
  GET_SUMMARY_PERSONAL,
  CONFIRM_ENROLLMENT,
  GET_BENEFICIARIES_ALL,
  SET_BASE_PAGE_TITLE,
  GET_CONTENT_HELP_MENU,
  NEXT_ENROLLMENT_STEP,
  SET_NAV_URL,
  SET_MENU2_FLAG,
  SET_BASE_PAGE_ITEM,
  SET_NAV_ERROR,
  SET_HEADER_INFO,
  FINISH_ENROLLMENT_FORM,
  BENEFIT_URL_LIST,
  GET_MULTI_BENEFIT_DATA,
  SET_STEP_DESCRIPTION,
  ENROLLMENT_DEVICE_TYPE,
  SET_FLEX_BENEFICIARY_ID,
  SET_MULTI_BENEFICIARIES_LIST,
  SET_FOCUS_ON_BENEFIT_ERROR,
  SAVE_ESIGNATURE,
  REMOVE_BENEFICIARY,
  REALLOCATE_MULTIBENEFIT,
  PROCEED_TO_NEXT,
  RELOCATE_FLEX
} from "../actions/types.js";

import {
  getLifeChangeEvent,
  getLoginContent,
  getForgotPasswordContent,
  getTemporaryPasswordContent,
  changeLocale,
  getWelcomeContent,
  getMenu,
  getMenu2,
  getHeaderImages,
  getTFARegContent,
  getTFARegSelection,
  verificationContent,
  getSmokerContent
} from "./contentActions";
import { trackPromise } from "react-promise-tracker";

import _ from "underscore";
import flexit from "../apis/flexit";
import { USER_TOKEN } from "../helpers/storage.js";
import { checkBrowser } from "../utils";
import { isMobileOnly } from "react-device-detect";

let intervalID = 0;
let timer = 0;

const NEED_TFA_REGISTRATION = "needTwoFARegistration";
const NEED_TFA_VALIDATION = "needTwoFAValidation";

export const authenticate =
  (username, password, tfaDate, failure, history) =>
  async (dispatch, getState) => {
    try {
      const response = await flexit.post("/Account/token", {
        Username: username,
        Password: password,
        TwoFAValidatedDate: tfaDate
      });
      if (response.data.statusCode !== 201) {
        dispatch({ type: FAILURE, payload: response.data.errorMessage });
        if (failure) failure(response.data.errorMessage);
      } else {
        USER_TOKEN.set(response.data);

        dispatch({
          type: SIGN_IN,
          payload: response.data
        });
        dispatch({
          type: SET_PAGE_SETTING,
          payload: welcome
        });
        localStorage.setItem("logOffCheck", true);
        dispatch(setHeaderNavURL(true));
        const timeout = response.data.lifeSpan * 1000 - 60 * 1000; // you can configure as you want but here it is 1 min before token will get expired
        sessionStorage.setItem("timeout", timeout);
        localStorage.removeItem("sessionTimeOut");
        intervalID = setInterval(
          // this would reset localStorage before token expiry timr
          refreshToken(history),
          timeout
        );
      }
    } catch (e) {
      console.log("exception occured ", e);
      dispatch({ type: FAILURE, payload: "Error occured" });
      failure();
    }
  };

export const loginSSO = (payload, history) => async (dispatch) => {
  //Test implementation for SSO validation. TODO
  try {
    if (payload.token) {
      USER_TOKEN.set(payload);
      await dispatch({
        type: SIGN_IN,
        payload: payload
      });
      dispatch({
        type: SET_PAGE_SETTING,
        payload: welcome
      });
      localStorage.setItem("logOffCheck", true);
      dispatch(setHeaderNavURL(true));
      const { client, languageSwitchRequired } = USER_TOKEN.get();
      const { search, pathname } = window.location;
      const pathName = search ? pathname.toLowerCase() + window.location.search : pathname.toLowerCase();

      if(languageSwitchRequired) {
        trackPromise(dispatch(languageChange('fr', client.toLowerCase(), pathName, false, true)));
      }
      // } else {
        // await dispatch(changeLocale(payload.lang.split("-")[0]));
      const timeout = payload.lifeSpan * 1000 - 60 * 1000;
      sessionStorage.setItem("timeout", timeout);
      localStorage.removeItem("sessionTimeOut");
      intervalID = setInterval(refreshToken(history), timeout);
      // await dispatch(setPageSetting("Welcome"));
      trackPromise(dispatch(getSmokerContent()));
      if(!languageSwitchRequired) {
        trackPromise(dispatch(getWelcomeContent()));
        trackPromise(dispatch(getMenu()));
      }
    }
  } catch (e) {
    console.log("Error during SSO ", e);
    dispatch({
      type: FAILURE,
      payload: "SSO Failure"
    });
  }
};

export const getSSOtoken = (req, history) => async (dispatch) => {
  const locale = req.lang.split("-")[0];
  dispatch(changeLocale(locale));
  const headers = { Lang: `${locale}-CA` };

  try {
    const response = await flexit.post("/account/getssotoken", {uid: req.u}, { headers });
    if (response && response.data && response.data.statusCode < 204) {
      response.data.lang = req.lang;
      delete response.data.version;
      delete response.data.statusCode;
      await dispatch(loginSSO(response.data, history));
    } else {
      dispatch({
        type: FAILURE,
        payload: response.data.errorMessage
      });
      clearInterval(intervalID);
      history.push("/logoff");
    }
  } catch (e) {
    console.log("Error during SSO ", e);
    dispatch({
      type: FAILURE,
      payload: "SSO Failure"
    });
  }
};

const refreshToken = (history) => async (dispatch) => {
  const { token, refreshToken } = USER_TOKEN.get();
  try {
    const response = await flexit.post("/Account/refreshtoken", {
      token,
      refreshToken
    });
    if (response && response.data && response.data.statusCode < 204) {
      USER_TOKEN.set(response.data);
    } else {
      dispatch({
        type: FAILURE,
        payload: response.data.errorMessage
      });
      clearInterval(intervalID);
      history.push("/logoff");
    }
  } catch (e) {
    clearInterval(intervalID);
    history.push("/logoff");
    console.log(e);
  }
};

export const resetPassword =
  (username, success, failure) => async (dispatch) => {
    try {
      const response = await flexit.put("/Account/ResetPassword", {
        LoginID: username
      });
      dispatch({
        type: RESET_PASSWORD,
        payload: response.data
      });
      success();
    } catch (e) {
      const message = e.message;
      dispatch({ type: FAILURE, payload: { message } });
      failure();
    }
  };

export const changePassword =
  (password, tfaDate, success, failure) => async (dispatch) => {
    try {
      const response = await flexit.put("/Account/ChangePassword", {
        newPassword: password,
        TwoFAValidatedDate: tfaDate
      });
      dispatch({
        type: CHANGE_PASSWORD,
        payload: response.data
      });
      success();
    } catch (e) {
      const message = e.message;
      dispatch({ type: FAILURE, payload: { message } });
      failure();
    }
  };

export const onChangePassword = (result, twoFACheck) => (dispatch) => {
  if (!result) {
    dispatch({
      type: PASSWORD_CHANGED,
      payload: result
    });
    if (!twoFACheck) {
      dispatch({
        type: SET_PAGE_SETTING,
        payload: { screen: welcome }
      });
    }
  }
};

export const logoff = () => async (dispatch, getState) => {
  console.log("logoff and clear the Cache/Session"); //TODO clear local storage too
  const locale = getState().contentReducer.locale;
  const headers = { Lang: `${locale}-CA` };
  try {
    const response = await flexit.delete("/Account/token", { headers });
    if (response && response.data && response.data.statusCode === 200) {
      USER_TOKEN.remove();
      //window.localStorage.clear()
      dispatch({
        type: LOGOFF,
        payload: response.data.result.redirectUrl
      });
      clearInterval(intervalID);
      sessionStorage.removeItem("employeeSmoker");
      sessionStorage.removeItem("dependentSmoker");
      sessionStorage.removeItem("timeout");
      localStorage.removeItem("clientStyles");
      localStorage.setItem("logOffCheck", false);
    } else if(response && response.data && response.data.statusCode >= 200) {
      dispatch({ type: FAILURE, payload: response.data.errorMessage });
    }
  } catch (e) {
    dispatch({
      type: LOGOFF,
      payload: null
    });
    USER_TOKEN.remove();
    clearInterval(intervalID);
  }
};

export const getProfile =
  (isReview = true) =>
  async (dispatch, getState) => {
    const locale = getState().contentReducer.locale;
    const headers = { Lang: `${locale}-CA` };
    try {
      const response = await flexit.get("/Profile?isReview=" + isReview, {
        headers
      });
      dispatch({
        type: GET_PROFILE,
        payload: response.data
      });

      if (localStorage.getItem("isReview") || localStorage.getItem("lifeEvent"))
        trackPromise(dispatch(progressBarIndicator()));

      const menu2Flag = getState().userReducer.menu2Flag;
      if (
        menu2Flag &&
        (localStorage.getItem("isReview") || localStorage.getItem("lifeEvent"))
      ) {
        trackPromise(dispatch(getMenu2()));
        trackPromise(dispatch(getShowFlex()));
        dispatch(setMenu2Flag(false));
      }
    } catch (e) {
      const message = e.message;
      dispatch({ type: FAILURE, payload: { message } });
    }
  };

export const updatePersonalInfo =
  (user, failure, success) => async (dispatch, getState) => {
    const locale = getState().contentReducer.locale;
    const headers = { Lang: `${locale}-CA` };

    try {
      const response = await flexit.put("/Profile", user, { headers });
      if (response && response.data && response.data.statusCode > 400) {
        if (failure) failure(response.data.errorMessage);
        dispatch({ type: FAILURE, payload: response.data.errorMessage });
      } else {
        dispatch({
          type: UPDATE_PERSONAL_INFO,
          payload: response.data
        });
        if (success) success();
      }
    } catch (e) {
      dispatch({
        type: FAILURE,
        payload:
          e.response.data.validationErrors.length > 0
            ? e.response.data.validationErrors
            : e.message
      });
      if (failure)
        failure(
          e.response.data.validationErrors.length > 0
            ? e.response.data.validationErrors
            : e.message
        );
    }
  };

export const updateDependent =
  (user, callback, success) => async (dispatch, getState) => {
    const locale = getState().contentReducer.locale;
    const headers = { Lang: `${locale}-CA` };
    try {
      const response = await flexit.put("/Dependent", user, { headers });
      dispatch({
        type: UPDATE_DEPENDENT,
        payload: response.data
      });
      if (response.data.errorMessage) {
        if (callback) {
          callback(
            response.data.validationErrors
              ? response.data.validationErrors
              : response.data.errorMessage
          );
        }
      } else {
        if (success) success();
      }
    } catch (e) {
      const message = e.message;
      dispatch({ type: FAILURE, payload: { message } });
    }
  };

export const updateStudentStatus =
  (dependentType, user, callback, success) => async (dispatch, getState) => {
    const locale = getState().contentReducer.locale;
    const headers = { Lang: `${locale}-CA` };
    try {
      const dependentURL =
        dependentType === "updatestudent"
          ? "/Dependent?type=updatestudent"
          : "/Dependent";
      const response = await flexit.put(dependentURL, user, { headers });
      dispatch({
        type: UPDATE_DEPENDENT,
        payload: response.data
      });
      if (response.data.errorMessage) {
        if (callback) {
          callback(
            response.data.validationErrors
              ? response.data.validationErrors
              : response.data.errorMessage
          );
        }
      } else {
        if (success) success();
      }
    } catch (e) {
      dispatch({ type: FAILURE, payload: e.message });
    }
  };

export const createDependent =
  (dependent, failure, success) => async (dispatch, getState) => {
    const locale = getState().contentReducer.locale;
    const headers = { Lang: `${locale}-CA` };
    try {
      const response = await flexit.post("/Dependent", dependent, { headers });
      if (response.data.errorMessage) {
        dispatch({
          type: FAILURE,
          payload: response.data.validationErrors
            ? response.data.validationErrors
            : response.data.errorMessage
        });
        if (failure)
          failure(
            response.data.validationErrors
              ? response.data.validationErrors
              : response.data.errorMessage
          );
      } else {
        dispatch({
          type: CREATE_DEPENDENT,
          payload: dependent
        });
        if (success) {
          success();
        }
      }
    } catch (e) {
      dispatch({ type: FAILURE, payload: e.message });
      if (failure)
        failure(
          e.response.data.validationErrors
            ? e.response.data.validationErrors
            : e.message
        );
    }
  };

export const deleteDependent =
  (dependentId, success) => async (dispatch, getState) => {
    const locale = getState().contentReducer.locale;
    const headers = { Lang: `${locale}-CA` };
    try {
      await flexit.delete("/Dependent/" + dependentId, { headers });
      dispatch({
        type: DELETE_DEPENDENT,
        payload: dependentId
      });
      if (success) success();
    } catch (e) {
      const message = e.message;
      dispatch({ type: FAILURE, payload: { message } });
    }
  };
export const getDependents = (dependentType) => async (dispatch, getState) => {
  const locale = getState().contentReducer.locale;
  const headers = { Lang: `${locale}-CA` };
  try {
    const dependentURL =
      dependentType === "updatestudent"
        ? "/Dependent?type=updatestudent"
        : "/Dependent";
    const response = await flexit.get(dependentURL, { headers });
    dispatch({
      type: GET_DEPENDENTS,
      payload: response.data
    });
    if (localStorage.getItem("isReview") || localStorage.getItem("lifeEvent")) {
      trackPromise(dispatch(progressBarIndicator()));
    }
    const menu2Flag = getState().userReducer.menu2Flag;
    if (
      menu2Flag &&
      (localStorage.getItem("isReview") || localStorage.getItem("lifeEvent"))
    ) {
      trackPromise(dispatch(getMenu2()));
      trackPromise(dispatch(getShowFlex()));
      dispatch(setMenu2Flag(false));
    }
  } catch (e) {
    const message = e.message;
    dispatch({ type: FAILURE, payload: { message } });
  }
};

export const getEnrollmentHeader = () => async (dispatch, getState) => {
  const locale = getState().contentReducer.locale;
  const headers = { Lang: `${locale}-CA` };
  try {
    const response = await flexit.get(`/EnrollmentHeader`, { headers });
    dispatch({
      type: GET_ENROLMENT_HEADER,
      payload: response.data
    });
  } catch (e) {
    const message = e.message;
    dispatch({ type: FAILURE, payload: { message } });
  }
};

export const getEnrollmentFooter = (callback) => async (dispatch, getState) => {
  const locale = getState().contentReducer.locale;
  const headers = { Lang: `${locale}-CA` };
  try {
    const response = await flexit.get(`/EnrollmentFooter`, { headers });
    if (response && response.data && response.data.enrollmentReturnCode === 0) {
      dispatch({
        type: GET_ENROLMENT_FOOTER,
        payload: response.data
      });
      if (callback) {
        callback();
      }
    } else {
      dispatch({ type: FAILURE, payload: response.data.errorMessage });
    }
  } catch (e) {
    const message = e.message;
    dispatch({ type: FAILURE, payload: { message } });
  }
};

export const getContentFooter = (callback) => async (dispatch, getState) => {
  const locale = getState().contentReducer.locale;
  const headers = { Lang: `${locale}-CA` };
  try {
    const response = await flexit.get(`/Content/Footer`, { headers });
    if (response && response.data && response.data.statusCode === 200) {
      dispatch({
        type: GET_CONTENT_FOOTER,
        payload: response.data
      });
    }
    if (callback) {
      callback();
    }
  } catch (e) {
    const message = e.message;
    dispatch({ type: FAILURE, payload: { message } });
  }
};

export const getContentFooterPage =
  (section, success) => async (dispatch, getState) => {
    const locale = getState().contentReducer.locale;
    const headers = { Lang: `${locale}-CA` };
    try {
      const response = await flexit.get(`/Content/Footer/${section}`, {
        headers
      });
      dispatch({
        type: GET_CONTENT_FOOTER_PAGE,
        payload: response.data
      });
      if (success) success();
    } catch (e) {
      const message = e.message;
      dispatch({ type: FAILURE, payload: { message } });
    }
  };

export const disableNext = () => async (dispatch) => {
  dispatch({
    type: DISABLE_NEXT,
    payload: true
  });
};

export const enableNext = () => async (dispatch) => {
  dispatch({
    type: DISABLE_NEXT,
    payload: false
  });
};

export const getShowFlex = (benefitid) => async (dispatch, getState) => {
  const locale = getState().contentReducer.locale;
  const headers = { Lang: `${locale}-CA` };
  try {
    const response = await flexit.get(`/EnrollmentHeader/showFlex`, {
      headers
    });
    dispatch({
      type: GET_SHOW_FLEX,
      payload: response.data
    });
    if (response.data && response.data.statusCode === 200) {
      const headerInfo = {
        firsName: response.data.firsName,
        lastName: response.data.lastName,
        divisionName: response.data.divisionName,
        headerEffectiveDate: response.data.headerEffectiveDate,
        effectiveDateText: response.data.effectiveDateText
      };

      dispatch({
        type: SET_HEADER_INFO,
        payload: headerInfo
      });
    }
  } catch (e) {
    const message = e.message;
    dispatch({ type: FAILURE, payload: { message } });
  }
};

export const getLifeBenefit = (benefitid) => async (dispatch, getState) => {
  const locale = getState().contentReducer.locale;
  const headers = { Lang: `${locale}-CA` };
  try {
    const response = await flexit.get(`/Life?benefitid=${benefitid}`, {
      headers
    });
    dispatch({
      type: GET_BASIC_LIFE,
      payload: response.data
    });
  } catch (e) {
    dispatch({ type: FAILURE, payload: e.message });
  }
};

export const getHealthBenefit = (benefitid) => async (dispatch, getState) => {
  const locale = getState().contentReducer.locale;
  const headers = { Lang: `${locale}-CA` };
  try {
    const response = await flexit.get(`/Health?benefitid=${benefitid}`, {
      headers
    });
    dispatch({
      type: GET_HEALTH,
      payload: response.data
    });
  } catch (e) {
    dispatch({ type: FAILURE, payload: e.message });
  }
};

export const getADDBenefit = (benefitid) => async (dispatch, getState) => {
  const locale = getState().contentReducer.locale;
  const headers = { Lang: `${locale}-CA` };
  try {
    const response = await flexit.get(`/ADD?benefitid=${benefitid}`, {
      headers
    });
    dispatch({
      type: GET_ADD,
      payload: response.data
    });
  } catch (e) {
    dispatch({ type: FAILURE, payload: e.message });
  }
};

export const getADDWCBenefit = (benefitid) => async (dispatch, getState) => {
  const locale = getState().contentReducer.locale;
  const headers = { Lang: `${locale}-CA` };
  try {
    const response = await flexit.get(`/ADDWC?benefitid=${benefitid}`, {
      headers
    });
    dispatch({
      type: GET_ADDWC,
      payload: response.data
    });
  } catch (e) {
    dispatch({ type: FAILURE, payload: e.message });
  }
};

export const getDisabilityBenefit =
  (benefitid) => async (dispatch, getState) => {
    const locale = getState().contentReducer.locale;
    const headers = { Lang: `${locale}-CA` };
    try {
      const response = await flexit.get(`/Disability?benefitid=${benefitid}`, {
        headers
      });
      dispatch({
        type: GET_DISABILITY,
        payload: response.data
      });
    } catch (e) {
      dispatch({ type: FAILURE, payload: e.message });
    }
  };

export const getWellness = () => async (dispatch, getState) => {
  const locale = getState().contentReducer.locale;
  const headers = { Lang: `${locale}-CA` };
  try {
    const response = await flexit.get(`/Wellness`, { headers });
    dispatch({
      type: GET_WELLNESS,
      payload: response.data
    });
    const menu2Flag = getState().userReducer.menu2Flag;
    if (
      menu2Flag &&
      (localStorage.getItem("isReview") || localStorage.getItem("lifeEvent"))
    ) {
      trackPromise(dispatch(getMenu2()));
      trackPromise(dispatch(getShowFlex()));
      dispatch(setMenu2Flag(false));
    }
  } catch (e) {
    const message = e.message;
    dispatch({ type: FAILURE, payload: { message } });
  }
};

export const updateWellness = (failure) => async (dispatch, getState) => {
  const locale = getState().contentReducer.locale;
  const wellnessQuestionAnswers = getState().userReducer.wellness.result;
  const headers = { Lang: `${locale}-CA` };
  try {
    const response = await flexit.put("/Wellness", wellnessQuestionAnswers, {
      headers
    });
    if (response.data.statusCode === 304 && response.data.errorMessage) {
      if (failure) {
        failure();
      }
    }
    dispatch({
      type: UPDATE_WELLNESS,
      payload: response.data
    });
  } catch (e) {
    const message = e.message;
    dispatch({ type: FAILURE, payload: { message } });
  }
};

export const clearWellnessError = () => (dispatch) => {
  try {
    dispatch({
      type: UPDATE_WELLNESS,
      payload: null
    });
  } catch (e) {
    dispatch({ type: FAILURE, payload: e.message });
  }
};

export const getChangeEvent = () => async (dispatch, getState) => {
  const locale = getState().contentReducer.locale;
  const headers = { Lang: `${locale}-CA` };
  try {
    const response = await flexit.get(`/Home/LifeChangeEvent`, { headers });
    dispatch({
      type: GET_CHANGE_EVENT,
      payload: response.data
    });
  } catch (e) {
    const message = e.message;
    dispatch({ type: FAILURE, payload: { message } });
  }
};

export const changeEvent = () => async (dispatch, getState) => {
  const locale = getState().contentReducer.locale;
  const headers = { Lang: `${locale}-CA` };
  try {
    const response = await flexit.put(`/Home/LifeChangeEvent`, { headers });
    dispatch({
      type: CHANGE_EVENT,
      payload: response.data
    });
  } catch (e) {
    const message = e.message;
    dispatch({ type: FAILURE, payload: { message } });
  }
};

export const toggleSideBar = (isOpen) => async (dispatch, getState) => {
  dispatch({
    type: TOGGLE_SIDEBAR,
    payload: isOpen
  });
};

export const setBenefitOption =
  (optionId, save) => async (dispatch, getState) => {
    const locale = getState().contentReducer.locale;
    const headers = { Lang: `${locale}-CA` };
    const [benefitName, benefitid] =
      getState().userReducer.pageSetting.link.split("/");
    if (benefitid && parseInt(benefitid)) {
      dispatch({
        type: SET_BENEFIT_OPTION,
        payload: { benefitID: benefitid, optionID: optionId }
      });
      if (save) {
        const paramProp =
          benefitName.toLowerCase() === "package"
            ? "packageGroupID"
            : "benefitID";
        const reqParams = { optionID: optionId };
        reqParams[paramProp] = benefitid;
        try {
          const url = `/${benefitName}/ChangeOption`;
          const response = await flexit.put(url, reqParams, { headers });
          await dispatch(getShowFlex());
          if (response && response.data && response.data.statusCode === 200) {
            dispatch({
              type: CHANGE_OPTION,
              payload: response.data
            });
          }
        } catch (e) {
          const message = e.message;
          dispatch({ type: FAILURE, payload: { message } });
        }
      }
    }
  };

export const setMultiBenefitOption =
  (benefitURL, optionId, save) => async (dispatch, getState) => {
    const locale = getState().contentReducer.locale;
    const headers = { Lang: `${locale}-CA` };
    const [benefitName, benefitid] = benefitURL.split("/");
    if (benefitid && parseInt(benefitid)) {
      // dispatch({
      //   type: SET_BENEFIT_OPTION,
      //   payload: { benefitID: benefitid, optionID: optionId }
      // })
      if (save) {
        const paramProp =
          benefitName.toLowerCase() === "package"
            ? "packageGroupID"
            : "benefitID";
        const reqParams = { optionID: optionId };
        console.log(reqParams, optionId);
        reqParams[paramProp] = benefitid;
        try {
          const url = `/${benefitName}/ChangeOption`;
          const response = await flexit.put(url, reqParams, { headers });
          if (response && response.data && response.data.statusCode === 200) {
            // dispatch({
            //   type: CHANGE_OPTION,
            //   payload: response.data
            // })
            await trackPromise(dispatch(getMultiBenefits()));
            await trackPromise(dispatch(getShowFlex()));
          }
        } catch (e) {
          const message = e.message;
          dispatch({ type: FAILURE, payload: { message } });
        }
      }
    }
  };

export const removeEOIOption =
  (benefitURL = undefined) =>
  async (dispatch, getState) => {
    const locale = getState().contentReducer.locale;
    const headers = { Lang: `${locale}-CA` };
    const [benefitName, benefitid] = benefitURL
      ? benefitURL.split("/")
      : getState().userReducer.pageSetting.link.split("/");
    if (benefitid && parseInt(benefitid)) {
      try {
        const url = `/${benefitName}/RemoveEOI?benefitID=${benefitid}`;
        const response = await flexit.put(url, { headers });
        dispatch({
          type: REMOVE_EOI,
          payload: response.data
        });

        if (benefitURL) {
          await trackPromise(dispatch(getMultiBenefits()));
        }
        dispatch(getShowFlex());
      } catch (e) {
        const message = e.message;
        dispatch({ type: FAILURE, payload: { message } });
      }
    }
  };

export const removeWaiver =
  (benefitURL = undefined) =>
  async (dispatch, getState) => {
    const locale = getState().contentReducer.locale;
    const headers = { Lang: `${locale}-CA` };
    const [benefitName, benefitid] = benefitURL
      ? benefitURL.split("/")
      : getState().userReducer.pageSetting.link.split("/");
    if (benefitid && parseInt(benefitid)) {
      try {
        const url = `/${benefitName}/RemoveWaiver`;
        //{benefitID: benefitid, optionID: "0"}
        const response = await flexit.put(
          url,
          { benefitID: benefitid, optionID: "0" },
          { headers }
        );
        dispatch({
          type: REMOVE_WAIVER,
          payload: response.data
        });
        if (benefitURL) {
          await trackPromise(dispatch(getMultiBenefits()));
        }
        dispatch(getShowFlex());
      } catch (e) {
        const message = e.message;
        dispatch({ type: FAILURE, payload: { message } });
      }
    }
  };

export const getContentHelp =
  (helpLink, success) => async (dispatch, getState) => {
    const locale = getState().contentReducer.locale;
    const headers = { Lang: `${locale}-CA` };
    try {
      const response = await flexit.get(`/Content/Help/${helpLink}`, {
        headers
      });
      if (response && response.data && response.data.statusCode === 200) {
        dispatch({
          type: GET_CONTENT_HELP,
          payload: response.data
        });
      }
      if (success) {
        success();
      }
    } catch (e) {
      const message = e.message;
      dispatch({ type: FAILURE, payload: { message } });
    }
  };

/* E-Signature Start */
export const getBeneficiaryDeclaration =
  (btnNavigateTo, success) => async (dispatch, getState) => {
    const locale = getState().contentReducer.locale;
    const headers = { Lang: `${locale}-CA` };
    try {
      const response = await flexit.get(`${btnNavigateTo}`, { headers });
      if (response && response.data && response.data.statusCode === 200) {
        dispatch({
          type: GET_CONTENT_HELP,
          payload: response.data
        });
      }
      if (success) {
        success();
      }
    } catch (e) {
      const message = e.message;
      dispatch({ type: FAILURE, payload: { message } });
    }
  };
/* E-Signature End */

export const getCustomPopUpURL =
  (link, success) => async (dispatch, getState) => {
    const locale = getState().contentReducer.locale;
    const headers = { Lang: `${locale}-CA` };
    try {
      const response = await flexit.get(`/Content/${link}`, { headers });
      if (response && response.data && response.data.statusCode === 200) {
        dispatch({
          type: GET_CONTENT_HELP,
          payload: response.data
        });
      }
      if (success) {
        success();
      }
    } catch (e) {
      dispatch({ type: FAILURE, payload: e.message });
    }
  };

export const getContentHelpMenu =
  (pageName, success) => async (dispatch, getState) => {
    const locale = getState().contentReducer.locale;
    const headers = { Lang: `${locale}-CA` };
    try {
      const url = pageName
        ? `/Content/HelpMenu/${pageName}`
        : `/Content/HelpMenu`;
      const response = await flexit.get(url, { headers });
      if (response && response.data && response.data.statusCode === 200) {
        dispatch({
          type: GET_CONTENT_HELP_MENU,
          payload: response.data
        });
      }
      if (success) {
        success();
      }
    } catch (e) {
      const message = e.message;
      dispatch({ type: FAILURE, payload: { message } });
    }
  };

export const saveWaiverForm =
  (
    waiverReason,
    insuranceCompany,
    policyNumber,
    packageWaiverObj = null,
    benefitURL = undefined
  ) =>
  async (dispatch, getState) => {
    const locale = getState().contentReducer.locale;
    const headers = { Lang: `${locale}-CA` };
    const [benefitName, benefitid] = benefitURL
      ? benefitURL.split("/")
      : getState().userReducer.pageSetting.link.split("/");
    if (benefitid && parseInt(benefitid)) {
      try {
        let reqParams = {
          waiverReason: waiverReason,
          insuranceCompany: insuranceCompany,
          policyNumber: policyNumber,
          benefitID: benefitid
        };
        if (benefitName.toLowerCase() === "package") {
          reqParams = packageWaiverObj.map((item) => ({
            ...item,
            packageGroupId: benefitid
          }));
        }
        const url = `/${benefitName}/CoverageWaiver`;
        const response = await flexit.put(url, reqParams, { headers });
        dispatch({
          type: SAVE_WAIVER_FORM,
          payload: response.data
        });
        if (benefitURL) {
          await trackPromise(dispatch(getMultiBenefits()));
        }
        dispatch(getShowFlex());
      } catch (e) {
        const message = e.message;
        dispatch({ type: FAILURE, payload: { message } });
      }
    }
  };

export const saveDependentWaiverForm =
  (
    waiverReason,
    insuranceCompany,
    policyNumber,
    packageWaiverObj = null,
    benefitURL = undefined
  ) =>
  async (dispatch, getState) => {
    const locale = getState().contentReducer.locale;
    const headers = { Lang: `${locale}-CA` };
    const [benefitName, benefitid] = benefitURL
      ? benefitURL.split("/")
      : getState().userReducer.pageSetting.link.split("/");
    if (benefitid && parseInt(benefitid)) {
      try {
        let reqParams = {
          waiverReason: waiverReason,
          insuranceCompany: insuranceCompany,
          policyNumber: policyNumber,
          benefitID: benefitid
        };
        if (benefitName.toLowerCase() === "package") {
          const [optionsObject] = packageWaiverObj.map((item) => ({
            ...item,
            packageGroupId: benefitid
          }));
          reqParams = optionsObject;
        }
        const url = `/${benefitName}/OptOutWaiver`;
        const response = await flexit.put(url, reqParams, { headers });
        if (response && response.data && response.data.statusCode === 200) {
          dispatch({
            type: SAVE_DEPENDENT_WAIVER_FORM,
            payload: response.data
          });
          if (benefitURL) {
            await trackPromise(dispatch(getMultiBenefits()));
          }
          dispatch(getShowFlex());
        } else {
          const message = response.data.errorMessage
            ? response.data.errorMessage
            : response.errorMessage || response.statusText;
          dispatch({ type: FAILURE, payload: { message } });
        }
      } catch (e) {
        const message = e.message;
        dispatch({ type: FAILURE, payload: { message } });
      }
    }
  };

export const saveChangeDependentCoverage =
  (dependentsThisPlan, dependentsOtherPlan, benefitURL) =>
  async (dispatch, getState) => {
    const locale = getState().contentReducer.locale;
    const headers = { Lang: `${locale}-CA` };

    const [benefitName, benefitid] = benefitURL
      ? benefitURL.split("/")
      : getState().userReducer.pageSetting.link.split("/");
    const packageGroupID = "packageGroupID";
    const reqParams = {
      dependentsThisPlan,
      dependentsOtherPlan
    };
    if (benefitName.toLowerCase() === "package") {
      reqParams[packageGroupID] = parseInt(benefitid);
    }

    if (benefitid && parseInt(benefitid)) {
      try {
        const url = `/${benefitName}/DependentCoverageChange`;
        const response = await flexit.put(url, reqParams, { headers });
        if (response && response.data && response.data.statusCode === 200) {
          dispatch({
            type: PUT_DEPENDED_COVERAGE,
            payload: response.data
          });
          if (benefitURL) {
            await trackPromise(dispatch(getMultiBenefits()));
          }
          dispatch(getShowFlex());
        } else {
          const message = response.data.errorMessage
            ? response.data.errorMessage
            : response.errorMessage || response.statusText;
          dispatch({ type: FAILURE, payload: { message } });
        }
      } catch (e) {
        const message = e.message;
        dispatch({ type: FAILURE, payload: { message } });
      }
    }
  };

const saveBenefit = async (locale, url, body, dispatch) => {
  const headers = { Lang: `${locale}-CA` };
  try {
    const response = await flexit.post(url, body, { headers });
    if (response.data && response.data.statusCode === 500) {
      const message = response.data.errorMessage;
      dispatch({ type: FAILURE, payload: { message } });
    }

    return response.data;
  } catch (e) {
    const message = e.message;
    dispatch({ type: FAILURE, payload: { message } });
  }
};

const getBenefit = async (
  locale,
  url,
  dispatch = undefined,
  deviceType = "Mobile"
) => {
  const headers = { Lang: `${locale}-CA` };
  const [benefitName, benefitid] = url.split("/");
  const paramName =
    benefitName.toLowerCase() === "package" ? "packageGroupID" : "benefitid";
  if (benefitid && parseInt(benefitid)) {
    try {
      url = `/${benefitName}?${paramName}=${benefitid}`;
      const response = await flexit.get(url, { headers });
      if (deviceType !== "Desktop" && dispatch) {
        trackPromise(dispatch(progressBarIndicator()));
      }
      return response.data;
    } catch (e) {
      //dispatch({type: FAILURE, payload: e.message})
    }
  }
};

export const getMultiBenefits = () => async (dispatch, getState) => {
  const benefitList = [];
  const locale = getState().contentReducer.locale;
  const benefitUrlList = getState().userReducer.benefitUrlList;
  // eslint-disable-next-line
  for (const [index, benefit] of benefitUrlList.entries()) {
    const benfitData = await getBenefit(
      locale,
      benefit,
      dispatch,
      getState().userReducer.deviceType
    );
    benefitList.push(benfitData);
  }
  trackPromise(dispatch(progressBarIndicator()));
  //const benList = Promise.all(benefitList)
  dispatch({
    type: GET_MULTI_BENEFIT_DATA,
    payload: benefitList
  });
};

// const mfa = { name: "MFA", link: "registration", order: -1 };
const life = { name: "LIFE", link: "life/11", order: -1 };
const health = { name: "HEALTH", link: "health/1", order: -1 };
const welcome = { name: "WELCOME", link: "", display: "", order: -1 };
const dependents = {
  name: "REVIEWDEPENDENTPROFILE",
  link: "Dependent",
  order: 0
};
const wellness = {
  name: "WELLNESS",
  link: "wellness",
  display: "Wellness",
  order: -1
};
const reviewpersonalprofile = {
  name: "PERSONALINFORMATION",
  link: "Profile",
  order: 0,
  display: "Review Personal Profile"
};
const lifeChangeEvent = {
  name: "LifeChangeEvent",
  link: "LifeChangeEvent",
  order: 40,
  display: "Life Change Event"
};

export const navigatesTo = (history, link) => async (dispatch, getState) => {
  link = link ? link.replace(/\//g, "") : link;
  const screens = getState().contentReducer.screens;
  let screen = _.where(screens, { link });
  if (screen.length === 0)
    console.log(`No screens found for ${link}, so lets see Welcome screen`);
  screen = screen.length > 0 ? screen[screen.length - 1] : welcome;
  dispatch({
    type: SET_PAGE_SETTING,
    payload: { screen }
  });
  history.push(`/${screen.link}`);
};

export const navigatesToLifeChangeEvent =
  (history) => async (dispatch, getState) => {
    let screen = lifeChangeEvent;
    dispatch({
      type: SET_BASE_PAGE_ITEM,
      payload: ""
    });
    dispatch({
      type: SET_PAGE_SETTING,
      payload: { screen }
    });
    history.push(`/${screen.link}`);
  };

export const setMenu2Flag = (flag) => (dispatch) => {
  dispatch({
    type: SET_MENU2_FLAG,
    payload: flag
  });
};

export const setPageSetting =
  (pageName, callMenu, noMultiUpdate = false, callback) =>
  async (dispatch, getState) => {
    const screens = getState().contentReducer.screens;
    let screen = _.where(screens, { name: pageName.toUpperCase() });
    screen = screen.length > 0 ? screen[screen.length - 1] : pageName === 'MFA' ? mfa : welcome;
    const locale = getState().contentReducer.locale;
    // if (pageName.toUpperCase() === dependents.name || pageName.toLowerCase() === dependents.link || pageName === dependents.display )
    //   screen = dependents
    let benefit;
    if (screen.link instanceof Array) {
      let benefitUrlList = [];
      _.each(screen.link, (item) => {
        benefitUrlList.push(item.actinLink);
      });

      dispatch({
        type: BENEFIT_URL_LIST,
        payload: benefitUrlList
      });
      if (!noMultiUpdate) await trackPromise(dispatch(getMultiBenefits()));
      benefit = true;
    } else {
      benefit = await getBenefit(locale, screen.link, dispatch);
    }

    if (callMenu) dispatch(setMenu2Flag(benefit ? false : true));
    dispatch(setBasePageTitle(screen));
    await dispatch({
      type: SET_PAGE_SETTING,
      payload: { screen, benefit }
    });
    if (callback) callback();
    if (callMenu && benefit !== undefined) {
      trackPromise(dispatch(getMenu2()));
      trackPromise(dispatch(getShowFlex()));
    }
  };

export const putWelcomeMessages = () => async (dispatch, getState) => {
  const locale = getState().contentReducer.locale;
  const headers = { Lang: `${locale}-CA` };
  const lifeChange = getState().userReducer.putWelcomeRequestObject
    ? getState().userReducer.putWelcomeRequestObject
    : {};
  try {
    let response;
    if (typeof lifeChange.lifeEventDate === "object" && Object.keys(lifeChange.lifeEventDate).length !== 0 && lifeChange.lifeEventType !== "") {
      response = await flexit.put(
        "/Home/WelcomeMessages",
        {
          selectedLink: lifeChange.selectedLink,
          lifeEventDate: lifeChange.lifeEventDate,
          lifeEventType: lifeChange.lifeEventType
        },
        { headers }
      );
    } else {
      response = await flexit.put(
        "/Home/WelcomeMessages",
        { selectedLink: lifeChange.selectedLink },
        { headers }
      );
    }
    if (response && response.data && response.data.statusCode === 200) {
      dispatch({
        type: PUT_WELCOME_MESSAGES,
        payload: response.data
      });
    }
  } catch (e) {
    const message = e.message;
    dispatch({ type: FAILURE, payload: { message } });
  }
};

// function findNestedObj(entireObj, keyToFind, valToFind) {
//   let foundObj;
//   JSON.stringify(entireObj, (_, nestedValue) => {
//     if (nestedValue && nestedValue[keyToFind] === valToFind) {
//       foundObj = nestedValue;
//     }
//     return nestedValue;
//   });
//   return foundObj;
// };

export const putWelcome =
  (history, selectedLink, callback, isReview, lifeChange) =>
  async (dispatch, getState) => {
    //trackPromise(dispatch(getMenu2()));
    dispatch(setMenu2Flag(false));
    const locale = getState().contentReducer.locale;
    let deviceType = isMobileOnly ? "Mobile" : "Desktop";
    const headers = { Lang: `${locale}-CA`, DeviceType: deviceType }; //(selectedLink === "lnkCancelEnrollment") ? { "Lang": `${locale}-CA`} : { "Lang": `${locale}-CA`, "DeviceType": deviceType}
    //const uri = (selectedLink === "lnkCancelEnrollment") ? '/Home/Welcome' : '/Home/Welcome?DeviceType=Mobile'
    try {
      let response;
      if (lifeChange) {
        response = await flexit.put(
          "/Home/Welcome",
          {
            selectedLink: lifeChange.selectedLink,
            lifeEventDate: lifeChange.lifeEventDate,
            lifeEventType: lifeChange.lifeEventType
          },
          { headers }
        );
      } else {
        response = await flexit.put(
          "/Home/Welcome",
          { selectedLink },
          { headers }
        );
      }

      let link = response.data.navigateTo;
      // if (link.length > 0 && (typeof link !== "string")) {
      //   link = link[0]
      // }

      if (link && link !== "" && !/EnrollmentFooter/.test(link))
        await dispatch(getMenu2());

      if (
        link &&
        typeof link === "string" &&
        link.split("/").length > 0 &&
        link.split("/")[0] === "VAC"
      ) {
        link = `VACATION/${link.split("/").pop()}`;
      }

      if (
        link &&
        typeof link === "string" &&
        link.split("/").length > 0 &&
        link.split("/")[0] === "Package"
      ) {
        link = `PACKAGE/${link.split("/").pop()}`;
      }

      const screens = getState().contentReducer.screens;
      let screen;
      let navLink;
      if (link.length > 0 && typeof link !== "string") {
        dispatch({
          type: BENEFIT_URL_LIST,
          payload: link
        });

        await trackPromise(dispatch(getMultiBenefits()));

        link = link[0];
        //screen = _.where(screens, {actinLink: link})
        if (deviceType === "Desktop") {
          _.each(screens, (item, index) => {
            if (item.link instanceof Array) {
              let obj = _.where(item.link, { actinLink: link.toUpperCase() });
              if (obj && obj.length !== 0) {
                screen = item;
                navLink = "/Benefits";
                return;
              }
            }
          });
        } else {
          screen = _.where(screens, { link: link });
          screen = screen.length > 0 ? screen[screen.length - 1] : welcome;
          navLink = `/${
            screen.link
              ? screen.link
              : !/EnrollmentFooter/.test(link)
              ? link
              : ""
          }`;
        }
      } else {
        screen = _.where(screens, { link: link });
        screen = screen.length > 0 ? screen[screen.length - 1] : welcome;
        navLink = `/${
          screen.link ? screen.link : !/EnrollmentFooter/.test(link) ? link : ""
        }`;
      }
      if (
        deviceType === "Desktop" &&
        screens.length > 0 &&
        screen === welcome
      ) {
        screen = _.where(screens, { link: link });
        //dispatch(setPageSetting(screen[0].name))
        screen.length && dispatch(setPageSetting(screen[0].name));
      }

      // if (link.toUpperCase() === dependents.name || link.toLowerCase() === dependents.link || link === dependents.display ) {
      //   screen = [dependents]
      // }

      // if (screen.length === 0) console.log(`No screens found for ${link}, so lets see Welcome screen`)
      // screen = screen.length > 0 ? screen[screen.length -1] : welcome
      const benefit =
        deviceType !== "Desktop"
          ? await getBenefit(locale, screen.link ? screen.link : link)
          : {};
      dispatch(setBasePageTitle(screen));
      dispatch({
        type: SET_PAGE_SETTING,
        payload: { screen, benefit }
      });
      dispatch({
        type: IS_DISABLED,
        payload: screen.link ? screen.link : link
      });
      dispatch({
        type: PUT_WELCOME,
        payload: response.data
      });
      {
        let requestedObject = lifeChange;
        if (!lifeChange) {
          requestedObject = {
            selectedLink: selectedLink,
            lifeEventDate: {},
            lifeEventType: ""
          };
        }
        dispatch({
          type: PUT_WELCOME_REQUESTED_OBJECT,
          payload: requestedObject
        });
      }
      localStorage.setItem("hideButtons", true);
      if (callback) {
        callback();
      }
      if (isReview) {
        if (selectedLink === isReview) {
          localStorage.setItem("isReview", false);
        }
      }
      if (lifeChange) {
        localStorage.setItem("logOffCheck", false);
        dispatch(setHeaderNavURL(false));
      } else {
        localStorage.setItem("logOffCheck", true);
        dispatch(setHeaderNavURL(true));
      }

      dispatch({
        type: ENROLLMENT_DEVICE_TYPE,
        payload: deviceType
      });

      //history.push(navLink)
      history && history.push(navLink);
      //history.push(`/${screen.link ? screen.link : !(/EnrollmentFooter/.test(link))  ? link : "" }`)
    } catch (e) {
      const message = e.message;
      dispatch({ type: FAILURE, payload: { message } });
    }
  }; /*Marker*/
export const goNext = (history, pageName) => async (dispatch, getState) => {
  //console.group();
  dispatch(setMenu2Flag(false));
  const locale = getState().contentReducer.locale;
  const headers = { Lang: `${locale}-CA` };
  const currentScreen =
    getState().userReducer.pageSetting.constructor === Array &&
    getState().userReducer.pageSetting.length > 0
      ? getState().userReducer.pageSetting[0]
      : getState().userReducer.pageSetting;
  const deviceType = getState().userReducer.deviceType;
  if (
    currentScreen &&
    currentScreen.link instanceof Array &&
    history.location.pathname.toUpperCase() === "/BENEFITS"
  ) {
    const benefitUrlList = getState().userReducer.benefitUrlList;
    const benefitsList = getState().userReducer.benefitsList;
    let saveBenefitSuccessAmout = 0;
    for (let [index, benefit] of benefitsList.entries()) {
      const [benefitName, benefitid] = benefitUrlList[index].split("/");
      const optionList =
        benefitName.toLowerCase() === "package"
          ? benefit.packageList
          : benefit.options;
      const selectedOption = _.where(optionList, { isSelected: true });
      const optionID = selectedOption.length ? selectedOption[0].optionID : "";
      const reqParamProp =
        (benefitName.toLowerCase() === "package" && "packageGroupID") ||
        "benefitID";
      const selectedBenefitOption = {};
      if (benefitid && optionID) {
        selectedBenefitOption[reqParamProp] = benefitid;
        selectedBenefitOption["optionID"] = optionID;
      } else {
        selectedBenefitOption["benefitID"] = 100 + benefitid;
      }
      const url = `/${benefitName}/SaveBenefitChanges`;
      const responseSave = await saveBenefit(
        locale,
        url,
        selectedBenefitOption,
        dispatch
      );
      if (
        (responseSave || {}).errorMessage ||
        (responseSave || {}).canProceedToNextStep === false
      ) {
        dispatch(setFocusOnBenefitError(true));
        return;
      }
      saveBenefitSuccessAmout += 1;
    }
    if (saveBenefitSuccessAmout !== (benefitUrlList || {}).length) {
      return;
    }
  }

  if (
    currentScreen &&
    typeof currentScreen.link === "string" &&
    currentScreen.link.split("/").length > 1
  ) {
    const [benefitName] = currentScreen.link.split("/");
    const { selectedBenefitOption: benefitOption } = getState().userReducer;
    const reqParamProp =
      (benefitName.toLowerCase() === "package" && "packageGroupID") ||
      "benefitID";
    const selectedBenefitOption = {};
    if (benefitOption) {
      selectedBenefitOption[reqParamProp] = benefitOption.benefitID;
      selectedBenefitOption["optionID"] = benefitOption.optionID;
    } else {
      selectedBenefitOption["benefitID"] = 100 + benefitOption.benefitID;
    }
    const url = `/${benefitName}/SaveBenefitChanges`;
    const responseSave = await saveBenefit(
      locale,
      url,
      selectedBenefitOption,
      dispatch
    );
    if (
      (responseSave || {}).errorMessage ||
      (responseSave || {}).canProceedToNextStep === false
    ) {
      dispatch(setFocusOnBenefitError(true));
      return;
    }
    console.log(
      "on click of Next , response of POST benefit:",
      url,
      selectedBenefitOption,
      responseSave
    );
  }
  if (
    currentScreen &&
    typeof currentScreen.link === "string" &&
    currentScreen.link.split("?").length > 1
  ) {
    const path = currentScreen.link.split("?")[1];
    const search = new URLSearchParams(path);
    const groupID = search.get("GroupID");
    let shouldReturn = false;
    await dispatch(
      saveFlex(parseInt(groupID), () => {
        shouldReturn = true;
      })
    );
    if (shouldReturn) return;
  }
  console.log("currentScreen: ", currentScreen);
  if (
    currentScreen &&
    typeof currentScreen.link === "string" &&
    currentScreen.link.toLowerCase() === "wellness"
  ) {
    let shouldReturn = false;
    await dispatch(
      updateWellness(() => {
        shouldReturn = true;
      })
    );
    if (shouldReturn) return;
  }
  let nextScreen = null;
  let responseNext = null;
  let navLink = "";
  // if(pageName === reviewpersonalprofile.name || currentScreen.name === reviewpersonalprofile.name){
  //   nextScreen = dependents
  //   history.push(`/${nextScreen.link}`)
  // }else{
  responseNext = await flexit.get(`/EnrollmentFooter/nextenrollmentstep`, {
    headers
  });

  dispatch({
    type: NEXT_ENROLLMENT_STEP,
    payload: responseNext.data
  });
  if (
    responseNext.data.nextEvent &&
    responseNext.data.nextEvent instanceof Array
  ) {
    if (deviceType === "Desktop") {
      if (
        pageName === dependents.name ||
        currentScreen.name === dependents.name ||
        (typeof currentScreen.link === "string" &&
          currentScreen.link.toLowerCase() === "dependent")
      ) {
        await dispatch(getMenu2());
      }
      dispatch({
        type: BENEFIT_URL_LIST,
        payload: responseNext.data.nextEvent
      });
      await trackPromise(dispatch(getMultiBenefits()));
      responseNext.data.nextEvent = responseNext.data.nextEvent[0];
      const screens = getState().contentReducer.screens;
      _.each(screens, (item, index) => {
        if (item.link instanceof Array) {
          let obj = _.where(item.link, {
            actinLink: responseNext.data.nextEvent.toUpperCase()
          });
          if (obj && obj.length !== 0) {
            nextScreen = item;
            navLink = "/Benefits";
            return;
          }
        }
      });
      await dispatch(getShowFlex());
      dispatch(setBasePageTitle(nextScreen ? nextScreen : welcome));
      const benefit = {};
      dispatch({
        type: SET_PAGE_SETTING,
        payload: { screen: nextScreen ? nextScreen : welcome, benefit }
      });
      dispatch({
        type: IS_DISABLED,
        payload: (nextScreen || {}).link
          ? nextScreen.link
          : responseNext.data.nextEvent
      });
      history.push(navLink ? navLink : "/");

      return;
    } else {
      responseNext.data.nextEvent = responseNext.data.nextEvent[0];
    }
  }

  if (
    pageName === dependents.name ||
    currentScreen.name === dependents.name ||
    (typeof currentScreen.link === "string" &&
      currentScreen.link.toLowerCase() === "dependent")
  ) {
    await dispatch(getMenu2()); //Update menu2 content to match API logic
    //  if(responseNext.data.nextEvent === "Dependent"){
    //   history.push(`/${dependents.link}`)
    //   return;
    //  }
  }
  // console.log('on click of Next , response of GET enrolmentFooter/nextenrollmentstep:', responseNext)

  const screens = getState().contentReducer.screens;
  if (!pageName && currentScreen) {
    if (
      responseNext &&
      typeof responseNext.data.nextEvent === "string" &&
      responseNext.data.nextEvent.split("/").length > 0 &&
      responseNext.data.nextEvent.split("/")[0] === "VAC"
    ) {
      responseNext.data.nextEvent = `VACATION/${responseNext.data.nextEvent
        .split("/")
        .pop()}`;
    }

    if (
      responseNext &&
      typeof responseNext.data.nextEvent === "string" &&
      responseNext.data.nextEvent.split("/").length > 0 &&
      responseNext.data.nextEvent.split("/")[0] === "Package"
    ) {
      responseNext.data.nextEvent = `PACKAGE/${responseNext.data.nextEvent
        .split("/")
        .pop()}`;
    }
    console.log("screens: ", screens);
    screens.map((item) => {
      if (item.link === responseNext.data.nextEvent) {
        nextScreen = item;
      }
      return true;
    });
    console.log("nextScreen: ", nextScreen);
  }
  history.push(`/${responseNext.data.nextEvent}`);
  //}
  if (pageName || nextScreen == null) {
    nextScreen = getNextPage(pageName ? pageName : currentScreen.name);
  }
  const benefit =
    deviceType !== "Desktop"
      ? await getBenefit(
          locale,
          nextScreen.link ? nextScreen.link : responseNext.data.nextEvent,
          dispatch
        )
      : {};
  if (responseNext && responseNext.data.nextEvent) {
    await dispatch(getShowFlex());
  }
  dispatch(setBasePageTitle(nextScreen));
  dispatch({
    type: SET_PAGE_SETTING,
    payload: { screen: nextScreen, benefit }
  });
  dispatch({
    type: IS_DISABLED,
    payload: nextScreen.link ? nextScreen.link : responseNext.data.nextEvent
  });
  //console.gtoupEnd();
};

export const saveBenefitsWithSmoker =
  (history, smokerOptions) => async (dispatch, getState) => {
    const locale = getState().contentReducer.locale;
    const currentScreen =
      getState().userReducer.pageSetting.constructor === Array &&
      getState().userReducer.pageSetting.length > 0
        ? getState().userReducer.pageSetting[0]
        : getState().userReducer.pageSetting;
    if (
      currentScreen &&
      currentScreen.link instanceof Array &&
      history.location.pathname.toUpperCase() === "/BENEFITS"
    ) {
      const benefitUrlList = getState().userReducer.benefitUrlList;
      const benefitsList = getState().userReducer.benefitsList;
      let saveBenefitSuccessAmout = 0;
      var benefitWithOptions = [];
      var benefitOptions = {};
      for (let [index, benefit] of benefitsList.entries()) {
        // console.log(benefit.isDependentSmokerStatusRequired);
        const selectedBenefitOption = {};
        const [benefitName, benefitid] = benefitUrlList[index].split("/");
        const optionList =
          benefitName.toLowerCase() === "package"
            ? benefit.packageList
            : benefit.options;
        const selectedOption = _.where(optionList, { isSelected: true });
        const optionID = selectedOption.length
          ? selectedOption[0].optionID
          : "";
        const reqParamProp =
          (benefitName.toLowerCase() === "package" && "packageGroupID") ||
          "benefitID";
        if (benefitid && optionID) {
          selectedBenefitOption[reqParamProp] = benefitid;
          selectedBenefitOption["optionID"] = optionID;
        } else {
          selectedBenefitOption["benefitID"] = 100 + benefitid;
        }
        /* if ((responseSave || {}).errorMessage || (responseSave || {}).canProceedToNextStep === false) {
        dispatch(setFocusOnBenefitError(true));
        return
      } */
        benefitWithOptions.push(selectedBenefitOption);
        saveBenefitSuccessAmout += 1;
      }
      benefitOptions = { ...smokerOptions, benefits: [...benefitWithOptions] };
      const url = `/EnrollmentFooter/SaveBenefits`;
      const headers = { Lang: `${locale}-CA` };

      try {
        const response = await flexit.post(url, benefitOptions, { headers });
        if (response.data && response.data.statusCode === 500) {
          const message = response.data.errorMessage;
          dispatch({ type: FAILURE, payload: { message } });
        }
        await trackPromise(dispatch(getMultiBenefits()));
        await trackPromise(dispatch(getShowFlex()));
        return response.data;
      } catch (e) {
        const message = e.message;
        dispatch({ type: FAILURE, payload: { message } });
      }
    }
  };

export const languageChange =
  (locale, client, pathname, validate2fa = false, isSSOLogin = false) => async (dispatch, getState) => {
    const isSignedIn = getState().userReducer.isSignedIn;
    dispatch(changeLocale(locale));
    
    if (localStorage.getItem("isReview") || localStorage.getItem("lifeEvent")) {
      trackPromise(dispatch(getMenu2()));
      if (pathname !== "/benefits") trackPromise(dispatch(getShowFlex()));
    } else if (isSignedIn && !validate2fa) {
      !isSSOLogin && trackPromise(dispatch(getMenu()));
      if (pathname !== `/${client}/sso` && pathname !== "/") {
        trackPromise(dispatch(getWelcomeContent()));
      }
    } else {
      dispatch(
        setBasePageTitle(
          getState().userReducer.basePageTitleItem
            ? getState().userReducer.basePageTitleItem
            : getState().userReducer.pageSetting
            ? getState().userReducer.pageSetting
            : {}
        )
      );
    }

    if (pathname.split("/").length > 1 && isSignedIn) {
      trackPromise(dispatch(changeLocaleForBenefit()));
    }
    let path = "";
    if (pathname.split("?").length > 1) {
      path = pathname.split("?")[1];
      const search = new URLSearchParams(path);
      const groupID = search.get("GroupID");
      trackPromise(dispatch(getFlex(parseInt(groupID), true)));
    }

    if (!_.isEmpty(getState().userReducer.putWelcomeResponse.contentResource)) {
      dispatch(putWelcomeMessages());
    }
    if(!isSSOLogin) {
      console.log("isSSOLogin: ", isSSOLogin);
      trackPromise(dispatch(getHeaderImages()));
      trackPromise(dispatch(getContentFooter()));
      trackPromise(dispatch(getSmokerContent()));
    }
    let reqURL =
      pathname.split("?").length > 1 ? pathname.split("?")[0] : pathname;
    console.log("reqURL: ", reqURL);
    
    switch (reqURL) {
      case `/${client}/sso`:
      case "/": {
        trackPromise(dispatch(getMenu()));
        trackPromise(dispatch(getWelcomeContent()));
        break;
      }
      case `/${client}/login`: {
        trackPromise(dispatch(getLoginContent()));
        break;
      }
      case `/${client}/forgotpassword`: {
        trackPromise(dispatch(getForgotPasswordContent()));
        break;
      }
      case "/changepassword": {
        trackPromise(dispatch(getTemporaryPasswordContent()));
        break;
      }
      case "/logoff": {
        trackPromise(dispatch(logoff()));
        // trackPromise(dispatch(getLoginContent()));
        break;
      }
      case "/reviewpersonalprofile": {
        trackPromise(dispatch(getProfile()));
        //trackPromise(dispatch(getDependents()))
        break;
      }
      case "/personalinformation": {
        trackPromise(dispatch(getProfile()));
        break;
      }
      case "/profile": {
        trackPromise(dispatch(getProfile()));
        //trackPromise(dispatch(getDependents()))
        break;
      }
      case "/dependents": {
        //trackPromise(dispatch(getProfile()))
        trackPromise(dispatch(getDependents()));
        break;
      }
      case "/dependent": {
        //trackPromise(dispatch(getProfile()))
        trackPromise(dispatch(getDependents()));
        break;
      }
      case "/life": {
        trackPromise(dispatch(getLifeBenefit()));
        break;
      }
      case "/wellness": {
        trackPromise(dispatch(getWellness()));
        break;
      }
      case "/beneficiaries": {
        trackPromise(dispatch(getBeneficiaries()));
        break;
      }
      case "/lifechangeevent": {
        trackPromise(dispatch(getLifeChangeEvent()));
        break;
      }
      case "/lifechangeeventclaim": {
        trackPromise(dispatch(getLifeChangeEvent()));
        break;
      }
      case "/reviewcoverage/currentcoverage": {
        trackPromise(dispatch(reviewCurrentCoverage(path)));
        break;
      }
      case "/reviewcoverage/previouscoveragelist": {
        trackPromise(dispatch(pastCoverageList()));
        break;
      }
      case "/coverage": {
        trackPromise(
          dispatch(reviewPastCoverage(localStorage.getItem("dateString")))
        );
        break;
      }
      case "/content/footercontent": {
        if (localStorage.getItem("section"))
          trackPromise(
            dispatch(getContentFooterPage(localStorage.getItem("section")))
          );
        else trackPromise(dispatch(getContentFooterPage()));
        break;
      }
      case "/FileUpload/UploadedFilesInfo": {
        trackPromise(dispatch(fileUploadInfo()));
        trackPromise(dispatch(uploadedFilesInfo()));
        break;
      }
      case "/summary": {
        trackPromise(dispatch(getBenefitSummary(true)));
        break;
      }
      case "/summary/personal": {
        trackPromise(dispatch(getSummaryPersonal()));
        break;
      }
      case "/summary/dependents": {
        trackPromise(dispatch(getSummaryDependents()));
        break;
      }
      case "/summary/beneficiaries": {
        trackPromise(dispatch(getSummaryBeneficiaries()));
        break;
      }
      case "/enrollmentsummary": {
        trackPromise(dispatch(getSummaryBenefits(true)));
        trackPromise(dispatch(getSummaryFlex()));
        break;
      }
      case "/content/helpmenu": {
        dispatch(getContentHelpMenu());
        break;
      }
      case "/beneficiary/all": {
        dispatch(getBeneficiariesAll());
        dispatch(finishEnrollmentForms());
        break;
      }
      case "/benefits": {
        await trackPromise(dispatch(getMultiBenefits()));
        await trackPromise(dispatch(getEnrollmentHeader()));
        await trackPromise(dispatch(getShowFlex()));
        break;
      }
      case "/registration": {
        await dispatch(getTFARegContent());
        localStorage.getItem("TypeID") &&
          (await dispatch(
            getTFARegSelection(
              localStorage.getItem("TypeID"),
              localStorage.getItem("isRegistration")
            )
          ));
        const params =
          "isRegistration=" +
          localStorage.getItem("isRegistration") +
          "&selectedTwoFAType=" +
          localStorage.getItem("TypeID");
        await dispatch(verificationContent(params));
        return;
      }
      case "/verification": {
        const params =
          "isRegistration=" +
          localStorage.getItem("isRegistration") +
          "&selectedTwoFAType=" +
          localStorage.getItem("TypeID");
        await dispatch(verificationContent(params));
        return;
      }

      case "/beneficiaryreallocate": {
        const pageName = getState().userReducer.pageSetting.name;
        const benefitID = getState().userReducer.flexBeneficiaryID;
        await trackPromise(
          dispatch(
            reallocateMultiBenefit(
              pageName === "BENEFICIARIES" ? -1 : benefitID
            )
          )
        );
        return;
      }

      default: {
        console.log("do nothing");
      }
    }
  };

export const changeLocaleForBenefit = () => async (dispatch, getState) => {
  const locale = getState().contentReducer.locale;
  const currentScreen = getState().userReducer.pageSetting;
  if (currentScreen && currentScreen.link.split("/").length > 1) {
    const benefit = await getBenefit(locale, currentScreen.link);
    dispatch({
      type: SET_PAGE_SETTING,
      payload: { screen: currentScreen, benefit }
    });
    dispatch(getEnrollmentHeader());
    dispatch(getShowFlex());
  }
};

export const progressBarIndicator = () => async (dispatch, getState) => {
  const headers = { Lang: `${getState().contentReducer.locale}-CA` };
  const progress = await flexit.get(`/EnrollmentFooter/progressIndicator`, {
    headers
  });
  dispatch({
    type: GET_PROGRESS_INDICATOR,
    payload: progress.data
  });
};

export const cancelClick =
  (history, callback) => async (dispatch, getState) => {
    const headers = { Lang: `${getState().contentReducer.locale}-CA` };
    const response = await flexit.delete(`/EnrollmentFooter`, { headers });
    console.log(
      "User click on EnrollmentFooter/Cancel and result is:",
      response
    );
    let screen = welcome;
    dispatch({
      type: SET_PAGE_SETTING,
      payload: { screen }
    });
    if (callback) {
      callback();
    }
    localStorage.setItem("logOffCheck", true);
    dispatch(setHeaderNavURL(true));
    history.push(`/${screen.link}`);
  };

export const modifyEnrollment =
  (history, callback) => async (dispatch, getState) => {
    const headers = { Lang: `${getState().contentReducer.locale}-CA` };
    const response = await flexit.delete(`/EnrollmentFooter/modifyenrollment`, {
      headers
    });
    console.log(
      "User click on EnrollmentFooter/modifyenrollment and result is:",
      response
    );
    let screen = welcome;
    dispatch({
      type: SET_PAGE_SETTING,
      payload: { screen }
    });
    if (callback) {
      callback();
    }
    localStorage.setItem("logOffCheck", true);
    dispatch(setHeaderNavURL(true));
    history.push(`/${screen.link}`);
  };

export const goPrevious = (history, pageName) => async (dispatch, getState) => {
  const headers = { Lang: `${getState().contentReducer.locale}-CA` };
  let responsePrevious = await flexit.get(
    `/EnrollmentFooter/previousenrollmentstep`,
    { headers }
  );
  // console.log('on click of Previous , response of GET EnrollmentFooter/previousenrollmentstep:', responsePrevious)
  const currentScreen = getState().userReducer.pageSetting;
  const screens = getState().contentReducer.screens;
  const deviceType = getState().userReducer.deviceType;
  let previousScreen = null;
  let link = responsePrevious
    ? responsePrevious.data
      ? responsePrevious.data.previousEvent
      : ""
    : "";

  if (link && typeof link !== "string") {
    console.log(((responsePrevious || {}).data || {}).previousEvent, "--<");
    if (deviceType === "Desktop") {
      let screen;
      link = link[0];

      _.each(screens, (item, index) => {
        if (item.link instanceof Array) {
          let obj = _.where(item.link, { actinLink: link.toUpperCase() });
          if (obj && obj.length !== 0) {
            screen = item;
            return;
          }
        }
      });
      dispatch({
        type: BENEFIT_URL_LIST,
        payload: responsePrevious.data.previousEvent
      });
      dispatch({
        type: SET_PAGE_SETTING,
        payload: { screen: screen, benefit: {} }
      });

      dispatch(setBasePageTitle(screen));
      await trackPromise(dispatch(getMultiBenefits()));
      dispatch(setMenu2Flag(false));
      trackPromise(dispatch(getMenu2()));

      history.push("/Benefits");

      return;
    } else {
      responsePrevious.data.previousEvent =
        responsePrevious.data.previousEvent[0];
    }
  }

  if (!pageName && currentScreen) {
    if (!pageName && currentScreen) {
      if (
        responsePrevious &&
        typeof responsePrevious.data.previousEvent === "string" &&
        responsePrevious.data.previousEvent.split("/").length > 0 &&
        responsePrevious.data.previousEvent.split("/")[0] === "Package"
      ) {
        responsePrevious.data.previousEvent = `PACKAGE/${responsePrevious.data.previousEvent
          .split("/")
          .pop()}`;
      }

      screens.map((item) => {
        if (item.link === responsePrevious.data.previousEvent) {
          previousScreen = item;
        }
        return true;
      });
    }
  }

  history.push(`/${responsePrevious.data.previousEvent}`);
  if (previousScreen && responsePrevious.data.previousEvent) {
    await dispatch(getShowFlex());
  }

  if (pageName || previousScreen == null) {
    previousScreen = getPreviousPage(pageName ? pageName : currentScreen.name);
  }

  //EXCEPTIONAL PREVIOUS
  if (
    pageName === dependents.name ||
    currentScreen.name === dependents.name ||
    (typeof currentScreen.link === "string" &&
      currentScreen.link.toLowerCase() === "dependent")
  ) {
    dispatch(enableNext());
    //previousScreen = reviewpersonalprofile
    const navigationError =
      getState().userReducer.nextEnrolStep.navigationError;
    if (navigationError !== null) dispatch(setNavigationError(null));
    //history.push(`/${previousScreen.link}`)
    // }else if (responsePrevious.data.previousEvent.toUpperCase() === dependents.name || responsePrevious.data.previousEvent.toLowerCase() === dependents.link || responsePrevious.data.previousEvent === dependents.display){
    //   previousScreen = dependents
    //   history.push(`/${previousScreen.link}`)
    // }else{
    //   history.push(`/${responsePrevious.data.previousEvent}`)
  }

  const locale = getState().contentReducer.locale;
  const benefit =
    deviceType !== "Desktop"
      ? await getBenefit(
          locale,
          previousScreen.link
            ? previousScreen.link
            : responsePrevious.data.previousEvent,
          dispatch
        )
      : {};
  dispatch(setBasePageTitle(previousScreen));
  dispatch({
    type: SET_PAGE_SETTING,
    payload: { screen: previousScreen, benefit }
  });
  dispatch(setMenu2Flag(benefit ? false : true));
  if (benefit !== undefined) trackPromise(dispatch(getMenu2()));
  // history.push(`/${responsePrevious.data.previousEvent}`)
  // history.push(`/${previousScreen.link}`)
};

export const setNavigationError = (errorValue) => (dispatch) => {
  dispatch({
    type: SET_NAV_ERROR,
    payload: errorValue
  });
};

const getNextPage = (currentPage) => {
  currentPage = currentPage.toLowerCase();
  console.log("currentPage: ", currentPage);
  switch (currentPage) {
    case "lifechangeevent":
      return reviewpersonalprofile;
    case "reviewpersonalprofile":
      return dependents;
    case "personalinformation":
      return dependents;
    case "dependents":
      return wellness;
    case "wellness":
      return health;
    case "health":
      return life;
    case "life":
      return welcome;

    default:
      return welcome;
  }
};
const getPreviousPage = (currentPage) => {
  currentPage = currentPage.toLowerCase();
  switch (currentPage) {
    case "Lifechangeeventclaim":
      return lifeChangeEvent;
    case "Lifechangeevent":
      return welcome;
    case "personalinformation":
      return welcome;
    case "dependents":
      return reviewpersonalprofile;
    case "wellness":
      return dependents;
    case "life":
      return wellness;
    default:
      return welcome;
  }
};

export const setFlexBeneficiaryID =
  (benefitID, callback) => (dispatch, getState) => {
    try {
      dispatch({
        type: SET_FLEX_BENEFICIARY_ID,
        payload: benefitID
      });
      if (callback) callback();
    } catch (exception) {
      console.log("exception found", exception);
    }
  };

export const removeFlexBeneficiaryID = () => (dispatch, getState) => {
  try {
    dispatch({
      type: SET_FLEX_BENEFICIARY_ID,
      payload: null
    });
  } catch (exception) {
    console.log("exception found", exception);
  }
};

export const getBeneficiaries =
  (benefit, beneficiariesAll = false, failure, success) =>
  async (dispatch, getState) => {
    const locale = getState().contentReducer.locale;
    const link = getState().userReducer.pageSetting.link;
    const setting = typeof link !== "string" ? ["-1"] : link.split("/");
    const flexBeneficiaryID = getState().userReducer.flexBeneficiaryID;
    const benefitID = benefit
      ? benefit
      : flexBeneficiaryID
      ? flexBeneficiaryID
      : setting[setting.length - 1];
    const headers = { Lang: `${locale}-CA` };
    try {
      const response = await flexit.get(
        `/Beneficiary?isReview=false&benefitID=${benefitID}&beneficiaryall=${beneficiariesAll}`,
        { headers }
      );
      if (response && response.data && response.data.statusCode > 400) {
        if (failure) failure(response.data);
        const message = response.data.errorMessage;
        dispatch({ type: FAILURE, payload: { message } });
      } else {
        dispatch({
          type: GET_BENEFICIARIES,
          payload: response.data
        });
        if (success) success();
      }
    } catch (e) {
      const message = e.message;
      dispatch({ type: FAILURE, payload: { message } });
    }
  };

export const createBeneficiary =
  (beneficiary, failure, success) => async (dispatch, getState) => {
    const locale = getState().contentReducer.locale;
    const headers = { Lang: `${locale}-CA` };
    const addEstateAddress = "/Beneficiary/AddEditEstate";
    const addPersonAddress = "/Beneficiary/AddEditPerson";
    const addCharityAddress = "/Beneficiary/AddEditCharity";
    const addAddress =
      beneficiary.beneficiaryType === "charity"
        ? addCharityAddress
        : beneficiary.beneficiaryType === "estate"
        ? addEstateAddress
        : addPersonAddress;

    if (beneficiary.beneficiaryType === "estate") {
      beneficiary = {
        BeneficiaryID: beneficiary.beneficiaryId,
        estate: beneficiary.estate
      };
    }
    if (beneficiary.beneficiaryType === "charity") {
      beneficiary = {
        BeneficiaryID: beneficiary.beneficiaryId,
        charity: beneficiary.charity
      };
    }
    try {
      const response = await flexit.post(addAddress, beneficiary, { headers });

      if (response && response.data && response.data.statusCode > 400) {
        if (failure)
          failure(
            response.data.validationErrors
              ? response.data.validationErrors
              : response.data.errorMessage
          );
        dispatch({ type: FAILURE, payload: response.data.errorMessage });
      } else {
        dispatch({
          type: CREATE_BENEFICIARY,
          payload: beneficiary
        });
        if (success) success();
      }
    } catch (e) {
      if (failure)
        failure(
          e.response.data.validationErrors
            ? e.response.data.validationErrors
            : e.response.data.errorMessage
        );
      dispatch({ type: FAILURE, payload: e.message });
    }
  };

export const updateBeneficiary =
  (beneficiary, contBeneficiaryList, benefit, failure, success) =>
  async (dispatch, getState) => {
    const locale = getState().contentReducer.locale;
    const link = getState().userReducer.pageSetting.link;
    const setting = typeof link !== "string" ? ["-1"] : link.split("/");
    const flexBeneficiaryID = getState().userReducer.flexBeneficiaryID;
    const benefitID = benefit
      ? benefit
      : flexBeneficiaryID
      ? flexBeneficiaryID
      : setting[setting.length - 1];
    const headers = { Lang: `${locale}-CA` };
    const beneficiaries = {
      primaryBeneficiary: beneficiary,
      contingentBeneficiary: contBeneficiaryList
    };
    try {
      const response = await flexit.put(
        `/Beneficiary?benefitID=${benefitID}`,
        beneficiaries,
        { headers }
      );

      if (response && response.data && response.data.statusCode > 400) {
        if (failure) failure(response.data.errorMessage);
        dispatch({ type: FAILURE, payload: response.data.errorMessage });
      } else {
        dispatch({
          type: UPDATE_BENEFICIARY,
          payload: {
            beneficiary
          }
        });
        if (success) success();
      }
    } catch (e) {
      if (failure) failure(e.response.data.errorMessage);
      dispatch({ type: FAILURE, payload: e.message });
    }
  };

export const updateIndividualBeneficiary =
  (beneficiary, failure, success) => async (dispatch, getState) => {
    const locale = getState().contentReducer.locale;
    const headers = { Lang: `${locale}-CA` };
    try {
      const response = await flexit.post(
        "/Beneficiary/AddEditPerson",
        beneficiary,
        { headers }
      );

      if (response && response.data && response.data.statusCode > 400) {
        if (failure) failure(response.data.errorMessage);
        dispatch({ type: FAILURE, payload: response.data.errorMessage });
      } else {
        dispatch({
          type: UPDATE_INDIVIDUAL_BENEFICIARY,
          payload: {
            beneficiary
          }
        });
        if (success) success();
      }
    } catch (e) {
      if (failure) failure(e.response.data.errorMessage);
      dispatch({ type: FAILURE, payload: e.message });
    }
  };

export const deleteBeneficiary =
  (beneficiaryId, benefitID, failure, success) =>
  async (dispatch, getState) => {
    const locale = getState().contentReducer.locale;
    const headers = { Lang: `${locale}-CA` };
    try {
      const response = await flexit.put(
        `/Beneficiary/RemoveBeneficiary?beneficiaryID=${beneficiaryId}&benefitID=${benefitID}`,
        { headers }
      );
      if (response && response.data && response.data.statusCode > 400) {
        if (failure) failure(response.data.errorMessage);
        dispatch({ type: FAILURE, payload: response.data.errorMessage });
      } else {
        dispatch({ type: REMOVE_BENEFICIARY, payload: response.data });
        dispatch({ type: DELETE_BENEFICIARY, payload: beneficiaryId });
        if (
          response &&
          response.data &&
          response.data.hasOwnProperty("reallocateActionLink") &&
          response.data.reallocateActionLink != null
        ) {
          trackPromise(dispatch(reallocateMultiBenefit(benefitID)));
        }

        if (success) success();
      }
    } catch (e) {
      if (failure)
        failure(
          e.response.data.errorMessage
            ? e.response.data.errorMessage
            : e.message
        );
      dispatch({ type: FAILURE, payload: e.message });
    }
  };

export const reallocateMultiBenefit =
  (benefitID, failure, success) => async (dispatch, getState) => {
    const locale = getState().contentReducer.locale;
    const headers = { Lang: `${locale}-CA` };
    const screen = { name: "BenefitSummary", link: "/Summary" };
    try {
      if (benefitID === -2) {
        benefitID = -1;
        dispatch({ type: SET_PAGE_SETTING, payload: { screen, benefit: [] } });
      }

      const response = await flexit.get(
        `/Beneficiary/Reallocate?benefitID=${benefitID}`,
        { headers }
      );
      if (response && response.data && response.data.statusCode > 400) {
        if (failure) failure(response.data);
        const message = response.data.errorMessage;
        dispatch({ type: FAILURE, payload: { message } });
      } else {
        console.log("REALLOCATE_MULTIBENEFIT", response.data);
        dispatch({
          type: REALLOCATE_MULTIBENEFIT,
          payload: response.data
        });

        dispatch({
          type: SET_BASE_PAGE_TITLE,
          payload: response.data.tabName
        });

        if (
          response &&
          response.data &&
          response.data.result != null &&
          response.data.result.length > 0
        ) {
          var resultBenefitIDs = response.data.result.toString();
          trackPromise(dispatch(proceedToNextMultiBenefit(resultBenefitIDs)));
        }
        if (success) success();
      }
    } catch (e) {
      const message = e.message;
      dispatch({ type: FAILURE, payload: { message } });
    }
  };
export const proceedToNextMultiBenefit =
  (benefitIDs, failure, success) => async (dispatch, getState) => {
    const locale = getState().contentReducer.locale;
    const headers = { Lang: `${locale}-CA` };
    try {
      const response = await flexit.get(
        `/Beneficiary/ProceedToNext?benefitIDs=${benefitIDs}`,
        { headers }
      );
      if (response && response.data && response.data.statusCode > 400) {
        if (failure) failure(response.data);
        const message = response.data.errorMessage;
        dispatch({ type: FAILURE, payload: { message } });
      } else {
        dispatch({
          type: PROCEED_TO_NEXT,
          payload: response.data
        });
        if (success) success();
      }
    } catch (e) {
      const message = e.message;
      dispatch({ type: FAILURE, payload: { message } });
    }
  };

export const fileUploadInfo = () => async (dispatch, getState) => {
  const locale = getState().contentReducer.locale;
  const headers = { Lang: `${locale}-CA` };
  try {
    const response = await flexit.get("/FileUpload/FileUploadInfo", {
      headers
    });
    dispatch({
      type: FILE_UPLOAD_INFO,
      payload: response.data
    });
  } catch (e) {
    const message = e.message;
    dispatch({ type: FAILURE, payload: { message } });
  }
};

export const uploadedFile =
  (fileID, fileName) => async (dispatch, getState) => {
    const locale = getState().contentReducer.locale;
    const headers = {
      Lang: `${locale}-CA`,
      "Content-Type": "application/json",
      Accept: "application/" + fileName.split(".").pop() + ""
    };
    try {
      const response = await flexit.get(
        "/FileUpload/UploadedFile/?fileID=" + fileID,
        { responseType: "arraybuffer", headers }
      );
      const isIE = checkBrowser();
      if (isIE) {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          let blob = new Blob([response.data], {
            type: "application/" + fileName.split(".").pop() + ""
          });
          window.navigator.msSaveBlob(blob, `${fileName}`);
        }
      } else {
        let url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/" + fileName.split(".").pop() + ""
          })
        );
        let a = document.createElement("a");
        a.href = url;
        a.download = `${fileName}`;
        a.click();
      }
    } catch (e) {
      const message = e.message;
      dispatch({ type: FAILURE, payload: { message } });
    }
  };

export const uploadedFilesInfo = () => async (dispatch, getState) => {
  const locale = getState().contentReducer.locale;
  const headers = { Lang: `${locale}-CA` };
  try {
    const response = await flexit.get("/FileUpload/UploadedFilesInfo", {
      headers
    });
    dispatch({
      type: UPLOADED_FILES_INFO,
      payload: response.data
    });
  } catch (e) {
    const message = e.message;
    dispatch({ type: FAILURE, payload: { message } });
  }
};

export const uploadFile =
  (cancelSource, fileData, errCallback) => async (dispatch, getState) => {
    const locale = getState().contentReducer.locale;
    const headers = {
      Lang: `${locale}-CA`,
      onUploadProgress: (e) => {
        const percentComplete = Math.ceil((e.loaded / e.total) * 100);
        dispatch({ type: PROGRESS_PERCENTAGE, payload: percentComplete });
      },

      cancelToken: cancelSource.token
    };
    try {
      const response = await flexit.post(
        "FileUpload/UploadFile/",
        fileData,
        headers
      );
      dispatch({
        type: UPLOAD_FILE,
        payload: response.data
      });
    } catch (e) {
      dispatch({ type: PROGRESS_PERCENTAGE, payload: 0 });
      dispatch({ type: FAILURE, payload: e.message });
      errCallback(e.message);
    }
  };

export const getFlex =
  (groupID, callProgress, failure) => async (dispatch, getState) => {
    const locale = getState().contentReducer.locale;
    const headers = { Lang: `${locale}-CA` };
    try {
      const response = await flexit.get(
        `/Flex?GroupID=${groupID ? groupID : 1}`,
        { headers }
      );
      if (response && response.data && response.data.statusCode === 200) {
        dispatch({
          type: GET_FLEX,
          payload: response.data
        });
        if (callProgress) trackPromise(dispatch(progressBarIndicator()));
        const menu2Flag = getState().userReducer.menu2Flag;
        if (
          menu2Flag &&
          (localStorage.getItem("isReview") ||
            localStorage.getItem("lifeEvent"))
        ) {
          trackPromise(dispatch(getMenu2()));
          trackPromise(dispatch(getShowFlex()));
          dispatch(setMenu2Flag(false));
        }
      } else {
        if (failure) failure(response.data.errorMessage);
      }
    } catch (e) {
      const message = e.message;
      dispatch({ type: FAILURE, payload: { message } });
    }
  };

export const updateFlex = (payload) => async (dispatch, getState) => {
  const locale = getState().contentReducer.locale;
  const headers = { Lang: `${locale}-CA` };
  console.log("FLEX PUT \n", payload);
  try {
    const response = await flexit.put("/Flex/UpdateFlex", payload, { headers });
    dispatch({
      type: UPDATE_FLEX,
      payload: response.data
    });
    trackPromise(dispatch(getShowFlex()));
  } catch (e) {
    const message = e.message;
    dispatch({ type: FAILURE, payload: { message } });
  }
};

export const flexReallocate = (payload) => async(dispatch, getState) => {
  const locale = getState().contentReducer.locale;
  const headers = { Lang: `${locale}-CA` };
  console.log("FLEX PUT \n", payload);
  try {
    const response = await flexit.put("/Flex/ReallocateFlex", payload, { headers });
    dispatch({
      type: RELOCATE_FLEX,
      payload: response.data
    });
  } catch (e) {
    const message = e.message;
    dispatch({ type: FAILURE, payload: { message } });
  }
}

export const finishEnrollment =
  (formStatus, queryParam = "") =>
  async (dispatch, getState) => {
    const locale = getState().contentReducer.locale;
    const headers = { Lang: `${locale}-CA` };
    const fihishURL =
      formStatus === 1
        ? "/FinishedEnrollment/Forms" + queryParam
        : "/FinishedEnrollment";

    try {
      const response = await flexit.get(fihishURL, { headers });
      if (formStatus !== 1) {
        dispatch(getMenu2());
      }
      /* console.log("Response: ",response); */
      dispatch({
        type: FINISH_ENROLLMENT,
        payload: response.data
      });
    } catch (e) {
      const message = e.message;
      dispatch({ type: FAILURE, payload: { message } });
    }
  };

export const finishEnrollmentForms = () => async (dispatch, getState) => {
  const locale = getState().contentReducer.locale;
  const headers = { Lang: `${locale}-CA` };
  try {
    const response = await flexit.get(
      "/FinishedEnrollment/Forms?formTypes=beneficiaryform",
      { headers }
    );
    /* console.log("Response: ",response); */
    dispatch({
      type: FINISH_ENROLLMENT_FORM,
      payload: response.data
    });
  } catch (e) {
    const message = e.message;
    dispatch({ type: FAILURE, payload: { message } });
  }
};

export const downloadFinishedEnrollmentFile =
  (fileURL, fileName) => async (dispatch, getState) => {
    const locale = getState().contentReducer.locale;
    const headers = {
      Lang: `${locale}-CA`,
      "Content-Type": "application/json",
      Accept: "application/pdf"
    };
    try {
      const response = await flexit.get(fileURL, {
        responseType: "arraybuffer",
        headers
      });
      const isIE = checkBrowser();
      if (isIE) {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          let blob = new Blob([response.data], { type: "application/pdf" });
          window.navigator.msSaveBlob(blob, `${fileName}.pdf`);
        }
      } else {
        let url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        let a = document.createElement("a");
        a.href = url;
        a.download = `${fileName}`;
        a.click();
      }
    } catch (e) {
      const message = e.message;
      dispatch({ type: FAILURE, payload: { message } });
    }
  };
export const reviewCurrentCoverage =
  (sublink, success) => async (dispatch, getState) => {
    const locale = getState().contentReducer.locale;
    const headers = { Lang: `${locale}-CA` };
    try {
      const url = sublink
        ? `/ReviewCoverage/CurrentCoverage?${sublink}`
        : "/ReviewCoverage/CurrentCoverage";
      const response = await flexit.get(url, { headers });
      if (
        response &&
        response.status === 200 &&
        response.data.statusCode !== 500
      ) {
        dispatch({
          type: REVIEW_CURRENT_COVERAGE,
          payload: response.data
        });
        if (success) success();
      }
    } catch (e) {
      const message = e.message;
      dispatch({ type: FAILURE, payload: { message } });
    }
  };

export const reviewPastCoverage =
  (sublink, success) => async (dispatch, getState) => {
    const locale = getState().contentReducer.locale;
    const headers = { Lang: `${locale}-CA` };
    try {
      const response = await flexit.get(
        `/ReviewCoverage/PreviousCoverage?dateTime=${sublink}`,
        { headers }
      );
      if (
        response &&
        response.status === 200 &&
        response.data.statusCode !== 500
      ) {
        dispatch({
          type: REVIEW_PAST_COVERAGE,
          payload: response.data
        });
        if (success) success();
      }
    } catch (e) {
      const message = e.message;
      dispatch({ type: FAILURE, payload: { message } });
    }
  };
export const saveFlex = (groupID, failure) => async (dispatch, getState) => {
  const flexDollars = getState().userReducer.flexDollars;
  const flexGroupID = groupID ? groupID : -1;
  const locale = getState().contentReducer.locale;
  const headers = { Lang: `${locale}-CA` };

  const flexItemList = [];
  _.forEach(flexDollars.benefitFlexes, (benefit) => {
    flexItemList.push({
      flexBenefitID: benefit.benefitID,
      flexAmount:
        benefit.flexAmount.toString() === ""
          ? "0"
          : benefit.flexAmount.toString()
    });
  });
  console.log("POST FLEX \n", flexGroupID, flexItemList);

  try {
    const response = await flexit.post(
      "/Flex/SaveFlex",
      { flexGroupID, flexItemList },
      { headers }
    );
    if (
      response &&
      response.data &&
      response.data.result !== "Failed" &&
      response.data.statusCode === 200
    ) {
      dispatch({
        type: SAVE_FLEX,
        payload: response.data
      });
    } else {
      const message = response.data.result
        ? response.data.result
        : response.data.errorMessage;
      dispatch({ type: FAILURE, payload: { message } });
      if (failure) failure();
    }
  } catch (e) {
    const message = e.message;
    dispatch({ type: FAILURE, payload: { message } });
  }
};

export const pastCoverageList = () => async (dispatch, getState) => {
  const locale = getState().contentReducer.locale;
  const headers = { Lang: `${locale}-CA` };
  try {
    const response = await flexit.get("ReviewCoverage/PreviousCoverageList", {
      headers
    });
    dispatch({
      type: REVIEW_PAST_COVERAGE_LIST,
      payload: response.data
    });
  } catch (e) {
    const message = e.message;
    dispatch({ type: FAILURE, payload: { message } });
  }
};
export const getSummaryBenefits =
  (callProgress) => async (dispatch, getState) => {
    const locale = getState().contentReducer.locale;
    const headers = { Lang: `${locale}-CA` };
    try {
      const response = await flexit.get("/Summary/Benefits", { headers });
      if (response && response.data) {
        dispatch({
          type: GET_SUMMARY_BENEFITS,
          payload: response.data
        });
        if (callProgress) {
          trackPromise(dispatch(progressBarIndicator()));
        }
      } else {
        dispatch({
          type: FAILURE,
          payload: response.errorMessage
        });
      }
    } catch (e) {
      const message = e.message;
      dispatch({ type: FAILURE, payload: { message } });
    }
  };

export const pdfStatement = (url, name) => async (dispatch, getState) => {
  const locale = getState().contentReducer.locale;
  const headers = {
    Lang: `${locale}-CA`,
    "Content-Type": "application/json",
    Accept: "application/pdf"
  };
  const type = url.split("?");
  try {
    const response = await flexit.get(
      `/ReviewCoverage/PDFStatement?${type[type.length - 1]}`,
      { responseType: "arraybuffer", headers }
    );
    const isIE = checkBrowser();
    if (isIE) {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        window.navigator.msSaveBlob(blob, `${name}.pdf`);
      }
    } else {
      let url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      let a = document.createElement("a");
      a.href = url;
      a.download = `${name}.pdf`;
      a.click();
    }
  } catch (e) {
    const message = e.message;
    dispatch({ type: FAILURE, payload: { message } });
  }
};

export const getSummaryFlex = () => async (dispatch, getState) => {
  const locale = getState().contentReducer.locale;
  const headers = { Lang: `${locale}-CA` };
  try {
    const response = await flexit.get("/Summary/Flex", { headers });
    if (response && response.data) {
      dispatch({
        type: GET_SUMMARY_FLEX,
        payload: response.data
      });
    } else {
      dispatch({
        type: FAILURE,
        payload: response.errorMessage
      });
    }
  } catch (e) {
    const message = e.message;
    dispatch({ type: FAILURE, payload: { message } });
  }
};

export const getBenefitSummary =
  (callProgress) => async (dispatch, getState) => {
    const locale = getState().contentReducer.locale;
    const headers = { Lang: `${locale}-CA` };
    try {
      const response = await flexit.get(`Summary/BenefitSummary`, { headers });
      if (response && response.data && response.data.statusCode === 200) {
        dispatch({
          type: GET_BENEFIT_SUMMARY,
          payload: response.data
        });
        if (callProgress) {
          trackPromise(dispatch(progressBarIndicator()));
        }
      } else {
        dispatch({
          type: FAILURE,
          payload: response.data.errorMessage
        });
      }
    } catch (e) {
      const message = e.message;
      dispatch({ type: FAILURE, payload: { message } });
    }
  };

export const getSummaryPersonal = () => async (dispatch, getState) => {
  const locale = getState().contentReducer.locale;
  const headers = { Lang: `${locale}-CA` };
  try {
    const response = await flexit.get(`Summary/Personal`, { headers });
    if (response && response.data && response.data.statusCode === 200) {
      dispatch({
        type: GET_SUMMARY_PERSONAL,
        payload: response.data
      });
    } else {
      dispatch({
        type: FAILURE,
        payload: response.data.errorMessage
      });
    }
  } catch (e) {
    const message = e.message;
    dispatch({ type: FAILURE, payload: { message } });
  }
};

export const getSummaryBeneficiaries = () => async (dispatch, getState) => {
  const locale = getState().contentReducer.locale;
  const headers = { Lang: `${locale}-CA` };
  try {
    const response = await flexit.get(`Summary/Beneficiaries`, { headers });
    if (response && response.data && response.data.statusCode === 200) {
      dispatch({
        type: GET_SUMMARY_BENEFICIARIES,
        payload: response.data
      });
    } else {
      dispatch({
        type: FAILURE,
        payload: response.data.errorMessage
      });
    }
  } catch (e) {
    const message = e.message;
    dispatch({ type: FAILURE, payload: { message } });
  }
};

/* E-Signature Start */
export const getSummaryBeneficiariesESignature =
  (actionLink) => async (dispatch, getState) => {
    const locale = getState().contentReducer.locale;
    const headers = { Lang: `${locale}-CA` };
    try {
      const response = await flexit.get(actionLink, { headers });
      if (response && response.data && response.data.statusCode === 200) {
        dispatch({
          type: GET_SUMMARY_BENEFICIARIES,
          payload: response.data
        });
      } else {
        dispatch({
          type: FAILURE,
          payload: response.data.errorMessage
        });
      }
    } catch (e) {
      const message = e.message;
      dispatch({ type: FAILURE, payload: { message } });
    }
  };

export const summarySaveESignature =
  (isAgreed, wordTyped, beneficiaryall, success, failure) =>
  async (dispatch, getState) => {
    try {
      const response = await flexit.put("Summary/SaveESignature", {
        isAgreed,
        wordTyped,
        beneficiaryall
      });
      console.log(response);
      dispatch({
        type: SAVE_ESIGNATURE,
        payload: response.data
      });
      if (success) success();
    } catch (e) {
      if (failure) failure(e.response.data.errorMessage);
      dispatch({ type: FAILURE, payload: e.message });
    }
  };
/* E-Signature End */

export const getSummaryDependents = () => async (dispatch, getState) => {
  const locale = getState().contentReducer.locale;
  const headers = { Lang: `${locale}-CA` };
  try {
    const response = await flexit.get(`Summary/Dependents`, { headers });
    if (response && response.data && response.data.statusCode === 200) {
      dispatch({
        type: GET_SUMARY_DEPENDENTS,
        payload: response.data
      });
    } else {
      dispatch({
        type: FAILURE,
        payload: response.data.errorMessage
      });
    }
  } catch (e) {
    const message = e.message;
    dispatch({ type: FAILURE, payload: { message } });
  }
};

export const confirmEnrollment =
  (callback, failure) => async (dispatch, getState) => {
    const locale = getState().contentReducer.locale;
    const headers = { Lang: `${locale}-CA` };
    try {
      const response = await flexit.post(`/EnrollmentFooter`, { headers });
      if (
        response &&
        response.data &&
        response.data.enrollmentReturnCode === -1
      ) {
        dispatch({ type: FAILURE, payload: response.data.errorMessage });
        if (failure)
          failure(
            response.data.enrollmentReturnCode === -1
              ? response.data.errorMessage
              : ""
          );
      }
      if (
        response &&
        response.data &&
        response.data.enrollmentReturnCode === 0
      ) {
        dispatch({
          type: CONFIRM_ENROLLMENT,
          payload: response.data
        });
        if (callback) callback();
        localStorage.setItem("logOffCheck", true);
        dispatch(setHeaderNavURL(true));
      } else {
        dispatch({ type: FAILURE, payload: response.data.errorMessage });
        if (failure)
          failure(
            response.data.enrollmentReturnCode === -1
              ? response.data.errorMessage
              : ""
          );
      }
    } catch (e) {
      const message = e.message;
      dispatch({ type: FAILURE, payload: { message } });
    }
  };

export const getBeneficiariesAll = () => async (dispatch, getState) => {
  const locale = getState().contentReducer.locale;
  const headers = { Lang: `${locale}-CA` };
  try {
    const response = await flexit.get(`/Beneficiary/All`, { headers });
    if (response && response.data) {
      dispatch({
        type: GET_BENEFICIARIES_ALL,
        payload: response.data
      });
    } else {
      dispatch({
        type: FAILURE,
        payload: response.data.errorMessage
      });
    }
  } catch (e) {
    const message = e.message;
    dispatch({ type: FAILURE, payload: { message } });
  }
};

export const setBasePageTitle = (pageName) => (dispatch, getState) => {
  const screens = getState().contentReducer.menu2.mainMenu;
  let screenTitle = "";
  let stepDescription = "";

  const keys = _.keys(screens);
  dispatch({
    type: SET_BASE_PAGE_ITEM,
    payload: pageName
  });

  _.each(keys, (item) => {
    let subMenus = screens[item].subMenus;
    if (Object.keys(subMenus).length !== 0 && subMenus.constructor === Object) {
      if (screens[item].subMenus[pageName.name] !== undefined) {
        screenTitle = screens[item].display;
        stepDescription = screens[item].stepDescription;
        return;
      }
    } else if (item === pageName.name) {
      screenTitle = screens[item].display;
      stepDescription = screens[item].stepDescription;
      return;
    }
  });

  dispatch({
    type: SET_BASE_PAGE_TITLE,
    payload: screenTitle
  });
  dispatch({
    type: SET_STEP_DESCRIPTION,
    payload: stepDescription
  });
};

export const setHeaderNavURL =
  (isNavAllowed = true) =>
  (dispatch) => {
    dispatch({
      type: SET_NAV_URL,
      payload: isNavAllowed ? "/" : ""
    });
  };

export const validateTFACode =
  (options, failure, history, success) => async (dispatch, getState) => {
    const locale = getState().contentReducer.locale;
    const headers = { Lang: `${locale}-CA` };
    try {
      const response = await flexit.post("/Account/TwoFAValidate", options, {
        headers
      });

      if (response && response.data.statusCode === 401) {
        USER_TOKEN.remove();
        history.push("/logoff");
      } else if (response.data.statusCode !== 201) {
        dispatch({ type: FAILURE, payload: response.data.errorMessage });
        if (failure) failure(response.data.errorMessage);
      } else if (response.data.statusCode === 422) {
        dispatch({ type: FAILURE, payload: response.data.errorMessage });
        if (failure) failure(response.data.errorMessage);
      } else {
        success();
        USER_TOKEN.set(response.data);
        sessionStorage.setItem(NEED_TFA_REGISTRATION, false);
        sessionStorage.setItem(NEED_TFA_VALIDATION, false);
        dispatch({
          type: SIGN_IN,
          payload: response.data
        });
        dispatch({
          type: SET_PAGE_SETTING,
          payload: welcome
        });
        localStorage.setItem("logOffCheck", true);
        dispatch(setHeaderNavURL(true));
        const timeout = response.data.lifeSpan * 1000 - 60 * 1000; // you can configure as you want but here it is 1 min before token will get expired
        sessionStorage.setItem("timeout", timeout);
        localStorage.removeItem("sessionTimeOut");
        intervalID = setInterval(
          // this would reset localStorage before token expiry timr
          refreshToken(history),
          timeout
        );
        const cdt = new Date();
        const tfaDate = {
          day: cdt.getDate(),
          month: cdt.getMonth() + 1,
          year: cdt.getFullYear()
        };
        localStorage.setItem("tfaDate", JSON.stringify(tfaDate));
        // history.push("/");
      }
    } catch (e) {
      console.log("exception occured ", e);
      dispatch({ type: FAILURE, payload: "Error occured" });
    }
  };

export const setMultiBeneficiariesList =
  (benefitList, callback) => async (dispatch, getState) => {
    try {
      await dispatch({
        type: SET_MULTI_BENEFICIARIES_LIST,
        payload: benefitList ? benefitList : {}
      });
      if (callback) {
        callback();
      }
    } catch (e) {
      const message = e.message;
      dispatch({
        type: FAILURE,
        payload: { message }
      });
    }
  };

export const setBaseBeneficiaryPageTitle =
  (pageTitle) => (dispatch, getState) => {
    dispatch({
      type: SET_BASE_PAGE_TITLE,
      payload: pageTitle
    });
  };

export const setFocusOnBenefitError = (payload) => (dispatch, getState) => {
  dispatch({
    type: SET_FOCUS_ON_BENEFIT_ERROR,
    payload: payload
  });
};

export const idleTimer = (history) => {
  clearTimeout(timer);
  resetTimer(history);
};

const resetTimer = (history) => {
  const timeout = parseInt(sessionStorage.getItem("timeout"));
  const timeOutInterval = timeout - 60 * 1000;
  /* console.log("timeout: ", timeout);
  console.log("timeOutInterval: ", timeOutInterval); */
  function logout() {
    localStorage.setItem("sessionTimeOut", 1);
    if (parseInt(localStorage.getItem("sessionTimeOut")) === 1) {
      console.log("Session timeout!!");
      setTimeout(() => {
        clearTimeout(timer);
        clearInterval(intervalID);
        history.replace("/logoff");
      }, 100);
    }
  }

  timer = setTimeout(logout, timeOutInterval); // time is in milliseconds
};
