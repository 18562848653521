import React, { Component, Fragment } from "react";

import {
  getWelcomeContent,
  getMenu,
  setClientName,
  dissmiss,
  setFocusWelcomeFlag,
  getSmokerContent
} from "../actions/contentActions";
import defaultWelcomeBanner from "../assets/jpeg/welcome-banner.jpg";
import {
  navigatesTo,
  navigatesToLifeChangeEvent,
  putWelcome,
  cancelClick,
  getSSOtoken,
  getContentFooter,
  modifyEnrollment,
  setPageSetting,
  setHeaderNavURL,
  languageChange
} from "../actions/userActions";
import { getDisplayText, setModalAttribute, skipMainContent } from "../utils";
import SideMenu from "./SideMenu";
import _ from "underscore";

import { Container, Row } from "react-bootstrap";
import { Modal, ModalBody } from "reactstrap";
import { withRouter, Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import DisplayButton from "./button";
import { USER_TOKEN } from "../helpers/storage";

class Welcome extends Component {
  constructor(props) {
    super(props);
    const needSSO = props.location.search ? true : false;
    this.state = {
      user: { firstname: "", lastname: "" },
      loading: false,
      isSignedIn: (needSSO && this.checkSSO(props)) || props.isSignedIn,
      showModal: false,
      isModifyEnrollment: false,
      isTruncated: true,
      combinedHeight: 0
    };
  }

  toggleTruncate = () => {
    this.setState((state) => ({ isTruncated: !state.isTruncated }));
  };

  checkSSO(props) {
    console.log("checkSSOR");

    const clientName = props.match.params.client;
    let didSetClientName = false;

    console.log("CLIENT NAME RECEIVED IN SSO-->", clientName);

    if (props.client !== clientName) {
      this.props
        .setClientName(
          clientName,
          (data) => {
            //Handle Success
            didSetClientName = true;
          },
          (data) => {
            // Handle Failure
            didSetClientName = false;
          }
        )
        .then(() => {
          if (!didSetClientName) props.history.replace("/403");
        });
    }

    const query =
      props.location.search && props.location.search.indexOf("u") > -1
        ? props.location.search.replace("?", "").split("&")
        : [];

    const params = _.object(
      _.map(query, (item) => {
        const temp = item.split("=");
        const temp_0 = temp[0] === "Lang" ? temp[0].toLowerCase() : temp[0];
        return [temp_0, temp[1]];
        //return [temp[0], temp[1]];
      })
    );
    this.props.getSSOtoken(params, this.props.history);
    props.history.replace(`/${clientName}/SSO`);
    // props.history.replace(`/`)
    //setTimeout(() => {
    //    props.history.replace(`/`)
    //}, 2000);
    return true; //TODO
  }

  componentDidMount() {
    const headerHeight = document.querySelector("#mainHeader") && document.querySelector("#mainHeader").clientHeight;
    const footerHeight = document.querySelector("#mainFooter") && document.querySelector("#mainFooter").clientHeight;
    const screenHeight = window.innerHeight;
    if (this.state.combinedHeight === 0) {
      this.setState({
        combinedHeight: screenHeight - (headerHeight + footerHeight + 0),
        loading: true
      });
    }
    window.addEventListener("resize", this.resize.bind(this));
    if (this.props.isWelcomeFocus) {
      skipMainContent();
    }
    this.props.setFocusWelcomeFlag(true);

    if (
      this.props.pageSetting === "undefined" ||
      (this.props.pageSetting && this.props.pageSetting.name !== "WELCOME")
    ) {
      this.props.setPageSetting("WELCOME");
    }

    const { client, languageSwitchRequired } = USER_TOKEN.get();
    const { search, pathname } = window.location;
    const pathName = search ? pathname.toLowerCase() + window.location.search : pathname.toLowerCase();
    languageSwitchRequired && trackPromise(this.props.languageChange("fr", client.toLowerCase(), pathName));
  }

  resize() {
    this.setState({
      combinedHeight:
        window.innerHeight -
        (document.querySelector("#mainHeader").clientHeight +
          document.querySelector("#mainFooter").clientHeight +
          (document.querySelector("#bannerID") ? document.querySelector("#bannerID").clientHeight : 0) +
          0)
    });
  }

  UNSAFE_componentWillMount = () => {
    if (this.props.isSignedIn) {
      console.log("props isSignedIn: ", this.props.isSignedIn);
      trackPromise(this.props.getWelcomeContent());
      trackPromise(this.props.getSmokerContent());
      trackPromise(this.props.getMenu());
    }
  };

  componentWillUnmount = () => {
    this.props.dissmiss();
  };
  onButtonClick = (e) => {
    const { name } = e.target;
    const conf = this.props.configs[name];
    if (conf) {
      trackPromise(this.props.putWelcome(this.props.history, conf.btnLabel, null, this.props.configs[name].btnLabel));
    }
  };

  onCancelEnrollment = (e) => {
    const { name } = e.target;
    const conf = this.props.configs[name];
    const clientName = this.props.match.params.client;
    const isModifyEnrollment = name === "showModifyEnrollment" ? true : false;
    const historyProp = this.props.location.pathname === `/${clientName}/SSO` ? null : this.props.history;
    if (conf) {
      trackPromise(
        this.props.putWelcome(
          historyProp,
          conf.btnLabel,
          () => {
            this.setState({ showModal: true, isModifyEnrollment });
          },
          () => <Redirect to="/" />
        )
      );
    }
    e.currentTarget.blur();
  };

  onLifeChangeEventClick = (e) => {
    const { name } = e.target;
    const conf = this.props.configs[name];
    if (conf) {
      trackPromise(this.props.navigatesToLifeChangeEvent(this.props.history));
      this.props.setHeaderNavURL(false);
    }
  };

  onCoverageClick = (e) => {
    localStorage.removeItem("parameters");
    e.currentTarget.blur();
  };

  handleClose = (hideButtons = false) => {
    if (localStorage.getItem("hideButtons") === "true") {
      if (!hideButtons) localStorage.removeItem("hideButtons");
    }
    this.setState({
      showModal: false,
      isModifyEnrollment: false
    });
  };

  handleCancel = () => {
    const isModifyEnrollment = this.state.isModifyEnrollment;
    const selectedLink = "lnkStartAnnualEnrollment";
    if (isModifyEnrollment) {
      trackPromise(
        this.props.putWelcome(
          this.props.history,
          selectedLink,
          () => {
            this.handleClose(true);
          },
          selectedLink
        )
      );
    } else {
      trackPromise(
        this.props.cancelClick(this.props.history, () => {
          this.props.getWelcomeContent();
          this.props.getMenu();
          this.handleClose();
        })
      );
    }
  };

  /* clearCacheData = () => {
    if ("caches" in window) {
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach((name) => {
          console.log("name: ", name);
          caches.delete(name);
        });
      });
      // window.location.reload(true);
    }
  }; */

  render = () => {
    if (this.state.isSignedIn) {
      // return <Redirect to='/' />
    }
    if (!this.state.isSignedIn) {
      return <Redirect to={`/${this.props.match.params.client}/login`} />;
    }
    if (this.props.passwordChangeRequired === true) {
      return <Redirect to="/changepassword" />;
    }
    const contents = this.props.content;
    const footerContent = this.props.contentFooter;
    const lblEnrollmentWindow = getDisplayText(contents, "lblEnrollmentWindow");
    const lnkReviewEnrollment = getDisplayText(contents, "lnkReviewEnrollment");
    const lnkStartLifeEvent = getDisplayText(contents, "lnkStartLifeEvent");
    const cancelEnrollmentLabel = getDisplayText(contents, "lnkCancelEnrollment");
    const modifyEnrollmentLabel = getDisplayText(contents, "lnkModifyEnrollment");
    const lblConfirmModifyDialogTitle = getDisplayText(contents, "lblConfirmModifyDialogTitle");
    const continueEnrollmentLabel = getDisplayText(contents, "lnkContinueEnrollment");
    const startAnnualEnrollmentLabel = getDisplayText(contents, "lnkStartAnnualEnrollment");
    const startNewHireEnrollmentLabel = getDisplayText(contents, "lnkStartNewHireEnrollment");
    const completeEnrollmentAlertLabel = getDisplayText(contents, "lblCompleteEnrollmentAlert");
    const enrollmentInstructionsLabel = getDisplayText(contents, "lblEnrollmentInstructions");
    const yesButtonLabel = getDisplayText(footerContent, "lblYes");
    const noButtonLabel = getDisplayText(footerContent, "lblNo");
    const lblPageTitle = getDisplayText(contents, "lblPageTitle");
    const lblHomeBanner = getDisplayText(contents, "lblHomeBanner");
    const homeBanner = getDisplayText(contents, "homeBanner");
    const lblEnrollmentWaiting = getDisplayText(contents, "lblEnrollmentWaiting");
    const lblEnrollmentClosed = getDisplayText(contents, "lblEnrollmentClosed");
    const lblEnrollmentComplete = getDisplayText(contents, "lblEnrollmentComplete");
    const lblPartialEnrollmentAlert = getDisplayText(contents, "lblPartialEnrollmentAlert");
    const lnkMore = getDisplayText(contents, "lnkMore");
    const lnkLess = getDisplayText(contents, "lnkLess");
    const closeButtonText = getDisplayText(contents, "btnClose");
    const lblLanguageSwitchMessageHeader = getDisplayText(contents, "lblLanguageSwitchMessageHeader");
    const lblLanguageSwitchMessage = getDisplayText(contents, "lblLanguageSwitchMessage");

    const lblNewTab = getDisplayText(footerContent, "lblNewTab");
    const homePageText = this.props.configs.homePageText ? this.props.configs.homePageText : "";
    const truncatedHomePageText = homePageText ? homePageText.substring(0, 200) : "";
    const reviewCoverageLnk = this.props.configs.isReviewFuture
      ? "/ReviewCoverage/CurrentCoverage?period=Future"
      : "/ReviewCoverage/CurrentCoverage";
    const welcomeBanneralttext = getDisplayText(this.props.language, "welcomeBannerAltText");
    document.title = lblPageTitle.display;
    const { languageSwitchRequired } = USER_TOKEN.get();

    return this.state.isSignedIn ? (
      <div role="main">
        {languageSwitchRequired === "true" && (
          <div className={`alert alert-primary mb-1`}>
            <span className={`bold-text`} aria-level={lblLanguageSwitchMessageHeader.textToRead}>
              {lblLanguageSwitchMessageHeader.display}
            </span>
            <div
              className="pt-1"
              aria-level={lblLanguageSwitchMessage.textToRead}
              dangerouslySetInnerHTML={{ __html: lblLanguageSwitchMessage.display }}
            />
          </div>
        )}
        {this.props.headerImages.welcomeBanner && (
          <div className="banner-holder">
            <div className="banner-align">
              <img
                className="welcome-banner"
                onLoad={(obj) => {
                  console.log("OnLoad Called");
                  this.setState({
                    combinedHeight: this.state.combinedHeight - document.querySelector("#bannerID").clientHeight
                  });
                }}
                id="bannerID"
                ref="homeBanner"
                src={this.props.headerImages.welcomeBanner}
                alt={welcomeBanneralttext.textToRead}
              />
            </div>
          </div>
        )}

        <Container className="welcome-page-container">
          <Row className="welcome-page-holder">
            <div className="col-lg-3 side-nav-holder-desktop">
              <SideMenu content={this.props.sideBarMenu} isOpen={this.props.isOpen} />
            </div>

            <div
              className="col-md-12 col-lg-9 welcome-content-holder"
              style={{ minHeight: this.state.combinedHeight + "px" }}
            >
              {this.state.loading && lblHomeBanner.display != null && lblHomeBanner.display.trim() && (
                <h1 className="caption" id="headingWelcome">
                  {lblHomeBanner.display}
                </h1>
              )}
              {homeBanner.textToRead && (
                <Fragment>
                  <p id="mainWelcome" dangerouslySetInnerHTML={{ __html: homeBanner.display }} />
                </Fragment>
              )}
              {this.props.configs.showEnrollmentWaiting && (
                <p className="bold-text">
                  <span>{lblEnrollmentWaiting.display}</span>
                </p>
              )}
              {this.props.configs.showEnrollmentClosed && (
                <p className="bold-text">
                  <span>{lblEnrollmentClosed.display}</span>
                </p>
              )}
              {this.props.configs.showEnrollmentComplete && (
                <p className="bold-text">
                  <span>{lblEnrollmentComplete.display}</span>
                </p>
              )}
              {this.props.configs.showEnrollmentWindow && (
                <p className="bold-text">
                  <span>{lblEnrollmentWindow.display}</span>
                </p>
              )}
              {this.props.configs.showEnrollmentInstructions && (
                <p>
                  <Fragment>
                    <span dangerouslySetInnerHTML={{ __html: enrollmentInstructionsLabel.display }} />
                  </Fragment>
                </p>
              )}
              {this.props.configs.showHomePageText && (
                <div className="mb-3 mb-lg-4">
                  {this.state.isTruncated ? (
                    <Fragment>
                      <span className="pr-2" dangerouslySetInnerHTML={{ __html: truncatedHomePageText }} />
                      <span
                        tabIndex="0"
                        role="button"
                        aria-label={lnkMore.textToRead}
                        onClick={this.toggleTruncate}
                        className="btn-link inline-block"
                      >
                        {lnkMore.display}
                      </span>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <span aria-disabled="true" dangerouslySetInnerHTML={{ __html: homePageText }} />
                      <span
                        tabIndex="0"
                        role="button"
                        aria-label={lnkLess.textToRead}
                        onClick={this.toggleTruncate}
                        className="btn-link inline-block"
                      >
                        {" "}
                        {lnkLess.display}
                      </span>
                    </Fragment>
                  )}
                </div>
              )}
              {this.props.configs.showPartialEnrollmentAlert && (
                <p className="alert alert-info" role="alert">
                  <span>{lblPartialEnrollmentAlert.display}</span>
                </p>
              )}
              {this.props.configs.showCompleteEnrollmentAlert && (
                <p className="alert alert-info" role="alert">
                  <span>{completeEnrollmentAlertLabel.display}</span>
                </p>
              )}
              <Row className="welcome-btns">
                {this.props.configs.showStartNewHireEnrollment && (
                  <Row className="col-xs-12 col-md-12 col-lg-12 welcome-btn">
                    <DisplayButton
                      name="showStartNewHireEnrollment"
                      className="secondary col-xs-12 col-md-6"
                      displayName={startNewHireEnrollmentLabel.display}
                      onClick={this.onButtonClick}
                    />
                    <div className="space-height-20" />
                  </Row>
                )}
                {this.props.configs.showStartAnnualEnrollment && (
                  <Row className="col-xs-12 col-md-12 col-lg-12  welcome-btn">
                    <DisplayButton
                      name="showStartAnnualEnrollment"
                      className="secondary col-xs-12 col-md-6"
                      onClick={this.onButtonClick}
                      displayName={startAnnualEnrollmentLabel.display}
                    />
                    <div className="space-height-20" />
                  </Row>
                )}
                {this.props.configs.showContinueEnrollment && (
                  <Row className="col-xs-12 col-md-12 col-lg-12 welcome-btn">
                    <DisplayButton
                      name="showContinueEnrollment"
                      className="secondary col-xs-12 col-md-6"
                      displayName={continueEnrollmentLabel.display}
                      onClick={this.onButtonClick}
                    />
                    <div className="space-height-20" />
                  </Row>
                )}
                {this.props.configs.showModifyEnrollment && (
                  <Row className="col-xs-12 col-md-12 col-lg-12 welcome-btn">
                    <DisplayButton
                      name="showModifyEnrollment"
                      className="secondary col-xs-12 col-md-6"
                      displayName={modifyEnrollmentLabel.display}
                      onClick={this.onCancelEnrollment}
                    />
                    <div className="space-height-20" />
                  </Row>
                )}
                {this.props.configs.showReviewEnrollment && (
                  <Row className="col-xs-12 col-md-12 col-lg-12  welcome-btn">
                    <Link
                      style={{ outline: "none", boxShadow: "none" }}
                      //to='/ReviewCoverage/CurrentCoverage'
                      to={reviewCoverageLnk}
                      target="__blank"
                      name="showReviewEnrollment"
                      aria-label={lnkReviewEnrollment.textToRead + " " + lblNewTab.textToRead}
                      onClick={this.onCoverageClick}
                      className="btn btn-secondary col-xs-12 col-md-6"
                    >
                      {lnkReviewEnrollment.display}
                    </Link>
                    <div className="space-height-20" />
                  </Row>
                )}
                {this.props.configs.showCancelEnrollment && (
                  <Row key="showCancelEnrollment" className="col-xs-12 col-md-12 col-lg-12 welcome-btn">
                    <DisplayButton
                      name="showCancelEnrollment"
                      className="secondary col-xs-12 col-md-6"
                      displayName={cancelEnrollmentLabel.display}
                      onClick={this.onCancelEnrollment}
                    />
                    <div className="space-height-20" />
                  </Row>
                )}
                {this.props.configs.showLifeEvent && (
                  <Row className="col-xs-12 col-md-12 col-lg-12  welcome-btn">
                    <DisplayButton
                      name="showLifeEvent"
                      className="secondary col-xs-12 col-md-6"
                      onClick={this.onLifeChangeEventClick}
                      displayName={lnkStartLifeEvent.display}
                    />
                    <div className="space-height-20" />
                  </Row>
                )}
              </Row>
              {/* <button onClick={() => this.clearCacheData()}>Delete Specific Cache</button> */}
            </div>
          </Row>
          <Modal
            onOpened={() =>
              setModalAttribute(
                this.state.isModifyEnrollment
                  ? lblConfirmModifyDialogTitle.textToRead
                  : cancelEnrollmentLabel.textToRead
              )
            }
            aria-labelledby="modalHeading"
            className="ml-cancelenrollment"
            isOpen={this.state.showModal}
            toggle={this.handleClose}
            centered
          >
            <div className="modal-header">
              <h1 className="modal-title" id="modalHeading">
                {this.state.isModifyEnrollment ? lblConfirmModifyDialogTitle.display : cancelEnrollmentLabel.textToRead}
              </h1>
              <DisplayButton
                type="button"
                className="close"
                displayName="×"
                arialabel={closeButtonText.display}
                onClick={this.handleClose}
              />
            </div>
            <ModalBody>
              <div className="ml-popupmessage">
                <p
                  className="ml-popupmessagetext"
                  dangerouslySetInnerHTML={{ __html: this.props.putWelcomeResponse.alert }}
                ></p>
              </div>
              <div className={"ml-buttons d-flex justify-content-between"}>
                <DisplayButton
                  className="outline-secondary"
                  displayName={noButtonLabel.display}
                  onClick={this.handleClose}
                />
                <DisplayButton
                  controlId="cancelBtn"
                  className="secondary"
                  displayName={yesButtonLabel.display}
                  onClick={this.handleCancel}
                />
              </div>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    ) : null;
  };
}

const mapStateToProps = (state) => {
  return {
    content: state.contentReducer.welcomeContent.contentResource
      ? state.contentReducer.welcomeContent.contentResource.contents
      : [],
    configs: state.contentReducer.welcomeContent.result ? state.contentReducer.welcomeContent.result : [],
    locale: state.contentReducer.locale,
    passwordChangeRequired: state.userReducer.authResponse.passwordChangeRequired || false,
    sideBarMenu: state.contentReducer.menu2.mainMenu,
    isSignedIn: state.userReducer.isSignedIn || false,
    headerImages: state.contentReducer.images ? state.contentReducer.images : { welcomeBanner: defaultWelcomeBanner },
    user: {
      firstname: state.userReducer.firstName,
      lastname: state.userReducer.lastName
    },
    isOpen: state.userReducer.menu.isOpen,
    putWelcomeResponse: state.userReducer.putWelcomeResponse,
    contentFooter: state.userReducer.contentFooter.contentResource
      ? state.userReducer.contentFooter.contentResource.contents
      : {},
    pageSetting: state.userReducer.pageSetting,
    language: state.contentReducer.language,
    isWelcomeFocus: state.contentReducer.isWelcomeFocus
  };
};

export default connect(mapStateToProps, {
  getWelcomeContent,
  getMenu,
  navigatesTo,
  putWelcome,
  navigatesToLifeChangeEvent,
  cancelClick,
  getSSOtoken,
  getContentFooter,
  modifyEnrollment,
  setClientName,
  dissmiss,
  setPageSetting,
  setHeaderNavURL,
  setFocusWelcomeFlag,
  getSmokerContent,
  languageChange
})(withRouter(Welcome));
