import { 
  LOGOFF,
  SIGN_IN,
  GET_ADD,
  GET_ADDWC,
  GET_HEALTH,
  GET_PROFILE,
  CHANGE_EVENT,
  GET_WELLNESS,
  UPDATE_WELLNESS,
  GET_SHOW_FLEX,
  GET_DISABILITY,
  RESET_PASSWORD,
  TOGGLE_SIDEBAR,
  GET_DEPENDENTS, 
  GET_BASIC_LIFE,
  GET_BENEFIT_IDS,
  CREATE_DEPENDENT,
  DELETE_DEPENDENT, 
  SET_PAGE_SETTING,
  UPDATE_BASIC_LIFE,
  SET_BENEFIT_OPTION,
  GET_ENROLMENT_HEADER,
  // GET_PENSION_CONTRIBUTION,
  GET_BENEFICIARIES,
  CREATE_BENEFICIARY,
  DELETE_BENEFICIARY,
  UPDATE_BENEFICIARY,
  GET_ENROLMENT_FOOTER,
  CHANGE_OPTION,
  PUT_WELCOME,
  PUT_WELCOME_MESSAGES,
  PUT_WELCOME_REQUESTED_OBJECT,
  CHANGE_PASSWORD,
  PASSWORD_CHANGED,
  REMOVE_EOI,
  SAVE_WAIVER_FORM,
  PUT_DEPENDED_COVERAGE,
  SAVE_DEPENDENT_WAIVER_FORM,
  DISABLE_NEXT,
  GET_BENEFIT_SUMMARY,
  GET_CONTENT_FOOTER,
  GET_CONTENT_FOOTER_PAGE,
  UPDATE_INDIVIDUAL_BENEFICIARY,
  GET_PROGRESS_INDICATOR,
  FILE_UPLOAD_INFO,
  UPLOADED_FILE,
  UPLOADED_FILES_INFO,
  UPLOAD_FILE,
  PROGRESS_PERCENTAGE,
  REMOVE_WAIVER,
  GET_CONTENT_HELP,
  GET_FLEX,
  UPDATE_FLEX,
  RELOCATE_FLEX,
  FINISH_ENROLLMENT,
  REVIEW_CURRENT_COVERAGE,
  REVIEW_PAST_COVERAGE,
  REVIEW_PAST_COVERAGE_LIST,
  PDF_STATEMENT,
  SAVE_FLEX,
  GET_SUMMARY_BENEFITS,
  GET_SUMMARY_FLEX,
  GET_SUMMARY_BENEFICIARIES,
  GET_SUMARY_DEPENDENTS,
  GET_SUMMARY_PERSONAL,
  CONFIRM_ENROLLMENT,
  GET_BENEFICIARIES_ALL,
  SET_BASE_PAGE_TITLE,
  GET_CONTENT_HELP_MENU,
  NEXT_ENROLLMENT_STEP,
  SET_NAV_URL,
  SET_MENU2_FLAG,
  SET_BASE_PAGE_ITEM,
  SET_NAV_ERROR,
  FINISH_ENROLLMENT_FORM,
  ENROLLMENT_DEVICE_TYPE,
  BENEFIT_URL_LIST,
  GET_MULTI_BENEFIT_DATA,
  SET_STEP_DESCRIPTION,
  SET_FLEX_BENEFICIARY_ID,
  SET_MULTI_BENEFICIARIES_LIST,
  SET_FOCUS_ON_BENEFIT_ERROR,
  SAVE_ESIGNATURE,
  REMOVE_BENEFICIARY,
  REALLOCATE_MULTIBENEFIT,
  PROCEED_TO_NEXT
} from "../actions/types.js";

import Defaults from "../helpers/defaults";
import _ from 'underscore';
import { USER_TOKEN } from "../helpers/storage.js";
import {isMobileOnly} from "react-device-detect";

  const initialState = {
    authResponse: Defaults.authResponse,
    resetPasswordResponse: Defaults.resetPasswordResponse,
    user: {csrf: 'CSRF98765tyuio098765'},
    isSignedIn: USER_TOKEN.get().isSignedIn,
    needTwoFARegistration: USER_TOKEN.get().needTwoFARegistration,
    needTwoFAValidation: USER_TOKEN.get().needTwoFAValidation,
    firstName: USER_TOKEN.get().firstName,
    lastName: USER_TOKEN.get().lastName,
    personalInfo: USER_TOKEN.get().personalInfo ? USER_TOKEN.get().personalInfo : Defaults.personalInfo,
    dependents: Defaults.dependent,
    basicLife: Defaults.basicLife,
    wellness: Defaults.wellness,
    pension: Defaults.pension,
    changeEvent: Defaults.changeEvent,
    benefitIds: Defaults.benefitIds,
    menu: {isOpen: false},
    enrolmentHeader: {}, 
    beneficiaries: {}, //Defaults.beneficiaries,
    putWelcomeResponse: { navigateTo:'', contentResource: {} },
    putWelcomeRequestObject: {},
    benefit: USER_TOKEN.get().pageSetting ? USER_TOKEN.get().pageSetting.benefit : Defaults.health,
    pageSetting: USER_TOKEN.get().pageSetting ? USER_TOKEN.get().pageSetting.screen : {name: 'welcome', link: '', display: "", order: 0},
    enrolmentFooter: Defaults.enrolmentfooter,
    disableNext: false,
    contentFooter: Defaults.contentFooter,
    contentFooterPage: {},
    progressBar: {},
    fileUploadInfoData: Defaults.fileUploadInfoData,
    uploadedFileData: {},
    uploadedFilesInfoData: {},
    uploadFileData: {},
    fileUploadProgress: 0,
    flexDollars: {},
    finishEnrollment: Defaults.finishEnrollmentResponse,
    currentCoverage: Defaults.currentCoverage,
    coverageList: Defaults.coverageList,
    enrollmentSummaryBenefits: Defaults.enrollmentSummaryBenefits,
    enrollmentSummaryFlex: Defaults.enrollmentSummaryFlex,
    pastCoverage: Defaults.previousCoverageList,
    nextEnrolStep: {},
    headerNavURL: localStorage.getItem('logOffCheck') !== null ? localStorage.getItem('logOffCheck') ? "/" : "" : "/",
    deviceType: 
      localStorage.getItem('deviceType') !== null 
        ? localStorage.getItem('deviceType') 
        : isMobileOnly 
          ? "Mobile"
          : "Desktop",
    benefitUrlList: USER_TOKEN.get().benefitNavLinks ? USER_TOKEN.get().benefitNavLinks : [],
    benefitsList: USER_TOKEN.get().multiBenefitList ? USER_TOKEN.get().multiBenefitList : [],
    flexBeneficiaryID: localStorage.getItem('flexBenId') !== null ? parseInt(localStorage.getItem('flexBenId')) : -1,
    packageMultiBeneficiaries: localStorage.getItem('multiBenList') !== null ? JSON.parse(localStorage.getItem('multiBenList')) : {},
    benefitErrorOnNext: false,
    removeBeneficiaryResponse:Defaults.removeBeneficiaryResponse,
    beneficiariesMultiBenefit:Defaults.reallocatemultiBenefit,
    multiBenefitErrorMessage:Defaults.multiBenefitErrorMessage
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case SIGN_IN: {
        return {
          ...state,
          authResponse: action.payload,
          firstName: USER_TOKEN.get().firstName,
          lastName: USER_TOKEN.get().lastName,
          isSignedIn: USER_TOKEN.get().isSignedIn ? true : false,
          needTwoFARegistration: USER_TOKEN.get().needTwoFARegistration,
          needTwoFAValidation: USER_TOKEN.get().needTwoFAValidation,
          twoFATypeID: USER_TOKEN.get().twoFATypeID,
          errorMessage: action.payload.errorMessage
        };
      }
      case RESET_PASSWORD: {
        return {
          ...state,
          resetPasswordResponse: action.payload,
          isSignedIn: false,
          errorMessage: null
        };
      }
      case CHANGE_PASSWORD: {
        return {
          ...state,
          resetPasswordResponse: action.payload,
          errorMessage: null
        }
      }
      case PASSWORD_CHANGED: {
        return {
          ...state,
          authResponse: {...state.authResponse, passwordChangeRequired: action.payload}
        }
      }
      case LOGOFF: {
        return {
          ...state,
          errorMessage: null,
          isSignedIn: false,
          authResponse: {firstName: '', lastName:''},
          message: {}, messageType: null,
          redirectURL: action.payload
        }
      }
      case GET_PROFILE: {
        USER_TOKEN.setPersonalInfo(action.payload)
        return {
          ...state,
          personalInfo: action.payload,
        };
      }
      case GET_DEPENDENTS: {
        return {
          ...state,
          dependents: action.payload,
        };
      }
      case DELETE_DEPENDENT: {
        const newDependentsList = _.reject(state.dependents.result.Dependents, (dep)=>dep.dependentID === action.payload)
        return {
          ...state,
          dependents: {...state.dependents, result:{Dependents:newDependentsList}}
        };
      }
      case CREATE_DEPENDENT: {
        state.dependents.result.Dependents.push(action.payload)
        return {
          ...state,
          dependents: {...state.dependents, result:{Dependents:state.dependents.result.Dependents}},
        };
      }

      case GET_CONTENT_FOOTER: {
        return{
          ...state,
          contentFooter: action.payload
        }
      }

      case GET_CONTENT_FOOTER_PAGE: {
        return{
          ...state,
          contentFooterPage: action.payload
        }
      }

      case GET_BENEFICIARIES: {
        return {
          ...state,
          beneficiaries: action.payload,
        };
      }

      case FILE_UPLOAD_INFO: {
        return {
          ...state,
          fileUploadInfoData: action.payload
        };
      }

      case UPLOADED_FILE: {
        console.log("UPLOADED_FILE",action.payload);
        return {
          ...state,
          uploadedFileData: action.payload
        };
      }

      case UPLOADED_FILES_INFO: {
        return {
          ...state,
          uploadedFilesInfoData: action.payload
        };
      }

      case UPLOAD_FILE: {
        return {
          ...state,
          uploadFileData: action.payload
        };
      }

      case PROGRESS_PERCENTAGE: {
        return {
          ...state,
          fileUploadProgress: action.payload
        }
      }

      case FINISH_ENROLLMENT: {
        return {
          ...state,
          finishEnrollment: action.payload
        }
      }

      // case DELETE_BENEFICIARY: {
      //   const newDependentsList = _.reject(state.beneficiaries.result.Beneficiaries, (ben)=>ben.beneficiaryID === action.payload)
      //   return {
      //     ...state,
      //     beneficiaries: {...state.beneficiaries, result:{Beneficiaries:newBeneficiariesList}},
      //   };
      // }
      case CREATE_BENEFICIARY: {
        state.beneficiaries.beneficiaries.push(action.payload)
        return {
          ...state,
          beneficiaries: {...state.beneficiaries, result:{Beneficiaries:state.beneficiaries.beneficiaries}},
        };
      }


      case DELETE_BENEFICIARY: {
        const newBeneficiariesList = _.reject(state.beneficiaries.beneficiaries, (ben)=>ben.beneficiaryId === action.payload)
        return {
          ...state,
          beneficiaries: {...state.beneficiaries, beneficiaries:newBeneficiariesList},
        };
      }
      case UPDATE_BENEFICIARY: {

        let benOnject  = state.beneficiaries;
        benOnject['beneficiaries'] = action.payload.beneficiary;
        
        return {
          ...state,
          beneficiaries: benOnject
        };
      }
      case UPDATE_INDIVIDUAL_BENEFICIARY: {
        // state.beneficiaries.beneficiaries.push(action.payload)
        const updatedBeneficiary = action.payload.beneficiary;


        const newBeneficiariesList = state.beneficiaries.beneficiaries.map(beneficiary => {
          if (beneficiary.beneficiaryId === updatedBeneficiary.beneficiaryId) {
            return {
              ...beneficiary,
              ...updatedBeneficiary,
            };
          }
          return beneficiary;
        });
        return {
          ...state,
          beneficiaries: {
            ...state.beneficiaries,
            beneficiaries: newBeneficiariesList,
          },
        }
      }

      case TOGGLE_SIDEBAR: {
        return {
          ...state,
          menu: {isOpen: action.payload},
        };
      }
      case GET_BASIC_LIFE: {
        return {
          ...state,
          benefit: action.payload
        }
      }
      case GET_WELLNESS: {
        return {
          ...state,
          wellness: action.payload
        }
      }
      case UPDATE_WELLNESS: {
        return {
          ...state,
          wellnessError: action.payload
        }
      }
      case UPDATE_BASIC_LIFE: {
        return {
          ...state,
          basicLifeUpdateResult: action.payload
        }
      }
      case CHANGE_EVENT: {
        return {
          ...state,
          changeEvent: action.payload
        }
      }
      case GET_BENEFIT_IDS: {
        return {
          ...state,
          benefitIds: action.payload
        }
      }
      case GET_HEALTH: {
        return {
          ...state,
          benefit: action.payload
        }
      }
      case GET_ADD: {
        return {
          ...state,
          benefit: action.payload
        }
      }
      case GET_ADDWC: {
        return {
          ...state,
          benefit: action.payload
        }
      }
      case GET_DISABILITY: {
        return {
          ...state,
          benefit: action.payload
        }
      }
      case SET_PAGE_SETTING: {
        USER_TOKEN.setPageSetting(action.payload)
        return {
          ...state,
          pageSetting: action.payload.screen,
          benefit: action.payload.benefit ? action.payload.benefit : [],
        }
      }
      case SET_MENU2_FLAG: {
        return {
          ...state,
          menu2Flag: action.payload
        }
      }
      case GET_ENROLMENT_HEADER: {
        return {
          ...state,
          enrolmentHeader: action.payload
        }
      }
      case GET_ENROLMENT_FOOTER: {
        return {
          ...state,
          enrolmentFooter: action.payload
        }
      }
      case GET_PROGRESS_INDICATOR: {
        return{
          ...state,
          progressBar: action.payload
        }
      }
      case GET_SHOW_FLEX: {
        return {
          ...state,
          showFlex: action.payload
        }
      }
      case CHANGE_OPTION: {
        return {
          ...state,
          benefit: action.payload
        }
      }
      case REMOVE_EOI: {
        return {
          ...state,
          benefit: action.payload
        }
      }
      case REMOVE_WAIVER: {
        return {
          ...state,
          benefit: action.payload
        }
      }
      case SAVE_WAIVER_FORM: {
        return {
          ...state,
          benefit: action.payload
        }
      }
      case SAVE_DEPENDENT_WAIVER_FORM: {
        return {
          ...state,
          benefit: action.payload
        }
      }
      case PUT_DEPENDED_COVERAGE: {
        return {
          ...state,
          benefit: action.payload
        }
      }
      case SET_BENEFIT_OPTION: {
        return {
          ...state,
          selectedBenefitOption: action.payload
        }
      }
      case PUT_WELCOME: {
        return {
          ...state,
          putWelcomeResponse: action.payload
        }
      }
      case PUT_WELCOME_MESSAGES: {
        return {
          ...state,
          putWelcomeResponse: {...state.putWelcomeResponse,
            contentResource: action.payload.contentResource
          }
        };
      }
      case PUT_WELCOME_REQUESTED_OBJECT: {
        return {
          ...state,
          putWelcomeRequestObject: action.payload
        }
      }
      case DISABLE_NEXT: {
        return {
          ...state,
          disableNext: action.payload
        }
      }
      case GET_BENEFIT_SUMMARY: {
        return {
          ...state,
          benefitSummary: action.payload
        }
      }
      case GET_CONTENT_HELP: {
        return {
          ...state,
          helpContentResponse: action.payload
        }
      }
      case GET_FLEX: {
        return {
          ...state,
          flexDollars: action.payload
        }
      }
      case UPDATE_FLEX: {
        return {
          ...state,
          flexDollars: action.payload
        }
      }
      case RELOCATE_FLEX: {
        return {
          ...state,
          flexDollars: action.payload
        }
      }
      case REVIEW_CURRENT_COVERAGE: {
        return {
          ...state,
          currentCoverage: action.payload
        }
      }
      case REVIEW_PAST_COVERAGE: {
        return{
          ...state,
          pastCoverage: action.payload
        }
      }
      case REVIEW_PAST_COVERAGE_LIST: {
        return{
          ...state,
          coverageList: action.payload
        }
      }
      case PDF_STATEMENT: {
        return {
          ...state,
          pdfStatement: action.payload
        }
      }
      case SAVE_FLEX: {
        return {
          ...state,
          flexDollarsSavePayload: action.payload
        }
      }
      case GET_SUMMARY_BENEFITS: {
        return {
          ...state,
          enrollmentSummaryBenefits: action.payload
        }
      }
      case GET_SUMMARY_FLEX: {
        return {
          ...state,
          enrollmentSummaryFlex: action.payload
        }
      }
      case GET_SUMARY_DEPENDENTS: {
        return {
          ...state,
          summaryDependents: action.payload
        }
      }
      case GET_SUMMARY_BENEFICIARIES: {
        return {
          ...state,
          summaryBeneficiaries: action.payload
        }
      }
      case GET_SUMMARY_PERSONAL: {
        return {
          ...state,
          summaryPersonal: action.payload
        }
      }
      case CONFIRM_ENROLLMENT: {
        return {
          ...state,
          enrolmentFooterPostResponse: action.payload
        }
      }
      case GET_BENEFICIARIES_ALL: {
        return {
          ...state,
          beneficiariesAll: action.payload
        }
      }
      case SET_BASE_PAGE_ITEM: {
        return {
          ...state,
          basePageTitleItem: action.payload
        }
      }
      case SET_BASE_PAGE_TITLE: {
        return {
          ...state,
          basePageTitle: action.payload
        }
      }
      case GET_CONTENT_HELP_MENU: {
        return {
          ...state,
          contentHelpMenu: action.payload
        }
      }

      case NEXT_ENROLLMENT_STEP: {
        return{
          ...state,
          nextEnrolStep: action.payload
        }
      }

      case SET_NAV_URL: {
        return {
          ...state,
          headerNavURL: action.payload
        }
      }
      case SET_NAV_ERROR: {
        return{
          ...state,
          nextEnrolStep:{
            navigationError: action.payload
          }
        }
      }
      case FINISH_ENROLLMENT_FORM: {
        return{
          ...state,
          beneficiariyForm: action.payload
        }
      }
      case BENEFIT_URL_LIST: {
        USER_TOKEN.setBenefitNavLinks(action.payload)
        return{
          ...state,
          benefitUrlList: action.payload
        }
      }
      case GET_MULTI_BENEFIT_DATA: {
        USER_TOKEN.setMultiBenefitList(action.payload)
        return{
          ...state,
          benefitsList: action.payload
        }
      }
      case ENROLLMENT_DEVICE_TYPE: {
        localStorage.setItem("deviceType", action.payload)
        return{
          ...state,
          deviceType: action.payload
        }
      }
      case SET_STEP_DESCRIPTION: {
        return{
          ...state,
          stepDescription: action.payload
        }
      }
      case SET_FLEX_BENEFICIARY_ID: {
        localStorage.setItem("flexBenId", action.payload)
        return{
          ...state,
          flexBeneficiaryID: action.payload
        }
      }
      case SET_MULTI_BENEFICIARIES_LIST: {
        localStorage.setItem("multiBenList", JSON.stringify(action.payload))
        return{
          ...state,
          packageMultiBeneficiaries: action.payload
        }
      }
      case SET_FOCUS_ON_BENEFIT_ERROR: {
        return{
          ...state,
          benefitErrorOnNext: action.payload
        }
      }
      /* E-Signature Start */
      case SAVE_ESIGNATURE: {
        return{
          ...state,
          onSaveESign: action.payload
        }
      }
      /* E-Signature End */

      case REMOVE_BENEFICIARY: {
    
          return {
            ...state,
            removeBeneficiaryResponse:action.payload
          };
        }
        case REALLOCATE_MULTIBENEFIT: {
          return {
            ...state,
            beneficiariesMultiBenefit:action.payload
          };
        }
        case PROCEED_TO_NEXT: {
          return {
            ...state,
            multiBenefitErrorMessage:action.payload
          };
        }

      default:
        return state;
    }
  }
  