import React, { Fragment } from "react";
import { Form, Container } from "react-bootstrap";
import { Modal, ModalBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import {
  setModalAttribute,
  getDisplayText,
  CustomDeletePersonIcon,
  CustomToolTipIcon
} from "../utils";
import NewBeneficiariesForm from "./NewBeneficiariesForm";
import { trackPromise } from "react-promise-tracker";
import MultiRadioComponent from "./MultiRadioComponent";
import _ from "underscore";
import { withRouter } from "react-router-dom";
import DisplayButton from "./button";

class BeneficiaryDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showRevocableMsg: false,
      revocableHeader: "",
      revocableMessage: "",
      tooltipId: '',
      showNewModal: false,
      editMode: false,
      formErrors: {
        percentage: "",
        relationship: "",
        trustee: "",
        birthDate: ""
      },
      changeIrrevocable: true,
      showRemoveConfirmModal: false,
      setRevocable: false,
      showEditModal: false,
      mode: "",
      percentageValid: true,
      trusteeValid: true,
      validationforPercentage: new RegExp(
        "^100$|^[0-9]{1,2}$|^[0-9]{1,2},[0-9]{1,3}$"
      ),
      validationforTrustee:
        "^[ÀÁÂÃÄÅÆçÈÉÊËÌÍÎÏñÒÓÔÕÖÙÚÛÜÝŸàáâãäåæçèéêëìíîïñòóôõöùúûüýÿA-Za-z \\-'\\`]{0,50}$",
      openAccordion: true,
      showViewBeneficiary: false,
      showFullModifyConfirmModal: false,
      beneficiary: this.props.beneficiary ? this.props.beneficiary : {},
      error: []
    };
  }

  reverseMode = () => {
    this.setState({ editMode: !this.state.editMode });
  };

  showDeleteConfirmModal = () => {
    this.setState({ ...this.state, showRemoveConfirmModal: true });
  };
  showModifyConfirmModal = () => {
    this.setState({ ...this.state, showFullModifyConfirmModal: true });
  };

  showViewBeneficiaryModal = () => {
    this.setState({
      ...this.state,
      showViewBeneficiary: true,
      showEditModal: true,
      showFullModifyConfirmModal: false
    });
  };

  handleClose = () => {
    this.setState({
      ...this.state,
      showRemoveConfirmModal: false,
      showViewBeneficiary: false,
      setRevocable: false,
      showEditModal: false,
      showFullModifyConfirmModal: false,
      showRevocableMsg: false
    });
  };

  deleteBeneficiary = (e) => {
    e.preventDefault();
    trackPromise(
      this.props.deleteBeneficiary(
        this.props.beneficiary.beneficiaryId,
        this.props.pageSetting.name === "BENEFICIARIES"
          ? -1
          : this.props.benefitID,
        null,
        () => {
          if (
            !this.props.removeBeneficiaryResponse.hasOwnProperty(
              "reallocateActionLink"
            ) ||
            this.props.removeBeneficiaryResponse.reallocateActionLink === null
          ) {
            this.props.getBeneficiaries();
          } else {
            const url = this.props.removeBeneficiaryResponse
              .reallocateActionLink.text
              ? this.props.removeBeneficiaryResponse.reallocateActionLink.text
              : "/BeneficiaryReallocate";
            this.props.history.push(url);
          }
        }
      )
    );
    this.handleClose();
  };

  changeInput = e => {
    let beneficiary = { ...this.state.beneficiary };
    const { name, value } = e.target;
    beneficiary[name] = value;
    this.setState({ beneficiary });
  };

  onDownKeyPressed = event => {
    switch (event.which) {
      case 13: {
        this.showDeleteConfirmModal();
        break;
      }
      default:
        break;
    }
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let validationforPercentage = this.state.percentageValid;
    let validationforTrustee = this.state.trusteeValid;

    switch (fieldName) {
      case "percentage":
        validationforPercentage = this.state.validationforPercentage.test(
          value
        ); //value.match(this.state.validationforPercentage);
        //console.log("P test: ", value.match(this.state.validationforPercentage), validationforPercentage)
        fieldValidationErrors.percentage = validationforPercentage
          ? ""
          : " is invalid";
        break;
      case "trustee":
        validationforTrustee = value.match(this.state.validationforTrustee);
        fieldValidationErrors.trustee = validationforTrustee
          ? ""
          : " is invalid";
        break;

      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        percentageValid: validationforPercentage,
        trusteeValid: validationforTrustee
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid: this.state.percentageValid && this.state.trusteeValid
    });
  }

  setValue = e => {
    const { name, value } = e.target;
    let beneficiary = this.state.beneficiary;
    this.setState(
      {
        beneficiary
      },
      () => {
        this.validateField(name, value);
        let newBeneficiary = { ...this.state.beneficiary };
        if (name === "revocable") {
          delete newBeneficiary.isRevocable;
          newBeneficiary[name] = parseInt(value);
          this.setState({
            setRevocable: !this.state.setRevocable
          });
        } else {
          newBeneficiary[name] = value;
        }
        this.setState({ beneficiary: newBeneficiary });
      }
    );
  };

  setPercentage = e => {
    const { name, value } = e.target;

    if (parseInt(value) !== value) e.target.value = parseInt(value);

    this.validateField(name, value);
    //if(value.match(this.state.validationforPercentage)){
    let beneficiary = this.props.beneficiary;
    let beneficiaryList = this.props.beneficiaries;
    beneficiaryList = beneficiaryList.filter(
      obj => obj.beneficiaryId !== beneficiary.beneficiaryId
    );

    beneficiary[name] = value && parseInt(value);
    beneficiaryList.push(beneficiary);
    this.props.updateBeneficiaryList(beneficiaryList);
    this.setState({ beneficiaryList });
    if (this.props.hasEdited) this.props.hasEdited();
    //}
    // if(value === ""){
    //   let beneficiary = this.props.beneficiary
    //   let beneficiaryList = this.props.beneficiaries
    //   beneficiaryList = beneficiaryList.filter(obj =>
    //     obj.beneficiaryId !== beneficiary.beneficiaryId
    //   )

    //   beneficiary[name] = parseInt(0)
    //   beneficiaryList.push(beneficiary)
    //   this.props.updateBeneficiaryList(beneficiaryList)
    //   this.setState({beneficiaryList})
    // }
  };

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }

  toggleAccordion = () => {
    this.setState(
      oldState => ({
        openAccordion: !oldState.openAccordion,
        error: []
      }),
      () => {
        if (this.state.openAccordion === false) {
          this.setState({
            beneficiary: this.props.beneficiary
          });
          this.props.onOpenBeneificiary(this.props.beneficiaryId);
        } else {
          this.setState({
            beneficiary: {
              trustee: ""
            },
            beneficiaries: [],
            setRevocable: false
          });
          this.props.onOpenBeneificiary("");
          this.props.getBeneficiaries(null, () => {
            this.props.updateBeneficiaryList(this.props.beneficiaries);
          });
        }
      }
    );
  };

  onSave = () => {
    trackPromise(
      this.props.updateIndividualBeneficiary(
        this.state.beneficiary,
        null,
        () => {
          this.props.getBeneficiaries();
        }
      )
    );
  };

  renderBirthDate = birthDate => {
    if (birthDate) {
      const { month, day, year } = birthDate;
      return `${month}/${day}/${year}`;
    } else {
      return "";
    }
  };

  changeRevocability = e => {
    let beneficiary = { ...this.state.beneficiary };
    const { name } = e.target;
    if (beneficiary[name] === 0) {
      beneficiary[name] = 1;
    } else {
      beneficiary[name] = 0;
    }

    this.setState({
      beneficiary,
      setRevocable: !this.state.setRevocable
    });
  };

  handleSave = e => {
    this.onSave();
    this.handleClose();
  };

  getBeneficiaryType = type => {
    const index = _.indexOf(this.props.beneficiariesFull.typeList, type);
    let benefiaryType = "";
    switch (index) {
      case 0:
        benefiaryType = "Person";
        break;
      case 1:
        benefiaryType = "Charity";
        break;
      case 2:
        benefiaryType = "Estate";
        break;
      default:
        benefiaryType = "Unknown";
    }

    return benefiaryType;
  };

  toggleDescription = (revocableMessage, tooltipId) => {
    this.setState({
      showRevocableMsg: !this.state.showRevocableMsg,
      revocableMessage,
      tooltipId
    });
  };

  handleInfoDetailThroughTab = (event, revocableMessage, tooltipId) => {
    switch (event.which) {
      case 13: {
        this.toggleDescription(revocableMessage, tooltipId);
        break;
      }
      default:
        break;
    }
  };

  render = () => {
    const canBeRemoved = this.props.canBeRemoved;
    const { contents } = this.props;
    const lblRelationship = getDisplayText(
      this.props.benefitBeneficiaries,
      "lblRelationship"
    );
    const lblTrustee = getDisplayText(
      this.props.benefitBeneficiaries,
      "lblTrustee"
    );
    const lblSetIrrevocable = getDisplayText(
      this.props.benefitBeneficiaries,
      "lblSetIrrevocable"
    );
    const lblDeleteModifyBeneficiaryHeader = getDisplayText(
      contents,
      "btnConfirm"
    );
    const lblDeleteModifyBeneficiaryConfirmBtn = getDisplayText(
      contents,
      "btnOK"
    );
    const lblDeleteModifyBeneficiaryCancelBtn = getDisplayText(
      contents,
      "btnCancel"
    );
    const rvPercentErrorMessage = getDisplayText(
      this.props.benefitBeneficiaries,
      "rvPercentErrorMessage"
    );
    const lblInstructionsView = getDisplayText(
      this.props.benefitBeneficiaries,
      "lblInstructionsEdit"
    );
    const lblViewBeneficiaries = getDisplayText(
      this.props.benefitBeneficiaries,
      "lblViewBeneficiaries"
    );
    const lblViewBeneficiaryTitle = getDisplayText(
      this.props.benefitBeneficiaries,
      "lblViewBeneficiaryTitle"
    );
    const lblEditBeneficiaryTitle = getDisplayText(
      this.props.benefitBeneficiaries,
      "lblEditBeneficiaryTitle"
    );
    const lblSetRevocable = getDisplayText(
      this.props.benefitBeneficiaries,
      "lblSetRevocable"
    );
    const dob = getDisplayText(contents, "DOB");
    const btnSave = getDisplayText(contents, "btnSave");
    const closeButtonText = getDisplayText(contents, "btnClose");
    const editIcon = getDisplayText(
      (this.props.contentFooter && this.props.contentFooter) || {},
      "EditIcon"
    );
    const deleteIcon = getDisplayText(
      (this.props.contentFooter && this.props.contentFooter) || {},
      "DeleteIcon"
    );
    const birthDate = this.props.beneficiary.birthDate;
    const lblSetIrrevocableOptions = [
      { text: lblSetIrrevocable.display, value: 0 }
    ];
    const lblSetRevocableOptions = [
      { text: lblSetRevocable.display, value: 1 }
    ];

    
    const {
      isRevocable,
      isEditable,
      isReadOnly,
      modifyEnabled,
      fullModify
    } = this.props.beneficiary;
    const benefiaryType = this.getBeneficiaryType(this.props.beneficiary.type);
    const isTrustee =
      this.props.beneficiary.trustee !== null &&
      this.props.beneficiary.trustee !== "" &&
      this.props.beneficiary.trustee !== "N/A"
        ? true
        : false;
    //const revocableMessage = this.props.beneficiary.revocableMessage || "";

    /* <Accordion className={this.state.openAccordion ? "" : "expanded"}> */
    const toolTipIcon = getDisplayText(contents, "ToolTipIcon");
    return (
      <Fragment>
        {this.state.error.length > 0 ? typeof this.state.error === "string" ? (<p className="ml-inputfields formErrors has-error formErrorPStyle" role="alert">{this.state.error}</p>) : (this.state.error.map(e => (<p className="ml-inputfields has-error formErrors formErrorPStyle" role="alert">{e}</p>))) : null}
        <tr className="beneficiary">
          <td className="justify-content-center align-items-center">
            <strong>{this.props.beneficiary.name}</strong>
            <br />
            {dob.display + " "}
            {birthDate && benefiaryType === "Person"
              ? birthDate.display
              : "n/a"}
          </td>
          <td className="justify-content-center align-items-center">
            {benefiaryType !== "Estate"
              ? this.props.beneficiary.relationship
              : "n/a"}
          </td>
          <td className="justify-content-center align-items-center">
            {(benefiaryType === "Person" || benefiaryType === "Estate") &&
            isTrustee
              ? this.props.beneficiary.trustee
              : "n/a"}
          </td>
          <td className="justify-content-center align-items-center">
            <div
              className={`percentage ${this.errorClass(
                this.state.formErrors.percentage
              )}`}
            >
              <Form.Control
                type="number"
                aria-label="-"
                value={this.props.beneficiary.percentage}
                isInvalid={!this.state.percentageValid}
                name="percentage"
                disabled={isReadOnly}
                onChange={this.setPercentage}
              />
            </div>
            <span className="d-flex lbl-percentage">%</span>
            <div className="btnsaction d-flex justify-content-between">
              {isReadOnly && (
                <span
                tid="showTooltip"
                role="button"
                aria-label={toolTipIcon.textToRead}
                tabIndex="0"
                className="tooltip-icon icon-action float-right"
                onClick={() => this.toggleDescription(this.props.beneficiary.revocableMessage, "showTooltip-benefitID-" + this.props.benefitID)}
                onKeyDown={event => {
                  this.handleInfoDetailThroughTab(event, this.props.beneficiary.revocableMessage, "showTooltip-benefitID-" + this.props.benefitID);
                }}
              >
                <CustomToolTipIcon />
              </span>
            )}
              {(isEditable || benefiaryType === "Person") && (isRevocable === 1 || canBeRemoved) &&
                !fullModify && (
                  <span
                    className="btnaction btndetailpopup"
                    tabIndex="0"
                    role="button"
                    aria-label={`${editIcon.textToRead} ${this.props.beneficiary.name}`}
                    onKeyDown={this.onDownKeyPressed}
                    onClick={this.showViewBeneficiaryModal}
                  >
                    {" "}
                    <FontAwesomeIcon
                      className="icon-action edit-icon"
                      icon={faPencilAlt}
                    />{" "}
                  </span>
                )}
              {modifyEnabled && fullModify && (
                <span
                  className="btnaction btndetailpopup"
                  tabIndex="0"
                  role="button"
                  aria-label={`${editIcon.textToRead} ${this.props.beneficiary.name}`}
                  onKeyDown={this.onDownKeyPressed}
                  onClick={this.showModifyConfirmModal}
                >
                  {" "}
                  <FontAwesomeIcon
                    className="icon-action edit-icon"
                    icon={faPencilAlt}
                  />{" "}
                </span>
              )}
              {canBeRemoved && (
                <span
                  className="btnaction btndelete icon-action edit-icon close-icon"
                  tid="showDeleteConfirmModal"
                  tabIndex="0"
                  role="button"
                  aria-label={`${deleteIcon.textToRead} ${this.props.beneficiary.name}`}
                  onKeyDown={this.onDownKeyPressed}
                  onClick={this.showDeleteConfirmModal}
                >
                  {" "}
                  <CustomDeletePersonIcon />{" "}
                </span>
              )}
            </div>
          </td>
        </tr>
        {!this.state.percentageValid &&
        <tr>
          <td colSpan="4" role='alert' className="pt-2 alert alert-danger text-center pb-2">
            {rvPercentErrorMessage.display}
          </td>
        </tr>
        }
        
        {/*    Modify Modal Pop-up Warning message */}
        <Modal onOpened={() => setModalAttribute(lblDeleteModifyBeneficiaryHeader.textToRead)} aria-labelledby="modalHeadingDetail"  className={`deletebeneficiary`} size="md"  centered isOpen={this.state.showFullModifyConfirmModal && fullModify} toggle={this.handleClose}>
          <div className="modal-header">
            <h1 className="modal-title" id="modalHeadingDetail">
              {lblDeleteModifyBeneficiaryHeader.display}
            </h1>          
            <DisplayButton type="button"  className="close" displayName="×" arialabel={closeButtonText.display} onClick={this.handleClose}/> 
          </div>
          <ModalBody>
            <div className={`ml-popupmessage`}>
              <p className="ml-popupmessagetext"  dangerouslySetInnerHTML={{ __html: this.props.beneficiary.modifyButtonMsg}}></p>
            </div>
            <div className={'ml-buttons d-flex justify-content-between'}>
              <DisplayButton className="outline-secondary btn-sm btn-cancel" displayName={lblDeleteModifyBeneficiaryCancelBtn.display} controlId="closeModal" onClick={this.handleClose} />
              <DisplayButton className="outline-secondary btn-sm btn-ok" 
                controlId="modifyBeneficiary" 
                onClick={this.showViewBeneficiaryModal} 
                displayName={lblDeleteModifyBeneficiaryConfirmBtn.display}
              />
            </div>
          </ModalBody>
        </Modal>

        {/*    Delete Modal Pop-up */}
        <Modal onOpened={() => setModalAttribute(lblDeleteModifyBeneficiaryHeader.textToRead)} className={`deletebeneficiary`} size="md" aria-labelledby="modalHeadingDeleteBeneficiary" centered isOpen={this.state.showRemoveConfirmModal} toggle={this.handleClose}>
          <div className="modal-header">
            <h1 className="modal-title" id="modalHeadingDeleteBeneficiary">
              {lblDeleteModifyBeneficiaryHeader.display}
            </h1>
            <DisplayButton type="button"  className="close" displayName="×" arialabel={closeButtonText.display} onClick={this.handleClose}/> 
          </div>
          <ModalBody>
            <div className={`ml-popupmessage`}>
            <p   className="ml-popupmessagetext"  dangerouslySetInnerHTML={{ __html: this.props.beneficiary.removeButtonMsg}}></p>
            </div>
            <div className={'ml-buttons d-flex justify-content-between'}>
              <DisplayButton className="outline-secondary btn-sm btn-cancel" displayName={lblDeleteModifyBeneficiaryCancelBtn.display} controlId="closeModal" onClick={this.handleClose}  />
              <DisplayButton className="outline-secondary btn-sm btn-ok" 
                controlId="deleteBeneficiary" 
                onClick={this.deleteBeneficiary}  
                displayName={lblDeleteModifyBeneficiaryConfirmBtn.display}
              / >
            </div>
          </ModalBody>
        </Modal>

        {/*    View Modal Pop-up  //{lblViewBeneficiaryTitle.display} */}
        <Modal onOpened={() => setModalAttribute(lblEditBeneficiaryTitle.textToRead)} aria-labelledby="modalViewBeneficiaryHeading" className="ml-addnewbeneficiary" isOpen={this.state.showEditModal && fullModify} toggle={this.handleClose}>
          <div className="modal-header">
              <h1 className="modal-title" id="modalViewBeneficiaryHeading">
              {lblEditBeneficiaryTitle.display}
              </h1>
              <DisplayButton type="button"  className="close" displayName="×" arialabel={closeButtonText.display} onClick={this.handleClose}/> 
            </div>
          <ModalBody>
          <div className="row">
                  <Container className="scroll-container">
                      <NewBeneficiariesForm
                        beneficiary={this.props.beneficiary}
                        beneficiaries={this.props.beneficiariesFull}
                        beneficiariesFull={this.props.beneficiariesFull}
                        createBeneficiary={this.props.createBeneficiary}
                        isRevocable ='1'
                        close={this.handleClose}
                        getBeneficiaries={this.props.getBeneficiaries}
                        onOpenBeneificiary={this.props.onOpenBeneificiary}
                        lblCancel= {this.props.lblCancel}
                        locale = {this.props.locale}
                        isEditMode={true}
                        benefiaryType={benefiaryType.toLowerCase()}
                        closeButtonText={closeButtonText}
                      />
                  </Container>
                </div>
          </ModalBody>
        </Modal>

        {/*    View Modal Pop-up */}
        <Modal onOpened={() => setModalAttribute(lblViewBeneficiaryTitle.textToRead)} className={`viewEditBeneficiary`} size="md" aria-labelledby="modalViewEditBeneficiary" centered isOpen={this.state.showViewBeneficiary && !fullModify} toggle={this.handleClose}>
        <div className="modal-header">
          <h1 className="modal-title" id="modalViewEditBeneficiary" >
              {lblViewBeneficiaryTitle.display} 
          </h1>
          <DisplayButton type="button"  className="close" displayName="×" arialabel={closeButtonText.display} onClick={this.handleClose}/> 
        </div>
        <ModalBody>
          <Fragment>
            <div className="description pb-3">
              <p className="m-0" aria-label={lblInstructionsView.textToRead} dangerouslySetInnerHTML={{__html:lblInstructionsView.display}}></p>
            </div>
          </Fragment>
          <div className="beneficiaryinfo py-3">
            <div>
              {this.props.beneficiary.name}
            </div>
            {benefiaryType === "Person" ?
              <div className="py-2 d-flex">
                <span>{dob.display}</span>
                <span>{ birthDate && birthDate.display }</span>
                {/* <span>{ this.renderBirthDate(birthDate) }</span> */}
              </div> : ''
            }
            {benefiaryType !== "Estate" ?
              <div className="d-flex">
                <span>{lblRelationship.display}</span>
                <span>{this.props.beneficiary.relationship}</span>
              </div> : ''
            }
            <div className="pt-2">
              {benefiaryType!== "Charity" ?
                <span className=""  id={"trusteeLabel"+this.props.ItemIndex}>
                  <label htmlFor={"trustee"+this.props.ItemIndex}>{lblTrustee.display}</label>
                </span> : ''
              }
              { isEditable === true && benefiaryType === "Person" ?
                <div className={`ml-inputfields ml-trustee ${this.errorClass(this.state.formErrors.trustee)}`} tid="typeCharity">
                  <Form.Control  id={"trustee"+this.props.ItemIndex} value={this.state.beneficiary.trustee || ''} type="text" placeholder={this.props.beneficiary.trustee?this.props.beneficiary.trustee:lblTrustee.display} name="trustee" onChange={this.setValue} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}/>
                </div>
                : benefiaryType === "Person" || benefiaryType === "Estate" ? (<span>{this.props.beneficiary.trustee}</span>) : ''
              }
            </div>
            {benefiaryType === "Person" && this.props.beneficiariesFull.showIsRevocable ?
              <div className="ml-radiolist clearfix">
                <MultiRadioComponent component={`newBeneficiary-${this.props.index}`}
                  name="revocable"
                  checked={this.state.setRevocable}
                  type="checkbox"
                  options={isRevocable===1?lblSetIrrevocableOptions:lblSetRevocableOptions}
                  keyy={`${this.props.beneficiary.beneficiaryId}-setrevocable`}
                  onChange={this.state.setRevocable ? this.changeRevocability:this.setValue}/>
              </div> 
            : ''}
          </div>
          <div className={'ml-buttons d-flex justify-content-between'}>
            <DisplayButton className="outline-secondary btn-sm btn-cancel" displayName={lblDeleteModifyBeneficiaryCancelBtn.display} controlId="closeModal" onClick={this.handleClose} />
            <DisplayButton className="outline-secondary btn-sm btn-save"
              onClick={this.handleSave}
              displayName={btnSave.display}/>
          </div>
        </ModalBody>
      </Modal>

      {/* Model for revocable message */}
      <Modal onOpened={() => setModalAttribute('modalHeading')} aria-labelledby="modalHeading" isOpen={this.state.showRevocableMsg} toggle={this.handleClose} centered>
          <div className="modal-header">
            <h1 className="modal-title" id="modalHeading">
              {this.state.revocableHeader ? this.state.revocableHeader : '\u00A0'}
            </h1>
            <DisplayButton type="button"  className="close" arialabel={closeButtonText.display} displayName="×" onClick={this.handleClose}/> 
          </div>
          <ModalBody>
            <Fragment><div dangerouslySetInnerHTML={{__html: this.state.revocableMessage}}/></Fragment>
          </ModalBody>
        </Modal>
      
      </Fragment>
    );
  };
}
export default withRouter(BeneficiaryDetail);
