import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React, { Component } from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import 'bootstrap/dist/css/bootstrap.css';
import "./index.scss";
import { Spinner } from './components/spinner/Spinner';

class Root extends Component  {
  render () {
  return (
    <div>
      <App />
      <Spinner/>
    </div>
  )
  }
}


ReactDOM.render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
