import React, { Component } from 'react';
import { connect } from "react-redux";

import { withRouter } from 'react-router-dom';

import { getMultiBenefits } from '../actions/userActions';
import { dissmiss } from '../actions/contentActions';

import _ from 'underscore';

import BenefitShellMulti from "./BenefitShellMulti";

import PackageGroupMulti from "./PackageGroupMulti";

class MultiBenefitShell extends Component {

  componentDidUpdate() {
    this.props.dissmiss()
  }
  componentWillUnmount() {
    this.props.dissmiss()
  }
  render () {
    const benefitsList = this.props.benefitsList
    const benefitUrlList = this.props.benefitUrlList
    const benefitList = []
    const benefitErrorIndex = _.findIndex(benefitsList, benefit => (benefit || {}).errorMessage)

    _.forEach(benefitsList, (benefit, index) => {
        const benefitContent = benefit
        const benefitURL = (benefitUrlList && (benefitUrlList || {}).length > 0) ? benefitUrlList[index] : ""
        const benefitName = ((benefitURL || "").split('/')[0] || "")

        const benefitWithErrorUrl = 
          benefitErrorIndex !== -1 
            ? (benefitUrlList && (benefitUrlList || {}).length > 0) ? benefitUrlList[benefitErrorIndex] : ""
            : ""

        benefitList.push(
            <div key={`div-${benefit[index]}-benefit-${index}-url-${benefitURL}-ID`}>
                {benefitName.toLowerCase() === "package" 
                  ? <PackageGroupMulti benefitWithError={benefitWithErrorUrl} benefitURL={benefitURL} benefit={benefitContent} isMulti={true}/>
                  : <BenefitShellMulti benefitWithError={benefitWithErrorUrl} benefitURL={benefitURL} benefit={benefitContent} isMulti={true}/>
                }
            </div>
        )
    })
    return (
    <div className={`clearfix`}>
        {benefitList}
    </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
          benefitUrlList: state.userReducer.benefitUrlList ? state.userReducer.benefitUrlList : [],
          benefitsList: state.userReducer.benefitsList ? state.userReducer.benefitsList : []
        }
};

export default connect(
mapStateToProps,
{ getMultiBenefits, dissmiss }
)(withRouter(MultiBenefitShell));
