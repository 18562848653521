import React, { Component, Fragment } from "react";

import { connect } from "react-redux";
import { Alert } from "react-bootstrap";
import { dissmiss } from "../actions/contentActions";
import ReactDOM from "react-dom";
import { getDisplayText } from "../utils";

class CustomAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sessionTimeOutFlag: ""
    };
  }

  componentDidUpdate(prevState) {
    if (this.state.sessionTimeOutFlag !== localStorage.getItem("sessionTimeOut")) {
      this.setState({ sessionTimeOutFlag: localStorage.getItem("sessionTimeOut") });
    }
    if (this.refs.errorMessage && this.props.message.message) {
      ReactDOM.findDOMNode(this.refs.errorMessage).focus();
    }
  }
  render() {
    const handleDismiss = () => this.props.dissmiss();
    const sessionExpiredMessage = getDisplayText((this.props.contentFooter.contentResource && this.props.contentFooter.contentResource.contents) || {}, "SessionExpiredMessage");

    if (this.state.sessionTimeOutFlag !== null) {
      setTimeout(() => {
        localStorage.removeItem("sessionTimeOut");
      }, 2000);
    }
    if (this.props.message.message || this.props.message.header) {
      return (
        <Alert ref="errorMessage" tabIndex="-1" variant={this.props.type} onClose={handleDismiss} dismissible>
          <Alert.Heading>{this.props.message.header}</Alert.Heading>
          <Fragment>
            <p dangerouslySetInnerHTML={{ __html: this.props.message.message }} />
          </Fragment>
        </Alert>
      );
    } else if (this.state.sessionTimeOutFlag) {
      return (
        <Alert ref="errorMessage" tabIndex="-1" variant="danger" onClose={handleDismiss} dismissible>
          <Alert.Heading>{""}</Alert.Heading>
          <Fragment>
            <p dangerouslySetInnerHTML={{ __html: sessionExpiredMessage.display }} />
          </Fragment>
        </Alert>
      );
    }
    return <div className="nomessage" />;
  }
}

const mapStateToProps = state => {
  return {
    contentFooter: state.userReducer.contentFooter,
    message: state.contentReducer.message,
    type: state.contentReducer.messageType
  };
};

export default connect(mapStateToProps, { dissmiss })(CustomAlert);
