import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getSummaryDependents, setPageSetting } from '../actions/userActions';
import { dissmiss } from '../actions/contentActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { trackPromise } from 'react-promise-tracker';
import { Card, Row, Col } from 'react-bootstrap';
import DisplayButton from './button';
import _ from 'underscore';
import { getDisplayText } from '../utils';
import WarningMessageModal from './WarningMessageModal'

class SummaryDependents extends Component {

    constructor(props) {
		super(props)
        this.state = { showWarningModal: false,actionLink:''}
		
    }
    componentDidMount() {
        trackPromise(this.props.getSummaryDependents())
    }
    componentWillUnmount(){
        this.props.dissmiss()
    }
    handleEditAction = (link='') => {
        link=this.state.actionLink?this.state.actionLink:link;
        const menuItem = _.find(this.props.sideBarMenu, item => item.link === link)
        this.props.setPageSetting(menuItem ? menuItem.name : "WELCOME", true);
        this.props.history.push(`/${link}`);
        // this.props.setPageSetting(`${link}`, true);
        // this.props.history.push(`/${link}`);
    }

    handleWarningModal = (link='') => { 
        if(this.props.showWarningMessage){
            this.setState({showWarningModal: true,actionLink:link})
        }
        else {this.handleEditAction(link)}
    }

    renderDependentsDetail(list, name, isNoCoverageVisible, dependentsCoverageHeader) {
        if (list && list.length > 0) {
            const items = []
            _.forEach(list, (item, index) => {
                items.push(
                    <Row key={`div-${name}-${item.benefitName}-${index}`} className="text-center valueboxes">
                        {dependentsCoverageHeader.benefit && <Col><span className="visuallyhidden" dangerouslySetInnerHTML={{__html: dependentsCoverageHeader.benefit}}/>{item.benefitName}</Col>}
                {dependentsCoverageHeader.coveredThisPlan && <Col><span className="visuallyhidden" dangerouslySetInnerHTML={{__html: dependentsCoverageHeader.coveredThisPlan.replace(/<br\s*\/?>/gi,' ')}}/>{item.isCoveredUnderThisPlan}</Col>}
                        {dependentsCoverageHeader.coveredOtherPlan && <Col><span className="visuallyhidden" dangerouslySetInnerHTML={{__html: dependentsCoverageHeader.coveredOtherPlan.replace(/<br\s*\/?>/gi,' ')}}/>{item.isCoveredUnderOtherPlan}</Col>}
                        {isNoCoverageVisible && <Col><span className="visuallyhidden" dangerouslySetInnerHTML={{__html:dependentsCoverageHeader.noCoverage}}/>{item.noCoverage}</Col>}
                    </Row>
                )
            })
            return (
                <Col className="col-10 col-sm-8">{items}</Col>
            )
        }
        return null;
    }

    handleDownKeyPressed = (event, actionLink='') => {
        switch(event.which){
            case 13: {
                this.handleEditAction(actionLink);
                break;
            }
            default:
				break;
        }
     }

    handleClose=() =>{
        this.setState({showWarningModal: false,actionLink:''})  
    }
    render() {
        const pageTitle = this.props.summaryDependents.tabName
        const noDependentsMessage = this.props.summaryDependents.noDependentsMessage
        const displayDependentsInfo = this.props.summaryDependents.displayDependentsInfo ? this.props.summaryDependents.displayDependentsInfo : []
        
        const isbtnEditDependentVisible = this.props.summaryDependents.isbtnEditDependentVisible ? this.props.summaryDependents.isbtnEditDependentVisible : false
        const actionLink = this.props.summaryDependents.actionlink
        const dependentsCoverageHeader = this.props.summaryDependents.dependentsCoverageHeader ? this.props.summaryDependents.dependentsCoverageHeader : {}
        
        const tabIndexValue = 0;
        const isNoCoverageVisible = dependentsCoverageHeader.noCoverage 
            && typeof dependentsCoverageHeader.noCoverage !== 'undefined' 
            && dependentsCoverageHeader.noCoverage !== null 
            ? true : false

        const editIcon = getDisplayText((this.props.contentFooter.contentResource && this.props.contentFooter.contentResource.contents) || {}, 'EditIcon')
        const backButtonText = getDisplayText((this.props.contentFooter.contentResource && this.props.contentFooter.contentResource.contents) || {}, 'btnBack')
        document.title = pageTitle
        const dependentsList = [];
        _.forEach(displayDependentsInfo, (dependent) => {
            const details = this.renderDependentsDetail(dependent.dependentDetail, dependent.dependentName, isNoCoverageVisible, dependentsCoverageHeader)
            dependentsList.push(
                <div key={`div-${dependent.dependentName}-${dependent.dob}-ID`} className="mb-4">
                    <Card>
                        <Card.Header>
                            <h2 className="float-left h2">{dependent.dependentName} / {dependent.relationShip}</h2>
                            {isbtnEditDependentVisible && <span className="float-right icon-action" tabIndex={tabIndexValue} role='button' aria-label={editIcon.textToRead+" "+dependent.dependentName} onKeyDown={(event) => this.handleDownKeyPressed(event, actionLink)} onClick={() => {this.handleWarningModal(actionLink)}} >
                                <FontAwesomeIcon icon={faPencilAlt}/>
                            </span>}
                        </Card.Header>
                        <Card.Body>
                            <Row className="mb-3">
                                <Col>{dependent.dob} {dependent.smokerStatus && `, ${dependent.smokerStatus}`}</Col>
                            </Row>
                            {details && 
                                <Row className="justify-content-end">{details}</Row>
                            }
                        </Card.Body>
                    </Card>
                </div>
            )
        })
        return (
            <div className="summarydependents clearfix">
                <div className={`d-flex titlewithicon mb-3`}> 
                    <DisplayButton className="icon-action" displayName={backButtonText.display} icon={faChevronLeft} onClick={() => { this.props.history.push("/Summary")}} /> 
                </div>
                <div>
                    { (!noDependentsMessage || dependentsList.length !== 0) ? 
                        <>
                        <Row className="justify-content-end m-0">
                            <Col className="col-10 col-sm-8">
                                <Row className="justify-content-between text-center valueboxes valueboshead">
                                    {dependentsCoverageHeader.benefit && <Col>{dependentsCoverageHeader.benefit}</Col>}
                                    {dependentsCoverageHeader.coveredThisPlan && <Fragment><Col><div dangerouslySetInnerHTML={{__html: dependentsCoverageHeader.coveredThisPlan}}/></Col></Fragment>}
                                    {dependentsCoverageHeader.coveredOtherPlan && <Fragment><Col><div dangerouslySetInnerHTML={{__html: dependentsCoverageHeader.coveredOtherPlan}}/></Col></Fragment> }
                                    { isNoCoverageVisible && <Col>{dependentsCoverageHeader.noCoverage}</Col> }
                                </Row>
                            </Col>
                        </Row>
                        {dependentsList}
                        </> : noDependentsMessage }
                </div>
                <WarningMessageModal showWarningModal={this.state.showWarningModal} clickAction={this.handleEditAction} handleClose={this.handleClose} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {summaryDependents: state.userReducer.summaryDependents ? state.userReducer.summaryDependents : {},
            contentFooter: state.userReducer.contentFooter,
            sideBarMenu: state.contentReducer.screens,
            showWarningMessage:state.contentReducer.showWarningMessage	
    }
};

export default connect(
    mapStateToProps, { getSummaryDependents, setPageSetting, dissmiss }
)(withRouter(SummaryDependents));

