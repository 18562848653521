import React, { useState, useEffect, Fragment } from 'react';
import ConfirmedItem from './confirmedItem';
import EnrollmentBenefit from './EnrollmentBenefit';
import NextPrevious from '../NextPrevious';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { trackPromise } from 'react-promise-tracker';
import DisplayButton from '../button';
import { getDisplayText,skipMainContent } from '../../utils';
import {
    finishEnrollment, goNext, downloadFinishedEnrollmentFile
} from "../../actions/userActions";
import { dissmiss } from '../../actions/contentActions'

const EnrollmentConfirmation = props => {
    localStorage.removeItem('hideButtons')
    const [confirmationStatmentVisible, setConfirmationStatmentVisible] = useState(false);
    const [beneficiaryFormsFormVisible, setBeneficiaryFormsFormVisible] = useState(false);
    const [rrspFormVisible, set_rrspFormVisible] = useState(false);
    const [eoiFormVisible, setEoiFormVisible] = useState(false);
    const [showFileUploadButton, setShowFileUploadButton] =useState(false);
    const { finishEnrollmentData, locale, finishEnrollment } = props;
    const formStatus = props.match.url === '/FinishedEnrollment/Forms' && 1;

    const queryParam = (props.location.search && props.location.search.indexOf('period') > -1) ? props.location.search: null
    const dangerType = 'DANGER';
    const eobFormButtonColor = '#f7f7f8';
    const simpleButtonColor = '#f4f9f2';

    useEffect(() => {
        dissmiss()
        skipMainContent()
        trackPromise(finishEnrollment(formStatus,queryParam));
    },[locale, finishEnrollment, formStatus, queryParam]);

    useEffect(() => {
        setConfirmationStatmentVisible(finishEnrollmentData.confirmationStatmentVisible);
        setBeneficiaryFormsFormVisible(finishEnrollmentData.beneficiaryFormsFormVisible);
        setEoiFormVisible(finishEnrollmentData.eoiFormVisible);
        set_rrspFormVisible(finishEnrollmentData.rrspFormVisible);
        setShowFileUploadButton(finishEnrollmentData.showFileUploadButton);
    }, [finishEnrollmentData])

    const confirmationTitle = finishEnrollmentData.finishConfirmPageTitle;
    const lblBottomMessage = finishEnrollmentData.lblBtmMSG ? finishEnrollmentData.lblBtmMSG : "";
  	const lblBottomMessageVis = (finishEnrollmentData.lblBtmMSG===""||finishEnrollmentData.lblBtmMSG===null) ? false:true;

    let confimationStatementName = "";
    let confimationStatementMessage = "";
    let confimationStatementMessageType = "";
    let confimationStatementNavigateTo = "";
    if(confirmationStatmentVisible && (finishEnrollmentData.confirmationStatment && finishEnrollmentData.confirmationStatment !== null)){
        confimationStatementName = confirmationStatmentVisible ? finishEnrollmentData.confirmationStatment.name : "";
        confimationStatementMessage = confirmationStatmentVisible ? finishEnrollmentData.confirmationStatment.message : "";
        confimationStatementMessageType = confirmationStatmentVisible ? finishEnrollmentData.confirmationStatment.messageType : "";
        confimationStatementNavigateTo = confirmationStatmentVisible ? finishEnrollmentData.confirmationStatment.btnNavigateTo : "";
    }
    
    let beneficiaryFormName = "";
    let beneficiaryFormMessage = "";
    let beneficiaryFormMessageType = "";
    let beneficiaryFormNavigateTo = "";
    let beneficiaryFilename = "";
    if(beneficiaryFormsFormVisible && (finishEnrollmentData.beneficiaryForms && finishEnrollmentData.beneficiaryForms !== null)){
        beneficiaryFormName = beneficiaryFormsFormVisible ? finishEnrollmentData.beneficiaryForms.name : "";
        beneficiaryFormMessage = beneficiaryFormsFormVisible ? finishEnrollmentData.beneficiaryForms.message : "";
        beneficiaryFormMessageType = beneficiaryFormsFormVisible ? finishEnrollmentData.beneficiaryForms.messageType : "";
        beneficiaryFormNavigateTo = beneficiaryFormsFormVisible ? finishEnrollmentData.beneficiaryForms.btnNavigateTo : "";
        beneficiaryFilename = finishEnrollmentData.beneficiaryForms.fileName ? finishEnrollmentData.beneficiaryForms.fileName : "";
    }

    /* E-Signature Start */
    const isEsignable = finishEnrollmentData.isEsignable || false
    const beneficiaryESign = finishEnrollmentData.beneficiaryESign || null
    let beneficiaryESignFilename = "";
    if(isEsignable && (beneficiaryESign && beneficiaryESign !== null)) {
        beneficiaryESignFilename = beneficiaryESign.fileName ? beneficiaryESign.fileName : "";
    }
    /* E-Signature End */

    let rrspFormName = "";
    let rrspFormMessage = "";
    let rrspFormMessageType = "";
    let rrspFormNavigateTo = "";
    let rrspFileName = "";
    if (rrspFormVisible && (finishEnrollmentData.rrsp && finishEnrollmentData.rrsp !== null)) {
        rrspFormName = (finishEnrollmentData.rrsp.name) ? finishEnrollmentData.rrsp.name : "";
        rrspFormMessage = (finishEnrollmentData.rrsp.message) ? finishEnrollmentData.rrsp.message : "";
        rrspFormMessageType = (finishEnrollmentData.rrsp.messageType) ? finishEnrollmentData.rrsp.messageType : "";
        rrspFormNavigateTo = (finishEnrollmentData.rrsp.btnNavigateTo) ? finishEnrollmentData.rrsp.btnNavigateTo : "";
        rrspFileName = (finishEnrollmentData.rrsp.fileName) ? finishEnrollmentData.rrsp.fileName : "RRSP";
    }

    let eobEnabledList = [];
    let messageTop = "";
    let messageBottom = "";
    let messageTopType = "";
    let messageBottomType = "";
    if(eoiFormVisible && (finishEnrollmentData.eoiForms && finishEnrollmentData.eoiForms !== null)){
        eobEnabledList = finishEnrollmentData.eoiForms.list ? finishEnrollmentData.eoiForms.list : [];
        messageTopType = finishEnrollmentData.eoiForms.messageTopType ? finishEnrollmentData.eoiForms.messageTopType : "";
        messageTop = finishEnrollmentData.eoiForms.messageTop ? finishEnrollmentData.eoiForms.messageTop : "";
        messageBottomType = finishEnrollmentData.eoiForms.messageBottomType ? finishEnrollmentData.eoiForms.messageBottomType : "";
        messageBottom = finishEnrollmentData.eoiForms.messageBottom ? finishEnrollmentData.eoiForms.messageBottom : "";
    }

    const downloadButtonLabel = finishEnrollmentData.downloadButtonLabel ? finishEnrollmentData.downloadButtonLabel : "";
    const noCarrierMessageLabel = finishEnrollmentData.lblNoCarrieMSG ? finishEnrollmentData.lblNoCarrieMSG : "";
    const coverLetterMessage = finishEnrollmentData.coverletterLabel ? finishEnrollmentData.coverletterLabel : "";
    const questionnaireLabel = finishEnrollmentData.questionnaireLabel ? finishEnrollmentData.questionnaireLabel : "";
    const homeButtonLabel = finishEnrollmentData.homeButtonLabel ? finishEnrollmentData.homeButtonLabel : "";

    const uploadButtonNavigateClicked = () => {
        props.history.push(finishEnrollmentData.fileUploadButtonlnk)
    }

    const beneficiaryNavigationClicked = (formNavigationUrl, fileName) => {
        props.downloadFinishedEnrollmentFile(formNavigationUrl, fileName);
    }

    const confirmationStatementButtonClicked = () => {
        // eslint-disable-next-line
        const [path, params] = confimationStatementNavigateTo.split('?')
		if (params)
			localStorage.setItem("parameters", params)
		else 
            localStorage.removeItem("parameters")
        //props.history.push('/current');
    }

    const rrspButtonClicked = () => {
        props.downloadFinishedEnrollmentFile(rrspFormNavigateTo, rrspFileName);
    }
    const footerContent = props.contentFooter
    const lblNewTab =getDisplayText(footerContent, 'lblNewTab')
    return (
        <div className="enrollmentconfirmation clearfix">
            { confirmationTitle && <h1 className="caption personal-info-caption" id="headingenrollmentconfirmation" >{confirmationTitle}</h1>}
            {confirmationStatmentVisible &&
                <div className="clearfix mb-md-2">
                    <Fragment><div role='alert' className={confimationStatementMessageType === dangerType ? 'clearfix alert alert-danger' : 'clearfix pb-3 pb-md-4'} dangerouslySetInnerHTML={{ __html: confimationStatementMessage }}/></Fragment>
                    <ConfirmedItem
                        lblNewTabText ={lblNewTab.textToRead}
                        title={confimationStatementName}
                        buttonTitle={downloadButtonLabel}
                        navigationTo={confimationStatementNavigateTo}
                        backColor={simpleButtonColor}
                        confirmationStatment = {true}
                        navigateClicked={() => confirmationStatementButtonClicked()} />
                </div>
            }
            {beneficiaryFormsFormVisible &&
                <div className="clearfix">
                    <Fragment><div role='alert' className={beneficiaryFormMessageType === dangerType ? 'clearfix alert alert-danger m-0 mb-3' : 'clearfix pb-4'} dangerouslySetInnerHTML={{ __html: beneficiaryFormMessage }}/></Fragment>
                    <ConfirmedItem
                        title={beneficiaryFormName}
                        buttonTitle={downloadButtonLabel}
                        navigationTo={beneficiaryFormNavigateTo}
                        backColor={simpleButtonColor}
                        navigateClicked={() => beneficiaryNavigationClicked(beneficiaryFormNavigateTo, beneficiaryFilename)} />
                </div>
            }
            {/* E-Signature Start */}
            {isEsignable && beneficiaryESign &&
                <div className="clearfix">
                    <Fragment>
                        <div role='alert' className={beneficiaryESign.messageType === dangerType ? 'clearfix alert alert-danger m-0 mb-3' : 'clearfix pb-4'} dangerouslySetInnerHTML={{ __html: beneficiaryESign.message }}/>
                    </Fragment>
                    <ConfirmedItem
                    title={beneficiaryESign.name}
                    buttonTitle={downloadButtonLabel}
                    navigationTo={beneficiaryESign.btnNavigateTo}
                    backColor={simpleButtonColor}
                    navigateClicked={() => beneficiaryNavigationClicked(beneficiaryESign.btnNavigateTo, beneficiaryESignFilename)} />
                </div>
            }
            {/* E-Signature End */}
            {eoiFormVisible &&
                <div className="clearfix m-0 p-md-3 card">
                    <Fragment><p  role='alert' className={messageTopType === dangerType ? 'clearfix alert alert-danger' : 'clearfix pb-4'}   dangerouslySetInnerHTML={{ __html: messageTop}} /></Fragment>
                    {eobEnabledList.map((item, index) => {
                        return (
                        <div className="clearfix pb-md-3" key={index}>
                            <div className="clearfix">
                            <EnrollmentBenefit
                                benefitTitle={item.name}
                                coverLetterTitle={coverLetterMessage}
                                coverLetterButton={downloadButtonLabel}
                                questionaireTitle={questionnaireLabel}
                                questionaireButton={downloadButtonLabel}
                                coverLetterFile={item.coverLetter}
                                btnNavigateTo={item.btnNavigateTo}
                                questionaireFile={item.questionnaire}
                                noCarrier={item.noCarrier}
                                noCarrierMessage={noCarrierMessageLabel}
                                backColor={eobFormButtonColor}  />
                            </div>
                        </div>);
                    })}
                    <Fragment><div role='alert' className={messageBottomType === dangerType ? 'clearfix alert alert-danger m-0' : 'clearfix pb-4'} dangerouslySetInnerHTML={{ __html: messageBottom }}/></Fragment>
                </div>
            }
            {rrspFormVisible &&
                <div className="clearfix" tid="rrspFormVisible">
                    <Fragment><div role='alert' className={rrspFormMessageType === dangerType ? 'clearfix alert alert-danger' : 'clearfix pb-4'} dangerouslySetInnerHTML={{ __html: rrspFormMessage }}/></Fragment>
                    <ConfirmedItem
                        title={rrspFormName}
                        buttonTitle={downloadButtonLabel}
                        navigationTo={rrspFormNavigateTo}
                        backColor={simpleButtonColor}
                        navigateClicked={() => rrspButtonClicked()}  />
                </div>
            }

            {lblBottomMessageVis &&
            <div className={`d-block pt-4 pl-4 pr-4`}>
                <p className="alert alert-info" role='alert'>
                    <span>
                        {lblBottomMessage}
                    </span>
                </p>
            </div>
            }

            {
            <div className="d-flex justify-content-between pt-5">
            <NextPrevious lblHome={homeButtonLabel} />
            {(finishEnrollmentData.fileUploadButtonlnk && showFileUploadButton )&& <DisplayButton controlId="buttonNavigate" className="outline-secondary btn-fileupload" displayName={finishEnrollmentData.fileUploadButtonLabel}  onClick={() => uploadButtonNavigateClicked()} />}
            </div>
            }
        </div>
    )
}

const mapStateToProps = state => {
    return {
        locale: state.contentReducer.locale,
        finishEnrollmentData: state.userReducer.finishEnrollment,
        contentFooter: state.userReducer.contentFooter.contentResource ? state.userReducer.contentFooter.contentResource.contents : {}
    };
};

export default connect(mapStateToProps, {
    finishEnrollment, goNext, downloadFinishedEnrollmentFile, dissmiss
})(withRouter(EnrollmentConfirmation));
