import React, { Component, Fragment } from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class DisplayButton extends Component {
  keyPressHandler = e => {
    if ((e.which === 13 || e.which === 32) && this.props.onClick) {
      e.preventDefault();
      this.props.onClick(e);
    }
  };

  render() {
    const { displayName, className, onClick, onKeyDown } = this.props;
    return (
      <Fragment>
        {!this.props.icon ? (
          <Button
            tid={this.props.controlId}
            name={this.props.name}
            type={this.props.type}
            key={this.props.controlId}
            id={this.props.id}
            variant={className}
            data-toggle={this.props.datatoggle}
            onClick={onClick}
            disabled={this.props.disabled}
            aria-label={this.props.arialabel} //use aria-lable for (x) icon popup button
            onKeyPress={this.keyPressHandler}
            onKeyDown={onKeyDown}
          >
            {displayName}
            {this.props.btnIcon ? (
              <span>
                <FontAwesomeIcon icon={this.props.btnIcon} />
              </span>
            ) : (
              ""
            )}
          </Button>
        ) : (
          ""
        )}

        {this.props.icon ? (
          <div tabIndex="0" role="button" onClick={this.props.onClick} onKeyPress={this.keyPressHandler}>
            <FontAwesomeIcon className={className} icon={this.props.icon} />
            <span className={`m-0 ml-3`}>{displayName}</span>
          </div>
        ) : (
          ""
        )}
      </Fragment>
    );
  }
}

export default DisplayButton;
