import React, { Component }  from 'react';
import { Form, Row } from 'react-bootstrap';
import { Modal, ModalBody } from 'reactstrap';
import { getDependents, deleteDependent, createDependent, updateDependent, updateStudentStatus,disableNext, enableNext, setNavigationError } from '../../actions/userActions' 
import { getDisplayText,setModalAttribute } from "../../utils";
import DependentDetail from './DependentDetail';
import NewDependentFrom from "./NewDependentFrom";
import { trackPromise } from 'react-promise-tracker';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import Defaults from '../../helpers/defaults'
import _ from 'underscore';
import ReactDOM from 'react-dom';
import DisplayButton from '../button';

class Dependents extends Component {
  constructor (props) {
    super(props);
    let dependentType = '';
    if (props.location.search) {
      let search = new URLSearchParams(this.props.location.search);
      dependentType = search.get('type') ? search.get('type') : '';
    }
    this.state = {
      showNewModal : false,
      disableNextCounter: 0,
      dependentType
    }
  }
  
  componentDidUpdate(){
    let dependentType = ''
    if (this.props.location.search) {
      let search = new URLSearchParams(this.props.location.search);
      dependentType = search.get('type') ? search.get('type') : '';
    }
    if (this.state.dependentType !== dependentType) {
      this.setState({dependentType}, () => {
        trackPromise(this.props.getDependents(dependentType));
      })
    }
  }
  shouldComponentUpdate(nextProps){
    const { nextEnrolStep } = this.props
    if ( !(nextEnrolStep.navigationError === undefined || nextEnrolStep.navigationError === null) ) {
      if(nextProps.pageSetting === this.props.pageSetting &&
        this.refs.errorMessage){
        ReactDOM.findDOMNode(this.refs.errorMessage).focus()   
      }
    }
    return true
}
  componentWillUnmount(){
    this.props.enableNext()
    const { nextEnrolStep } = this.props
    if(!(nextEnrolStep.navigationError === undefined || nextEnrolStep.navigationError === null))
    this.props.setNavigationError()
  }

  handleClose = () => {
    this.setState({ showNewModal: false });
  }

  handleShow = () => {
    this.setState({ showNewModal: true }); 
  }
  updateDependent = (dependent) => {
    trackPromise(this.props.updateDependent(dependent))
  }

  updateStudentStatus = (dependent) => {
    trackPromise(this.props.updateDependent(dependent))
  }

  //Temporary functions to disable Next Button, remove when changes proposed by Telus
  showNext = () => {
    this.setState({
      disableNextCounter: this.state.disableNextCounter - 1
    }, () => {
      if(this.state.disableNextCounter === 0) this.props.enableNext();
    })
  }
  hideNext = () => {
    this.setState({
      disableNextCounter: this.state.disableNextCounter + 1
    })
    this.props.disableNext();
  }

  render () {
    const { nextEnrolStep, locale } = this.props;
    const localestring = locale.substring(0,2)
    const { readOnly, smokerDeclarationLabel, spousalSmokerDeclarationOptional, isAllowUpdateSudentStatus } = this.props.dependents
    const contents1 = this.props.contentResource ? this.props.contentResource.contents : []
    const contents2 = (this.props.contentResource && this.props.contentResource.controlResource.DependentInfo) ? this.props.contentResource.controlResource.DependentInfo : []
    const contents = _.union(contents1, contents2)
    const lblAddDependentText = getDisplayText(contents, 'lblAddDependentText')
    const lblNoDependentsText = getDisplayText(contents, 'lblNoDependentsText')
    const lblYoucanaddordeleteyourdependent = getDisplayText(contents, 'lblYoucanaddordeleteyourdependent')
    const closeButtonText = getDisplayText((this.props.contentFooter.contentResource && this.props.contentFooter.contentResource.contents) || {}, 'btnClose')
    //const lblN
    let dependentsComponent = []
    const dependents = [...this.props.dependents.result.Dependents]
    const canBeModified = this.props.dependents.isCoveredThisPlanEditable || this.props.dependents.isCoveredOtherPlanEditable
    let canBeRemoved = true //this.props.dependents.canBeRemoved //TODO: they dont have this now, Update, Fix later
    if(this.state.dependentType === 'updatestudent') {
      canBeRemoved = false;
    }
    const smokerStatusList = this.props.dependents.smokerStatusList
    const genderList = this.props.dependents.genderList
    const relationshipList = this.props.dependents.relationshipList
    const relationshipListDisplay = this.props.dependents.relationshipListDisplay ? this.props.dependents.relationshipListDisplay : []
    const me = this
    _.each(dependents, (item, index)=>{

      dependentsComponent.push(<DependentDetail keyy={`d-div-${item.dependentID}-${index}`}
                                                key={`d-div-${item.dependentID}-${index}`}
                                                index={index}
                                                tabIndexValue={0}
                                                deleteDependent = {me.props.deleteDependent}
                                                getDependents = {me.props.getDependents}
                                                dependentId={item.dependentID}
                                                relationshipListDisplay={relationshipListDisplay}
                                                relationshipList={relationshipList} 
                                                smokerStatusList={smokerStatusList} 
                                                genderList={genderList} 
                                                contents={contents} 
                                                canBeModified={canBeModified} 
                                                isCoveredThisPlanEditable={this.props.dependents.isCoveredThisPlanEditable} 
                                                isCoveredOtherPlanEditable={this.props.dependents.isCoveredOtherPlanEditable} 
                                                canBeRemoved={canBeRemoved}
                                                updateDependent={this.props.updateDependent}
                                                updateStudentStatus={this.props.updateStudentStatus}
                                                dependent={item}
                                                readOnly={readOnly}
                                                smokerDeclarationLabel={smokerDeclarationLabel}
                                                spousalSmokerDeclarationOptional={spousalSmokerDeclarationOptional}
                                                disableNext={this.hideNext}
                                                enableNext={this.showNext}
                                                setNavigationError={this.props.setNavigationError}
                                                navigationError={nextEnrolStep.navigationError}
                                                contentFooter={this.props.contentFooter}
                                                dependentType={this.state.dependentType}
                                                isAllowUpdateSudentStatus={isAllowUpdateSudentStatus}
                                                />)
    })
    return (<>
      {!this.props.hasDependents && readOnly && <Form.Label> <span  dangerouslySetInnerHTML={{__html: lblNoDependentsText.display}} /></Form.Label>}
      <Row>
        {!readOnly && lblYoucanaddordeleteyourdependent.display && <div className="alert alert-light" role='alert'  dangerouslySetInnerHTML={{__html: lblYoucanaddordeleteyourdependent.display}} />}
        {dependentsComponent}
        {!(nextEnrolStep.navigationError === undefined || nextEnrolStep.navigationError === null) && <div className="alert alert-danger w-100" tabIndex="-1"   ref="errorMessage" dangerouslySetInnerHTML={{__html: nextEnrolStep.navigationError}} />}
        <div className="ml-popupbuttons clearfix w-100 px-3">
        {(!readOnly && this.state.dependentType === '') &&
          <DisplayButton className="outline-secondary btn-addnewdependent float-right" displayName={lblAddDependentText.display} onClick={this.handleShow}   />
          }
          <Modal onOpened={() => setModalAttribute(lblAddDependentText.textToRead)} centered aria-labelledby="modalHeader" isOpen={this.state.showNewModal} toggle={this.handleClose} className="ml-addnewdependent">
            <div className="modal-header">
            <h1 className="modal-title" id='modalHeader'>
            {lblAddDependentText.display}</h1>
             <DisplayButton type="button"  className="close" displayName="×" arialabel={closeButtonText.display} onClick={this.handleClose}/> 
            </div>
            <ModalBody>
              <NewDependentFrom
                localestring={localestring}
                smokerStatusList={smokerStatusList}
                genderList={genderList}
                relationshipList={relationshipList}
                contents={contents}
                dependents={this.props.dependents}
                isAllowUpdateSudentStatus={isAllowUpdateSudentStatus}
                getDependents = {this.props.getDependents}
                keyy="new-dependent"
                title={lblAddDependentText.display}
                createDependent={this.props.createDependent} 
                onClose={this.handleClose}
                contentFooter={this.props.contentFooter}
                setNavigationError={this.props.setNavigationError}
                navigationError={nextEnrolStep.navigationError}/>
              </ModalBody>
          </Modal>
        </div>
      </Row>
    </>);
  }
  UNSAFE_componentWillMount = () => {
    const { nextEnrolStep } = this.props
    trackPromise(this.props.getDependents(this.state.dependentType))
    if(!(nextEnrolStep.navigationError === undefined || nextEnrolStep.navigationError === null))
    this.props.setNavigationError(null)
  }
}
const mapStateToProps = (state) => {
  return  { locale: state.contentReducer.locale, 
            hasDependents: state.userReducer.dependents.result.Dependents.length ? true : false,
            dependents: state.userReducer.dependents ? state.userReducer.dependents : Defaults.dependent,
            contentResource: state.userReducer.dependents.contentResource,
            contentFooter: state.userReducer.contentFooter,
            nextEnrolStep: state.userReducer.nextEnrolStep
          }
};
  
export default connect(
mapStateToProps,
{ getDependents, deleteDependent, createDependent, updateDependent, updateStudentStatus, disableNext, enableNext, setNavigationError}
)(withRouter(Dependents));
