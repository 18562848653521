import React, {Component,Fragment } from 'react';
import { getDisplayText,setModalAttribute } from '../utils';
import { Modal, ModalBody } from 'reactstrap';
import DisplayButton from './button';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

class  WarningMessageModal extends Component {

  keyPressHandler = (e) => {
    if(e.which===13 && this.props.clickAction)
    { e.preventDefault();
      this.props.clickAction(e)
    } 
  }

render() {
      const contentFooter = this.props.contentFooter;
      const yesButtonLabel = getDisplayText(contentFooter, 'lblYes');
      const noButtonLabel = getDisplayText(contentFooter, 'lblNo');
      const lblWarningMessage=getDisplayText(contentFooter, 'lblWarningMessage');
      const lblWarningMessageTitle=getDisplayText(contentFooter, 'lblWarningMessageTitle');
    
        return(
            <Fragment>
            <Modal onOpened={() => setModalAttribute(lblWarningMessageTitle)} aria-labelledby="modalHeading" aria-describedby="modalDescription" className="ml-cancelenrollment" isOpen={this.props.showWarningModal} toggle={this.props.handleClose} centered>
                <div className="modal-header">
                    <h1 className="modal-title" id='modalHeading'>
                        {lblWarningMessageTitle.display}
                    </h1>
                    <DisplayButton type="button" className="close" displayName="×" onClick={this.props.handleClose} />
                </div>
                <ModalBody>
                    <div className="ml-popupmessage" id='modalDescription'>
                        <p className="ml-popupmessagetext" dangerouslySetInnerHTML={{ __html: lblWarningMessage.display }}></p>
                    </div>
                    <div className={'ml-buttons d-flex justify-content-between'}>
                        <DisplayButton controlId="handleClose" displayName={noButtonLabel.display}
                            className="outline-secondary btn-sm btn-cancel"
                            onClick={this.props.handleClose}
                        />
                        <DisplayButton controlId="handleCancel"
                            displayName={yesButtonLabel.display}
                            className="outline-secondary btn-sm btn-close"
                            onClick={this.props.clickAction}
                            onKeyDown={this.keyPressHandler}
                        />
                    </div>
                </ModalBody>
            </Modal>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
           contentFooter: state.userReducer.contentFooter.contentResource ? state.userReducer.contentFooter.contentResource.contents : {},
        }
  };
  
export default connect(mapStateToProps)(WarningMessageModal);
