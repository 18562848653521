import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getSummaryPersonal, setPageSetting, getProfile } from '../actions/userActions';
import { dissmiss } from '../actions/contentActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { Card, Row, Col } from 'react-bootstrap';
import { getDisplayText } from '../utils';
import { trackPromise } from 'react-promise-tracker';
import DisplayButton from './button';
import WarningMessageModal from './WarningMessageModal'

import _ from 'underscore';

class SummaryPersonal extends Component {

    constructor(props) {
		super(props)
        this.state = { showWarningModal: false,actionLink:''}
		
    }
    componentDidMount() {
        trackPromise(this.props.getSummaryPersonal())
        trackPromise(this.props.getProfile(true))
    }
    componentWillUnmount(){
        this.props.dissmiss()
    }
    handleEditAction = (link='') => {
        link=this.state.actionLink?this.state.actionLink:link;
        const menuItem = _.find(this.props.sideBarMenu, item => item.link === link)
        console.log("TEST FLOW: ", menuItem)
        this.props.setPageSetting(menuItem ? menuItem.name : "WELCOME", true);
        this.props.history.push(`/${link}`);
    }
    handleWarningModal = (link='') => { 
        if(this.props.showWarningMessage){
            this.setState({showWarningModal: true,actionLink:link})
        }
        else {this.handleEditAction(link)}
    }
    handleClose=() =>{
        this.setState({showWarningModal: false,actionLink:''})  
    }
    render() {
        const pageTitle = this.props.summaryPersonal.tabName
        const smokerStatus = this.props.summaryPersonal.smokerStatusText
        const isbtnEditPersonalVisible = this.props.summaryPersonal.isbtnEditPersonalVisible
        const actionLink = this.props.summaryPersonal.actionlink

        const changeNote = this.props.personalInfo.changeNote
        const maritalStatus = this.props.personalInfo.maritalStatusText
        const profileInfo = this.props.personalInfo.result ? this.props.personalInfo.result : {}
        const employeeName = this.props.personalInfo.employeeNameText
        const birthDate = profileInfo.dateOfBirth.display
        const address = profileInfo.address
        const gender = profileInfo.gender
        const language = this.props.personalInfo.languageText
        const homePhone = profileInfo.homePhone
        const workPhone = profileInfo.workPhone
        const homeEmail = profileInfo.personalEmail
        const workEmail = profileInfo.workEmail

        const contents = this.props.personalInfo.contentResource ? this.props.personalInfo.contentResource.controlResource.PersonalInfo : {}
        const lblBirthDate = getDisplayText(contents, 'lblBirthDate')
        const lblGender = getDisplayText(contents, 'lblGender')
        const lblLanguage = getDisplayText(contents, 'lblLanguage')
        const lblMaritalStatus = getDisplayText(contents, 'lblMaritalStatus')
        const lblAddress = getDisplayText(contents, 'lblEditAddress')
        const lblHomePhone = getDisplayText(contents, 'lblHomePhone')
        const lblWorkPhone = getDisplayText(contents, 'lblWorkPhone')
        const lblHomeEmail = getDisplayText(contents, 'lblPersonalEmail')
        const lblWorkEmail = getDisplayText(contents, 'lblWorkEmail')
        const lblEmailHeader = getDisplayText(contents, 'pnlEmailGroupingText')
        const lblPhoneHeader = getDisplayText(contents, 'pnlPhoneGroupingText')
        const editIcon = getDisplayText((this.props.contentFooter.contentResource && this.props.contentFooter.contentResource.contents) || {}, 'EditIcon')
        const backButtonText = getDisplayText((this.props.contentFooter.contentResource && this.props.contentFooter.contentResource.contents) || {},'btnBack')
        document.title = pageTitle
        return (<div className="summary summarypersonal clearfix">
            <div className={`d-flex titlewithicon mb-3`}> 
                <DisplayButton className="icon-action" displayName={backButtonText.display} icon={faChevronLeft} onClick={() => { this.props.history.push("/Summary")}} /> 
            </div>
            <div className="ml-description">
                <p className="mb-4"  dangerouslySetInnerHTML={{__html: changeNote}} />
            </div>
            <Card>
                <Card.Header>
                    <h2 className="float-left" dangerouslySetInnerHTML={{__html: employeeName}}></h2>
                    {isbtnEditPersonalVisible && <span className="float-right icon-action" tabIndex='0' role='button' aria-label={editIcon.textToRead +" "+ employeeName} onClick={() => {this.handleWarningModal(actionLink)}}>
                        <FontAwesomeIcon icon={faPencilAlt}/>
                    </span>}
                </Card.Header>
                <Card.Body className="personal-info-holder">
                    <Row className="mb-2"><Col><span>{lblBirthDate.display}</span> {birthDate}</Col></Row>
                    <Row className="mb-2"><Col><span>{lblGender.display}</span> {gender}</Col></Row>
                    <Row className="mb-2"><Col><span>{lblMaritalStatus.display}</span> {maritalStatus}</Col></Row>
                    <Row className="mb-2"><Col><span>{lblAddress.display}</span> {address.address1} {address.address2} {address.city} {address.provinceCode} {address.postalCode} {address.countryCode}</Col></Row>
                    <Row><Col><span>{lblLanguage.display}</span> {language}</Col></Row>
                </Card.Body>
                <Card.Footer>
                    <span dangerouslySetInnerHTML={{__html: smokerStatus}} />
                </Card.Footer>
            </Card>
            <Card>
                <Card.Header>
                    <h2 className="float-left" dangerouslySetInnerHTML={{__html: lblPhoneHeader.display}}></h2>
                    {isbtnEditPersonalVisible && <span className="float-right icon-action" tabIndex='0' role='button' aria-label={editIcon.textToRead +" "+ lblPhoneHeader.textToRead} onClick={() => {this.handleWarningModal(actionLink)}}>
                        <FontAwesomeIcon icon={faPencilAlt}/>
                    </span>}
                </Card.Header>
                <Card.Body className="personal-info-holder">
                    <Row className="mb-2"><Col><span>{lblWorkPhone.display}</span> {workPhone.areaCode} {workPhone.phoneNumber} {workPhone.extension}</Col></Row>
                    <Row><Col><span>{lblHomePhone.display}</span> {homePhone.areaCode} {homePhone.phoneNumber}</Col></Row>
                </Card.Body>
            </Card>
            <Card>
                <Card.Header>
                    <h2 className="float-left" dangerouslySetInnerHTML={{__html: lblEmailHeader.display}}></h2>
                    {isbtnEditPersonalVisible && <span className="float-right icon-action" tabIndex='0' role='button' aria-label={editIcon.textToRead +" "+ lblEmailHeader.textToRead} onClick={() => {this.handleWarningModal(actionLink)}}>
                        <FontAwesomeIcon icon={faPencilAlt}/>
                    </span>}
                </Card.Header>
                <Card.Body className="personal-info-holder">
                    <Row className="mb-2"><Col><span>{lblWorkEmail.display}</span> {workEmail}</Col></Row>
                    <Row><Col><span>{lblHomeEmail.display}</span> {homeEmail}</Col></Row>
                </Card.Body>
            </Card>
            <WarningMessageModal showWarningModal={this.state.showWarningModal} clickAction={this.handleEditAction} handleClose={this.handleClose} />
        </div>);
    }
}

const mapStateToProps = (state) => {
    return {summaryPersonal: state.userReducer.summaryPersonal ? state.userReducer.summaryPersonal : {},
            contentFooter: state.userReducer.contentFooter,
            personalInfo: state.userReducer.personalInfo,
            sideBarMenu: state.contentReducer.screens,
            showWarningMessage:state.contentReducer.showWarningMessage	
        }
};

export default connect(
    mapStateToProps, { getSummaryPersonal, setPageSetting, getProfile, dissmiss }
)(withRouter(SummaryPersonal));

