import React, { Component, Fragment } from "react";
import { Row, Form, Col } from "react-bootstrap";
import { getDisplayText,getControlId } from "../utils";
import { connect } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import _ from "underscore";
import DisplayButton from './button';

import {
  saveWaiverForm,
  saveDependentWaiverForm
} from "../actions/userActions";

class MedicalWaiver extends Component {
  constructor(props) {
    super(props);
    const {
      waiverBenefits: propWaiverBenefits,
      optOutWaiverBenefits
    } = this.props.benefit;
    const { selectedOption, isOptOut } = this.props;
    let optOutBenefits = null;
    if (optOutWaiverBenefits && isOptOut)
      optOutBenefits = (optOutWaiverBenefits.length &&
        optOutWaiverBenefits) || [optOutWaiverBenefits];
    const waveOptionsOrBenefits =
      (selectedOption.showWaiveOption && optOutBenefits) || propWaiverBenefits;
    const waiverBenefits =
      waveOptionsOrBenefits &&
      waveOptionsOrBenefits.map(item => ({
        ...item,
        isSelected:
          (waveOptionsOrBenefits && waveOptionsOrBenefits.length === 1) ||
          item.isSelected,
        waiverReason: {
          value: item.waiverReason || "",
          hasError: !item.waiverReason
        },
        insuranceCompany: {
          value: item.insuranceCompany || "",
          hasError: !item.insuranceCompany
        },
        policyNumber: {
          value: item.policyNumber || "",
          hasError: !item.policyNumber
        }
      }));
    this.state = {
      formValidated: false,
      formSubmitted: false,
      waiverBenefits,
      selectedOption
    };
  }

  componentDidMount() {
    this.handlecheckbox();
  }
  submitHandler = event => {
    event.preventDefault();
    const { waiverBenefits } = this.state;
    let isValid = true;
    waiverBenefits.forEach(item => {
      const { waiverReason, insuranceCompany, policyNumber, isSelected } = item;
      const isFieldValid =
        !waiverReason.hasError &&
        !insuranceCompany.hasError &&
        !policyNumber.hasError;
      if (isSelected && !isFieldValid) isValid = false;
    });
    if (!isValid) {
      return this.setState({
        waiverBenefits,
        formValidated: false,
        formSubmitted: true
      });
    }
    this.setState({ formValidated: true, formSubmitted: true });
    this.handleSaveForm();
  };

  handleSaveForm = () => {
    const { isOptOut } = this.props;
    const { waiverBenefits, selectedOption } = this.state;
    const packageWaiverObj = waiverBenefits.map(item => ({
      optionID: selectedOption && selectedOption.optionID,
      benefitID: item.benefitID,
      benefitName: item.benefitName,
      isSelected: item.isSelected,
      waiverReason: !this.props.showAlternateCoverageDetails? item.waiverReason.value.replace('_PACKAGEDEPENDENTWAIVER',''):(item.waiverReason.value.indexOf("_PACKAGEDEPENDENTWAIVER")<=-1?item.waiverReason.value+'_PACKAGEDEPENDENTWAIVER':item.waiverReason.value),
      insuranceCompany: item.insuranceCompany.value,
      policyNumber: item.policyNumber.value
    }));
    if (isOptOut) {
      const waiverOptOutObj = packageWaiverObj.map(
        ({
          optionID,
          benefitID,
          waiverReason,
          insuranceCompany,
          policyNumber
        }) => ({
          optionID,
          benefitID,
          waiverReason,
          insuranceCompany,
          policyNumber
        })
      );
      if (this.props.benefitURL) 
        trackPromise(
          this.props.saveDependentWaiverForm(null, null, null, waiverOptOutObj, this.props.benefitURL)
        );
      else 
        trackPromise(
          this.props.saveDependentWaiverForm(null, null, null, waiverOptOutObj)
        );
    } else {
      if (this.props.benefitURL)
        trackPromise(
          this.props.saveWaiverForm(null, null, null, packageWaiverObj, this.props.benefitURL)
        );
      else
        trackPromise(
          this.props.saveWaiverForm(null, null, null, packageWaiverObj)
        );
    }
    this.props.handleClose();
  };

  changeValue = (index, name, value) => {
    const { waiverBenefits } = this.state;
    waiverBenefits[index][name]["value"] = value;
    waiverBenefits[index][name]["hasError"] = !value;
    this.setState({
      waiverBenefits
    });
  };

  handleCheckboxChange = index => {
    const { waiverBenefits } = this.state;
    waiverBenefits[index].isSelected = !waiverBenefits[index].isSelected;
    return this.setState({ waiverBenefits });
  };

handlecheckbox =()=> {
  const { waiverBenefits } = this.state;
  _.each(waiverBenefits, (item,index) => {
    if(this.props.showAlternateCoverageDetails){
    waiverBenefits[index].isSelected=true;
    }
    else if(item.waiverReason.value.indexOf("_PACKAGEDEPENDENTWAIVER")>0)
    {
      waiverBenefits[index].isSelected=true;
    }
  });
  return this.setState({ waiverBenefits });
}

  isInvalid = (item, fieldName) => {
    const { formSubmitted } = this.state;
    return formSubmitted && item.isSelected && item[fieldName].hasError;
  };

  render() {
    const { isOptOut } = this.props;
    const props = this.props.benefit;
    const contents = props.contentResource.contents;
    const btnLblSave = getDisplayText(contents, "btnSave");
    const btnLblCancel = getDisplayText(contents, "btnCancel");
    const lblDependentCoverageWaiverHeading=getDisplayText(contents, "lblDependentCoverageWaiverHeading");
    const lblWaiveCoverageDialogDescription = getDisplayText(
      contents,
      (isOptOut && "lblWaiveOptionDialogDescription") ||
        "lblWaiveCoverageDialogDescription"
    );
    const lblWaiveCoverageDialogReason = getDisplayText(contents, "lblReason");
    const lblWaiveCoverageDialogInsuranceCompany = getDisplayText(
      contents,
      "lblInsuranceCompany"
    );
    const lblWaiveCoverageDialogPolicy = getDisplayText(contents, "lblPolicy");
    const lblWaiveCoverageDialogNote = getDisplayText(
      contents,
      "lblWaiveCoverageDialogNote"
    );

    const rfvtxtWaiveCoverageReasonErrorMessage = getDisplayText(
      contents,
      "rfvtxtReason"
    );
    const rfvtxtWaiveCoverageInsuranceCompanyErrorMessage = getDisplayText(
      contents,
      "rfvtxtInsuranceCompany"
    );
    const rfvtxtWaiveCoveragePolicyErrorMessage = getDisplayText(
      contents,
      "rfvtxtPolicy"
    );
    const { waiverBenefits, formValidated } = this.state;
    return (
      <div className={"medicalwaiver"}>
        <div className="ml-description mb-3">
          <p
            tabIndex="0"
            className={`m-0`}
            dangerouslySetInnerHTML={{ __html: !this.props.showAlternateCoverageDetails?lblWaiveCoverageDialogDescription.display:lblDependentCoverageWaiverHeading.display}}
          >
          </p>
        </div>
        <Form
          noValidate
          validated={formValidated}
          onSubmit={this.submitHandler}
        >
          {waiverBenefits &&
            waiverBenefits.map((item, index) => (
              <div key={index}>
                <Row className="mb-4">
                  <Col xs="12" sm="12" md="3=12">
                    <Form.Label className={`m-0 p-0`}>
                      {waiverBenefits && !this.props.showAlternateCoverageDetails && waiverBenefits.length > 1 && (
                        <input
                          type="checkbox"
                          checked={item.isSelected}
                          onChange={() => this.handleCheckboxChange(index)}
                        />
                      )}{" "}
                      <b> {item.benefitName}</b>
                    </Form.Label>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col xs="12" sm="5" md="3">
                    <Form.Label className={`m-0 p-0`} htmlFor={getControlId(lblWaiveCoverageDialogReason.textToRead)}>
                      {lblWaiveCoverageDialogReason.display}
                    </Form.Label>
                  </Col>
                  <Col xs="12" sm="7" md="9" className="ml-inputfields">
                    <Form.Control
                      tid="waiverReason"
                      type="text"
                      id={getControlId(lblWaiveCoverageDialogReason.textToRead)}
                      placeholder={lblWaiveCoverageDialogReason.display}
                      value={item.waiverReason.value.indexOf("_PACKAGEDEPENDENTWAIVER")>=0?item.waiverReason.value.replace('_PACKAGEDEPENDENTWAIVER',''):item.waiverReason.value}
                      onChange={evt =>
                        this.changeValue(
                          index,
                          "waiverReason",
                          evt.target.value
                        )
                      }
                      isInvalid={this.isInvalid(item, "waiverReason")}
                      className={`m-0`}
                    />
                    {item.waiverReason.hasError && (
                      <Form.Control.Feedback
                        role='alert'
                        type="invalid"
                      >
                        {rfvtxtWaiveCoverageReasonErrorMessage.display}
                      </Form.Control.Feedback>
                    )}
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col xs="12" sm="5" md="3">
                    <Form.Label className={`m-0 p-0`} htmlFor={getControlId(lblWaiveCoverageDialogInsuranceCompany.textToRead)}>
                      {lblWaiveCoverageDialogInsuranceCompany.display}
                    </Form.Label>
                  </Col>
                  <Col xs="12" sm="7" md="9" className="ml-inputfields">
                    <Form.Control
                      id={getControlId(lblWaiveCoverageDialogInsuranceCompany.textToRead)}
                      type="text"
                      placeholder={lblWaiveCoverageDialogInsuranceCompany.display}
                      value={item.insuranceCompany.value}
                      onChange={evt =>
                        this.changeValue(
                          index,
                          "insuranceCompany",
                          evt.target.value
                        )
                      }
                      isInvalid={this.isInvalid(item, "insuranceCompany")}
                      className={`m-0`}
                    />
                    {item.insuranceCompany.hasError && (
                      <Form.Control.Feedback
                        role='alert'
                        type="invalid"
                      >
                        {
                          rfvtxtWaiveCoverageInsuranceCompanyErrorMessage.display
                        }
                      </Form.Control.Feedback>
                    )}
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col xs="12" sm="5" md="3">
                    <Form.Label className={`m-0 p-0`} htmlFor={getControlId(lblWaiveCoverageDialogPolicy.textToRead)}>
                      {lblWaiveCoverageDialogPolicy.display}
                    </Form.Label>
                  </Col>
                  <Col xs="12" sm="7" md="9" className="ml-inputfields">
                    <Form.Control
                      name="policyNumber"
                      type="text"
                      id={getControlId(lblWaiveCoverageDialogPolicy.textToRead)}
                      placeholder={lblWaiveCoverageDialogPolicy.display}
                      value={item.policyNumber.value}
                      onChange={evt =>
                        this.changeValue(
                          index,
                          "policyNumber",
                          evt.target.value
                        )
                      }
                      isInvalid={this.isInvalid(item, "policyNumber")}
                      className={`m-0`}
                    />
                    {item.policyNumber.hasError && (
                      <Form.Control.Feedback
                        role='alert'
                        type="invalid"
                      >
                        {rfvtxtWaiveCoveragePolicyErrorMessage.display}
                      </Form.Control.Feedback>
                    )}
                  </Col>
                </Row>
              </div>
            ))}
          <Row className="mb-4">
            <Col>
              <Fragment>
                <p
                  tabIndex="0"
                  className={`m-0`}
                  dangerouslySetInnerHTML={{
                    __html: lblWaiveCoverageDialogNote.display
                  }}
                />
              </Fragment>
            </Col>
          </Row>
          <div className="d-flex justify-content-between">
            <DisplayButton
              className="outline-secondary"
              type="button"
              onClick={() => this.props.handleClose()}
              displayName= {btnLblCancel.display}
            />
            {this.state.waiverBenefits &&
              <DisplayButton className="secondary" type="submit" displayName={btnLblSave.display} />
            }
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    waiverFormResponse: state.userReducer.waiverFormResponse
      ? state.userReducer.waiverFormResponse
      : {}
  };
};

export default connect(mapStateToProps, {
  saveWaiverForm,
  saveDependentWaiverForm
})(MedicalWaiver);
