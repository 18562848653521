import React, { Component } from 'react';
import { withRouter} from 'react-router-dom';
import { connect } from "react-redux";
import { getContentHelpMenu } from '../actions/userActions';
import { dissmiss } from '../actions/contentActions'
class Help extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.props.getContentHelpMenu(this.props.pageName ? this.props.pageName : "");
  }
  componentWillUnmount(){
    this.props.dissmiss()
  }

  render () {
      return (
          <div className="container" dangerouslySetInnerHTML={{__html: this.props.content.content}} />
      );
  }
}
const mapStateToProps = (state) => {
  return {
      content: state.userReducer.contentHelpMenu ? state.userReducer.contentHelpMenu.result : {}
  }
}

export default connect(
  mapStateToProps,
  { getContentHelpMenu, dissmiss }
)(withRouter(Help));