import React, { Component, Fragment}  from 'react';
import ReactDOM from 'react-dom'
import { Row, Form, Col } from 'react-bootstrap';
import { getDisplayText,getControlId } from '../utils';
import { connect } from "react-redux";
import { trackPromise } from 'react-promise-tracker';
import DisplayButton from './button';
import { saveWaiverForm, saveDependentWaiverForm } from '../actions/userActions';

class MedicalWaiver extends Component {
  constructor (props) {
    super(props);
    this.state = {
        waiverReason: '',
        insuranceCompany: '',
        policyNumber: '',
        formValidated: false
    }
  }

  submitHandler = event => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      if(ReactDOM.findDOMNode(this.refs.reasonForWaiver) && ReactDOM.findDOMNode(this.refs.reasonForWaiver).value === ""){
        ReactDOM.findDOMNode(this.refs.reasonForWaiver).focus();  
      }else if(ReactDOM.findDOMNode(this.refs.insuranceCompany) && ReactDOM.findDOMNode(this.refs.insuranceCompany).value === ""){
        ReactDOM.findDOMNode(this.refs.insuranceCompany).focus(); 
      }else if(ReactDOM.findDOMNode(this.refs.policyNumber) && ReactDOM.findDOMNode(this.refs.policyNumber).value === ""){
        ReactDOM.findDOMNode(this.refs.policyNumber).focus(); 
      }
    } else {
        event.preventDefault();
        this.handleSaveForm()
    }
    
    this.setState({formValidated: true})
  };

  handleSaveForm = () => {
    const waiverReason = this.state.waiverReason
    const insuranceCompany = this.state.insuranceCompany
    const policyNumber = this.state.policyNumber
    if (this.props.benefitURL)
    this.props.type 
    ? trackPromise(this.props.saveWaiverForm(waiverReason, insuranceCompany, policyNumber, null, this.props.benefitURL)) 
    : trackPromise(this.props.saveDependentWaiverForm(waiverReason, insuranceCompany, policyNumber, null, this.props.benefitURL))
else 
  this.props.type 
    ? trackPromise(this.props.saveWaiverForm(waiverReason, insuranceCompany, policyNumber)) 
    : trackPromise(this.props.saveDependentWaiverForm(waiverReason, insuranceCompany, policyNumber))

    this.props.handleClose()
  }

  changeValue = event => {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    
    const props = this.props.benefit
    const type = this.props.type
    const showAlternateCoverageDetails=this.props.showAlternateCoverageDetails;
    const contents = props.contentResource.contents

    const lblPlanStartDate = props.lblPlanStartDate
    const btnLblSave = getDisplayText(contents, 'btnSave')
    const btnLblCancel = getDisplayText(contents, 'btnCancel')

    const lblWaiveCoverageDialogDescription = getDisplayText(contents, 'lblWaiveCoverageDialogDescription')
    const lblWaiveCoverageDialogPlanStartDate = getDisplayText(contents, 'lblWaiveCoverageDialogPlanStartDate')
    const lblWaiveCoverageDialogReason = getDisplayText(contents, 'lblWaiveCoverageDialogReason')
    const lblWaiveCoverageDialogForProof = getDisplayText(contents, 'lblWaiveCoverageDialogForProof')
    const lblWaiveCoverageDialogInsuranceCompany = getDisplayText(contents, 'lblWaiveCoverageDialogInsuranceCompany')
    const lblWaiveCoverageDialogPolicy = getDisplayText(contents, 'lblWaiveCoverageDialogPolicy')
    const lblWaiveCoverageDialogNote = getDisplayText(contents, 'lblWaiveCoverageDialogNote')

    const lblWaiveOptionDialogDescription = getDisplayText(contents, 'lblWaiveOptionDialogDescription')
    const lblWaiveOptionDialogReason = getDisplayText(contents, 'lblWaiveOptionDialogReason')
    const lblWaiveOptionDialogProofCoverage = getDisplayText(contents, 'lblWaiveOptionDialogProofCoverage')
    const lblWaiveOptionDialogCompany = getDisplayText(contents, 'lblWaiveOptionDialogCompany')
    const lblWaiveOptionDialogPolicy = getDisplayText(contents, 'lblWaiveOptionDialogPolicy')
    const lblWaiveOptionDialogNote = getDisplayText(contents, 'lblWaiveOptionDialogNote')
    const lblAlternateCoverageDetailsNote = getDisplayText(contents, 'lblAlternateCoverageDetailsNote') 

    const rfvtxtWaiveCoverageReasonErrorMessage = getDisplayText(contents, 'rfvtxtWaiveCoverageReasonErrorMessage')
    const rfvtxtWaiveCoverageInsuranceCompanyErrorMessage = getDisplayText(contents, 'rfvtxtWaiveCoverageInsuranceCompanyErrorMessage')
    const rfvtxtWaiveCoveragePolicyErrorMessage = getDisplayText(contents, 'rfvtxtWaiveCoveragePolicyErrorMessage')

    const rfvtxtWaiveOptionDialogReasonErrorMessage = getDisplayText(contents, 'rfvtxtWaiveOptionDialogReasonErrorMessage')
    const rfvtxtWaiveOptionDialogCompanyErrorMessage = getDisplayText(contents, 'rfvtxtWaiveOptionDialogCompanyErrorMessage')
    const rfvtxtWaiveOptionDialogPolicyErrorMessage = getDisplayText(contents, 'rfvtxtWaiveOptionDialogPolicyErrorMessage')
    return (
        <div className={'medicalwaiver'}>
            <div className="ml-description mb-md-3">
                <p  className={`m-0`}>{showAlternateCoverageDetails?lblAlternateCoverageDetailsNote.display:(type? lblWaiveCoverageDialogDescription.display : lblWaiveOptionDialogDescription.display)}</p>
            </div>
            <Form   noValidate validated={this.state.formValidated} onSubmit={this.submitHandler}>
                { type || showAlternateCoverageDetails && <Row className="mb-md-3">
                  <Col xs="6" md="3">
                    <Form.Label htmlFor={getControlId(lblWaiveCoverageDialogPlanStartDate.textToRead)} className={`m-0 p-0`}>{lblWaiveCoverageDialogPlanStartDate.display}</Form.Label>
                  </Col>
                  <Col xs="6" md="9">
                    <Form.Control id={getControlId(lblWaiveCoverageDialogPlanStartDate.textToRead)}  plaintext readOnly defaultValue={lblPlanStartDate} />
                  </Col>
                </Row>}
                {!showAlternateCoverageDetails && <Row className="mb-md-4 rowgroup" controlid="waiverReason">
                  <Col xs="12" sm="5" md="3">
                    <Form.Label htmlFor={getControlId((type) ? lblWaiveCoverageDialogReason.textToRead : lblWaiveOptionDialogReason.textToRead)} className={`m-0 p-0`}>{ type ? lblWaiveCoverageDialogReason.display : lblWaiveOptionDialogReason.display}</Form.Label>
                  </Col>
                  <Col xs="12" sm="7" md="9" className="ml-inputfields">
                    <Form.Control id={getControlId((type) ? lblWaiveCoverageDialogReason.textToRead : lblWaiveOptionDialogReason.textToRead)}   placeholder={(type) ? lblWaiveCoverageDialogReason.display : lblWaiveOptionDialogReason.display} ref="reasonForWaiver" name="waiverReason" value={this.state.waiverReason} onChange={this.changeValue} required type="text" className={`m-0`}/>
                    <Form.Control.Feedback  role='alert' ref="reasonForWaiverError"  type="invalid">{ type ? rfvtxtWaiveCoverageReasonErrorMessage.display : rfvtxtWaiveOptionDialogReasonErrorMessage.display}</Form.Control.Feedback>
                  </Col>
                </Row>}
                {!showAlternateCoverageDetails && <Row className="mb-md-4 rowgroup">
                  <Col>
                    <h2 className={"m-0 waiversubtitle"}>{type ? lblWaiveCoverageDialogForProof.display : lblWaiveOptionDialogProofCoverage.display}</h2>
                  </Col>
                </Row>}
                <Row className="mb-md-4 rowgroup">
                  <Col xs="12" sm="5" md="3">
                    <Form.Label htmlFor={getControlId(type ? lblWaiveCoverageDialogInsuranceCompany.textToRead : lblWaiveOptionDialogCompany.textToRead)} className={`m-0 p-0`} >{type ? lblWaiveCoverageDialogInsuranceCompany.display : lblWaiveOptionDialogCompany.display}</Form.Label>
                  </Col>
                  <Col xs="12" sm="7" md="9" className="ml-inputfields">
                    <Form.Control id={getControlId(type ? lblWaiveCoverageDialogInsuranceCompany.textToRead : lblWaiveOptionDialogCompany.textToRead)}  placeholder={type ? lblWaiveCoverageDialogInsuranceCompany.display : lblWaiveOptionDialogCompany.display} name="insuranceCompany" ref="insuranceCompany" value={this.state.insuranceCompany} onChange={this.changeValue} required type="text" className={`m-0`}/>
                    <Form.Control.Feedback  role='alert' ref="insuranceCompanyError"  type="invalid">{type ? rfvtxtWaiveCoverageInsuranceCompanyErrorMessage.display : rfvtxtWaiveOptionDialogCompanyErrorMessage.display}</Form.Control.Feedback>
                  </Col>
                </Row>
                {/*<Row>
                  <Col xs="8" md="9">
                  </Col>
                </Row>*/}
                <Row className="mb-md-4 rowgroup">
                  <Col xs="12" sm="5" md="3">
                    <Form.Label htmlFor={getControlId(type ? lblWaiveCoverageDialogPolicy.textToRead : lblWaiveOptionDialogPolicy.textToRead)} className={`m-0 p-0`} >{type ? lblWaiveCoverageDialogPolicy.display : lblWaiveOptionDialogPolicy.display}</Form.Label>
                  </Col>
                  <Col xs="12" sm="7" md="9" className="ml-inputfields">
                    <Form.Control id={getControlId(type ? lblWaiveCoverageDialogPolicy.textToRead : lblWaiveOptionDialogPolicy.textToRead)} name="policyNumber" placeholder={type ? lblWaiveCoverageDialogPolicy.display : lblWaiveOptionDialogPolicy.display} value={this.state.policyNumber} onChange={this.changeValue} ref="policyNumber" required type="text" className={`m-0`}/>
                    <Form.Control.Feedback  role='alert' ref="policyNumberError"  type="invalid">{type ? rfvtxtWaiveCoveragePolicyErrorMessage.display : rfvtxtWaiveOptionDialogPolicyErrorMessage.display}</Form.Control.Feedback>
                  </Col>
                </Row>
                <Row className="mb-md-4 rowgroup">
                  <Col>
                    <Fragment><p className={`m-0`} dangerouslySetInnerHTML={{__html: type ? lblWaiveCoverageDialogNote.display : lblWaiveOptionDialogNote.display}}/></Fragment>
                  </Col>
                </Row>
                <div className="ml-popupbuttons d-flex border-top pt-3 pt-md-4 justify-content-between">
                  <DisplayButton className="outline-secondary btn-cancel" type="button" displayName={btnLblCancel.display}  onClick={() => this.props.handleClose()} />
                  <DisplayButton className="secondary btn-save" displayName={btnLblSave.display}  type="submit" />
                </div>
            </Form>
        </div>
  )
  }
}

const mapStateToProps = (state) => {
  return {waiverFormResponse: state.userReducer.waiverFormResponse ? state.userReducer.waiverFormResponse : {}
  }};

export default connect(mapStateToProps,
    { saveWaiverForm, saveDependentWaiverForm }
)(MedicalWaiver);
