import React, { Component, Fragment }  from 'react';
import { connect } from "react-redux";
import { reviewPastCoverage, pastCoverageList, pdfStatement, reviewCurrentCoverage, setPageSetting } from "../../actions/userActions"
import "./coverage.css"
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { isMobile } from "react-device-detect";
import { trackPromise } from 'react-promise-tracker';
import { getDisplayText } from '../../utils';
import DisplayButton from '../button';
import _ from 'underscore';

class Current extends Component {
	state={
		currentCoverage: "",
		saveBtn: this.props.currentCoverage.buttonSavePDF.display

	}

	componentDidMount(){
		let query = (this.props.location.search && this.props.location.search.indexOf('period') > -1) ? this.props.location.search.replace('?', '') : [] ;
       	if(query)
         query = query !=='period=Future' ? null:query;
      	const period = !query ? localStorage.getItem("parameters"):query
		trackPromise(this.props.reviewCurrentCoverage(period, () => {
			this.setState({
				currentCoverage: this.props.currentCoverage.html,
				saveBtn: this.props.currentCoverage.buttonSavePDF.display
			})
		})
		)
	}

	static getDerivedStateFromProps(props, state) {
		if (props.currentCoverage.buttonSavePDF.display !== state.saveBtn) {
			return {
			saveBtn: props.currentCoverage.buttonSavePDF.display,
			currentCoverage: props.currentCoverage.html
		  };
		}
		if(document.getElementById("mainHeader") !==null){
			document.getElementById("mainHeader").setAttribute("tabIndex", "-1");
			document.getElementById("mainHeader").focus();
		}
		return null;
	  }

	handleClick = (e) => {
	e.preventDefault()
	this.props.pdfStatement(this.props.currentCoverage.buttonSavePDF.btnNavigateTo, "Current")
	
	}

	previousPage = () => {
		if(localStorage.getItem('pageName')){
			const menuItem = _.find(this.props.screens, item => item.name === localStorage.getItem('pageName'))
			trackPromise(this.props.setPageSetting(localStorage.getItem('pageName')))
			localStorage.removeItem('pageName')
			this.props.history.push(`/${menuItem ? menuItem.link : ""}`)
		} else 
			this.props.history.push("/")
	}

	render(){
		const backButtonText = getDisplayText((this.props.contentFooter.contentResource && this.props.contentFooter.contentResource.contents) || {}, 'btnBack')
		return (
		<div className={`coveragewrapper`}>
			<div className={`coveragebox clearfix`}>
				<div className="pt-4 pb-4 mb-4 btnbox d-flex justify-content-between align-items-center">
					<div className={`d-flex titlewithicon`} id="coverageReport">
						{isMobile ? <>
                              <DisplayButton className="icon-action" displayName={backButtonText.display} icon={faChevronLeft} onClick={() => this.previousPage()} /> 
							</> :
							''
						}
					</div>
					<DisplayButton controlId='navigateclick' displayName={this.state.saveBtn} onClick={(e) => {this.handleClick(e)}} className={`btn btn-outline-secondary float-right`}  />
				</div>
				<Fragment>
					<div dangerouslySetInnerHTML={ { __html: this.state.currentCoverage } } className={`reviewcoverage clearfix`}></div>
				</Fragment>
				<div className={`clearfix`}></div>
				<DisplayButton controlId='saveclick' displayName={this.state.saveBtn} onClick={(e) => {this.handleClick(e)}} className={`btn btn-outline-secondary float-right`} />
			</div>
		</div>)
	}
}

const mapStateToProps = (state) => {
	return {
		currentCoverage: state.userReducer.currentCoverage,
		contentFooter: state.userReducer.contentFooter,
		screens: state.contentReducer.screens
	}
};
export default connect(
mapStateToProps,
{ reviewPastCoverage, pastCoverageList, pdfStatement, reviewCurrentCoverage, setPageSetting }
)(Current);
