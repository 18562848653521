import React, { Component, Fragment } from 'react';
import { Row, Form, Table} from 'react-bootstrap';
import { Modal, ModalBody } from 'reactstrap';
import DisplayButton from './button';
import _ from 'underscore';
import {getDisplayText, CustomToolTipIcon,setModalAttribute} from '../utils';
import NewMultiRadioComponent from './NewMultiRadioComponent';
import { FormGroup } from 'reactstrap';

class BenefitOptionComponent extends Component {
  constructor(props) {
    super(props)
    this.state = { 
      showDesc: false, 
      description: '',
      header: '',
      employeeSmokerStatus: '',
      dependentSmokerStatus: '',
      hidden: this.props.expandSmokerBlock,
      tooltipId:''
    }
  }
  toggleDescription = (opt,tooltipIdValue) => {
    this.setState({ 
      showDesc: !this.state.showDesc,
      description: opt.toolTipDescription,
      header: opt.toolTipHeader,
      tooltipId:tooltipIdValue
    })
  }
  handleClose = () => {
    this.setState({ showDesc: false, hidden: false });
    this.props.resetSmokerStatus();
   if(document.getElementById(this.state.tooltipId)!==null){
    document.getElementById(this.state.tooltipId).focus();
   }
  }
  handleOptionSelectionThroughTab = (event, opt) => {
    switch(event.which){
        case 32: {
          event.preventDefault();
          this.props.setValue(opt);
          break;
        }
        default:
          break;
    }
  }
  handleInfoDetailThroughTab = (event, opt,tooltipIdValue) => {
  switch(event.which){
      case 13: {
        this.toggleDescription(opt,tooltipIdValue)
        break;
      }
      default:
				break;
  }
  }

  changeSmokerStatus = (e, smokerType, isDeclared = false) => {
    this.setState({ [smokerType]: e.target.value });
    this.props.setSmokerStatus({ [smokerType]: e.target.value })
  }
  componentDidUpdate(prevProps, prevState) {
    if(prevProps.expandSmokerBlock !== this.props.expandSmokerBlock) {
      this.setState({hidden: this.props.expandSmokerBlock});
    }
  }

  
  render() {
    const props = this.props
    const contents = this.props.contents
    const benefit = this.props.benefit
    const headers = props.header ? props.header : {}
    const { footer } = this.props
    const lblEOINote = getDisplayText(contents, 'lblEOINote')
    const lblEOITitle = getDisplayText(contents, 'lblEOINoteTitle')
    const toolTipIcon = getDisplayText((footer && footer.contentResource && footer.contentResource.contents) || {}, 'ToolTipIcon')
    const closeButtonText = getDisplayText((footer && footer.contentResource && footer.contentResource.contents) || {}, 'btnClose')
    const tabIndexValue = 0;
    const {lblIsSmoker, lblIsNonSmoker, smokerDeclarationLabel, smokerStatusList} = props.showSmokerContent || "";
    const btnCancel = getDisplayText((footer && footer.contentResource && footer.contentResource.contents) || {},'btnCancel')
    const btnDeclare = getDisplayText((footer && footer.contentResource && footer.contentResource.contents) || {}, 'btnDeclare')
    // console.log(props.showSmokerContent && props.smokerStatus !== -1);
    const headingTitleTxt = props.showSmokerContent ? props.smokerStatus !== 2 ? props.showSmokerContent.lblChangeText : props.showSmokerContent.lblButtonText : "";
    let smokerStatusMessage = "";
    if (["0", 0].indexOf(props.smokerStatus) > -1) smokerStatusMessage = lblIsNonSmoker;
    if (["1", 1].indexOf(props.smokerStatus) > -1) smokerStatusMessage = lblIsSmoker;
    let smokerType = "";
    if(benefit.isEmployeeSmokerStatusRequired === true) {
      smokerType = 'employeeSmokerStatus';
    }
    if(benefit.isDependentSmokerStatusRequired === true) {
      smokerType = 'dependentSmokerStatus';
    }
    const optionsDiv = []
    _.each(props.options, (opt, i)=>{
      const optionName = `${opt.optionName} ${opt.coverage ? opt.coverage : ""}`
      optionsDiv.push(
        <div tid="handleOptionSelection" onKeyDown={(event) => {this.handleOptionSelectionThroughTab(event,opt)}} key={Math.random()} className={this.props.selected === opt.optionID ? "selected mb-3 ml-selectedsecondary benefit-option" : "mb-3 benefit-option"}>
          <div id={this.props.benefitURL ? this.props.benefitURL+opt.optionName+i+"-option-div" : opt.optionName+i+"-option-div"} className="header d-block">
            <Row>
              <div className="col-9">
                <Form.Check 
                  tabIndex='0'
                  inline 
                  className={`${opt.isIneligible && 'option-ineligble'} ${opt.requireEOI && 'option-eoi'}`}
                  disabled={!opt.selectEnabled}
                  name={props.name}
                  type='radio'
                  id={this.props.benefitURL ? this.props.benefitURL+opt.optionName+i : opt.optionName+i}>
                    <Form.Check.Input 
                      tid={opt.selectEnabled ? "selectEnableCheckbox" : "selectNoEnableCheckbox"}
                      type='radio' 
                      disabled={!opt.selectEnabled}
                      isInvalid={opt.isIneligible}
                      value={opt.optionID}
                      name={props.benefit.benefitName.replace(/\s+/g, '-').toLowerCase() + '-' + opt.optionID}
                      checked={opt.isIneligible ? false : opt.isSelected}
                      aria-checked={opt.isIneligible ? false : opt.isSelected}
                      onChange={()=>{props.setValue(opt)}}
                      onSelect={()=>{props.setValue(opt)}} />
                    <Fragment><Form.Check.Label  dangerouslySetInnerHTML={{__html: optionName}} /></Fragment>
                  </Form.Check>                     
              </div>
              <div className="col-3"  >
                { (opt.showToolTip && opt.toolTipDescription) && <span tid="showTooltip" id={"showTooltip-benefitID-"+this.props.benefitID+"-"+i}  tabIndex={tabIndexValue} onKeyDown={(event) => {this.handleInfoDetailThroughTab(event,opt,"showTooltip-benefitID-"+this.props.benefitID+"-"+i)}} role='button' aria-label={toolTipIcon.textToRead} className="tooltip-icon icon-action float-right" onClick={() => this.toggleDescription(opt,"showTooltip-benefitID-"+this.props.benefitID+"-"+i)}>
                  <CustomToolTipIcon />
                  </span>}
              </div>
            </Row>
          </div>
          <div className="col-xs-12 col-md-12 col-lg-12 benefit-option-body ml-benefitoptions">
            <Table>
              <tbody>
                <Fragment>
                  <tr>
                    {headers.category && <th scope="col"><span dangerouslySetInnerHTML={{__html: headers.category}}/></th>}
                    {headers.taxable && <th scope="col"><span dangerouslySetInnerHTML={{__html: headers.taxable}}/></th>}
                    {headers.eoi && <th scope="col"><span dangerouslySetInnerHTML={{__html: headers.eoi}}/></th>}
                    {headers.cost && <th scope="col"><span dangerouslySetInnerHTML={{__html: headers.cost}}/></th>}
                    {headers.flex && <th scope="col"><span dangerouslySetInnerHTML={{__html: headers.flex}}/></th>}
                    {headers.employerCost && <th scope="col"><span dangerouslySetInnerHTML={{__html: headers.employerCost}}/></th>}
                    {headers.creditsApplied && <th scope="col"><span dangerouslySetInnerHTML={{__html: headers.creditsApplied}}/></th>}
                    {headers.deduction && <th scope="col"><span dangerouslySetInnerHTML={{__html: headers.deduction}}/></th>}
                  </tr>
                </Fragment>
                <tr aria-label={(headers.category ? headers.category: "")+" "+(opt.category ? opt.category: "")+" "+
                       (headers.taxable ? headers.taxable : "") +" "+(opt.taxable ? opt.taxable : "")+" "+
                       (headers.eoi ? headers.eoi : "")+" "+(opt.eoi ? opt.eoi : "")+" "+
                       (headers.cost ? headers.cost : "")+" "+(opt.cost ? opt.cost : "")+" "+
                       (headers.flex ? headers.flex : "")+" "+(opt.flex ? opt.flex : "")+" "+
                       (headers.employerCost ? headers.employerCost : "")+" "+(opt.employerCost ? opt.employerCost : "")+" "+
                       (headers.creditsApplied ? headers.creditsApplied : "")+" "+(opt.creditsApplied ? opt.creditsApplied : "")+" "+
                       (headers.deduction ? headers.deduction :"")+" "+(opt.deduction ? opt.deduction : "")} >
                          {headers.category && <td>{opt.category}</td>}
                          {headers.taxable && <td>{opt.taxable}</td>}
                          {headers.eoi && <td>{opt.eoi}</td>}
                          {headers.cost && <td>{opt.cost}</td>}
                          {((headers.flex && opt.showFlex) && <td>{opt.flex}</td> )}
                          {headers.employerCost && <td>{opt.employerCost}</td>}
                          {headers.creditsApplied && <td>{opt.creditsApplied}</td>}
                          {((headers.deduction && opt.showDeductions) && <td>{opt.deduction}</td>) || <td>&nbsp;</td>}
                </tr>
              </tbody>
            </Table>
          </div>
          { (benefit.showBtnEOI && opt.requireEOI) ?
          <div className="additionaltext clearfix">
            <p dangerouslySetInnerHTML={{__html: lblEOITitle.display}}/>
            <p className={`m-0 `} dangerouslySetInnerHTML={{__html: lblEOINote.display}} />
          </div>
          : (opt.description && opt.isSelected) && <div className="additionaltext clearfix">
              <p className={`m-0 `}  dangerouslySetInnerHTML={{__html: opt.description}}/>
            </div>
          }
        </div>
        );
    });
    // console.log('benefitID: ' + props.benefitID + ' === smokerStatus: ' + props.smokerStatus + ' === smokerType: ' + smokerType);
    // console.log("this.state[smokerType]: ", this.state[smokerType]);
    // console.log("Smoker Status: ", !this.state[smokerType] ? props.smokerStatus.toString() : this.state[smokerType]);
    return (
      <div>
        <FormGroup tag="fieldset"   controlid={`singleSelectControl-${props.name}`}>
          <legend className="visuallyhidden">{this.props.benefit.benefitName}</legend>
          {optionsDiv}
          {props.showSmokerContent && props.smokerStatus !== -1 &&
            <Modal onOpened={() => setModalAttribute('modalHeading')} aria-labelledby="modalHeading" isOpen={this.state.hidden} toggle={() => {this.handleClose(); this.props.handleSmokerStatus()}} centered>
              <h1 className="modal-header modal-title">{headingTitleTxt}
              <DisplayButton type="button" className="close" displayName="×" onClick={() => {this.handleClose(); this.props.handleSmokerStatus()}}/> 
              </h1>
            <ModalBody>
              <NewMultiRadioComponent
                type="radio"
                component={`${benefit.isEmployeeSmokerStatusRequired ? 'employee-smokerStatus':'dependent-smokerStatus'}-${props.benefitID}`}
                name={`${benefit.isEmployeeSmokerStatusRequired ? 'employee-smokerStatus':'dependent-smokerStatus'}-${props.benefitID}`}
                fname={`${benefit.isEmployeeSmokerStatusRequired ? 'employee-smokerStatus':'dependent-smokerStatus'}-${props.benefitID}`}
                display={smokerStatusMessage}
                title={smokerStatusMessage}
                aria-label={smokerStatusMessage}
                selected={props.smokerStatus.toString()}
                options={smokerStatusList}
                onChange={(e) => this.changeSmokerStatus(e, smokerType)}
                onSelect={(e) => this.changeSmokerStatus(e, smokerType)}
              />
              {props.smokerStatus === 0 && smokerDeclarationLabel ? (
                <Fragment><label role="alert" className="alert alert-primary"  dangerouslySetInnerHTML={{ __html:smokerDeclarationLabel}} /></Fragment>) : ''}
                <div className="float-left">
                  <DisplayButton displayName={btnCancel.display} className="outline-secondary"  onClick={this.handleClose} />
                </div>
                <div className="float-right">
                  <DisplayButton displayName={btnDeclare.display} className="secondary btn-save"  onClick={this.props.declareSmokerStatus} />
                </div>
            </ModalBody>
          </Modal>
          }
        </FormGroup>

        <Modal onOpened={() => setModalAttribute('modalHeading')} aria-labelledby="modalHeading" isOpen={this.state.showDesc} toggle={this.handleClose} centered>
          <div className="modal-header">
            <h1 className="modal-title" id="modalHeading">
            {this.state.header ? this.state.header : '\u00A0'}
            </h1>
            <DisplayButton type="button"  className="close" arialabel={closeButtonText.display} displayName="×" onClick={this.handleClose}/> 
          </div>
          <ModalBody>
            <Fragment><div dangerouslySetInnerHTML={{__html: this.state.description}}/></Fragment>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
export default BenefitOptionComponent;