import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import {getControlId} from "../utils";

class  ViewEditText extends Component {

    render() {
        const editMode = this.props.mode === 'edit' ? true : false;
        const type = this.props.type ? this.props.type : "text";
        const labelC = editMode && type !== 'select'?<Form.Label htmlFor={getControlId(this.props.textToRead)}  className="edit-input-label" dangerouslySetInnerHTML={{__html: this.props.display}}></Form.Label>
                        :<Form.Label   className="edit-input-label" dangerouslySetInnerHTML={{__html: this.props.display}}></Form.Label>
        let inputC = null;
        const invalidStyle = {'color': 'red', 'marginTop':'-10px'}
        if (editMode) {
            if (type === 'select') {
                let options = []
                for (let i = 0; i < this.props.options.length; i++) {
                    options.push(<option key={this.props.options[i].value} value={this.props.options[i].value}>{this.props.options[i].text}</option>);
                }
                inputC = <select {...this.props}>{options}</select>;
            } else {
                inputC = (<div>
                            <Form.Control id={getControlId(this.props.textToRead)} maxLength={this.props.maxLength ? this.props.maxLength : 100}  className="login-input-field edit-input-field" type={type} {...this.props} value={this.props.text}/>
                            <Form.Label style={invalidStyle} aria-hidden={this.props.errormessage ? false : true} className="invalid"  role='alert' >{this.props.errormessage}</Form.Label>
                         </div>)
            }
        } else {
            inputC = <Form.Label>{this.props.text}</Form.Label>;
        }
        return <div className="name">{labelC} {inputC}</div>
    }
}

export default ViewEditText;
