import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

import { reallocateMultiBenefit, getBenefitSummary, confirmEnrollment, goNext, getShowFlex } from "../actions/userActions";
import { getMenu2, dissmiss } from "../actions/contentActions";

import { getDisplayText } from "../utils";
import { Table, Col, Row } from "react-bootstrap";

import { PieChart } from "react-minimal-pie-chart";
import { trackPromise } from "react-promise-tracker";
//import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import DisplayButton from "./button";

import _ from "underscore";

import NextPrevious from "./NextPrevious";
// import {chartColorsList} from '../constants';
class BenefitSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSelected: false,
      isEnabled: false,
      showToolTip: false,
      displayTooltipMessage: { benefit: "", amout: "", percent: "" },
      toolTipColor: { color: "", textColor: "" },
      errorMessage: "",
      disableCheckBox: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    trackPromise(this.props.getBenefitSummary(true));
    trackPromise(this.props.getMenu2());
    this.pieChartToolTip = React.createRef(); //TODO: REMOVE LATER. TEMPORARILY TO TEST CONDITION UNTIL NAVIGATION IS UPDATED
    this.scrollRef = React.createRef();
  }

  componentWillUnmount() {
    this.props.dissmiss();
  }

  handleSubmit = () => {
    trackPromise(
      this.props.confirmEnrollment(
        () => {
          this.props.getShowFlex();
          this.props.history.push("/EnrollmentConfirmation");
        },
        errorMessage => {
          this.setState({ errorMessage });
        }
      )
    );
  };

  handleValue = event => {
    this.setState({ isSelected: !this.state.isSelected });
  };

  /* mouseOver = (event, data, index) => {
    const message = {
      benefit: data[index].title,
      amount: this.props.benefitSummary.chart[index].amountSpent,
      percent: this.props.benefitSummary.chart[index].percent
    };
    const color = { color: data[index].color, textColor: data[index].textColor };
    this.setState({ showToolTip: true, displayTooltipMessage: message, toolTipColor: color }, () => {
      this.pieChartToolTip.current.focus();
    });
  };

  mouseOut = () => {
    this.setState({ showToolTip: false });
  }; */

  reallocate = () => {
    const actionlink = this.props.benefitSummary.reallocation && this.props.benefitSummary.reallocation.actionlink ? this.props.benefitSummary.reallocation.actionlink : "/BeneficiaryReallocate";
    trackPromise(this.props.reallocateMultiBenefit(-2));
    this.props.history.push(actionlink);
  };

  /* UNSAFE_componentWillReceiveProps(nextProps) {
        if(nextProps.errorMessage){
            const node = ReactDOM.findDOMNode(this.refs.errorMessage);
            if(node){
                node.focus();
            }
        }
    } */

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.benefitSummary !== this.props.benefitSummary) {
      const currentRef = this.scrollRef.current;
      const scrollTop = currentRef?.scrollTop;
      const scrollHeight = currentRef?.scrollHeight;
      const clientHeight = currentRef?.clientHeight;
      console.log("scrollTop ===> : ", scrollTop);
      console.log("scrollHeight ===> : ", scrollHeight);
      console.log("clientHeight ===> : ", clientHeight);
      const bottom = scrollHeight - scrollTop <= clientHeight;
      if (bottom) {
        this.setState({ disableCheckBox: false, isEnabled: true });
      } else if (this.props.benefitSummary?.isScrollableDisclaimer && !this.state.isSelected) {
        this.setState({ disableCheckBox: this.props.benefitSummary.isScrollableDisclaimer });
      }
    }

    if (this.state.errorMessage) {
      ReactDOM.findDOMNode(this.refs.errorMessage).focus();
    }
  }

  handleScroll = e => {
    const {scrollHeight, scrollTop, clientHeight} = e.target
    console.log("Scrolling START >>>");
    console.log("scrollTop ===> : ", Math.round(scrollTop));
    console.log("scrollHeight ===> : ", scrollHeight);
    console.log("clientHeight ===> : ", clientHeight);
    const bottom = scrollHeight - Math.round(scrollTop) <= clientHeight;
    if(bottom) {
      this.setState({ disableCheckBox: false });
      console.log("<<< Scrolling END");
    }
  };

  render() {
    const { benefitSummary } = this.props;
    const contents = benefitSummary.contentResource.controlResource.EnrollmentFooter ? benefitSummary.contentResource.controlResource.EnrollmentFooter : {};
    const benefitContents = benefitSummary.contentResource.controlResource.Beneficiaries || {};
    const chartData = benefitSummary.chart;
    const lblFlexAmountTitle = getDisplayText(contents, "lblFlexAmount");
    const lblBenefitSummaryMessage = benefitSummary.benefitSummaryMessage;
    const lblClickChartMessage = getDisplayText(contents, "lblClickChartMessage");
    const lblChartListHeading = getDisplayText(contents, "lblChartListHeading");
    const benefitSummaryTableInfo = getDisplayText(benefitContents, "benefitSummaryTableInfo");

    const lblChartListLegend = getDisplayText(contents, "lblChartListLegend");
    const lblPercentageAllocation = getDisplayText(contents, "lblPercentageAllocation");
    const lblBenefitType = getDisplayText(contents, "lblBenefitType");
    const lblAmount = getDisplayText(contents, "lblAmount");

    const lblConfirmMessage = benefitSummary.confirmMessage ? benefitSummary.confirmMessage : "";
    const lblBtnSubmit = getDisplayText(contents, "btnSubmit");
    const lblBtnCancel = getDisplayText(contents, "btnCancel");
    const beneficiaries = benefitSummary.beneficiaries ? benefitSummary.beneficiaries : {};
    const dependents = benefitSummary.dependents ? benefitSummary.dependents : {};
    const personal = benefitSummary.personal ? benefitSummary.personal : {};

    /* E-Signature Start */
    const isEsignable = benefitSummary.isEsignable || false;
    const canSubmit = benefitSummary.canSubmit || false;
    const lblEsignableTitle = getDisplayText(contents, "lblEsignableTitle");
    const lblEsignableSuccess = getDisplayText(contents, "lblEsignableSuccess");
    const lblEsignableMessage = getDisplayText(contents, "lblEsignableMessage");
    const beneficiaryESignature = benefitSummary.beneficiaryESignature ? benefitSummary.beneficiaryESignature : null;
    /* E-Signature End */

    /* Reallocation Start */
    const reallocationButtonText = benefitSummary.reallocation && benefitSummary.reallocation.text ? benefitSummary.reallocation.text : "";
    const removedBeneficiaryError = benefitSummary && benefitSummary.allBenefitsAllocationError ? benefitSummary.allBenefitsAllocationError : "";
    /* Reallocation End */

    /* Scrollable Disclaimer Start */
    const isScrollableDisclaimer = benefitSummary?.isScrollableDisclaimer || false;
    const disclaimerMessage = benefitSummary?.disclaimerMessage;
    const lblSummaryTermsAndConditions = getDisplayText(benefitSummary?.contentResource?.contents, "lblSummaryTermsAndConditions");
    /* Scrollable Disclaimer End */

    let concatenateChartData = "";
    const chart = [];
    _.forEach(chartData, (item, i) => {
      let value = {};
      value["title"] = item.name;
      value["value"] = parseInt(item.percent.split("%")[0]);
      value["color"] = item.colorCode;
      value["textColor"] = item.colorName;
      value["amount"] = item.amountSpent;
      // value["color"] = chartColorsList[i % chartColorsList.length].color
      // value["textColor"]= chartColorsList[i % chartColorsList.length].textColor
      chart.push(value);
      concatenateChartData = concatenateChartData + "benefit " + value.title + " amount " + benefitSummary.chart[i].amountSpent + " percent " + benefitSummary.chart[i].percent + "\n";
    });

    const pageTitle = getDisplayText(contents, "lblBenefitSummary");
    document.title = pageTitle.display;

    return (
      <div className="summary benefitsummary clearfix">
        {benefitSummary.isFlexChartVisible && (
          <>
            <h1>{lblFlexAmountTitle.display}</h1>
            <div className="ml-description mb-2 mb-md-4 clearfix">
              <p className="m-0">{lblBenefitSummaryMessage}</p>
            </div>
          </>
        )}
        {benefitSummary.isFlexChartVisible && lblClickChartMessage !== "" && (
          <div className="ml-titledescription mb-3 mb-md-4 clearfix">
            <h2 className="m-0">{lblClickChartMessage.display}</h2>
          </div>
        )}
        {benefitSummaryTableInfo !== "" && (
          <div className="ml-description mb-2 mb-md-4 clearfix">
            <p className="m-0">{benefitSummaryTableInfo.display}</p>
          </div>
        )}
        {benefitSummary.isFlexChartVisible && concatenateChartData !== "" && (
          <>
            {/* <div aria-label={concatenateChartData}> */}
            <div>
              <div className="summarychart clearfix" aria-hidden="true">
                <Row>
                  <Col md={2}></Col>
                  <Col md={4}>
                    <PieChart
                      tid="pieChart"
                      animate={false}
                      animationDuration={500}
                      animationEasing="ease-out"
                      data={chart}
                      colors={this.state.colors}
                      label={false}
                      labelPosition={50}
                      lengthAngle={360}
                      lineWidth={100}
                      onClick={undefined}
                      // onMouseOut={this.mouseOut}
                      // onMouseOver={this.mouseOver}
                      paddingAngle={1.2}
                      strokeWidth={2}
                      radius={50}
                      rounded={false}
                      startAngle={0}
                      style={{
                        height: "30vh"
                      }}
                      viewBoxSize={[100, 100]}
                    />
                  </Col>
                  <Col md={6} style={{ marginTop: "28px" }}>
                    {benefitSummary.isFlexChartVisible && lblChartListLegend !== "" && (
                      <div className="ml-description mb-3 mb-md-3 clearfix">
                        <div role="text" aria-label={lblChartListLegend.textToRead} className={`piechart-list-heading m-0`}>
                          {lblChartListLegend.display}
                        </div>
                      </div>
                    )}
                    {chart &&
                      chart.map(item => (
                        <Row key={item.title}>
                          <Col className="col-spnlegend">
                            <div className="spnlegend" style={{ backgroundColor: item.color }}></div>
                            <div className="spnlegendtext17">{item.title}</div>
                            <div className="spnlegendtext10">- {item.value}%</div>
                            {/* <span className={item.value && item.value.toString().length === 2 ? "spnlegendtext10" : "spnlegendtext17"}>{item.amount}</span> */}
                          </Col>
                        </Row>
                      ))}
                  </Col>
                </Row>
              </div>
            </div>

            <div className="tbl-pieChart">
              <h2 className="title">{lblChartListHeading.display}</h2>
              <Table className="table-striped">
                <thead>
                  <tr>
                    <th scope="col">{lblPercentageAllocation.display}</th>
                    <th scope="col">{lblBenefitType.display}</th>
                    <th scope="col">{lblAmount.display}</th>
                  </tr>
                </thead>
                <tbody>
                  {chart &&
                    chart.map(item => (
                      <tr key={item.title}>
                        <td>{item.value}%</td>
                        <td>{item.title}</td>
                        <td>{item.amount}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </>
        )}
        {this.state.showToolTip && (
          <div ref={this.pieChartToolTip} className="mb-4 p-3 w-100 piechartinfo" style={{ backgroundColor: this.state.toolTipColor.color }}>
            <div className="piechartarrow" style={{ backgroundColor: this.state.toolTipColor.color }}></div>
            <Row className="m-0 justify-content-between" style={{ color: this.state.toolTipColor.textColor }}>
              <Col className="col-auto">{this.state.displayTooltipMessage.benefit}</Col>
              <Col className="col-auto">{this.state.displayTooltipMessage.amount}</Col>
              <Col className="col-auto">{this.state.displayTooltipMessage.percent}</Col>
            </Row>
          </div>
        )}
        <div className="mb-3 pb-3 pb-md-4 mb-md-4 d-flex flex-wrap justify-content-center quicklinks">
          <DisplayButton
            className="outline-secondary btn-personal"
            displayName={personal.text}
            onClick={() => {
              this.props.history.push(`/${personal.actionlink}`);
            }}
          />
          <DisplayButton
            className="outline-secondary btn-beneficiaries"
            displayName={beneficiaries.text}
            onClick={() => {
              this.props.history.push(`/${beneficiaries.actionlink}`);
            }}
          />
          <DisplayButton
            className="outline-secondary btn-dependents"
            displayName={dependents.text}
            onClick={() => {
              this.props.history.push(`/${dependents.actionlink}`);
            }}
          />
        </div>
        {/* E-Signature Start */}
        {isEsignable && (
          <div className="mb-3 pb-3 pb-md-4 mb-md-4 d-flex flex-wrap quicklinks">
            <p className="bold-text-imp ml-popupmessagetext">{lblEsignableTitle.display}</p>
            {!canSubmit && <label className="mb-3 form-check-label warning" htmlFor="lblEsignableMessage" dangerouslySetInnerHTML={{ __html: lblEsignableMessage.display }} />}
            {canSubmit && <label className="mb-3 form-check-label warning w-100" htmlFor="lblEsignableSuccess" dangerouslySetInnerHTML={{ __html: lblEsignableSuccess.display }} />}
            {!canSubmit && (
              <DisplayButton
                className="outline-secondary btn-beneficiaries"
                displayName={beneficiaryESignature.text}
                onClick={() => {
                  this.props.history.push(`/${beneficiaryESignature.actionlink}`);
                }}
              />
            )}
          </div>
        )}
        {/* E-Signature End */}

        {/* Reallocate Start */}
        {!isEsignable && !canSubmit && (
          <div className="clearfix mb-3 mb-md-4 quicklinks">
            {removedBeneficiaryError && (
              <Fragment>
                <div className="alert alert-danger mb-2" role="alert" dangerouslySetInnerHTML={{ __html: removedBeneficiaryError }} />
              </Fragment>
            )}
            <div>
              <DisplayButton className="outline-secondary btn-beneficiaries" displayName={reallocationButtonText} onClick={this.reallocate} />
            </div>
            <br />
          </div>
        )}
        {/* Reallocate End */}

        {isScrollableDisclaimer && disclaimerMessage && <div className="bold-text-imp ml-popupmessagetext align-left">{lblSummaryTermsAndConditions.display}</div>}
        {disclaimerMessage && (
          <div className="esignable-container">
            <div className="mb-3 pb-3 d-flex flex-wrap">
              <div className="scroll-container" ref={this.scrollRef} onScroll={this.handleScroll} tabIndex="0">
                <p
                  className="mb-3 align-justify"
                  htmlFor="lblEsignableMessage"
                  dangerouslySetInnerHTML={{
                    __html: disclaimerMessage
                  }}
                />
              </div>
            </div>
          </div>
        )}

        <div className="clearfix form-check confirmationcheck mb-3 mb-md-4">
          <input
            type="checkbox"
            key="confirmSelection"
            className=""
            id="confirmSelection"
            disabled={!this.state.isEnabled && this.state.disableCheckBox}
            checked={this.state.isSelected}
            onChange={() => {
              this.handleValue();
            }}
          />
          <label className="form-check-label warning" htmlFor="confirmSelection">
            {lblConfirmMessage}
          </label>
        </div>

        {this.state.errorMessage !== "" && (
          <div className="pt-3 border-top">
            <div className="alert alert-danger" ref="errorMessage" role="alert" dangerouslySetInnerHTML={{ __html: this.state.errorMessage }} />
          </div>
        )}

        <NextPrevious
          lastPage={this.state.errorMessage !== "" ? true : false}
          firstPage={true}
          nextClick={this.handleSubmit}
          nextDisabled={!this.state.isSelected || !canSubmit}
          showButtons={true}
          lblNext={lblBtnSubmit}
          lblCancel={lblBtnCancel}
          lblPrevious={lblBtnCancel}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    benefitSummary: state.userReducer.benefitSummary ? state.userReducer.benefitSummary : { contentResource: { controlResource: { EnrollmentFooter: {} } } },
    errorMessage: state.userReducer.enrollmentErrorMessage ? state.userReducer.enrollmentErrorMessage : ""
  };
};

export default connect(mapStateToProps, { reallocateMultiBenefit, getBenefitSummary, getMenu2, confirmEnrollment, goNext, dissmiss, getShowFlex })(withRouter(BenefitSummary));