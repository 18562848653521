import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import { Form, InputGroup, Card } from "react-bootstrap";
import { Modal, ModalBody } from "reactstrap";
import { withRouter } from "react-router-dom";

import { getDisplayText, setModalAttribute } from "../utils";
import BenefitOptionComponent from "./BenefitOptionComponent";
import ChangeDependedCoverage from "./ChangeDependedCoverage";
import MedicalWaiver from "./MedicalWaiver";
import { setBenefitOption, removeEOIOption, removeWaiver, getContentHelp, setPageSetting, setBaseBeneficiaryPageTitle, setFocusOnBenefitError } from "../actions/userActions";
import { dissmiss } from "../actions/contentActions";

import _ from "underscore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus, faQuestionCircle, faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { trackPromise } from "react-promise-tracker";
import _deep from "underscore.deepclone";
import DisplayButton from "./button";

_.mixin(_deep);
class BenefitShell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionKey: props.optionKey,
      benefit: {},
      options: [],
      header: {},
      selected: "",
      showHelpContent: false,
      showChangeDependentCoverage: false,
      showMedicalWaiver: false,
      showMedicalWaiverDependent: false,
      showAlternateCoverageDetails: false,
      coverageAmount: -1,
      coverageAmountOldValue: -1,
      selectedOption: {},
      unitCoverage: props.benefit.unitCoverage ? props.benefit.unitCoverage : {},
      isExpanded: false,
      collapsedOptionList: []
    };
    this.handleFocusInput = this.handleFocusInput.bind(this);
    this.handleBlurInput = this.handleBlurInput.bind(this);
  }

  makeCollapsedOptions = (options, selected) => {
    const selectedIndex = _.findIndex(options, opt => opt === selected);
    const eoiOptionIndex = _.findIndex(options, opt => opt.requireEOI === true);
    let collapsedOptionList = [];
    if (selectedIndex === 0) {
      collapsedOptionList = options.slice(selectedIndex, selectedIndex + 3);
    } else if (selectedIndex === options.length - 1) {
      collapsedOptionList = options.slice(selectedIndex - 2);
    } else {
      collapsedOptionList = options.slice(selectedIndex - 1, selectedIndex + 2);
    }

    if (eoiOptionIndex !== -1) {
      const collapsedEOIIndex = _.findIndex(collapsedOptionList, opt => opt.requireEOI === true);
      if (collapsedEOIIndex === -1) collapsedOptionList.push(options[eoiOptionIndex]);
    }

    this.setState({ collapsedOptionList });
  };

  componentDidMount() {
    if (this.props.benefit !== this.state.benefit) {
      const options = this.props.benefit.options;
      const selectedOption = _.where(options, { isSelected: true });
      const selected = selectedOption.length ? selectedOption[0].optionID : "";
      const selectedOptionProp = options ? options.find(opt => opt.optionID === selected) : {};
      if (this.props.benefit.unitCoverage)
        if (this.props.benefit.unitCoverage.unitCoverageAmount !== this.state.coverageAmount) this.setState({ unitCoverage: this.props.benefit.unitCoverage, coverageAmount: this.props.benefit.unitCoverage.unitCoverageAmount });
      this.setState(
        { options, selected, selectedOption: selectedOptionProp, benefit: this.props.benefit, optionKey: this.props.optionKey, header: this.props.benefit.header },
        () => setTimeout(() => this.handleSetFocusOnError(), 250) //this.handleSetFocusOnError()
      );
      trackPromise(this.props.setBenefitOption(selected, false));

      if ((options || []).length > 10) this.makeCollapsedOptions(options, selectedOptionProp);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.benefit !== this.state.benefit) {
      const options = nextProps.benefit.options;
      const selectedOption = _.where(options, { isSelected: true });
      console.log(selectedOption);
      const selected = selectedOption.length ? selectedOption[0].optionID : "";
      const selectedOptionProp = options ? options.find(opt => opt.optionID === selected) : {};
      if (nextProps.benefit.unitCoverage)
        if (nextProps.benefit.unitCoverage.unitCoverageAmount !== this.state.coverageAmount) this.setState({ unitCoverage: nextProps.benefit.unitCoverage, coverageAmount: parseFloat(nextProps.benefit.unitCoverage.unitCoverageAmount) });
      this.setState({ options, selected, selectedOption: selectedOptionProp, benefit: nextProps.benefit, optionKey: nextProps.optionKey, header: nextProps.benefit.header }, () => setTimeout(() => this.handleSetFocusOnError(), 250));
      trackPromise(this.props.setBenefitOption(selected, false));

      if ((options || []).length > 10) this.makeCollapsedOptions(options, selectedOptionProp);
    }
    if (nextProps.benefitErrorOnNext) {
      setTimeout(() => {
        this.handleSetFocusOnError();
        this.props.setFocusOnBenefitError(false);
      }, 200);
    }
  }

  UNSAFE_shouldComponentUpdate(nextProps, nextState) {
    if (nextState.benefit) {
      if (nextState.benefit.errorMessage) {
        const errorMessageNode = ReactDOM.findDOMNode(this.refs.errorMessageField);
        if (errorMessageNode) {
          errorMessageNode.focus();
        }
      }
    }
  }

  setValue = option => {
    let options = { ...this.state.options };
    _.each(options, opt => {
      opt.isSelected = opt.optionID === option.optionID;
    });
    this.setState({ options, selected: option.optionID });
    this.setState({ isExpanded: false });
    trackPromise(this.props.setBenefitOption(option.optionID, true));
  };

  showModal = modalName => {
    if (modalName === "showChangeDependentCoverage") {
      this.setState({
        prevDependentCoverage: _.deepClone(this.state.benefit)
      });
    }
    this.setState({ [modalName]: true });
  };

  handleClose = () => {
    this.setState(state => ({
      showHelpContent: false,
      showChangeDependentCoverage: false,
      showMedicalWaiver: false,
      showMedicalWaiverDependent: false,
      showAlternateCoverageDetails:false,
      benefit: state.prevDependentCoverage ? _.deepClone(this.state.prevDependentCoverage) : state.benefit
    }));
  };

  getSelectedProps = () => {
    let option = {};
    if (this.state.options) {
      option = this.state.options.find(opt => opt.optionID === this.state.selected);
    }
    return option ? option : {};
  };

  handleRemoveEOI = () => {
    trackPromise(this.props.removeEOIOption());
  };

  handleRemoveWaiver = () => {
    trackPromise(this.props.removeWaiver());
  };

  handleUnitCoverageUpdateValue = event => {
    const formattedValue = event.target.value.replace(/[^\d]/g, "").replace(/(^(0|1-9))[\d]/g, "$1");
    this.setState({ [event.target.name]: formattedValue });
  };

  handleSubmit = event => {
    event.preventDefault();

    const coverageAmount = parseInt(this.state.coverageAmount ? this.state.coverageAmount : this.state.unitCoverage.unitCoverageAmount);
    trackPromise(this.props.setBenefitOption(coverageAmount, true));
  };

  handleUnitCoverageIncrement = () => {
    const unitCoverage = this.props.benefit.unitCoverage;
    if (unitCoverage) {
      let coverageAmount = parseInt(this.state.coverageAmount ? this.state.coverageAmount : unitCoverage.unitCoverageAmount);

      if (coverageAmount + unitCoverage.coveragePerUnit <= unitCoverage.maximumCoverage) {
        coverageAmount += unitCoverage.coveragePerUnit;
      } else {
        coverageAmount = unitCoverage.maximumCoverage;
      }

      this.setState({ unitCoverage:unitCoverage,coverageAmount }, () => {
        trackPromise(this.props.setBenefitOption(coverageAmount, true));
      });
    }
  };

  handleUnitCoverageDecrement = () => {
    const unitCoverage = this.props.benefit.unitCoverage;
    if (unitCoverage) {
      let coverageAmount = parseInt(this.state.coverageAmount ? this.state.coverageAmount : unitCoverage.unitCoverageAmount);

      if (coverageAmount - unitCoverage.coveragePerUnit >= 0) {
        coverageAmount -= unitCoverage.coveragePerUnit;
      } else {
        coverageAmount = 0;
      }

      this.setState({unitCoverage:unitCoverage, coverageAmount }, () => {
        trackPromise(this.props.setBenefitOption(coverageAmount, true));
      });
    }
  };

  handleShowHelp = () => {
    const helpLink = this.props.benefit.helpLink;
    if (helpLink) {
      this.props.getContentHelp(helpLink, () => {
        this.showModal("showHelpContent");
      });
    }
  };

  handleFocusInput = event => {
    this.setState(state => ({ coverageAmountOldValue: state.coverageAmount }));
  };

  handleBlurInput = event => {
    if (this.state.coverageAmountOldValue !== this.state.coverageAmount) this.handleSubmit(event);
  };

  handleDownKey = (event, type) => {
    console.log("Event Triggered");
    switch (event.which) {
      case 13: {
        if (type === "increment") {
          this.handleUnitCoverageIncrement();
        } else if (type === "decrement") {
          this.handleUnitCoverageDecrement();
        }
        break;
      }
      default:
        break;
    }
  };
  setAreaLabel = (currentState, lblDependentsCoveredUnderThisPlan, lblWaiveOptionDialogTitle) => {
    if (currentState.showChangeDependentCoverage) {
      return lblDependentsCoveredUnderThisPlan.display;
    } else if (currentState.showHelpContent) {
      return "Help";
    } else if (currentState.showMedicalWaiver || currentState.showMedicalWaiverDependent) {
      return lblWaiveOptionDialogTitle.display;
    }
  };

  handleSetFocusOnError = () => {
    if (this.props.benefit.errorMessage) {
      const errorMessageNode = ReactDOM.findDOMNode(this.refs.errorMessageField);
      if (errorMessageNode) {
        errorMessageNode.focus();
      }
    }
  };

  toggleExpandCollapse = () => {
    this.setState(state => ({ isExpanded: !state.isExpanded }));
  };

  handleAddDependent = () => {
    const menuItem = _.find(this.props.sideBarMenu, item => (item.link || "").toUpperCase() === "DEPENDENT");
    this.props.setPageSetting(menuItem ? menuItem.name : "WELCOME", true, true);
    this.props.history.push(`/${menuItem ? menuItem.link : ""}`);
  };

  handleOpenBeneficiary = (event, benefitName) => {
    this.props.setBaseBeneficiaryPageTitle(benefitName);
    this.props.history.push("/Beneficiaries");
  };

  render() {
    const contents = this.props.contents;
    const benefit = this.state.benefit;
    const options = this.state.options;
    const header = this.state.header;
    const footerContent = this.props.footer && this.props.footer.contentResource ? this.props.footer.contentResource.contents : {};

    const showWaiverNotes = this.state.selectedOption.showWaiverNotes ? this.state.selectedOption.showWaiverNotes : false;
    const requiresOtherCoverage = this.state.selectedOption.requiresOtherCoverage ? this.state.selectedOption.requiresOtherCoverage : false;
    const provincesRequiringOtherCoverage = this.state.selectedOption.provincesRequiringOtherCoverage ? this.state.selectedOption.provincesRequiringOtherCoverage : false;
    const showAlternateCoverageDetails=this.state.benefit.showAlternateCoverageDetails ? this.state.benefit.showAlternateCoverageDetails : false;
    const lblViewBeneficiaries = getDisplayText(contents, "lblViewBeneficiaries");
    const lblChangeDependents = getDisplayText(contents, "lblChangeDependents");
    const lblWaiveCoverage = getDisplayText(contents, "lblWaiveCoverage");
    const lblRemoveEOI = getDisplayText(contents, "lblRemoveEOI");
    const lblDependentsCoveredUnderThisPlan = getDisplayText(contents, "lblDependentsCoveredUnderThisPlan");
    const lblWaiveOptionDialogTitle = getDisplayText(contents, "lblWaiveOptionDialogTitle");
    const lblWaiverNoteOptOut = getDisplayText(contents, "lblWaiverNoteOptOut");
    const lblOtherCoverageRequiredNote = getDisplayText(contents, "lblOtherCoverageRequiredNote");
    const lblOtherCoverageRequiredForProvince = getDisplayText(contents, "lblOtherCoverageRequiredForProvince");
    const lblRemoveWaiver = getDisplayText(contents, "lblRemoveWaiver");
    const lblSeeDetails = getDisplayText(contents, "lblSeeDetails");
    const unitCoverageMessage = getDisplayText(contents, "unitCoverageMessage");
    const lblAlternateCoverageDetailsTitle = getDisplayText(contents, "lblAlternateCoverageDetailsTitle");
    const lblAlternateCoverageDetails = getDisplayText(contents, "lblAlternateCoverageDetails");
    const lnkMore = getDisplayText(footerContent, "lnkMore");
    const lnkLess = getDisplayText(footerContent, "lnkLess");
    const closeButtonText = getDisplayText(footerContent, "btnClose");
    const optionList = options && options.length > 10 ? (this.state.isExpanded ? options : this.state.collapsedOptionList) : options;
    return (
      <Card className={`healthcarecontent clearfix`}>
        <Card.Header>
          <div className="ml-titledescription d-flex justify-content-between">
            <div className="titlehelpicon">
              <h2>{benefit.benefitName}</h2>
              {benefit.showHelp && (
                <span
                  tid="helpBtn"
                  className="icon-action"
                  role="button"
                  tabIndex="0"
                  aria-label={lblSeeDetails.textToRead}
                  onClick={() => {
                    this.handleShowHelp();
                  }}
                >
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </span>
              )}
            </div>
            {/* benefit effective date */}
            {benefit.lblBenefitNotes && <span className={`float-right m-0`} role='alert' dangerouslySetInnerHTML={{ __html: benefit.lblBenefitNotes }} />}
            {benefit.benefitNote && <span className="float-right" role='alert'>{benefit.benefitNote}</span>}
          </div>
        </Card.Header>
        <Card.Body>
          {requiresOtherCoverage && <label className={`warning note`} dangerouslySetInnerHTML={{ __html: lblOtherCoverageRequiredNote.display }} />}
          {provincesRequiringOtherCoverage && <label className={`warning note`} dangerouslySetInnerHTML={{ __html: lblOtherCoverageRequiredForProvince.display }} />}
          {benefit.showLinkedNote && <label className={`warning note`} dangerouslySetInnerHTML={{ __html: benefit.linkedNote }} />}
          {benefit.hasUnitCoverage && (
            <div className={`incrementer pt-md-3 clearfix`}>
              <Fragment>
                <span className="notetext" dangerouslySetInnerHTML={{ __html: unitCoverageMessage.display }} />
              </Fragment>
              <div className="ml-quntitybox d-flex float-right">
                <span
                  tabIndex="0"
                  tid="decrementSpan"
                  aria-label="decrement"
                  role="button"
                  onKeyDown={event => {
                    this.handleDownKey(event, "decrement");
                  }}
                  onClick={() => {
                    this.handleUnitCoverageDecrement();
                  }}
                >
                  <FontAwesomeIcon className="icon-action m-2" icon={faMinus} />
                </span>
                <Form onSubmit={this.handleSubmit}>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>$</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control aria-label={this.state.coverageAmount} name="coverageAmount" value={this.state.coverageAmount} onChange={this.handleUnitCoverageUpdateValue} onFocus={this.handleFocusInput} onBlur={this.handleBlurInput} />
                  </InputGroup>
                </Form>
                <span
                  tid="incrementSpan"
                  tabIndex="0"
                  aria-label="increment"
                  role="button"
                  onKeyDown={event => {
                    this.handleDownKey(event, "increment");
                  }}
                  onClick={() => {
                    this.handleUnitCoverageIncrement();
                  }}
                >
                  <FontAwesomeIcon className="icon-action m-2" icon={faPlus} />
                </span>
                {/*<Col xs={{span: "1", offset: "5"}} sm={{span: "1", offset: "8"}}>
            </Col>
            <Col xs={{span: "4"}} sm={{span: "2"}}>
            </Col>
            <Col xs={{span: "1"}}>
            </Col>*/}
              </div>
            </div>
          )}
          <div className={`ml-options mt-md-4 ${benefit.hasUnitCoverage ? "" : "float-none"} clearfix`}>
            {benefit.isWaived || benefit.isTerminated ? (
              <p className={`m-0`}>{benefit.terminatedORWaived}</p>
            ) : (
              <BenefitOptionComponent options={optionList} benefit={benefit} header={header} contents={contents} selected={this.state.selected} setValue={this.setValue} footer={this.props.footer} />
            )}
          </div>

          {options && options.length > 10 && (
            <div className="ml-showmore d-flex justify-content-end">
              <DisplayButton className="link" onClick={this.toggleExpandCollapse} displayName={this.state.isExpanded ? lnkLess.display : lnkMore.display} btnIcon={this.state.isExpanded ? faChevronUp : faChevronDown} />
            </div>
          )}
          {benefit.errorMessage && <div className="alert alert-danger" role="alert" tabIndex="-1" ref="errorMessageField" dangerouslySetInnerHTML={{ __html: benefit.errorMessage }} />}
          <div className="clearfix"></div>
          {/* <div className = {`ml-popupbuttons d-flex ${navBtnsLength > 1 ? 'justify-content-between' : 'justify-content-end'}`}> */}
          <div className={`ml-popupbuttons d-flex justify-content-end`}>
          {showWaiverNotes && (
              <DisplayButton
                controlId="showMedicalWaiverDependent"
                className="outline-secondary btn-waivecoverage"
                displayName={lblWaiveCoverage.display}
                onClick={() => {
                  this.showModal("showMedicalWaiverDependent");
                }}
              />
            )}
            {benefit.showRemoveWaiver && (
              <DisplayButton
                controlId="showRemoveWaiver"
                className="outline-secondary btn-removewaiver"
                displayName={lblRemoveWaiver.display}
                onClick={() => {
                  this.handleRemoveWaiver();
                }}
              />
            )}
            {benefit.showWaiveCoverage && (
              <DisplayButton
                controlId="showWaiveCoverage"
                className="outline-secondary btn-waivecoverage"
                displayName={lblWaiveCoverage.display}
                onClick={() => {
                  this.showModal("showMedicalWaiver");
                }}
              />
            )}

            {benefit.showDependents && (
              <DisplayButton
                controlId="showDependents"
                className="outline-secondary btn-changedependentcoverage"
                displayName={lblChangeDependents.display}
                onClick={() => {
                  this.showModal("showChangeDependentCoverage");
                }}
              />
            )}
             {showAlternateCoverageDetails && (
              <DisplayButton
                controlId="showAlternateCoverageDetails"
                className="outline-secondary btn-waivecoverage"
                displayName={lblAlternateCoverageDetails.display}
                onClick={() => {
                  this.showModal("showAlternateCoverageDetails");
                }}
              />
            )}
            {/* [FLEXS-803] Beneficiaries button right align & Remove to be left */}
            {benefit.removeBtnEOI && benefit.showBtnEOI && (
              <DisplayButton
                controlId="handleRemoveEOI"
                displayName={lblRemoveEOI.display}
                className="outline-secondary btn-remove"
                onClick={() => {
                  this.handleRemoveEOI();
                }}
              />
            )}
            <div className="float-right">
              {benefit.showBeneficiariesAssignment && (
                <DisplayButton
                  className="outline-secondary btn-beneficiaries"
                  displayName={lblViewBeneficiaries.display}
                  onClick={event => {
                    this.handleOpenBeneficiary(event, benefit.benefitName);
                  }}
                />
              )}
            </div>
            {/* [FLEXS-803] End */}
          </div>
          <Modal
            onOpened={() => setModalAttribute(this.setAreaLabel(this.state, lblDependentsCoveredUnderThisPlan, lblWaiveOptionDialogTitle))}
            aria-labelledby="modalHeading"
            centered
            isOpen={this.state.showHelpContent || this.state.showChangeDependentCoverage || this.state.showMedicalWaiver || this.state.showMedicalWaiverDependent|| this.state.showAlternateCoverageDetails}
            toggle={this.handleClose}
            className={`${this.state.showChangeDependentCoverage ? "" : "ml-addnewdependent"} coveragewaiverpopup`}
          >
            <div className="modal-header">
              <h1 className="modal-title" id="modalHeading">
                {this.state.showChangeDependentCoverage && lblDependentsCoveredUnderThisPlan.display}
                {this.state.showHelpContent && benefit.benefitName}
                {(this.state.showMedicalWaiver || this.state.showMedicalWaiverDependent) && lblWaiveOptionDialogTitle.display}
                {this.state.showAlternateCoverageDetails && lblAlternateCoverageDetailsTitle.display}
              </h1>
              <DisplayButton type="button" className="close" arialabel={closeButtonText.display} displayName="×" onClick={this.handleClose} />
            </div>
            <ModalBody className={this.state.showHelpContent ? "scroll-container" : ""}>
              {this.state.showHelpContent && (
                <Fragment>
                  {" "}
                  <div dangerouslySetInnerHTML={{ __html: this.props.helpContent }} />{" "}
                </Fragment>
              )}
              {this.state.showChangeDependentCoverage && (
                <ChangeDependedCoverage
                  isMultiSelectDependents={benefit.isMultiSelectDependents}
                  dependentsThisPlan={benefit.dependentsThisPlan}
                  dependentsOtherPlan={benefit.dependentsOtherPlan}
                  contents={benefit.contentResource.contents}
                  handleClose={this.handleClose}
                  handleAddDependent={this.handleAddDependent}
                />
              )}
              {this.state.showMedicalWaiver && <MedicalWaiver benefit={benefit} type={true} handleClose={this.handleClose} />}
              {this.state.showMedicalWaiverDependent && <MedicalWaiver benefit={benefit} type={false} handleClose={this.handleClose} />}
              {this.state.showAlternateCoverageDetails && <MedicalWaiver benefitURL={this.props.benefitURL} benefit={benefit} type={false} showAlternateCoverageDetails={true}  handleClose={this.handleClose} />}
            </ModalBody>
          </Modal>
        </Card.Body>
      </Card>
    );
  }
}
const mapStateToProps = state => {
  return {
    contents: state.userReducer.benefit && state.userReducer.benefit.contentResource ? state.userReducer.benefit.contentResource.contents : [],
    benefit: state.userReducer.benefit ? state.userReducer.benefit : { benefitName: "", lblBenefitNotes: "" },
    pageSetting: state.userReducer.pageSetting,
    helpContent: state.userReducer.helpContentResponse ? state.userReducer.helpContentResponse.result.content : "",
    footer: state.userReducer.contentFooter,
    locale: state.contentReducer.locale,
    sideBarMenu: state.contentReducer.screens,
    benefitErrorOnNext: state.userReducer.benefitErrorOnNext || false
  };
};

export default connect(mapStateToProps, { setBenefitOption, removeEOIOption, removeWaiver, getContentHelp, dissmiss, setPageSetting, setBaseBeneficiaryPageTitle, setFocusOnBenefitError })(withRouter(BenefitShell));
