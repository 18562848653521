import React, {useEffect} from "react";

const Progress = props => {
  let errorMessageRef;
  const handleDownKey = (event,clickedType) => {
    switch(event.which){
      case 13: {
        if(clickedType === 0){
          props.tryAgainUploading();
        }else if(clickedType === 1){
          props.cancelUploading();
        }
        break;
      }
      default:
        break;
    }
  }

  const {progress, message, errorMessage} = props && props

  useEffect(() => {
    if(errorMessageRef){
      errorMessageRef.focus();
    }
},[errorMessage,errorMessageRef])

  return (
    <div className="uploadFileProgressBar d-flex mb-5">
      {message && message.messageType === 'danger' && 
        <span style={{color: 'red'}}>{message.message}</span>
      }
      <span className={`filename`}>{props.filename}</span>
      <div className="uploadFileProgress" style={{ width: progress + "%" }} />
      {errorMessage && (
        <span className="errorMessage float-right">
          <span  role='alert'  >{errorMessage}</span>
          <span role='button' tid='tryAgain' tabIndex='0' onKeyDown={(event) => handleDownKey(event,0)} onClick={props.tryAgainUploading}>{props.tryAgainLabel}</span>
        </span>
      )}
      {!errorMessage && (
        <span role='button' tid='errorMessage' tabIndex='0' ref={ref => errorMessageRef = ref} onKeyDown={(event) => handleDownKey(event,1)} className={`btncancle`} onClick={props.cancelUploading}></span>
      )}
    </div>
  );
};

export default Progress;
