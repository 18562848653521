import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import { Form, InputGroup, Card } from "react-bootstrap";
import { Modal, ModalBody } from "reactstrap";
import { withRouter } from "react-router-dom";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { getDisplayText, setModalAttribute, skipMainContent } from "../utils";
import BenefitOptionComponent from "./BenefitOptionComponent";
import ChangeDependedCoverage from "./ChangeDependedCoverage";
import MedicalWaiver from "./MedicalWaiver";
import { removeEOIOption, removeWaiver, getContentHelp, setMultiBenefitOption, setFlexBeneficiaryID, setPageSetting, setBaseBeneficiaryPageTitle, setFocusOnBenefitError, saveBenefitsWithSmoker } from "../actions/userActions";
import { dissmiss, setBenefitErrorID } from "../actions/contentActions";
import DisplayButton from "./button";
import _ from "underscore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus, faQuestionCircle, faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { trackPromise } from "react-promise-tracker";
import _deep from "underscore.deepclone";

_.mixin(_deep);
class BenefitShellMulti extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionKey: props.optionKey,
      benefit: {},
      options: [],
      header: {},
      selected: "",
      showHelpContent: false,
      showChangeDependentCoverage: false,
      showMedicalWaiver: false,
      showMedicalWaiverDependent: false,
      showAlternateCoverageDetails: false,
      coverageAmount: -1,
      selectedOption: {},
      unitCoverage: props.benefit.unitCoverage ? props.benefit.unitCoverage : {},
      isExpanded: false,
      collapsedOptionList: [],
      expandSmokerBlock: false,
      smokerStatus: 2,
      smokerOptions: {}
    };
  }

  makeCollapsedOptions = (options, selected) => {
    const selectedIndex = _.findIndex(options, opt => opt === selected);
    const eoiOptionIndex = _.findIndex(options, opt => opt.requireEOI === true);
    let collapsedOptionList = [];
    if (selectedIndex === 0) {
      collapsedOptionList = options.slice(selectedIndex, selectedIndex + 3);
    } else if (selectedIndex === options.length - 1) {
      collapsedOptionList = options.slice(selectedIndex - 2);
    } else {
      collapsedOptionList = options.slice(selectedIndex - 1, selectedIndex + 2);
    }

    if (eoiOptionIndex !== -1) {
      const collapsedEOIIndex = _.findIndex(collapsedOptionList, opt => opt.requireEOI === true);
      if (collapsedEOIIndex === -1) collapsedOptionList.push(options[eoiOptionIndex]);
    }

    this.setState({ collapsedOptionList });
  };

  componentDidMount() {
    if (this.props.benefit !== this.state.benefit) {
      const options = this.props.benefit.options;
      const selectedOption = _.where(options, { isSelected: true });
      const selected = selectedOption.length ? selectedOption[0].optionID : "";
      const selectedOptionProp = options ? options.find(opt => opt.optionID === selected) : {};
      if (this.props.benefit.unitCoverage)
        if (this.props.benefit.unitCoverage.unitCoverageAmount !== this.state.coverageAmount) this.setState({ unitCoverage: this.props.benefit.unitCoverage, coverageAmount: this.props.benefit.unitCoverage.unitCoverageAmount });
      this.setState({ options, selected, selectedOption: selectedOptionProp, benefit: this.props.benefit, optionKey: this.props.optionKey, header: this.props.benefit.header }, () => setTimeout(() => this.handleSetFocusOnError(), 500));
      trackPromise(this.props.setMultiBenefitOption(this.props.benefitURL, selected, false));

      if ((options || []).length > 10) this.makeCollapsedOptions(options, selectedOptionProp);

      const smokerStatus = this.props.benefit.isEmployeeSmokerStatusRequired || this.props.benefit.isDependentSmokerStatusRequired ? this.props.benefit.smokerStatus : -1;
      this.setState({ smokerStatus });
      skipMainContent(); //to allow continue enrolment - set focus
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.benefit !== this.state.benefit) {
      const options = this.props.benefit.options;
      const selectedOption = _.where(options, { isSelected: true });
      const selected = selectedOption.length ? selectedOption[0].optionID : "";
      const selectedOptionProp = options ? options.find(opt => opt.optionID === selected) : {};
      if (this.props.benefit.unitCoverage)
        if (this.props.benefit.unitCoverage.unitCoverageAmount !== this.state.coverageAmount) this.setState({ unitCoverage: this.props.benefit.unitCoverage, coverageAmount: this.props.benefit.unitCoverage.unitCoverageAmount });
      this.setState({ options, selected, selectedOption: selectedOptionProp, benefit: this.props.benefit, optionKey: this.props.optionKey, header: this.props.benefit.header }, () => setTimeout(() => this.handleSetFocusOnError(), 500));
      trackPromise(this.props.setMultiBenefitOption(this.props.benefitURL, selected, false));

      this.setState({ smokerStatus: this.props.benefit.smokerStatus });
      if ((options || []).length > 10) this.makeCollapsedOptions(options, selectedOptionProp);
    }
    if (this.props.benefitErrorOnNext) {
      setTimeout(() => {
        this.handleSetFocusOnError();
        this.props.setFocusOnBenefitError(false);
      }, 200);
      this.setErrorFocus();
    }
  }
  setErrorFocus = () => {
    if (this.refs.dependentErrorMsg) {
      const dependentErrorMsg = ReactDOM.findDOMNode(this.refs.dependentErrorMsg);
      if (dependentErrorMsg) {
        dependentErrorMsg.focus();
      }
    } else if (this.refs.employeeErrorMsg) {
      const employeeErrorMsg = ReactDOM.findDOMNode(this.refs.employeeErrorMsg);
      if (employeeErrorMsg) {
        employeeErrorMsg.focus();
      }
    } else if (this.refs.errorMessageField) {
      const errorMessageNode = ReactDOM.findDOMNode(this.refs.errorMessageField);
      if (errorMessageNode) {
        errorMessageNode.focus();
      }
    }
  };
  componentWillUpdate() {
    if (this.props.benefit !== this.state.benefit) {
      const InputElement = document.getElementsByClassName("error-alert");
      if (InputElement && InputElement.length > 0) {
        for (var i = 0; i < InputElement.length; i++) {
          InputElement[i].setAttribute("role", "alert");
        }
      }
    }
    if (!this.props.benefit.errorMessage && this.props.benefitErrorID > 0) {
      const id = "#errorMessage-" + this.props.benefitErrorID;
      const employeeError = document.querySelector(id);
      if (employeeError) employeeError.removeAttribute("role");
      this.props.setBenefitErrorID(0);
    }
  }
  //   UNSAFE_componentWillReceiveProps(nextProps) {
  //     if (nextProps.benefit !== this.state.benefit) {
  //       const options = nextProps.benefit.options
  //       const selectedOption = _.where(options, {isSelected:true})
  //       console.log(selectedOption)
  //       const selected = selectedOption.length ? selectedOption[0].optionID : ''
  //       const selectedOptionProp = options ? options.find( opt => opt.optionID === selected) : {}
  //       if (nextProps.benefit.unitCoverage)
  //         if (nextProps.benefit.unitCoverage.unitCoverageAmount !== this.state.coverageAmount)
  //           this.setState({unitCoverage: nextProps.benefit.unitCoverage, coverageAmount: parseFloat(nextProps.benefit.unitCoverage.unitCoverageAmount)})
  //       this.setState({ options, selected, selectedOption: selectedOptionProp, benefit: nextProps.benefit, optionKey: nextProps.optionKey, header: nextProps.benefit.header });
  //       trackPromise(this.props.setBenefitOption(selected, false));

  //       if ( (options || []).length > 10)
  //         this.makeCollapsedOptions(options, selectedOptionProp)
  //     }
  //   }

  setValue = option => {
    let options = { ...this.state.options };
    _.each(options, opt => {
      opt.isSelected = opt.optionID === option.optionID;
    });
    // console.log("options: ", options);
    this.setState({ options, selected: option.optionID });
    this.setState({ isExpanded: false });
    trackPromise(this.props.setMultiBenefitOption(this.props.benefitURL, option.optionID, true));
  };

  setSmokerStatus = (smokerType, isDeclared) => {
    let smokerOptions = { ...this.state.smokerOptions };
    if (Object.prototype.hasOwnProperty.call(smokerType, "employeeSmokerStatus")) {
      smokerOptions["smokerStatus"] = parseInt(smokerType["employeeSmokerStatus"]);
      smokerOptions["isEmployeeSmokerStatusRequired"] = true;
    }
    if (Object.prototype.hasOwnProperty.call(smokerType, "dependentSmokerStatus")) {
      smokerOptions["smokerStatus"] = parseInt(smokerType["dependentSmokerStatus"]);
      smokerOptions["isDependentSmokerStatusRequired"] = true;
    }
    smokerOptions["IsSmokerStatusUpdate"] = true;
    this.setState({ smokerStatus: smokerOptions["smokerStatus"], smokerOptions });
  };

  declareSmokerStatus = () => {
    trackPromise(this.props.saveBenefitsWithSmoker(this.props.history, this.state.smokerOptions));
    this.setState({ expandSmokerBlock: !this.state.expandSmokerBlock });
  };

  resetSmokerStatus = () => {
    this.setState({ smokerStatus: this.props.benefit.smokerStatus });
  };

  showModal = modalName => {
    if (modalName === "showChangeDependentCoverage") {
      this.setState({
        prevDependentCoverage: _.deepClone(this.state.benefit)
      });
    }
    this.setState({ [modalName]: true });
  };

  handleClose = () => {
    this.setState(state => ({
      showHelpContent: false,
      showChangeDependentCoverage: false,
      showMedicalWaiver: false,
      showMedicalWaiverDependent: false,
      showAlternateCoverageDetails:false,
      benefit: state.prevDependentCoverage ? _.deepClone(this.state.prevDependentCoverage) : state.benefit
    }));
  };

  getSelectedProps = () => {
    let option = {};
    if (this.state.options) {
      option = this.state.options.find(opt => opt.optionID === this.state.selected);
    }
    return option ? option : {};
  };

  handleRemoveEOI = () => {
    trackPromise(this.props.removeEOIOption(this.props.benefitURL));
  };

  handleRemoveWaiver = () => {
    trackPromise(this.props.removeWaiver(this.props.benefitURL));
  };

  handleSmokerStatus = () => {
    this.setState({ expandSmokerBlock: !this.state.expandSmokerBlock });
  };

  handleSmokerStatusKeyDown = e => {
    if (e.which === 13) {
      this.setState({ expandSmokerBlock: !this.state.expandSmokerBlock });
    }
  };

  handleUnitCoverageUpdateValue = event => {
    const formattedValue = event.target.value.replace(/[^\d]/g, "").replace(/(^(0|1-9))[\d]/g, "$1");
    this.setState({ [event.target.name]: formattedValue });
  };

  handleSubmit = event => {
    event.preventDefault();

    const coverageAmount = parseInt(this.state.coverageAmount ? this.state.coverageAmount : this.state.unitCoverage.unitCoverageAmount);
    trackPromise(this.props.setMultiBenefitOption(this.props.benefitURL, coverageAmount, true));
  };

  handleUnitCoverageIncrement = () => {
    const unitCoverage =this.props.benefit.unitCoverage;
    if (unitCoverage) {
      let coverageAmount = parseInt(this.state.coverageAmount ? this.state.coverageAmount : unitCoverage.unitCoverageAmount);

      if (coverageAmount + unitCoverage.coveragePerUnit <= unitCoverage.maximumCoverage) {
        coverageAmount += unitCoverage.coveragePerUnit;
      } else {
        coverageAmount = unitCoverage.maximumCoverage;
      }

      this.setState({unitCoverage:unitCoverage, coverageAmount }, () => {
        trackPromise(this.props.setMultiBenefitOption(this.props.benefitURL, coverageAmount, true));
      });
    }
  };

  handleUnitCoverageDecrement = () => {
    const unitCoverage =this.props.benefit.unitCoverage;
    if (unitCoverage) {
      let coverageAmount = parseInt(this.state.coverageAmount ? this.state.coverageAmount : unitCoverage.unitCoverageAmount);

      if (coverageAmount - unitCoverage.coveragePerUnit >= 0) {
        coverageAmount -= unitCoverage.coveragePerUnit;
      } else {
        coverageAmount = 0;
      }

      this.setState({ unitCoverage:unitCoverage,coverageAmount }, () => {
        trackPromise(this.props.setMultiBenefitOption(this.props.benefitURL, coverageAmount, true));
      });
    }
  };

  handleShowHelp = () => {
    const helpLink = this.props.benefit.helpLink;
    console.log("--> HELP LINK: ", helpLink);
    if (helpLink) {
      this.props.getContentHelp(helpLink, () => {
        this.showModal("showHelpContent");
      });
    }
  };

  handleDownKey = (event, type) => {
    console.log("Event Triggered");
    switch (event.which) {
      case 13: {
        if (type === "increment") {
          this.handleUnitCoverageIncrement();
        } else if (type === "decrement") {
          this.handleUnitCoverageDecrement();
        }
        break;
      }
      default:
        break;
    }
  };
  setAreaLabel = (currentState, lblDependentsCoveredUnderThisPlan, lblWaiveOptionDialogTitle) => {
    if (currentState.showChangeDependentCoverage) {
      return lblDependentsCoveredUnderThisPlan.display;
    } else if (currentState.showHelpContent) {
      return "Help";
    } else if (currentState.showMedicalWaiver || currentState.showMedicalWaiverDependent) {
      return lblWaiveOptionDialogTitle.display;
    }
  };

  handleSetFocusOnError = () => {
    if (this.props.benefit.errorMessage) {
      if (this.props.benefitWithError && this.props.benefitURL === this.props.benefitWithError) {
        const errorMessageNode = ReactDOM.findDOMNode(this.refs.errorMessageField);
        if (errorMessageNode) {
          // errorMessageNode.focus();
        }
      }
    }
  };

  toggleExpandCollapse = () => {
    this.setState(state => ({ isExpanded: !state.isExpanded }));
  };

  handleOpenBeneficiary = (event, benefitName) => {
    const benefitID = this.props.benefitURL ? parseInt(this.props.benefitURL.split("/").pop()) : -1;
    this.props.setFlexBeneficiaryID(benefitID, () => {
      this.props.setBenefitErrorID(benefitID);
      this.props.setBaseBeneficiaryPageTitle(benefitName);
      this.props.history.push("/Beneficiaries");
    });
  };

  handleAddDependent = () => {
    const menuItem = _.find(this.props.sideBarMenu, item => (item.link || "").toUpperCase() === "DEPENDENT");
    this.props.setPageSetting(menuItem ? menuItem.name : "WELCOME", true, true);
    this.props.history.push(`/${menuItem ? menuItem.link : ""}`);
  };

  render() {
    const contents = (this.state.benefit.contentResource || {}).contents || [];
    const benefit = this.state.benefit;
    const options = this.state.options;
    const header = this.state.header;
    const footerContent = this.props.footer && this.props.footer.contentResource ? this.props.footer.contentResource.contents : {};
    const showWaiverNotes = this.state.selectedOption.showWaiverNotes ? this.state.selectedOption.showWaiverNotes : false;
    const requiresOtherCoverage = this.state.selectedOption.requiresOtherCoverage ? this.state.selectedOption.requiresOtherCoverage : false;
    const provincesRequiringOtherCoverage = this.state.selectedOption.provincesRequiringOtherCoverage ? this.state.selectedOption.provincesRequiringOtherCoverage : false;
    const showAlternateCoverageDetails=this.state.benefit.showAlternateCoverageDetails ? this.state.benefit.showAlternateCoverageDetails : false;
    const lblViewBeneficiaries = getDisplayText(contents, "lblViewBeneficiaries");
    const lblChangeDependents = getDisplayText(contents, "lblChangeDependents");
    const lblWaiveCoverage = getDisplayText(contents, "lblWaiveCoverage");
    const lblRemoveEOI = getDisplayText(contents, "lblRemoveEOI");
    const lblDependentsCoveredUnderThisPlan = getDisplayText(contents, "lblDependentsCoveredUnderThisPlan");
    const lblWaiveOptionDialogTitle = getDisplayText(contents, "lblWaiveOptionDialogTitle");
    const lblWaiverNoteOptOut = getDisplayText(contents, "lblWaiverNoteOptOut");
    const lblOtherCoverageRequiredNote = getDisplayText(contents, "lblOtherCoverageRequiredNote");
    const lblOtherCoverageRequiredForProvince = getDisplayText(contents, "lblOtherCoverageRequiredForProvince");
    const lblRemoveWaiver = getDisplayText(contents, "lblRemoveWaiver");
    const lblSeeDetails = getDisplayText(contents, "lblSeeDetails");
    const unitCoverageMessage = getDisplayText(contents, "unitCoverageMessage");
    const lblAlternateCoverageDetailsTitle = getDisplayText(contents, "lblAlternateCoverageDetailsTitle");
    const lblAlternateCoverageDetails = getDisplayText(contents, "lblAlternateCoverageDetails");
    const lnkMore = getDisplayText(footerContent, "lnkMore");
    const lnkLess = getDisplayText(footerContent, "lnkLess");
    const closeButtonText = getDisplayText(footerContent, "btnClose");
    const optionList = options && options.length > 10 ? (this.state.isExpanded ? options : this.state.collapsedOptionList) : options;
    {
      /* Handle Employee Smoker Status */
    }
    const employeeSmoker = sessionStorage.getItem("employeeSmoker") ? JSON.parse(sessionStorage.getItem("employeeSmoker")) : null;
    // const employeeBtnTxt = (benefit.isEmployeeSmokerStatusRequired && benefit.smokerStatus === 2) ? employeeSmoker.lblButtonText : employeeSmoker.lblChangeText;
    /* if(this.state.expandSmokerBlock && benefit.isEmployeeSmokerStatusRequired) {
      employeeBtnTxt = employeeSmoker.lblHideText
    } */
    const employeeErrorMsg = benefit.isEmployeeSmokerStatusRequired && benefit.smokerStatus === 2 && employeeSmoker ? employeeSmoker.lblErrorMessage : null;

    {
      /* Handle Dependent Smoker Status */
    }
    const dependentSmoker = sessionStorage.getItem("dependentSmoker") ? JSON.parse(sessionStorage.getItem("dependentSmoker")) : null;
    // const dependentBtnTxt = (benefit.isDependentSmokerStatusRequired && benefit.smokerStatus === 2) ? dependentSmoker.lblButtonText : dependentSmoker.lblChangeText;

    /* if(this.state.expandSmokerBlock && benefit.isDependentSmokerStatusRequired) {
      dependentBtnTxt = dependentSmoker.lblHideText
    } */
    const dependentErrorMsg = benefit.isDependentSmokerStatusRequired && benefit.smokerStatus === 2 && dependentSmoker ? dependentSmoker.lblErrorMessage : null;

    // let smokerStatus = benefit.isEmployeeSmokerStatusRequired || benefit.isDependentSmokerStatusRequired ? benefit.smokerStatus : -1;
    let showSmokerContent = "";
    if (benefit.isEmployeeSmokerStatusRequired) {
      showSmokerContent = employeeSmoker;
    }
    if (benefit.isDependentSmokerStatusRequired) {
      showSmokerContent = dependentSmoker;
    }
    const benefitID = this.props.benefitURL ? parseInt(this.props.benefitURL.split("/").pop()) : -1;

    let smokerStatusText = "";
    if (["0", 0].indexOf(benefit.smokerStatus) > -1) smokerStatusText = showSmokerContent?.lblNonSmokerStatusText;
    if (["1", 1].indexOf(benefit.smokerStatus) > -1) smokerStatusText = showSmokerContent?.lblSmokerStatusText;

    return (
      <Card className={`healthcarecontent clearfix`}>
        <Card.Header>
          <div className="ml-titledescription d-flex justify-content-between">
            <div className="titlehelpicon">
              <h2>{benefit.benefitName}</h2>
              {benefit.showHelp && (
                <span
                  tid="helpBtn"
                  className="icon-action"
                  role="button"
                  tabIndex="0"
                  aria-label={lblSeeDetails.textToRead}
                  onClick={() => {
                    this.handleShowHelp();
                  }}
                >
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </span>
              )}
            </div>
            {/* benefit effective date */}
            {benefit.lblBenefitNotes && <span className={`float-right m-0`} role='alert' dangerouslySetInnerHTML={{ __html: benefit.lblBenefitNotes }} />}
            {benefit.benefitNote && <span className="float-right" role='alert'>{benefit.benefitNote}</span>}
          </div>
        </Card.Header>
        <Card.Body>
          {requiresOtherCoverage && <label className={`warning note`} dangerouslySetInnerHTML={{ __html: lblOtherCoverageRequiredNote.display }} />}
          {provincesRequiringOtherCoverage && <label className={`warning note`} dangerouslySetInnerHTML={{ __html: lblOtherCoverageRequiredForProvince.display }} />}
          {benefit.showLinkedNote && <label className={`warning note`} dangerouslySetInnerHTML={{ __html: benefit.linkedNote }} />}
          {benefit.hasUnitCoverage && (
            <div className={`incrementer pt-md-3 clearfix`}>
              <Fragment>
                <span className="notetext" dangerouslySetInnerHTML={{ __html: unitCoverageMessage.display }} />
              </Fragment>
              <div className="ml-quntitybox d-flex float-right">
                <span
                  tabIndex="0"
                  tid="decrementSpan"
                  aria-label="decrement"
                  role="button"
                  onKeyDown={event => {
                    this.handleDownKey(event, "decrement");
                  }}
                  onClick={() => {
                    this.handleUnitCoverageDecrement();
                  }}
                >
                  <FontAwesomeIcon className="icon-action m-2" icon={faMinus} />
                </span>
                <Form onSubmit={this.handleSubmit}>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>$</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control aria-label={this.state.coverageAmount} name="coverageAmount" value={this.state.coverageAmount} onChange={this.handleUnitCoverageUpdateValue} onBlur={this.handleUnitCoverageUpdateValue} />
                  </InputGroup>
                </Form>
                <span
                  tid="incrementSpan"
                  tabIndex="0"
                  aria-label="increment"
                  role="button"
                  onKeyDown={event => {
                    this.handleDownKey(event, "increment");
                  }}
                  onClick={() => {
                    this.handleUnitCoverageIncrement();
                  }}
                >
                  <FontAwesomeIcon className="icon-action m-2" icon={faPlus} />
                </span>
                {/*<Col xs={{span: "1", offset: "5"}} sm={{span: "1", offset: "8"}}>
            </Col>
            <Col xs={{span: "4"}} sm={{span: "2"}}>
            </Col>
            <Col xs={{span: "1"}}>
            </Col>*/}
              </div>
            </div>
          )}
          <div className={`ml-options mt-md-4 ${benefit.hasUnitCoverage ? "" : "float-none"} clearfix`}>
            {benefit.isWaived || benefit.isTerminated ? (
              <p className={`m-0`}>{benefit.terminatedORWaived}</p>
            ) : (
              <BenefitOptionComponent
                key={benefitID}
                benefitURL={this.props.benefitURL}
                options={optionList}
                benefit={benefit}
                header={header}
                contents={contents}
                selected={this.state.selected}
                setValue={this.setValue}
                footer={this.props.footer}
                showSmokerContent={showSmokerContent}
                smokerStatus={this.state.smokerStatus}
                benefitID={benefitID}
                expandSmokerBlock={this.state.expandSmokerBlock}
                setSmokerStatus={this.setSmokerStatus}
                declareSmokerStatus={this.declareSmokerStatus}
                handleSmokerStatus={this.handleSmokerStatus}
                resetSmokerStatus={this.resetSmokerStatus}
              />
            )}
          </div>

          {options && options.length > 10 && (
            <div className="ml-showmore d-flex justify-content-end">
              <DisplayButton className="link" onClick={this.toggleExpandCollapse} displayName={this.state.isExpanded ? lnkLess.display : lnkMore.display} btnIcon={this.state.isExpanded ? faChevronUp : faChevronDown} />
            </div>
          )}
          {benefit.errorMessage && <div role="alert" className="alert alert-danger error-alert" id={`errorMessage-${benefitID}`} ref="errorMessageField" tabIndex="-1" dangerouslySetInnerHTML={{ __html: benefit.errorMessage }} />}
          {employeeErrorMsg !== null && <div role="alert" className="alert alert-danger error-alert" ref="employeeErrorMsg" tabIndex="-1" dangerouslySetInnerHTML={{ __html: employeeErrorMsg }} />}
          {dependentErrorMsg !== null && <div role="alert" className="alert alert-danger error-alert" ref="dependentErrorMsg" tabIndex="-1" dangerouslySetInnerHTML={{ __html: dependentErrorMsg }} />}
          <div className="clearfix"></div>
          {/* <d className = {`ml-popupbuttons d-flex ${navBtnsLength > 1 ? 'justify-content-between' : 'justify-content-end'}`}> */}
          <div className={`ml-popupbuttons d-flex justify-content-between`}>
          {showWaiverNotes && (
              <DisplayButton
                controlId="showMedicalWaiverDependent"
                className="outline-secondary btn-waivecoverage"
                displayName={lblWaiveCoverage.display}
                onClick={() => {
                  this.showModal("showMedicalWaiverDependent");
                }}
              />
            )}
            {benefit.showRemoveWaiver && (
              <DisplayButton
                controlId="showRemoveWaiver"
                className="outline-secondary btn-removewaiver"
                displayName={lblRemoveWaiver.display}
                onClick={() => {
                  this.handleRemoveWaiver();
                }}
              />
            )}
            {benefit.showWaiveCoverage && (
              <DisplayButton
                controlId="showWaiveCoverage"
                className="outline-secondary btn-waivecoverage"
                displayName={lblWaiveCoverage.display}
                onClick={() => {
                  this.showModal("showMedicalWaiver");
                }}
              />
            )}
            {benefit.showDependents && (
              <DisplayButton
                controlId="showDependents"
                className="outline-secondary btn-changedependentcoverage"
                displayName={lblChangeDependents.display}
                onClick={() => {
                  this.showModal("showChangeDependentCoverage");
                }}
              />
            )}
             {showAlternateCoverageDetails && (
              <DisplayButton
                controlId="showAlternateCoverageDetails"
                className="outline-secondary btn-waivecoverage"
                displayName={lblAlternateCoverageDetails.display}
                onClick={() => {
                  this.showModal("showAlternateCoverageDetails");
                }}
              />
            )}
            {/* [FLEXS-803] Beneficiaries button right align & Remove to be left */}
            {benefit.removeBtnEOI && benefit.showBtnEOI && (
              <DisplayButton
                controlId="handleRemoveEOI"
                className="outline-secondary btn-remove"
                displayName={lblRemoveEOI.display}
                onClick={() => {
                  this.handleRemoveEOI();
                }}
              />
            )}

            {/* Handle Employee Smoker Status */}
            {benefit.isEmployeeSmokerStatusRequired === true && employeeSmoker !== null && benefit.smokerStatus === 2 ? (
              <DisplayButton controlId="employeeSmokerStatus" className="outline-secondary btn-removewaiver" displayName={employeeSmoker.lblButtonText} onClick={this.handleSmokerStatus} />
            ) : (
              benefit.isEmployeeSmokerStatusRequired === true &&
              smokerStatusText && (
                <Card className="clearfix pcard sm-card">
                  <Card.Header>
                    <h2 className="heading-white">
                      {showSmokerContent.titleText}
                      <span className="btnaction btndetailpopup" tabIndex="0" role="button" aria-label={lblViewBeneficiaries.textToRead} onKeyDown={this.handleSmokerStatusKeyDown} onClick={this.handleSmokerStatus}>
                        {" "}
                        <FontAwesomeIcon className="float-right icon-action edit-icon" icon={faPencilAlt} />{" "}
                      </span>
                    </h2>
                  </Card.Header>
                  <Card.Body>
                    <div className={`ml-description m-2 mb-3 clearfix`}>
                      <Fragment>
                        {" "}
                        <p className={`m-0`} dangerouslySetInnerHTML={{ __html: smokerStatusText }} />
                      </Fragment>
                    </div>
                  </Card.Body>
                </Card>
              )
            )}

            {/* Handle Dependent Smoker Status */}
            {benefit.isDependentSmokerStatusRequired === true && benefit.smokerStatus === 2 ? (
              <DisplayButton controlId="dependentSmokerStatus" className="outline-secondary btn-removewaiver" displayName={dependentSmoker.lblButtonText} onClick={this.handleSmokerStatus} />
            ) : (
              benefit.isDependentSmokerStatusRequired === true &&
              smokerStatusText && (
                <Card className="clearfix pcard sm-card">
                  <Card.Header>
                    <h2 className="heading-white">
                      {showSmokerContent.titleText}
                      <span className="btnaction btndetailpopup" tabIndex="0" role="button" aria-label={lblViewBeneficiaries.textToRead} onKeyDown={this.handleSmokerStatus} onClick={this.handleSmokerStatus}>
                        {" "}
                        <FontAwesomeIcon className="float-right icon-action edit-icon" icon={faPencilAlt} />{" "}
                      </span>
                    </h2>
                  </Card.Header>
                  <Card.Body>
                    <div className={`ml-description m-2 mb-3 clearfix`}>
                      <Fragment>
                        {" "}
                        <p className={`m-0`} dangerouslySetInnerHTML={{ __html: smokerStatusText }} />
                      </Fragment>
                    </div>
                  </Card.Body>
                </Card>
              )
            )}

            <div className="float-right">
              {benefit.showBeneficiariesAssignment && (
                <DisplayButton
                  className="outline-secondary btn-beneficiaries"
                  displayName={lblViewBeneficiaries.display}
                  onClick={event => {
                    this.handleOpenBeneficiary(event, benefit.benefitName);
                  }}
                />
              )}
            </div>
            {/* [FLEXS-803] End */}
          </div>
          <Modal
            onOpened={() => setModalAttribute("modalHeading")}
            aria-labelledby="modalHeading"
            centered
            isOpen={this.state.showHelpContent || this.state.showChangeDependentCoverage || this.state.showMedicalWaiver || this.state.showMedicalWaiverDependent || this.state.showAlternateCoverageDetails}
            toggle={this.handleClose}
            className={`${this.state.showChangeDependentCoverage ? "" : "ml-addnewdependent"} coveragewaiverpopup`}
          >
            <div className="modal-header">
              <h1 className="modal-title" id="modalHeading">
                {this.state.showChangeDependentCoverage && lblDependentsCoveredUnderThisPlan.display}
                {this.state.showHelpContent && benefit.benefitName}
                {(this.state.showMedicalWaiver || this.state.showMedicalWaiverDependent) && lblWaiveOptionDialogTitle.display}
                {this.state.showAlternateCoverageDetails && lblAlternateCoverageDetailsTitle.display}
              </h1>
              <DisplayButton type="button" className="close" displayName="×" arialabel={closeButtonText.display} onClick={this.handleClose} />
            </div>
            <ModalBody className={this.state.showHelpContent ? "scroll-container" : ""}>
              {this.state.showHelpContent && (
                <Fragment>
                  {" "}
                  <div dangerouslySetInnerHTML={{ __html: this.props.helpContent }} />{" "}
                </Fragment>
              )}
              {this.state.showChangeDependentCoverage && (
                <ChangeDependedCoverage
                  isMultiSelectDependents={benefit.isMultiSelectDependents}
                  dependentsThisPlan={benefit.dependentsThisPlan}
                  dependentsOtherPlan={benefit.dependentsOtherPlan}
                  contents={benefit.contentResource.contents}
                  handleClose={this.handleClose}
                  benefitURL={this.props.benefitURL}
                  handleAddDependent={this.handleAddDependent}
                />
              )}
              {this.state.showMedicalWaiver && <MedicalWaiver benefitURL={this.props.benefitURL} benefit={benefit} type={true}  handleClose={this.handleClose} />}
              {this.state.showMedicalWaiverDependent && <MedicalWaiver benefitURL={this.props.benefitURL} benefit={benefit} type={false}  handleClose={this.handleClose} />}
              {this.state.showAlternateCoverageDetails && <MedicalWaiver benefitURL={this.props.benefitURL} benefit={benefit} type={false} showAlternateCoverageDetails={true}  handleClose={this.handleClose} />}
            </ModalBody>
          </Modal>
        </Card.Body>
      </Card>
    );
  }
}
const mapStateToProps = state => {
  return {
    pageSetting: state.userReducer.pageSetting,
    helpContent: state.userReducer.helpContentResponse ? state.userReducer.helpContentResponse.result.content : "",
    footer: state.userReducer.contentFooter,
    locale: state.contentReducer.locale,
    sideBarMenu: state.contentReducer.screens,
    benefitErrorOnNext: state.userReducer.benefitErrorOnNext || false,
    benefitErrorID: state.contentReducer.benefitErrorID
  };
};

export default connect(mapStateToProps, {
  removeEOIOption,
  removeWaiver,
  getContentHelp,
  dissmiss,
  setMultiBenefitOption,
  setFlexBeneficiaryID,
  setPageSetting,
  setBaseBeneficiaryPageTitle,
  setFocusOnBenefitError,
  saveBenefitsWithSmoker,
  setBenefitErrorID
})(withRouter(BenefitShellMulti));
