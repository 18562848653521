
const STATE = 'state';
const TOKEN = 'token';
const REFRESH_TOKEN = 'refreshToken';
const IS_SIGNED_IN = 'isSignedIn';
const LANGUAGE_SWITCH = 'languageSwitchRequired';
const EXPIRES_IN = 'expiresIn';
const FIRSTNAME = 'firstName';
const LASTNAME = 'lastName';
const LANGUAGE = 'Lang';
const CLIENT = 'Client';
const PAGE_SETTING = 'pageSetting';
const PERSONAL_INFO = 'personalInfo';
const EMPLOYEE_INFO = 'employeeInfo';
const MULTI_BENEFIT_LIST = 'multiBenefitList';
const BENEFIT_NAV_LINKS = 'benefitNavLinks';
const NEED_TFA_REGISTRATION = 'needTwoFARegistration';
const NEED_TFA_VALIDATION = 'needTwoFAValidation';
const TFA_TYPE_ID = 'twoFATypeID';
let DEFAULT_LANG = (((window.navigator.userLanguage || window.navigator.language).split('-')[0]) === 'fr') ? 'fr-CA' : 'en-CA';
// console.log("DEFAULT_LANG: ", DEFAULT_LANG);
export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem(STATE, serializedState);
  } catch (e) {
    console.log(e)
  }
}

export const loadState = () => {
  try {
    const state = sessionStorage.getItem(STATE);
    if ( state === null || state === '') {
      return null;
    }
    return JSON.parse(state);
  } catch (e) {
    return null;
  }
}

export const USER_TOKEN = {
  set: ({ token, refreshToken, rtoken, lifeSpan, firstName, lastName, client, lang, needTwoFARegistration, needTwoFAValidation, twoFATypeID, languageSwitchRequired }) => {
    if (token) sessionStorage.setItem(TOKEN, token);
    if (refreshToken) sessionStorage.setItem(REFRESH_TOKEN, refreshToken);
    if (rtoken && !refreshToken) sessionStorage.setItem(REFRESH_TOKEN, rtoken);
    let dt = new Date();
    dt.setSeconds( dt.getSeconds() + lifeSpan );
    sessionStorage.setItem(EXPIRES_IN, dt);
    if (firstName) sessionStorage.setItem(FIRSTNAME, firstName);
    if (lastName) sessionStorage.setItem(LASTNAME, lastName);
    if (lang) sessionStorage.setItem(LANGUAGE, lang);
    if (client) sessionStorage.setItem(CLIENT, client);
    if (needTwoFARegistration) sessionStorage.setItem(NEED_TFA_REGISTRATION, needTwoFARegistration);
    if (needTwoFAValidation) sessionStorage.setItem(NEED_TFA_VALIDATION, needTwoFAValidation);
    if (twoFATypeID) sessionStorage.setItem(TFA_TYPE_ID, twoFATypeID);
    if (languageSwitchRequired) sessionStorage.setItem(LANGUAGE_SWITCH, languageSwitchRequired);
    sessionStorage.setItem(IS_SIGNED_IN, true);
  },
  
  setLang: (lang) => {
    if (lang) sessionStorage.setItem(LANGUAGE, lang);
  },
  setClientName: (client) => {
    if (client) sessionStorage.setItem(CLIENT, client);
  },
  setPageSetting: (ps) => {
    if (ps) sessionStorage.setItem(PAGE_SETTING, JSON.stringify(ps));
  },
  setPersonalInfo: (ps) => {
    if (ps) sessionStorage.setItem(PERSONAL_INFO, JSON.stringify(ps));
  },
  setEmployeeInfo: (employeeInfo) => {
    if (employeeInfo) sessionStorage.setItem(EMPLOYEE_INFO, JSON.stringify(employeeInfo));
  },
  setMultiBenefitList: (multiBenefitList) => {
    if (multiBenefitList) sessionStorage.setItem(MULTI_BENEFIT_LIST, JSON.stringify(multiBenefitList));
  },
  setBenefitNavLinks: (benefitNavLinks) => {
    if (benefitNavLinks) sessionStorage.setItem(BENEFIT_NAV_LINKS, JSON.stringify(benefitNavLinks));
  },
  remove: () => {
    sessionStorage.removeItem(TOKEN);
    sessionStorage.removeItem(REFRESH_TOKEN);
    sessionStorage.removeItem(EXPIRES_IN);
    sessionStorage.removeItem(IS_SIGNED_IN);
    sessionStorage.removeItem(LANGUAGE_SWITCH);
    //sessionStorage.removeItem(LANGUAGE);
    //sessionStorage.removeItem(CLIENT);
    sessionStorage.removeItem(FIRSTNAME);
    sessionStorage.removeItem(LASTNAME);
    sessionStorage.removeItem(PERSONAL_INFO);
    sessionStorage.removeItem(PAGE_SETTING);
    sessionStorage.removeItem(EMPLOYEE_INFO);
    sessionStorage.removeItem(MULTI_BENEFIT_LIST);
    sessionStorage.removeItem(BENEFIT_NAV_LINKS);
    sessionStorage.removeItem(NEED_TFA_REGISTRATION);
    sessionStorage.removeItem(NEED_TFA_VALIDATION);
    sessionStorage.removeItem(TFA_TYPE_ID);
  },
  removeClientName: () => {
    sessionStorage.removeItem(CLIENT);
  },
  removeQCUser: () => {
    sessionStorage.removeItem(LANGUAGE_SWITCH);
  },
  get: () => ({
    lang: sessionStorage.getItem(LANGUAGE) || DEFAULT_LANG,
    client: sessionStorage.getItem(CLIENT),
    token: sessionStorage.getItem(TOKEN),
    refreshToken: sessionStorage.getItem(REFRESH_TOKEN),
    expiresIn: sessionStorage.getItem(EXPIRES_IN),
    isSignedIn: sessionStorage.getItem(IS_SIGNED_IN),
    languageSwitchRequired: sessionStorage.getItem(LANGUAGE_SWITCH),
    timeZoneOffset: (new Date()).getTimezoneOffset(),
    firstName: sessionStorage.getItem(FIRSTNAME),
    lastName: sessionStorage.getItem(LASTNAME),
    pageSetting: JSON.parse(sessionStorage.getItem(PAGE_SETTING)),
    personalInfo: JSON.parse(sessionStorage.getItem(PERSONAL_INFO)),
    employeeInfo: JSON.parse(sessionStorage.getItem(EMPLOYEE_INFO)),
    multiBenefitList: JSON.parse(sessionStorage.getItem(MULTI_BENEFIT_LIST)),
    benefitNavLinks: JSON.parse(sessionStorage.getItem(BENEFIT_NAV_LINKS)),
    needTwoFARegistration: sessionStorage.getItem(NEED_TFA_REGISTRATION),
    needTwoFAValidation: sessionStorage.getItem(NEED_TFA_VALIDATION),
    twoFATypeID: JSON.parse(sessionStorage.getItem(TFA_TYPE_ID)),
 }),
  get notEmpty() {
     return this.get().token !== null;
 },
};

export const DEFAULT_HEADER = {
  get: () => ({
    'Content-type': 'application/json;charset=UTF-8',
    'Lang': `${USER_TOKEN.get().lang}`,
    'Client': `${USER_TOKEN.get().client}`,
    'TimeZoneOffset': `${USER_TOKEN.get().timeZoneOffset}`,
    'Authorization': `Bearer ${USER_TOKEN.get().token}`,
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Methods': 'OPTIONS,GET,POST,PUT,DELETE',
    common: {
      'Pragma': 'no-cache',
      'Cache-Control': 'no-cache'
    }
  }),
};