import React, { Component, Fragment }  from 'react';
import { connect } from "react-redux";
import { Table, Container,Card } from 'react-bootstrap';
import {Modal, ModalBody} from 'reactstrap';
import { reallocateMultiBenefit,getBeneficiaries, createBeneficiary, deleteBeneficiary, updateBeneficiary, updateIndividualBeneficiary, setPageSetting, removeFlexBeneficiaryID } from '../actions/userActions'
import { dissmiss } from '../actions/contentActions' 
import NewBeneficiariesForm from './NewBeneficiariesForm';
import BeneficiaryDetail from './BeneficiaryDetail';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { getDisplayText,setModalAttribute } from "../utils";
import { withRouter } from 'react-router-dom';
import _ from 'underscore';
import { trackPromise } from 'react-promise-tracker';
import ReactDOM from 'react-dom';
import DisplayButton from './button';

export const FormErrors = ({formErrors}) =>
  <div className='formErrors'>
    {Object.keys(formErrors).map((fieldName, i) => {
      if(formErrors[fieldName].length > 0){
        return (
          <p className='formErrorPStyle' key={i} role='alert'>{formErrors[fieldName]}</p>
        )
      } else {
        return '';
      }
    })}
  </div>

class Beneficiaries extends Component {
  
  constructor (props) {
    super(props);
    const benefitIdList = this.props.beneficiariesMultiBenefit.result ? this.props.beneficiariesMultiBenefit.result.toString() :"-1"
    this.state = { 
      openedBeneficiaryId : '',
      isDisabled : false,
      showNewModal : false, 
      optionKey: props.optionKey,
      benefit: null,
      options: [], 
      selected: '',
      openAccordion: true,
      isEdited: false,
      showSaveModal: false,
      benefitIDs:benefitIdList?benefitIdList:'-1',
      // disablePendingIds:[],
      // disableCongPendingIds:[],

      ltrTotalPercentage: {
        "keyName": "ltrTotalPercentage",
        "display": "100",
        "textToRead": "100"
      },

      beneficiary : {
        isRevocable: "",
        percentage: 0
      },
      beneficiariesList: [],
      contBeneficiaryList: [],
      error: [],

    }
    
    document.addEventListener('click', () => 
    {
      if(document.activeElement.toString() == '[object HTMLButtonElement]')
      {
        document.activeElement.blur();
      }
    });
    
    this.handleGetBeneficiaries = this.handleGetBeneficiaries.bind(this)
  }
  UNSAFE_componentWillMount = () => {
    this.handleGetBeneficiaries()

  }

  componentWillUnmount() {
    this.props.dissmiss()
    this.props.removeFlexBeneficiaryID()
  }

  componentDidUpdate(prevState) {
    const beneficiaries = this.props.beneficiaries.beneficiaries
    const contbeneficiaries = this.props.beneficiaries.contingentBeneficiaries
    // const disabledList = [...this.state.disablePendingIds];
    // const disabledCongList = [...this.state.disableCongPendingIds];
    if(this.state.beneficiariesList !== beneficiaries ) {
      /* _.each(beneficiaries, beneficiary => {
        const congIndex = disabledCongList.indexOf(beneficiary.beneficiaryId)
        if(beneficiary.percentage > 0)
        congIndex === -1 && disabledCongList.push(beneficiary.beneficiaryId);
        else
        congIndex >= 0 && disabledCongList.splice(congIndex, 1);
      
        this.setState({disableCongPendingIds: disabledCongList})
      }); */
    
      this.setState({ beneficiariesList : beneficiaries })
    }
    
    if(this.state.contBeneficiaryList !== contbeneficiaries ) {

      /* _.each(contbeneficiaries, congBeneficiary => {
        const pendIndex = disabledList.indexOf(congBeneficiary.beneficiaryId)
        if(congBeneficiary.percentage > 0)
        pendIndex === -1 && disabledList.push(congBeneficiary.beneficiaryId);
        else
        pendIndex >= 0 && disabledList.splice(pendIndex, 1);
      
        this.setState({disablePendingIds: disabledList})
      }); */

      this.setState({ contBeneficiaryList : contbeneficiaries })
    }

  }

  resetTabOrder = (elem) => {
    const yOffset = -70;
    let element = document.getElementById(elem)
    let position = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    element.setAttribute("tabIndex", "-1");
    element.focus();
    window.scrollTo({top: position, behavior: 'smooth'});
  }

  handleClose = (isSaveNClose) => {
    this.setState({ showNewModal: false }, () => isSaveNClose === true && this.resetTabOrder('pending-beneficiaries'));
  }
  
  handleShow = () => {
    this.setState({ showNewModal: true }); 
  }
  handleGetBeneficiaries = () => {
    this.props.benefitID ? trackPromise(this.props.getBeneficiaries(this.props.benefitID, this.props.beneficiariesAll)) : trackPromise(this.props.getBeneficiaries())
  }

  getTotalPer = (beneficiary) =>{

    let newTotal = 0

    if((beneficiary || {}).length > 0){
      beneficiary.map(item => {
        return newTotal = item.percentage === "" ? null : item.percentage + parseInt(newTotal)
      })
    }

    return isNaN(newTotal) ? null : newTotal
  }

  isTotalValid = () => {
    const perValues = (this.state.beneficiariesList || {}).length > 0 ? this.state.beneficiariesList : this.props.beneficiaries.beneficiaries
    const perValuesCont = (this.state.contBeneficiaryList || {}).length > 0 ? this.state.contBeneficiaryList : this.props.beneficiaries.contingentBeneficiaries
    const totalValue = this.getTotalPer(perValues)
    const totalValueCont = this.getTotalPer(perValuesCont)
    return totalValue && totalValueCont !== null ? true : false
  }

  setValue = (option) => {
    let options = {...this.state.options}
    _.each(options, opt=>{opt.isSelected = opt.optionID === option.optionID})
    this.setState({options, selected: option.optionID})
  }

  toggleAccordion = () => {
    this.setState((oldState) => ({
      openAccordion: !oldState.openAccordion,
    }));
  }

  setBeneficiary = (newBeneficiary) => {
    this.setState({
      beneficiary: newBeneficiary
    })
  }

  updateBeneficiaryList = (list) => {
    this.setState({
      beneficiariesList: list
    })
  }
  updateCongBeneficiaryList = (list) => {
    this.setState({
      contBeneficiaryList: list
    })
  }

  onSave = (event) => {
    this.setState({
      error: []
    })
     trackPromise(this.props.updateBeneficiary(this.state.beneficiariesList, this.state.contBeneficiaryList, this.props.benefitID ? this.props.benefitID : null, (err) => {
      // beneficiary[name] = parseInt(0)
      this.setState({error: err}, 
        () => {
          if (this.refs.errorMessage)
            ReactDOM.findDOMNode(this.refs.errorMessage).focus()
        }
      )
    }, () => {

      if (this.props.isCancelVisible){
          this.handleGetBeneficiaries()  
          this.getReallocateMultiBenefit()        
      }
      else 
      this.previousPage()
      this.props.hideEdit && this.props.hideEdit(event, this.props.benefitID)
      this.setState({isEdited: false}, 
        () => this.handlePackageEdit()
      );
    }));
  }
  getReallocateMultiBenefit = () => {
    if(this.state.benefitIDs !=='-1'){
      trackPromise(this.props.reallocateMultiBenefit(this.props.pageSetting.name==="BENEFICIARIES"?-1:this.props.benefitID)); 
    } 
  }
  /* E-Signature Start */
  onSaveAndSign = (event, actionLink) => {
    this.setState({
      error: []
    })
    trackPromise(this.props.updateBeneficiary(this.state.beneficiariesList, this.state.contBeneficiaryList, this.props.benefitID ? this.props.benefitID : null, (err) => {
      // beneficiary[name] = parseInt(0)
      this.setState({error: err}, 
        () => {
          if (this.refs.errorMessage)
            ReactDOM.findDOMNode(this.refs.errorMessage).focus()
        }
      )
    }, () => {
      if (this.props.isCancelVisible) {
        this.handleGetBeneficiaries()
        this.props.history.push(`/${actionLink}`)
      } else {
        this.previousPage()
      }
      this.props.hideEdit && this.props.hideEdit(event, this.props.benefitID)
      this.setState({isEdited: false}, () => this.handlePackageEdit());
    }));
  }


  /* E-Signature End */

  onOpenBeneificiary = (benId) =>{
    this.setState({openedBeneficiaryId: benId})
  }

  previousPage = () => {
    trackPromise(this.props.setPageSetting(this.props.pageSetting.name))
    if (typeof this.props.pageSetting.link === "string")
       this.props.history.push(this.props.pageSetting.link)
     else
      this.props.history.push("/Benefits")
    
  }

  getBenefitTitle = () => {
    let benefitTitle = ""

    if (this.props.benefitTitle) {
      benefitTitle = this.props.benefitTitle
    } else if (this.props.basePageItem) {
      benefitTitle = this.props.basePageItem
    }

    return benefitTitle
  }

  checkIfCanGoBack = (event) => {
    this.getReallocateMultiBenefit()      
    if (this.state.isEdited)
      this.setState({showSaveModal: true})
    else 
      this.handleCloseSaveModal(event)
  }

  handleCloseSaveModal = (event) => {
    if (!this.props.hideEdit)
      this.previousPage() 
    else 
      this.props.handleCancelEdit(event)
    this.handleCancelSaveModal()
    this.props.removeFlexBeneficiaryID()
  }

  handleCancelSaveModal = () => {
    this.setState({showSaveModal: false})
  }

  hasEdited = () => {
    if (!this.state.isEdited)
      this.setState({isEdited: true},
        () => this.handlePackageEdit()
      );
  }

  handlePackageEdit = () => {
    
    if (this.props.handleEdit) 
      this.props.handleEdit(this.props.benefitID, this.state.isEdited)
  }

  render = () => {  
    let beneficiariesComponent = []
    let onFileBeneficiariesList = []
    const beneficiariesFull = this.props.beneficiaries
    const beneficiaries = this.props.beneficiaries.beneficiaries
    const benefitBeneficiaries = ((this.props.beneficiaries.contentResource || {}).controlResource || {}).BenefitBeneficiaries || []
    const contents = (this.props.beneficiaries.contentResource || {}).contents || []
    const onFileBeneficiaryInfo = this.props.beneficiaries.onFileBeneficiaryInfo
    const benefitTitle = this.getBenefitTitle() //this.pthis.props.basePageItem ? this.props.basePageItem.display : "Benefit Name"
    const benefitID=(this.props.benefitID && this.props.benefitID !==-1)?this.props.benefitID:this.props.flexbenefitID
    const lblAddBeneficiaryTitle = getDisplayText(benefitBeneficiaries,'lblAddBeneficiaryTitle')
    const lblViewBeneficiaries = getDisplayText(benefitBeneficiaries, 'lblViewBeneficiaries')
    const lblNoBeneficiaries = getDisplayText(benefitBeneficiaries, 'lblNoBeneficiaries')
    const thcPercent = getDisplayText(benefitBeneficiaries,'thcPercent')
    
    /* Beneficiaries new design */
    const thcName = getDisplayText(benefitBeneficiaries,'thcName');
    const thcRelationship = getDisplayText(benefitBeneficiaries,'thcRelationship');
    const thcTrustee = getDisplayText(benefitBeneficiaries,'thcTrustee');

    const lblViewBeneficiaryTitle = getDisplayText(benefitBeneficiaries, beneficiariesFull.showPendingBeneficiaryInfo ? 'lblPending' : 'lblBeneficiaries')
    const lblBeneficiariesRequired = getDisplayText(benefitBeneficiaries, 'lblBeneficiariesRequired')
    const lblNoCoverage = getDisplayText(contents, 'lblNoCoverage')
    const lblOnFile = getDisplayText(benefitBeneficiaries, 'lblOnFile')
    const lblPendingNote = getDisplayText(benefitBeneficiaries, 'lblPendingNote')
    const btnSave = getDisplayText(contents, 'btnSave')
    const closeButtonText = getDisplayText(contents,"btnClose")
    const lblCancel = getDisplayText(this.props.contentFooter ? this.props.contentFooter : {}, 'btnCancel')
    //const expandCollapseIcon = getDisplayText(this.props.contentFooter ? this.props.contentFooter : {}, 'ExpandCollapseIcon')
   
    const backButtonText = getDisplayText((this.props.contentFooter && this.props.contentFooter) || {},'btnBack')
    document.title = lblViewBeneficiaries.display
    const thcTotal = getDisplayText(benefitBeneficiaries, 'ltrTotal')
    const totalValueCustomClass = parseInt(this.getTotalPer((this.state.beneficiariesList || {}).length > 0 ? this.state.beneficiariesList : beneficiaries)) !== 100 ? "ml-error": ""
    const confirmSaveMessage = getDisplayText(benefitBeneficiaries, 'confirmSaveMessage')
    const noButtonLabel = getDisplayText(this.props.contentFooter ? this.props.contentFooter : {}, 'btnCancel')
    const yesButtonLabel = getDisplayText(this.props.contentFooter ? this.props.contentFooter : {}, 'lblYes')
    
    /* E-Signature Start */
    const lblOnFileESign = getDisplayText(benefitBeneficiaries, 'lblOnFileESign')
    const beneficiaryESignature =this.props.beneficiaries.beneficiaryESignature || null
    const lblContingentOnFileESign = getDisplayText(benefitBeneficiaries, 'lblContingentOnFileESign')
    const lblPendingESignatureNote = getDisplayText(benefitBeneficiaries, 'lblPendingESignatureNote')
    /* E-Signature End */

    /* Contingent Beneficiaries */
    const lblContingentOnFile = getDisplayText(benefitBeneficiaries, 'lblContingentOnFile')
    const lblContingentPending = getDisplayText(benefitBeneficiaries, 'lblContingentPending')
    const onFileContingentBeneficiaryInfo = this.props.beneficiaries.onFileContingentBeneficiaryInfo
    const contingentBeneficiaries = this.props.beneficiaries.contingentBeneficiaries
    const onFileContingentBeneficiariesList = []
    const pendingContingentComponent = []
    const totalValueContingentClass = parseInt(this.getTotalPer((this.state.contBeneficiaryList || {}).length > 0 ? this.state.contBeneficiaryList : contingentBeneficiaries)) !== 100 ? "ml-error": ""
    
    _.each(beneficiaries, (item, index)=>{
      beneficiariesComponent.push(
        <BeneficiaryDetail
          key={`d-div-${item.beneficiaryId}-${index}`}
          index={index}
          beneficiaryId={item.beneficiaryId}
          canBeRemoved = {item.removeEnabled}
          beneficiary = {item}
          beneficiaries={beneficiaries}
          beneficiariesFull={beneficiariesFull}
          deleteBeneficiary = {this.props.deleteBeneficiary}
          updateBeneficiary = {this.props.updateBeneficiary}
          getBeneficiaries = {this.handleGetBeneficiaries}
          contents={contents}
          benefitBeneficiaries={benefitBeneficiaries}
          onOpenBeneificiary = {this.onOpenBeneificiary}
          isDisabled= {this.state.openedBeneficiaryId === '' ? false : this.state.openedBeneficiaryId ===  item.beneficiaryId ? false : true}
          setBeneficiary = {this.setBeneficiary}
          updateIndividualBeneficiary = {this.props.updateIndividualBeneficiary}
          updateBeneficiaryList = {this.updateBeneficiaryList}
          ItemIndex={index}
          contentFooter={this.props.contentFooter}
          hasEdited={this.hasEdited}
          benefitID={benefitID}
          removeBeneficiaryResponse={this.props.removeBeneficiaryResponse}
          createBeneficiary={this.props.createBeneficiary}
          lblCancel= {lblCancel}
          locale = {this.props.locale}
          close={this.handleClose}
          pageSetting={this.props.pageSetting}
        />
      )
    })

    _.each(contingentBeneficiaries, (item, index)=>{

      pendingContingentComponent.push(
        <BeneficiaryDetail
          key={`d-div-${item.beneficiaryId}-${index}`}
          index={index}
          beneficiaryId={item.beneficiaryId}
          canBeRemoved = {item.removeEnabled}
          beneficiary = {item}
          beneficiaries={contingentBeneficiaries}
          beneficiariesFull={beneficiariesFull}
          deleteBeneficiary = {this.props.deleteBeneficiary}
          updateBeneficiary = {this.props.updateBeneficiary}
          getBeneficiaries = {this.handleGetBeneficiaries}
          contents={contents}
          benefitBeneficiaries={benefitBeneficiaries}
          onOpenBeneificiary = {this.onOpenBeneificiary}
          isDisabled= {this.state.openedBeneficiaryId === '' ? false : this.state.openedBeneficiaryId ===  item.beneficiaryId ? false : true}
          setBeneficiary = {this.setBeneficiary}
          updateIndividualBeneficiary = {this.props.updateIndividualBeneficiary}
          updateBeneficiaryList = {this.updateCongBeneficiaryList}
          ItemIndex={index}
          contentFooter={this.props.contentFooter}
          hasEdited={this.hasEdited}
          benefitID={benefitID}
          removeBeneficiaryResponse={this.props.removeBeneficiaryResponse}
          createBeneficiary={this.props.createBeneficiary}
          close={this.handleClose}
          lblCancel= {lblCancel}
          locale = {this.props.locale}
          pageSetting={this.props.pageSetting}
        />
      )
    })

    _.each(onFileBeneficiaryInfo, (item, index) => {
      onFileBeneficiariesList.push(
        <tr key={`d-div-onFileBeneficiary-${index}`}>
          <td dangerouslySetInnerHTML={{ __html: `<strong>${item.beneficiaryName}</strong><br />${item.dateOfBirth ? item.dateOfBirth : "n/a"}`}} />
          <td dangerouslySetInnerHTML={{ __html: item.relationShip ? item.relationShip : "n/a"}} />
          <td dangerouslySetInnerHTML={{ __html: item.trustee ? item.trustee : "n/a"}} />
          {(item.removedMsg==="" || item.removedMsg==null)?<td className="text-right">{item.percentage}%</td>:<td><span className="ml-mandatory-sign">({item.removedMsg})</span> {item.percentage}%</td>}
        </tr>
      )
    });

    _.each(onFileContingentBeneficiaryInfo, (item, index) => {
      onFileContingentBeneficiariesList.push(
        <tr key={`d-div-onFileBeneficiary-${index}`}> 
          <td dangerouslySetInnerHTML={{ __html: `<strong>${item.beneficiaryName}</strong><br />${item.dateOfBirth ? item.dateOfBirth : "n/a"}`}} />
          <td dangerouslySetInnerHTML={{ __html: item.relationShip ? item.relationShip : "n/a"}} />
          <td dangerouslySetInnerHTML={{ __html: item.trustee ? item.trustee : "n/a"}} />
          {(item.removedMsg==="" || item.removedMsg==null)?<td className="text-right">{item.percentage}%</td>:<td><span className="ml-mandatory-sign">({item.removedMsg})</span> {item.percentage}%</td>}
        </tr>
      )
    })
    
    const cardFooterBtns = {
      handleShow: 
      (<DisplayButton controlId="showModalBtn" displayName={lblAddBeneficiaryTitle.display} className="outline-secondary btn-addnewbeneficiary" onClick={this.handleShow} />),
      handleCancel: 
      (
        <DisplayButton displayName={lblCancel.display} className="outline-secondary btn-cancel" onClick={this.checkIfCanGoBack} />
      ),
      handleSave: (
        /* E-Signature Start */
        beneficiariesFull.isEsignable && beneficiaryESignature ? <DisplayButton displayName={beneficiaryESignature.text} className="outline-secondary btn-save"  onClick={ (e) => this.onSaveAndSign(e, beneficiaryESignature.actionlink) } disabled={!this.isTotalValid()} ></DisplayButton> : <DisplayButton className="outline-secondary btn-save" displayName={btnSave.display}  onClick={this.onSave} disabled={!this.isTotalValid()}/>
      )
    }
    
    return (
      <div className={`baneficiarylist`}>
        {!this.props.hideEdit && <div className={`d-flex titlewithicon mb-md-2`}>
        <DisplayButton className="icon-action" displayName={backButtonText.display} icon={faChevronLeft} onClick={this.checkIfCanGoBack} /> 
        </div>}
      <Card className="clearfix pcard">
        <Card.Header>
          <h2 className="heading-white">{benefitTitle}</h2>
        </Card.Header>
        <Card.Body>
        {
          beneficiariesFull.showlblNoCoverage && 
          <span className="d-block" aria-hidden={!beneficiariesFull.showlblNoCoverage}>{lblNoCoverage.display}</span>
        }
        {beneficiariesComponent.length === 0 && <div className={`alert alert-primary ml-description mb-4 clearfix`} role='alert'>
          <Fragment> <p className={`m-0`}  dangerouslySetInnerHTML={{__html: lblNoBeneficiaries.display}} /> </Fragment>
        </div>}

        {/* On File */}
        {onFileBeneficiariesList.length > 0 && 
          <Fragment>
            <div className="tbl-beneficiaries">
              {/* E-Signature Start */}
                <h3 className="title">{beneficiariesFull.isEsignable ? lblOnFileESign.display : lblOnFile.display}</h3>
              {/* E-Signature End */}
              <Table className="table-beneficiaries">
                <thead>
                  <tr>
                    <th scope="col">{thcName.display}</th>
                    <th scope="col">{thcRelationship.display}</th>
                    <th scope="col">{thcTrustee.display}</th>
                    <th scope="col">{thcPercent.display}</th>
                  </tr>
                </thead>
                <tbody>{onFileBeneficiariesList}</tbody>
                <tfoot>
                  <tr>
                    <td colSpan="3" className="text-right no-right-border">
                      <strong>{thcTotal.display}</strong>
                    </td>
                    <td>
                      <strong className="m-0" >
                        {this.getTotalPer( (onFileBeneficiaryInfo || []).length > 0 ? onFileBeneficiaryInfo : 0)}%
                      </strong>
                    </td>
                  </tr>
                </tfoot>
              </Table>
            </div>
          </Fragment>
        }
        {/* On File Contingent Beneficiaries */}
        {onFileContingentBeneficiariesList.length > 0 && 
          <Fragment>
            <div className="tbl-beneficiaries">
              {/* E-Signature Start */}
                <h3 className="title">{beneficiariesFull.isEsignable ? lblContingentOnFileESign.display : lblContingentOnFile.display}</h3>
              {/* E-Signature End */}
              <Table className="table-beneficiaries">
                <thead>
                  <tr>
                    <th scope="col">{thcName.display}</th>
                    <th scope="col">{thcRelationship.display}</th>
                    <th scope="col">{thcTrustee.display}</th>
                    <th scope="col">{thcPercent.display}</th>
                  </tr>
                </thead>
                <tbody>{onFileContingentBeneficiariesList}</tbody>
                <tfoot>
                  <tr>
                    <td colSpan="3" className="text-right no-right-border">
                      <strong>{thcTotal.display}</strong>
                    </td>
                    <td>
                      <strong className="m-0" >
                        {this.getTotalPer( (onFileContingentBeneficiaryInfo || []).length > 0 ? onFileContingentBeneficiaryInfo : 0)}%
                      </strong>
                    </td>
                  </tr>
                </tfoot>
              </Table>
            </div>
          </Fragment>
        }

        {/* Pending */}
        {beneficiariesComponent.length > 0 &&
          <Fragment>
          <div className="tbl-beneficiaries" ref="pending-beneficiaries" id="pending-beneficiaries">
            <h3 className="title">{lblViewBeneficiaryTitle.display}</h3>
            <Table>
              <thead>
                <tr>
                  <th scope="col">{thcName.display}</th>
                  <th scope="col">{thcRelationship.display}</th>
                  <th scope="col">{thcTrustee.display}</th>
                  <th scope="col">{thcPercent.display}</th>
                </tr>
              </thead>
              <tbody>{beneficiariesComponent}</tbody>
              <tfoot>
                <tr>
                  <td colSpan="3" className="text-right no-right-border">
                    <strong>{thcTotal.display}</strong>
                  </td>
                  <td>
                    <strong className={`m-0 ${totalValueCustomClass}`}>
                      {this.getTotalPer( (this.state.beneficiariesList || []).length > 0 ? this.state.beneficiariesList : beneficiaries)}%
                    </strong>
                  </td>
                </tr>
              </tfoot>
            </Table>
          </div>
          </Fragment>
        }
        
        {/* Pending Contingent Beneficiaries */}
        {pendingContingentComponent.length > 0 &&
          <Fragment>
            <div className="tbl-beneficiaries">
              <h3 className="title">{lblContingentPending.display}</h3>
              <Table>
                <thead>
                  <tr>
                    <th scope="col">{thcName.display}</th>
                    <th scope="col">{thcRelationship.display}</th>
                    <th scope="col">{thcTrustee.display}</th>
                    <th scope="col">{thcPercent.display}</th>
                  </tr>
                </thead>
                <tbody>{pendingContingentComponent}</tbody>
                <tfoot>
                  <tr>
                    <td colSpan="3" className="text-right no-right-border">
                      <strong>{thcTotal.display}</strong>
                    </td>
                    <td>
                      <strong className={`m-0 ${totalValueContingentClass}`} >
                        {this.getTotalPer( (this.state.contBeneficiaryList || []).length > 0 ? this.state.contBeneficiaryList : contingentBeneficiaries)}%
                      </strong>
                    </td>
                  </tr>
                </tfoot>
              </Table>
            </div>
          </Fragment>
        }
        
        {/* Messages */}
        {this.state.error.length > 0 ? (typeof this.state.error === "string" ? 
          (<Fragment><p  ref="errorMessage" role='alert' tabIndex="-1"  className="d-block alert alert-danger mb-2" dangerouslySetInnerHTML={{ __html: this.state.error}} />
            </Fragment>) 
          : ( this.state.error.map ( e => <Fragment><p  ref="errorMessage" tabIndex="-1"  role='alert'  className="ml-inputfields has-error formErrors formErrorPStyle" dangerouslySetInnerHTML={{ __html: e}} />
          </Fragment>)
          )
        ) : ''}
        
        {/* E-Signature Start */}
        {beneficiariesFull.isEsignable && beneficiariesFull.showPendingBeneficiaryInfo &&
          <span className="d-block mb-2 alert alert-info"  role='alert'  >{lblViewBeneficiaryTitle.display} <strong>{lblPendingESignatureNote.display}</strong></span>
        }
        {/* E-Signature End */}
        
        {!beneficiariesFull.isEsignable && beneficiariesFull.showPendingBeneficiaryInfo &&
          <span className="d-block mb-2 alert alert-info"  role='alert'  >{lblViewBeneficiaryTitle.display} <strong>{lblPendingNote.display}</strong></span>
        }

        {beneficiariesFull.beneficiariesMessage !== "" &&
          <span className="d-block mb-2 alert alert-info"  role='alert'  dangerouslySetInnerHTML={{__html: beneficiariesFull.beneficiariesMessage}} />
        }

        {/* Buttons */}
        { beneficiariesComponent.length !== 0 && !this.props.isCancelVisible && 
          <div className="d-flex justify-content-between py-3 btnsfoot">
            { cardFooterBtns.handleShow }
            { cardFooterBtns.handleSave }
          </div>
        }
        { beneficiariesComponent.length !== 0 && this.props.isCancelVisible &&
          <>
            <div className="d-flex justify-content-between py-3 btnsfoot">
              { cardFooterBtns.handleShow }
            </div>
            <div className="d-flex justify-content-between py-3 btnsfoot">
              { cardFooterBtns.handleCancel }
              { cardFooterBtns.handleSave }
            </div>
          </>
        }
        { beneficiariesComponent.length === 0 && this.props.isCancelVisible &&
          <div className="d-flex justify-content-between py-3 btnsfoot">
            { cardFooterBtns.handleShow }
            { cardFooterBtns.handleCancel }
          </div>
        }
        </Card.Body>
      </Card>

        {beneficiariesComponent.length === 0 && !this.props.isCancelVisible &&
          <DisplayButton className="outline-secondary btn-addnewbeneficiary mt-3" displayName={lblAddBeneficiaryTitle.display} onClick={this.handleShow}/>
        }
        
        <Modal onOpened={() => setModalAttribute(lblAddBeneficiaryTitle.textToRead)} aria-labelledby="modalHeading" className="ml-addnewbeneficiary" isOpen={this.state.showNewModal} toggle={this.handleClose}>
          <div className="modal-header">
            <h1 className="modal-title" id='modalHeading'>
              {lblAddBeneficiaryTitle.display}
            </h1>
            <DisplayButton type="button"  className="close" displayName="×" arialabel={closeButtonText.display}  onClick={this.handleClose}/> 
          </div>
            <ModalBody>
              <div className="row">
                <Container className="scroll-container">
                  {/* {lblAddBeneficiaryTitle.display} */}
                    <NewBeneficiariesForm
                      beneficiary={this.props.beneficiary}
                      beneficiaries={this.props.beneficiaries}
                      beneficiariesFull={beneficiariesFull}
                      createBeneficiary={this.props.createBeneficiary}
                      isRevocable = {this.props.isRevocable}
                      close={this.handleClose}
                      getBeneficiaries={this.handleGetBeneficiaries}
                      onOpenBeneificiary={this.onOpenBeneificiary}
                      lblCancel= {lblCancel}
                      locale = {this.props.locale}
                      isEditMode={false}
                      benefiaryType='person'
                      closeButtonText={closeButtonText}
                    />
                </Container>
              </div>
            </ModalBody>
          </Modal>

           <Modal onOpened={() => setModalAttribute(lblViewBeneficiaries.textToRead)} aria-labelledby="modalHeading" aria-describedby="modalDescription" className="ml-cancelenrollment" isOpen={this.state.showSaveModal} toggle={this.handleCancelSaveModal} centered>
          <div className="modal-header">
            <h1 className="modal-title" id='modalHeading'>
            {lblViewBeneficiaries.display}
            </h1>
            <DisplayButton type='button'  className="close" displayName='×' onClick={this.handleCancelSaveModal}/> 
          </div>
          <ModalBody>
            <div className="ml-popupmessage" id='modalDescription'>
                <Fragment><h5 dangerouslySetInnerHTML={{ __html: confirmSaveMessage.display}} /></Fragment>
            </div>
            <div className={'ml-buttons d-flex justify-content-between'}>
                <DisplayButton controlId="handleClose" className="outline-secondary" displayName={noButtonLabel.display}  onClick={this.handleCancelSaveModal}/>
                <DisplayButton controlId="handleCancel" className="secondary" displayName={yesButtonLabel.display}  onClick={this.handleCloseSaveModal}/>
            </div>
          </ModalBody>
        </Modal>

          {beneficiariesFull.isBeneficiariesRequired && 
          <Fragment><span className="d-block text-danger mb-2"  role='alert'  dangerouslySetInnerHTML={{ __html: lblBeneficiariesRequired.display}} /></Fragment>
          }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const contents1 = state.userReducer.beneficiaries.contentResource ? state.userReducer.beneficiaries.contentResource.contents : []
  const contents2 = (state.userReducer.beneficiaries&&state.userReducer.beneficiaries.contentResource&&state.userReducer.beneficiaries.contentResource.controlResource.BenefitBeneficiaries) ? state.userReducer.beneficiaries.contentResource.controlResource.BenefitBeneficiaries : []
  const contents = _.union(contents1, contents2)

  return { 
    beneficiaries: state.userReducer.beneficiaries,
    locale: state.contentReducer.locale, 
    contentResource: contents ? contents : {}, //,
    pageSetting: state.userReducer.pageSetting,
    contentFooter: (state.userReducer.contentFooter && state.userReducer.contentFooter.contentResource) ? state.userReducer.contentFooter.contentResource.contents : {},
    basePageItem: state.userReducer.basePageTitle,
    removeBeneficiaryResponse:state.userReducer.removeBeneficiaryResponse,
    beneficiariesMultiBenefit: state.userReducer.beneficiariesMultiBenefit ? state.userReducer.beneficiariesMultiBenefit : {},
    flexbenefitID:state.userReducer.flexBeneficiaryID
  }
};

export default connect(
mapStateToProps,
{ reallocateMultiBenefit,getBeneficiaries, createBeneficiary, deleteBeneficiary, updateBeneficiary, updateIndividualBeneficiary, dissmiss, setPageSetting, removeFlexBeneficiaryID }
)(withRouter(Beneficiaries));
