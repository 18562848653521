import React, { Component } from "react";
import { getDisplayText, getControlId } from "../../utils";
import { Row, Form, Col } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import DisplayButton from "../button";

class QRForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otpCode: "",
      inActive: true
    };
  }

  setOtpCode = e => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      this.setState({ otpCode: e.target.value });
      if (e.target.value.length === 6) {
        this.setState({ inActive: false });
      } else {
        this.setState({ inActive: true });
      }
    }
  };

  failureCallback = msg => {
    this.setState({ lbldisabled: false });
    this.props.showError(msg ? msg : "Please enter a valid verification code.");
  };

  handleKeyPress = event => {
    if (event.key === "Enter" || event.charCode === 13) {
      this.handleSubmit(event);
    }
  };

  handleSubmit = event => {
    event.preventDefault();
    const options = {
      Code: this.state.otpCode,
      IsRegistration: true,
      TypeID: 4
    };
    trackPromise(this.props.validateTFACode(options, this.failureCallback, this.props.history, this.props.showSuccess));
  };

  render() {
    const regContent = this.props.regContent;
    const content = regContent.contentResource ? regContent.contentResource.contents : [];

    const lblDetail2 = getDisplayText(content, "lblDetail2");

    const lblTitle = getDisplayText(content, "lblTitle");
    const lblDetail1 = getDisplayText(content, "lblDetail1");
    const lblOptionlOTP = getDisplayText(content, "lblOptionlOTP");
    const lblOption2OTP = getDisplayText(content, "lblOption2OTP");
    const lblOption3OTP = getDisplayText(content, "lblOption3OTP");
    // const qrCodeLink = getDisplayText(regContent, 'qrCodeLink');
    const qrCodeLink = regContent.qrCodeLink || "";
    const lblverificationCode = getDisplayText(content, "lblverificationCode");

    const btnVerify = getDisplayText(content, "btnVerify");

    const lblDetailOptionlPhone = getDisplayText(content, "lblDetailOptionlPhone");

    const lblCancel = getDisplayText(content, "btnCancel");
    const lblQRCode = getDisplayText(content, "lblQRCode");

    return (
      <div className="conatiner" role="main" id="maincontent">
        <div className="login-holder">
          <Row className="h-100">
            <div className="col-12 login-image-holder"></div>
            <div className="logincontainer d-md-flex align-items-center align-content-center justify-content-between">
              <div className="holder login-form description panel d-none d-md-block">
                {/* <h1 className="login-heading d-sm-block d-md-none">{lblTitle.display}</h1> */}
                <span dangerouslySetInnerHTML={{ __html: lblDetail2.display }} />
              </div>
              <div className="form-group login-form mobile-login-form pb-5 pb-md-0" xs={{ order: 1 }} md={{ order: 2 }} lv={{ order: 2 }} style={{ paddingRight: "1rem" }}>
                <h1 className="login-heading d-md-block">{lblTitle.display}</h1>
                <div className="login-form description panel d-md-none">
                  <span dangerouslySetInnerHTML={{ __html: lblDetail2.display }} />
                </div>
                <div className="holder">
                  <div id="login-container" className="loginform">
                    <Row>
                      <Col className="col-12">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: lblDetail1.display
                          }}
                        />
                        <div>
                          <ol
                            style={{
                              fontSize: "14px"
                            }}
                          >
                            <li
                              dangerouslySetInnerHTML={{
                                __html: lblOptionlOTP.display
                              }}
                            />
                            <li
                              dangerouslySetInnerHTML={{
                                __html: lblOption2OTP.display
                              }}
                            />
                            <li className="nobull">
                              <img src={qrCodeLink} alt={lblQRCode.textToRead} />
                            </li>
                            <li
                              dangerouslySetInnerHTML={{
                                __html: lblOption3OTP.display
                              }}
                            />
                          </ol>
                        </div>

                        {regContent.showOptionalPhone && regContent.isPhoneNoExists && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: lblDetailOptionlPhone.display
                            }}
                          />
                        )}
                      </Col>

                      <Col className="col-12 paddingbottom">
                        <label htmlFor={getControlId(lblverificationCode.textToRead)} className="label-heading">
                          {lblverificationCode.display}
                        </label>
                        <Form.Control
                          type="text"
                          autoComplete="off"
                          placeholder={lblverificationCode.display}
                          className="form-control login-input-field"
                          id={getControlId(lblverificationCode.textToRead)}
                          ref="otpCode"
                          value={this.state.otpCode}
                          maxLength="6"
                          onChange={this.setOtpCode}
                          onKeyPress={this.handleKeyPress}
                        />
                      </Col>

                      <Col>
                        <div className={`btns-forgot d-flex justify-content-between`}>
                          {regContent.allowCancel && <DisplayButton className="outline-secondary" displayName={lblCancel.display} onClick={this.handleCancel} controlId="cancelBtn" />}
                          <DisplayButton className="secondary" type="submit" displayName={btnVerify.display} onClick={this.handleSubmit} disabled={this.state.inActive} />
                        </div>
                      </Col>
                    </Row>
                    {/* <Row>
                                            {this.state.errorMessage && (
                                                <Col className='col-12 paddingbottom'>
                                                    <div
                                                        className='alert alert-danger mt-4'
                                                        role='alert'
                                                    >
                                                        <p
                                                            className='m-0'
                                                            aria-label={
                                                                this.state
                                                                    .errorMessage
                                                            }
                                                        >
                                                            {
                                                                this.state
                                                                    .errorMessage
                                                            }
                                                        </p>
                                                    </div>
                                                </Col>
                                            )}
                                        </Row> */}
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </div>
      </div>
    );
  }
}

export default QRForm;
