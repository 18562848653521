import React, { Component } from "react";

class ErrorComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    return {
      hasError: true
    };
  }
  componentDidCatch() {
    document.title = "Application Error"
    document.body.style.setProperty('background', '#f8d7d9')
  }
  render() {
    if (this.state.hasError) {
      return (
        <main className="error-center">
          <h1 className="mb-4">Error:</h1>
          <div className="mt-4">The system was not able to complete the last operation you tried to perform. Please contact your benefit plan administrator.</div>
          <div className="mt-4">La dernière transaction effectuée n’a pu être complétée. Veuillez contacter votre administrateur de régime.</div>
        </main>
      );
    }

    return this.props.children;
  }
}

export default ErrorComponent;
