import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "underscore";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";

import {
  getLifeChangeEvent,
  getLoginContent,
  getForgotPasswordContent,
  getTemporaryPasswordContent,
  getHeaderImages,
  changeLocale,
  getWelcomeContent,
  getMenu,
  getMenu2,
  dissmiss
} from "../actions/contentActions";
import {
  languageChange,
  getProfile,
  getDependents,
  toggleSideBar,
  getLifeBenefit,
  getWellness,
  getBeneficiaries,
  logoff,
  setPageSetting,
  changeLocaleForBenefit,
  reviewCurrentCoverage,
  reviewPastCoverage
} from "../actions/userActions";

import DisplayButton from "./button";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Modal, ModalBody } from "reactstrap";
import { getDisplayText, setModalAttribute } from "../utils";
import { isMobile } from "react-device-detect";
import { trackPromise } from "react-promise-tracker";
import WarningMessageModal from "./WarningMessageModal";
import Help from "./Help";
import { USER_TOKEN } from "../helpers/storage";

class SideMenu extends Component {
  constructor(props) {
    super(props);
    const keys = _.keys(this.props.content);
    let menu = { isOpen: this.props.isOpen };
    _.each(keys, (menuItem) => {
      menu[menuItem] = false;
    });
    this.state = {
      menuKey: "",
      pathIs: this.props.location.pathname,
      sideMenu: this.props.content,
      menu,
      showModal: false,
      isNormalLogOff: true,
      showLogOutPopUp: _.isEmpty(props.welcomeContent.contents) ? false : true,
      showHelpContent: false,
      helpTitle: "",
      helpAriaTitle: "",
      curentPage: "",
      showWarningModal: false,
      selectMenuItem: "",
      actionLink: ""
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.welcomeContent !== prevProps.welcomeContent)
      _.isEmpty(this.props.welcomeContent.contents)
        ? this.isLogOutPopUpVisible(false)
        : this.isLogOutPopUpVisible(true);
  }

  componentDidMount() {
    let paths = this.props.location.pathname.slice(1);
    let { sideMenu } = this.state;
    let menu = { ...this.state.menu };
    for (let item in sideMenu) {
      if (sideMenu[item].actinLink === "") {
        for (let subItem in sideMenu[item].subMenus) {
          if (paths === sideMenu[item].subMenus[subItem].actinLink) {
            menu[item] = true;
            this.setState({ menu });
          }
        }
      }
    }
  }

  lowerCase = (str) => {
    return str.toLowerCase();
  };

  static getDerivedStateFromProps(props, state) {
    let paths = props.location.pathname.slice(1);
    let menuKey;
    if (props.location.pathname !== state.pathIs || !state.sideMenu) {
      if (!state.sideMenu) state.sideMenu = props.sideBarMenu;
      for (let item in state.sideMenu) {
        if (state.sideMenu[item].actinLink === "") {
          for (let subItem in state.sideMenu[item].subMenus) {
            if (paths === state.sideMenu[item].subMenus[subItem].actinLink) {
              state.menu[item] = true;
              state.pathIs = props.location.pathname;
              state.menuKey = menuKey = item;
            }
          }
          if (menuKey !== item) {
            state.menu[item] = false;
            state.pathIs = props.location.pathname;
          }
        } else {
          state.menu[item] = false;
        }
      }

      return state;
    } else {
      state.sideMenu = props.sideBarMenu;
      return state;
    }
  }

  toggleSubMenu = (item) => {
    let menu = { ...this.state.menu };
    menu[item] = !menu[item];
    this.setState({ menu });
  };

  handleWarningPopup = async (item, menulink) => {
    if (item === "DEPENDENTINFORMATION" || item === "PERSONALINFORMATION" || item === "WELLNESS") {
      this.setState({ showWarningModal: true, selectMenuItem: item, actionLink: menulink });
    } else {
      this.handleSelect(item);
    }
  };
  handleSelect = async (item) => {
    //Handle Click of the Menu Item
    const callMenu = true;
    this.props.dissmiss();
    if (this.props.isMobile) this.props.toggleSideBar(!this.props.isOpen);
    if (localStorage.getItem("isReview") || localStorage.getItem("lifeEvent")) {
      trackPromise(this.props.setPageSetting(item, callMenu));
    } else trackPromise(this.props.setPageSetting(item));
  };

  handleSelectClickByWarningModal = () => {
    //Handle Click of the  warning modal yest button
    this.props.history.push(`/${this.state.actionLink}`);
    this.setState({ showWarningModal: false });
    this.handleSelect(this.state.selectMenuItem);
  };
  handleShowModal = (e) => {
    this.setState({ showModal: true });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  handleClose = () => {
    this.setState({ showWarningModal: false, selectMenuItem: "", actionLink: "" });
  };
  handleShowHelp = (display, textToRead) => {
    const pagePath =
      this.props.history.location.pathname === "/" || this.props.history.location.pathname.toLowerCase().includes("sso")
        ? "home"
        : this.props.history.location.pathname.slice(1);
    let curentPage;

    if (pagePath.split("/").length > 1) {
      console.log(pagePath.split("/").length, pagePath);
      if (localStorage.getItem("isReview") || localStorage.getItem("lifeEvent")) {
        curentPage = "benefit";
      } else {
        curentPage = pagePath.split("/")[0].toLowerCase();
      }
    } else {
      curentPage = pagePath.toLowerCase() === "benefits" ? "benefit" : pagePath.toLowerCase();
    }

    this.setState({ showHelpContent: true, helpTitle: display, helpAriaTitle: textToRead, curentPage: curentPage });
  };

  handleHelpClose = () => {
    this.setState({ showHelpContent: false });
  };

  isLogOutPopUpVisible = (value) => {
    if (this.state.showLogOutPopUp !== value) {
      this.setState({ showLogOutPopUp: value });
    }
  };

  onLogoutPrompt = (e) => {
    e.preventDefault();
    if (
      this.state.showLogOutPopUp &&
      window.location.pathname !== "/" &&
      localStorage.getItem("hideButtons") === "true"
    ) {
      if (localStorage.getItem("logOffCheck") !== "true") {
        this.setState({ isNormalLogOff: false });
      } else {
        this.setState({ isNormalLogOff: true });
      }
      this.handleShowModal();
    } else {
      this.handleSelect("LOGOFF");
      this.props.history.push("/Logoff");
    }
  };
  onConfirmLogout = () => {
    this.handleCloseModal();
    this.handleSelect("LOGOFF");
    this.props.history.push("/Logoff");
  };

  getClassNamesL2 = (menuItem, submenuItem) => {
    let classNames = "menu-item";
    if (this.state.menu[menuItem]) classNames += " opened";
    else classNames += " closed";
    let sublink = this.props.content[menuItem].subMenus[submenuItem].actinLink;
    const isDisabled = this.props.content[menuItem].subMenus[submenuItem].isDisabled;
    let link = this.props.content[menuItem].actinLink;
    if (link) {
      if (link === this.props.pageSetting.link) classNames += " active";
      else if (link === "BENEFITS") {
        if (this.props.content[menuItem].isDisabled) classNames += " btn btn-link disabled text-left";
        else classNames += " active";
      }
    }
    if (sublink) {
      if (sublink === this.props.pageSetting.link) classNames += " active";
      else if (isDisabled) classNames += " btn btn-link disabled text-left";
    }
    return classNames;
  };

  getClassForLang = (menuItem) => {
    let classNames = "";
    if (this.state.menu[menuItem]) classNames += "menu-item opened";
    else classNames += "d-none closed";

    return classNames;
  };

  handleChangeLang = (event, locale) => {
    event.which === 13 && this.onChangeLang(locale);
  };

  onChangeLang = (locale) => {
    this.props.dissmiss();
    if (this.props.isMobile) this.props.toggleSideBar(!this.props.isOpen);
    const pathname = window.location.search
      ? window.location.pathname.toLowerCase() + window.location.search
      : window.location.pathname.toLowerCase();
    USER_TOKEN.get().languageSwitchRequired && USER_TOKEN.removeQCUser();
    trackPromise(this.props.languageChange(locale, this.props.client.toLowerCase(), pathname));
  };
  handlePolicy = (value, menu) => {
    const { name } = this.props.pageSetting;
    if (this.props.isMobile) {
      this.props.toggleSideBar(!this.props.isOpen);
    }
    localStorage.setItem("policyName", name);
    this.props.setPageSetting(menu);
    let section = value.actinLink.split("/");
    console.log("section: ", section);

    const menuItem = localStorage.getItem("policyName");
    console.log("menuItem: ", menuItem);
    this.props.history.push(`/${menuItem ? menuItem.link : ""}`);

    localStorage.setItem("section", section[section.length - 1]);
    localStorage.setItem("display", value.display);
  };
  handleCoverage = (sublink) => {
    this.props.reviewCurrentCoverage(sublink, () => {
      var myWindow = window.close();
      myWindow = window.open("", "Review", "width=1000; height=1000");
      myWindow.document.body.innerHTML = "";
      myWindow.document.write(`<div dangerouslySetInnerHTML = {{__html:${this.props.currentCoverage.html}}}>
				<button>${this.props.currentCoverage.buttonSavePDF.display}</button>
				</div>`);
    });
  };

  handleKeyDown = (event, item) => {
    let menu = { ...this.state.menu };
    switch (event.which) {
      case 40: {
        event.preventDefault();
        menu[item] = true;
        this.setState({ menu });
        break;
      }
      case 38: {
        event.preventDefault();
        menu[item] = false;
        this.setState({ menu });
        break;
      }
      default:
        break;
    }
  };

  handleReview = (item) => {
    const { name } = this.props.pageSetting;
    this.props.dissmiss();
    if (this.props.isMobile) {
      this.props.toggleSideBar(!this.props.isOpen);
      localStorage.setItem("pageName", name);
      this.handleSelect(item);
    }
  };

  goToMainURL = (e, menuItem) => {
    if (!e.shiftKey && e.which === 9 && menuItem === "LOGOFF" && this.props.isOpen) {
      document.getElementById("mainHeader").setAttribute("tabIndex", "-1");
      document.getElementById("mainHeader").focus();
    }
  };

  render() {
    let menu = [];
    const keys = _.keys(this.props.content);
    const isOpen = this.props.isOpen;
    const langLabels = this.props.language.filter((key) => key.keyName === "labelEN" || key.keyName === "labelFR");
    const contents = this.props.welcomeContent.contents ? this.props.welcomeContent.contents : {};

    const lblLogoutDialogTitle = getDisplayText(contents, "lblLogoutDialogTitle");
    const logoutConfirmMessage = getDisplayText(contents, "logoutConfirmMessage");
    const btnCancel = getDisplayText(contents, "btnCancelEnrollment");
    const btnOk = getDisplayText(contents, "btnOK");
    const footerContent = this.props.footer;
    const copyrightMessage = footerContent.result.copyright || "";
    const closeButtonText = getDisplayText(
      (footerContent.contentResource && footerContent.contentResource.contents) || {},
      "btnClose"
    );
    const lblNewTab = getDisplayText(
      (footerContent.contentResource && footerContent.contentResource.contents) || {},
      "lblNewTab"
    );
    let tabIndexValue = 0;
    let index =
      this.props.screens && this.props.screens.length > 0
        ? this.props.screens.findIndex((item) => item.name === "PERSONALINFORMATION")
        : -1;
    const allowWarningMessageFlag =
      this.props.showWarningMessage &&
      ((this.props.nextEnrolStep !== null &&
        this.props.nextEnrolStep.nextEvent !== undefined &&
        this.props.nextEnrolStep.nextEvent !== null) ||
        index >= 0)
        ? true
        : false;

    _.each(keys, (menuItem) => {
      let display = this.props.content[menuItem].display;
      let textToRead = this.props.content[menuItem].textToRead;
      let link = this.props.content[menuItem].actinLink;
      let subMenus = this.props.content[menuItem].subMenus ? this.props.content[menuItem].subMenus : [];
      let isDisabled = this.props.content[menuItem].isDisabled;
      if (Object.keys(subMenus).length !== 0 && subMenus.constructor === Object) {
        //has sub-menu
        menu.push(
          <div
            tabIndex={tabIndexValue}
            onKeyDown={(event) => this.handleKeyDown(event, menuItem)}
            key={`nolink-${display}`}
            role="button"
            tid="MenuSubMenuItem"
            //  aria-label={textToRead} onClick={isDisabled ? e => e.preventDefault() : () => { this.toggleSubMenu(menuItem) }}
            className="menu-item menu-item-selected icon-action"
          >
            {" "}
            <div>{display}</div>
            <FontAwesomeIcon
              className="icon-action"
              tid="MenuSubMenuItemToggle"
              onClick={() => {
                this.toggleSubMenu(menuItem);
              }}
              icon={this.state.menu[menuItem] ? faAngleUp : faAngleDown}
            />
          </div>
        );
        const subKeys = _.keys(subMenus);
        _.each(subKeys, (submenuItem) => {
          let subDisplay = this.props.content[menuItem].subMenus[submenuItem].display;
          let subTextToRead = this.props.content[menuItem].subMenus[submenuItem].textToRead;
          let sublink = this.props.content[menuItem].subMenus[submenuItem].actinLink;
          let isExternal = this.props.content[menuItem].subMenus[submenuItem].isExternal;
          let isDisabled = this.props.content[menuItem].subMenus[submenuItem].isDisabled;

          if (submenuItem === "HELP") {
            menu.push(
              <div
                tabIndex={tabIndexValue}
                key={submenuItem}
                role="button"
                //  aria-label={subTextToRead}
                onClick={
                  isDisabled
                    ? (e) => e.preventDefault()
                    : () => {
                        this.handleShowHelp(subDisplay, subTextToRead);
                      }
                }
                className={this.getClassNamesL2(menuItem, submenuItem)}
              >
                {" "}
                <div>{subDisplay}</div>
                {/* <FontAwesomeIcon className="icon-action" onClick={() => { this.toggleSubMenu(menuItem) }} icon={this.state.menu[menuItem] ? faAngleUp : faAngleDown} /> */}
              </div>
            );
          } else {
            menu.push(
              isExternal && !isDisabled ? (
                <a
                  key={submenuItem}
                  className={this.getClassNamesL2(menuItem, submenuItem)}
                  target="_blank"
                  href={sublink}
                  aria-label={subTextToRead + " " + lblNewTab.textToRead}
                  rel="noopener noreferrer"
                >
                  {subDisplay}
                </a>
              ) : (
                <Link
                  // tabIndex={tabIndexValue}
                  key={submenuItem}
                  tid="subMenuItem"
                  // aria-label={sublink.includes('ReviewCoverage/CurrentCoverage') || sublink.includes('/ReviewCoverage/PreviousCoverage') ? isMobile ? "" : subTextToRead+" "+lblNewTab.textToRead  : subTextToRead}
                  onClick={
                    isDisabled
                      ? (e) => e.preventDefault()
                      : sublink.includes("ReviewCoverage/CurrentCoverage")
                      ? () => {
                          // eslint-disable-next-line
                          const [path, params] = sublink.split("?");
                          if (params) localStorage.setItem("parameters", params);
                          else localStorage.removeItem("parameters");
                          this.handleReview(submenuItem);
                        }
                      : () => {
                          this.handleSelect(submenuItem);
                          if (sublink.includes("/ReviewCoverage/PreviousCoverage")) {
                            this.handleReview(submenuItem);
                            localStorage.setItem("dateString", sublink.split("?")[1].split("=")[1]);
                          }
                        }
                  }
                  to={sublink.includes("/ReviewCoverage/PreviousCoverage") ? "/coverage" : `/${sublink}`}
                  target={
                    sublink.includes("ReviewCoverage/CurrentCoverage") ||
                    sublink.includes("/ReviewCoverage/PreviousCoverage")
                      ? isMobile
                        ? ""
                        : "__blank"
                      : ""
                  }
                  className={this.getClassNamesL2(menuItem, submenuItem)}
                >
                  {" "}
                  <div>{subDisplay} </div>
                </Link>
              )
            );
          }
        });
      } else {
        //No submenu
        if (menuItem === "LANGUAGESETTING") {
          menu.push(
            <div
              tabIndex={tabIndexValue}
              onKeyDown={(event) => this.handleKeyDown(event, menuItem)}
              key={`nolink-${display}`}
              role="button"
              tid="langMenuItem"
              //  aria-label={textToRead} onClick={isDisabled ? e => e.preventDefault() : () => { this.toggleSubMenu(menuItem) }}
              className="menu-item menu-item-selected icon-action d-lg-none d-md-flex"
            >
              {" "}
              <div>{display}</div>
              <FontAwesomeIcon
                className="icon-action"
                onClick={() => {
                  this.toggleSubMenu(menuItem);
                }}
                icon={this.state.menu[menuItem] ? faAngleUp : faAngleDown}
              />
            </div>
          );
          _.each(langLabels, (label, i) => {
            const languageType = label.textToRead === "EN" ? "en" : label.textToRead === "FR" ? "fr" : "";
            menu.push(
              <div
                tabIndex={tabIndexValue}
                className={this.getClassForLang(menuItem)}
                tid="langSubMenuItem"
                aria-label={label.textToRead}
                key={i}
                onKeyDown={(event) => this.handleChangeLang(event, languageType)}
                onClick={() => this.onChangeLang(languageType)}
              >
                {label.display}
              </div>
            );
          });
        } else if (menuItem === "TELUSHEALTHPRIVACY" || menuItem === "TERMSOFUSE" || menuItem === "ACCESSIBILITY") {
          menu.push(
            <Link
              // tabIndex={tabIndexValue}
              key={menuItem}
              // aria-label={textToRead}
              tid="policyMenuItem"
              to="/Content/FooterContent"
              className={
                link === this.props.pageSetting.link
                  ? "menu-item menu-item-selected icon-action d-lg-none d-md-block active"
                  : isDisabled
                  ? "menu-item menu-item-selected btn btn-link icon-action d-lg-none d-md-block disabled"
                  : "menu-item menu-item-selected icon-action d-lg-none d-md-block"
              }
              onClick={
                isDisabled ? (e) => e.preventDefault() : () => this.handlePolicy(this.props.content[menuItem], menuItem)
              }
            >
              {display}
            </Link>
          );
        } else if (menuItem === "HELP") {
          menu.push(
            <div
              tabIndex={tabIndexValue}
              key={menuItem}
              role="button"
              //  aria-label={textToRead}
              onClick={
                isDisabled
                  ? (e) => e.preventDefault()
                  : () => {
                      this.handleShowHelp(display, textToRead);
                    }
              }
              className="menu-item menu-item-selected"
            >
              {" "}
              <div>{display}</div>
              {/* <FontAwesomeIcon className="icon-action" onClick={() => { this.toggleSubMenu(menuItem) }} icon={this.state.menu[menuItem] ? faAngleUp : faAngleDown} /> */}
            </div>
          );
        } else if (allowWarningMessageFlag && this.props.pageSetting && this.props.pageSetting.name !== undefined) {
          menu.push(
            <Link
              // tabIndex={tabIndexValue}
              key={menuItem}
              // aria-label={(link === 'ReviewCoverage/CurrentCoverage' || link.includes('/ReviewCoverage/PreviousCoverage')) ? isMobile ? "" : textToRead+" "+lblNewTab.textToRead  : textToRead}
              tid={`${menuItem.toLowerCase()}MenuItem`}
              role={menuItem !== "LOGOFF" ? "link" : "button"}
              onClick={
                isDisabled
                  ? (e) => e.preventDefault()
                  : (e) => {
                      menuItem === "LOGOFF"
                        ? this.onLogoutPrompt(e)
                        : this.props.pageSetting.link.includes("Profile") ||
                          this.props.pageSetting.link.includes("Dependent") ||
                          this.props.pageSetting.link.includes("Wellness")
                        ? this.handleSelect(menuItem)
                        : this.handleWarningPopup(menuItem, link);
                    }
              }
              to={
                this.props.pageSetting &&
                !(
                  this.props.pageSetting.link.includes("Profile") ||
                  this.props.pageSetting.link.includes("Dependent") ||
                  this.props.pageSetting.link.includes("Wellness")
                )
                  ? "#"
                  : `/${link}`
              }
              target={
                link === "ReviewCoverage/CurrentCoverage" || link.includes("/ReviewCoverage/PreviousCoverage")
                  ? isMobile
                    ? ""
                    : "__blank"
                  : ""
              }
              className={
                link === this.props.pageSetting.link
                  ? "menu-item menu-item-selected active"
                  : isDisabled
                  ? "menu-item menu-item-selected btn btn-link disabled"
                  : "menu-item menu-item-selected"
              }
              onKeyDown={(e) => this.goToMainURL(e, menuItem)}
            >
              {" "}
              <div>{display}</div>
            </Link>
          );
        } else {
          menu.push(
            <Link
              // tabIndex={tabIndexValue}
              key={menuItem}
              // aria-label={(link === 'ReviewCoverage/CurrentCoverage' || link.includes('/ReviewCoverage/PreviousCoverage')) ? isMobile ? "" : textToRead + " " + lblNewTab.textToRead : textToRead}
              tid={`${menuItem.toLowerCase()}MenuItem`}
              role={menuItem !== "LOGOFF" ? "link" : "button"}
              onClick={
                isDisabled
                  ? (e) => e.preventDefault()
                  : (e) => {
                      menuItem === "LOGOFF"
                        ? this.onLogoutPrompt(e)
                        : link === "ReviewCoverage/CurrentCoverage" || link.includes("/ReviewCoverage/PreviousCoverage")
                        ? this.handleReview(menuItem)
                        : this.handleSelect(menuItem);
                    }
              }
              to={link.includes("/ReviewCoverage/PreviousCoverage") ? "/coverage" : `/${link}`}
              target={
                link === "ReviewCoverage/CurrentCoverage" || link.includes("/ReviewCoverage/PreviousCoverage")
                  ? isMobile
                    ? ""
                    : "__blank"
                  : ""
              }
              className={
                link === this.props.pageSetting.link
                  ? "menu-item menu-item-selected active"
                  : isDisabled
                  ? "menu-item menu-item-selected btn btn-link disabled"
                  : display === this.props.pageSetting.display
                  ? "menu-item menu-item-selected active"
                  : "menu-item menu-item-selected"
              }
              onKeyDown={(e) => this.goToMainURL(e, menuItem)}
            >
              {" "}
              <div>{display}</div>
            </Link>
          );
        }
      }
    });

    return (
      <div className={isOpen ? "col-xs-12 col-md-12 col-lg-12 float-left p-0 scroll-container" : "closed"}>
        <div className="menu" role="navigation">
          {!(Object.keys(this.props.headerInfo).length === 0 && this.props.headerInfo.constructor === Object) && (
            <div className="header-info-holder">
              <p>
                {this.props.headerInfo.firsName && <span className="bold-text">{this.props.headerInfo.firsName} </span>}
                {this.props.headerInfo.lastName && <span className="bold-text">{this.props.headerInfo.lastName} </span>}
                {this.props.headerInfo.divisionName && <span>{this.props.headerInfo.divisionName}</span>}
              </p>
              {this.props.headerInfo.headerEffectiveDate && (
                <div>
                  {this.props.headerInfo.headerEffectiveDate && <p>{this.props.headerInfo.headerEffectiveDate}</p>}
                  {this.props.headerInfo.effectiveDateText && (
                    <strong className="d-block">{this.props.headerInfo.effectiveDateText}</strong>
                  )}
                </div>
              )}
            </div>
          )}
          {menu}
          {copyrightMessage && (
            <div className="d-lg-none d-md-block">
              <span className="ml-copyright" dangerouslySetInnerHTML={{ __html: copyrightMessage }} />
            </div>
          )}
        </div>
        <Modal
          onOpened={() => setModalAttribute(lblLogoutDialogTitle.textToRead)}
          aria-labelledby="modalHeading"
          aria-describedby="modalDescription"
          className="ml-cancelenrollment"
          isOpen={this.state.showModal}
          toggle={this.handleCloseModal}
          centered
        >
          <div className="modal-header">
            <h1 className="modal-title" id="modalHeading">
              {lblLogoutDialogTitle.display}
            </h1>
            <DisplayButton
              type="button"
              className="close"
              displayName="×"
              arialabel={closeButtonText.display}
              onClick={this.handleCloseModal}
            />
          </div>
          <ModalBody>
            <div className="ml-popupmessage" id="modalDescription">
              <p className="ml-popupmessagetext" dangerouslySetInnerHTML={{ __html: logoutConfirmMessage.display }}></p>
            </div>
            {this.state.isNormalLogOff ? (
              <div className={"ml-buttons d-flex justify-content-between"}>
                <DisplayButton
                  controlId="modalLogOffButton"
                  className="outline-secondary"
                  displayName={btnCancel.display}
                  onClick={this.handleCloseModal}
                />
                <DisplayButton
                  controlId="modalOkButton"
                  className="secondary"
                  onClick={this.onConfirmLogout}
                  displayName={btnOk.display}
                />
              </div>
            ) : (
              <div className={"ml-buttons d-flex justify-content-between"}>
                <DisplayButton
                  controlId="modalCancelButton"
                  className="outline-secondary"
                  onClick={this.handleCloseModal}
                  displayName={btnCancel.display}
                />
              </div>
            )}
          </ModalBody>
        </Modal>

        <Modal
          onOpened={() => setModalAttribute(this.state.helpAriaTitle)}
          aria-labelledby="modalHeading"
          centered
          isOpen={this.state.showHelpContent}
          toggle={this.handleHelpClose}
          className={`ml-addnewdependent`}
        >
          <div className="modal-header">
            <h1 className="modal-title" id="modalHeading">
              {this.state.helpTitle}
            </h1>
            <DisplayButton
              type="button"
              className="close"
              displayName="×"
              arialabel={closeButtonText.display}
              onClick={this.handleHelpClose}
            />
          </div>

          <ModalBody className="scroll-container">
            <Help pageName={this.state.curentPage} />
          </ModalBody>
        </Modal>
        <WarningMessageModal
          showWarningModal={this.state.showWarningModal}
          clickAction={this.handleSelectClickByWarningModal}
          handleClose={this.handleClose}
        />
        <div _ngcontent-c4="" className="lds-roller-wrapper"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pageSetting: state.userReducer.pageSetting ? state.userReducer.pageSetting : {},
    language: state.contentReducer.language,
    client: state.contentReducer.client,
    welcomeContent: state.userReducer.putWelcomeResponse.contentResource
      ? state.userReducer.putWelcomeResponse.contentResource
      : {},
    currentCoverage: state.userReducer.currentCoverage,
    pastCoverage: state.userReducer.pastCoverage,
    sideBarMenu: state.contentReducer.menu2.mainMenu,
    headerInfo: state.contentReducer.headerInfo ? state.contentReducer.headerInfo : {},
    footer: state.userReducer.contentFooter ? state.userReducer.contentFooter : {},
    nextEnrolStep: state.userReducer.nextEnrolStep ? state.userReducer.nextEnrolStep : {},
    showWarningMessage: state.contentReducer.showWarningMessage,
    screens: state.contentReducer.screens
  };
};

export default connect(mapStateToProps, {
  getLifeChangeEvent,
  setPageSetting,
  getWellness,
  getLifeBenefit,
  toggleSideBar,
  getLoginContent,
  getForgotPasswordContent,
  getTemporaryPasswordContent,
  getHeaderImages,
  changeLocale,
  getWelcomeContent,
  getMenu,
  getMenu2,
  logoff,
  getProfile,
  getDependents,
  getBeneficiaries,
  changeLocaleForBenefit,
  reviewCurrentCoverage,
  reviewPastCoverage,
  languageChange,
  dissmiss
})(withRouter(SideMenu));
