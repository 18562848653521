export const LOGOFF = "LOGOFF";
export const CANCEL = "CANCEL";
export const FAILURE = "FAILURE";
export const SUCCESS = "SUCCESS";
export const SIGN_IN = "SIGN_IN";
export const GET_MENU = "GET_MENU";
export const GET_PROFILE = "GET_PROFILE";
export const PUT_WELCOME = "PUT_WELCOME";
export const PUT_WELCOME_MESSAGES = "PUT_WELCOME_MESSAGES";
export const PUT_WELCOME_REQUESTED_OBJECT = "PUT_WELCOME_REQUESTED_OBJECT";
export const CHANGE_EVENT = "CHANGE_EVENT";
export const CHANGE_OPTION = "CHANGE_OPTION";
export const GET_SHOW_FLEX = "GET_SHOW_FLEX";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const GET_BENEFIT_IDS = "GET_BENEFIT_IDS";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const GET_SECOND_MENU = "GET_SECOND_MENU";
export const SET_MENU2_FLAG = "SET_MENU2_FLAG";
export const SET_PAGE_SETTING = "SET_PAGE_SETTING";
export const GET_CHANGE_EVENT = "GET_CHANGE_EVENT";
export const DISSMISS_MESSAGE = "DISSMISS_MESSAGE";
export const GET_LOGIN_CONTENT = "GET_LOGIN_CONTENT";
export const GET_WELCOME_IMAGE = "GET_WELCOME_IMAGE";
export const GET_HEADER_IMAGES = "GET_HEADER_IMAGES";
export const SET_BENEFIT_OPTION = "SET_BENEFIT_OPTION";
export const GET_WELCOME_CONTENT = "GET_WELCOME_CONTENT";
export const GET_ENROLMENT_HEADER = "GET_ENROLMENT_HEADER";
export const GET_ENROLMENT_FOOTER = "GET_ENROLMENT_FOOTER";
export const UPDATE_PERSONAL_INFO = "UPDATE_PERSONAL_INFO";
export const GET_LIFE_EVENT_CHANGE = "GET_LIFE_EVENT_CHANGE";
export const CHANGE_PASSWORD_CONTENT = "CHANGE_PASSWORD_CONTENT";
export const GET_PENSION_CONTRIBUTION = "GET_PENSION_CONTRIBUTION";
export const TEMPORARY_PASSWORD_CONTENT = "TEMPORARY_PASSWORD_CONTENT";
export const GET_FORGOT_PASSWORD_CONTENT = "GET_FORGOT_PASSWORD_CONTENT";
export const GET_TWOFA_REG_CONTENT = "GET_TWOFA_REG_CONTENT";
export const GET_REGISTRATION_FORM = "GET_REGISTRATION_FORM";
export const SEND_VERIFICATION_CODE = "SEND_VERIFICATION_CODE";
export const GET_VERIFICATION_CONTENT = "GET_VERIFICATION_CONTENT";
export const GET_TFA_SETTINGS = "GET_TFA_SETTINGS";
export const TFA_DISABLE = "TFA_DISABLE";
export const FOCUS_IS_WELCOME = "FOCUS_IS_WELCOME"
export const BENEFIT_ERROR_ID = "BENEFIT_ERROR_ID"


// Wellness
export const GET_WELLNESS = "GET_WELLNESS";
export const UPDATE_WELLNESS = "UPDATE WELLNESS";

/**
 * DEPENDENT
 */
export const GET_DEPENDENTS = "GET_DEPENDENTS";
export const UPDATE_DEPENDENT = "UPDATE_DEPENDENT";
export const CREATE_DEPENDENT = "CREATE_DEPENDENT";
export const DELETE_DEPENDENT = "DELETE_DEPENDENT";

export const NEXT = "Next";
export const PREVIOUS = "PREVIOUS";
export const CHANGE_LOCALE = "CHANGE_LOCALE";
export const FOCUS_INITIALIZING = "FOCUS_INITIALIZING";

/**
 * BENEFITS
 */
export const GET_ADD = "GET_ADD";
export const GET_ADDWC = "GET_ADDWC";
export const GET_HEALTH = "GET_HEALTH";
export const SET_BENEFIT = "SET_BENEFIT";
export const GET_DISABILITY = "GET_DISABILITY";
export const GET_BASIC_LIFE = "GET_BASIC_LIFE";
export const UPDATE_BASIC_LIFE = "UPDATE_BASIC_LIFE";
export const REMOVE_WAIVER = "REMOVE_WAIVER";
export const GET_CONTENT_HELP = "GET_CONTENT_HELP";

/* Beneficiaries */
export const GET_BENEFICIARIES = "GET_BENEFICIARIES";
export const CREATE_BENEFICIARY = "CREATE_BENEFICIARY";
export const UPDATE_BENEFICIARY = "UPDATE_BENEFICIARY";
export const UPDATE_INDIVIDUAL_BENEFICIARY = "UPDATE_INDIVIDUAL_BENEFICIARY";
export const DELETE_BENEFICIARY = "DELETE_BENEFICIARY";
export const PASSWORD_CHANGED = "PASSWORD_CHANGED";
export const SET_CLIENT_NAME = "SET_CLIENT_NAME";
export const REMOVE_EOI = "REMOVE_EOI";
export const SAVE_WAIVER_FORM = "SAVE_WAIVER_FORM";
export const SAVE_DEPENDENT_WAIVER_FORM = "SAVE_DEPENDENT_WAIVER_FORM";
export const PUT_DEPENDED_COVERAGE = "PUT_DEPENDED_COVERAGE";
export const GET_BENEFICIARIES_ALL = "GET_BENEFICIARIES_ALL";
export const REMOVE_BENEFICIARY = "REMOVE_BENEFICIARY";
export const REALLOCATE_MULTIBENEFIT = "REALLOCATE_MULTIBENEFIT";
export const PROCEED_TO_NEXT = "PROCEED_TO_NEXT";

//Disable Next
export const DISABLE_NEXT = "DISABLE_NEXT"

//Benefit Summary
export const GET_BENEFIT_SUMMARY ="GET_BENEFIT_SUMMARY";
export const GET_SUMMARY_BENEFICIARIES ="GET_SUMMARY_BENEFICIARIES";
export const GET_SUMARY_DEPENDENTS = "GET_SUMMARY_DEPENDENTS";
export const GET_SUMMARY_PERSONAL = "GET_SUMMARY_PERSONAL";
export const CONFIRM_ENROLLMENT = "CONFIRM_ENROLLMENT";
// Upload Files
export const FILE_UPLOAD_INFO = "FILE_UPLOAD_INFO"
export const UPLOADED_FILE = "UPLOADED_FILE";
export const UPLOADED_FILES_INFO = "UPLOADED_FILES_INFO";
export const UPLOAD_FILE = "UPLOAD_FILE";
export const PROGRESS_PERCENTAGE = "PROGRESS_PERCENTAGE";

// finish Enrollment
export const FINISH_ENROLLMENT = "FINISH_ENROLLMENT"

//Content Footer
export const GET_CONTENT_FOOTER = "GET_CONTENT_FOOTER"
export const GET_CONTENT_FOOTER_PAGE = "GET_CONTENT_FOOTER_PAGE"

//ProgressBar
export const GET_PROGRESS_INDICATOR = "GET_PROGRESS_INDICATOR"

//Flex Dollar
export const UPDATE_FLEX = "UPDATE_FLEX";
export const GET_FLEX = "GET_FLEX";
export const RELOCATE_FLEX = "RELOCATE_FLEX";

//Review Coverage

export const REVIEW_CURRENT_COVERAGE = "REVIEW_CURRENT_COVERAGE";
export const REVIEW_PAST_COVERAGE = "REVIEW_PAST_COVERAGE";
export const REVIEW_PAST_COVERAGE_LIST = "REVIEW_PAST_COVERAGE_LIST";
export const PDF_STATEMENT = "PDF_STATEMENT";
export const SAVE_FLEX = "SAVE_FLEX";

//Enrollment Summary
export const GET_SUMMARY_BENEFITS = "GET_SUMMARY_BENEFITS";
export const GET_SUMMARY_FLEX = "GET_SUMMARY_FLEX";

//Set IsDisabled
export const IS_DISABLED = "IS_DISABLED"

//Set Base Page Title
export const SET_BASE_PAGE_TITLE = "SET_BASE_PAGE_TITLE"
export const SET_BASE_PAGE_ITEM = "SET_BASE_PAGE_ITEM";

//Help Menu
export const GET_CONTENT_HELP_MENU = "GET_CONTENT_HELP_MENU";
//Next Enrollemnt Step
export const NEXT_ENROLLMENT_STEP = "NEXT_ENROLLMENT_STEP"

//Custom styling
export const SET_CLIENT_TYPE = "SET_CLIENT_TYPE";

//Set Header Nav URL
export const SET_NAV_URL = "SET_NAV_URL";
export const SET_MINI_CART_INITIAL_STATE = "SET_MINI_CART_INITIAL_STATE";

//Set Navigation Error
export const SET_NAV_ERROR = "SET_NAV_ERROR"

//Set Employee Header Info
export const SET_HEADER_INFO = "SET_HEADER_INFO"

// Beneficiary Enrollment Form
export const FINISH_ENROLLMENT_FORM = "FINISH_ENROLLMENT_FORM"

// SET DEVICE TYPE
export const ENROLLMENT_DEVICE_TYPE = "ENROLLMENT_DEVICE_TYPE"

// SET MULTI BEN URLS
export const BENEFIT_URL_LIST = "BENEFIT_URL_LIST"

// GET MULTI BENEFITS DATA
export const GET_MULTI_BENEFIT_DATA = "GET_MULTI_BENEFIT_DATA"

// SET STEP DSC
export const SET_STEP_DESCRIPTION = "SET_STEP_DESCRIPTION"
// Flex Benefit ID for Beneficiaries
export const SET_FLEX_BENEFICIARY_ID = "SET_FLEX_BENEFICIARY_ID"

// Packge Multi Beneficiaries List
export const SET_MULTI_BENEFICIARIES_LIST = "SET_MULTI_BENEFICIARIES_LIST"

// Focus on Benefit Error
export const SET_FOCUS_ON_BENEFIT_ERROR = "SET_FOCUS_ON_BENEFIT_ERROR"

/* E-Signature Start */
export const SAVE_ESIGNATURE = "SAVE_ESIGNATURE";

/* Client Dynamic Stylesheet */
export const GET_CLIENT_STYLES_CONTENT =  "GET_CLIENT_STYLES_CONTENT"
/* show warning message */
export const SHOW_WARNING_MESSAGE =  "SHOW_WARNING_MESSAGE"