import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { Row } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import DisplayButton from './button';
import NextPrevious from './NextPrevious';
import SideMenu from './SideMenu';
import { getHealthBenefit, progressBarIndicator} from '../actions/userActions' 
import { getMenu2, getMenu} from '../actions/contentActions' 
import { getDisplayText,setModalAttribute } from "../utils";
import { trackPromise } from 'react-promise-tracker';
import { Modal, ModalBody } from 'reactstrap';

import EnrollmentHeader from './EnrollmentHeader';
import PagingComponent from './PagingComponent';

import { withRouter } from 'react-router-dom';

class BaseContentPageWithMenu extends Component {
  constructor(props) {
    super(props);
    this.callMenu()
    this.state = {
      smokerUndefined: false,
      isDisclaimerModalVisible: false
    }
  }

  UNSAFE_shouldComponentUpdate(nextProps){
      if(nextProps.pageSetting === this.props.pageSetting &&
      this.refs.errorMessage){
      ReactDOM.findDOMNode(this.refs.errorMessage).focus()  
      }
    return true

  } 

  callMenu(){
    if(localStorage.getItem('isReview') || localStorage.getItem('lifeEvent'))
    trackPromise(this.props.getMenu2())
    else {
      trackPromise(this.props.getMenu())
    }
  }
  callProgress = () => {
    this.setState({ isDisclaimerModalVisible: false });
    window.scrollTo(0,0)
  }
  toggleDisclaimer = () => {
    this.setState({ isDisclaimerModalVisible: !this.state.isDisclaimerModalVisible })
  }

  isSmokerCallBack = () =>{
    this.setState({
      smokerUndefined : true
    })
  }

  isSmokerDefinedCallback = () => {
    this.setState({
      smokerUndefined : false
    })
  }

  componentWillUnmount(){
    this.setState({
      smokerUndefined : false
    })
  }

  render () {
    const contents = this.props.contentFooter
    const { smokerUndefined } = this.state
    const { contentResource, result } = this.props.personalInfo || {}
    const { smokerStatus } = result || {}
    const btnPrevious = this.props.lblPrevious || getDisplayText(contents, "btnPrevious")
    const btnCancel = this.props.lblCancel || getDisplayText(contents, "btnCancel")
    const btnNext = this.props.lblNext || getDisplayText(contents, "btnNext")
    const lifeEventContents = ( ((this.props.lifeChangeEvent || {}).contentResource || {}).contents || [] )
    const lnkStartLifeEvent = getDisplayText(lifeEventContents, 'lnkStartLifeEvent')
    const pageTitle = this.props.history.location.pathname === "/LifeChangeEvent" 
      ? lnkStartLifeEvent.display
      : this.props.basePageTitle 
        ? this.props.basePageTitle 
        : this.props.pageSetting 
          ? this.props.pageSetting.display 
          : ""
    const stepDescription = this.props.stepDescription //"Basic Life is 100% Company Paid. Optional Life is 100% Employee Paid."
    const shouldMountProgress = (localStorage.getItem('isReview') || localStorage.getItem('lifeEvent')) ? true : false
    // console.log('pageTitle',pageTitle)
     if(pageTitle !==null && pageTitle !==""){
     document.title = pageTitle
     }
    const deviceType = this.props.deviceType
    //const custSaveSmokerErrorMessage = getDisplayText(contentResource.contents, 'custSaveSmokerErrorMessage')
    const noButtonLabel = getDisplayText(contents, 'btnCancel')
    const yesButtonLabel = getDisplayText(contents, 'lblYes')
    const {confirmPopupRequired, confirmWarningMessage} = this.props.warningInfo ? this.props.warningInfo : {}
    const custSaveSmokerErrorMessage = getDisplayText((contentResource ||{}).contents || [], 'custSaveSmokerErrorMessage')
    return (
      <div className="container">
        <Row className="main-content-holder">
          <div className="col-xs-10 col-lg-3 side-nav-holder" >
            <SideMenu content={this.props.sideBarMenu} />
          </div>
          <div className="col-xs-12 col-lg-9 content-holder" id="main-content" role="main" >
            
            {deviceType === "Desktop" && (localStorage.getItem('isReview') || localStorage.getItem('lifeEvent')) &&
            <div className="topbutons">
              {!this.props.hideNavigation && confirmPopupRequired && this.props.pageSetting.link === 'Flex?GroupID=1' ? (
                      <NextPrevious
                          {...this.props}
                          lblNext={btnNext}
                          lblCancel={btnCancel}
                          lblPrevious={btnPrevious}
                          nextClick={this.toggleDisclaimer}
                          onClick={this.callProgress}
                      />
                  ):
              (smokerStatus === 2) && (this.props.pageSetting.link === 'Profile') && (this.props.personalInfo.result.smokerDeclarationOptional === false)  ? (
                !this.props.hideNavigation &&
                  <NextPrevious {...this.props}
                            lblNext={btnNext}
                            lblCancel={btnCancel} 
                            lblPrevious={btnPrevious}
                            onClick={this.callProgress}
                            isSmoker= {smokerStatus === 2 ? true : false}
                            isSmokerCallBack = {this.isSmokerCallBack}
                            />) : (
                !this.props.hideNavigation &&
                  <NextPrevious {...this.props}
                            lblNext={btnNext}
                            lblCancel={btnCancel} 
                            lblPrevious={btnPrevious}
                            onClick={this.callProgress}
                            />
                            )} 
            </div>}

            {!this.props.hidePaging && shouldMountProgress && <PagingComponent />}
            <span id="headingMain" className="visuallyhidden">-</span>
            {pageTitle ? (<h1 className="caption personal-info-caption" >{pageTitle}</h1>) : ''}

            {stepDescription &&  <div className="stepdescription mb-4" dangerouslySetInnerHTML={{__html: stepDescription}} />}

            {!this.props.hideFlex && <EnrollmentHeader />}
            <this.props.benefit optionKey= {this.props.optionKey} isSmokerCallBack={this.isSmokerCallBack} isSmokerDefinedCallback = {this.isSmokerDefinedCallback}/>
            {/* {smokerUndefined && (this.props.personalInfo.result.smokerStatus === 2) && (this.props.personalInfo.result.smokerDeclarationOptional === false ) &&<Fragment><div className="alert alert-danger " role="alert" aria-label={custSaveSmokerErrorMessage.textToRead} title={custSaveSmokerErrorMessage.textToRead} dangerouslySetInnerHTML={{ __html: custSaveSmokerErrorMessage.display}} /></Fragment>} */
            smokerUndefined && (this.props.personalInfo.result.smokerStatus === 2) && (this.props.personalInfo.result.smokerDeclarationOptional === false ) && (((this.props.personalInfo || {}).result || {}).smokerStatus === 2) && <Fragment><div className="alert alert-danger " role="alert" ref="errorMessage" tabIndex="-1" dangerouslySetInnerHTML={{ __html: custSaveSmokerErrorMessage.display}} /></Fragment>}
            {!this.props.hideNavigation && confirmPopupRequired && this.props.pageSetting.link === 'Flex?GroupID=1' ? (
                            <NextPrevious
                                {...this.props}
                                lblNext={btnNext}
                                lblCancel={btnCancel}
                                lblPrevious={btnPrevious}
                                nextClick={this.toggleDisclaimer}
                                onClick={this.callProgress}                          
                            />
                        ):
            (smokerStatus === 2) && (this.props.pageSetting.link === 'Profile') && (this.props.personalInfo.result.smokerDeclarationOptional === false )  ? (
            !this.props.hideNavigation &&
              <NextPrevious {...this.props}
                            lblNext={btnNext}
                            lblCancel={btnCancel} 
                            lblPrevious={btnPrevious}
                            onClick={this.callProgress}
                            isSmoker= {smokerStatus === 2 ? true : false}
                            isSmokerCallBack = {this.isSmokerCallBack}                 
                            />) : (
              !this.props.hideNavigation &&
              <NextPrevious {...this.props}
                            lblNext={btnNext}
                            lblCancel={btnCancel} 
                            lblPrevious={btnPrevious}
                            onClick={this.callProgress}             
                            />
                            )}
          </div>
          {confirmPopupRequired && this.props.pageSetting.link === 'Flex?GroupID=1' && (<Modal
            onOpened={() => setModalAttribute('disclaimerModal')}
            aria-labelledby="modalHeading"
            className="ml-lifechangeeventpopup"
            isOpen={this.state.isDisclaimerModalVisible} toggle={this.toggleDisclaimer} centered>
            <div className="modal-header">
            <div id="modalHeading">&nbsp;</div>
              <DisplayButton type='button'  className="close" displayName='×' onClick={this.toggleDisclaimer}/> 
            </div>
            <ModalBody>
              <p className="textcontent" dangerouslySetInnerHTML={{__html: confirmWarningMessage}} />
                <NextPrevious {...this.props}
                  lastPage={false}
                  firstPage={true}
                  cancelClicked={this.toggleDisclaimer}
                  lblNext={yesButtonLabel}
                  lblCancel={noButtonLabel}
                  onClick={this.callProgress}
                  />
            </ModalBody>
          </Modal>)}
        </Row>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {sideBarMenu: state.contentReducer.menu2.mainMenu,
          contentFooter: state.userReducer.contentFooter.contentResource ? state.userReducer.contentFooter.contentResource.contents : {},
          pageSetting: state.userReducer.pageSetting,
          basePageTitle: state.userReducer.basePageTitle ? state.userReducer.basePageTitle : "",
          personalInfo: state.userReducer.personalInfo,
          focusInitializing: state.contentReducer.focusInitializing,
          deviceType: state.userReducer.deviceType,
          stepDescription: state.userReducer.stepDescription ? state.userReducer.stepDescription : "".contentResource,
          lifeChangeEvent: state.contentReducer.lifeEventChangeContent ? state.contentReducer.lifeEventChangeContent : {},
          warningInfo: state.userReducer.flexDollars ? state.userReducer.flexDollars.warningInfo : {}
          /* confirmPopupRequired: state.userReducer.flexDollars ? state.userReducer.flexDollars.confirmPopupRequired : false,
          confirmWarningMessage: state.userReducer.flexDollars ? state.userReducer.flexDollars.confirmWarningMessage : "", 
          
          stepDescription: state.userReducer.stepDescription ? state.userReducer.stepDescription : "",
          warningInfo: state.userReducer.flexDollars ? state.userReducer.flexDollars.warningInfo : {}*/
         }
};

export default connect(
mapStateToProps,
{ getHealthBenefit, progressBarIndicator, getMenu2, getMenu }
)(withRouter(BaseContentPageWithMenu));
