import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { setPageSetting } from '../actions/userActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import Beneficiaries from './Beneficiaries';
import { trackPromise } from "react-promise-tracker";
import { getDisplayText,setModalAttribute } from "../utils";
import {Modal, ModalBody} from 'reactstrap';
import DisplayButton from './button';
import _ from 'underscore';
import {Accordion, Card } from 'react-bootstrap';

class PackageMultiBeneficiaries extends Component {
  constructor(props) {
    super(props)
   
    let benefitMessageStatusList = {}
    if (this.props.beneficiaryBenefits)
      _.each(this.props.beneficiaryBenefits, (benefit) => benefitMessageStatusList[benefit.benefitID] = benefit.showErrorMessage)
    this.state = {
      expandedID: (props.beneficiaryBenefits && props.beneficiaryBenefits.length > 0 ) ? props.beneficiaryBenefits[0].benefitID : -1,
      expandedAccordion: (props.beneficiaryBenefits && props.beneficiaryBenefits.length > 0 ) ? 0 : -1,
      benefitMessageStatus: benefitMessageStatusList,
      isEdited: false,
      showSaveModal: false
    }
  }
  
  previousPage = () => {
    trackPromise(this.props.setPageSetting(this.props.pageSetting.name))
    if (typeof this.props.pageSetting.link === "string")
      this.props.history.push(this.props.pageSetting.link)
    else
      this.props.history.push("/Benefits")
  }

  handleSelect = (benefitID) => {
    this.setState({expandedID: benefitID})
  }

  handleCancel = (event, benefitID) => {
    if (this.props.beneficiaryBenefits.length > 1 )
      this.setState({expandedID: -1, expandedAccordion: -1})
    if (benefitID) {
      let benefitMessageStatus = this.state.benefitMessageStatus
      if (benefitMessageStatus[benefitID]) {
        benefitMessageStatus[benefitID] = false
        this.setState({benefitMessageStatus})
      }
    }
  }

  toggleAccordion = (openElementIndex) => {
    this.setState(state => ({expandedAccordion: openElementIndex === state.expandedAccordion ? -1: openElementIndex}), () => {
      if (this.state.expandedAccordion !== -1) 
        this.handleSelect(this.props.beneficiaryBenefits[this.state.expandedAccordion].benefitID)
    })
  }

  handleEdit = (beneftID, isEditing) => {
    if (this.state.expandedID === beneftID)
      this.setState({isEdited: isEditing})
  }

  checkIfCanGoBack = () => {
    if (this.state.isEdited)
      this.setState({showSaveModal: true})
    else
      this.previousPage()
  }

  handleCancelSaveModal = () => {
    this.setState({showSaveModal: false})
  }

  handleCloseSaveModal = () => {
    this.previousPage()
    this.handleCancelSaveModal()
  }

  render() {
    const pageTitle = getDisplayText(this.props.contents, 'lblViewBeneficiaries')
    const beneficiaryList = [];
    const isCancelVisible = this.props.beneficiaryBenefits.length > 1 ? true : false
    const expandCollapseIcon = getDisplayText((this.props.contentFooter && this.props.contentFooter) || {}, 'ExpandCollapseIcon')
    const lblBeneficiariesRequired = getDisplayText(this.props.contentBenefits, 'lblBeneficiariesRequired')
    const lblChooseBeneficiaries = getDisplayText((this.props.contentFooter && this.props.contentFooter) || {}, 'lblViewBeneficiaries')
    const confirmSaveMessage = getDisplayText(this.props.benefitBeneficiaryContent || this.props.contentBenefits, 'confirmSaveMessage')
    const noButtonLabel = getDisplayText((this.props.contentFooter && this.props.contentFooter) || {}, 'btnCancel')
    const yesButtonLabel = getDisplayText((this.props.contentFooter && this.props.contentFooter) || {}, 'lblYes')
    const backButtonText = getDisplayText((this.props.contentFooter && this.props.contentFooter) || {},'btnBack')
    const closeButtonText = getDisplayText((this.props.contentFooter && this.props.contentFooter) || {},'btnClose')

    _.forEach(this.props.beneficiaryBenefits, (benefit, index) => {
      beneficiaryList.push(
      <div key={`div-${benefit.benefitID}-ID-${benefit.benefitName}-package`} className="mb-4">
        {this.state.expandedID !== benefit.benefitID && <>
          <Card className="pcard">
            <Accordion.Toggle
              as={Card.Header}
              eventKey={`${index}`}
              onClick={()=>{this.toggleAccordion(index)}}>
                <FontAwesomeIcon aria-label={expandCollapseIcon.textToRead} className="pr-1" icon={this.state.expandedAccordion === index ? faChevronUp : faChevronDown}/> {benefit.benefitName}
            </Accordion.Toggle>
            <Card.Body>
              { this.state.benefitMessageStatus[benefit.benefitID] &&
                <div className="alert alert-danger" role="alert">{lblBeneficiariesRequired.display}</div>
              }
              <DisplayButton  className="outline-secondary" displayName={lblChooseBeneficiaries.display} onClick={() => {this.handleSelect(benefit.benefitID)}}  />
            </Card.Body>
          </Card>
        </>}
        {this.state.expandedID === benefit.benefitID && <>
          <div className="clearfix">
            <Beneficiaries benefitID={benefit.benefitID} benefitTitle={benefit.benefitName} isCancelVisible={isCancelVisible} handleCancelEdit={this.handleCancel} hideEdit={this.handleCancel} handleEdit={this.handleEdit}/>
          </div>
        </>}
      </div>
      )
    })
    return (
      <div className="packagebeneficiaries clearfix">
        <div className={`d-flex titlewithicon mb-3`}>
          <DisplayButton className="icon-action" displayName={backButtonText.display} icon={faChevronLeft} onClick={this.checkIfCanGoBack} /> 
        </div>
        <Accordion defaultActiveKey="0" className="baneficiarylist">
          { beneficiaryList }
        </Accordion>

        <Modal onOpened={() => setModalAttribute(pageTitle.textToRead)} aria-labelledby="modalHeading" aria-describedby="modalDescription" className="ml-cancelenrollment" isOpen={this.state.showSaveModal} toggle={this.handleCancelSaveModal} centered>
          <div className="modal-header">
            <h1 className="modal-title" id='modalHeading'>
             {pageTitle.display}
            </h1>
            <DisplayButton type="button"  className="close" displayName="×" arialabel={closeButtonText.display} onClick={this.handleCancelSaveModal}/> 
          </div>
          <ModalBody>
            <div className="ml-popupmessage" id='modalDescription' >
              <Fragment><h5 dangerouslySetInnerHTML={{ __html: confirmSaveMessage.display}} /></Fragment>
            </div>
            <div className={'ml-buttons d-flex justify-content-between'}>
              <DisplayButton controlId="handleClose" className="outline-secondary" displayName={noButtonLabel.display}  onClick={this.handleCancelSaveModal} />
              <DisplayButton controlId="handleCancel" className="secondary" displayName={yesButtonLabel.display}  onClick={this.handleCloseSaveModal} />
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
    return {
        beneficiaryBenefits: state.userReducer.packageMultiBeneficiaries && state.userReducer.packageMultiBeneficiaries.beneficiaryBenefits ? state.userReducer.packageMultiBeneficiaries.beneficiaryBenefits : [],
        contents: state.userReducer.packageMultiBeneficiaries && state.userReducer.packageMultiBeneficiaries.contentResource && state.userReducer.packageMultiBeneficiaries.contentResource.contents ? state.userReducer.packageMultiBeneficiaries.contentResource.contents : [],
        pageSetting: state.userReducer.pageSetting,
        contentFooter: (state.userReducer.contentFooter && state.userReducer.contentFooter.contentResource) ? state.userReducer.contentFooter.contentResource.contents : {},
        contentBenefits: state.userReducer.packageMultiBeneficiaries && state.userReducer.packageMultiBeneficiaries.contentResource ? state.userReducer.packageMultiBeneficiaries.contentResource.contents : {},
        benefitBeneficiaryContent: (((state.userReducer.beneficiaries || {}).contentResource || {}).controlResource || {}).BenefitBeneficiaries || []
    }
};
  
export default connect(
  mapStateToProps, 
  { setPageSetting })(withRouter(PackageMultiBeneficiaries));